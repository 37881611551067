import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/form/SubmitButton';
import { Dialog } from '~/components/ui/Dialog';
import { JobStatus } from '~/generated/graphql';
import { useContacts } from '~/routes/resources/contacts';
import { SubmitOptions } from '.';
import { MultiActionButton } from '../../form/MultiActionButton';
import { Switch as NewSwitch } from '../../ui/nucleus/Switch';

type SaveJobActionsProps = {
  assignee?: string[] | null;
  jobName?: string | null;
  status?: JobStatus;
  loading?: boolean;
  onClick: (status: JobStatus, options?: SubmitOptions) => void;
  isMultipleJobCreation?: boolean;
  notifyDefault?: boolean;
};

// TODO write stories

export const SaveJobActions = ({
  assignee,
  jobName,
  status,
  loading,
  onClick,
  isMultipleJobCreation,
  notifyDefault,
}: SaveJobActionsProps) => {
  const { t } = useTranslation('job');
  const contacts = useContacts({ requestPolicy: 'cache-first' });

  const assignees = contacts.filter((contact) =>
    assignee?.includes(contact.id)
  );
  const assigneeName = assignees.map((contact) => contact.name).join(', ');
  const isOwner = assignees.length === 1 && Boolean(assignees[0].isCurrentUser);

  // TODO combine the "SubmitOptions" state into a single piece of state - requires change comment from uncontrolled to controlled
  //const [notify, setNotify] = useState<boolean>(false);
  const [notify, setNotify] = useState<boolean>(notifyDefault ?? true);
  const [sendMeACopy, setSendMeACopy] = useState<boolean>(false);
  const [includePdf, setIncludePdf] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [action, setAction] = useState<'offer' | 'confirm' | null>(null);

  // Set notify to default value
  useEffect(() => setNotify(notifyDefault ?? true), [notifyDefault]);

  // Reset checkbox options if notify is switched off
  useEffect(() => {
    if (!notify) {
      setSendMeACopy(false);
      setIncludePdf(false);
    }
  }, [notify]);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const submitButtonLabel =
    action === 'confirm' ? t('form.jobReady') : t('form.saveAndSend');

  return (
    <>
      <Dialog
        title='Almost there!'
        show={isOpen}
        onClose={() => {
          if (loading) return;
          setIsOpen(false);
        }}
      >
        <div className='flex flex-col flex-wrap gap-3 px-5'>
          <p className='px-2 pt-3'>
            You are about to {action} the job{' '}
            <span className='font-semibold'>{jobName}</span>{' '}
            {action === 'offer' ? 'to' : 'for'}{' '}
            <span className='font-semibold'>{assigneeName}</span>
          </p>
          <NewSwitch
            label='Notify assignees'
            active={notify}
            onChange={setNotify}
          />
          <textarea
            ref={inputRef}
            className={classNames(
              'input mt-1 w-full resize-none rounded-md border border-grey-20 px-3 py-2.5 focus-visible:border-brand focus-visible:outline-none',
              !notify && 'hidden'
            )}
            placeholder='Add optional message...'
            autoFocus
          ></textarea>

          {notify && (
            <div className='text-left text-sm'>
              <label className='block p-1'>
                <input
                  className='mr-1'
                  type='checkbox'
                  checked={sendMeACopy}
                  onChange={(e) => setSendMeACopy(e.target.checked)}
                />{' '}
                Send Me a Copy
              </label>
              <label className='block p-1'>
                <input
                  className='mr-1'
                  type='checkbox'
                  checked={includePdf}
                  onChange={(e) => setIncludePdf(e.target.checked)}
                />{' '}
                Include PDF
              </label>
            </div>
          )}

          <div className='flex gap-3 self-end pb-5 pt-1'>
            <button
              className={classNames(
                'px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand',
                {
                  'text-grey-40 hover:text-grey-40': loading,
                }
              )}
              onClick={() => {
                setIsOpen(false);
              }}
              disabled={loading}
            >
              Go back
            </button>
            <Button
              type='button'
              loading={loading}
              size='small'
              onClick={() => {
                action === 'confirm'
                  ? onClick(JobStatus.Accepted, {
                      notify,
                      comment: inputRef.current?.value,
                      sendMeACopy,
                      includePdf,
                    })
                  : onClick(JobStatus.Offered, {
                      notify,
                      comment: inputRef.current?.value,
                      sendMeACopy,
                      includePdf,
                    });
              }}
            >
              {loading ? t('loading') : submitButtonLabel}
            </Button>
          </div>
        </div>
      </Dialog>
      {assignee &&
        (isOwner || status === JobStatus.InProgress ? (
          <Button
            type='button'
            loading={loading}
            onClick={() => {
              onClick(JobStatus.Accepted);
            }}
          >
            {status === JobStatus.InProgress ? 'Save Job' : t('form.jobReady')}
          </Button>
        ) : (
          <MultiActionButton
            options={[
              {
                label: t('form.jobReady'),
                onClick: () => {
                  if (!jobName) {
                    // To initiate form validation
                    onClick(JobStatus.Offered);
                    return;
                  }
                  setAction('confirm');
                  setIsOpen(true);
                },
              },
              {
                label: t('form.saveAndSend'),
                onClick: () => {
                  if (!jobName) {
                    // To initiate form validation
                    onClick(JobStatus.Accepted);
                    return;
                  }
                  setAction('offer');
                  setIsOpen(true);
                },
              },
            ]}
          />
        ))}
      {(!status || status === JobStatus.Created) && (
        <Button
          type='button'
          loading={loading}
          onClick={() => onClick(JobStatus.Created)}
          intent='secondary'
        >
          {isMultipleJobCreation ? t('form.saveAndClose') : t('form.saveDraft')}
        </Button>
      )}
    </>
  );
};
