import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { JobStatusBadge } from '~/components/ui/JobStatusBadge';
import { JobStatus, Status } from '~/gql/graphql';
import { Avatar } from '../Avatar';
import { Image } from '../Image';
import { StatusBadge } from '../StatusBadge';
import { Card } from '../nucleus';
import { OccupationInformation } from './OccupationInformation';

type CardNewV2Props = {
  /** HTML ID */
  id?: string;
  /** URL path to pass to router link */
  linkTo: string;
  /** If true the card will have a shaded background */
  isSelected?: boolean;
  /** Is true the card will display in narrower view even when on larger breakpoints */
  small?: boolean;
  /** URL to an image file to pass to `Image` component */
  image?: string | React.ReactNode;
  /** If true will show placeholder if image is empty */
  showImage?: boolean;
  /** First row of text */
  titleText: string;
  /** Middle row of text */
  secondaryText?: string | React.ReactNode;
  /** Bottom row of text */
  tertiaryText?: React.ReactNode;
  /** Text under the main title and secondary text */
  hero?: { number?: number | string; directive: string | React.ReactNode };
  /** Hero element for more control over what's displayed on the right side */
  heroElement?: React.ReactNode;
  /** Status to display in badge */
  status?: Status | JobStatus;
  /** Object representing an assigned user */
  user?: {
    name: string;
    image?: string | null;
  } | null;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  /** If true will show tertiary text on mobile viewport */
  showTertiaryTextOnMobile?: boolean;
  /** Necessary data for rendering 'Occupation Information' */
  occupationData: OccupationInformation;
};

const { Complete } = JobStatus;

function getColor(str: string) {
  const hash = [...str].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${hash % 360}, 80%, 50%)`;
}

export function CardNewV2({
  id,
  linkTo,
  isSelected,
  small,
  image,
  showImage,
  titleText,
  secondaryText,
  tertiaryText,
  user,
  status,
  hero,
  heroElement,
  onClick,
  showTertiaryTextOnMobile = false,
  occupationData,
}: CardNewV2Props) {
  const [searchParams] = useSearchParams();

  return (
    <div className='mb-2 flex min-w-0 flex-1 flex-col lg:shadow'>
      <OccupationInformation occupationData={occupationData} id={id}>
        <OccupationInformation.JobListItemOuter>
          <Card
            as={Link}
            id={id}
            to={linkTo}
            selected={isSelected}
            className='!mb-0 !rounded-none !p-0 !shadow-none'
            style={
              searchParams.get('pretty') === ''
                ? {
                    borderLeftWidth: 4,
                    borderLeftStyle: 'solid',
                    borderLeftColor: getColor(user?.name ?? 'x'),
                  }
                : undefined
            }
            onClick={onClick}
          >
            <div className='flex items-center gap-2 p-2 lg:py-0 lg:pl-3 lg:pr-0'>
              {showImage && (
                <div className='hidden h-10 w-10 shrink-0 overflow-hidden rounded-md xs:block lg:h-14 lg:w-14'>
                  {typeof image === 'string' ? (
                    <Image url={image} size='fit' />
                  ) : (
                    image
                  )}
                </div>
              )}
              <div className='flex-1 truncate sm:ml-1 lg:ml-2'>
                <div
                  className={classNames(
                    small && 'lg:flex-col lg:items-start lg:gap-0',
                    'flex flex-col items-start overflow-hidden text-base md:flex-row md:items-center md:gap-2.5'
                  )}
                >
                  <p className='max-w-full truncate text-sm font-medium leading-tight text-grey-70 md:text-base lg:font-semibold lg:leading-normal'>
                    {titleText}
                  </p>
                  {secondaryText && (
                    <>
                      {!small && (
                        <div className='mt-1 hidden h-1 w-1 rounded-full bg-grey-70 md:block'></div>
                      )}
                      <p
                        className={classNames(
                          'min-w-[170px] max-w-full truncate text-sm leading-normal text-grey-40',
                          !small && 'md:text-base'
                        )}
                      >
                        {secondaryText}
                      </p>
                    </>
                  )}
                </div>
                {tertiaryText && (
                  <div
                    className={classNames(
                      'truncate text-xs text-grey-40',
                      !showTertiaryTextOnMobile ? 'hidden lg:block' : 'block'
                    )}
                  >
                    {tertiaryText}
                  </div>
                )}
              </div>

              {heroElement ? (
                <div className='flex flex-col justify-between lg:min-h-[78px]'>
                  <OccupationInformation.JobListItemInner
                    hidden={status === Complete}
                  >
                    <div
                      className={classNames(
                        small && '-mt-1 md:flex-col lg:items-end',
                        'ml-auto flex items-center gap-1 sm:flex-row lg:mr-[15px] lg:gap-4'
                      )}
                    >
                      <div className='flex justify-center pr-1 text-right leading-tight'>
                        {heroElement}
                      </div>
                      <div className='flex items-center gap-2'>
                        {user && (
                          <Avatar
                            image={user.image ?? ''}
                            name={user.name}
                            size='medium'
                          />
                        )}
                        {status && <JobStatusBadge value={status} />}
                      </div>
                    </div>
                  </OccupationInformation.JobListItemInner>
                </div>
              ) : (
                <div className='ml-auto pr-1 text-right'>
                  {status === 'Inactive' && <StatusBadge value={status} />}
                  <p className='whitespace-nowrap text-sm font-medium'>
                    <span className='text-base'>{hero?.number}</span>{' '}
                    {hero?.directive}
                  </p>
                </div>
              )}
            </div>
          </Card>
        </OccupationInformation.JobListItemOuter>
      </OccupationInformation>
    </div>
  );
}
