import { Button } from '~/components/ui/buttons/Button';
import { Confirm } from '~/components/ui/Confirm';
import { useState } from 'react';

type DeleteButtonProps = {
  id: string;
  onDelete: (id: string) => void;
};

export function DeleteTaskButton({ id, onDelete }: DeleteButtonProps) {
  const [deleting, setDeleting] = useState(false);

  return (
    <div className='text-center'>
      {deleting && (
        <Confirm
          title='Delete this task?'
          body='Are you sure you want to delete this task?'
          cancel='Keep the task'
          confirm='Delete'
          onCancel={() => setDeleting(false)}
          onConfirm={() => onDelete(id)}
          danger
        />
      )}
      <Button type='button' color='delete' onClick={() => setDeleting(true)}>
        Delete Task
      </Button>
    </div>
  );
}
