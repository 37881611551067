import { Fragment } from 'react';
import { useQuery } from 'urql';
import { isSameDate } from '~/components/job/JobView/JobHistory';
import { DateDisplay } from '~/components/sites/tabs/JobsTab';
import { CardSmall } from '~/components/ui/CardSmall';
import { Loading } from '~/components/ui/Loading';
import { graphql } from '~/gql';
import { formatDate } from '~/helpers/formatDate';

export const AssetJobsDocument = graphql(`
  query AssetJobs($id: ID!) {
    asset(id: $id) {
      jobs {
        id
        status
        name
        _dateScheduled
        scheduleStart
        scheduleEnd
        location {
          __typename
          id
          name
        }
        assignee {
          id
          name
        }
      }
    }
  }
`);

export function useAssetJobs(assetId: string) {
  const [result, reexecuteQuery] = useQuery({
    query: AssetJobsDocument,
    variables: { id: assetId },
    requestPolicy: 'cache-first',
  });

  const assetJobs = result.data?.asset?.jobs ?? [];
  return [
    assetJobs,
    () => reexecuteQuery({ requestPolicy: 'network-only' }),
  ] as [typeof assetJobs, () => void];
}

type AssetJob = ReturnType<typeof useAssetJobs>['0'][number];

export function AssetJobs({ assetId }: { assetId: string }) {
  const [jobs, reexecuteQuery] = useAssetJobs(assetId);

  if (!jobs.length) {
    return <div className='p-4'>There are no jobs for this asset</div>;
  }

  return (
    <div className='py-3'>
      {jobs?.map((job, i) => (
        <Fragment key={job.id}>
          {i <= 0 ||
          !isSameDate(jobs[i - 1].scheduleStart, job.scheduleStart) ? (
            <DateDisplay date={formatDate(job.scheduleStart, 'PP')} />
          ) : !job._dateScheduled && jobs[i - 1]._dateScheduled ? (
            <DateDisplay date='Unscheduled' />
          ) : (
            ''
          )}
          <JobCardSm key={job.id} job={job} />
        </Fragment>
      ))}
    </div>
  );
}

function JobCardSm({ job }: { job: AssetJob }) {
  if (!job) {
    return <Loading />;
  }

  return (
    <>
      <CardSmall
        linkTo={`/jobs/${job.id}`}
        titleText={job.name}
        secondaryText={job.location?.name}
        heroElement={<HeroElement job={job} />}
        user={{ name: job.assignee?.name ?? 'U' }}
        status={job.status}
      />
    </>
  );
}

function HeroElement({ job }: { job: AssetJob }) {
  return (
    <div className='flex flex-col justify-start pr-1 text-right leading-tight'>
      <p className='... truncate text-sm font-medium leading-tight text-grey-70 md:text-[15px] '>
        {formatDate(job.scheduleStart, 'h:mm a')}
      </p>
      {job.scheduleEnd && (
        <p className='whitespace-nowrap text-xs text-grey-50'>
          to {formatDate(job.scheduleEnd, 'h:mm a')}
        </p>
      )}
    </div>
  );
}
