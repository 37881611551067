import { IAttribute } from '~/types';

export function extractAttributes(
  attributes: Record<string, string> | IAttribute[] | undefined
) {
  if (attributes) {
    return Array.isArray(attributes)
      ? attributes.flatMap(({ name, value }) =>
          value.map((s) => `${name}: ${s}`)
        )
      : Object.keys(attributes).map((key) => `${key}: ${attributes[key]}`);
  }
  return [];
}
