import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type ResultProps = {
  count?: number;
  className?: string;
};

export const Result = ({ count = 0, className }: ResultProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'whitespace-nowrap pb-3 text-center text-xs text-grey-40',
        className
      )}
    >
      {t('displayingResults', { count })}
    </div>
  );
};
