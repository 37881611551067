export function pick<T extends object, K extends keyof T>(obj: T, keys: K[]) {
  const newObject: Partial<Pick<T, K>> = {};
  keys.forEach((k) => {
    newObject[k] = obj[k];
  });
  return newObject as Pick<T, K>;
}

export function removeEmpty<T extends object>(obj: T) {
  // TODO the return type is not correct
  return Object.fromEntries(
    Object.entries(obj).filter(
      (entry): entry is [string, NonNullable<any>] => entry[1] != null
    )
  ) as T;
}
