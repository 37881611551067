/**
 * This is a helper for populating Formik initialValues with data from a
 * resource without adding any additional properties to the initialValues
 *
 * @deprecated
 */
export function updateInitialValues(initialValues, values) {
  const newInitialValues = {}

  Object.keys(initialValues).forEach((key) => {
    newInitialValues[key] =
      values && key in values && values[key] ? values[key] : initialValues[key]
  })

  return newInitialValues
}
