import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Menu } from '~/components/ui/Menu';

export function AutomationMenu() {
  const { t } = useTranslation();

  return (
    <Menu>
      <Menu.Item as={Link} to='edit'>
        {t('automations.edit')}
      </Menu.Item>
    </Menu>
  );
}
