import { Filter } from '../ui/FilterGroup';
import { FilterGroupSearch } from '../ui/FilterGroupSearch';

type Props = {
  query: string;
  onSubmit: (values: { query: string; filters: URLSearchParams }) => void;
  filters: Filter[];
  filtersValue: URLSearchParams;
};

export function JobTemplateFilters({
  query,
  onSubmit,
  filters,
  filtersValue,
}: Props) {
  return (
    <FilterGroupSearch
      filters={filters}
      value={{
        searchValue: query,
        filtersValue: filtersValue,
      }}
      onChange={({ searchValue, filtersValue }) => {
        onSubmit({ query: searchValue, filters: filtersValue });
      }}
    />
  );
}
