import autosize from 'autosize';
import classNames from 'classnames';
import { useField } from 'formik';
import {
  HTMLProps,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import { Label } from '~/components/ui/Label';
import styles from './TextArea.module.css';

const cx = classNames.bind(styles);

interface CommonProps extends HTMLProps<HTMLTextAreaElement> {
  label: string;
}

interface TextAreaProps extends CommonProps {
  error?: string | false;
  /** Skeleton has no skin */
  skeleton?: boolean;
}

interface TextAreaFieldProps extends CommonProps {
  name: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, error, className, skeleton, ...props }, ref) => {
    const textarea = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => textarea.current!, []);

    useLayoutEffect(() => {
      const el = textarea.current;
      if (el) {
        // console.log('initializing autosize');
        autosize(el);
        return () => {
          // console.log('destroying autosize');
          autosize.destroy(el);
        };
      }
    }, []);

    useLayoutEffect(() => {
      if (textarea.current?.value === '') {
        autosize.update(textarea.current);
      }
    }, [textarea.current?.value]);

    return (
      <div className={cx('group', className)}>
        {label && <Label>{label}</Label>}
        <textarea
          ref={textarea}
          className={classNames([
            'w-full rounded px-3 py-2.5 outline-none',
            error
              ? 'border-red-600 focus-visible:border-red-600'
              : skeleton
              ? ''
              : 'border-gray88',
            !skeleton &&
              'input mt-1 w-full border focus-visible:border-brand focus-visible:outline-none',
          ])}
          {...props}
        />
        {error && <div className='ErrorMessage'>{error}</div>}
      </div>
    );
  }
);

export const TextAreaField = ({
  name,
  label,
  ...props
}: TextAreaFieldProps) => {
  const [field, meta] = useField(name);

  const error =
    meta.touched &&
    meta.error &&
    (typeof meta.error === 'string' ? meta.error : JSON.stringify(meta.error));

  // @ts-expect-error FIXME
  return <TextArea label={label} error={error} {...field} {...props} />;
};
