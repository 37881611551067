import { yupResolver } from '@hookform/resolvers/yup';
// @ts-ignore
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
// import LogoIcon from '~/brand/gfactor.com/logo-icon';
// import LogoWhite from '~/brand/gfactor.com/logo-white';
import { Link } from 'react-router-dom';
import { FullMono, Square } from '~/brand/generated/propfit.app';
import { Input } from '~/components/form/Input';
import { post } from '~/helpers/fetch';

type FormData = {
  name: string;
  email: string;
  password: string;
};

type SignupResult = {
  email: string;
  redirectTo?: string;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export function SignupForm() {
  const { t } = useTranslation('auth');
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<FormData>({
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const signup = async (values: FormData) => {
    const { data } = await post<SignupResult>('/api/signup', values);
    if (data.redirectTo) {
      window.location.href = data.redirectTo;
    } else {
      window.location.reload();
    }
  };

  return (
    <div className='flex w-screen items-center justify-center h-screen'>
      <div className='w-full lg:w-1/2'>
        <form
          onSubmit={handleSubmit(signup)}
          className='mx-auto flex flex-1 flex-col justify-center rounded-lg px-10 py-16 md:w-4/5 md:border md:border-gray88 lg:mx-auto lg:w-[30rem] lg:flex-initial'
        >
          <div className='mb-12 flex flex-col items-center justify-center'>
            <Square className='mx-auto h-20' />
            <h1 className='mt-5 text-center text-2xl font-medium'>
              {t('signUp')}
            </h1>
          </div>
          <Input
            {...register('name')}
            required
            label={t('name')}
            type='text'
            error={errors.name?.message}
          />
          <Input
            {...register('email')}
            required
            label={t('emailAddress')}
            type='email'
            error={errors.email?.message}
          />
          <Input
            {...register('password')}
            required
            label={t('password')}
            type='password'
            error={errors.password?.message}
          />
          <button
            className='mb-4 mt-8 w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark  disabled:opacity-50'
            disabled={isSubmitting}
          >
            {t('createAccount')}
          </button>
          <div className='text-center text-sm'>
            <Link className='font-normal underline' to='/login'>
              {t('backToLogin')}
            </Link>
          </div>
        </form>
      </div>

      <div
        className={
          'hidden items-center justify-center bg-gradient-to-b from-brand to-slate-dark h-screen lg:flex lg:w-1/2'
        }
      >
        <FullMono className='w-1/2' />
      </div>
    </div>
  );
}
