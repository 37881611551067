import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import { TagCombobox, TagEntityType } from '~/components/TagCombobox';
import { Tag, useTags } from '~/components/TagCombobox/tags';
import { Button } from '~/components/form/SubmitButton';
import { Dialog } from '~/components/ui/Dialog';
import { graphql } from '~/gql';
import { SideLayout } from '~/layouts/side/SideLayout';

type Props = {
  selected: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

type BulkTagsAction = 'add' | 'remove';

const AddTagsToJobs_Mutation = graphql(/* GraphQL */ `
  mutation AddTagsToJobs($jobIds: [ID!]!, $tagIds: [ID!]!) {
    addTagsToJob(id: $jobIds, tagIds: $tagIds) {
      id
      jobTags {
        id
      }
    }
  }
`);

const RemoveTagsFromJobsMutation = graphql(/* GraphQL */ `
  mutation RemoveTagsFromJobs($jobIds: [ID!]!, $tagIds: [ID!]!) {
    removeTagsFromJob(id: $jobIds, tagIds: $tagIds) {
      id
      jobTags {
        id
      }
    }
  }
`);

export function BulkTags({ selected, onCancel, onSuccess }: Props) {
  const { t } = useTranslation();

  const [addResult, addTags] = useMutation(AddTagsToJobs_Mutation);
  const [removeResult, removeTags] = useMutation(RemoveTagsFromJobsMutation);

  const [tags, reload] = useTags(TagEntityType.Job);
  const [selectedTags, setSelectedTags] = useState<Tag['id'][]>([]);

  // Confirmation dialog state
  const [action, setAction] = useState<BulkTagsAction | null>(null);

  useEffect(() => {
    if (addResult.error) {
      toast.error(addResult.error.message.replace('[GraphQL] ', ''));
      setAction(null);
      return;
    }
    if (addResult.data) onSuccess();
  }, [addResult, onSuccess]);

  useEffect(() => {
    if (removeResult.error) {
      toast.error(removeResult.error.message.replace('[GraphQL] ', ''));
      setAction(null);
      return;
    }
    if (removeResult.data) onSuccess();
  }, [removeResult, onSuccess]);

  const loading = addResult.fetching || removeResult.fetching;

  const confirm = (a: BulkTagsAction) => {
    setAction(a);
  };

  const submit = async () => {
    if (action === 'add') {
      await addTags({ jobIds: selected, tagIds: selectedTags });
    } else if (action === 'remove') {
      await removeTags({ jobIds: selected, tagIds: selectedTags });
    }
  };

  return (
    <>
      <Dialog
        title='Bulk Change Warning!'
        show={!!action}
        onClose={() => {
          if (loading) return;
          setAction(null);
        }}
      >
        <div className='flex flex-col flex-wrap gap-3 px-5'>
          <p className='px-2 pt-3'>
            You are about to {action} the selected tag(s){' '}
            {action === 'add' ? 'to' : 'from'}
            <span className='font-semibold'> {selected.length}</span> jobs.
          </p>
          <div className='flex gap-3 self-end pb-5 pt-1'>
            <button
              className={classNames(
                'px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand',
                {
                  'text-grey-40 hover:text-grey-40': loading,
                }
              )}
              onClick={() => {
                setAction(null);
              }}
              disabled={loading}
            >
              Go back
            </button>
            <Button
              type='button'
              loading={loading}
              size='small'
              onClick={submit}
            >
              {loading ? t('loading') : 'Go Ahead'}
            </Button>
          </div>
        </div>
      </Dialog>
      <SideLayout>
        <SideLayout.Head iconClass='chevron-left' onClose={onCancel}>
          Bulk Change
        </SideLayout.Head>
        <SideLayout.Body>
          <div className='my-4'>
            <TagCombobox
              entityType={TagEntityType.Job}
              options={tags}
              value={
                selectedTags
                  ? tags.filter((t) => selectedTags.includes(t.id))
                  : []
              }
              onChange={(tags) => {
                reload();
                setSelectedTags(tags.map(({ id }) => id));
              }}
            />
          </div>
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <Button type='button' intent='primary' onClick={() => confirm('add')}>
            Add
          </Button>
          <Button
            type='button'
            intent='secondary'
            onClick={() => confirm('remove')}
          >
            Remove
          </Button>
        </SideLayout.Foot>
      </SideLayout>
    </>
  );
}
