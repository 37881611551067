import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '~/components/form/Input';
import { post } from '~/helpers/fetch';
import LogoIcon from '~/brand/gfactor.com/logo-icon';
import LogoWhite from '~/brand/gfactor.com/logo-white';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

type FormData = {
  name: string;
  email: string;
  message: string;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
});

export const Help = () => {
  const [done, setDone] = useState(false);
  const { t } = useTranslation('auth');

  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const help = async (values: FormData) => {
    try {
      await post('/api/help', values);
      setDone(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  if (done) {
    return (
      <div className='flex h-screen w-screen items-center p-4'>
        <div className='flex-1 lg:mx-auto lg:w-96 lg:flex-initial'>
          <div className='mb-16'>
            <LogoIcon className='mx-auto h-20' />
          </div>
          <p className='mb-8 text-lg font-semibold'>
            Need Help with your Account?
          </p>
          <p>Fill out the form below so we know how to help you. </p>
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-screen w-screen items-center justify-center'>
      <div className='w-full lg:w-1/2'>
        <form
          onSubmit={handleSubmit(help)}
          className='md:border-gray88 mx-auto flex flex-col justify-center rounded-lg py-16 px-10 md:w-4/5 md:border lg:mx-auto lg:w-[30rem] lg:flex-initial'
        >
          <div className='mb-8 flex flex-col items-center justify-center'>
            <LogoIcon className='mx-auto h-20' />
            <h1 className='mt-5 mb-1 text-2xl font-medium'>
              Need help with your account?
            </h1>
            <p className='text-center'>
              Fill out the form below so we know how to help you.{' '}
            </p>
          </div>
          <Input
            {...register('name')}
            required
            label={t('name')}
            type='text'
            error={errors.name?.message}
          />
          <Input
            {...register('email')}
            required
            label={t('email')}
            type='email'
            error={errors.email?.message}
          />
          <Input
            {...register('message')}
            required
            label={t('message')}
            type='textarea'
            error={errors.message?.message}
          />
          <button
            className='bg-brand hover:bg-slateDark mt-8 mb-4 w-full rounded-lg p-3 font-semibold uppercase text-white transition-all duration-300 disabled:opacity-50'
            disabled={isSubmitting}
          >
            {t('getHelp')}!
          </button>
          <div className='text-center text-sm'>
            <Link className='font-normal underline' to='/login'>
              {t('backToLogin')}
            </Link>
          </div>
        </form>
      </div>

      <div
        className={
          'from-brand to-slate-dark hidden h-screen items-center justify-center bg-gradient-to-b lg:flex lg:w-1/2'
        }
      >
        <LogoWhite className='w-1/2' />
      </div>
    </div>
  );
};
