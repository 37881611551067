import { SelectorIcon as ChevronUpDownIcon } from '@heroicons/react/solid';
import { useField } from 'formik';
import { useState } from 'react';
import { TagCombobox, TagEntityType } from '~/components/TagCombobox';
import {
  AddTagsButton,
  TagsBuilder,
} from '~/components/TagCombobox/TagsBuilder';
import { Tag } from '~/generated/graphql';
import { extractProperty } from '~/helpers/collection';
import { notUndefined } from '~/helpers/filter';

type Props = {
  options: Tag[];
  value: Tag[];
  onChange: (ids: string[]) => void;
};

/** Props for the Formik field */
type FieldProps = {
  name?: string;
  options: Tag[];
};

export function UpdateTaskTags({ options, value, onChange }: Props) {
  const [isTagBoxOpen, setIsTagBoxOpen] = useState(false);
  const selectedTagsIds = extractProperty(value, 'id');

  return (
    <div className='mb-4'>
      <p className='mb-[3.5px] ml-1 text-sm font-medium text-grey-50'>Tags</p>
      {selectedTagsIds.length > 0 && !isTagBoxOpen ? (
        <TagsBuilder tags={value} onUpdate={() => setIsTagBoxOpen(true)} />
      ) : isTagBoxOpen ? (
        <>
          <div className='mb-[5px] flex items-center rounded-[3px] border border-grey-20 p-2'>
            <div className='flex flex-1 flex-wrap gap-1 text-left'>
              {selectedTagsIds.length > 0 ? (
                <TagsBuilder
                  tags={options.filter((t) => selectedTagsIds.includes(t.id))}
                  onRemove={(id) =>
                    onChange(selectedTagsIds.filter((tagId) => tagId !== id))
                  }
                />
              ) : (
                <span className='text-grey-40'>None Selected</span>
              )}
            </div>
            <ChevronUpDownIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </div>

          <TagCombobox
            entityType={TagEntityType.Task}
            options={options}
            value={options.filter((t) => selectedTagsIds.includes(t.id))}
            hasPreview={false}
            onChange={(tags) => {
              onChange(tags.map(({ id }) => id));
            }}
            dialogMode={true}
            isOpen={isTagBoxOpen}
            onClose={() => {
              setIsTagBoxOpen(false);
              onChange(value.map((t) => t.id));
            }}
            onSubmit={() => setIsTagBoxOpen(false)}
            containerStyles='w-full'
          />
        </>
      ) : (
        <AddTagsButton onClick={() => setIsTagBoxOpen(true)} />
      )}
    </div>
  );
}

export function UpdateTaskTagsField({ name = 'tags', options }: FieldProps) {
  const [field, meta, helpers] = useField<string[]>(name);
  console.log('valu', field.value, options);
  return (
    <UpdateTaskTags
      options={options}
      value={field.value
        .map((id) => options.find((tag) => tag.id === id))
        .filter(notUndefined)}
      onChange={(selected) => helpers.setValue(selected)}
    />
  );
}
