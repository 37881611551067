import localforage from 'localforage';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'urql';
import { AttributeFormData } from '~/components/AttributeForm/AttributeFormData';
import { Button } from '~/components/form/SubmitButton';
import { ReadOnlyEditor } from '~/components/form/editor/ReadOnlyEditor';
import { Confirm } from '~/components/ui/Confirm';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { getFragmentData, graphql } from '~/gql';
import {
  Attachment,
  CompleteAttributeAuditTaskDocument,
  JobQuery,
  JobStatus,
  TaskQuery,
} from '~/gql/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { WorkflowButtons } from '../WorkflowButtons';
import { MandatoryTaskHelper } from './Custom';

type Job = JobQuery['job'];

type Props = {
  enabled: boolean;
  job: Job;
  task: TaskQuery['task'];
  onReset: () => void;
  onSuccess: () => void;
  onClose: () => void;
};

type FormValues = {
  attributes: Record<string, string>;
};

const AttributeTask_TaskFragment = graphql(`
  fragment AttributeTask on Task {
    attribute {
      id
      attributes
      formValues {
        name
        value
      }
    }
  }
`);

const SiteAttributes_Query = graphql(`
  query SiteAttributes($id: ID!) {
    site(id: $id) {
      id
      attributes {
        id
        value
        ...Attribute_AttributeFragment
      }
    }
  }
`);

export const AttributeTask = ({
  enabled,
  job,
  task,
  onReset,
  onSuccess,
  onClose,
}: Props) => {
  const storageKey = `Task:${task.id}:Form`;

  // Whether or not to display the confirm disacrd changes dialog
  const [confirm, setConfirm] = useState(false);

  const [result] = useQuery({
    query: SiteAttributes_Query,
    variables: { id: job?.location?.id ?? '' },
    pause: !job?.location,
  });

  const taskData = getFragmentData(AttributeTask_TaskFragment, task);

  // The default attributes value on the audit task is all the configured attributes with values mixed in from the existing values on location
  //
  // Note that since attributes are written at the time the task is completed, this should always be the current values from the location, even when the task is reset via "Reset Status"
  const defaultAttributes = taskData.attribute?.attributes
    ? taskData.attribute.attributes.reduce<Record<string, string>>(
        (prev, curr) => {
          // Find the current attribute value on the site
          const siteAttribute = result.data?.site.attributes?.find(
            ({ id }) => id === curr
          );

          return { ...prev, [curr]: siteAttribute?.value.join(',') || '' };
        },
        {}
      )
    : {};

  const attachments = result.data?.site.attributes?.reduce(
    (prev: Record<string, Attachment[]>, curr: any) => {
      if (curr.type === 'attachment' && curr.attachments?.length) {
        return { ...prev, [curr.id]: curr.attachments };
      }
      return prev;
    },
    {}
  );

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      attributes: defaultAttributes,
    },
  });

  useEffect(() => {
    if (result.fetching) {
      return;
    }

    /**
     * The order of precedence for attribute values is:
     * 1. The value from offline storage
     * 2. The value from the saved task
     * 3. The value from the site
     */
    async function setInitialValues() {
      // 1. reset default values to the current site attributes
      reset({ attributes: defaultAttributes });

      // 2. find and merge any saved values
      const saved = taskData.attribute?.attributes?.reduce(
        (prev: Record<string, string>, id) => {
          const value = taskData.attribute?.formValues?.find(
            (v) => v.name === id
          )?.value;

          if (value) {
            return { ...prev, [id]: value.join(',') };
          }

          return prev;
        },
        {}
      );

      // 3. read in any unsaved values from offline storage
      const offline = await localforage.getItem<FormValues>(storageKey);

      if (saved || offline?.attributes) {
        setValue(
          'attributes',
          { ...defaultAttributes, ...saved, ...offline?.attributes },
          { shouldDirty: Boolean(offline?.attributes) }
        );
      }
    }

    setInitialValues();
  }, [result.fetching, taskData]);

  // Is the form editable or in read mode?
  const editable = useMemo(
    () =>
      enabled &&
      [JobStatus.Created, JobStatus.InProgress].includes(task.status),
    [enabled, task.status]
  );

  // Watch all attribute fields for changes and write them to offline storage...
  useEffect(() => {
    if (!editable) return;

    const subscription = watch(() => {
      // On ANY change simply write the entire form to offline storage
      localforage.setItem(storageKey, getValues());
    });
    return () => subscription.unsubscribe();
  }, [editable, watch]);

  const { t } = useTranslation(['translation', 'job']);
  const [, complete] = useMutation(CompleteAttributeAuditTaskDocument);
  const location = job?.location;

  if (!(location && location.__typename === 'Site')) {
    return (
      <ErrorMessage message='Job does not have a location' onBack={onClose} />
    );
  }

  const attributeIds = taskData.attribute?.attributes;

  const description = ReadOnlyEditor({ content: task.description });

  const handleClose = async () => {
    if (isDirty) {
      setConfirm(true);
      return;
    }
    await localforage.removeItem(storageKey);
    onClose();
  };

  const handleDiscard = async () => {
    await localforage.removeItem(storageKey);
    onClose();
  };

  const submit = async (data: FormValues) => {
    const res = await complete({
      input: {
        id: task.id,
        attributes: Object.keys(data.attributes).map((id) => ({
          name: id,
          value: [data.attributes[id]],
        })),
      },
    });

    if (!res.error) {
      await localforage.removeItem(storageKey);
      onSuccess();
    }
  };

  const required = task.required;
  const showMandatoryTaskHelper =
    required &&
    task.status !== JobStatus.Complete &&
    task.status !== JobStatus.Incomplete;

  return (
    <SideLayout as='form' onSubmit={handleSubmit(submit)}>
      {confirm && (
        <Confirm onCancel={() => setConfirm(false)} onConfirm={handleDiscard} />
      )}

      <SideLayout.Head onClose={handleClose}>{task.name}</SideLayout.Head>
      <SideLayout.Body>
        {task.status !== JobStatus.Created && (
          <StatusBadge prefix={t('job:task')} value={task.status} />
        )}

        {showMandatoryTaskHelper ? (
          <MandatoryTaskHelper jobName={job.name} />
        ) : null}

        <div>
          {description && (
            <>
              <div className='label mb-1.5'>{t('description')}</div>
              <div className='mb-6'>{description}</div>
            </>
          )}
        </div>

        {result.fetching ? (
          <div className='flex items-center justify-center pt-4'>
            <Loading spinner />
          </div>
        ) : (
          <Controller
            control={control}
            name='attributes'
            render={({ field }) => (
              <AttributeFormData
                value={field.value}
                onChange={field.onChange}
                accept={attributeIds}
                readMode={!editable}
                attachments={attachments}
              />
            )}
          />
        )}
      </SideLayout.Body>
      <SideLayout.Foot className='p-4'>
        {enabled && (
          <WorkflowButtons
            id={task.id}
            jobStatus={job.status}
            taskStatus={task.status}
            onReset={onReset}
          >
            <CompleteTaskButton />
          </WorkflowButtons>
        )}
      </SideLayout.Foot>
    </SideLayout>
  );
};

function CompleteTaskButton({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation('job');

  return (
    <Button type='submit' disabled={disabled}>
      {t('completeTask')}
    </Button>
  );
}
