import { useTranslation } from 'react-i18next';
import { ItemType } from '~/generated/graphql';
import { Avatar } from '../ui/Avatar';

export type SkuInfoProps = {
  name: string;
  itemType?: ItemType | keyof typeof ItemType;
  code?: string | null;
  image?: string | null;
};

/**
 * Display information about a SKU - used inside cards
 */
export const SkuInfo = ({ name, code, itemType, image }: SkuInfoProps) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-1 overflow-hidden'>
      <Avatar
        image={image}
        name={name}
        size='full'
        square
        className='mr-3 h-16 w-16 rounded-md'
      />

      <div className='pr-3'>
        <p className='title-text my-1'>{name}</p>
        <p className='secondary-text mb-1 text-tertiary'>
          {t('itemType.' + itemType)}
        </p>
        <p className='tertiary-text mb-1 text-tertiary'>{code}</p>
      </div>
    </div>
  );
};
