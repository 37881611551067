import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormData } from '.';
import { ContactType, useContactsQuery } from '../../../generated/graphql';
import { SelectField } from '../../form/downshift/SelectField';
import { Loading } from '../../ui/Loading';

export function OrganisationField() {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormData>();
  const [result] = useContactsQuery();

  if (result.fetching) return <Loading />;

  const options =
    result.data?.contacts
      .filter(({ type }) => type === ContactType.Organisation)
      .map(({ id, name }) => ({
        value: id,
        label: name,
      })) ?? [];

  return values.type === ContactType.Person ? (
    <SelectField
      name='organisationId'
      label={t('organisation')}
      options={options}
    />
  ) : null;
}
