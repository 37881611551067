import { VariantProps, cva } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';
import { JobStatus, Status } from '~/generated/graphql';

type StatusKey = keyof typeof Status | JobStatus;

type StatusVariant = {
  [key in StatusKey]: string;
};

export const statusBadgeStyles = cva(
  'transition-all flex items-center justify-center',
  {
    variants: {
      usedIn: {
        activityFeed:
          'w-fit rounded-md bg-opacity-10 px-1.5 md:px-1.5 font-medium pt-0 pb-0.5 leading-5',
        entityCard: 'md:w-[110px] h-6 w-6 md:h-auto md:text-sm',
        rhs: 'w-fit',
      },
      status: {
        Created: 'bg-status-created outline-1 outline outline-grey-20',
        Accepted: 'bg-status-active',
        Active: 'bg-status-active',
        Declined: 'bg-status-offered',
        Offered: 'bg-status-offered',
        Paused: 'bg-status-offered',
        Incomplete: 'bg-status-offered',
        InProgress: 'bg-status-inprogress',
        Complete: 'bg-status-complete',
        Cancelled: 'bg-status-cancelled',
        Deleted: 'bg-status-inactive',
        Inactive: 'bg-status-cancelled',
      } as StatusVariant,
    },
    compoundVariants: [
      {
        usedIn: ['entityCard', 'rhs'],
        className:
          'rounded-full px-2.5 font-semibold md:px-2.5 text-sm md:pt-0.5 md:pb-1 ',
      },
      {
        usedIn: ['entityCard', 'rhs', 'activityFeed'],
        status: JobStatus.Created,
        className: 'text-primary',
      },
      {
        usedIn: ['entityCard', 'rhs'],
        status: [
          JobStatus.Accepted,
          JobStatus.Complete,
          JobStatus.Declined,
          JobStatus.InProgress,
          JobStatus.Offered,
          JobStatus.Paused,
          JobStatus.Cancelled,
          JobStatus.Deleted,
          JobStatus.Incomplete,
          Status.Active,
          Status.Inactive,
        ],
        className: 'text-white',
      },
      {
        usedIn: ['activityFeed'],
        status: [
          JobStatus.Accepted,
          JobStatus.Complete,
          JobStatus.Declined,
          JobStatus.InProgress,
          JobStatus.Incomplete,
          JobStatus.Offered,
          JobStatus.Paused,
          Status.Active,
        ],
        className: 'text-primary',
      },
      {
        usedIn: ['activityFeed'],
        status: [JobStatus.Cancelled, JobStatus.Deleted, Status.Inactive],
        className: 'text-status-cancelled',
      },
    ],
  }
);

type Props = {
  prefix?: string;
  className?: string;
  lowerCase?: boolean;
  status: StatusKey;
} & VariantProps<typeof statusBadgeStyles>;

export interface StatusBadgeProps
  extends Omit<Props, 'usedIn'>,
    Required<Pick<Props, 'usedIn'>> {}

export function StatusBadge({
  className,
  usedIn,
  lowerCase,
  prefix,
  status,
  ...props
}: StatusBadgeProps) {
  const { t } = useTranslation('job');

  function getBadgeStatus(status: StatusKey) {
    if (usedIn === 'activityFeed') {
      return t('feed.status.' + status).toLowerCase();
    } else if (usedIn === 'entityCard') {
      return (
        <>
          <span className='hidden md:flex'>
            {prefix && t(prefix) + ' '}
            {lowerCase ? t(status).toLowerCase() : t(status)}
          </span>
          <span className='md:hidden'>
            {prefix && t(prefix) + ' '}
            {t(status).substring(0, 1)}
          </span>
        </>
      );
    } else return lowerCase ? t(status).toLowerCase() : t(status);
  }

  const BadgeStatus = getBadgeStatus(status);

  return (
    <div
      className={statusBadgeStyles({
        usedIn,
        status,
        className,
      })}
      {...props}
    >
      <span>
        {prefix && t(prefix) + ' '}
        {BadgeStatus}
      </span>
    </div>
  );
}
