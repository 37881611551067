import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SvgWarningIcon from '~/WarningIcon';
import { JobStatusBadge } from '~/components/ui/JobStatusBadge';
import { JobStatus, Status } from '~/generated/graphql';
import { Avatar } from './Avatar';

type CardSmallProps = {
  /** URL path to pass to router link */
  linkTo: string;
  /** If true will open item to new tab */
  newTab?: boolean;
  /** URL to an image file to pass to `Image` component */
  image?: string | null;
  /** If true will show placeholder if image is empty */
  deletable?: boolean;
  /** If true will display link to delete Item */
  onClick?: () => void;
  /** onClick action for deletable */
  showImage?: boolean;
  /** First row of text */
  titleText: string;
  /** Middle row of text */
  secondaryText: string | undefined;
  /** Bottom row of text */
  tertiaryText?: React.ReactNode;
  /** Hero content for the right side. Ignored if heroElement provided */
  hero?: { number?: number | string; directive: string };
  /** Hero element for more control over what's displayed on the right side */
  heroElement?: React.ReactNode;
  /** Status to display in badge */
  status?: Status | JobStatus;
  /** If true will display yellow background and warning Icon */
  warning?: boolean;
  /** Object representing an assigned user */
  user?: {
    name: string;
    image?: string | null;
  } | null;
};

export function CardSmall({
  linkTo,
  newTab,
  image,
  showImage,
  titleText,
  deletable,
  onClick,
  secondaryText,
  user,
  status,
  hero,
  warning,
  heroElement,
}: CardSmallProps) {
  return (
    <Link
      to={linkTo}
      target={newTab ? '_blank' : '_self'}
      className={classNames(
        'mb-2 flex w-auto flex-1 items-center gap-2 truncate rounded border border-grey-10 px-2.5 py-2 shadow-sm',
        { 'mx-3 cursor-pointer hover:shadow': linkTo },
        { 'bg-yellow-10': warning },
        { 'bg-white': !warning },
        { 'pointer-events-none': !linkTo },
        'mx-3 mb-2 flex items-center gap-2 rounded border border-grey-10 px-2.5 shadow-sm hover:shadow'
      )}
    >
      {showImage && (
        <div className='h-11 w-11'>
          <Avatar
            image={image}
            name={titleText}
            size='full'
            square
            className='mr-3 h-11 w-11 rounded-md'
          />
        </div>
      )}
      <div className='ml-1 flex flex-col items-start overflow-hidden text-base '>
        <p className='... max-w-full truncate text-base font-medium text-grey-70 lg:text-[15px] lg:leading-5'>
          {titleText}
        </p>
        <p className='... max-w-full truncate text-xs text-grey-40'>
          {secondaryText}
        </p>
      </div>
      {heroElement ? (
        <div className='ml-auto flex items-center gap-1'>
          {warning && <SvgWarningIcon className='mr-1 self-end pb-0.5' />}
          <div className='flex flex-col justify-start pr-1 text-right leading-tight'>
            {heroElement}
          </div>
          <div className='flex gap-2'>
            {user && (
              <Avatar image={user.image ?? ''} name={user.name} size='small' />
            )}
            {status && <JobStatusBadge small={true} value={status} />}
          </div>
        </div>
      ) : (
        <div className='ml-auto pr-1'>
          <p className='whitespace-nowrap text-sm font-medium'>
            <span className='text-base'>{hero?.number}</span> {hero?.directive}
          </p>
        </div>
      )}
      {deletable && onClick && (
        <button onClick={onClick}>
          <FontAwesomeIcon
            icon={faTrashCan}
            className='-mb-1 h-6 w-6 stroke-1 text-red-600 opacity-50 hover:opacity-100'
          />
        </button>
      )}
    </Link>
  );
}
