import { get } from 'lodash';
import { useState } from 'react';

export type FilterFunction<T = unknown> = (
  value: T,
  index: number,
  array: T[]
) => any;

export function useFilter() {
  const [filterFn, setFilterFn] = useState<FilterFunction | null>(null);

  const wrappedSetter = (fn: FilterFunction) =>
    setFilterFn((state: React.SetStateAction<FilterFunction | null>) => fn);

  return [filterFn, wrappedSetter];
}

export type Filters = {
  [path: string]: string[];
};

export type FilterFn = (obj: Filters) => void;

type Options = {
  initialFilters?: Filters;
};

export function useFilter2<T>(items: T[] | undefined, options?: Options) {
  const [filters, setFilters] = useState<Filters>(
    options?.initialFilters ?? {}
  );

  const filtered = items?.filter((item) => {
    return Object.keys(filters).every((path) => {
      if (!filters[path].length) return true;
      const val = get(item, path, false);
      return val && filters[path].indexOf(val) > -1;
    });
  });

  return {
    filtered,
    filters,
    filter: (obj: FilterInputObject) => {
      setFilters(flattenKeys(obj));
    },
  };
}

type FilterInputObject = {
  [path: string]: string[] | FilterInputObject;
};

function flattenKeys(obj: FilterInputObject | string[], path = ''): Filters {
  if (Array.isArray(obj)) return { [path.replace(/\.$/g, '')]: obj };

  return Object.keys(obj).reduce((prev, key) => {
    return { ...prev, ...flattenKeys(obj[key], path + key + '.') };
  }, {});
}

export type FilterHandler = ReturnType<typeof useFilter2>['filter'];
