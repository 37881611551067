import { useAuth } from '~/hooks/useAuth';
import { Link } from 'react-router-dom';
import EditIcon from './edit';

export const EditLink = () => {
  const { is } = useAuth();
  // const { pathname } = useLocation();
  // const editResource = pathname.split('/')[1] + '.edit'

  if (!is('operator')) {
    return null;
  }

  return (
    <Link to='edit' className='fill-copy-light inline-block'>
      <EditIcon className='fill-text-copy-nav hover:fill-brand' />
    </Link>
  );
};
