import { SVGProps } from 'react';

const SvgCard = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
    <g transform='translate(-305 -1203)'>
      <path
        d='M28.05,10.668H10.95a.282.282,0,0,0-.282.282v3.088a.282.282,0,0,0,.282.282h17.1a.282.282,0,0,0,.282-.282V10.95A.282.282,0,0,0,28.05,10.668ZM10.95,10a.95.95,0,0,0-.95.95v3.088a.95.95,0,0,0,.95.95h17.1a.95.95,0,0,0,.95-.95V10.95a.95.95,0,0,0-.95-.95Zm17.1,7.793H10.95a.282.282,0,0,0-.282.282v3.088a.282.282,0,0,0,.282.282h17.1a.282.282,0,0,0,.282-.282V18.075A.282.282,0,0,0,28.05,17.793Zm-17.1-.668a.95.95,0,0,0-.95.95v3.088a.95.95,0,0,0,.95.95h17.1a.95.95,0,0,0,.95-.95V18.075a.95.95,0,0,0-.95-.95Zm0,7.555h17.1a.282.282,0,0,1,.282.282V28.05a.282.282,0,0,1-.282.282H10.95a.282.282,0,0,1-.282-.282V24.963A.282.282,0,0,1,10.95,24.68Zm-.95.282a.95.95,0,0,1,.95-.95h17.1a.95.95,0,0,1,.95.95V28.05a.95.95,0,0,1-.95.95H10.95a.95.95,0,0,1-.95-.95Z'
        transform='translate(297.5 1195.5)'
        fill='none'
        strokeWidth={1}
        fillRule='evenodd'
      />
    </g>
  </svg>
);

export default SvgCard;
