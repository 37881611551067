import { SideLayout } from '~/layouts/side/SideLayout'
import { Button } from '~/components/ui/buttons/Button'
import styles from './CancelConfirmation.module.css'

export const CancelConfirmation = ({ onConfirm }) => {
  return (
    <SideLayout>
      <SideLayout.Head
        leftSlot={
          <div
            className='x'
            style={{ position: 'absolute', marginLeft: '1em' }}
            onClick={() => onConfirm(false)}
          />
        }
      >
        Cancel your changes?
      </SideLayout.Head>
      <SideLayout.Body>
        <div className={styles.body}>
          <p className={styles.message}>
            Are you sure you want to cancel this form and lose the changes?
          </p>
        </div>
      </SideLayout.Body>
      <SideLayout.Foot>
        <div className='flex p-4'>
          <div className='mr-auto'>
            <Button type='button' onClick={() => onConfirm(false)}>
              No, stay with task
            </Button>
          </div>
          <div>
            <Button type='button' onClick={() => onConfirm(true)}>
              Yes, cancel
            </Button>
          </div>
        </div>
      </SideLayout.Foot>
    </SideLayout>
  )
}
