import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { spaceSkuRemove } from '~/api/assets'
import { ItemDetail } from '~/components/asset-config/ItemDetail'
import { ActionForm } from '~/components/form/ActionForm'
import { Card } from '~/components/ui/Card'

const RemoveItemCard = ({ remove }) => {
  return (
    <>
      <Card color='smoke' shadow>
        <div style={{ padding: '1rem' }}>
          <ItemDetail item={remove} />
        </div>
      </Card>
      {remove.soh !== 0 && (
        <div
          className='mb-4 text-xs font-semibold'
          style={{ marginTop: '-1rem', color: 'var(--color-red)' }}
        >
          On Hand must be 0 to remove
        </div>
      )}
    </>
  )
}

export const RemoveItems = ({ selected, data, onSuccess }) => {
  const { t } = useTranslation('config')
  const remove = Object.keys(selected).reduce((arr, id) => {
    const row = data.find((o) => o.id === id)
    if (row && row.space_sku_id !== null && row.status === 'active') {
      return arr.concat(row)
    }
    return arr
  }, [])

  return (
    <ActionForm
      // action={[
      //   {
      //     key: 'queue',
      //     action: spaceSkuRemove('queue'),
      //     buttonText: t('queue'),
      //   },
      //   {
      //     key: 'commit',
      //     action: spaceSkuRemove('commit'),
      //     buttonText: t('commit'),
      //   },
      // ]}
      action={spaceSkuRemove('commit')}
      title={t('removeItems')}
      initialValues={{
        remove,
      }}
      enableReinitialize
      onSuccess={onSuccess}
      buttonDisabled={remove.length < 1}
      buttonText='Remove'
    >
      <Field name='remove'>
        {({ form }) => (
          <>
            {form.values.remove.length < 1 && (
              <p className='pt-8 text-center'>No items selected</p>
            )}
            {form.values.remove.map((row) => (
              <RemoveItemCard key={row.id} remove={row} />
            ))}
          </>
        )}
      </Field>
    </ActionForm>
  )
}
