import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '~/App';
import { useLocalStorage } from '~/hooks';
import { useBreakpoint } from '~/hooks/useBreakpoint';

// ? commented out because browser router does not have access to context for abilities
// export function loader({ params }: LoaderFunctionArgs) {
//   // ! browser only
//   const isMobile = window.innerWidth < 1024;

//   return params.view === undefined
//     ? redirect(isMobile ? '/jobs/scheduled' : '/jobs/scheduled')
//     : null;
// }

export default function JobsIndexRoute() {
  const navigate = useNavigate();
  const { ability } = useAppContext();
  const { isMobile } = useBreakpoint();
  const localStorage = useLocalStorage();
  const { hash } = useLocation();

  const navigateTo = localStorage.get('nav.jobs') ?? '/jobs/scheduled';

  useEffect(() => {
    navigate(
      (!isMobile &&
      ability.can('use', 'feat.jobs.default-to-calendar') &&
      ability.can('read', 'feat.schedule_view')
        ? '/jobs/calendar'
        : navigateTo) + hash
    );
  }, []);

  return null;
}
