import { Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { PromptEffect } from '~/components/Prompt';
import { DateTimeField } from '~/components/form/DateTimeField';
import { StockSelectionField } from '~/components/form/StockSelectionField';
import { SubmitButton } from '~/components/form/SubmitButton';
import { TextAreaField } from '~/components/form/textarea/TextArea';
import { Context } from '~/components/smc/card/Hero';
import {
  StockSelection,
  useAdjustStockMutation,
  useSpaceSkusQuery,
  useStockAdjustmentTypesQuery,
} from '~/generated/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { Selected } from '.';
import { ComboboxField } from '../../components/ui/Combobox';

type Props = {
  selected: Selected;
  data: {
    id: string;
    space_id: number;
    sku_id: number;
    status: string;
    queued_type: string | null;
  }[];
  onSuccess: () => void;
  onClose: () => void;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  date: Yup.string().required('Required'),
  reason: Yup.string().required('Required'),
});

export const AdjustStock = ({ selected, data, onSuccess, onClose }: Props) => {
  const ids = useMemo(
    () =>
      Object.keys(selected).reduce((acc: string[], key) => {
        const [selectionType] = key.split(':');
        const item = data.find((d) => d.id === key);
        const isActive =
          item?.status === 'active' || item?.queued_type === 'Remove';

        return selectionType === 'space_sku' && isActive
          ? [...acc, `${item.space_id}:${item.sku_id}`]
          : acc;
      }, []),
    [selected, data]
  );

  const { t } = useTranslation();

  const [adjustmentTypes] = useStockAdjustmentTypesQuery({
    requestPolicy: 'cache-and-network',
    variables: {},
  });
  const adjustmentOptions = adjustmentTypes.data?.stockAdjustmentTypes ?? [];

  const [result] = useSpaceSkusQuery({
    variables: { ids },
    requestPolicy: 'cache-and-network',
    pause: !ids.length,
  });

  const [, adjustStock] = useAdjustStockMutation();

  const initialValues = {
    date: new Date(),
    reason: '',
    notes: '',
    adjustments: [] as StockSelection[],
  };
  const handleSubmit = async (values: typeof initialValues) => {
    try {
      // Ensure entered value is still a selected SpaceSku
      const adjustments = values.adjustments.filter(({ id }) =>
        ids.includes(id)
      );

      const { error } = await adjustStock({
        input: {
          date: values.date.toISOString(),
          reason: values.reason,
          notes: values.notes,
          adjustments,
        },
      });

      if (error) {
        throw error;
      }
      toast.success(t('Success'));
      onSuccess();
    } catch (err: any) {
      toast.error(err.message.replace('[GraphQL] ', ''));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
    >
      <SideLayout>
        <PromptEffect />
        <SideLayout.Head onClose={onClose} iconClass='chevron-left'>
          {'Adjust Stock'}
        </SideLayout.Head>
        <SideLayout.Body className=''>
          <div className='px-4'>
            <DateTimeField name='date' />
            <ComboboxField
              name='reason'
              label={t('adjustmentType')}
              items={adjustmentOptions}
            />
            <TextAreaField name='notes' label={t('notes')} />
          </div>
          {Object.keys(selected).length < 1 && (
            <div className='pt-20 text-center'>No items selected</div>
          )}
          <StockSelectionField
            context={Context.ADJUSTMENT}
            name='adjustments'
            location={ids[0]}
            items={result.data?.spaceSkus}
            moreFilters={false}
          />
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
};
