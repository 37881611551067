import classNames from 'classnames';
import { groupBy } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '~/components/FindAddContact/SearchInput';
import { Button } from '~/components/form/SubmitButton';
import { Dialog } from '~/components/ui/Dialog';
import { unique } from '~/helpers/array';
import { upperCaseFirst } from '~/helpers/string';

type Attribute = {
  id: string;
  type: string;
  category?: string | null;
  condition?: string | null;
  name: string;
  value: string[];
};

type Props = {
  integration?: string;
  attributes: Attribute[];
  locationAttributes?: Attribute[] | null;
  initialValue?: string[] | null;
  onChange: (selected: string[]) => void;
  /** if true will enable select from all attributes, instead of those scoped to location */
  globalSelect?: boolean;
  /** If true, integration attributes will be removed from the selection */
  isAttributesTask?: boolean;
};

export const PropFit = 'PropFit';

export function AttributesDialog({
  integration,
  attributes,
  locationAttributes,
  initialValue,
  onChange,
  globalSelect,
  isAttributesTask,
}: Props) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>([]);
  const [lastSelected, setLastSelect] = useState<string>('');
  const [isShiftKeyDown, setIsShiftKeyDown] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(PropFit);

  const [query, setQuery] = useState('');
  const [show, setShow] = useState(false);
  const local = locationAttributes?.reduce(
    (acc: Record<string, string[]>, attribute) => {
      if (attribute.name) {
        acc[attribute.name] = attribute.value;
      }
      return acc;
    },
    {}
  );
  const filtered =
    query === ''
      ? attributes
      : attributes.filter(({ category, name }) =>
          `${category}${name}`
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const groupedByIntegration = groupByIntegration(integration, filtered);

  const filteredGroup = groupedByIntegration[tab] ?? [];

  const allFilteredSelected = filteredGroup.every((attr) =>
    selected.includes(attr.id)
  );

  const groupedByCategory = groupBy(
    filteredGroup,
    (attribute) => attribute.category ?? 'General'
  );

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = () => {
    onChange(selected);
    handleClose();
  };

  const getNextValue = (value: string) => {
    const hasBeenSelected = !selected.includes(value);

    if (isShiftKeyDown) {
      const newSelectedItems = getNewSelectedItems(value);
      const selections = [...new Set([...selected, ...newSelectedItems])];

      if (!hasBeenSelected) {
        return selections.filter((item) => !newSelectedItems.includes(item));
      }

      return selections;
    }

    // if it's already in there, remove it, otherwise append it
    return selected.includes(value)
      ? selected.filter((attr) => attr !== value)
      : [...selected, value];
  };

  const getNewSelectedItems = (value: string) => {
    const currentSelectedIndex = filtered.findIndex(
      (attr) => attr.id === value
    );
    const lastSelectedIndex = filtered.findIndex(
      (attr) => attr.id === lastSelected
    );

    return filtered
      .slice(
        Math.min(lastSelectedIndex, currentSelectedIndex),
        Math.max(lastSelectedIndex, currentSelectedIndex) + 1
      )
      .map((item) => item.id);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const nextValue = getNextValue(value);

    setSelected(nextValue);
    setLastSelect(value);
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      setIsShiftKeyDown(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      setIsShiftKeyDown(true);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setSelected(initialValue || []);
  }, [show]);

  return (
    <>
      <Dialog title='Select Attributes' onClose={handleClose} show={show}>
        <div className='max-h-[calc(100vh-20rem)] overflow-y-auto px-3 pt-3'>
          {attributes && attributes.length > 0 && (
            <div className='mb-2 flex'>
              {Object.keys(groupedByIntegration).length > 0
                ? Object.keys(groupedByIntegration).map((key) => {
                    if (isAttributesTask && key !== PropFit) return null;
                    return (
                      <div
                        className={classNames(
                          'flex h-12 flex-1 cursor-pointer flex-col items-center justify-center text-center text-sm font-normal not-italic leading-[normal] text-[#007070]',
                          tab === key &&
                            'border-b-[3px] border-brand font-semibold'
                        )}
                        onClick={() => setTab(key)}
                      >
                        <span className='capitalize'>{key}</span>
                      </div>
                    );
                  })
                : null}
            </div>
          )}
          <div className='mb-1'>
            <SearchInput setQuery={setQuery} query={query} />
          </div>
          {!attributes.length && (
            <div className='p-4'>No attributes for this location.</div>
          )}
          {filteredGroup.length > 0 && (
            <label className='relative block break-words py-1.5 pl-6 text-left'>
              <input
                className={classNames(
                  'absolute left-0 top-2.5 h-4 w-4 cursor-pointer accent-brand',
                  {
                    hidden: allFilteredSelected,
                  }
                )}
                type='checkbox'
                onChange={() =>
                  allFilteredSelected
                    ? setSelected(
                        [...selected].filter(
                          (attr) =>
                            !filteredGroup.map(({ id }) => id).includes(attr)
                        )
                      )
                    : setSelected(
                        unique([
                          ...selected,
                          ...filteredGroup.map((attr) => attr.id),
                        ])
                      )
                }
                checked={false}
                id='attributes-dialog-select-all'
              />
              <label
                className={classNames(
                  'absolute left-0 top-2.5 flex h-4 w-4 cursor-pointer select-none items-center justify-center rounded-[2px] border border-solid border-white bg-brand pb-[3px] text-xl text-white',
                  {
                    hidden: !allFilteredSelected,
                  }
                )}
                htmlFor='attributes-dialog-select-all'
              >
                -
              </label>
              {t('selectAll')}
            </label>
          )}
          {/* {filteredGroup.length > 0
            ? filteredGroup.map(({ id, category, name }) => (
                <label
                  className='relative block break-words py-1.5 pl-6 text-left'
                  key={id}
                >
                  <input
                    className='absolute left-0 top-2.5 h-4 w-4 cursor-pointer accent-brand'
                    type='checkbox'
                    value={id}
                    checked={selected.includes(id)}
                    onChange={handleChange}
                  />
                  <span
                    className={classNames('', {
                      'select-none': isShiftKeyDown,
                    })}
                  >
                    {(category ? `${category} \\ ` : '') + name}
                  </span>
                </label>
              ))
            : null} */}
          {Object.keys(groupedByCategory).map((key, idx) => (
            <Fragment key={key}>
              <div className='pb-1 pt-3 text-left text-base font-semibold text-primary'>
                {key}
              </div>
              {groupedByCategory[key].map(({ id, name }) => (
                <label
                  className='relative block break-words py-1.5 pl-6 text-left'
                  key={id}
                >
                  <input
                    className='absolute left-0 top-2.5 h-4 w-4 cursor-pointer accent-brand'
                    type='checkbox'
                    value={id}
                    checked={selected.includes(id)}
                    onChange={handleChange}
                  />
                  <span
                    className={classNames('', {
                      'select-none': isShiftKeyDown,
                    })}
                  >
                    {name}
                  </span>
                </label>
              ))}
            </Fragment>
          ))}
          <div className='sticky bottom-0 flex justify-end gap-3 bg-white pb-3'>
            <button
              type='button'
              className='px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand'
              onClick={handleClose}
            >
              {t('cancel')}
            </button>

            <Button
              type='button'
              intent='primary'
              size='small'
              onClick={handleSave}
            >
              {t('ok')}
            </Button>
          </div>
        </div>
      </Dialog>
      <div className='mb-2 ml-1'>
        <Button onClick={() => setShow(true)} intent='text' size='small'>
          Select {t('site')} Attributes
        </Button>
      </div>
    </>
  );
}

export function groupByIntegration(
  integration: string | undefined,
  attributesList: Attribute[]
) {
  return groupBy(attributesList, ({ id }) => {
    return integration && id.startsWith('integration_')
      ? `${
          integration.length > 3
            ? upperCaseFirst(integration)
            : integration.toUpperCase()
        }`
      : PropFit;
  });
}
