import { useState } from 'react';
// import DateRangeField from '~/components/form/DateRangeField';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { AuthGate } from '~/components/AuthGate';
import { Split } from '~/components/Split';
import { Filter } from '~/components/ui/FilterGroup';
import { FilterGroupSearch } from '~/components/ui/FilterGroupSearch';
import { Result } from '~/components/ui/Result';
import { VirtualList } from '~/components/ui/VirtualList';
import {
  DeploymentsQuery,
  Status,
  useDeploymentsQuery,
} from '~/generated/graphql';
import { formatDate } from '~/helpers/formatDate';
import parseFilters from '~/helpers/parseFilters';
import { Filters } from '~/hooks/useFilter';
import { useFilterOptions } from '~/hooks/useFilterOptions';
import { useReload } from '~/hooks/useReload';
import { useSearch } from '~/hooks/useSearch';
import { AssetsNav } from '~/layouts/nav/AssetsNav';

type Deployment = DeploymentsQuery['deployments'][0];

const SEARCH_OPTIONS = {
  keys: [
    'status',
    'location.name',
    'space.name',
    'asset.type',
    'asset.name',
    'state',
  ],
};

const createGetListItemProps =
  (selected?: string) => (deployment: Deployment) => ({
    id: deployment.id,
    linkTo: `/deployments/${deployment.id}`,
    titleText: deployment.asset?.name,
    secondaryText: deployment.asset.type,
    tertiaryText: deployment.displayLocation,
    heroElement: (
      <>
        <p className='... max-w-[20vw] truncate text-xs sm:max-w-none md:text-sm'>
          <span className='hidden sm:inline-block'>Deployed</span>{' '}
          {formatDate(deployment.startedAt, 'PP')}
        </p>
        {/* Returns null even when deployment has ended  */}
        {deployment.endedAt && (
          <p>Undeployed {formatDate(deployment.endedAt, 'PP')}</p>
        )}
      </>
    ),
    showStatus: true,
    status: deployment.status,
    image: deployment.image,
    active: Boolean(selected === deployment.id.toString()),
  });

export const Deployments = () => {
  const { t } = useTranslation();
  const [{ data }, reload] = useDeploymentsQuery();

  useReload(reload);

  const applyFilters = (
    data: DeploymentsQuery['deployments'],
    filters: Filters
  ) => {
    return data.filter((deployment) => {
      const { asset } = deployment;
      return (
        (!filters['status'] || filters['status'].includes(deployment.status)) &&
        (!filters['asset.type'] ||
          filters['asset.type'].includes(asset.type || '')) &&
        (!filters['asset.name'] ||
          filters['asset.name'].includes(asset.name || '')) &&
        (!filters['location.name'] ||
          filters['location.name'].includes(deployment.location.name || '')) &&
        (!filters['space.name'] ||
          filters['space.name'].includes(deployment.space.name || '')) &&
        (!filters['state'] || filters['state'].includes(deployment.state || ''))
      );
    });
  };

  const [filters, setFilters] = useState<Filters>({});
  const { results, search } = useSearch(data?.deployments, SEARCH_OPTIONS);
  const filtered = applyFilters(results || [], filters);
  const match = useMatch('/deployments/:id');

  const listProps = {
    data: filtered,
    getListItemProps: createGetListItemProps(match?.params.id),
    scrollTo: match?.params.id ? parseInt(match.params.id) : undefined,
  };

  return (
    <AuthGate action='manage' subject='feat.assets'>
      <Split
        nav={<AssetsNav />}
        filters={
          <DeploymentFilters
            data={results ?? []}
            onSubmit={({ query, filters }) => {
              search(query);
              setFilters(filters);
            }}
          />
        }
        main={
          <>
            <Result count={filtered?.length} />
            <VirtualList {...listProps} />
          </>
        }
      />
    </AuthGate>
  );
};

type DeploymentFiltersProps = {
  data: DeploymentsQuery['deployments'];
  onSubmit: (values: {
    query: string;
    filters: ReturnType<typeof parseFilters>;
  }) => void;
};

const DeploymentFilters = ({ data, onSubmit }: DeploymentFiltersProps) => {
  const { t } = useTranslation();
  const [options] = useFilterOptions(data ?? [], [
    { name: 'status', path: 'status' },
    { name: 'location.name', path: 'location.name' },
    { name: 'space.name', path: 'space.name' },
    { name: 'asset.type', path: 'asset.type' },
    { name: 'asset.name', path: 'asset.name' },
    { name: 'state', path: 'state' },
  ]);
  const [searchValue, setSearchValue] = useState('');
  const [filtersValue, setFiltersValue] = useState(
    new URLSearchParams([['status', Status.Active]])
  );

  const filters: Filter[] = [
    {
      name: 'status',
      label: t('status'),
      options: Object.values(Status).map((value) => ({
        value,
        label: t(`statuses.${value}`),
      })),
      searchable: false,
      type: 'select',
    },
    options['location.name']?.length > 1 && {
      name: 'location.name',
      label: t('location'),
      options: options['location.name'],
      type: 'select',
    },
    options['space.name']?.length > 1 && {
      name: 'space.name',
      label: t('space'),
      options: options['space.name'],
      type: 'select',
    },
    options['asset.type']?.length > 1 && {
      name: 'asset.type',
      label: t('assetType'),
      options: options['asset.type'],
      type: 'select',
    },
    options['asset.name']?.length > 1 && {
      name: 'asset.name',
      label: t('assetName'),
      options: options['asset.name'],
      type: 'select',
    },
    options.state?.length > 1 && {
      name: 'state',
      label: t('state'),
      options: options.state,
      type: 'select',
    },
  ];

  return (
    <FilterGroupSearch
      filters={filters}
      value={{ searchValue, filtersValue }}
      onChange={({ searchValue, filtersValue }) => {
        setSearchValue(searchValue);
        setFiltersValue(filtersValue);
        onSubmit({ query: searchValue, filters: parseFilters(filtersValue) });
      }}
      placement={'portal'}
    />
  );
};
