import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Helper for toggling a location hash, same usage as useState except initial value replaced with the hash value to match
 */
export function useLocationHash(value: string) {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const state = hash.startsWith(value);
  const setState = (toggled: boolean) => navigate(toggled ? value : '#!');

  return [state, setState] as [typeof state, typeof setState];
}
