import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '~/components/form/SubmitButton';
import {
  useEndTaskMutation,
  usePauseTaskMutation,
  useResetTaskStatusMutation,
  useResumeTaskMutation,
  useStartTaskMutation,
} from '~/generated/graphql';
import { JobStatus } from '~/gql/graphql';
import { StartJobButton } from '../JobView';

export function StartTaskButton({ id }: { id: string }) {
  const { t } = useTranslation('job');
  const [, start] = useStartTaskMutation();

  return (
    <Button type='button' onClick={() => start({ id, startTime: new Date() })}>
      {t('startTask')}
    </Button>
  );
}

export function PauseTaskButton({ id }: { id: string }) {
  const { t } = useTranslation('job');
  const [, pause] = usePauseTaskMutation();

  return (
    <Button
      type='button'
      onClick={() => pause({ id, pauseTime: new Date() })}
      intent='secondary'
    >
      {t('pauseTask')}
    </Button>
  );
}

export function ResumeTaskButton({ id }: { id: string }) {
  const { t } = useTranslation('job');
  const [, resume] = useResumeTaskMutation();

  return (
    <Button
      type='button'
      onClick={() => resume({ id, resumeTime: new Date() })}
    >
      {t('resumeTask')}
    </Button>
  );
}

function DeferTaskButton({ id }: { id: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation('job');
  const [, end] = useEndTaskMutation();

  return (
    <Button
      type='button'
      onClick={async () => {
        await end({ id, endTime: new Date() });
        navigate(-1);
      }}
      intent='secondary'
    >
      {t('quitTask' /* Defer task */)}
    </Button>
  );
}

export function ResetTaskStatusButton({
  id,
  jobStatus,
  onReset,
}: {
  id: string;
  jobStatus: JobStatus;
  onReset?: () => void;
}) {
  const { t } = useTranslation('job');
  const [{ fetching }, reset] = useResetTaskStatusMutation();

  if (jobStatus !== JobStatus.InProgress) {
    return null;
  }

  return (
    <Button
      type='button'
      onClick={async () => {
        await reset({ id });

        // if (taskViewContext?.revalidate) {
        //   taskViewContext?.revalidate();
        //   return;
        // }

        onReset?.();
      }}
      loading={fetching}
      intent='secondary'
    >
      {t('resetStatus')}
    </Button>
  );
}

type WorkflowButtonsProps = {
  id: string;
  jobStatus: JobStatus;
  taskStatus: JobStatus;
  onReset?: () => void;
  // TODO make this default behaviour and remove the prop
  startJobId?: string;

  /** children should contain the appropriate submit button for completing the task */
  children: React.ReactNode;
};

export function WorkflowButtons({
  children,
  id,
  jobStatus,
  taskStatus,
  onReset,
  startJobId,
}: WorkflowButtonsProps) {
  if (startJobId && jobStatus !== JobStatus.InProgress) {
    return <StartJobButton id={startJobId} />;
  }

  if ([JobStatus.Complete, JobStatus.Incomplete].includes(taskStatus)) {
    return (
      <>
        <ResetTaskStatusButton
          id={id}
          jobStatus={jobStatus}
          onReset={onReset}
        />
      </>
    );
  }

  return (
    <>
      {children}
      <DeferTaskButton id={id} />
    </>
  );
}
