import { Tab } from '@headlessui/react';
import { startCase } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Attributes } from '~/components/ui/Attributes';
import { Gallery } from '~/components/ui/Gallery';
import { ReadValue } from '~/components/ui/ReadValue';
import { getFragmentData } from '~/gql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useAssets } from '../AssetSelect';
import { DeploymentFieldsFragment } from '../AssetSelect/AssetSelect';
import { Loading } from '../ui/Loading';
import { StatusBadge } from '../ui/StatusBadge';
import { TabHeader } from '../ui/TabHeader';
import { AssetMenu } from './AssetMenu';
import { AssetAttachments } from './tabs/AssetAttachments';
import { AssetDeployments } from './tabs/AssetDeployments';
import { AssetJobs } from './tabs/AssetJobs';

type Props = {
  id?: string;
  isPopup?: boolean;
};

export function AssetView({ id, isPopup }: Props) {
  const [assets] = useAssets();
  const navigate = useNavigate();
  const location = useLocation();
  const { siteId, assetId } = useParams();

  const asset = assets.find((asset) => asset.id === (isPopup ? id : assetId));
  const deployment = getFragmentData(
    DeploymentFieldsFragment,
    asset?.deployment
  );

  return (
    <SideLayout isPopup={isPopup}>
      {!isPopup && (
        <SideLayout.Head
          rightSlot={<AssetMenu asset={asset} />}
          onClose={() => navigate(siteId ? '..' : '/assets')}
        >
          {asset?.name}
        </SideLayout.Head>
      )}
      <SideLayout.Body>
        {!asset ? (
          <div className='flex justify-center'>
            <Loading spinner />
          </div>
        ) : (
          <>
            <StatusBadge value={asset.status} rounded />
            <ReadValue label='Model'>
              {asset.model && asset.model.displayName}
            </ReadValue>
            <ReadValue label='assetType'>{startCase(asset.type)}</ReadValue>
            {/* @ts-expect-error FIXME */}
            <Attributes data={asset} />
            <ReadValue label='notes'>{asset.notes}</ReadValue>

            {/* Here it would be good to have access to the asset model image, and have the asset deployment image return empty if there is none.  */}
            <Gallery
              images={deployment?.image ? deployment.image : asset.image}
            />

            {!location.pathname.startsWith('/sites') && (
              <div className='mb-4 w-auto rounded-t-2xl border border-grey-20'>
                <Tab.Group>
                  <Tab.List className='bg-blue-900/20 flex overflow-hidden rounded-t-2xl'>
                    <TabHeader title='Jobs' />
                    <TabHeader title='Deployments' />
                    <TabHeader title='Files' />
                  </Tab.List>
                  <Tab.Panels className=''>
                    <Tab.Panel className='relative flex min-h-[20rem] flex-col bg-grey-2'>
                      <AssetJobs assetId={asset.id} />
                    </Tab.Panel>
                    <Tab.Panel className='relative flex min-h-[20rem] flex-col bg-grey-2'>
                      <AssetDeployments assetId={asset.id} />
                    </Tab.Panel>
                    <Tab.Panel className='relative flex min-h-[20rem] flex-col bg-grey-2'>
                      <AssetAttachments assetId={asset.id} />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            )}
          </>
        )}
      </SideLayout.Body>
    </SideLayout>
  );
}

// function Tab(props: { children: React.ReactNode; to: string }) {
//   return (
//     <NavLink
//       to={props.to}
//       className={({ isActive }) =>
//         classNames([
//           'block w-full border-b border-t py-2.5 text-center text-[15px] font-medium leading-5',
//           'ring-offset-blue-400 ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2',
//           isActive
//             ? 'border border-brand bg-brand text-white shadow'
//             : 'hover:black hover:bg-brand/[0.12] border-r border-grey-20 text-grey-70 first:border-l-0 last:border-r-0',
//         ])
//       }
//       end
//     >
//       {props.children}
//     </NavLink>
//   );
// }
