import { faTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { useBreakpoint } from '~/hooks/useBreakpoint';

export function ColorPicker({
  label,
  hexValue,
  onChange,
  randomizeColor = true,
}: {
  label: string;
  hexValue: string;
  onChange: (hexValue: string) => void;
  randomizeColor?: boolean;
}) {
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (!randomizeColor) return;

    const generateRandomHex = () =>
      [...Array(6)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join('');

    onChange(`#${generateRandomHex()}`);
  }, []);

  return (
    <>
      <p className='mb-[2px] ml-1 text-sm font-medium text-grey-50'>{label}</p>
      <div
        className={classNames('flex', isMobile ? 'flex-col gap-1' : 'gap-3')}
      >
        <div className='relative h-fit w-full'>
          <input
            className='input w-full rounded border border-grey-20 py-2.5 pl-10 pr-3 focus-visible:border-grey-40 focus-visible:outline-none'
            value={`#${hexValue}`}
            onChange={(e) => onChange(e.target.value)}
          />
          <div className='absolute top-0 flex h-full items-center pl-4'>
            <FontAwesomeIcon
              color={`#${hexValue}`}
              className='mr-3'
              icon={faTag}
            />
          </div>
        </div>

        <div className='w-full rounded border border-grey-20'>
          <SketchPicker
            width=''
            color={hexValue}
            onChangeComplete={(c) => onChange(c.hex)}
          />
        </div>
      </div>
    </>
  );
}
