import { ReactNode, createElement } from 'react';
import classNames from 'classnames/bind';
import styles from './Button.module.css';

const cx = classNames.bind(styles);

type Props = {
  as?: any;
  color?: 'default' | 'primary' | 'text' | 'delete';
  size?: 'small';
  rounded?: boolean;
  outlined?: boolean;
  loading?: boolean;
  children?: ReactNode;
  [x: string]: any;
};

/**
 * Use `Button` to apply button styling to a specified element type.
 *
 * Will accept a component as the element type, useful for router links.
 */
export const Button = ({
  as = 'button',
  color = 'default',
  size,
  rounded = false,
  outlined = false,
  loading = false,
  ...props
}: Props) =>
  createElement(as, {
    ...props,
    className: cx(props.className, 'button', color, size, {
      rounded,
      outlined,
      loading,
    }),
  });
