import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { FileLink } from '~/components/FileLink';
import { FragmentType, getFragmentData, graphql } from '~/gql';
import { Gallery } from './Gallery';
import { Linkify } from './Linkify';

const Feed_CommentFragment = graphql(/* GraphQL */ `
  fragment Feed_Comment on Comment {
    id
    body
    createdAt
    isRead
    isFlagged
    createdBy {
      id
      name
    }
    attachments {
      id
      kind
      thumb
      url
      originalFilename
    }
    tags {
      id
      entityType
      category
      name
      description
      colour
    }
  }
`);

type Props = {
  comment: FragmentType<typeof Feed_CommentFragment>;
  highlight?: boolean;
};

export function Comment({ comment, highlight }: Props) {
  const item = getFragmentData(Feed_CommentFragment, comment);
  const [bg, setBackground] = useState('bg-white');

  useEffect(() => {
    if (highlight) {
      setBackground('bg-yellow-10');
    }
    setTimeout(
      () => setBackground('bg-white transition-colors duration-1000'),
      1300
    );
  }, [highlight]);

  // TODO use <Files /> component instead
  const files = item.attachments
    ?.filter(({ kind }) => kind === 'file')
    .map(({ id, originalFilename, url }) => (
      <FileLink key={id} url={url} originalFilename={originalFilename} />
    ));

  const gallery = useMemo(
    () => item.attachments && <Gallery attachments={item.attachments} />,
    []
  );

  return (
    <div className={classNames('relative w-full', bg)}>
      <pre className='w-5/6 whitespace-pre-line font-sans leading-6'>
        <Linkify>{item.body}</Linkify>
      </pre>
      {files}
      {gallery}
    </div>
  );
}
