import { FilterForm, FilterProps } from '~/components/filter/FilterForm';
import { SelectField } from '~/components/form/downshift/SelectField';
import { StatusSelectField } from '~/components/form/StatusSelectField';
import { useFormikContext } from 'formik';
import { LocationType, useLocationsQuery } from '~/generated/graphql';
import { unique } from '~/helpers/array';
import { useTranslation } from 'react-i18next';

export const SpaceFilters = (props: FilterProps) => {
  // const [locationOptions, setLocationOptions] = useState<Option[]>([]);

  return (
    <FilterForm
      cols='5'
      initialValues={{
        status: ['Active'],
        locationType: ['Site'],
        locationName: [],
        name: [],
      }}
      {...props}
    >
      <StatusSelectField />
      {/* <AggregateOptionsEffect
        locations={locations}
        setLocationOptions={setLocationOptions}
      /> */}
      {/* <SelectField
        floating
        name='locationType'
        label={t('locationType')}
        placeholder={t('locationType')}
        options={['Asset', 'Contact', 'Site']
          .map((value) => ({ value, label: t('type.' + value) }))
          .sort((a, b) => a.label.localeCompare(b.label))}
        multiple
      /> */}
      <LocationSelect />
      {/* <SelectField floating name='name' label={t('space')} options={spaces} multiple /> */}
    </FilterForm>
  );
};

function LocationSelect() {
  const {
    values: { locationType },
  } = useFormikContext<{ locationType: string[] }>();
  const { t } = useTranslation();
  const [result] = useLocationsQuery();
  const { data } = result;
  const options = data
    ? unique(
        data.locations
          .filter(({ __typename }) =>
            locationType.length ? locationType.includes(__typename) : true
          )
          .map(({ name: value }) => value)
      ).map((value) => ({
        value,
        label: value,
      }))
    : [];

  return (
    <SelectField
      floating
      name='locationName'
      label={t('location')}
      placeholder={t('location')}
      options={options}
      multiple
    />
  );
}

// type AggregateOptionsEffectProps = {
//   locations: OptionGroup[];
//   setLocationOptions: React.Dispatch<React.SetStateAction<Option[]>>;
// };

// const AggregateOptionsEffect = ({
//   locations,
//   setLocationOptions,
// }: AggregateOptionsEffectProps) => {
//   const formik = useFormikContext<{ locationType: string[] }>();

//   useEffect(() => {
//     setLocationOptions(
//       locations
//         .filter(({ label }) => {
//           const type = camelCase(label);

//           return formik.values.locationType.length
//             ? formik.values.locationType.includes(type)
//             : true;
//         })
//         .flatMap(({ label: group, options }) =>
//           options.map(({ label }) => ({
//             value: label,
//             label,
//           }))
//         )
//         .filter(
//           ({ value }, index, self) =>
//             self.findIndex((opt) => opt.value === value) === index
//         )
//     );
//   }, [formik.values, locations, setLocationOptions]);

//   return null;
// };
