import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { AuthGate } from '~/components/AuthGate';
import { FloatingActionButton } from '~/components/FloatingActionButton';
import { ListDetailView } from '~/components/ListDetailView';
import { AssetConfigTable } from '~/components/asset-config/AssetConfigTable';
import { FilterProps } from '~/components/filter/FilterForm';
import { StockManipulationComponent } from '~/components/smc/StockManipulationComponent';
import { TransferStock } from '~/components/stock/TransferStock';
import { Result } from '~/components/ui/Result';
import { withModal } from '~/components/withModal';
import { AssetsNav } from '~/layouts/nav/AssetsNav';
import { SitesNav } from '~/layouts/nav/SitesNav';
import { StockNav } from '~/layouts/nav/StockNav';
import RotatePhone from '../../RotatePhone';
import { AddItems } from './AddItems';
import { AddSpaces } from './AddSpaces';
import { AdjustStock } from './AdjustStock';
import { AssetConfigActions } from './AssetConfigActions';
import { AssetConfigFilterForm } from './AssetConfigFilterForm';
import { CloneSpaces } from './CloneSpaces';
import { CommitChanges } from './CommitChanges';
import { ModifyProperties } from './ModifyProperties';
import { RemoveItems } from './RemoveItems';
import { UnqueueChanges } from './UnqueueChanges';

const ModifyPropertiesModal = withModal(ModifyProperties);
const RemoveItemsModal = withModal(RemoveItems);
const AddItemsModal = withModal(AddItems);
const UnqueueChangesModal = withModal(UnqueueChanges);
const CommitChangesModal = withModal(CommitChanges);
const AddSpacesModal = withModal(AddSpaces);
const CloneSpacesModal = withModal(CloneSpaces);
const AdjustStockModal = withModal(AdjustStock);
const SMCModal = withModal(StockManipulationComponent);
const TransferStockModal = withModal(TransferStock);

export type ConfigProps = {
  locationType?: 'Asset' | 'Site';
};

export const AssetConfig = ({ locationType }: ConfigProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Selected>({});
  const [onlySelected, setOnlySelected] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const { data, mutate: revalidate } = useSWR<AssetConfigData[]>(
    `/api/asset-config?${
      locationType ? `filters[locationTypes]=${locationType}&` : ''
    }${query}`
    // {
    //   initialData: [],
    //   revalidateOnMount: true,
    // }
  );

  useEffect(() => {
    if (Object.keys(selected).length === 0) {
      setOnlySelected(false);
    }
  }, [selected]);

  const view = useMemo(() => {
    switch (locationType) {
      case 'Asset':
        return {
          path: '/asset-config',
          Nav: AssetsNav,
        };
      case 'Site':
        return {
          path: '/site-config',
          Nav: SitesNav,
        };
      default:
        return {
          path: '/config',
          Nav: StockNav,
        };
    }
  }, [locationType]);

  const back = useCallback(() => navigate(view.path), [navigate, view.path]);

  const assetConfig = onlySelected
    ? data?.filter(({ id }) => Object.keys(selected).includes(id)) ?? []
    : data ?? [];

  const hasSelected = Object.keys(selected).length > 0;

  const handleSuccess = () => {
    revalidate();
    back();
  };

  const childProps = {
    selected,
    data: assetConfig,
    onSuccess: handleSuccess,
  };

  const handleFiltered: FilterProps['onFiltered'] = (filters) => {
    setQuery(qs.stringify({ filters }, { arrayFormat: 'comma' }));
  };

  return (
    <AuthGate action='manage' subject='feat.items'>
      <ListDetailView
        main={
          <AssetConfigTable
            locationType={locationType}
            data={assetConfig}
            selected={selected}
            setSelected={setSelected}
          />
        }
        nav={<view.Nav />}
        actions={<AssetConfigActions selected={selected} />}
        filters={
          <>
            <div className='absolute inset-0 z-[9] my-20 flex flex-col justify-center bg-white fill-copy-body px-16 sm:hidden'>
              <RotatePhone />
              <p className='text-center'>Rotate to view</p>
            </div>
            <AssetConfigFilterForm
              locationType={locationType}
              onFiltered={handleFiltered}
            />
            <div className='flex justify-between'>
              <div className='label mt-3'>
                {hasSelected && (
                  <label>
                    <input
                      type='checkbox'
                      checked={onlySelected}
                      onChange={() => setOnlySelected((state) => !state)}
                    />{' '}
                    Only show selected
                  </label>
                )}
              </div>
              <Result count={assetConfig.length} />
            </div>
            <div className='mt-3 whitespace-nowrap text-right'>
              <a
                className='text-brand hover:underline'
                href={`${
                  process.env.NODE_ENV === 'development'
                    ? 'http://localhost:4000'
                    : ''
                }/api/asset-config?format=csv&${query}`}
              >
                {t('export')}
              </a>
            </div>
          </>
        }
      >
        <Route path='*' element={<ConfigFAB />} />
        <Route
          path='modify-properties'
          element={<ModifyPropertiesModal {...childProps} />}
        />
        <Route
          path='remove-items'
          element={<RemoveItemsModal {...childProps} />}
        />
        <Route
          path='add-items'
          element={<AddItemsModal {...childProps} back={back} />}
        />
        <Route
          path='unqueue-changes'
          element={<UnqueueChangesModal {...childProps} />}
        />
        <Route
          path='commit-changes'
          element={<CommitChangesModal {...childProps} />}
        />
        <Route
          path='add-spaces'
          element={<AddSpacesModal onClose={back} onSuccess={handleSuccess} />}
        />

        <Route
          path='clone-spaces'
          element={<CloneSpacesModal {...childProps} onClose={back} />}
        />

        <Route
          path='stock-adjustment'
          element={<AdjustStockModal {...childProps} onClose={back} />}
        />

        <Route
          path='stocktake'
          element={
            <SMCModal
              mode='stocktake'
              onSuccess={childProps.onSuccess}
              onCancel={() => navigate('/config')}
            />
          }
        />

        <Route
          path='transfer-stock'
          element={<TransferStockModal returnTo={view.path} />}
        />
      </ListDetailView>
    </AuthGate>
  );
};

function ConfigFAB() {
  const { t } = useTranslation();
  return (
    <FloatingActionButton
      actions={[
        {
          to: 'add-items',
          label: t('addItems'),
        },
        {
          to: 'remove-items',
          label: t('removeItems'),
        },
        {
          to: 'stock-adjustment',
          label: t('adjustStock'),
        },
        // {
        //   to: 'transfer-stock',
        //   label: t('Transfer Stock'),
        // },
        {
          to: 'stocktake',
          label: t('stocktake'),
        },
        {
          to: 'modify-properties',
          label: t('modifyProperties'),
        },
      ]}
    />
  );
}

export type AssetConfigData = {
  id: string;
  status: 'active' | 'queued';
  queued_type: 'Add' | 'Remove' | null;
  asset_id: number;
  asset_name: string;
  space_sku_id: number;
  space_id: number;
  space_name: string;
  sku_id: number;
  sku_code: string;
  sku_name: string;
  item_type: string;
  price: number;
  soh: number;
  reserved: number;
  capacity: number;
  empty: number;
  target: number;
  missing: number;
  available: number;
  queued_price: number | null;
  queued_capacity: number | null;
  queued_target: number | null;
  location_name: string;
  image: string | null;
  reorder_level: string | number | null;
};

export type Selected = Record<string, boolean>;
