type Props = {
  children: React.ReactNode;
};

export function StickyHeading({ children }: Props) {
  return (
    <div className='sticky top-0 z-10 bg-white px-4 pt-3 pb-1 text-sm font-semibold dark:bg-slate-800'>
      {children}
    </div>
  );
}
