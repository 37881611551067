import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { DateTimeField } from '~/components/form/DateTimeField';
import { LocationField } from '~/components/form/LocationField';
import { SubmitButton } from '~/components/form/SubmitButton';
import { TextField } from '~/components/form/TextField';
import { ReadValue } from '~/components/ui/ReadValue';
import {
  useLocationQuery,
  useStockAdjustmentTypesQuery,
} from '~/generated/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { Prompt } from '../Prompt';
import { ComboboxField } from '../ui/Combobox';
import { SMCHead } from './SMCHead';
import { CancelCallback, Mode, SuccessCallback } from './types';

/**
 * Fields display only on Stock Adjustment
 */
const AdjustmentType = () => {
  const { t } = useTranslation();
  const [result] = useStockAdjustmentTypesQuery({
    requestPolicy: 'cache-and-network',
    variables: {},
  });
  const options = result.data?.stockAdjustmentTypes ?? [];

  return (
    <>
      <ComboboxField
        name='adjustmentType'
        label={t('adjustmentType')}
        items={options}
        required
      />
      <TextField label='Additional Information (Optional)' name='info' />
    </>
  );
};

// const checkStringLength = (value) => {
//   return value.length < 33
// }

const validationSchema = (mode: Mode) => {
  return Yup.object().shape({
    location: Yup.object()
      .shape({
        type: Yup.string().oneOf(['Asset', 'Contact', 'Site']),
        id: Yup.mixed().required(),
      })
      .required(),
    date: Yup.date().required(),
    adjustmentType:
      mode !== 'stocktake'
        ? Yup.string().required('Adjustment type is required')
        : Yup.string(),
    info: Yup.string().max(
      32,
      'Additional Information limited to 32 characters.'
    ),
  });
};

type Props = {
  mode: Mode;
  onCancel: CancelCallback;
  onSuccess: SuccessCallback;
  prevData?: any;
  /** Lock form to specified location */
  location?: string;
};

/**
 * The form presented as Step 1 of the wizard-like Stock Manipulation Component
 */
export const Step1Form = ({
  mode,
  onCancel,
  onSuccess,
  prevData = null,
  location,
}: Props) => {
  const { t } = useTranslation();
  const maxDate = new Date().setSeconds(new Date().getSeconds() + 30).valueOf();

  const initialLocation = (() => {
    if (location) {
      const split = location.split(':');
      return { type: split[0], id: [split[1]] };
    }
    return { type: 'Site', id: [] };
  })();
  const initialValues = prevData
    ? prevData
    : {
        location: initialLocation,
        date: new Date(),
        adjustmentType: '',
        info: '',
      };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSuccess(values);
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema(mode)}
    >
      {({ dirty, isSubmitting }) => (
        <Form className='h-full'>
          <Prompt
            when={(dirty && !isSubmitting) || prevData}
            cancel='Stay with Form'
          />
          <SideLayout>
            <SMCHead step={1} onClose={onCancel}>
              {t(mode)}
            </SMCHead>
            <SideLayout.Body>
              {location ? (
                <ReadOnlyLocation location={location} />
              ) : (
                <LocationField title={'Location Type'} required />
              )}
              <DateTimeField
                label='Date & Time'
                name='date'
                maxDate={maxDate}
              />
              {mode === 'adjustment' && <AdjustmentType />}
            </SideLayout.Body>
            <SideLayout.Foot className='p-4'>
              <SubmitButton>Next &raquo;</SubmitButton>
            </SideLayout.Foot>
          </SideLayout>
        </Form>
      )}
    </Formik>
  );
};

function ReadOnlyLocation({ location }: { location: string }) {
  const [result] = useLocationQuery({ variables: { id: location } });
  const [type] = location.split(':');
  return (
    <ReadValue label={'type.' + type} value={result.data?.location.name} />
  );
}
