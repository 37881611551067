import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { useAppContext } from '~/App';
import { UploadField } from '~/components/upload/Upload';
import { useSetProfilePicMutation } from '~/generated/graphql';

type FormData = {
  images: string[];
};

type Props = {
  backTo: () => void;
};

export const UpdateProfilePicForm = ({ backTo }: Props) => {
  const { user: currentUser, revalidate } = useAppContext();
  invariant(currentUser);
  const [, setProfilePic] = useSetProfilePicMutation();

  const submit = async ({ images }: FormData) => {
    const result = await setProfilePic({ image: images[0] });
    if (result.error) {
      toast.error('Something went wrong. Please try again');
      return;
    }

    setTimeout(async () => {
      await revalidate();
      backTo();
    }, 1000);
  };

  return (
    <Formik initialValues={{ images: [] }} onSubmit={submit}>
      <Form className='flex w-full grow flex-col'>
        <UploadField name='images' />
      </Form>
    </Formik>
  );
};
