import { SelectField } from '~/components/form/downshift/SelectField';
import { TextField } from '~/components/form/TextField';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import { Form, Formik } from 'formik';
import {
  ItemType,
  Status,
  StockOnHandFilters,
  useStockOnHandQuery,
} from '~/generated/graphql';
import { sortNatural, unique } from '~/helpers/array';
import { extractAttributes } from '~/helpers/attributes';
// import { extractAttributes } from '~/helpers/attributes';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  filters?: StockOnHandFilters;
  location?: string;
  onClose: () => void;
  onFilter: (filters: StockOnHandFilters) => void;
};

// TODO rename to StockSelectionFilters or something similar
export const StocktakeFilters = ({
  filters,
  location,
  onClose,
  onFilter,
}: Props) => {
  const { t } = useTranslation();

  const [result] = useStockOnHandQuery({
    requestPolicy: 'cache-and-network',
    variables: { location },
  });
  const { data, fetching, error } = result;

  const [items, spaces, spaceAttributes] = useMemo(() => {
    if (!data?.stockOnHand) return [[], [], []];

    const items = sortNatural(
      unique(data.stockOnHand.map(({ sku: { name } }) => name))
    );

    const spaces = sortNatural(
      unique(data.stockOnHand.map(({ space: { name } }) => name))
    );

    const spaceAttributes = sortNatural(
      unique(
        data.stockOnHand.flatMap(({ space: { attributes } }) =>
          extractAttributes(attributes ?? undefined)
        )
      )
    );

    return [items, spaces, spaceAttributes];
  }, [data?.stockOnHand]);

  if (fetching) return <Loading />;
  if (error) return <ErrorMessage message={error.message} />;

  // const attributes = useMemo(
  //   () =>
  //     sortNatural(
  //       unique(
  //         spaceSkus.flatMap((item) => extractAttributes(item.sku.attributes))
  //       )
  //     ),
  //   [spaceSkus]
  // );

  const initialAttributes: string[] = [];
  // const initialAttributes = Object.keys(filters).reduce(
  //   (prev: string[], curr) => {
  //     if (curr.startsWith('sku.attributes.')) {
  //       const values = filters[curr].map(
  //         (value) => `${curr.substr(15)}: ${value}`
  //       );
  //       return [...prev, ...values];
  //     }
  //     return prev;
  //   },
  //   []
  // );

  const itemTypeOptions = Object.values(ItemType)
    .map((value) => ({ value, label: t(`itemType.${value}`) }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const statusOptions = [
    { value: Status.Active, label: t('active') },
    { value: Status.Inactive, label: t('inactive') },
  ];

  return (
    <SideLayout className='relative z-20 h-full bg-white'>
      <SideLayout.Head iconClass='chevron-left' onClose={onClose}>
        Filters
      </SideLayout.Head>
      <SideLayout.Body className='min-h-screen-nav bg-white p-4'>
        <Formik
          initialValues={{
            items: filters?.items ?? [],
            itemTypes: filters?.itemTypes ?? [],
            itemStatus: filters?.itemStatus ?? [Status.Active],
            spaces: filters?.spaces ?? [],
            minSoh: filters?.minSoh ?? '',
            maxSoh: filters?.maxSoh ?? '',
            spaceAttributes: filters?.spaceAttributes ?? [],
            itemAttributes: filters?.itemAttributes ?? initialAttributes,
          }}
          onSubmit={(values) => {
            // const attributes = values.attributes.reduce(
            //   (prev: Record<string, string[]>, curr) => {
            //     const [name, value] = curr.split(/:\s(.+)/);
            //     const filterKey = `sku.attributes.${name}`;
            //     if (prev[filterKey]) {
            //       return { ...prev, [filterKey]: [...prev[filterKey], value] };
            //     }
            //     return { ...prev, [filterKey]: [value] };
            //   },
            //   {}
            // );

            const filters = Object.keys(values).reduce(
              (prev: StockOnHandFilters, key) => {
                const value = values[key as keyof typeof values];
                const length = Array.isArray(value)
                  ? value.length
                  : ('' + value).length;
                if (value && length > 0) return { ...prev, [key]: value };
                return prev;
              },
              {}
            );
            onFilter(filters);
            onClose();
          }}
        >
          <Form>
            <SelectField
              name='items'
              label={t('item', { count: 0 })}
              options={items.map((value) => ({ value, label: value }))}
              multiple
            />
            <SelectField
              name='itemTypes'
              label={t('itemTypeLabel')}
              options={itemTypeOptions}
              multiple
            />
            <SelectField
              name='itemStatus'
              label={'Item Status'}
              options={statusOptions}
              multiple
            />
            <SelectField
              name='spaces'
              label={t('space_plural')}
              options={spaces.map((value) => ({ value, label: value }))}
              multiple
            />
            <SelectField
              name='spaceAttributes'
              label={t('spaceAttribute_plural')}
              options={spaceAttributes.map((value) => ({
                value,
                label: value,
              }))}
              multiple
            />
            <SelectField
              name='itemAttributes'
              label={t('itemAttribute_plural')}
              options={[]}
              multiple
            />
            <TextField type='number' name='minSoh' label={'Minimum On Hand'} />
            <TextField type='number' name='maxSoh' label={'Maximum On Hand'} />
            <button
              className='border-future-blue text-future-blue mt-12 rounded-lg border px-5 py-2 text-lg font-bold uppercase'
              type='submit'
            >
              {t('apply')}
            </button>
          </Form>
        </Formik>
      </SideLayout.Body>
    </SideLayout>
  );
};
