import { SVGProps } from 'react';

const SvgMulti = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='80.999'
    height='68.999'
    viewBox='0 0 80.999 68.999'
    {...props}
  >
    <g
      id='noun_image_upload_1156517'
      data-name='noun_image upload_1156517'
      transform='translate(-8.2 -13.2)'
    >
      <path
        id='Path_7563'
        data-name='Path 7563'
        d='M75.6,54.6V21.2a3.009,3.009,0,0,0-3-3H16.2a3.009,3.009,0,0,0-3,3V66.3a3.009,3.009,0,0,0,3,3H60.9A13.767,13.767,0,1,0,75.6,54.6ZM59,37.7a1.049,1.049,0,0,0-1.3,0L40.1,54.1l-8.1-8a1.048,1.048,0,0,0-1.3-.1L15.2,57.3V21.2a1,1,0,0,1,1-1H72.6a1,1,0,0,1,1,1V50.5Zm21.2,30a.967.967,0,0,1-1.4,0l-3.2-3.2v10a1,1,0,0,1-2,0v-10l-3.2,3.2a1.08,1.08,0,0,1-.7.3.908.908,0,0,1-.7-.3.967.967,0,0,1,0-1.4l4.9-4.9c.1-.1.2-.2.3-.2a1.1,1.1,0,0,1,.8,0c.1,0,.2.1.3.2l4.9,4.9A.967.967,0,0,1,80.2,67.7Z'
        transform='translate(-5 -5)'
        fill='#007070'
      />
      <rect
        id='Rectangle_12640'
        data-name='Rectangle 12640'
        width={76}
        height={64}
        transform='translate(13.199 18.199)'
        fill='#fff'
      />
      <path
        id='Path_7561'
        data-name='Path 7561'
        d='M31,38.2a6.38,6.38,0,0,1-6.4-6.4A6.35,6.35,0,1,1,31,38.2Z'
        fill='#007070'
      />
      <path
        id='Path_7562'
        data-name='Path 7562'
        d='M75.6,54.6V21.2a3.009,3.009,0,0,0-3-3H16.2a3.009,3.009,0,0,0-3,3V66.3a3.009,3.009,0,0,0,3,3H60.9A13.767,13.767,0,1,0,75.6,54.6ZM59,37.7a1.049,1.049,0,0,0-1.3,0L40.1,54.1l-8.1-8a1.048,1.048,0,0,0-1.3-.1L15.2,57.3V21.2a1,1,0,0,1,1-1H72.6a1,1,0,0,1,1,1V50.5Zm21.2,30a.967.967,0,0,1-1.4,0l-3.2-3.2v10a1,1,0,0,1-2,0v-10l-3.2,3.2a1.08,1.08,0,0,1-.7.3.908.908,0,0,1-.7-.3.967.967,0,0,1,0-1.4l4.9-4.9c.1-.1.2-.2.3-.2a1.1,1.1,0,0,1,.8,0c.1,0,.2.1.3.2l4.9,4.9A.967.967,0,0,1,80.2,67.7Z'
        fill='#007070'
      />
    </g>
  </svg>
);

export default SvgMulti;
