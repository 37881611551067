import { useField } from 'formik';
import { Autocomplete } from '~/components/autocomplete/Autocomplete';
import { TextField } from './TextField';

type Props = {
  name: string;
  label: string;
};

export const AddressInputGroup = ({ name, label, ...props }: Props) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  return (
    <div className='AddressInputGroup'>
      <div>
        <Autocomplete
          label={label}
          onGeocoded={(result) => setValue(result)}
          error={meta.error}
        />
      </div>
      {field.value && (
        <div className='AddressInputGroup-address'>
          <div>
            <TextField
              {...props}
              name={name + '.subpremise'}
              label='Unit / Floor / Suite'
            />
          </div>
          <div className='flex'>
            <div style={{ width: '5rem' }}>
              <TextField
                {...props}
                name={name + '.street_number'}
                label='No.'
              />
            </div>
            <div style={{ width: '100%' }}>
              <TextField {...props} name={name + '.route'} label='Street' />
            </div>
          </div>
          <div>
            <TextField {...props} name={name + '.locality'} label='Suburb' />
          </div>
          <div>
            <div>
              <TextField {...props} name={name + '.country'} label='Country' />
            </div>
          </div>
          <div className='AddressInputGroup-multicell'>
            <TextField
              {...props}
              name={name + '.administrative_area_level_1'}
              label='State'
            />
            <TextField
              {...props}
              name={name + '.postal_code'}
              label='Postcode'
            />
          </div>
        </div>
      )}
    </div>
  );
};
