import { yupResolver } from '@hookform/resolvers/yup';
import { AppContext } from '~/App';
import { Input } from '~/components/form/Input';
import { useUpdatePersonalInfoMutation } from '~/generated/graphql';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import * as Yup from 'yup';

type FormData = {
  name: string;
  phone: string;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Required'),
  phone: Yup.string(),
});

export const PersonalInfoForm = () => {
  const { user: currentUser } = useContext(AppContext);
  invariant(currentUser);
  const [, update] = useUpdatePersonalInfoMutation();

  const { t } = useTranslation();

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<FormData>({
    defaultValues: {
      name: currentUser.name,
      phone: currentUser.phone ?? '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const submit = async (input: FormData) => {
    const result = await update({ input });

    if (result.data?.updatePersonalInfo === 0) {
      window.location.reload();
    }
  };

  return (
    <div className='flex w-full grow'>
      <form
        onSubmit={handleSubmit(submit)}
        className='mx-auto flex flex-1 grow flex-col justify-between'
      >
        <div>
          <Input
            {...register('name')}
            label={t('name')}
            type='text'
            error={errors.name?.message}
          />
          <Input
            {...register('phone')}
            label={t('phoneNumber')}
            type='text'
            error={errors.phone?.message}
          />
        </div>
        <button
          className='bg-brand hover:bg-slate-dark w-full rounded-lg p-3 font-semibold uppercase text-white transition-all duration-300  disabled:opacity-50'
          disabled={isSubmitting}
        >
          {t('submit')}
        </button>
      </form>
    </div>
  );
};
