import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLoaderData,
  useNavigate,
  useParams,
  useRevalidator,
} from 'react-router-dom';
import { client } from '~/client';
import { AuthGate } from '~/components/AuthGate';
import { JobTemplateFilters } from '~/components/job-template/JobTemplateFilters';
import { JobTemplateList } from '~/components/job-template/JobTemplateList';
import { Split } from '~/components/Split';
import { graphql } from '~/gql';
import { JobTemplate, Status } from '~/gql/graphql';
import { unique } from '~/helpers/array';
import parseFilters from '~/helpers/parseFilters';
import { useLocalStorage } from '~/hooks';
import { useSearch } from '~/hooks/useSearch';
import { MoreNav } from '~/layouts/nav/MoreNav';
import { Option } from '~/types';

const SEARCH_OPTIONS = {
  keys: [
    'name',
    'status',
    {
      name: 'includeAttributes',
      getFn(jobTemplate: any) {
        return jobTemplate.includeAttributes?.join(', ') ?? '';
      },
    },
  ],
};

const ListJobTemplatesDocument = graphql(`
  query ListJobTemplates($status: Status) {
    jobTemplates(status: $status) {
      id
      name
      ...JobTemplateFields
    }
  }
`);

export async function loader() {
  const { data, error } = await client.query(
    ListJobTemplatesDocument,
    { status: undefined },
    { requestPolicy: 'cache-and-network' }
  );

  if (error || !data) {
    throw new Response(error?.message, { status: 500 });
  }

  return data;
}

type TContext = {
  templates?: Awaited<ReturnType<typeof loader>>['jobTemplates'];
  reload: () => void;
};

type TOption = {
  value: string;
  label: string;
};

const JobTemplateListContext = createContext<TContext>({} as TContext);
export const useJobTemplateListContext = () => {
  const context = useContext(JobTemplateListContext);

  if (!context) {
    throw new Error('Must be used within JobTemplateListContext');
  }

  return context;
};

const parseAttributeOptions = (templates: JobTemplate[]) => {
  const listOfOpts: string[][] = [];

  templates?.forEach((template) => {
    if (template?.includeAttributes) {
      listOfOpts.push(template.includeAttributes);
    }
  });

  return unique(listOfOpts.flat(1)).map((opt): Option => {
    const optParts = opt.split('\\');
    const group = optParts.length > 1 ? optParts[0] : undefined;
    const optionLabel =
      optParts.length > 1 ? optParts.splice(1).join('\\') : opt;
    return {
      $group: group?.trimEnd(),
      value: opt,
      label: optionLabel.trimStart(),
    };
  });
};

export default function TemplatesRoute() {
  const localStorage = useLocalStorage();
  const navigate = useNavigate();
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const revalidator = useRevalidator();
  const reload = () => revalidator.revalidate();
  const templates = data.jobTemplates;
  const { templateId } = useParams();
  const [query, setQuery] = useState('');
  const { results, search } = useSearch(templates, SEARCH_OPTIONS);
  const attributesFilterOptions: TOption[] = parseAttributeOptions(
    templates as JobTemplate[]
  );
  const { t } = useTranslation(['translation']);
  const [filtersValue, setFiltersValue] = useState<URLSearchParams>(
    new URLSearchParams([['status', 'Active']])
  );

  useEffect(() => {
    const navigateTo = localStorage.get('nav.more') ?? '/job-templates';
    if (navigateTo !== '/job-templates') {
      navigate(navigateTo);
    }
  }, []);

  return (
    <JobTemplateListContext.Provider value={{ templates, reload }}>
      <AuthGate action='manage' subject='feat.templates'>
        <Split
          nav={<MoreNav />}
          filters={
            <JobTemplateFilters
              filters={[
                {
                  name: 'status',
                  label: t('status'),
                  options: [
                    { label: 'Active', value: Status.Active },
                    { label: 'Inactive', value: Status.Inactive },
                  ],
                  searchable: false,
                  type: 'select',
                },
                {
                  name: 'includeAttributes',
                  label: t('attribute'),
                  options: attributesFilterOptions,
                  type: 'select',
                },
              ]}
              filtersValue={filtersValue}
              setFiltersValue={setFiltersValue}
              query={query}
              onSubmit={({ query, filters }) => {
                setFiltersValue(filters);
                setQuery(query);
                search(query, parseFilters(filters));
              }}
            />
          }
          main={
            <JobTemplateList
              templates={results ?? []}
              selectedId={templateId}
            />
          }
        />
      </AuthGate>
    </JobTemplateListContext.Provider>
  );
}
