import { Formik } from 'formik';
import { groupBy, toPairs } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuickAdd } from '~/components/QuickAdd';
import { QuickAddPopup } from '~/components/QuickAddPopup';
import { CardSmall } from '~/components/ui/CardSmall';
import { Menu } from '~/components/ui/Menu';
import { SearchField } from '~/components/ui/SearchField';
import {
  AddItemsTaskQuery,
  StockOnHandFilters,
  Stocktake,
  useStockOnHandQuery,
} from '~/generated/graphql';
import { useSearch } from '~/hooks/useSearch';

type Item = Omit<AddItemsTaskQuery['addItemsTask']['cards'][0], '__typename'>;

type TInitialFilters = Partial<
  Pick<Stocktake, 'include' | 'itemStatus' | 'spaces'>
>;

type Props = {
  siteId: string;
  items?: Item[];
  /** Optional filter space skus included in this list of space sku ids */
  in?: string[];
  initialFilters?: TInitialFilters | null;
  onFiltersUpdate?: (filters: StockOnHandFilters, ids: string[]) => void;
  moreFilters?: boolean;
};

const SEARCH_OPTIONS = {
  keys: ['sku.name', 'sku.code', 'sku.itemType', 'space.name'],
};

export const StockTab = ({
  siteId,
  items,
  initialFilters,
  moreFilters = true,
}: Props) => {
  const { t } = useTranslation();

  const location = `Site:${siteId}`;

  const quickAdd = useQuickAdd();
  const [result, reload] = useStockOnHandQuery({
    pause: !!items,
    requestPolicy: 'cache-and-network',
    variables: { location },
  });
  const stock = items ?? (result.data?.stockOnHand as unknown as Item[]);

  const { results, query, search } = useSearch(stock, SEARCH_OPTIONS);

  const spaces = useMemo(
    () => toPairs(groupBy(results, 'space.name')),
    [results]
  );

  // const [showFilters, setShowFilters] = useState(false);

  if (!location) return null;

  // function handleFilter(filters: StockOnHandFilters) {
  //   setFilters(filters);
  // }

  // if (showFilters) {
  //   return (
  //     <Modal
  //       component={StocktakeFilters}
  //       // @ts-expect-error FIXME
  //       filters={filters}
  //       location={location}
  //       onClose={() => setShowFilters(false)}
  //       onFilter={handleFilter}
  //       open
  //     />
  //   );
  // }

  return (
    <>
      <div className='flex items-center p-3'>
        <SearchField
          placeholder='Search Items...'
          onChange={(event) => search(event.currentTarget.value)}
        />

        <Menu buttonClassName='p-3 ml-auto text-tertairy'>
          <Formik initialValues={{}} onSubmit={() => {}}>
            <Menu.Item onClick={quickAdd.open}>Add Item</Menu.Item>
          </Formik>
          <Menu.Item as={Link} to='stock-adjustment'>
            Adjust Stock
          </Menu.Item>
          <Menu.Item as={Link} to='stocktake'>
            Stocktake
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={`/config?locationId=Site:${siteId}`}
            target='_blank'
          >
            Bulk Configuration
          </Menu.Item>
        </Menu>

        {/* {moreFilters && (
          <button
            className='text-copy-alt block fill-current pl-2 text-sm font-bold'
            onClick={() => setShowFilters(true)}
          >
            <FilterIcon className='mr-2 inline-block h-4' />
            {t('filters')}
          </button>
        )} */}
      </div>
      <div className='flex-grow'>
        {spaces.map(([spaceName, items], i) => {
          return (
            <Fragment key={spaceName}>
              <div className='sticky top-0 z-[2] mb-1 bg-grey-2 px-4 py-1 text-center text-xs font-medium text-grey-50'>
                {spaceName}
              </div>
              {items.map((item, j) => {
                return (
                  <Fragment key={item.id}>
                    <CardSmall
                      linkTo={`items/${[item.space.id, item.sku.id].join(':')}`}
                      showImage={true}
                      image={item.sku.image ? item.sku.image : undefined}
                      titleText={item.sku.name}
                      secondaryText={t('itemType.' + item.sku.itemType)}
                      heroElement={
                        <div className='flex flex-col'>
                          <p className='text-right text-sm leading-tight'>
                            <span className='text-medium text-[17px] leading-tight'>
                              {item.soh}
                            </span>
                            {item.target ? ' / ' + item.target : ''}
                          </p>
                          <p className='text-xs leading-5 text-grey-40'>
                            On hand
                          </p>
                        </div>
                      }
                      warning={
                        item.reorderLevel && item.soh < item.reorderLevel
                          ? true
                          : false
                      }
                    />
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
      </div>

      <QuickAddPopup
        stockLocation={location}
        onCancel={quickAdd.close}
        onSuccess={() => reload()}
        show={quickAdd.showQuickAdd ?? false}
        onClose={quickAdd.close}
      />
    </>
  );
};
