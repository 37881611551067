import { VariantProps, cva } from 'class-variance-authority';

const card = cva(
  [
    'relative',
    'mb-2',
    'bg-white',
    'py-2',
    'pl-2',
    'pr-3',
    'lg:mb-3',
    'lg:px-3',
  ],
  {
    variants: {
      type: {
        listItem: 'hover:shadow-md rounded border lg:py-2',
        activity: 'hover:bg-hoverBg lg:pt-2 lg:py-3',
      },
      selected: {
        true: '',
      },
    },
    compoundVariants: [
      {
        type: 'listItem',
        selected: true,
        className: 'border-2 border-brand-lighter shadow-brand-lighter',
      },
      {
        type: 'listItem',
        selected: false,
        className: 'border-grey-10 shadow',
      },
      {
        type: 'activity',
        selected: true,
        className: 'outline outline-border-active outline-1',
      },
      {
        type: 'activity',
        selected: false,
        className: '',
      },
    ],
  }
);

type CardProps<T extends React.ElementType> = {
  as?: T;
} & VariantProps<typeof card>;

export function Card<T extends React.ElementType = 'div'>({
  as,
  className,
  type = 'listItem',
  selected,
  ...props
}: CardProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof CardProps<T>>) {
  const Component = as ?? 'div';
  return (
    <Component className={card({ type, selected, className })} {...props} />
  );
}
