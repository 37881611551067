import { PaperAirplaneIcon } from '@heroicons/react/outline';

type Props = {
  sending: boolean;
  send: () => void;
};

/** A square variation of primary button */
export function SendButton({ sending, send }: Props) {
  return (
    <button
      className='mb-1 flex h-7 w-7 items-center justify-center rounded bg-brand text-white'
      type='button'
      disabled={sending}
      onClick={send}
    >
      <PaperAirplaneIcon className='ml-0.5 h-5 w-5 rotate-90' />
    </button>
  );
}
