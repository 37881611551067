import * as Sentry from '@sentry/react';
import '@yaireo/tagify/dist/tagify.css';
import axios, { AxiosStatic } from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';

declare global {
  interface Window {
    axios: AxiosStatic;
  }
}

/**
 * Sentry setup
 */
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
  });
}

/**
 * axios setup
 * - axios automatically handles sending the XSRF token
 * - Default base url for all requests to /api
 * - Enables req.xhr usage on the server with Express
 *   https://expressjs.com/en/api.html#req.xhr
 */
window.axios = axios;
window.axios.defaults.baseURL = '/api/';
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
