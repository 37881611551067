import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  CogIcon,
  LogoutIcon,
  SelectorIcon,
} from '@heroicons/react/solid';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '~/App';
import HeadsetIcon from '~/HeadsetIcon';
import { handleLogout } from '~/components/auth/LogoutLink';
import { Image } from '~/components/ui/Image';
import { initials } from '~/helpers/initials';
import { useLocationHash } from '~/hooks/useLocationHash';
import { handleSupportMessengerOpen } from './SupportLink';

export function TenantSwitcher() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setShowSettings] = useLocationHash('#settings-open');
  const {
    tenant: { id: tid },
    user,
  } = useContext(AppContext);

  if (!user) {
    return null;
  }

  const currentTenant = user.tenants.find((tenant) => tenant.id === tid);

  if (!currentTenant) {
    return null;
  }

  const handleChange = (value: (typeof user.tenants)[0] | null) => {
    if (value === null) {
      return;
    }
    const tenantId = value.id;
    window.axios.patch(`/sessions`, { tenantId }).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className='flex items-center justify-center'>
      <Listbox value={currentTenant} onChange={handleChange}>
        <div className='relative mx-4 w-full lg:mx-0 lg:w-80'>
          <Listbox.Button
            className='focus-visible:ring-offset-orange-300 relative flex w-full cursor-default items-center justify-end gap-2 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'
            style={{ height: 'calc(69px - 1px)' }}
          >
            <div className='h-10 w-10'>
              <Logo name={currentTenant.name} url={currentTenant.image} />
            </div>
            <span className='block truncate'>
              {currentTenant?.name ?? currentTenant?.slug}
            </span>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <SelectorIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute z-[9001] mt-1 max-h-screen w-full divide-y overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              <div>
                {/* <Listbox.Option
                  as='div'
                  className={({ active }) =>
                    `block cursor-default select-none px-4 py-2 ${
                      active ? 'bg-brand text-white' : 'text-gray-900'
                    }`
                  }
                  onClick={() => navigate('/accounts/new')}
                  value={null}
                >
                  <div className='flex flex-1 items-center gap-2'>
                    <div className='h-7 w-7 p-1'>
                      <PlusIcon />
                    </div>
                    <span className='block truncate font-normal'>
                      Create New Account
                    </span>
                  </div>
                </Listbox.Option> */}
                {user.tenants.map((tenant) => (
                  <Listbox.Option
                    key={tenant.id}
                    className={({ active }) =>
                      `cursor-default select-none px-4 py-2 ${
                        active ? 'bg-brand text-white' : 'text-gray-900'
                      }`
                    }
                    value={tenant}
                  >
                    {({ active, selected }) => (
                      <div className='flex justify-between'>
                        <div className='flex flex-1 items-center gap-2'>
                          <div className='h-7 w-7'>
                            <Logo name={tenant.name} url={tenant.image} />
                          </div>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {tenant.name ?? tenant.slug}
                          </span>
                        </div>
                        {selected ? (
                          <span
                            className={`${
                              active ? 'text-inherit' : 'text-brand'
                            } flex items-center`}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </div>
              <div className='hidden lg:block'>
                <Listbox.Option
                  as='div'
                  className={({ active }) =>
                    `block cursor-default select-none px-4 py-2 ${
                      active ? 'bg-brand text-white' : 'text-gray-900'
                    }`
                  }
                  onClick={() => setShowSettings(true)}
                  value={null}
                >
                  <div className='flex flex-1 items-center gap-2'>
                    <div className='h-7 w-7 p-1'>
                      <CogIcon />
                    </div>
                    <span className='block truncate font-normal'>
                      {t('setting_plural')}
                    </span>
                  </div>
                </Listbox.Option>
                <Listbox.Option
                  as='div'
                  className={({ active }) =>
                    `block cursor-default select-none px-4 py-2 ${
                      active ? 'bg-brand text-white' : 'text-gray-900'
                    }`
                  }
                  onClick={handleLogout}
                  value={null}
                >
                  <div className='flex flex-1 items-center gap-2'>
                    <div className='h-7 w-7 p-1'>
                      <LogoutIcon />
                    </div>
                    <span className='block truncate font-normal'>
                      {t('signOut')}
                    </span>
                  </div>
                </Listbox.Option>
                <Listbox.Option
                  as='div'
                  className={({ active }) =>
                    `block cursor-default select-none px-4 py-2 ${
                      active ? 'bg-brand text-white' : 'text-gray-900'
                    }`
                  }
                  onClick={handleSupportMessengerOpen}
                  value={null}
                >
                  <div className='flex flex-1 items-center gap-2'>
                    <div className='h-7 w-7 p-1'>
                      <HeadsetIcon className='h-5 w-5' />
                    </div>
                    <span className='block truncate font-normal'>
                      {t('support')}
                    </span>
                  </div>
                </Listbox.Option>
              </div>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

type LogoProps = {
  name: string;
  url?: string;
};

function Logo({ name, url }: LogoProps) {
  return (
    <div
      className={
        'flex h-full w-full shrink-0 items-center justify-center overflow-hidden rounded-md bg-gray-300 text-center font-medium text-gray-500 lg:text-sm'
      }
    >
      {url ? <Image url={url} size='fit' /> : initials(name)}
    </div>
  );
}
