import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useAppContext } from '~/App';
import { Input } from '~/components/form/Input';
import { Upload } from '~/components/upload/Upload';
import { useUpdateAccountProfileMutation } from '~/generated/graphql';
import { PageProps } from '.';

type FormData = {
  name: string;
  logo: string | null;
  industry: string;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Required'),
  logo: Yup.string().nullable(),
  industry: Yup.string().nullable(),
});

export const AccountProfile = ({ setShowPage }: PageProps) => {
  const { tenant, revalidate } = useAppContext();
  const { t } = useTranslation();
  const [, save] = useUpdateAccountProfileMutation();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<FormData>({
    defaultValues: {
      name: tenant.name ?? '',
      logo: tenant.logo,
      industry: tenant.industry ?? '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const submit = async (values: FormData) => {
    const result = await save({ input: values });
    if (result.error?.message) {
      toast.error(result.error.message.replace('[GraphQL] ', ''));
      return;
    }
    if (result.data?.updateAccountProfile) {
      toast.success('Account profile updated successfully');
      setShowPage(null);
      revalidate();
    }
  };

  const industryOptions = ['Accommodation Provider', 'Logistics', 'Events'];

  return (
    <div className='flex w-full grow'>
      <form
        onSubmit={handleSubmit(submit)}
        className='mx-auto flex flex-1 grow flex-col justify-between'
      >
        <div>
          <Input
            {...register('name')}
            label={t('organisationName')}
            type='text'
            error={errors.name?.message}
          />

          <div className='my-7'>
            <p>Organisation Logo</p>
            <Controller
              control={control}
              name='logo'
              render={({ field: { value, onChange } }) => (
                <Upload
                  value={value ? [value] : []}
                  onChange={(newValue) => {
                    onChange(newValue.length ? newValue[0] : null);
                  }}
                  images={tenant.image ? [tenant.image] : []}
                />
              )}
            />
          </div>

          <div className='mb-5 flex w-full flex-col'>
            <label htmlFor='industry'>{t('selectYourIndustry')}</label>
            <select
              {...register('industry')}
              id='industry'
              className='mt-2 rounded border border-gray88 bg-white px-3 py-3'
            >
              <option value='' hidden>
                {t('selectYourIndustry')}
              </option>
              {industryOptions.map((value) => (
                <option
                  value={value}
                  key={value}
                  className='p-2 font-light text-copy-head'
                >
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          type='submit'
          className='w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark  disabled:opacity-50'
          disabled={isSubmitting}
        >
          {t('submit')}
        </button>
      </form>
    </div>
  );
};
