import { Input } from '~/components/form/TextField';
import { Modal } from '~/components/Modal';
import { ToggleGroup } from '~/components/toggle/ToggleGroup';
import { sortNatural, unique } from '~/helpers/array';
import { useFilter2 } from '~/hooks/useFilter';
import { useSearch } from '~/hooks/useSearch';
import { useSort } from '~/hooks/useSort';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAttribute } from '~/types';
import FilterIcon from '../filter/FunnelIcon';
import { FilterSort } from './filter/FilterSort';
import { SortBy } from './filter/SortBy';
import { StocktakeFilters } from './filter/StocktakeFilters';
import { ProcessAll } from './ProcessAll';
import { SMCCard, SMCCardProps } from './SMCCard';

type SMCCardGroupProps = {
  mode: 'adjustment' | 'stocktake';
  operator: 'add' | 'sub';
  spaceSkus: SMCCardProps['spaceSku'][];
  adjustmentLabel?: string;
  showHeroLabel?: boolean;
};

const SEARCH_OPTIONS = {
  keys: [
    'space.name',
    'sku.item.name',
    'sku.name',
    'sku.code',
    'sku.item.type',
    'sku.soh',
  ],
};

/**
 * Renders a group of `SMCCard`s from data passed in props
 */
export const SMCCardGroup = ({
  mode,
  operator,
  spaceSkus,
  adjustmentLabel,
  showHeroLabel,
}: SMCCardGroupProps) => {
  const { t } = useTranslation();
  const { results, query, search } = useSearch(spaceSkus, SEARCH_OPTIONS);
  const { filtered, filters, filter } = useFilter2(results);
  const { sorted, sortBy } = useSort(filtered);

  const [showFilters, setShowFilters] = useState(false);
  const spaces = useMemo(
    () => sortNatural(unique(spaceSkus.map((item) => item.space.name))),
    [spaceSkus]
  );
  const spaceAttributes = useMemo(
    () =>
      sortNatural(
        unique(
          spaceSkus.flatMap((item) => extractAttributes(item.space.attributes))
        )
      ),
    [spaceSkus]
  );
  const attributes = useMemo(
    () =>
      sortNatural(
        unique(
          spaceSkus.flatMap((item) => extractAttributes(item.sku.attributes))
        )
      ),
    [spaceSkus]
  );

  const processAll = mode === 'stocktake' && (
    <ProcessAll
      items={spaceSkus.map((item) => ({
        id: item.id,
        soh: item.soh,
        goal: item.soh,
      }))}
    />
  );

  if (showFilters) {
    return (
      <Modal
        component={StocktakeFilters}
        spaceAttributes={spaceAttributes}
        attributes={attributes}
        spaces={spaces}
        filters={filters}
        onClose={() => setShowFilters(false)}
        onFilter={filter}
        open
      />
    );
  }

  return (
    <ToggleGroup>
      {mode === 'stocktake' ? (
        <div className='flex items-stretch'>
          <Input
            className='flex-1'
            label={t('keywordSearch')}
            value={query}
            onChange={(event) => search(event.currentTarget.value)}
          />
          <button
            className='text-copy-alt block fill-current pl-2 text-sm font-bold'
            onClick={() => setShowFilters(true)}
          >
            <FilterIcon className='mr-2 inline-block h-4' />
            {t('filters')}
          </button>
        </div>
      ) : (
        <FilterSort query={query} search={search} sortBy={sortBy} />
      )}
      {mode === 'stocktake' && (
        <div className='mb-4'>
          <SortBy sortBy={sortBy} />
        </div>
      )}
      {filtered && filtered.length < spaceSkus.length && (
        <div className='text-copy-alt py-2 text-right text-xs font-bold'>
          {t('displayingResults', { count: filtered.length })}
        </div>
      )}
      {processAll}
      {(sorted ?? []).map((spaceSku) => {
        return (
          <SMCCard
            key={spaceSku.id}
            toggleKey={spaceSku.id}
            mode={mode}
            operator={operator}
            spaceSku={spaceSku}
            adjustmentLabel={adjustmentLabel}
            showHeroLabel={showHeroLabel}
          />
        );
      })}
    </ToggleGroup>
  );
};

function extractAttributes(
  attributes: Record<string, string> | IAttribute[] | undefined
) {
  if (attributes) {
    return Array.isArray(attributes)
      ? attributes.flatMap(({ name, value }) =>
          value.map((s) => `${name}: ${s}`)
        )
      : Object.keys(attributes).map((key) => `${key}: ${attributes[key]}`);
  }
  return [];
}
