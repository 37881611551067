import {
  ActionFunctionArgs,
  json,
  redirect,
  useLoaderData,
  useNavigate,
  useSubmit,
} from 'react-router-dom';
import { client } from '~/client';
import {
  JobTemplateForm,
  unserialize,
} from '~/components/job-template/JobTemplateForm';
import { graphql } from '~/gql';

const CreateJobTemplateDocument = graphql(`
  mutation CreateJobTemplate($input: JobTemplateInput!) {
    createJobTemplate(input: $input) {
      id
    }
  }
`);

/**
 * Data required to display the form and its options
 */
const CreateJobTemplateFormDocument = graphql(`
  query CreateJobTemplateForm {
    integrations
  }
`);

export async function action({ params, request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const input = unserialize(formData);

  const { data, error } = await client
    .mutation(CreateJobTemplateDocument, { input })
    .toPromise();

  if (error || !data) {
    throw new Response(error?.message, { status: 500 });
  }

  if (formData.has('noRedirect')) {
    return json({ success: true });
  }
  return redirect(`/job-templates/${data.createJobTemplate.id}`);
}

export async function loader() {
  const { data, error } = await client
    .query(
      CreateJobTemplateFormDocument,
      {},
      { requestPolicy: 'cache-and-network' }
    )
    .toPromise();

  if (error || !data) {
    throw new Response(error?.message, { status: 500 });
  }

  return data;
}

export default function CreateTemplateRoute() {
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const navigate = useNavigate();
  const submit = useSubmit();

  function handleClose() {
    navigate('..');
  }

  return (
    <JobTemplateForm data={data} onClose={handleClose} onSubmit={submit} />
  );
}
