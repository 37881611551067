import { isEmpty, merge } from 'lodash'
import { useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { TransferTypeForm } from './TransferTypeForm'
import { RemoveForm } from './RemoveForm'
import { DestinationForm } from './DestinationForm'
import { AddForm } from './AddForm'
import { useTranslation } from 'react-i18next'

const steps = [TransferTypeForm, RemoveForm, DestinationForm, AddForm]

export const TransferStock = ({ returnTo }) => {
  const [values, setValues] = useState({})
  const [step, setStep] = useState(0)
  const stepMax = steps.length - 1
  const FormComponent = useMemo(() => steps[step], [step])
  const navigate = useNavigate()
  const [prevData, setPrevData] = useState()
  const { t } = useTranslation()

  const handleSuccess = (newValues, formikProps = null) => {
    setValues(merge(values, newValues))

    if (step === stepMax) {
      async function submit() {
        const axios = window.axios
        try {
          await axios.post('transfer-stock', values)
          toast.success(t('Success'))
          navigate('..')
        } catch (error) {
          const errorMessage = error.response
            ? JSON.stringify(error.response.data)
            : 'Unexpected error'
          toast.error(errorMessage)
          formikProps && formikProps.setSubmitting(false)
        }
      }
      submit()
    } else {
      if (!isEmpty(prevData) && prevData[step]) {
        const removedValues = values['remove'] && step === 1 ? newValues : ''
        if (removedValues !== '') {
          values['remove'] = removedValues['remove']
          setValues(values)
        }
        prevData[step] = newValues
        setPrevData(prevData)
      } else {
        setPrevData(merge(prevData, { [step]: newValues }))
      }
      setStep(step + 1)
    }
  }

  const handlePrevState = (value) => {
    setStep(value)
  }

  return (
    <FormComponent
      prev={values}
      onSuccess={handleSuccess}
      step={step}
      onPrevState={handlePrevState}
      prevData={prevData}
      returnTo={returnTo}
    />
  )
}
