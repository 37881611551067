import { useEffect } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
};

export function PopoverStateChangedEffect({ open, onClose }: Props) {
  useEffect(() => (open ? undefined : onClose()), [open]);

  return null;
}
