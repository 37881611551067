import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import * as Yup from 'yup';
import { FindAddContact } from '~/components/FindAddContact';
import { useSites } from '~/components/FindAddSite/FindAddSite';
import { Prompt } from '~/components/Prompt';
import { TagCombobox, TagEntityType } from '~/components/TagCombobox';
import { useTags } from '~/components/TagCombobox/tags';
import { AttributeSelect } from '~/components/form/AttributeSelect';
import { Input } from '~/components/form/TextField';
import { TimePicker } from '~/components/form/TimePicker';
import { Switch } from '~/components/form/switch/Switch';
import { TextArea } from '~/components/form/textarea/Basic';
import { Label } from '~/components/ui/Label';
import { ReadValue } from '~/components/ui/ReadValue';
import {
  UploadResult,
  toAttachmentInput,
  toFormValue,
} from '~/components/upload2';
import { ControlledUpload } from '~/components/upload2/Upload';
import { graphql } from '~/gql';
import {
  EditJobFormQuery,
  JobQuery,
  JobStatus,
  TaskInput,
} from '~/gql/graphql';
import { splice } from '~/helpers/array';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useContacts } from '~/routes/resources/contacts';
import { taskToInput } from '.';
import { Accordion } from '../../ui/Accordion';
import { ManageTasks } from '../CreateJobForm/ManageTasks';
import { CustomTaskForm } from '../CustomTaskForm';
import { JobMenu } from '../JobView/JobMenu';
import { BookingSection } from '../JobView/JobReadView/BookingSection';
import { SaveJobActions, SubmitOptions } from '../SaveJobActions';
import { formatLocalDate } from '../formatDate';
import { getDefaultScheduledEndTime } from '../getDefaultScheduledEndTime';

type Props = {
  job: NonNullable<JobQuery['job']>;
  form: EditJobFormQuery;
  onSuccess: (status: JobStatus) => void;
};

type FormValues = {
  test: string[];
  name: string;
  customer: string;
  scheduledStartStrict: string;
  scheduledStartDate: string;
  scheduledStartTime: string;
  scheduledEndStrict: string;
  scheduledEndDate: string;
  scheduledEndTime: string;
  owner: string[] | null;
  assignee: string[] | null;
  include: string[] | null;
  includeAttributes: string[] | null;
  tags?: string[];
  notes: string;
  attachments: UploadResult[];
  enforceOrder: boolean;
  guestyCleaningStatus: boolean;
};

const UpdateJobDocument = graphql(`
  mutation UpdateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      id
      status
      name
      notes
      attachments {
        ...AttachmentFields
      }
      scheduleStart
      scheduleEnd
      assignee {
        id
        name
      }
      customer {
        id
        name
      }
      enforceOrder
      updatedAt
      tasks {
        ...TaskData
      }
    }
  }
`);

const EditJobSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

export function EditJobForm({ job, form, onSuccess }: Props) {
  const { t } = useTranslation(['translation', 'job']);
  const [tasks, setTasks] = useState<TaskInput[]>(() => {
    if (job) {
      return job.tasks.map(taskToInput);
    }
    return [];
  });
  const [editTask, setEditTask] = useState<string | null>(null);
  const [, updateJob] = useMutation(UpdateJobDocument);
  const [sites] = useSites();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const [allTags] = useTags(TagEntityType.Job);
  const outletContext = useOutletContext<{ scrollToJob?: () => void }>();

  const contacts = useContacts();

  // unmask fragment data
  // const jobTemplates = getFragmentData(JobTemplateFields, form.jobTemplates);

  const defaultValues = {
    location: job.location
      ? `${job.location.__typename}:${job.location.id}`
      : '',
    includeAttributes: job?.attributes?.map((a) => a.id) ?? null,
    tags: job.jobTags?.map((t) => t.id) ?? [],
    notes: job.notes ?? '',
    attachments: job.attachments ? job.attachments.map(toFormValue) : [],
    owner: job.owners ? job.owners.map((o) => o.id) : null,
    assignee: job.assignees ? job.assignees.map((a) => a.id) : null,
    include: job.include ? job.include.map((i) => i.id) : null,
    scheduledStartStrict: job.scheduledStartStrict === false ? '0' : '1',
    scheduledStartDate: job.scheduledStartDate ?? '',
    scheduledStartTime: job.scheduledStartTime ?? '',
    scheduledEndStrict: job.scheduledEndStrict === false ? '0' : '1',
    scheduledEndDate: job.scheduledEndDate ?? '',
    scheduledEndTime: job.scheduledEndTime ?? '',
    enforceOrder: job.enforceOrder,
    guestyCleaningStatus: job.guestyCleaningStatus === 'clean',
    name: job.name,
  };

  const {
    formState: { isDirty, errors, isSubmitting },
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(EditJobSchema),
  });

  const isComplete = job.status === JobStatus.Complete;

  const onSubmit =
    ({
      status,
      options = {},
    }: {
      status: JobStatus;
      options?: SubmitOptions;
    }) =>
    async (data: FormValues) => {
      const {
        name,
        customer,
        notes,
        owner,
        assignee,
        include,
        enforceOrder,
        scheduledStartStrict,
        scheduledEndStrict,
        tags,
      } = data;
      const res = await updateJob({
        input: {
          id: job.id,
          name: name,
          customer: customer,
          scheduledStartStrict,
          scheduledStartDate: data.scheduledStartDate || null,
          scheduledStartTime: data.scheduledStartTime || null,
          scheduledEndStrict,
          scheduledEndDate: data.scheduledEndDate || null,
          scheduledEndTime: data.scheduledEndTime || null,
          owner,
          assignee,
          include,
          includeAttributes: data.includeAttributes,
          tags,
          notes,
          attachments: data.attachments.map(toAttachmentInput),
          enforceOrder: enforceOrder,
          tasks,
          status,
          notify: options.notify,
          comment: options.comment,
          sendMeACopy: options.sendMeACopy === true,
          includePdf: options.includePdf === true,
          guestyCleaningStatus: data.guestyCleaningStatus ? 'clean' : null,
        },
      });

      setTimeout(() => outletContext?.scrollToJob?.(), 0);

      if (res.error) {
        console.error(res.error.message);
        return;
      }

      onSuccess(status);

      // TODO below could be moved up into onSuccess callback if it makes more sense??
      if (status !== JobStatus.Created) {
        navigate(routerLocation.pathname.slice(0, -4));
      } else {
        // ! this requires more than a form reset, e.g. tasks will need to retrieve their DB id
        reset(data);
        toast.success(`${t('statuses.Created')} saved`);
      }
    };

  const updateTask = (task: TaskInput) => {
    setTimeout(() => {
      setTasks((tasks) =>
        splice(
          tasks,
          tasks.findIndex(({ id }) => id === task.id),
          1,
          task
        )
      );
    }, 1);
    setEditTask(null);
  };

  const handleDeleteTask = (id: string) => {
    const task = tasks.find((t) => t.id === id);
    if (!task) return;
    updateTask({ ...task, _destroy: true });
  };

  const [task, setTask] = useState<TaskInput | null>(null);
  const [shownTaskType, setShownTaskType] = useState<string | null>(null);

  useEffect(() => {
    setTask(tasks?.find((task) => task.id === editTask) || null);
    setShownTaskType(tasks?.find((item) => item.id === editTask)?.type || null);
  }, [tasks, editTask]);

  const updateTaskType = (taskId: string, taskType: string) => {
    const tasksListCopy = tasks.slice();
    const taskIndex = tasksListCopy.findIndex((task) => task.id === taskId);
    if (taskIndex < 0) return;
    tasksListCopy[taskIndex].type = taskType;
    setTasks(tasksListCopy);
  };

  const taskSection = task ? (
    <CustomTaskForm
      locationId={
        job.location
          ? `${job.location.__typename}:${job.location.id}`
          : undefined
      }
      task={task}
      taskType={shownTaskType}
      updateTaskType={(id, type) => updateTaskType(id, type)}
      onApply={updateTask}
      onClose={() => setEditTask(null)}
      onDelete={handleDeleteTask}
    />
  ) : null;

  const hasTasks = tasks.filter((t) => !t._destroy);
  const guestyEnabled = form.integrations.includes('guesty');

  const location =
    job.location && job.location.__typename === 'Site'
      ? sites.find((site) => site.id === job.location?.id)
      : null;
  const where = location?.name;
  const assignee = watch('assignee');
  //const who = job.assignees?.map(({ id, name }) => <p key={id}>{name}</p>);
  const who =
    assignee &&
    contacts
      .filter(({ id }) => assignee.includes(id))
      .map(({ name }) => name)
      .join(', ');
  const startTime = watch('scheduledStartTime');
  const startDate = watch('scheduledStartDate');
  const endDate = watch('scheduledEndDate');

  const thisJob = {
    timeZone: job?.timeZone,
    scheduleStart: startDate,
    scheduleEnd: endDate,
    // completedAt?: any;
    // createdAt?: any;
    // scheduledStartTime?: startTime;
    // scheduledEndTime?: any;
  };

  const when =
    startDate && endDate
      ? `${formatLocalDate(thisJob, 'scheduledStart')} to ${formatLocalDate(
          thisJob,
          'scheduledEnd'
        )}`
      : startDate
      ? formatLocalDate(thisJob, 'scheduledStart')
      : endDate
      ? formatLocalDate(thisJob, 'scheduledEnd')
      : null;

  const [accordionOpenOnError, setAccordionOpenOnError] = useState<
    number | null
  >(null);

  return (
    <div className='flex h-full items-stretch justify-end'>
      <Prompt when={!isSubmitting && isDirty} cancel='Keep Editing' />
      <SideLayout>
        <SideLayout.Head
          onClose={() => {
            navigate(
              job.status === JobStatus.Created
                ? '..'
                : routerLocation.pathname.slice(0, -4)
            );
          }}
          rightSlot={
            [JobStatus.Created, JobStatus.Declined].includes(job.status) && (
              <JobMenu job={job} editing />
            )
          }
        >
          Edit {job.name}
        </SideLayout.Head>
        <SideLayout.Body className='px-4 pb-36 lg:pb-0'>
          <Accordion
            defaultOpen={
              accordionOpenOnError !== null ? accordionOpenOnError : 0
            }
          >
            <Accordion.Card>
              <Accordion.Button summary={where} help='Place'>
                Where
              </Accordion.Button>
              <Accordion.Panel>
                {job.location && (
                  <ReadValue label={t('site')}>{job.location.name}</ReadValue>
                )}
                <BookingSection
                  className={isMobile ? 'pb-[16px]' : 'pb-[21px]'}
                  job={job}
                />
              </Accordion.Panel>
            </Accordion.Card>
            <Accordion.Card>
              <Accordion.Button summary={who} help='Unassigned'>
                Who
              </Accordion.Button>
              <Accordion.Panel>
                <Controller
                  name='owner'
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FindAddContact
                      optionsBoxHeight={
                        isMobile
                          ? 'max-h-[calc(100vh-305px)]'
                          : 'max-h-[calc(100vh-480px)]'
                      }
                      label={t('job:form.owner')}
                      error={error?.message}
                      value={
                        value
                          ? contacts.filter(({ id }) => value.includes(id))
                          : []
                      }
                      onChange={(newValue) => {
                        onChange(
                          newValue && newValue.length > 0
                            ? newValue.map(({ id }) => id)
                            : []
                        );
                      }}
                      isTeamMember
                      multiple
                      isAbsolute={false}
                    />
                  )}
                />
                {isComplete ? (
                  <ReadValue label='Assign to'>
                    {job.assignees?.map(({ id, name }) => (
                      <p key={id}>{name}</p>
                    ))}
                  </ReadValue>
                ) : (
                  <Controller
                    name='assignee'
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FindAddContact
                        optionsBoxHeight={
                          isMobile
                            ? 'max-h-[calc(100vh-305px)]'
                            : 'max-h-[calc(100vh-480px)]'
                        }
                        label={t('job:form.assignee')}
                        contacts={contacts ?? []}
                        error={error?.message}
                        value={
                          value?.length
                            ? contacts.filter(({ id }) => value.includes(id))
                            : []
                        }
                        onChange={(newValue) => {
                          if (newValue && newValue.length > 0) {
                            onChange(newValue.map(({ id }) => id));
                          } else {
                            onChange(null);
                          }
                        }}
                        multiple
                        isAbsolute={false}
                      />
                      // <FindAddContact
                      //   label={t('job:form.assignee')}
                      //   type='Assignee'
                      //   value={value}
                      //   onChange={onChange}
                      //   showEmail
                      // />
                    )}
                  />
                )}
                <Controller
                  name='include'
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FindAddContact
                      optionsBoxHeight={
                        isMobile
                          ? 'max-h-[calc(100vh-305px)]'
                          : 'max-h-[calc(100vh-480px)]'
                      }
                      label={t('job:form.include')}
                      contacts={contacts ?? []}
                      error={error?.message}
                      value={
                        contacts && value
                          ? contacts.filter(({ id }) => value.includes(id))
                          : []
                      }
                      onChange={(newValue) => {
                        onChange(
                          newValue && newValue.length > 0
                            ? newValue.map(({ id }) => id)
                            : []
                        );
                      }}
                      multiple
                      hasSelectAll
                      isAbsolute={false}
                    />
                  )}
                />
              </Accordion.Panel>
            </Accordion.Card>

            <Accordion.Card>
              <Accordion.Button help='Unscheduled' summary={when}>
                When
              </Accordion.Button>
              <Accordion.Panel>
                {isComplete ? (
                  <ReadValue label='Schedule for'>
                    {formatLocalDate(job, 'scheduledStart')}
                  </ReadValue>
                ) : (
                  <>
                    <div className='mb-2.5 hidden gap-4'>
                      <label>
                        <input
                          {...register('scheduledStartStrict')}
                          type='radio'
                          value='1'
                        />{' '}
                        Start at
                      </label>
                      <label>
                        <input
                          {...register('scheduledStartStrict')}
                          type='radio'
                          value='0'
                        />{' '}
                        Start from
                      </label>
                    </div>
                    <div className='flex w-full items-stretch gap-3 text-left'>
                      <Input
                        {...register('scheduledStartDate')}
                        label={t('job:form.scheduleStart')}
                        type='date'
                      />
                      <Controller
                        control={control}
                        name='scheduledStartTime'
                        render={({ field: { value, onChange } }) => (
                          <TimePicker
                            label='Time'
                            focusValue='09:00'
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      <button
                        className='text-grey-30'
                        onClick={() => {
                          setValue('scheduledStartDate', '');
                          setValue('scheduledStartTime', '');
                        }}
                      >
                        <FontAwesomeIcon
                          className='h-5 w-5'
                          icon={faTrashAlt}
                        />
                      </button>
                    </div>
                    <div className='mb-2.5 hidden gap-4'>
                      <label>
                        <input
                          {...register('scheduledEndStrict')}
                          type='radio'
                          value='1'
                        />{' '}
                        End at
                      </label>
                      <label>
                        <input
                          {...register('scheduledEndStrict')}
                          type='radio'
                          value='0'
                        />{' '}
                        End before
                      </label>
                    </div>
                    <div className='flex w-full items-stretch gap-3 text-left'>
                      <Input
                        {...register('scheduledEndDate')}
                        label={t('job:form.scheduleEnd')}
                        type='date'
                      />
                      <Controller
                        control={control}
                        name='scheduledEndTime'
                        render={({ field: { value, onChange } }) => (
                          <TimePicker
                            label='Time'
                            focusValue={getDefaultScheduledEndTime(
                              watch('scheduledStartTime')
                            )}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      <button
                        className='text-grey-30'
                        onClick={() => {
                          setValue('scheduledEndDate', '');
                          setValue('scheduledEndTime', '');
                        }}
                      >
                        <FontAwesomeIcon
                          className='h-5 w-5'
                          icon={faTrashAlt}
                        />
                      </button>
                    </div>
                  </>
                )}
              </Accordion.Panel>
            </Accordion.Card>
            <Accordion.Card>
              <Accordion.Button help='Description' summary={watch('name')}>
                What
              </Accordion.Button>
              <Accordion.Panel>
                <Input
                  label={t('job:form.name')}
                  className='mb-6'
                  defaultValue={job.name}
                  {...register('name')}
                  error={errors.name?.message}
                  maxLength={250}
                  required
                  autoFocus
                />
                <Label>{t('notes')}</Label>
                <TextArea {...register('notes')} />

                <span className='ml-1 text-sm font-medium text-grey-50'>
                  {t('attachment_plural')}
                </span>
                <Controller
                  name='attachments'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <ControlledUpload
                      id={`EditJobForm:${job.id}`}
                      value={value ?? []}
                      onChange={onChange}
                      multiple
                    />
                  )}
                />

                <Controller
                  name='includeAttributes'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <AttributeSelect
                      label={t('translation:attribute_plural')}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  name='tags'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TagCombobox
                      containerStyles={
                        isMobile
                          ? '!max-h-[calc(100vh-190px)]'
                          : '!max-h-[calc(100vh-410px)]'
                      }
                      entityType={TagEntityType.Job}
                      options={allTags}
                      value={
                        value ? allTags.filter((t) => value.includes(t.id)) : []
                      }
                      onChange={(tags) => {
                        onChange(tags.map(({ id }) => id));
                      }}
                    />
                  )}
                />

                {guestyEnabled && (
                  <label className='mb-5'>
                    <input
                      {...register('guestyCleaningStatus')}
                      type='checkbox'
                    />{' '}
                    Update Guesty cleaning status
                  </label>
                )}

                <ManageTasks
                  jobTemplates={form.jobTemplates ?? []}
                  state={[tasks, setTasks]}
                  onDeleteTask={handleDeleteTask}
                  onShowTask={setEditTask}
                  onTaskTypeUpdate={updateTaskType}
                  onUseTemplateSelect={(template) => {
                    const values = getValues();
                    if (template.tags && !values.tags) {
                      setValue(
                        'tags',
                        template.tags.map((tag) => tag.id)
                      );
                    }
                    if (template.notes && !values.notes) {
                      setValue('notes', template.notes);
                    }
                    if (
                      template.includeAttributes &&
                      !values.includeAttributes
                    ) {
                      setValue('includeAttributes', template.includeAttributes);
                    }
                    if (template.attachments && !values.attachments) {
                      // setValue('attachments', template.attachments);
                    }
                  }}
                />
                {hasTasks.length > 0 && (
                  <>
                    <Controller
                      name='enforceOrder'
                      control={control}
                      render={({ field }) => (
                        <Switch
                          name='enforceOrder'
                          label='Enforce task order'
                          help='Each task must be completed before the next task can be started.'
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </>
                )}
              </Accordion.Panel>
            </Accordion.Card>
          </Accordion>
          <br />
          <br />
        </SideLayout.Body>
        <SideLayout.Foot
          className={classNames('rounded p-4', {
            '!fixed': isMobile,
          })}
        >
          <SaveJobActions
            assignee={watch('assignee')}
            jobName={watch('name')}
            status={job.status}
            loading={isSubmitting}
            notifyDefault={false}
            onClick={(status, options) =>
              handleSubmit(onSubmit({ status, options }), (errors) => {
                if (errors.name) {
                  // Open WHAT section if Job Name fails on form validation
                  setAccordionOpenOnError(null);
                  setTimeout(() => setAccordionOpenOnError(3), 0);
                }
              })()
            }
          />
        </SideLayout.Foot>
      </SideLayout>
      {taskSection}
    </div>
  );
}
