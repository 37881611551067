import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { modifyProperties } from '~/api/assets';
import { PromptEffect } from '~/components/Prompt';
import { ItemDetail } from '~/components/asset-config/ItemDetail';
import { ActionForm } from '~/components/form/ActionForm';
import { TextField } from '~/components/form/TextField';
import { Card } from '~/components/ui/Card';
import { AssetConfigData, Selected } from '.';

type Modification = {
  price: string | number;
  capacity: string | number;
  target: string | number;
  sku_id: string | number;
  sku_code: string;
  sku_name: string;
  item_type: string;
  image: string | null;
  reorder_level: string | number | null;
};

type ModifyCardProps = {
  index: number;
  modify: Modification;
};

const ModifyCard = ({ index, modify }: ModifyCardProps) => {
  const { t } = useTranslation();
  return (
    <Card color='blue' shadow className='mb-4'>
      <div style={{ padding: '1rem' }}>
        <ItemDetail item={modify} />
        <div className='mt-4 flex justify-end gap-2'>
          <TextField
            label={t('price')}
            name={`modify[${index}].price`}
            align='right'
            type='number'
            min='0'
            step='any'
          />
          <TextField
            label={t('target')}
            name={`modify[${index}].target`}
            align='right'
            type='number'
            min='0'
          />
          <TextField
            label={t('reorderLevel')}
            name={`modify[${index}].reorder_level`}
            align='right'
            type='number'
            min='0'
          />
        </div>
      </div>
    </Card>
  );
};

type ModifyPropertiesProps = {
  selected: Selected;
  data: AssetConfigData[];
  onSuccess: () => void;
};

export const ModifyProperties = ({
  selected,
  data,
  onSuccess,
}: ModifyPropertiesProps) => {
  const { t } = useTranslation('config');

  const spaceSkuIds: { space_id: number; sku_id: number }[] = [];

  const modify = Object.keys(selected).reduce((prev: Modification[], i) => {
    const row = data.find((o) => o.id === i);

    if (!row || !row.sku_id) {
      return prev;
    }

    // Side-effect: Keep track of selected space skus
    spaceSkuIds.push({ space_id: row.space_id, sku_id: row.sku_id });

    const duplicate = prev.find((v) => v.sku_id === row.sku_id);

    if (duplicate) {
      // FIXME mutations not so easy to follow...
      if (row.price !== duplicate.price) duplicate.price = '';
      if (row.capacity !== duplicate.capacity) duplicate.capacity = '';
      if (row.target !== duplicate.target) duplicate.target = '';

      return prev;
    }

    return prev.concat({
      price: row.price ? row.price / 100 : '',
      capacity: row.capacity || '',
      target: row.target || '',
      sku_id: row.sku_id,
      sku_code: row.sku_code,
      sku_name: row.sku_name,
      item_type: row.item_type,
      image: row.image,
      reorder_level: row.reorder_level,
    });
  }, []);

  return (
    <ActionForm
      action={modifyProperties('commit')}
      buttonText='Commit'
      title={t('modifyProperties')}
      initialValues={{
        spaceSkuIds,
        modify,
      }}
      validationSchema={Yup.object({
        modify: Yup.array()
          .of(
            Yup.object({
              price: Yup.number().positive('Must be a positive number'),
              capacity: Yup.number().min(0, 'Must be a positive number'),
              target: Yup.number()
                .min(0, 'Must be a positive number')
                .when('capacity', (capacity, schema) => {
                  if (capacity) {
                    return schema.max(
                      capacity,
                      'Must be less than or equal to capacity'
                    );
                  }
                }),
            })
          )
          .min(1),
      })}
      onSuccess={onSuccess}
      enableReinitialize
    >
      <PromptEffect />
      <Field name='modify'>
        {({ form }: FieldProps<unknown, { modify: Modification[] }>) => (
          <>
            {form.values.modify.length < 1 && <p>No items selected</p>}
            {form.values.modify.map((row, i) => (
              <ModifyCard key={i} index={i} modify={row} />
            ))}
          </>
        )}
      </Field>
    </ActionForm>
  );
};
