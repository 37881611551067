import { CogIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
};

export const SettingsLink = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      className='hover:text-brand text-copy-alt flex flex-row items-center justify-start py-4 font-medium lg:py-0 lg:text-base lg:font-normal'
    >
      <span>
        <CogIcon
          strokeWidth={1}
          className='color-current mr-3 block h-8 w-8 flex-1'
        />
      </span>
      {t('setting_plural')}
    </div>
  );
};
