import { MouseEventHandler } from 'react';
import { useParams } from 'react-router-dom';
import { TagsBuilder } from '~/components/TagCombobox/TagsBuilder';
import { BuildingPlaceholder } from '~/components/ui/BuildingPlaceholder';
import { CardNewV2 } from '~/components/ui/CardNewV2';
import { getOccupationData } from '~/components/ui/CardNewV2/OccupationInformation';
import { Checkbox, CheckboxState } from '~/components/ui/nucleus/Checkbox';
import { JobStatus } from '~/generated/graphql';
import { TJob } from '~/routes/jobs';
import { Hero } from './Hero';

const { Created, Cancelled } = JobStatus;

type Props = {
  job: TJob;
  small?: boolean;
  selected?: boolean;
  onSelect: (checked: boolean, shift: boolean, single?: boolean) => void;
};

export function JobListItem({ job, small, selected, onSelect }: Props) {
  const { jobId } = useParams();

  const handleClick: MouseEventHandler = (event) => {
    if (job.status === Cancelled) return;

    if (event.shiftKey) {
      event.preventDefault();
      onSelect(!selected, true);
      return;
    }
    onSelect(true, false, true);
  };

  const occupationData = getOccupationData(job);

  return (
    <div className='flex flex-1 items-stretch gap-2 overflow-x-hidden'>
      {selected !== undefined && (
        <div className='mb-3 hidden w-6 shrink-0 items-center justify-start overflow-hidden p-1 lg:flex'>
          <Checkbox
            value={selected ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
            className='disabled:border-grey-20 disabled:bg-grey-10'
            disabled={job.status === Cancelled}
            onClick={(event) => onSelect(!selected, event.shiftKey)}
          />
        </div>
      )}

      <CardNewV2
        id={`JobListItem-${job.id}`}
        linkTo={job.status === Created ? `${job.id}/edit` : job.id}
        isSelected={jobId === job.id}
        titleText={job.name}
        image={job.image ?? <BuildingPlaceholder />}
        showImage={true}
        secondaryText={job.location?.name}
        heroElement={<Hero job={job} small={small} />}
        status={job.status}
        user={job.assignees?.[0]}
        small={small}
        onClick={handleClick}
        showTertiaryTextOnMobile
        tertiaryText={<TagsBuilder tags={job.jobTags ?? []} className='mt-1' />}
        occupationData={occupationData}
      />
    </div>
  );
}
