import { useField } from 'formik';
import { Pill } from '~/components/ui/nucleus/Pill';
import { Switch } from '~/components/ui/nucleus/Switch';
import styles from './CheckRadio.module.css';

type Props = {
  name?: string;
  value: string;
  label: string;
  toggle: string;
  onChange: (value: string) => void;
};

export const Checkbox = ({
  name,
  value,
  label,
}: {
  name: string;
  value: string;
  label: string;
}) => {
  const [field, meta, helpers] = useField<string | string[]>(name);
  const checked = Array.isArray(field.value)
    ? field.value && field.value.indexOf(value) > -1
    : field.value === value;

  const handleChange = () => {
    if (Array.isArray(field.value)) {
      checked
        ? helpers.setValue(field.value.filter((i) => i !== value))
        : helpers.setValue([...field.value, value]);
    } else {
      checked
        ? helpers.setValue(meta.initialValue || '')
        : helpers.setValue(value);
    }
  };

  return (
    <label className={styles.label}>
      <input
        type='checkbox'
        className={styles.input}
        checked={checked}
        onChange={handleChange}
      />
      {label}
    </label>
  );
};

export const FilterToggle = ({
  name,
  value,
  label,
  toggle,
  onChange,
}: Props) => {
  // Temporarily keep using Formik while transitioning to new repo structure
  // if (name) {
  //   const [field, meta, helpers] = useField<string | string[]>(name);
  // }

  // if (Array.isArray(field.value)) {
  //   throw new Error('FilterToggle cannot be used in group, single value only');
  // }

  // if (typeof field.value !== 'string') {
  //   throw new Error('Invalid field value');
  // }

  const checked = value !== '';

  // const handleChange = () => {
  //   checked
  //     ? helpers.setValue(meta.initialValue || '')
  //     : helpers.setValue(value);
  // };

  return (
    <Pill as='label' active={checked}>
      <input
        type='checkbox'
        className='hidden'
        checked={checked}
        // If it is being toggled ON, return the toggle value
        // If it is being toggled OFF, return an empty string
        // onChange will probably set value to whatever is returned
        onChange={() => onChange(value ? '' : toggle)}
      />
      <span className='truncate'>{label}</span>
    </Pill>
  );
};

export const FilterViewToggle = ({
  name,
  value,
  label,
  toggle,
  onChange,
}: Props) => {
  const checked = value !== '';

  return (
    <Switch
      key={label}
      name={name}
      label={label}
      active={!checked}
      onChange={() => onChange(value ? '' : toggle)}
    />
  );
};

export const Radio = ({
  name,
  value,
  label,
  onChange,
}: {
  name: string;
  value: string;
  label: string;
  onChange: (value: string) => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<string>(name);
  const checked = field.value === value;

  const handleChange = () => {
    helpers.setValue(value);
    onChange && onChange(value);
  };

  return (
    <label className={styles.label}>
      <input
        type='radio'
        className={styles.input}
        checked={checked}
        onChange={handleChange}
      />
      {label}
    </label>
  );
};
