import { forwardRef } from 'react';

type Props = {
  as?: React.ElementType;
  children: React.ReactNode;
};

export const Label = forwardRef<HTMLElement, Props>(
  ({ as = 'label', children }, ref) => {
    const Component = as;
    return (
      <Component
        ref={ref}
        className='ml-1 block text-sm font-medium text-grey-50'
      >
        {children}
      </Component>
    );
  }
);
