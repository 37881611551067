import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useEnums } from '~/App'
import { ActionForm } from '~/components/form/ActionForm'
import { StatusToggle } from '~/components/form/StatusToggle'
import { TextField } from '~/components/form/TextField'
import { AttributesField } from '~/components/form/attributes/AttributesField'
import { TextAreaField } from '~/components/form/textarea/TextArea'
import { UploadField } from '~/components/upload/Upload'
import { updateInitialValues } from '~/helpers'
import { useAssetModel } from '~/routes/asset-models/[id]'
import { ComboboxField } from '../ui/Combobox'

export function AssetModelForm({ action, title, onSuccess }) {
  const { t } = useTranslation(['translation', 'asset'])
  const navigate = useNavigate()
  const { modelId } = useParams()
  const { assetType: modelOptions } = useEnums()
  const model = useAssetModel(modelId)
  const initialValues = updateInitialValues(
    {
      id: null,
      status: 'Active',
      brand: '',
      name: '',
      type: '',
      notes: '',
      attributes: [],
      images: [],
    },
    model
  )

  return (
    <ActionForm
      action={action}
      title={title}
      initialValues={initialValues}
      onSuccess={({ data }) => {
        navigate('/asset-models/' + data.id)
      }}
      onCancel={() => navigate(`/asset-models/${modelId || ''}`)}
    >
      <StatusToggle />
      <TextField name='brand' label={t('asset:brand')} />
      <TextField name='name' label={t('asset:model')} />
      <ComboboxField name='type' label={t('assetType')} items={modelOptions} />
      <AttributesField type='AssetModel' />
      <TextAreaField name='notes' label={t('description')} />
      <UploadField name='images' />
    </ActionForm>
  )
}

AssetModelForm.propTypes = {
  modelId: PropTypes.string,
  action: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}
