import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Menu } from '~/components/ui/Menu';
import { ListAssetsQuery } from '~/gql/graphql';
import { useAuth } from '~/hooks';

type Props = {
  asset?: ListAssetsQuery['assets'][number];
};

export function AssetMenu({ asset }: Props) {
  const { is } = useAuth();
  const { t } = useTranslation(['translation', 'asset']);

  if (!asset) return null;

  const qsLocation = `locationType=Asset&locationName=${encodeURIComponent(
    asset.name
  )}`;

  return (
    <Menu>
      <Menu.Item as={Link} to={`/assets/${asset.id}/edit`}>
        {t('asset:editAsset')}
      </Menu.Item>
      {is('operator') && (
        <Menu.Item as={Link} to={`/assets/${asset.id}/deploy`}>
          {asset?.deployment ? t('asset:redeploy') : t('asset:deploy')}
        </Menu.Item>
      )}
    </Menu>
  );
}
