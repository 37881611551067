import { useFormikContext } from 'formik';
import CheckCircleIcon from '~/CheckCircleIcon';
import WarningIcon from '~/WarningIcon';
import { ToggleCard } from '~/components/toggle/ToggleCard';
import { ItemType } from '~/generated/graphql';
import { SkuInfo } from './SkuInfo';
import { SMCInputGroupField } from './SMCInputGroup';
import { IAttribute } from '~/types';

export type SMCCardProps = {
  toggleKey: string;
  mode: 'adjustment' | 'stocktake';
  operator: 'add' | 'sub';
  spaceSku: {
    id: string;
    space: { name: string; attributes?: IAttribute[] };
    sku: {
      name: string;
      code?: string | null;
      image?: string | null;
      item: { name: string; type: ItemType };
      attributes?: Record<string, string>;
    };
    soh: number;
  };
  adjustmentLabel?: string;
  showHeroLabel?: boolean;
};

/**
 * An individual stock manipulation item card
 */
export const SMCCard = ({
  toggleKey,
  mode,
  operator,
  spaceSku,
  adjustmentLabel = 'Adjustment',
  showHeroLabel = true,
}: SMCCardProps) => {
  const { id, space, sku, soh: stockOnHand } = spaceSku;
  const { values } = useFormikContext<{
    adjustments: Record<string, string | number>;
  }>();
  const isEmpty = !values.adjustments[id] || values.adjustments[id] === '';

  const color = isEmpty
    ? 'smoke'
    : mode === 'stocktake' && values.adjustments[id] !== stockOnHand
    ? 'warning'
    : 'green';

  const confirmed =
    mode === 'stocktake' && values.adjustments[id] !== stockOnHand
      ? false
      : true;
  return (
    <>
      <div className='mb-1 bg-white px-0 py-1 text-xs font-medium'>
        {space.name}
      </div>
      <ToggleCard toggleKey={toggleKey} color={color} shadow>
        {/* @ts-expect-error migrate to 18 FIXME */}
        {(isOpen, toggle) => (
          <div
            className='cursor-pointer p-4 text-xs'
            onMouseDown={(event) => toggle()}
          >
            <div className='flex items-stretch'>
              <SkuInfo
                name={(sku.item.name + ' ' + (sku.name ?? '')).trim()}
                code={sku.code}
                itemType={sku.item.type}
                image={sku.image}
              />
              {isOpen ? (
                <div className='w-24 text-right'>
                  <SMCInputGroupField
                    mode={mode}
                    operator={operator}
                    label={adjustmentLabel}
                    soh={stockOnHand}
                    name={`adjustments.${id}`}
                    onBlur={(event) => () => {}}
                  />
                </div>
              ) : (
                <div className='text-right'>
                  {mode === 'adjustment' && color === 'green' && (
                    <>
                      <span className='block whitespace-nowrap text-4xl'>
                        {{ add: '+', sub: '-' }[operator]}
                        {values['adjustments'][id] ?? stockOnHand}
                      </span>
                      {showHeroLabel && (
                        <span
                          className='mt-2'
                          style={{
                            fontWeight: 500,
                            color: 'var(--color-green)',
                          }}
                        >
                          Adjusted
                          <CheckCircleIcon
                            style={{
                              width: '1.5em',
                              height: '1.5em',
                              marginLeft: 4,
                              fill: 'var(--color-green)',
                              verticalAlign: 'text-bottom',
                            }}
                          />
                        </span>
                      )}
                    </>
                  )}

                  {((mode === 'adjustment' && color === 'smoke') ||
                    mode === 'stocktake') && (
                    <>
                      <span className='block whitespace-nowrap text-4xl'>
                        {isEmpty ? stockOnHand : values['adjustments'][id]}
                      </span>
                      <span className='mt-2 block'>On Hand</span>
                      {color !== 'smoke' ? (
                        <span className='mt-2 block'>
                          {confirmed ? (
                            <>
                              Confirmed
                              <CheckCircleIcon
                                className='inline-block'
                                style={{
                                  width: '1.5em',
                                  height: '1.5em',
                                  marginLeft: 4,
                                  fill: 'var(--color-green)',
                                  verticalAlign: 'text-bottom',
                                }}
                              />
                            </>
                          ) : (
                            <>
                              Adjusted
                              <WarningIcon
                                className='inline-block'
                                style={{
                                  width: '1.5em',
                                  height: '1.5em',
                                  marginLeft: 4,
                                  fill: 'var(--color-warning)',
                                  verticalAlign: 'text-bottom',
                                }}
                              />
                            </>
                          )}
                        </span>
                      ) : null}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </ToggleCard>
    </>
  );
};
