import { useCallback, useEffect, useState } from 'react'
import { CancelConfirmation } from './CancelConfirmation'
import { useNavigate } from 'react-router-dom'

/**
 * A form that will prompt with a cancel confirmation when it contains dirty values
 */
export const CancelPrompt = ({ when, children, ...props }) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const navigate = useNavigate()

  const handleBeforeUnload = useCallback(
    (event) => {
      if (when) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    [when]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [handleBeforeUnload])

  if (showConfirmation) {
    return (
      <CancelConfirmation
        onConfirm={(confirmed) =>
          confirmed ? navigate('./') : setShowConfirmation(false)
        }
      />
    )
  }

  return children
}
