import { QuickAddButton } from '~/components/QuickAdd';
import { SideLayout } from '~/layouts/side/SideLayout';

type Props = {
  children?: React.ReactNode;
  step?: number;
  onClose?: () => void;
  openQuickAdd?: (values: any) => void;
};

export const SMCHead = ({ children, step, onClose, openQuickAdd }: Props) => {
  return (
    <SideLayout.Head
      iconClass={step && step > 1 ? 'chevron-left' : 'x'}
      // rightSlot={step && `Step ${step} of 2`}
      onClose={onClose}
    >
      {children}
      {openQuickAdd && <QuickAddButton onClick={openQuickAdd} />}
    </SideLayout.Head>
  );
};
