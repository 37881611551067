import { Popover } from '@headlessui/react';
import { SelectorIcon as ChevronUpDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useState } from 'react';

import { useScrollToFocus } from '~/hooks/useScrollToFocus';
import { Option } from '~/types';
import { Combolist } from '../Combolist';
import { PopoverStateChangedEffect } from '../PopoverStateChangedEffect';
import { Label } from '../ui/Label';
import { MenuTransition } from '../ui/Transition/MenuTransition';

type Props = {
  label?: string;
  options: Option[];
  value: string;
  placeholder?: string;
  error?: string | false;
  className?: string;
  required?: boolean;
  searchable?: boolean;
  optionsBoxHeight?: string;
  onChange: (newValue: string) => void;
};

export function SelectCombolist({
  label = '',
  options,
  value,
  placeholder = '',
  error,
  className,
  required,
  searchable = true,
  optionsBoxHeight,
  onChange,
}: Props) {
  const ref = useScrollToFocus();

  const [selected, setSelected] = useState<string>(value);
  const [cancelling, setCancelling] = useState(false);

  const items = options.map((opt) => ({
    $group: opt.$group,
    id: opt.value,
    name: opt.label,
  }));

  function handleClosed() {
    if (cancelling) {
      setCancelling(false);
      setSelected(value);
      return;
    }
    onChange(selected);
  }

  return (
    <Popover ref={ref} className={classNames('relative', className)}>
      {({ open, close }) => (
        <>
          {label && (
            <Label className='mb-1.5 ml-1 text-sm font-medium text-grey-50'>
              {label}
              {required && <span className='pl-0.5 text-brand'>*</span>}
            </Label>
          )}

          <Popover.Button
            as='div'
            className={classNames(
              'input relative mt-0.5 w-full cursor-pointer overflow-hidden rounded border border-grey-20 py-3 pl-2 pr-3 leading-5 focus-visible:border-grey-40 focus-visible:outline-none',
              error && 'border-red-500',
              label && !error && 'mb-5'
            )}
          >
            <div className='flex items-center'>
              {items.length > 0 &&
                items.find(({ id }) => id === selected)?.name}

              <ChevronUpDownIcon
                className='ml-auto h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
          </Popover.Button>
          {error && <p className='ErrorMessage'>{error}</p>}
          <MenuTransition>
            <Popover.Panel
              className={classNames(
                'z-50 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
              )}
            >
              <PopoverStateChangedEffect open={open} onClose={handleClosed} />
              <Combolist
                items={items}
                searchKeys={['name']}
                placeholder={placeholder}
                multiple={false}
                value={items.filter(({ id }) => selected === id)}
                onChange={(selected) => {
                  setSelected(selected[0].id);
                  close();
                  onChange(selected[0].id);
                }}
                renderInner={(item) => item.name}
                searchable={searchable}
                height={optionsBoxHeight}
              />
            </Popover.Panel>
          </MenuTransition>
        </>
      )}
    </Popover>
  );
}
