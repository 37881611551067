import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from '~/components/form/TextField';
import { ComboboxField } from '../ui/Combobox';
import { Label } from '../ui/Label';
import { PopupDialogNew } from '../ui/PopupDialogNew';
import { ColorPicker } from './ColorPicker';
import { TagBlock } from './TagsBuilder';
import type { Tag } from './tags';

type Props = {
  isOpen: boolean;
  initialValues?: Tag;
  tags: Tag[];
  onClose: () => void;
  onSubmit: (values: Tag) => void;
};

export function TagForm({
  isOpen = false,
  tags,
  initialValues = {
    id: '',
    entityType: '',
    name: '',
    category: null,
    description: null,
    colour: '81848F',
  },
  onClose,
  onSubmit,
}: Props) {
  const isEdit = !initialValues.id;

  const tagCategories = () => {
    const categories = new Set<string>();

    tags.forEach((tag) => {
      if (tag.category) {
        categories.add(tag.category);
      }
    });

    return Array.from(categories);
  };

  return (
    <PopupDialogNew isOpen={isOpen} onClose={onClose}>
      <div className='absolute h-14 w-12'>
        <FontAwesomeIcon
          className='ml-5 mt-5 cursor-pointer text-lg text-white'
          icon={faXmark}
          onClick={onClose}
        />
      </div>
      <div className='flex justify-center bg-brand p-4 text-white'>
        {isEdit ? 'Create New Tag' : 'Edit Tag'}
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
          name: yup.string().required('Required'),
        })}
      >
        {(props) => {
          const { values, errors } = props;

          return (
            <div className='overflow-y-auto'>
              <div className='mx-4 mb-1'>
                {values.name && (
                  <div className='my-3 flex flex-col gap-1 py-3'>
                    <Label>Tag Preview</Label>
                    <TagBlock hexColor={values.colour} tagName={values.name} />
                  </div>
                )}
                <TextField
                  className='my-5'
                  name='name'
                  label='Tag Name'
                  autoFocus
                  required
                />
                <ComboboxField
                  name='category'
                  label='Tag Category'
                  items={tagCategories()}
                  required
                />
                <TextField
                  className='my-5'
                  name='description'
                  label='Description'
                  autoFocus
                />
                <Field name='colour'>
                  {({ field }: { field: any }) => {
                    const { value, name } = field;

                    return (
                      <ColorPicker
                        label={'Color'}
                        hexValue={value}
                        onChange={(hexValue) =>
                          props.setFieldValue(name, hexValue.substring(1))
                        }
                        randomizeColor={isEdit}
                      />
                    );
                  }}
                </Field>
              </div>

              <div className='flex justify-end px-4 py-6'>
                <button
                  className='w-fit px-7 py-1.5 text-sm font-thin uppercase text-grey-40 hover:text-brand'
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='w-fit rounded-lg bg-brand px-3 py-2 text-sm font-thin uppercase text-white hover:bg-brand-dark'
                  onClick={() => props.handleSubmit()}
                >
                  {isEdit ? 'Create Tag' : 'Edit Tag'}
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </PopupDialogNew>
  );
}
