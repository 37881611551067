import { useEffect, useState } from 'react';
// @ts-ignore
import ReactSelect from 'react-select';
import { TSort, SortByFunc } from '~/hooks/useSort';
import styles from '~/components/form/select/styles';
import { useTranslation } from 'react-i18next';

type SortByProps = {
  sortBy: SortByFunc;
  /** Include additional options for sorting by destination space */
  includeDest?: boolean;
};

function valueToSortObject(value?: string): TSort | undefined {
  if (!value) return;

  const i = value.lastIndexOf('(desc)');

  return i === value.length - 6
    ? { path: value.substr(0, value.length - 6), desc: true }
    : { path: value };
}

export const SortBy = ({ sortBy, includeDest = false }: SortByProps) => {
  const { t } = useTranslation();

  const options = [
    {
      value: 'space.name',
      label: 'Space Name - Ascending',
    },
    {
      value: 'space.name(desc)',
      label: 'Space Name - Descending',
    },
    {
      value: 'sku.code',
      label: t('skuCode'),
    },
    {
      // TODO replace with joined item + variant (sku) name when available
      value: 'sku.item.name',
      label: t('skuName'),
    },
    ...(includeDest
      ? [
          {
            value: 'destinationSpace.name',
            label: 'Destination Space - Ascending',
          },
          {
            value: 'destinationSpace.name(desc)',
            label: 'Destination Space - Descending',
          },
        ]
      : []),
  ];

  const [value, setValue] = useState<any>(options[0]);

  useEffect(() => {
    sortBy({ path: 'space.name' });
  }, [sortBy]);

  const handleChange = (value: any) => {
    setValue(value);
    if (value) {
      sortBy(valueToSortObject(value.value));
    }
  };

  return (
    <div className='relative'>
      <label className='label' style={{ top: '0.5rem' }}>
        {t('sortBy')}
      </label>
      <ReactSelect
        styles={styles}
        // isClearable={true}
        isSearchable={false}
        options={options}
        placeholder=''
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};
