import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { Custom } from '~/components/job/tasks/Custom';
import { ErrorMessage } from '~/components/ui/Error';
import { JobStatus } from '~/generated/graphql';
import { getFragmentData } from '~/gql';
import { JobTemplateFields } from '~/graphql/fragment/JobTemplateFields';
import { StocktakeTaskFields } from '~/graphql/fragment/StocktakeTaskFields';
import { useJobTemplateListContext } from '~/routes/job-templates._index';

export const TemplateTaskView = () => {
  const { templates } = useJobTemplateListContext();
  const navigate = useNavigate();
  const { templateId, taskId } = useParams();

  const handleClose = () => navigate('..');

  invariant(templateId, 'templateId missing');
  invariant(taskId, 'taskId missing');

  const template = getFragmentData(
    JobTemplateFields,
    templates?.find(({ id }) => id === templateId)
  );

  if (!template) {
    return <ErrorMessage message='Template not found' />;
  }

  const task = template.tasks.find(({ id }: { id: string }) => id === taskId);

  if (!task) {
    return <ErrorMessage message='Task not found' />;
  }

  const unmaskedStocktake = getFragmentData(
    StocktakeTaskFields,
    task.stocktake
  );

  const unmaskedTask = {
    ...task,
    stocktake: unmaskedStocktake,
  };

  return (
    <Custom
      job={{
        id: '0',
        status: JobStatus.Created,
        jobNumber: 'J0000',
        name: 'Template',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        scheduledStartStrict: true,
        scheduledEndStrict: false,
        isSource: false,
        owner: { id: '', name: '' },
        permissions: [],
        canAcceptOffer: false,
        tasks: [],
        enforceOrder: false,
      }}
      task={{ ...unmaskedTask, status: JobStatus.Created, tags: { nodes: [] } }}
      onClose={handleClose}
      onSuccess={handleClose}
      enabled={false}
    />
  );
};
