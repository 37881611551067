import { useNavigate } from 'react-router-dom';
import { SideLayout } from '~/layouts/side/SideLayout';
import { EditLink } from '~/components/nav/EditLink';

type Props = {
  children: React.ReactNode;
  title: React.ReactNode;
  foot?: React.ReactNode;
  returnTo: string;
  rightSlot?: React.ReactNode;
  className?: string;
};

export const DetailView = ({
  children,
  title,
  foot,
  className,
  returnTo,
  rightSlot,
}: Props) => {
  const navigate = useNavigate();

  return (
    <SideLayout className={className}>
      <SideLayout.Head
        rightSlot={rightSlot ?? <EditLink />}
        onClose={() => navigate(returnTo)}
      >
        {title}
      </SideLayout.Head>
      <SideLayout.Body>{children}</SideLayout.Body>
      {foot && <SideLayout.Foot>{foot}</SideLayout.Foot>}
    </SideLayout>
  );
};
