import { useField } from 'formik';
import { Attachment } from '~/gql/graphql';
import { AttributeFormData } from './AttributeFormData';

type Props = {
  attachments?: Record<string, Attachment[]>;
  search?: string;
};

export function AttributeFormikField({ attachments, search }: Props) {
  const [field, , helpers] = useField('attributes');

  const value = field.value.reduce(
    (prev: Record<string, string>, curr: any) => {
      return { ...prev, [curr.id]: curr.value.join(',') };
    },
    {}
  );

  const handleChange = (newValue: Record<string, string>) => {
    helpers.setValue(
      Object.keys(newValue).map((id) => {
        return { id, name: id, value: [newValue[id]] };
      })
    );
  };

  return (
    <div className='mx-4'>
      <AttributeFormData
        value={value}
        onChange={handleChange}
        attachments={attachments}
        searchValue={search}
      />
    </div>
  );
}
