import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import { FindAddContact } from '~/components/FindAddContact';
import { MultiActionButton } from '~/components/form/MultiActionButton';
import { Button } from '~/components/form/SubmitButton';
import { Switch } from '~/components/ui/nucleus/Switch';
import { graphql } from '~/gql';
import { JobStatus } from '~/gql/graphql';
import { splice } from '~/helpers/array';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { SideLayout } from '~/layouts/side/SideLayout';
import type { Contact } from '~/routes/resources/contacts';
import { ConfirmFn, Confirmation } from '../SaveJobActions';

type Props = {
  selected: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

const OPTIONS = [
  'Change Job Owner',
  'Change Assignee',
  'Change Include',
] as const;

const AssignJobs_Mutation = graphql(`
  mutation AssignJobs($input: AssignJobsInput!) {
    assignJobs(input: $input) {
      id
      status
      permissions
      owners {
        id
        name
        image
      }
      assignee {
        id
        name
        image
      }
      include {
        id
        name
        image
      }
    }
  }
`);

export function BulkAllocate({ selected, onCancel, onSuccess }: Props) {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const [result, assignJobs] = useMutation(AssignJobs_Mutation);

  const [change, setChange] = useState(0);
  const [selections, setSelections] = useState<Contact[][]>(
    [...Array(OPTIONS.length)].map(() => [])
  );

  // Confirmation dialog state
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [action, setAction] = useState<'offer' | 'confirm' | null>(null);

  const confirm = (s: typeof action) => {
    setAction(s);
    setIsOpen(true);
  };

  useEffect(() => {
    if (result.error) {
      toast.error(result.error.message.replace('[GraphQL] ', ''));
      setIsOpen(false);
      return;
    }
    if (result.data) onSuccess();
  }, [result, onSuccess]);

  const loading = result.fetching;

  const submit: ConfirmFn = ({ notify, comment }) => {
    function getSelection(i: number) {
      const active = Boolean(change & (1 << i));
      if (!active) return null;
      return active ? selections[i].map((c) => c.id) : null;
    }

    assignJobs({
      input: {
        jobIds: selected,
        ownerIds: getSelection(0),
        assigneeId: getSelection(1),
        includeIds: getSelection(2),
        status:
          action === 'confirm'
            ? JobStatus.Accepted
            : action === 'offer'
            ? JobStatus.Offered
            : null,
        notify: notify,
        comment: comment,
      },
    });
  };

  return (
    <>
      <Confirmation
        open={isOpen}
        loading={loading}
        showNotifyOptions={action !== null}
        onBack={() => setIsOpen(false)}
        onConfirm={submit}
      >
        You are about to {action ?? 'change'}{' '}
        <span className='font-semibold'>{selected.length}</span> jobs.{' '}
      </Confirmation>
      <SideLayout>
        <SideLayout.Head iconClass='chevron-left' onClose={onCancel}>
          Bulk Change
        </SideLayout.Head>
        <SideLayout.Body>
          {OPTIONS.map((label, i) => {
            const active = Boolean(change & (1 << i));
            return (
              <div key={i} className='mb-3 whitespace-nowrap'>
                <Switch
                  label={label}
                  active={active}
                  onChange={(checked) =>
                    setChange((state) =>
                      checked ? state | (1 << i) : state & ~(1 << i)
                    )
                  }
                />
                <div className=''>
                  <FindAddContact
                    optionsBoxHeight={
                      isMobile
                        ? 'max-h-[calc(100vh-240px)]'
                        : 'max-h-[calc(100vh-600px)]'
                    }
                    value={active ? selections[i] : []}
                    onChange={(selected) =>
                      setSelections(splice(selections, i, 1, selected))
                    }
                    disabled={!active}
                    isTeamMember={i === 0}
                    multiple
                  />
                </div>
              </div>
            );
          })}
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <MultiActionButton
            options={[
              {
                label: 'Offer Jobs',
                onClick: () => confirm('offer'),
              },
              {
                label: 'Confirm Jobs',
                onClick: () => confirm('confirm'),
              },
            ]}
          />
          <Button
            type='button'
            intent='secondary'
            onClick={() => confirm(null)}
          >
            Save
          </Button>
        </SideLayout.Foot>
      </SideLayout>
    </>
  );
}
