import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import { AutomationForm } from '~/components/automation/AutomationForm/AutomationForm';
import { graphql } from '~/gql';
import { AutomationFormDocument, formToInput } from './automations.new';

const UpdateAutomationDocument = graphql(`
  mutation UpdateAutomation($id: ID!, $input: AutomationInput!) {
    updateAutomation(id: $id, input: $input)
  }
`);

export function Component() {
  const navigate = useNavigate();
  const params = useParams();

  const [, updateAutomation] = useMutation(UpdateAutomationDocument);
  const [result] = useQuery({
    query: AutomationFormDocument,
    variables: { id: params.automationId },
    requestPolicy: 'network-only',
  });

  if (!result.data) {
    return null;
  }

  return (
    <AutomationForm
      data={result.data}
      onSubmit={async (data) => {
        if (!params.automationId) return;

        const res = await updateAutomation({
          id: params.automationId,
          input: formToInput(data),
        });

        const id = res.data?.updateAutomation;
        if (id) {
          return navigate(`/automations/${res.data?.updateAutomation}`);
        }
      }}
    />
  );
}
