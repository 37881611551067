import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { useField } from 'formik';
import { ContactType } from '~/generated/graphql';
import { useTranslation } from 'react-i18next';
import { FormData } from '.';

const options = [ContactType.Person, ContactType.Organisation];

export function ContactTypeField() {
  const { t } = useTranslation();
  const [field, , helpers] = useField<FormData['type']>('type');
  const { setValue } = helpers;
  const { value } = field;

  const handleChange = (newValue: ContactType) => {
    setValue(newValue);
  };

  return (
    <RadioGroup onChange={handleChange} value={value}>
      {/* <RadioGroup.Label>Contact Type</RadioGroup.Label> */}
      <div className='flex justify-center gap-3 px-4 pt-8 pb-4 text-base'>
        {options.map((option: string) => (
          <RadioGroup.Option
            value={option}
            key={option}
            className={({ active, checked }) =>
              classNames([
                `${
                  checked
                    ? 'outline-brand bg-white bg-opacity-75 outline-2'
                    : 'bg-white shadow-sm outline-2 outline-gray-200'
                }`,
                'relative flex w-1/2 cursor-pointer rounded-lg px-3 py-3 outline outline-1 ',
              ])
            }
          >
            {({ active, checked }) => (
              <>
                <div className='flex w-full items-center justify-between'>
                  <div className='flex items-center'>
                    <RadioGroup.Label as='p' className='text-copy-body '>
                      {t(option)}
                    </RadioGroup.Label>
                  </div>
                  {checked ? (
                    <div className='text-brand shrink-0 fill-current stroke-white'>
                      <CheckIcon className='h-4 w-4' />
                    </div>
                  ) : (
                    <div className='shrink-0 fill-current stroke-current text-gray-200'>
                      <CheckIcon className='h-4 w-4' />
                    </div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

function CheckIcon(props: any) {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <circle cx={12} cy={12} r={12} fill='currentColor' opacity='1' />
      <path
        d='M7 13l3 3 7-7'
        stroke='#fff'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
