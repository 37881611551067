import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import { SelectorIcon as ChevronUpDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Contact } from '~/routes/resources/contacts';
import { Combolist } from '../Combolist';
import { PopoverStateChangedEffect } from '../PopoverStateChangedEffect';
import { Avatar } from '../ui/Avatar';
import { Label } from '../ui/Label';
import { MenuTransition } from '../ui/Transition/MenuTransition';

export type ContactSelectProps = {
  label?: string;
  error?: string;
  contacts?: Contact[];
  value: Contact[];
  onChange: (newValue: Contact[]) => void;
  onCreate: (query: string) => void;
  isTeamMember?: boolean;
  isAbsolute?: boolean;
  multiple?: boolean;
  hasSelectAll?: boolean;
  disabled?: boolean;
  optionsBoxHeight?: string;
};

export const styles = {
  label: 'text-grey-50 ml-1 mb-1.5 text-sm font-medium',
  control:
    'overflow-hidden pl-2 pr-3 border-grey-20 focus-visible:border-grey-40 input relative mt-0.5 w-full rounded border focus-visible:outline-none',
  options:
    'flex flex-col top-0 w-full z-50 max-h-96 overflow-visible rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 pt-2 px-1 focus:outline-none sm:text-sm',
  option: ({
    active,
    selected,
    disabled,
  }: {
    active: boolean;
    selected: boolean;
    disabled: boolean;
  }) =>
    `relative cursor-default select-none ${
      selected && 'bg-brand-ghost text-grey-90'
    } ${disabled && 'opacity-75'} ${
      active ? 'bg-brand hover:text-white' : 'text-grey-90'
    } hover:bg-brand`,
  selectedOption: 'flex max-w-full items-center gap-2 bg-hoverBg px-2.5 py-1.5',
};

export function ContactSelect({
  label,
  error,
  value,
  onChange,
  isTeamMember,
  multiple = false,
  hasSelectAll = false,
  isAbsolute = true,
  ...props
}: ContactSelectProps) {
  const { t } = useTranslation();
  const [cancelling, setCancelling] = useState(false);
  const [selected, setSelected] = useState<Contact[]>(value);

  // Filter out non-team members if isTeamMember is true
  const contacts = isTeamMember
    ? props.contacts?.filter((contact) => contact.isTeamMember)
    : props.contacts;

  // If isTeamMember, don't allow creating new contacts
  const onCreate = isTeamMember ? undefined : props.onCreate;

  useEffect(() => {
    // When value changes from outside, update selected so combolist selections stay in sync
    setSelected(value);
  }, [value]);

  function handleClosed() {
    if (cancelling) {
      setCancelling(false);
      setSelected(value);
      return;
    }
    onChange(selected);
  }

  return (
    <Popover className='relative'>
      {({ open, close }) => (
        <>
          {label && <Label className={styles.label}>{label}</Label>}
          <Popover.Button
            as='div'
            className={classNames(
              styles.control,
              error && 'border-red-500',
              label && !error && 'mb-5',
              'cursor-pointer',
              selected.length ? 'py-1' : 'py-3',
              props.disabled && 'bg-grey-10'
            )}
            disabled={props.disabled}
          >
            <div className='flex items-center'>
              <div className='flex flex-1 flex-wrap gap-2 overflow-x-clip text-left'>
                {value.map((contact) => (
                  <div
                    key={contact.id}
                    className={classNames(
                      styles.selectedOption,
                      'rounded-full'
                    )}
                  >
                    <Inner contact={contact} />
                    {!props.disabled && (
                      <button
                        className='relative h-3.5 w-3.5 flex-shrink-0 rounded-full bg-[#808490] transition-colors hover:bg-[#6D6E7A]'
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange(
                            selected.filter(({ id }) => id !== contact.id)
                          );
                        }}
                        type='button'
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          className='absolute left-0 top-0 h-2.5 w-2.5 p-0.5 text-white'
                        />
                      </button>
                    )}
                  </div>
                ))}
              </div>
              <ChevronUpDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
          </Popover.Button>
          {error && <p className='ErrorMessage'>{error}</p>}
          <MenuTransition>
            <Popover.Panel
              className={classNames(
                'z-50 -mt-4 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                multiple && 'pb-0',
                isAbsolute && 'absolute'
              )}
            >
              <PopoverStateChangedEffect open={open} onClose={handleClosed} />
              <Combolist
                selectAll={hasSelectAll}
                items={contacts ?? []}
                searchKeys={['name', 'email']}
                placeholder={t('contact_plural')}
                value={selected}
                onChange={(newValue) => {
                  multiple ? onChange(newValue) : setSelected(newValue);
                  // If single select close the popover after selection
                  !multiple && close();
                }}
                closePopover={() => close()}
                onCreate={onCreate}
                createText='New Contact'
                multiple={multiple}
                renderInner={(contact) => <Inner showEmail contact={contact} />}
                height={props.optionsBoxHeight}
              />
              {multiple && (
                <div className='sticky bottom-0 z-10 flex items-center justify-end border-t p-2 text-xs'>
                  <Popover.Button
                    className='flex-1 p-2 text-left uppercase'
                    onClick={() => setCancelling(true)}
                  >
                    {t('cancel')}
                  </Popover.Button>
                  <Popover.Button className='flex-1 p-2 text-right uppercase'>
                    {t('ok')}
                  </Popover.Button>
                </div>
              )}
            </Popover.Panel>
          </MenuTransition>
        </>
      )}
    </Popover>
  );
}

function Inner({
  contact,
  showEmail = false,
}: {
  contact: Contact;
  showEmail?: boolean;
}) {
  return (
    <>
      <Avatar image={contact.image ?? ''} name={contact.name} size='small' />
      <span className='truncate'>{contact.name}</span>
      {showEmail && (
        <span className='truncate text-grey-40'>{contact.email}</span>
      )}
    </>
  );
}
