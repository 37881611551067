import classNames from 'classnames';
import { HelperPopup } from '~/components/ui/HelperPopup';
import styles from './Switch.module.css';

export type SwitchProps = {
  name: string;
  label: React.ReactNode;
  help?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
} & Omit<React.HTMLProps<HTMLInputElement>, 'onChange'>;

export const Switch = ({
  name,
  label,
  help,
  checked,
  onChange,
  ...props
}: SwitchProps) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange && onChange(event.target.checked);
  };
  return (
    <div className={styles.switch}>
      <input
        {...props}
        className={classNames(
          styles.input,
          props.disabled ? 'opacity-80' : 'cursor-pointer'
        )}
        type='checkbox'
        name={name}
        id={name}
        checked={checked}
        onChange={handleChange}
      />
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      {help && <HelperPopup>{help}</HelperPopup>}
    </div>
  );
};
