import { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import Info from '../../icons/Info';

type Props = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
};

export const HelperPopup = ({ children, icon }: Props) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [show, setShow] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'flip',
        enabled: false,
      },
    ],
  });

  return (
    <>
      <span className='flex items-center' ref={setReferenceElement}>
        {icon ? (
          <div className='mr-2 inline-block'>
            <div
              onTouchEnd={() => setShow(true)}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              {icon}
            </div>
          </div>
        ) : (
          <Info
            width='44'
            height='44'
            className='inline-block cursor-pointer text-orange'
            onTouchEnd={() => setShow(true)}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          />
        )}
      </span>

      {show &&
        createPortal(
          <div
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 55 }}
            {...attributes.popper}
          >
            <div
              className='z-20 my-4 px-4 py-2 text-xs'
              style={{
                background: '#F8F0D7',
                boxShadow: '0px 4px 5px #00000029',
                color: '#414A4C',
                maxWidth: 300,
              }}
            >
              {children}
            </div>
          </div>,
          document.querySelector('#tooltip')!
        )}
    </>
  );
};
