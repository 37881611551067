import { toast } from 'react-toastify';

type FetchResult<T> = {
  response: Response;
  data: T;
};

async function wrappedFetch<T>(
  input: RequestInfo,
  init?: RequestInit
): Promise<FetchResult<T>> {
  const response = await fetch(input, init);

  if (!response.ok) {
    // eslint-disable-next-line no-console
    console.error(response);
    toast.error('Error: ' + response.statusText);
    throw new Error(response.statusText);
  }

  const data = await response.json();

  if (init && init.method !== 'GET') {
    toast.success('Success');
  }

  return { response, data };
}

type PostData = {
  [key: string]: boolean | number | string | null | PostData;
};

function cookie(name: string) {
  const match = document.cookie.match(
    new RegExp('(^|;\\s*)(' + name + ')=([^;]*)')
  );
  return match ? decodeURIComponent(match[3]) : '';
}

function withData(method: 'POST' | 'PUT') {
  return function json<T>(url: string, data: PostData) {
    return wrappedFetch<T>(url, {
      method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-XSRF-TOKEN': cookie('XSRF-TOKEN'),
      },
      body: JSON.stringify(data),
    });
  };
}

const post = withData('POST');
const put = withData('PUT');

async function fetchJson(url: string) {
  const { data } = await wrappedFetch(url);
  return data;
}

class FetchError extends Error {
  response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

/**
 * Minimal fetcher, favor using this everywhere if possible
 * @param url
 */
export async function fetcher<T>(url: string): Promise<T> {
  const res = await fetch(url);

  if (!res.ok) {
    throw new FetchError(res);
  }

  return res.json();
}

export { wrappedFetch as fetch, post, put, fetchJson };
