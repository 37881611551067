import { useQuery } from 'urql';
import { Contact } from '~/routes/resources/contacts';
import { getFragmentData } from '../../../../gql';
import {
  Attribute_AttributeFragmentFragmentDoc,
  SiteAttributesDocument,
} from '../../../../gql/graphql';
import { ErrorMessage } from '../../../ui/Error';
import { Loading } from '../../../ui/Loading';
import {
  AttributeAuditChangeFn,
  AttributeAuditForm,
} from './AttributeAuditForm';
import { AllAttributes, Attachments } from './types';

type Props = {
  locationId: string;

  /** The global list of attributes */
  allAttributes: AllAttributes;

  /** The global list of contacts */
  contacts: Contact[]; // TODO store and read from App Context instead of props

  /** These attribute values always take precedence over current values at the location */
  overrides?: Record<string, string>;

  /** Passed down to the underlying form, a key to store cached values */
  storageKey: string;

  /** Passed down to the underlying form, display input fields in read only? */
  readMode?: boolean;

  /** There are changes to the underlying form  */
  onChange: AttributeAuditChangeFn;
};

export function AttributeAuditLocation({
  locationId,
  allAttributes,
  contacts,
  overrides,
  storageKey,
  readMode = false,
  onChange,
}: Props) {
  const [result] = useQuery({
    query: SiteAttributesDocument,
    variables: { id: locationId },
    requestPolicy: 'cache-and-network',
  });

  const attributes = getFragmentData(
    Attribute_AttributeFragmentFragmentDoc,
    result.data?.site?.attributes
  );

  // type Attachments = NonNullable<typeof attributes>[number]['attachments'];

  /** Lookup attachments by attribute id */
  const attachments = attributes?.reduce<Attachments>(
    (prev, currentAttribute) => {
      return currentAttribute.attachments
        ? {
            ...prev,
            [currentAttribute.id]: currentAttribute.attachments,
          }
        : prev;
    },
    {}
  );

  /** The current value stored on the server for this location */
  const currentValues = attributes?.reduce<Record<string, string>>(
    (prev, currentAttribute) => {
      return {
        ...prev,
        [currentAttribute.id]: currentAttribute.value.join(','),
      };
    },
    {}
  );

  let defaultValues: Record<string, string> = {};

  // If overrides is unset then use all possible attributes.
  if (overrides === undefined) {
    defaultValues = allAttributes?.reduce<Record<string, string>>(
      (prev, attr) => {
        const { id, value } = getFragmentData(
          Attribute_AttributeFragmentFragmentDoc,
          attr
        );
        return {
          ...prev,
          [id]: value.join(','),
        };
      },
      {}
    );
  }
  // Otherwise mix in current values in to any overrides that are empty
  else {
    for (const key in overrides) {
      defaultValues[key] = overrides[key] || currentValues?.[key] || '';
    }
  }

  if (result.fetching) {
    return <Loading spinner />;
  }

  if (!(attributes && defaultValues && attachments)) {
    return <ErrorMessage message='Attributes not found' />;
  }

  return (
    <>
      <AttributeAuditForm
        allAttributes={allAttributes}
        contacts={contacts}
        attachments={attachments}
        defaultValues={defaultValues}
        storageKey={storageKey}
        readMode={readMode}
        onChange={onChange}
      />
    </>
  );
}
