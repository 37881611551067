import { TagData } from '@yaireo/tagify';
import Tags, { TagifyBaseReactProps } from '@yaireo/tagify/dist/react.tagify';
import { useField } from 'formik';
import { useCallback } from 'react';

type TagsFieldProps = {
  name: string;
  label: string;
};

export const TagsField = ({ name, label }: TagsFieldProps) => {
  const [field, meta, { setValue }] = useField<TagData[] | string[]>(name);

  const onChange = useCallback<NonNullable<TagifyBaseReactProps['onChange']>>(
    (event) => {
      setValue(event.detail.tagify.value.map(({ value }) => value));
    },
    [setValue]
  );

  return (
    <div>
      <div className={`label${field.value?.length ? '' : ' opacity-0'}`}>
        {label}
      </div>
      <Tags
        className='x-tagify'
        settings={{ placeholder: label }}
        defaultValue={field.value}
        onChange={onChange}
      />
      {meta.error && meta.touched && (
        <p className='ErrorMessage'>{meta.error}</p>
      )}
    </div>
  );
};
