import BaseAutocomplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../ui/Error';
import { Label } from '../ui/Label';

type Props = {
  onGeocoded: (result: any) => void;
  label?: string;
  error?: string;
};

function parseAddressComponents(
  addressComponents: google.maps.places.PlaceResult['address_components']
) {
  return addressComponents?.reduce((obj: { [k: string]: string }, part) => {
    const type = part.types[0];
    return {
      ...obj,
      [type]:
        part[
          type === 'administrative_area_level_1' ? 'short_name' : 'long_name'
        ],
    };
  }, {});
}

function parsePlaceResult(place: google.maps.places.PlaceResult) {
  return {
    ...parseAddressComponents(place.address_components),
    ...place.geometry?.location,
  };
}

export const Autocomplete = ({ onGeocoded, label, error }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Label>{label || t('address')}</Label>
      <BaseAutocomplete
        className='border-grey-20 focus-visible:border-grey-40 input mb-5 mt-0.5 w-full rounded border py-2.5 px-3 focus-visible:outline-none'
        apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
        options={{
          types: ['address'],
          fields: [
            'address_components',
            'geometry.location',
            'place_id',
            'formatted_address',
          ],
          componentRestrictions: { country: 'au' },
        }}
        onPlaceSelected={(place) =>
          place && onGeocoded(parsePlaceResult(place))
        }
      />
      {error && <ErrorMessage message={error} />}
    </>
  );
};
