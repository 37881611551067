import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { client } from '~/client';
import { graphql } from '~/gql';
import { JobStatus } from '~/gql/graphql';

const JobStatusDocument = graphql(`
  query JobStatus($id: ID!) {
    job(id: $id) {
      id
      status
      isScheduled
    }
  }
`);

export async function loader({ params }: LoaderFunctionArgs) {
  if (!params.view) return null;
  if (['scheduled', 'unscheduled', 'completed'].includes(params.view)) {
    return null;
  }

  // Assume the param is a job id, fetch the status and redirect to the correct view
  const jobId = params.view;
  const { data } = await client
    .query(JobStatusDocument, { id: jobId })
    .toPromise();

  if (!data?.job) {
    throw new Response('Not Found', { status: 404 });
  }

  const view =
    data.job.status === JobStatus.Complete
      ? 'completed'
      : data?.job?.isScheduled
      ? 'scheduled'
      : 'unscheduled';

  return redirect(`/jobs/${view}/${jobId}`);
}
