import { PhoneIcon } from '@heroicons/react/solid';
import { TFunction } from 'i18next';
import { RefObject, createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactsQuery } from '~/generated/graphql';
import { Avatar } from '../ui/Avatar';
import { CardNew } from '../ui/CardNew';

type Contact = ContactsQuery['contacts'][number];

type Props = {
  contacts: readonly Contact[];
  selectedId?: string;
};

export function ContactList({ contacts, selectedId }: Props) {
  const { t } = useTranslation();
  const refs: { [x: string]: RefObject<HTMLDivElement> } = contacts.reduce(
    (acc, contact) => ({ ...acc, [contact.id]: createRef() }),
    {}
  );

  useEffect(() => {
    if (!Object.keys(refs).length) return;

    if (selectedId && refs[selectedId] && refs[selectedId].current) {
      refs[selectedId].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [refs, selectedId]);

  return (
    <div className='max-w-full flex-1 overflow-x-hidden'>
      {contacts.map((item) => (
        <div key={item.id} ref={refs[item.id]}>
          <CardNew
            isSelected={selectedId === item.id.toString()}
            {...getListItemProps(t, item)}
          />
        </div>
      ))}
    </div>
  );
}

const getListItemProps = (t: TFunction, contact: Contact) => ({
  id: contact.id,
  linkTo: `/contacts/${contact.id}`,
  titleText: contact.name,
  secondaryText: contact.relationship
    ?.map((r) => t(`contactRelationships.${r}`))
    .join(', '),
  tertiaryText: contact.email,
  // status: contact.status,
  image: (
    <Avatar image={contact.image} name={contact.name} size='full' square />
  ),
  showImage: true,
  hero: {
    directive: <PhoneLink number={contact.phone} />,
  },
  status: contact.status,
});

type PhoneLinkProps = {
  number: string | null | undefined;
};

function PhoneLink({ number }: PhoneLinkProps) {
  if (!number) {
    return null;
  }

  return (
    <button
      className='underline'
      onClick={() => (window.location.href = `tel:${number}`)}
    >
      <div className='flex gap-2'>
        <PhoneIcon className='mt-0.5 h-4 w-4 text-grey-90' />
        {number}
      </div>
    </button>
  );
}
