import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/outline';
import { Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import * as Yup from 'yup';
import { FindAddContactField } from '~/components/FindAddContact';
import { AddressInputGroup } from '~/components/form/AddressInputGroup';
import { StatusToggle } from '~/components/form/StatusToggle';
import { SubmitButton } from '~/components/form/SubmitButton';
import { TextField } from '~/components/form/TextField';
import { AttributesField } from '~/components/form/attributes/AttributesField';
import { SelectField } from '~/components/form/downshift/SelectField';
import { Loading } from '~/components/ui/Loading';
import { UploadField } from '~/components/upload/Upload';
import { SiteInput, Status, useSitesQuery } from '~/generated/graphql';
import { stringEqual } from '~/helpers';
import { SideLayout } from '~/layouts/side/SideLayout';
import { Option } from '~/types';
import { ComboboxField } from '../../ui/Combobox';

type Props = {
  defaultName?: string;
  onSubmit: (values: SiteInput) => void;
};

export const ShortSiteForm = ({ defaultName, onSubmit }: Props) => {
  const { t } = useTranslation();
  const { siteId } = useParams();

  const [result] = useSitesQuery({
    requestPolicy: 'cache-and-network',
  });

  const sites = result.data?.sites;

  const checkNameIsUnique = useCallback(
    (value: string | undefined) =>
      !sites?.find(
        (s) => s.id.toString() !== siteId && stringEqual(s.name, value!)
      ),
    [sites, siteId]
  );

  const siteOptions: Option[] | undefined = useMemo(() => {
    return (
      sites &&
      sites
        .filter(({ status }) => status === Status.Active)
        .map(({ id, name }) => ({ value: id, label: name }))
    );
  }, [sites]);

  if (!siteOptions) {
    return <Loading />;
  }

  const initialValues: SiteInput = {
    status: Status.Active,
    name: defaultName ?? '',
    type: '',
    address: null,
    defaultSourceId: null,
    licensorId: null,
    notes: '',
    attributes: [],
    images: [],
  };

  const validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string()
        .required('Please complete this required field')
        .test('name-exists', 'Please enter a unique name', checkNameIsUnique),
      // address_data: Yup.object().shape({
      //   subpremise: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   street_number: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   route: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   locality: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   administrative_area_level_1: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   postal_code: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   country: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      // }),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <SideLayout isPopup>
        <SideLayout.Body isPopup>
          <StatusToggle />

          <TextField name='name' label={t('siteName')} autoFocus />

          <SiteTypeField />

          <AddressInputGroup name='address' label='Address' />

          <FindAddContactField
            label={t('licensor')}
            name='licensorId'
            type='Owner'
          />

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className='flex pb-4 pt-6 text-left text-xs text-brand underline'>
                  <ChevronUpIcon
                    className={`${
                      !open ? 'rotate-180 transform pt-0.5' : ''
                    } mr-1 h-5 w-5  `}
                  />
                  {open ? <p>Less Details</p> : <p>More Details</p>}
                </Disclosure.Button>
                <Disclosure.Panel className=' pb-2 text-sm text-copy-body'>
                  <SelectField
                    label={t('defaultSource')}
                    name='defaultSourceId'
                    options={siteOptions}
                  />

                  <AttributesField type='Site' />

                  <TextField name='notes' label='Notes' />

                  <UploadField name='images' />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
};

function SiteTypeField() {
  const { t } = useTranslation();
  const [result] = useQuery<{ siteTypes: string[] }>({
    query: '{ siteTypes }',
  });

  return (
    <ComboboxField
      name='type'
      label={t('siteType')}
      items={result.data?.siteTypes ?? []}
    />
  );
}
