import Compressor, { CompressorOptions } from '@uppy/compressor';
import Uppy from '@uppy/core';
// import GoldenRetriever from '@uppy/golden-retriever';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import XHR, { XHRUploadOptions } from '@uppy/xhr-upload';

const MiB = 0x10_00_00;
let NEXT_ID = 0;

// List of CORS AllowedHeaders for S3 compatible put object request
const ALLOWED_HEADERS = [
  'Content-Type',
  'x-amz-meta-environment',
  'x-amz-meta-tenantid',
  'x-amz-meta-originalfilename',
  'x-amz-meta-source',
  'x-amz-meta-tenantid',
];

const compressorOptions: CompressorOptions = {
  quality: 0.6,
  // @ts-expect-error valid compressor.js options
  maxWidth: 1366,
  maxHeight: 1366,
};

const xhrOptions: XHRUploadOptions = {
  endpoint: '',
  allowedMetaFields: [],
  headers(file: any) {
    if (file.meta.kind === 'file') {
      // extract headers from presigned url
      const headers = [
        ...new URL(file.xhrUpload.endpoint).searchParams.entries(),
      ]
        .filter(([key]) => ALLOWED_HEADERS.includes(key))
        .reduce((prev: Record<string, string>, [key, value]) => {
          return { ...prev, [key]: value };
        }, {});

      return {
        'Content-Type': file.meta.type ?? '',
        ...headers,
      };
    }
    return {};
  },
};

export function createUppy(imagesOnly = false, max?: number, id?: string) {
  const uppy = new Uppy({
    id: id ?? `uppy-${++NEXT_ID}`,
    allowMultipleUploadBatches: true,
    autoProceed: true,
    restrictions: {
      maxFileSize: 50 * MiB,
      allowedFileTypes: imagesOnly ? ['image/*'] : undefined,
      maxNumberOfFiles: max,
    },
    debug: true, //process.env.NODE_ENV === 'development',
    onBeforeFileAdded(file, files) {
      // Allow duplicate files
      return true;
    },
  })
    .use(Compressor, compressorOptions)
    .use(XHR, xhrOptions)
    // .use(GoldenRetriever)
    .use(ThumbnailGenerator);

  uppy.addPreProcessor(async (fileIDs) => {
    await Promise.all(
      fileIDs.map(async (fileID) => {
        const file = uppy.getFile(fileID);

        uppy.emit('preprocess-progress', file, {
          mode: 'indeterminate',
          // message: 'Preparing',
        });

        const response = await fetch('/api/upload', {
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          body: new URLSearchParams([
            ['filename', file.meta.name],
            ['contentType', file.type ?? ''],
          ]),
        });

        if (!response.ok) {
          uppy.log('Direct upload failed: ' + file.id, 'error');
          return;
        }

        const result = await response.json();

        if (!(result.method && result.url)) {
          throw new Error('Missing result');
        }

        uppy.setFileState(fileID, {
          xhrUpload: {
            method: result.method,
            endpoint: result.url,
          },
        });

        uppy.setFileMeta(fileID, { kind: result.kind, id: result.id });
      })
    );

    fileIDs.forEach((fileID) => {
      const file = uppy.getFile(fileID);
      uppy.emit('preprocess-complete', file);
    });
  });

  const files = uppy.getFiles();
  console.log('uppy ready %i files found', files.length);
  if (files.length) {
    files.forEach(async (restoredFile) => {
      if (!restoredFile.progress?.uploadComplete) {
        console.log('retrying', restoredFile);
        await uppy.retryUpload(restoredFile.id);
      }
      // clean up
      uppy.removeFile(restoredFile.id);
    });
  }

  return uppy;
}

type CloudflareResponse = {
  errors: unknown[];
  messages: unknown[];
  result: {
    filename: string;
    id: string;
    meta: Record<string, unknown>;
    requireSignedURLs: boolean;
    uploaded: string;
    variants: string[];
  };
  success: boolean;
};

function isCloudflareResponse(
  body: Record<string, unknown>
): body is CloudflareResponse {
  return (
    'result' in body &&
    typeof body.result === 'object' &&
    body.result !== null &&
    'id' in body.result &&
    typeof body.result.id === 'string'
  );
}
