import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Form, Formik, FormikValues, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '~/components/Modal';
import { FloatingTextField } from '~/components/form/TextFieldFloating';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useToggle } from '~/hooks/useToggle';
import { SideLayout } from '~/layouts/side/SideLayout';

import FilterIcon from './FunnelIcon';

export type FilterProps = {
  onFiltered: (filtered: { [path: string]: string[] }, query?: string) => void;
  onSearch?: (query: string, filters?: any) => void;
};

type FilterFormProps<Values extends FormikValues = FormikValues> = {
  children: React.ReactNode;
  initialValues: Values;
  filterOnLoad?: boolean;
  includeKeyword?: boolean;
  mobileScroll?: boolean;
  cols?: '4' | '5' | 'auto';
} & FilterProps;

export const FilterForm = ({
  children,
  initialValues,
  cols,
  mobileScroll,
  filterOnLoad,
  includeKeyword,
  onFiltered,
  onSearch,
}: FilterFormProps) => {
  const { t } = useTranslation();
  const [open, toggleOpen] = useToggle(true);

  useEffect(() => {
    filterOnLoad && onFiltered(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={{ _query: '', ...initialValues }}
      onSubmit={(values: typeof initialValues, { setSubmitting }) => {
        const { _query, ...filters } = values;
        onFiltered(filters, _query);
        if (onSearch && _query != null) {
          onSearch(_query);
        }
        setSubmitting(false);
      }}
    >
      <Form
        className={[
          mobileScroll
            ? 'w-full flex-nowrap justify-start gap-2 overflow-x-auto px-4 pb-2.5 lg:px-0 lg:pb-0'
            : 'justify-end lg:grid lg:gap-x-4 lg:bg-transparent',
          'flex items-center',
          cols === '5'
            ? 'lg:grid-cols-5'
            : cols === 'auto'
            ? 'lg:auto-cols-auto lg:grid-flow-col'
            : 'lg:grid-cols-4',
        ].join(' ')}
      >
        <SubmitOnChangeEffect />
        <div className='absolute -right-4 -top-4 z-10 hidden bg-white text-xs'>
          <button type='button' className='p-2' onClick={toggleOpen}>
            {t('Filter')}{' '}
            <FontAwesomeIcon
              className={classNames(
                { 'rotate-90': !open },
                'transition-transform'
              )}
              icon={faAngleDown}
            />
          </button>
        </div>
        {(onSearch || includeKeyword) && (
          <FloatingTextField
            className='flex-1'
            name='_query'
            label={t('keywordSearch')}
          />
        )}
        {open && (
          <InnerWrapper mobileScroll={mobileScroll}>{children}</InnerWrapper>
        )}
      </Form>
    </Formik>
  );
};

const SubmitOnChangeEffect = () => {
  const { values, submitForm } = useFormikContext();

  useEffect(() => {
    const tick = setTimeout(() => submitForm(), 0);
    return () => {
      clearTimeout(tick);
    };
  }, [values, submitForm]);

  return null;
};

const InnerWrapper = (props: any) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const [show, setShow] = useState(false);

  if (!isMobile || props.mobileScroll) {
    return <>{props.children}</>;
  }

  if (show) {
    return (
      <Modal
        component={ModalContent}
        {...props}
        onClose={() => setShow(false)}
        open
      />
    );
  }

  const style: React.CSSProperties = {
    marginLeft: '2rem',
    padding: '0.5rem 0',
    background: 'var(--bg)',
    borderRadius: 8,
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: '#00000096',
  };

  return (
    <button type='button' onClick={() => setShow(true)} style={style}>
      <span>
        <FilterIcon className='mr-2 inline-block h-4 w-4 fill-current' />
      </span>
      {t('filters')}
    </button>
  );
};

type ModalContentProps = {
  children: React.ReactNode;
  onClose: () => void;
};

const ModalContent = ({ children, onClose }: ModalContentProps) => {
  return (
    <SideLayout>
      <SideLayout.Head onClose={onClose}>Filters</SideLayout.Head>
      <SideLayout.Body className='p-4 pb-0'>{children}</SideLayout.Body>
      <SubmitButton onSuccess={onClose} />
    </SideLayout>
  );
};

type SubmitButtonProps = {
  onSuccess: () => void;
};

const SubmitButton = ({ onSuccess }: SubmitButtonProps) => {
  const { submitForm } = useFormikContext();

  const handleClick = async () => {
    await submitForm();
    onSuccess();
  };

  const { t } = useTranslation();

  return (
    <div className='p-4 pt-0'>
      <button
        onClick={handleClick}
        type='submit'
        className='rounded-lg border border-future-blue px-5 py-2 text-lg font-bold uppercase text-future-blue'
      >
        {t('apply')}
      </button>
    </div>
  );
};
