import { createContext, useContext, Dispatch, SetStateAction } from 'react';

type Context = {
  open: string;
  setOpen: Dispatch<SetStateAction<string>>;
};

const ToggleContext = createContext<Context | null>(null);

ToggleContext.displayName = 'ToggleContext';

export function useToggleContext() {
  const context = useContext(ToggleContext);

  if (!context) {
    throw new Error('Must be used in scope of a ToggleProvider');
  }

  return context;
}

export const ToggleProvider = ToggleContext.Provider;
