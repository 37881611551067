import {
  faExternalLink,
  faFile,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReadValue } from '~/components/ui/ReadValue';
import { useAsset } from './assets';

export default function AssetAttachments() {
  const asset = useAsset();

  return (
    <div className='p-3'>
      <ReadValue label='attachment_plural'>
        {asset?.attachments.map(({ id, ext, originalFilename, url }) => (
          <a
            key={id}
            className='group mb-2 flex items-center hover:underline'
            href={url}
            target='_blank'
            rel='noreferrer'
          >
            <span className='mr-2 text-xl'>
              <FontAwesomeIcon icon={ext === '.pdf' ? faFilePdf : faFile} />
            </span>
            <span className='group-hover:text-blue'>{originalFilename}</span>
            <span className='text-blue ml-1 hidden group-hover:block'>
              <FontAwesomeIcon icon={faExternalLink} />
            </span>
          </a>
        ))}
      </ReadValue>
    </div>
  );
}
