import {
  faFile,
  faFilePdf,
  faPaperclip,
  faPhotoFilm,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResultOf } from '@graphql-typed-document-node/core';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FragmentType, getFragmentData, graphql } from '~/gql';
import { Attachment } from '~/gql/graphql';
import { parseJson } from '~/helpers';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { Contact } from '~/routes/resources/contacts';
import { FindAddContact } from '../FindAddContact';
import { TextArea } from '../form/textarea/TextArea';
import { Input } from '../form/TextField';
import { Combobox } from '../ui/Combobox';
import { Label } from '../ui/Label';
import { Upload, UploadResult, toAttachmentInput } from '../upload2';
import { loadPreviewsFromStorage } from '../upload2/Upload';
import { AttributeReadValue } from './AttributeReadValue';

const Attribute_AttachmentFragment = graphql(`
  fragment Attribute_AttachmentFragment on Attachment {
    id
    kind
    ext
    thumb
    url
    originalFilename
  }
`);

const Attribute_AttributeFragment = graphql(`
  fragment Attribute_AttributeFragment on Attribute {
    id
    type
    category
    name
    value
    condition
    options
    attachments {
      ...Attribute_AttachmentFragment
    }
  }
`);

// TODO remove this export and instead unmask the fragment exactly where it is needed
export type Attribute = ResultOf<typeof Attribute_AttributeFragment>;

type Props = {
  attribute: FragmentType<typeof Attribute_AttributeFragment>;
  value: string;
  attachments?: Attachment[];
  onChange: (value: string) => void;
  readMode?: boolean;
  contacts: Contact[];
};

export const byName =
  (search: string) =>
  (attribute: FragmentType<typeof Attribute_AttributeFragment>) => {
    const { name } = getFragmentData(Attribute_AttributeFragment, attribute);

    return name === search;
  };

export function parseCondition(
  attribute: FragmentType<typeof Attribute_AttributeFragment>
) {
  const { id, condition } = getFragmentData(
    Attribute_AttributeFragment,
    attribute
  );

  if (!condition) {
    return null;
  }

  const cond = condition.split('===');

  if (cond.length !== 2) {
    console.warn('Invalid condition for attribute', id, condition);
    return null;
  }

  return cond as [id: string, eq: string];
}

export function AttributeInput(props: Props) {
  const attribute = getFragmentData(
    Attribute_AttributeFragment,
    props.attribute
  );
  const { value, onChange, attachments, contacts } = props;
  const { isMobile } = useBreakpoint();

  // used to hold upload previews
  const [previews, setPreviews] = useState<UploadResult[]>(() => {
    const old = parseJson(value);
    return old && Array.isArray(old)
      ? old
          // .filter(({ kind }: { kind: string }) => kind === 'image')
          .map((a: { id: string; kind: string; originalFilename: string }) => ({
            id: a.id,
            preview: attachments?.find(({ id }) => id === a.id)?.thumb ?? '',
            name: a.originalFilename,
            kind: a.kind as 'image' | 'file',
          }))
      : [];
  });

  // mix in any previews found in offline storage as required (when not in attachments)
  useEffect(() => {
    async function restorePreviews() {
      const offlineImages = await loadPreviewsFromStorage();
      const parsedValue = parseJson(value);
      if (parsedValue && Array.isArray(parsedValue)) {
        setPreviews((prev) => {
          const missing = previews.filter(({ preview }) => !preview);
          const newPreviews = [...prev];

          missing.forEach((m) => {
            const offline = offlineImages.find(({ id }) => id === m.id);

            if (offline) {
              console.log('found missing', m.id, 'in offline storage', offline);
              const idx = newPreviews.findIndex(({ id }) => id === offline.id);

              if (idx > -1) {
                newPreviews[idx].preview = URL.createObjectURL(offline.data);
              }
            }
          });

          return newPreviews;
        });
      }
    }
    restorePreviews();
  }, []);

  // When previews (field value) changes, trigger onChange
  // useEffect(() => {
  //   // ...
  // }, [previews]);

  const handleUploaded = (uploaded: UploadResult[]) => {
    setPreviews((state) => {
      const newPreviews = [...state, ...uploaded];

      // ! Seems bad to be firing onChange from within the state setter
      // consider using the useEffect commented out just above
      const value = JSON.stringify(newPreviews.map(toAttachmentInput));
      onChange(value);

      return newPreviews;
    });
  };

  const label = attribute.name;

  if (props.readMode) {
    return (
      <div className='relative mb-5 flex flex-col gap-1'>
        <Label>{label}</Label>
        <div className='px-1'>
          <AttributeReadValue
            value={value}
            attribute={attribute}
            attachments={attachments}
            contacts={contacts}
          />
        </div>
      </div>
    );
  }

  if (attribute.type === 'date' || attribute.type === 'number') {
    return (
      <Input
        type={attribute.type}
        label={label}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    );
  }

  if (attribute.type === 'contact') {
    const oldValue = parseJson(value);
    return (
      <FindAddContact
        optionsBoxHeight={
          isMobile ? 'max-h-[calc(100vh-285px)]' : 'max-h-[calc(100vh-450px)]'
        }
        label={label}
        value={
          Array.isArray(oldValue)
            ? contacts.filter(({ id }) => oldValue.includes(id))
            : []
        }
        onChange={(selected) =>
          onChange(
            JSON.stringify(selected.length ? selected.map(({ id }) => id) : '')
          )
        }
        multiple
        isAbsolute={false}
        contacts={contacts}
      />
    );
  }

  if (attribute.type === 'attachment') {
    return (
      <div className='mb-5 flex flex-col items-start gap-2'>
        <Label>{label}</Label>

        <div className='grid w-full grid-cols-2 gap-2 xl:grid-cols-4'>
          {previews.map(({ id, kind, preview, name }) => (
            <div
              key={id}
              className='relative flex flex-col items-center justify-center rounded-lg bg-gray-100'
            >
              <button
                type='button'
                className='absolute -left-1.5 -top-1.5 h-8 w-8 rounded-full bg-brand text-xl text-white shadow'
                onClick={() => {
                  const oldValue = parseJson(value);
                  const newValue = Array.isArray(oldValue)
                    ? JSON.stringify(oldValue.filter((v) => v.id !== id))
                    : undefined;
                  setPreviews((prev) => prev.filter((p) => p.id !== id));
                  onChange(newValue?.length ? newValue : '');
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              {kind === 'image' ? (
                <img key={id} className='w-full' alt={name} src={preview} />
              ) : (
                <>
                  {name.endsWith('.pdf') ? (
                    <FontAwesomeIcon icon={faFilePdf} className='h-10 w-10' />
                  ) : (
                    <FontAwesomeIcon icon={faFile} className='h-10 w-10' />
                  )}
                  <span
                    className={classNames(
                      'mt-2 text-center',
                      !name && 'italic'
                    )}
                  >
                    {name ?? 'Unknown'}
                  </span>
                </>
              )}
            </div>
          ))}
        </div>

        {attribute.id && (
          <Upload
            id={`AttributeInput:${attribute.id}`}
            imagesOnly={attribute.options?.kind !== 'file'}
            onUploaded={handleUploaded}
          >
            <div className='h-8 rounded hover:bg-grey-5'>
              <div className='flex items-start gap-2 p-1'>
                {attribute.options?.kind !== 'file' ? (
                  <>
                    <FontAwesomeIcon icon={faPhotoFilm} /> Add Photo/s
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faPaperclip} /> Add Files
                  </>
                )}
              </div>
            </div>
          </Upload>
        )}
      </div>
    );
  }

  if (attribute.type === 'select') {
    return (
      <Combobox
        label={label}
        value={value}
        items={attribute.value}
        onChange={(v) => onChange(v ?? '')}
      />
    );
  }

  return (
    <>
      <TextArea
        data-attribute={attribute.id}
        label={label}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        className='mb-5'
        rows={1}
      />
    </>
  );
}
