import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, RichUtils } from 'draft-js';
import { createContext, ReactNode, useContext } from 'react';
import {
  faListCheck,
  faListOl,
  faListUl,
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faImage,
  faCode,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

type Props = {
  children?: ReactNode;
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
};

const TOOLBAR_ITEM_MAP = {
  // Default styles provided by Editor:
  BOLD: {
    type: 'inline',
    icon: <FontAwesomeIcon icon={faBold} />,
  },
  ITALIC: {
    type: 'inline',
    icon: <FontAwesomeIcon icon={faItalic} />,
  },
  UNDERLINE: {
    type: 'inline',
    icon: <FontAwesomeIcon icon={faUnderline} />,
  },
  CODE: {
    type: 'inline',
    icon: <FontAwesomeIcon icon={faCode} />,
  },
  // Custom styles defined by customStyleMap:
  STRIKETHROUGH: {
    type: 'inline',
    icon: <FontAwesomeIcon icon={faStrikethrough} />,
  },

  // Block types:
  'checklist-item': {
    type: 'block',
    icon: <FontAwesomeIcon icon={faListCheck} />,
  },
  'ordered-list-item': {
    type: 'block',
    icon: <FontAwesomeIcon icon={faListOl} />,
  },
  'unordered-list-item': {
    type: 'block',
    icon: <FontAwesomeIcon icon={faListUl} />,
  },

  // Atomic block types:
  // image: {
  //   type: 'atomic',
  //   icon: <FontAwesomeIcon icon={faImage} />,
  // },
} as const;

type Command = keyof typeof TOOLBAR_ITEM_MAP;

// const colorStyleMap = {
//   red: {
//     color: 'rgba(255, 0, 0, 1.0)',
//   },
//   orange: {
//     color: 'rgba(255, 127, 0, 1.0)',
//   },
//   yellow: {
//     color: 'rgba(180, 180, 0, 1.0)',
//   },
//   green: {
//     color: 'rgba(0, 180, 0, 1.0)',
//   },
//   blue: {
//     color: 'rgba(0, 0, 255, 1.0)',
//   },
//   indigo: {
//     color: 'rgba(75, 0, 130, 1.0)',
//   },
//   violet: {
//     color: 'rgba(127, 0, 255, 1.0)',
//   },
// };

// const FONTS = {
//   label: 'Font Size',
//   options: [12, 14, 16, 18, 20, 24, 30],
//   text: 'Color',
//   style: [
//     'text-xs',
//     'text-sm',
//     'text-base',
//     'text-lg',
//     'text-xl',
//     'text-2xl',
//     'text-3xl',
//   ],
// };

type Toggled = Partial<Record<Command, boolean>>;

type TContext = {
  toggle: (item: Command) => void;
  toggled: Toggled;
};

const ToolbarContext = createContext<TContext>({ toggle() {}, toggled: {} });

export const Toolbar = ({ children, editorState, setEditorState }: Props) => {
  const currentSelection = editorState.getSelection();
  const currentKey = currentSelection.getStartKey();
  const currentBlock = editorState
    .getCurrentContent()
    .getBlockForKey(currentKey);
  const style = editorState.getCurrentInlineStyle().toJS();
  const toggled = (
    [...style, currentBlock.getType()] as Command[]
  ).reduce<Toggled>((prev, curr) => {
    return { ...prev, [curr]: true };
  }, {});

  function handleToggle(item: Command) {
    const { type } = TOOLBAR_ITEM_MAP[item];

    // TODO it might be better if Toolbar was more isolated from setEditorState and draft-js
    setEditorState(
      type === 'inline'
        ? RichUtils.toggleInlineStyle(editorState, item)
        : RichUtils.toggleBlockType(editorState, item)
    );
  }

  return (
    <ToolbarContext.Provider value={{ toggle: handleToggle, toggled }}>
      <div className='RichEditor-controls border-grey-20  text-greyhound relative flex h-11 cursor-pointer flex-row flex-wrap content-center border-b pl-2'>
        {children}
      </div>
    </ToolbarContext.Provider>
  );
};

function Image({ onAddImage }: { onAddImage: (files: File[]) => void }) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const target = event.currentTarget;
    if (target.files?.length) {
      const file = target.files[0];
      onAddImage && onAddImage([file]);
    }
  };

  return (
    <label className='image-input cursor-pointer object-contain py-2 px-3'>
      <FontAwesomeIcon icon={faImage} className='hover:text-brand mt-0.5 h-4' />
      <input
        type='file'
        className='hidden'
        onChange={handleChange}
        accept='image/*'
      ></input>
    </label>
  );
}

const createToolbarItem = (command: Command) => () => {
  const ctx = useContext(ToolbarContext);

  return (
    <button
      type='button'
      className={classNames(
        'RichEditor-styleButton',
        'py-2 px-3',
        'hover:text-brand',
        {
          'RichEditor-activeButton text-brand': ctx.toggled[command],
        }
      )}
      onClick={() => ctx.toggle(command)}
    >
      {TOOLBAR_ITEM_MAP[command].icon}
    </button>
  );
};

// Object.keys(TOOLBAR_ITEM_MAP).forEach((name) => {
//   Toolbar[name] = createToolbarItem(name as Command);
// });

Toolbar.Bold = createToolbarItem('BOLD');
Toolbar.Italic = createToolbarItem('ITALIC');
Toolbar.Underline = createToolbarItem('UNDERLINE');
Toolbar.Code = createToolbarItem('CODE');
Toolbar.Strikethrough = createToolbarItem('STRIKETHROUGH');

Toolbar.Checklist = createToolbarItem('checklist-item');
Toolbar.OrderedList = createToolbarItem('ordered-list-item');
Toolbar.UnorderedList = createToolbarItem('unordered-list-item');

Toolbar.Image = Image;
