import classNames from 'classnames';
import { useOutlet } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  rsbSmall?: boolean;
};

export function Layout({ children, rsbSmall }: Props) {
  const outlet = useOutlet();

  return (
    <div className='flex items-stretch lg:[height:calc(100vh-8.5rem)]'>
      <div className='overflow-y-auto overflow-x-hidden border-r lg:flex-1'>
        {children}
      </div>
      {outlet && (
        <div
          className={classNames(
            'min-w-rsb absolute inset-0 overflow-y-auto overflow-x-hidden lg:static',
            rsbSmall ? 'lg:w-rsb' : 'lg:w-rsb xl:w-2rsb'
          )}
        >
          {outlet}
        </div>
      )}
    </div>
  );
}
