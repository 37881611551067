import { ArrowLeftIcon } from '@heroicons/react/solid';
import {
  ActionFunctionArgs,
  Form,
  Link,
  isRouteErrorResponse,
  json,
  redirect,
  useNavigation,
  useRouteError,
} from 'react-router-dom';
import { client } from '~/client';
import { Input } from '~/components/form/Input';
import { Button } from '~/components/form/SubmitButton';
import { ErrorMessage } from '~/components/ui/Error';
import { graphql } from '~/gql';
import { useTitle } from '~/hooks';

const MARKETPLACE_URL = 'https://app.guesty.com/integrations/partners/propfit';

const GuestySetupDocument = graphql(`
  mutation GuestySetup($integrationToken: String!) {
    guestySetup(integrationToken: $integrationToken)
  }
`);

export async function action({ request }: ActionFunctionArgs) {
  const data = Object.fromEntries(await request.formData());

  if (typeof data.token === 'string') {
    const result = await client.mutation(GuestySetupDocument, {
      integrationToken: data.token,
    });

    if (result.error) {
      throw json(
        { message: result.error.message.replace('[GraphQL] ', '') },
        { status: 500 }
      );
    }

    if (result.data?.guestySetup) {
      console.log('wait for background job', result.data.guestySetup);

      // TODO actually check if the job is finished
      await new Promise((resolve) => setTimeout(resolve, 8000));
      return redirect('/integrations');

      // return result.data.guestySetup;
    }
  }

  throw new Response(null, { status: 500 });
}

export default function GuestyIntegrationRoute() {
  const { state } = useNavigation();
  useTitle('Guesty Integration');

  return (
    <div className='mx-auto mt-4 max-w-screen-md px-4 md:px-0'>
      <p>
        <Link to='/integrations' className='text-brand flex items-center gap-1'>
          <ArrowLeftIcon className='h-4' />
          Integrations
        </Link>
      </p>
      <h2 className='text-copy-head self-start pt-6 pb-3 font-bold'>
        Guesty Setup
      </h2>
      <p className='mb-4'>
        PropFit can sync your Guesty listings and reservations to run
        automations.
      </p>

      <h2 className='mb-4 pt-4 text-lg font-semibold'>
        How To Connect Your Guesty Account
      </h2>

      <ol className='mb-4 list-decimal'>
        <li className='mb-2'>
          Visit PropFit at the Guesty marketplace by following the link:{' '}
          <a href={MARKETPLACE_URL} target='_blank' rel='noopener noreferrer'>
            {MARKETPLACE_URL}
          </a>
        </li>
        <li className='mb-2'>
          Click on the <strong>Connect</strong> button and copy the integration
          as shown in the clip below:
        </li>
        <li className='mb-2'>
          Paste the token in the field below and click on the{' '}
          <strong>Submit</strong> button
        </li>
      </ol>

      <Form method='POST'>
        <Input name='token' label='Token' required />
        <Button loading={state === 'submitting'}>Submit</Button>
      </Form>
    </div>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  console.error(error);

  if (isRouteErrorResponse(error)) {
    return (
      <ErrorMessage message={error.data?.message ?? 'Something went wrong'} />
    );
  }

  throw error;
}
