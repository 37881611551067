import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '~/components/form/Input';
import { useChangePasswordMutation } from '~/generated/graphql';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useSettingsContext } from './SettingsSubpage';

type FormData = {
  oldPassword: string;
  newPassword: string;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Required'),
});

export const UpdatePasswordForm = () => {
  const { backTo } = useSettingsContext();
  const { t } = useTranslation();
  const [, changePassword] = useChangePasswordMutation();

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<FormData>({
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const submit = async (input: FormData) => {
    const { error } = await changePassword({ input });

    if (error) {
      toast.error(error.message.replace('[GraphQL] ', ''));
      return;
    }

    toast.success('Password successfully changed');
    backTo();
  };

  return (
    <div className='flex w-full grow flex-col'>
      <form onSubmit={handleSubmit(submit)} className='flex grow flex-col pt-8'>
        <div>
          <Input
            {...register('oldPassword')}
            label={t('oldPassword')}
            type='password'
            error={errors.oldPassword?.message}
          />
          <Input
            {...register('newPassword')}
            label={t('newPassword')}
            type='password'
            error={errors.newPassword?.message}
          />
        </div>
        <button
          className='bg-brand hover:bg-slate-dark mt-auto w-full rounded-lg p-3 font-semibold uppercase text-white transition-all duration-300  disabled:opacity-50'
          disabled={isSubmitting}
        >
          {t('submit')}
        </button>
      </form>
    </div>
  );
};
