import localforage from 'localforage';
import { useEffect, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';
import { Loading } from '~/components/ui/Loading';
import { Contact } from '~/routes/resources/contacts';
import {
  AttributeInput,
  parseCondition,
} from '../../../AttributeForm/AttributeInput';
import { AllAttributes, Attachments } from './types';

type Props = {
  allAttributes: AllAttributes;
  contacts: Contact[];
  attachments: Attachments;
  defaultValues: Record<string, string>;
  storageKey: string;
  readMode?: boolean;
  onChange: AttributeAuditChangeFn;
};

type Values = Record<string, string>;
type FormState = {
  dirty: boolean;
  values: Values;
};

export type AttributeAuditChangeFn = (state: FormState) => void;

export function AttributeAuditForm({
  allAttributes,
  contacts,
  attachments,
  defaultValues,
  storageKey,
  readMode = false,
  onChange,
}: Props) {
  const [values, setValues] = useState<Values>();

  useEffect(() => {
    async function initValues() {
      const offline = await localforage.getItem<Values>(storageKey);
      setValues({ ...defaultValues, ...offline });
    }
    initValues();
  }, [defaultValues, storageKey]);

  useEffect(() => {
    async function cacheValues() {
      if (!values) return;

      const dirty = JSON.stringify(defaultValues) !== JSON.stringify(values);

      dirty
        ? await localforage.setItem(storageKey, values)
        : await localforage.removeItem(storageKey);

      onChange({ dirty, values });
    }
    cacheValues();
  }, [values]);

  // The order to display the chosen fields is the same as the order in which they appear in the global attributes list
  const orderedKeys = useMemo(
    () =>
      allAttributes
        .map(({ id }) => id)
        .filter((id) => defaultValues[id] != null),
    [allAttributes, defaultValues]
  );

  invariant(allAttributes, 'Attributes is required');

  if (!values) {
    return <Loading spinner />;
  }

  return (
    <>
      {orderedKeys.map((key) => (
        <div key={key}>
          {isAvailable(key, allAttributes, values) && (
            <AttributeInput
              attribute={allAttributes.find(({ id }) => id === key)!}
              // @ts-expect-error FIXME upstream
              attachments={attachments[key]}
              value={values[key]}
              onChange={(value) =>
                setValues((prev) => ({ ...prev, [key]: value }))
              }
              readMode={readMode}
              contacts={contacts}
            />
          )}
        </div>
      ))}
    </>
  );
}

function isAvailable(id: string, allAttributes: AllAttributes, values: Values) {
  const attribute = allAttributes.find(({ id: i }) => i === id);
  const condition = attribute ? parseCondition(attribute) : null;
  const other =
    // @ts-expect-error FIXME needs unmasking or condition structure refactor
    condition && allAttributes.find(({ name }) => name === condition[0]);

  if (condition && other) {
    return values[other.id] === condition[1];
  }

  return true;
}
