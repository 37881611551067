import type { Kind } from '.';
import { createUppy } from './uppy';

type Instance = ReturnType<typeof createUppy>;

export class UppyInstanceManager {
  private instances: Partial<Record<Kind, Instance>> = {};

  /**
   * Returns the Uppy instance with the given ID if it exists. Will create a new instance if it doesn't.
   */
  get(id: Kind) {
    if (!this.instances[id]) {
      const imagesOnly = id === 'image';
      this.instances[id] = createUppy(imagesOnly, undefined, id);
    }

    return this.instances[id]!;
  }

  unmount(id: Kind) {
    this.instances[id]?.close({ reason: 'unmount' });
    delete this.instances[id];
  }
}
