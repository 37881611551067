import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '~/generated/graphql';
import { ListJobsQuery } from '~/gql/graphql';

type Option = { key: string; name: string };

export enum SortedBy {
  TIME = 'time',
  PROPERTY = 'property',
  ASSIGNEE = 'assignee',
}

export function SortBy({
  className,
  options,
  value,
  onChange,
}: {
  className: string;
  options: Option[];
  value: SortedBy;
  onChange: (value: SortedBy) => void;
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(() => value);

  const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const val = event.currentTarget.value;
    const sortBy = val as SortedBy;

    setSelected(sortBy);
    onChange(sortBy);
  };

  return (
    <div className={classNames('label w-fit text-base', className)}>
      <span className='mx-1 text-copy-body'>{t('sortBy')}</span>
      <select
        className='bg-white hover:cursor-pointer'
        value={selected}
        onChange={handleChange}
      >
        {options.map(({ key, name }) => (
          <option key={key} value={key}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
}

export function sortJobs(sortBy: SortedBy, jobs?: ListJobsQuery['jobBoard']) {
  const jobsList = jobs && (jobs as Job[]);

  if (!jobsList) return [];

  const jobsGroupedByDate = jobsList.reduce((groups, job) => {
    const date = job.scheduledStartDate;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(job);
    return groups;
  }, {} as Record<string, Job[]>);

  const sortedGroups = Object.entries(jobsGroupedByDate).map(
    ([date, jobsList]) => {
      let sortedJobs: Job[];

      switch (sortBy) {
        case SortedBy.TIME:
          sortedJobs = jobsList.sort((a, b) => {
            const timeA = a.scheduledStartTime ?? '';
            const timeB = b.scheduledStartTime ?? '';
            return timeA.localeCompare(timeB);
          });
          break;
        case SortedBy.PROPERTY:
          sortedJobs = jobsList.sort((a, b) => {
            const locationA = a.location?.name ?? '';
            const locationB = b.location?.name ?? '';
            return locationA.localeCompare(locationB);
          });
          break;
        case SortedBy.ASSIGNEE:
          sortedJobs = jobsList.sort((a, b) => {
            const assigneeA = a.assignees?.[0]?.name ?? '';
            const assigneeB = b.assignees?.[0]?.name ?? '';
            return assigneeA.localeCompare(assigneeB);
          });
          break;
        default:
          sortedJobs = jobsList;
      }

      return sortedJobs;
    }
  );

  return sortedGroups.flat() as ListJobsQuery['jobBoard'];
}
