import { graphql } from '~/gql';

export const StocktakeTaskFields = graphql(`
  fragment StocktakeTaskFields on Stocktake {
    id
    include
    itemStatus
    spaces
    items {
      id
      adjustment
      newOnHand
    }
  }
`);
