import { QuickAdd } from '~/components/QuickAdd';
import { AssetConfigData, Selected } from '.';

type AddItemsProps = {
  selected: Selected;
  data: AssetConfigData[];
  back: () => void;
  onSuccess: () => void;
};

export function AddItems({ selected, data, back, onSuccess }: AddItemsProps) {
  return <QuickAdd onCancel={back} onSuccess={onSuccess} />;
}
