import classNames from 'classnames/bind';
import { forwardRef, ReactNode } from 'react';
import styles from './Card.module.css';

const cx = classNames.bind(styles);

export type CardProps = {
  ref?: any;
  children: ReactNode;
  color?: 'blue' | 'green' | 'warning' | 'remove' | 'smoke';
  /**
   * @deprecated Use children
   */
  header?: string;
  shadow?: boolean;
  className?: string;
};

/**
 * Use `Card` to apply consistent styling to groupable information
 */
export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, color, header, shadow = false, className }, ref) => {
    return (
      <div ref={ref} className={cx('card', color, { shadow }, className)}>
        {header && <div className={styles.header}>{header}</div>}
        {children}
      </div>
    );
  }
);
