import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Button } from '~/components/ui/buttons/Button';
import { useTranslation } from 'react-i18next';

type CancelButtonProps = {
  onCancel?: Function;
};

type SubmitButtonProps = {
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
};

type SubmitButtonGroupProps = CancelButtonProps &
  SubmitButtonProps & {
    buttonText?: string;
  };

const CancelButton = ({ onCancel }: CancelButtonProps) => {
  const { t } = useTranslation();
  return onCancel ? (
    <Button type='button' onClick={onCancel}>
      {t('cancel')}
    </Button>
  ) : (
    <Button as={Link} to='../'>
      {t('cancel')}
    </Button>
  );
};

const SubmitButton = ({ children, disabled, loading }: SubmitButtonProps) => {
  return (
    <Button type='submit' color='primary' loading={loading} disabled={disabled}>
      {children}
    </Button>
  );
};

export const SubmitButtonGroup = ({
  buttonText,
  onCancel,
  disabled = false,
  loading = false,
}: SubmitButtonGroupProps) => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext();
  const props = {
    disabled: disabled || loading,
    loading: isSubmitting || loading,
  };

  return (
    <div className='flex p-4'>
      <div className='mr-auto'>
        <CancelButton onCancel={onCancel} />
      </div>
      <div>
        <SubmitButton {...props}>{buttonText || t('Submit')}</SubmitButton>
      </div>
    </div>
  );
};
