import { SVGProps } from 'react';
const SvgGuesty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 14.97542 14.975416'
    id='svg406'
    xmlns='http://www.w3.org/2000/svg'
    // xmlns:svg='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    {...props}
  >
    <defs id='defs403' />
    <g id='layer1' transform='translate(-19.388577,-16.666931)'>
      <path
        className='st0'
        d='m 34.363997,22.090889 c 0,-0.211666 -0.105833,-0.396875 -0.264583,-0.529166 L 27.32608,16.825681 c -0.105833,-0.105833 -0.264583,-0.15875 -0.396875,-0.15875 h -0.05292 c -0.15875,0.02646 -0.291042,0.07937 -0.396875,0.15875 l -6.82625,4.736042 c -0.15875,0.132291 -0.264583,0.3175 -0.264583,0.529166 v 8.863542 c 0,0.343958 0.291041,0.635 0.635,0.635 h 10.186458 c 0.343958,0 0.635,-0.291042 0.635,-0.635 v -7.090834 c 0.02646,-0.211666 -0.07937,-0.423333 -0.264584,-0.529166 l -3.254375,-2.301875 c -0.105833,-0.105833 -0.264583,-0.15875 -0.396875,-0.15875 h -0.05292 c -0.15875,0.02646 -0.291042,0.07938 -0.396875,0.15875 l -3.307292,2.301875 c -0.15875,0.132291 -0.264583,0.3175 -0.264583,0.529166 v 3.598334 c 0,0.343958 0.291042,0.635 0.635,0.635 0.343958,0 0.635,-0.291042 0.635,-0.635 0,0 0,0 0,0 v -3.280834 l 2.69875,-1.904999 2.69875,1.904999 v 6.164792 h -8.916458 v -7.9375 l 6.217708,-4.339166 6.217708,4.365625 v 8.572499 c 0,0.343959 0.291042,0.635 0.635,0.635 0.343958,0 0.635,-0.291041 0.635,-0.635 v -8.916458'
        id='path11'
        style={{
          fill: '#3b9dff',
          strokeWidth: 0.264583,
        }}
      />
    </g>
  </svg>
);
export default SvgGuesty;
