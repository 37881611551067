import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FragmentType, getFragmentData, graphql } from '~/gql';
import { JobStatus } from '~/gql/graphql';
import { formatDate } from '~/helpers/formatDate';

const Feed_JobHistoryFragment = graphql(/* GraphQL */ `
  fragment Feed_JobHistory on JobHistory {
    id
    task {
      id
      name
    }
    status
    reverted
    inputDate
    createdAt
    createdBy {
      id
      image
      name
    }
  }
`);

type Props = {
  item: FragmentType<typeof Feed_JobHistoryFragment>;
};

export function JobHistory(props: Props) {
  const { t } = useTranslation('job');
  const item = getFragmentData(Feed_JobHistoryFragment, props.item);

  const containerStyle =
    'mx-auto my-3 flex items-center justify-center overflow-hidden whitespace-nowrap rounded-full px-5 py-1.5 first-of-type:mt-1';

  const author = item.createdBy?.name ?? t('translation:automation');
  const status =
    item.status === JobStatus.Created
      ? t('feed.status.Reset')
      : t('feed.status.' + item.status);

  const taskStatus = (
    <p className='flex'>
      {author} {status} the
      <b className='ml-1 truncate'>{item.task?.name ?? ''}</b>{' '}
      <span className='ml-1'>{capitalize(t('translation:task'))}</span>
    </p>
  );

  const jobStatus = (
    <p>
      {t('feed.statusChange', {
        name: author,
        status: `$t(feed.status.${item.reverted ? 'Reverted' : item.status})`,
      })}
    </p>
  );

  return (
    <div
      className={classNames(
        containerStyle,
        item.task?.id ? 'bg-grey-10' : 'max-w-xl bg-brand-ghost-2'
      )}
    >
      <div className='truncate text-sm text-grey-70'>
        {item.task?.id ? taskStatus : jobStatus}
      </div>
      <div className='ml-1 text-xs'>{formatDate(item.createdAt, 'h:mm a')}</div>
    </div>
  );
}
