import PropTypes from 'prop-types'
import { Card } from '../ui/Card'
import styles from './AssetConfigCard.module.css'
import { ItemDetail } from './ItemDetail'

export const AssetConfigCard = ({ item }) => {
  const color =
    item.queued_type === 'Add'
      ? 'green'
      : item.queued_type === 'Remove'
      ? 'remove'
      : 'smoke'

  return (
    <>
      <Card color={color} shadow>
        <div className={styles.content}>
          <ItemDetail item={item} />
        </div>
      </Card>
      {item.error && (
        <div
          className='mb-4 text-xs font-semibold'
          style={{ marginTop: '-1rem', color: 'var(--color-red)' }}
        >
          {item.error}
        </div>
      )}
    </>
  )
}

AssetConfigCard.propTypes = {
  item: PropTypes.object.isRequired,
}
