import { Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';
import { EditJobForm } from '~/components/job/EditJobForm';
import { Loading } from '~/components/ui/Loading';
import { graphql } from '~/gql';
import { JobDocument, JobStatus } from '~/gql/graphql';

const { Created } = JobStatus;

const EditJobFormDocument = graphql(`
  query EditJobForm {
    integrations
    attributes(entityType: "Site") {
      id
      type
      category
      name
      value
      condition
    }
    jobTemplates(status: Active) {
      ...JobTemplateFields
    }
    contacts(status: Active) {
      ...ContactFields
    }
  }
`);

// TODO remove this
function useJobFormData(jobId: string) {
  const [formResult] = useQuery({
    query: EditJobFormDocument,
  });

  const [jobResult, reexecuteQuery] = useQuery({
    query: JobDocument,
    variables: { id: jobId },
    pause: jobId === undefined,
    requestPolicy: 'cache-and-network',
  });

  return {
    fetching: formResult.fetching || jobResult.fetching,
    stale: formResult.stale || jobResult.stale,
    form: formResult.data,
    job: jobResult.data,
    reload: () => reexecuteQuery({ requestPolicy: 'network-only' }),
  };
}

export function Component() {
  const { jobId } = useParams();

  invariant(jobId, 'jobId is required');

  // Destructuring an restructuring the data is required for typescript to
  // understand the undefined guards below.
  const { fetching, stale, form, job: data, reload } = useJobFormData(jobId);

  if (fetching || stale || !(form && data?.job)) {
    return <Loading spinner />;
  }

  return (
    <>
      <EditJobForm
        form={form}
        job={data.job}
        onSuccess={(status) => {
          if (status === Created) {
            reload();
          }
        }}
      />
      <Outlet />
    </>
  );
}
