import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import * as Yup from 'yup';
import { Square } from '~/brand/generated/propfit.app';
import { graphql } from '~/gql';
import { LogoSection } from '../LogoSection';
import { OtpInput } from './OtpInput';

type FormValues = {
  otp: string;
};

type Props = {
  id: string;
  redirectTo: string | null;
  onBack: (verify: null) => void;
};

const OtpVerify_Mutation = graphql(`
  mutation OtpVerify($input: OtpVerifyInput!) {
    otpVerify(input: $input) {
      success
    }
  }
`);

const validationSchema = Yup.object().shape({
  // TODO figure out how to validate email or mobile number
  otp: Yup.string().required('Required').length(6, 'Must be 6 digits'),
});

export const VerifyForm = ({ id, redirectTo, onBack }: Props) => {
  const { t } = useTranslation('auth');
  const [result, otpVerify] = useMutation(OtpVerify_Mutation);

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const login = async (values: FormValues) => {
    const { data } = await otpVerify({ input: { id, otp: values.otp } });

    if (data?.otpVerify.success) {
      window.location.href = redirectTo ?? '/';
      return;
    }

    setError('root.failure', { type: 'custom', message: 'Error' });
  };

  return (
    <div className='flex w-screen items-center justify-center h-screen'>
      <div className='w-full lg:w-1/2'>
        <form
          onSubmit={handleSubmit(login)}
          className='mx-auto flex flex-1 flex-col justify-center rounded-lg px-10 py-16 md:w-4/5 md:border md:border-gray88 lg:mx-auto lg:w-[30rem] lg:flex-initial'
        >
          <div className='mb-12 flex flex-col items-center justify-center'>
            <Square className='mx-auto h-20' />
            <h1 className='mt-5 text-center text-2xl font-medium'>
              {t('signInText')}
            </h1>
          </div>

          <OtpInput
            {...register('otp')}
            label='Enter the code sent to your email or mobile number'
            error={errors.otp?.message}
          />

          {errors?.root?.failure && (
            <div className='mt-2 text-sm text-red-500'>
              {errors.root.failure.message}
            </div>
          )}

          <button
            className='mb-4 w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark  disabled:opacity-50'
            disabled={result.fetching}
          >
            Submit
          </button>
          <div className='mt-8 text-center text-sm'>
            <span
              onClick={() => onBack(null)}
              className='cursor-pointer text-blue hover:underline'
            >
              {t('didntReceiveCode')}
            </span>
          </div>
        </form>
      </div>

      <LogoSection />
    </div>
  );
};
