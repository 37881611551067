import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { client } from '~/client';
import { graphql } from '~/gql';
import { Option } from '~/types';
import { Select } from './form/downshift/Select';

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
  options: Option[];
};

const AssetTypesDocument = graphql(`
  query AssetTypes {
    assetTypes
  }
`);

export function useAssetTypes() {
  const [assetTypes, setAssetTypes] = useState<string[]>([]);

  useEffect(() => {
    async function load() {
      const result = await client
        .query(AssetTypesDocument, {}, { requestPolicy: 'cache-and-network' })
        .toPromise();

      setAssetTypes(result.data?.assetTypes ?? []);
    }
    load();
  }, []);

  return [assetTypes];
}

export function AssetTypeSelect({ value, onChange, options }: Props) {
  const { t } = useTranslation();

  return (
    <Select
      label={t('assetType', { count: 0 })}
      options={options}
      value={value}
      onChange={onChange}
      multiple
    />
  );
}
