import { useTranslation } from 'react-i18next';
import { useCompleteJobMutation } from '~/generated/graphql';
import { ConfirmButton } from '../ConfirmDateTime';

type Props = {
  id: string;
  enabled: boolean;
};

export function CompleteJobButton({ id, enabled }: Props) {
  const { t } = useTranslation('job');
  const [, complete] = useCompleteJobMutation();

  return (
    <ConfirmButton
      title='EndTime'
      onConfirm={async (date) => {
        await complete({ id, endTime: date });
        /**
         *  Remove saved job tasks form
         */
        localStorage.removeItem(`job.tasks_${id}`);
      }}
      disabled={!enabled}
    >
      {t('job:completeJob')}
    </ConfirmButton>
  );
}
