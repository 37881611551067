import CheckCircleIcon from '../../../CheckCircleIcon';
import WarningIcon from '../../../WarningIcon';

type Status = 'success' | 'warning';

type Props = {
  number?: React.ReactNode;
  directive: React.ReactNode;
  status?: { icon: Status; text: string };
};

export function Hero({ number, directive, status }: Props) {
  return (
    <div className='flex flex-col justify-between'>
      <div>
        <div className='text-right text-3xl'>{number}</div>
        <div className='whitespace-nowrap text-right text-xs'>
          {number != null && directive}
        </div>
      </div>
      {status && (
        <div className='whitespace-nowrap text-right text-xs'>
          {status.text}
          {
            {
              success: (
                <span className='text-success ml-1 align-bottom'>
                  <CheckCircleIcon className='inline-block h-5 fill-current' />
                </span>
              ),
              warning: (
                <span className='ml-1 align-bottom'>
                  <WarningIcon className='inline-block' />
                </span>
              ),
            }[status.icon]
          }
        </div>
      )}
    </div>
  );
}

const createStatus = (icon: Status) => (text: string) => ({ icon, text });
export const success = createStatus('success');
export const warning = createStatus('warning');
