import { Search } from './Search';
import { SortBy } from './SortBy';

type FilterSortProps = {
  query: string;
  search: any;
  sortBy: (sort: { path: string; desc?: boolean } | undefined) => void;
  includeDest?: boolean;
};

export const FilterSort = (props: FilterSortProps) => {
  return (
    <div className='flex mb-4'>
      <div className='flex-1'>
        <Search query={props.query} search={props.search} />
      </div>
      <div className='flex-1'>
        <SortBy sortBy={props.sortBy} includeDest={props.includeDest} />
      </div>
    </div>
  );
};
