import SearchIcon from '@heroicons/react/outline/SearchIcon';

type Props = {
  value: string;
  onChange: (query: string) => void;
};

export function ControlledKeywordSearch({ value, onChange }: Props) {
  return (
    <div className='relative mx-3 text-sm lg:mx-0 lg:min-w-[300px] lg:text-base'>
      <div className='absolute bottom-0 left-3 top-0 flex items-center lg:left-3'>
        <SearchIcon className='mt-0.5 h-5 w-5 text-grey-20 lg:h-6 lg:w-6' />
      </div>
      <input
        className='w-full rounded border border-grey-20 px-2 py-2 pl-10 text-base placeholder:text-grey-40 focus-visible:border-grey-40 focus-visible:outline-none lg:h-11 lg:px-3 lg:py-2.5 lg:pl-11'
        placeholder='Keyword Search...'
        value={value}
        onChange={(event) => onChange(event.currentTarget.value)}
      ></input>
    </div>
  );
}
