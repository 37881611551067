import { ReactNode, useState } from 'react';
import { ToggleProvider } from './ToggleContext';

type Props = {
  children: ReactNode;
};

export function ToggleGroup({ children }: Props) {
  const [open, setOpen] = useState<string>('');

  return <ToggleProvider value={{ open, setOpen }}>{children}</ToggleProvider>;
}
