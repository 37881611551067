import { useMemo } from 'react';
import { Table } from '~/components/table/Table';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { Column } from 'react-table';

type Props = {
  [key: string]: any;
};

export const StockOnHandTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('sku') ?? 'SKU',
        accessor: 'skuCode',
        Cell: ({ row }: any) => (
          <>
            <div className='font-semibold'>{row.original.skuName}</div>
            {row.original.skuCode && (
              <div className='text-copy-alt'>{row.original.skuCode}</div>
            )}
          </>
        ),
        width: 350,
      },
      { Header: t('itemTypeLabel') as string, accessor: 'itemType', width: 60 },
      {
        Header: 'Location Type',
        accessor: 'locationType',
        width: 150,
        Cell: ({ value }) => t(camelCase(value)),
      },
      { Header: 'Location', accessor: 'locationName', width: 150 },
      { Header: 'Space', accessor: 'spaceName', width: 100 },
      {
        Header: 'Total on Hand',
        accessor: 'soh',
        width: 100,
        // @ts-expect-error missing type
        align: 'right',
      },
      {
        Header: 'Reserved on Hand',
        accessor: 'reserved',
        width: 100,
        // @ts-expect-error missing type
        align: 'right',
      },
      {
        Header: 'Available on Hand',
        // @ts-expect-error
        Cell: ({
          cell: {
            row: { original },
          },
        }: any) => {
          return original.soh - original.reserved;
        },
        width: 100,
        align: 'right',
      },
    ],
    [t]
  );

  const initialHiddenColumns = useMemo(() => ['itemType'], []);
  const initialSortBy = useMemo(
    () => [{ id: 'locationName' }, { id: 'spaceName' }, { id: 'itemType' }],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialHiddenColumns={initialHiddenColumns}
      initialSortBy={initialSortBy}
    />
  );
};
