import { Formik, useField } from 'formik';
import { groupBy, toPairs } from 'lodash';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isFocusedAtom, useAtom } from '~/atom';
import { Modal } from '~/components/Modal';
import { SkuInfo } from '~/components/smc/SkuInfo';
import { Context, Hero } from '~/components/smc/card/Hero';
import { StocktakeFilters } from '~/components/smc/filter/StocktakeFilters';
import { SpaceSkuPopup } from '~/components/space-sku/SpaceSkuPopup';
import { Card } from '~/components/ui/Card';
import { Button } from '~/components/ui/buttons/Button';
import {
  AddItemsTaskQuery,
  StockOnHandFilters,
  StockSelection,
  Stocktake,
  useStockOnHandQuery,
} from '~/generated/graphql';
import { remove } from '~/helpers/array';
import { useSearch } from '~/hooks/useSearch';
import FilterIcon from '../filter/FunnelIcon';
import { FloatingInput } from './TextFieldFloating';

type Item = Omit<AddItemsTaskQuery['addItemsTask']['cards'][0], '__typename'>;

type TInitialFilters = Partial<
  Pick<Stocktake, 'include' | 'itemStatus' | 'spaces'>
>;

type Props = {
  context: Context;
  name: string;
  location: string;
  items?: Item[];
  /** Optional filter space skus included in this list of space sku ids */
  in?: string[];
  readOnly?: boolean;
  initialFilters?: TInitialFilters | null;
  onFiltersUpdate?: (filters: StockOnHandFilters, ids: string[]) => void;
  moreFilters?: boolean;
};

const SEARCH_OPTIONS = {
  keys: ['sku.name', 'sku.code', 'sku.itemType', 'spaceName'],
};

export const StockSelectionField = ({
  context,
  name,
  location,
  items,
  readOnly,
  initialFilters,
  moreFilters = true,
}: Props) => {
  const { t } = useTranslation();
  const [field, , helpers] = useField<StockSelection[]>(name);
  const [viewSpaceSku, setViewSpaceSku] = useState<[string, string] | null>(
    null
  );

  const [filters, setFilters] = useState<StockOnHandFilters>({});

  // StockSelection component can remember filters for the given location for the duration of the session
  // this behaviour should be ignored if passed initial filters
  useEffect(() => {
    if (initialFilters) {
      setFilters({
        itemTypes: initialFilters.include,
        itemStatus: initialFilters.itemStatus,
        spaces: initialFilters.spaces,
      });
      return;
    }

    const storageKey = `${location}:filters`;
    const session = window.sessionStorage.getItem(storageKey);
    if (session) {
      try {
        const filters = JSON.parse(session);
        if (
          filters &&
          typeof filters === 'object' &&
          Object.keys(filters).length
        ) {
          setFilters(filters);
          return;
        }
      } catch (err) {}
    }
  }, [initialFilters, location]);

  useEffect(() => {
    const storageKey = `${location}:filters`;
    window.sessionStorage.setItem(storageKey, JSON.stringify(filters));
  }, [filters, location]);

  const [result] = useStockOnHandQuery({
    pause: !!items,
    requestPolicy: 'cache-and-network',
    variables: { location, filters },
  });
  const stock = items ?? (result.data?.stockOnHand as unknown as Item[]);

  const { results, query, search } = useSearch(stock, SEARCH_OPTIONS);

  const spaces = useMemo(
    () => toPairs(groupBy(results, 'space.name')),
    [results]
  );
  const [showFilters, setShowFilters] = useState(false);

  if (!location) return null;

  const config = {
    enableProcessAll: false, // [Context.PICK, Context.ADD].includes(context),
    keyOfHappyValue: (context === Context.PICK ? 'pick' : 'received') as
      | 'pick'
      | 'received',
  };

  const handleChange = (key: string) => (value: string) => {
    if (value === undefined) {
      return;
    }

    const qty = parseInt(value);
    const idx = field.value.findIndex(({ id }) => id === key);
    const newValue = idx > -1 ? remove(field.value, idx) : field.value;

    if (value === '' || isNaN(qty)) {
      helpers.setValue(newValue);
      return;
    }

    // This should make it impossible to adjust soh below zero
    if (context === Context.ADJUSTMENT) {
      const item = spaces
        .flatMap(([, items]) => items)
        .find(({ id }) => id === key);

      if (item && item.soh + qty < 0) {
        helpers.setValue([...newValue, { id: key, qty: -item.soh }]);
        return;
      }
    }

    helpers.setValue([...newValue, { id: key, qty }]);
  };

  const processAll = () => {
    // const all = stock.map((s) => ({
    //   id: s.id,
    //   qty: s[config.keyOfHappyValue],
    // }));
    // helpers.setValue(all);
  };

  const unprocessAll = () => {
    helpers.setValue([]);
  };

  const processAllButton =
    config.enableProcessAll &&
    (field.value.length ? (
      <Button type='button' className='mx-auto block' onClick={unprocessAll}>
        {t('Unprocess All')}
      </Button>
    ) : (
      <Button type='button' className='mx-auto block' onClick={processAll}>
        {t('Process All')}
      </Button>
    ));

  function handleFilter(filters: StockOnHandFilters) {
    setFilters(filters);
  }

  if (showFilters) {
    return (
      <Modal
        component={StocktakeFilters}
        filters={filters}
        location={location}
        onClose={() => setShowFilters(false)}
        onFilter={handleFilter}
        open
      />
    );
  }

  return (
    <>
      <SpaceSkuPopup
        id={viewSpaceSku?.join(':')}
        onClose={() => setViewSpaceSku(null)}
      />

      <div className='flex px-4'>
        <FloatingInput
          className='flex-1'
          label={t('keywordSearch')}
          type='text'
          value={query}
          onChange={(event) => search(event.currentTarget.value)}
        />
        {moreFilters && (
          <button
            className='block fill-current pl-2 text-sm font-bold text-copy-alt'
            onClick={() => setShowFilters(true)}
          >
            <FilterIcon className='mr-2 inline-block h-4' />
            {t('filters')}
          </button>
        )}
      </div>
      {!readOnly && <div className='text-center'>{processAllButton}</div>}
      {spaces.map(([spaceName, items], i) => {
        return (
          <Fragment key={spaceName}>
            <div className='sticky top-0 z-[2] mx-auto mb-1 w-fit bg-white px-4 py-1 text-xs font-medium'>
              {spaceName}
            </div>
            {items.map((item, j) => {
              return (
                <FocusElement
                  key={item.id}
                  context={context}
                  item={item}
                  value={
                    field.value
                      .find(({ id }) => id === item.id)
                      ?.qty?.toString() ?? ''
                  }
                  onChange={handleChange(item.id)}
                  onClick={() => setViewSpaceSku([item.space.id, item.sku.id])}
                  readOnly={readOnly}
                />
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};

type FocusElementProps = {
  context: Context;
  item: Item;
  value: string;
  onChange: (value: string) => void;
  /**
   * You can use this to signal to the parent that the item was focused/opened
   * and then do something with it e.g. scroll it into view
   */
  onOpen?: (id: string) => void;
  /**
   * Only called if the component is in VIEW_STOCK mode
   * Tuple [id, itemName]
   */
  onClick: (item: [string, string]) => void;
  readOnly?: boolean;
};

export enum CardStatus {
  DEFAULT,
  SUCCESS,
  WARNING,
}

const FocusElement = ({
  context,
  item,
  value,
  onChange,
  onOpen,
  onClick,
  readOnly,
}: FocusElementProps) => {
  const isProcessed = value !== '';
  const ref = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useState(false);

  // Handle a global state for any focused stock selection
  const [, setIsFocused] = useAtom(isFocusedAtom);
  useEffect(() => setIsFocused(focus), [focus, setIsFocused]);

  useEffect(() => {
    if (!focus) return;

    setTimeout(
      () => {
        // Workaround fix for mobile devices' unnecessary scrolling when native keyboard is opened
        window.scrollTo(0, 0);

        ref.current?.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth',
        });
      },
      // ! Think this is needed to delay scroll until after a transition timing?
      // would prefer not to have to do this as it is quite jarring
      101
    );
  }, [focus]);

  const expectedValue =
    context === Context.PICK
      ? item.pick
      : context === Context.ADD
      ? item.received
      : context === Context.STOCKTAKE
      ? item.soh
      : undefined;
  const status = !isProcessed
    ? CardStatus.DEFAULT
    : expectedValue === undefined ||
      expectedValue === null ||
      expectedValue.toString() === value
    ? CardStatus.SUCCESS
    : CardStatus.WARNING;

  useEffect(() => {
    if (focus && onOpen) {
      onOpen(item.id);
    }
  }, [focus, item.id, onOpen]);

  const handleBlur: React.FocusEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setFocus(false);
  };

  const handleFocus: React.FocusEventHandler<HTMLDivElement> = (event) => {
    if (!readOnly) {
      setFocus(true);
    }
  };

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (focus) {
      event.currentTarget.dataset.shouldClose = 'true';
    }
  };

  const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.currentTarget.dataset.shouldClose === 'true') {
      setFocus(false);
      delete event.currentTarget.dataset.shouldClose;
    }
  };

  // TODO should use a router <Link> instead
  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    if (context === Context.VIEW_STOCK) onClick([item.id, item.sku.name]);
  };

  return (
    <div ref={ref} className='relative scroll-mt-3'>
      <input
        className='absolute left-4 top-0 z-50 h-4 w-4 rounded-full bg-red-500 opacity-0 focus:bg-green-500' //h-0 opacity-0'
        type='number'
        inputMode='decimal'
        onFocus={(event) => {
          const prev = prevSelector(event.target.parentElement, 'div.relative');
          if (!prev) {
            event.target.blur();
            return;
          }
          prev.querySelector<HTMLDivElement>('div.js-card')?.focus();
        }}
      />
      <div
        className='js-card px-4'
        tabIndex={readOnly || focus ? undefined : 0}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={handleClick}
      >
        <Card className='mb-4 flex p-4' color={getStatusColour(status)} shadow>
          <SkuInfo {...item.sku} />

          <Hero
            context={context}
            item={item}
            open={focus}
            status={status}
            value={value}
            onChange={onChange}
          />
        </Card>
      </div>
      <input
        className='absolute bottom-0 right-4 z-50 h-4 w-4 rounded-full bg-red-500 opacity-0 focus:bg-green-500' //h-0 opacity-0'
        type='number'
        inputMode='decimal'
        onFocus={(event) => {
          const next = nextSelector(event.target.parentElement, 'div.relative');
          next?.querySelector<HTMLDivElement>('div.js-card')?.focus();
        }}
      />
    </div>
  );
};

const getStatusColour = (status: CardStatus) => {
  switch (status) {
    case CardStatus.DEFAULT:
      return 'smoke';
    case CardStatus.SUCCESS:
      return 'green';
    case CardStatus.WARNING:
      return 'warning';
  }
};

const siblingSelector =
  (property: 'previousElementSibling' | 'nextElementSibling') =>
  (el: HTMLElement | null, selectors: string) => {
    if (!el) {
      return;
    }
    let sibling = el[property];
    while (sibling != null) {
      if (sibling.matches(selectors)) {
        return sibling;
      }
      sibling = sibling[property];
    }
  };

const prevSelector = siblingSelector('previousElementSibling');
const nextSelector = siblingSelector('nextElementSibling');

/** A little bit hacky read only component for StockSelectionField */
export const Stock = ({
  location,
}: Pick<Props, 'location' | 'onFiltersUpdate'>) => {
  return (
    <Formik initialValues={{ adjustments: [] }} onSubmit={() => {}}>
      <StockSelectionField
        context={Context.VIEW_STOCK}
        // Use the name adjustments to mirror naming in Step2Form
        name='adjustments'
        location={location}
        readOnly
      />
    </Formik>
  );
};
