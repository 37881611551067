import { FieldArray, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FindAddContactField } from '~/components/FindAddContact';
import { AddressInputGroup } from '~/components/form/AddressInputGroup';
import { StatusToggle } from '~/components/form/StatusToggle';
import { SubmitButton } from '~/components/form/SubmitButton';
import { TextField } from '~/components/form/TextField';
import { UploadField } from '~/components/upload/Upload';
import {
  SiteInput,
  SiteQuery,
  Status,
  useSiteTypesQuery,
} from '~/generated/graphql';
import { Attachment } from '~/gql/graphql';
import { parseJson } from '~/helpers';
import { SideLayout } from '~/layouts/side/SideLayout';
import { PromptEffect } from '../Prompt';
import { Button } from '../form/SubmitButton';
import { TimePickerField } from '../form/TimePicker';
import { ComboboxField } from '../ui/Combobox';
import { Label } from '../ui/Label';

type Props = {
  title: string;
  site?: SiteQuery['site'];
  onClose: (siteId?: string) => void;
  onSubmit: (input: SiteInput) => void;
};

export const SiteForm = ({ title, site, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();

  const attachments = site?.attributes?.reduce(
    (prev: Record<string, Attachment[]>, curr) => {
      if (curr.attachments && curr.attachments.length) {
        return { ...prev, [curr.id]: curr.attachments };
      }
      return prev;
    },
    {}
  );

  const initialValues: SiteInput = {
    status: site?.status ?? Status.Active,
    name: site?.name ?? '',
    type: site?.type ?? '',
    address: site?.address ? parseJson(site.address) : null,
    defaultSourceId: site?.defaultSource?.id ?? null,
    licensorId: site?.licensor?.id ?? null,
    notes: site?.notes ?? '',
    // TODO do not submit attribute values with create/update site at all
    attributes:
      site?.attributes
        ?.filter(({ id }) => !id.startsWith('integration_'))
        .map(({ id, value, attachments }) => ({
          id,
          name: id,
          value,
          attachments: attachments?.map((saved) => ({
            id: saved.id,
            kind: saved.kind,
            originalFilename: saved.originalFilename,
            preview: saved.thumb,
          })),
        })) ?? [],
    images: site?.images ?? [],
    airbnbCalendar: (site?.airbnbCalendar?.map(({ url }) => url) ?? [
      '',
    ]) as string[],
    airbnbDefaultCheckIn: site?.airbnbDefaultCheckIn ?? '',
    airbnbDefaultCheckOut: site?.airbnbDefaultCheckOut ?? '',
  };

  const validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required('Please complete this required field'),
      // .test('name-exists', 'Please enter a unique name', checkNameIsUnique),
      airbnbCalendar: Yup.array().of(
        Yup.string().matches(
          /^https:\/\/(?:www\.airbnb\.com\.au\/calendar\/ical\/(\d+)\.ics\?s=([0-9a-f]+)|admin.booking.com\/hotel\/hoteladmin\/ical.html\?t=[0-9a-f-]+|app\.guesty\.com\/api\/public\/icalendar-dashboard-api\/export\/[0-9a-f-]+|api\.homhero\.com\.au\/ical\/.+)$/,
          'Invalid calendar URL'
        )
      ),
      // address_data: Yup.object().shape({
      //   subpremise: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   street_number: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   route: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   locality: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   administrative_area_level_1: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   postal_code: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      //   country: Yup.string()
      //     .min(1)
      //     .required('Please complete this required field'),
      // }),
    });
  };

  function handleClose() {
    onClose(site?.id);
  }

  async function handleSubmit(input: SiteInput) {
    return onSubmit({
      ...input,
      address: JSON.stringify(input.address),
      attributes: input.attributes?.map(({ name, value }) => ({
        name,
        value,
      })),
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors }) => (
        <SideLayout>
          <PromptEffect cancel='Keep Editing' />
          <SideLayout.Head onClose={handleClose}>
            {title && t(title)}
          </SideLayout.Head>
          <SideLayout.Body>
            <StatusToggle />

            <TextField name='name' label={t('siteName')} required />

            <SiteTypeField />

            <AddressInputGroup name='address' label='Address' />

            <FindAddContactField
              label={t('licensor')}
              name='licensorId'
              type='Owner'
            />

            {/* <SelectField
            label={t('defaultSource')}
            name='defaultSourceId'
            options={siteOptions}
          /> */}

            {site?.id && (
              <>
                <Label>
                  {t('calendar', { count: values.airbnbCalendar?.length ?? 1 })}
                </Label>
                <FieldArray
                  name='airbnbCalendar'
                  render={(arrayHelpers) => (
                    <div className='mb-5'>
                      {values.airbnbCalendar &&
                        values.airbnbCalendar.length > 0 &&
                        values.airbnbCalendar.map((url, index) => (
                          <TextField
                            key={index}
                            name={`airbnbCalendar.${index}`}
                            label=''
                            className='mb-2'
                            placeholder='Paste ical url here'
                          />
                        ))}
                      {values.airbnbCalendar &&
                        values.airbnbCalendar[
                          values.airbnbCalendar.length - 1
                        ] !== '' && (
                          <Button
                            type='button'
                            intent='text'
                            size='small'
                            onClick={() => arrayHelpers.push('')}
                          >
                            Add another calendar
                          </Button>
                        )}
                    </div>
                  )}
                />
              </>
            )}

            {values.airbnbCalendar?.length && !errors.airbnbCalendar && (
              <div className='flex gap-5'>
                <TimePickerField
                  label='Default check-in'
                  name='airbnbDefaultCheckIn'
                  focusValue='15:00'
                />
                <TimePickerField
                  label='Default check-out'
                  name='airbnbDefaultCheckOut'
                  focusValue='10:00'
                />
              </div>
            )}

            <TextField name='notes' label='Notes' />

            <UploadField
              name='images'
              images={site?.image ? [site?.image] : undefined}
            />
          </SideLayout.Body>
          <SideLayout.Foot className='p-4'>
            <SubmitButton />
          </SideLayout.Foot>
        </SideLayout>
      )}
    </Formik>
  );
};

function SiteTypeField() {
  const { t } = useTranslation();
  const [result] = useSiteTypesQuery();
  const types = result.data?.siteTypes ?? [];

  return <ComboboxField name='type' label={t('siteType')} items={types} />;
}
