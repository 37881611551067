import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/form/SubmitButton';
import { Dialog } from '~/components/ui/Dialog';
import { Option } from '~/types';
import { SearchInput } from '../FindAddContact/SearchInput';

type FormValues = {
  fromTemplate: string;
};

type Props = {
  templateOptions: Option[];
  onSelect: (name: string) => void;
};

export const UseTemplateButton = ({ templateOptions, onSelect }: Props) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const [query, setQuery] = useState('');

  const { control, reset, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      fromTemplate: '',
    },
  });

  useEffect(() => reset(), [reset, showDialog]);

  const filtered =
    query === ''
      ? templateOptions
      : templateOptions.filter((option) =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const onSubmit = handleSubmit(({ fromTemplate }) => {
    if (fromTemplate) {
      onSelect(fromTemplate);
      setShowDialog(false);
    }
  });

  return (
    <>
      <Dialog
        title={t('useTemplate')}
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <form onSubmit={onSubmit} className='p-3 text-left'>
          <div className='mb-1'>
            <SearchInput setQuery={setQuery} query={query} />
          </div>
          <div className='mb-1 max-h-[50vh] overflow-y-auto'>
            {filtered.map((option) => (
              <Controller
                control={control}
                name='fromTemplate'
                render={({ field }) => (
                  <label
                    className='relative block py-1.5 pl-6 text-left'
                    key={option.value}
                  >
                    <input
                      {...field}
                      type='radio'
                      name='fromTemplate'
                      className='absolute left-0 top-2.5 h-4 w-4'
                      value={option.value}
                    />
                    {option.label}
                  </label>
                )}
              />
            ))}
          </div>

          <div className='flex justify-end gap-3'>
            <button
              type='button'
              className='px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand'
              onClick={() => setShowDialog(false)}
            >
              {t('cancel')}
            </button>

            <Button
              type='submit'
              intent='primary'
              size='small'
              disabled={!watch('fromTemplate')}
            >
              {t('use')}
            </Button>
          </div>
        </form>
      </Dialog>
      <Button onClick={() => setShowDialog(true)} intent='text' size='small'>
        {t('useTemplate')}
      </Button>
    </>
  );
};
