import { useRef } from 'react';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { Card, CardProps } from '~/components/ui/Card';
import { useToggleContext } from './ToggleContext';

type Props = {
  children: (
    isOpen: boolean,
    toggle: (open?: boolean) => void
  ) => React.ReactNode;
  toggleKey: string;
} & CardProps;

export const ToggleCard = ({ children, toggleKey, ...props }: Props) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { open, setOpen } = useToggleContext();
  const { isMobile } = useBreakpoint();

  const toggle = (isOpen?: boolean) => {
    const card = cardRef.current;

    if (card) {
      if (isMobile) window.scrollTo(0, card.offsetTop - 333);
      else card.parentElement?.scrollTo(0, card.offsetTop - 190);
    }

    isOpen === undefined
      ? setOpen(open === toggleKey ? '' : toggleKey)
      : setOpen(isOpen ? toggleKey : '');
  };

  return (
    <Card ref={cardRef} {...props}>
      {children(open === toggleKey, toggle)}
    </Card>
  );
};
