import { LocationField } from '~/components/form/LocationField'
import { ModalForm } from '~/components/form/ModalForm'
import * as Yup from 'yup'

const destinationSchema = Yup.object().shape({
  destination: Yup.object().shape({
    type: Yup.string().required('Required'),
    id: Yup.array()
      .min(1, 'Required')
      .max(1)
      .of(Yup.string().required('Required')),
  }),
})

export const DestinationForm = ({ onSuccess, step, onPrevState, prevData }) => {
  const initialValues = prevData[step]
    ? prevData[step]
    : { destination: { type: 'Asset', id: [] } }

  const handleSubmit = (values) => {
    onSuccess(values)
  }

  return (
    <ModalForm
      title='Transfer Stock'
      formikProps={{
        initialValues,
        onSubmit: handleSubmit,
        validationSchema: destinationSchema,
        validateOnChange: false,
        validateOnBlur: false,
      }}
      buttonText='Next'
      onPrevState={() => onPrevState(step - 1)}
    >
      <LocationField name='destination' title='Destination Location Type' />
    </ModalForm>
  )
}
