import { useOptions } from '~/api';
import { FilterForm, FilterProps } from '~/components/filter/FilterForm';
import { SelectField } from '~/components/form/downshift/SelectField';
import { SessionStorageEffect } from '~/components/form/SessionStorageEffect';
import { StatusSelectField } from '~/components/form/StatusSelectField';
import {
  ItemType,
  LocationType,
  useLocationsQuery,
  useSitesQuery,
  useSiteTypesQuery,
  useSpacesQuery,
} from '~/generated/graphql';
import { useEnumOptions } from '~/hooks/useEnumOptions';
import QueryString from 'qs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProps } from '.';

type Props = FilterProps & {
  locationType: ConfigProps['locationType'];
};

export const AssetConfigFilterForm = ({ locationType, ...props }: Props) => {
  const { t } = useTranslation();
  const { data: skus } = useOptions('skus');

  // const [{ data: sitesData }] = useSitesQuery();
  // const sites =
  //   sitesData?.sites?.map(({ id, name }) => ({ value: id, label: name })) ?? [];
  const itemTypes = useEnumOptions(ItemType, 'itemType');

  const { storageKey, Fields } = useMemo(() => {
    switch (locationType) {
      case 'Asset':
        return {
          storageKey: 'form.filter.asset-config',
          Fields: AssetFilters,
        };
      case 'Site':
        return {
          storageKey: 'form.filter.site-config',
          Fields: SiteFilters,
        };
      default:
        return {
          storageKey: 'form.filter.config',
          Fields: DefaultFilters,
        };
    }
  }, [locationType]);

  const [, queryString] = window.location.href.split('?', 2);
  const locationId = queryString && QueryString.parse(queryString).locationId;

  return (
    <FilterForm
      cols='5'
      initialValues={{
        locationStatus: ['Active'],
        locationTypes: [],
        locationIds: typeof locationId === 'string' ? [locationId] : [],
        assets: [],
        assetModels: [],
        sites: [],
        siteNames: [],
        siteTypes: [],
        skus: [],
        spaceNames: [],
        price: [],
        capacity: [],
        target: [],
        missing: [],
        itemType: [],
        configStatus: [],
        states: [],
      }}
      {...props}
    >
      <SessionStorageEffect storageKey={storageKey} />
      <Fields />
      {/* <SelectField options={sites} name='sites' label='Site' multiple /> */}
      <SpaceSelectField />
      <SelectField
        floating
        options={itemTypes}
        name='itemType'
        label={t('itemTypeLabel')}
        placeholder={t('itemTypeLabel')}
        multiple
      />
      <SelectField
        floating
        options={skus ?? []}
        name='skus'
        label={t('itemName')}
        placeholder={t('itemName')}
        multiple
      />
      {/* <SelectField
        options={[
          { value: 'active', label: 'Active' },
          // { value: 'inactive', label: 'Inactive' },
          { value: 'queued', label: 'Queued' },
        ]}
        name='configStatus'
        label='Configuration Status'
        multiple
      /> */}
      {/* <AssetConfigNumberField name='price' /> */}
      {/* <AssetConfigNumberField name='capacity' /> */}
      {/* <AssetConfigNumberField name='target' /> */}
      {/* <AssetConfigNumberField name='missing' /> */}
    </FilterForm>
  );
};

function SpaceSelectField() {
  const [result] = useSpacesQuery();
  const options = result.data?.spaceNames.map((value) => ({
    value,
    label: value,
  }));

  return (
    <SelectField
      floating
      options={options ?? []}
      name='spaceNames'
      label='Space Name'
      placeholder='Space Name'
      multiple
    />
  );
}

// const AssetConfigNumberField = ({ name }: { name: string }) => {
//   const { t } = useTranslation();
//   const [field, , helpers] = useField(name);
//   const [value, setValue] = useState(field.value ? field.value.join(',') : '');

//   return (
//     <Input
//       type='text'
//       name={name}
//       label={t(name)}
//       value={value}
//       onBlur={(event) => {
//         const { value } = event.currentTarget;
//         helpers.setValue(value ? value.split(',') : []);
//       }}
//       onChange={(event) => {
//         setValue(event.currentTarget.value);
//       }}
//     />
//   );
// };

function AssetFilters() {
  const { data: assets } = useOptions('assets');
  const { data: assetModels } = useOptions('asset-models');
  const { t } = useTranslation();

  return (
    <>
      <SelectField
        options={
          assets?.map(({ value, label }) => ({
            value: 'Asset:' + value,
            label,
          })) ?? []
        }
        name='assets'
        label={t('assetName')}
        placeholder={t('assetName')}
        multiple
      />
      <SelectField
        options={assetModels ?? []}
        name='assetModels'
        label='Asset Models'
        placeholder='Asset Models'
        multiple
      />
    </>
  );
}

function SiteFilters() {
  const [{ data: sitesData }] = useSitesQuery();
  const [{ data: siteTypesData }] = useSiteTypesQuery();
  const { t } = useTranslation();

  return (
    <>
      <SelectField
        floating
        options={
          sitesData?.sites.map(({ name: value }) => ({
            value,
            label: value,
          })) ?? []
        }
        name='siteNames'
        label={t('siteName')}
        placeholder={t('siteName')}
        multiple
      />
      <SelectField
        floating
        options={
          siteTypesData?.siteTypes
            .map((value) => ({ value, label: value }))
            .sort((a, b) => a.label.localeCompare(b.label)) ?? []
        }
        name='siteTypes'
        label={t('siteType')}
        placeholder={t('siteType')}
        multiple
      />
    </>
  );
}

function DefaultFilters() {
  const [{ data: locationsData }] = useLocationsQuery({
    variables: { type: LocationType.Site },
  });
  const locations =
    locationsData?.locations?.map(({ __typename: type, id, name }) => ({
      value: `${type}:${id}`,
      label: name,
    })) ?? [];

  return (
    <>
      <SelectField
        floating
        options={locations ?? []}
        name='locationIds'
        label='Location'
        placeholder='Location'
        multiple
      />
      <StatusSelectField name='locationStatus' labelKey='locationStatus' />
    </>
  );
}
