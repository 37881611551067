import { VariantList } from '~/components/item/VariantList';
import { Gallery } from '~/components/ui/Gallery';
import { Loading } from '~/components/ui/Loading';
import { Menu } from '~/components/ui/Menu';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { ItemsQuery } from '~/generated/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';

type ChildProps = { item: ItemsQuery['items'][0] };

function ItemMenu() {
  const { t } = useTranslation();

  return (
    <>
      <Menu>
        <Menu.Item as={Link} to='edit'>
          {t('editItem')}
        </Menu.Item>
      </Menu>
    </>
  );
}

const ReadView = ({ item }: ChildProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showInactive, setShowInactive] = useState<boolean>(false);
  const inactiveCount = useMemo(() => {
    return item.skus.reduce((count, sku) => {
      return count + (sku.status === 'Inactive' ? 1 : 0);
    }, 0);
  }, [item.skus]);

  const skus = showInactive
    ? item.skus
    : item.skus.filter((v) => v.status === 'Active');

  return (
    <SideLayout>
      <SideLayout.Head
        rightSlot={<ItemMenu />}
        onClose={() => navigate('/items')}
      >
        {item.name}
      </SideLayout.Head>
      <SideLayout.Body>
        <div className='mb-5'>
          <StatusBadge value={item.status} rounded />
        </div>

        <ReadValue label='itemTypeLabel' value={t('itemType.' + item.type)} />
        <ReadValue label='description' value={item.description} />

        {!item.hasVariants && (
          <ReadValue label='skuCode' value={item.skuPrefix} />
        )}

        <Gallery images={item.image} />

        {item.hasVariants && (
          <div>
            <div className='flex items-center'>
              <h3 className='my-4 font-semibold'>{t('Variants')}</h3>
              {inactiveCount > 0 && (
                <label className='ml-auto block text-sm'>
                  <input
                    className='mr-1'
                    type='checkbox'
                    checked={showInactive}
                    onChange={(event) => setShowInactive(event.target.checked)}
                  />
                  Show {inactiveCount} Inactive
                </label>
              )}
            </div>
            <VariantList skus={skus} />
          </div>
        )}
      </SideLayout.Body>
    </SideLayout>
  );
};

export const ItemView = ({ data }: { data?: ItemsQuery }) => {
  const { id } = useParams();
  const item = useMemo(
    () => data?.items.find((i) => i.id.toString() === id),
    [data, id]
  );

  if (!item) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route path='*' element={<ReadView item={item} />} />
    </Routes>
  );
};
