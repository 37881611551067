import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button as BaseButton } from '~/components/ui/buttons/Button';
import { SideLayout } from '~/layouts/side/SideLayout';
import { BulkAllocate } from './BulkAllocate';
import { BulkCancel } from './BulkCancel';
import { BulkComplete } from './BulkComplete';
import { BulkReschedule } from './BulkReschedule';
import { BulkTags } from './BulkTags';
import { BulkTemplateUpdate } from './BulkTemplateUpdate';

type Props = {
  selected: string[];
  onClose: () => void;
};

enum BulkEditAction {
  ALLOCATE = 'ALLOCATE',
  UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
  RESCHEDULE = 'RESCHEDULE',
  TAGS = 'TAGS',
}

const { ALLOCATE, UPDATE_TEMPLATE, COMPLETE, CANCEL, RESCHEDULE, TAGS } =
  BulkEditAction;

function Button({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <BaseButton
      as='div'
      className='block w-2/5'
      color='primary'
      onClick={onClick}
      rounded
    >
      {children}
    </BaseButton>
  );
}

export function BulkEditView({ selected, onClose }: Props) {
  const { t } = useTranslation();
  const [action, setAction] = useState<BulkEditAction | null>(null);

  const closeConfirmation = () => setAction(null);
  const confirmationDialog =
    action === CANCEL ? (
      <BulkCancel
        selected={selected}
        onCancel={closeConfirmation}
        onSuccess={onClose}
      />
    ) : null;

  return (
    <div
      className={classNames([
        'fixed inset-0 z-50 w-full overflow-hidden bg-white h-screen lg:relative',
        'lg:max-w-min',
        'lg:h-[calc(100vh-10rem-71px-1px)]',
      ])}
    >
      {confirmationDialog}
      {action === ALLOCATE ? (
        <BulkAllocate
          selected={selected}
          onCancel={closeConfirmation}
          onSuccess={() => {
            toast.success(`${selected.length} jobs changed`);
            onClose();
          }}
        />
      ) : action === UPDATE_TEMPLATE ? (
        <BulkTemplateUpdate
          selected={selected}
          onCancel={closeConfirmation}
          onSuccess={() => {
            toast.success(`${selected.length} jobs changed`);
            onClose();
          }}
        />
      ) : action === COMPLETE ? (
        <BulkComplete
          selected={selected}
          onCancel={closeConfirmation}
          onSuccess={() => {
            toast.success(`${selected.length} jobs changed`);
            onClose();
          }}
        />
      ) : action === RESCHEDULE ? (
        <BulkReschedule
          selected={selected}
          onCancel={closeConfirmation}
          onSuccess={() => {
            toast.success(`${selected.length} jobs changed`);
            onClose();
          }}
        />
      ) : action === TAGS ? (
        <BulkTags
          selected={selected}
          onCancel={closeConfirmation}
          onSuccess={() => {
            toast.success(`${selected.length} jobs changed`);
            onClose();
          }}
        />
      ) : (
        <SideLayout>
          <SideLayout.Head onClose={onClose}>
            {t('selected', { count: selected.length })}
          </SideLayout.Head>
          <SideLayout.Body className='mb-32 flex flex-col items-center justify-center'>
            <Button onClick={() => setAction(ALLOCATE)}>Allocate</Button>
            <Button onClick={() => setAction(RESCHEDULE)}>Reschedule</Button>

            <Button onClick={() => setAction(UPDATE_TEMPLATE)}>
              Update Template
            </Button>
            <Button onClick={() => setAction(TAGS)}>Add / Remove Tags</Button>
            <Button onClick={() => setAction(COMPLETE)}>Complete</Button>
            {/* TODO disable the cancel button if job status not one of: Created, Offered, Accepted */}
            <Button onClick={() => setAction(CANCEL)}>Cancel</Button>
          </SideLayout.Body>
        </SideLayout>
      )}
    </div>
  );
}
