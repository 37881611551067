import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VariantProps, cva } from 'class-variance-authority';
import classNames from 'classnames';

const switchStyles = cva(
  'relative inline-flex items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 w-9 px-0.5 transition duration-200 ease-in-out',
  {
    variants: {
      active: {
        true: 'bg-brand text-brand',
        false: 'bg-secondary text-secondary',
      },
    },
  }
);

type SwitchProps = {
  name?: string;
  active: boolean;
  /**
   * Switch label
   */
  label: string;
  /**
   * Will display a simple tooltip with the helper text provided
   */
  tooltip?: string;
  onChange: (checked: boolean) => void;
} & VariantProps<typeof switchStyles> &
  Omit<React.HTMLProps<HTMLInputElement>, 'onChange'>;

export function Switch({
  className,
  label,
  name = label,
  active,
  onChange,
  tooltip,
  ...props
}: SwitchProps) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange && onChange(event.target.checked);
  };
  return (
    <div className='flex items-center'>
      <label htmlFor={name} className='mx-3 my-1 flex items-center gap-2'>
        <div className={switchStyles({ active, className })}>
          <input
            {...props}
            className='hidden'
            type='checkbox'
            name={name}
            id={name}
            checked={active}
            onChange={handleChange}
          />
          <span
            aria-hidden='true'
            className={classNames(
              'pointer-events-none my-0.5 flex w-3.5 transform items-center justify-center rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out',
              active ? 'translate-x-3.5' : 'translate-x-0'
            )}
          >
            <FontAwesomeIcon
              icon={active ? faCheck : faXmark}
              className='my-0.5 h-2.5 w-2.5'
            ></FontAwesomeIcon>
          </span>
        </div>
        <span className='truncate pb-0.5'>{label}</span>
      </label>
      {/* {tooltip && <HelperPopup>{tooltip}</HelperPopup>} */}
    </div>
  );
}
