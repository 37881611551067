import type { Attachment } from '~/gql/graphql';
import { parseJson } from '~/helpers';
import { formatDate } from '~/helpers/formatDate';
import type { Contact } from '~/routes/resources/contacts';
import { Gallery } from '../job/JobView/Feed/Gallery';
import { Linkify } from '../job/JobView/Feed/Linkify';
import { Attribute } from './AttributeInput';
import { FilesListWithGallery } from './FilesListWithGallery';

type Props = {
  value: string;
  attribute: Attribute;
  attachments?: Attachment[];
  // TODO use a hook to fetch these when needed
  contacts: Contact[];
};

export const AttributeReadValue = (props: Props) => {
  if (empty(props.value)) {
    return <span className='text-grey-50'>&mdash;</span>;
  }

  switch (props.attribute.type) {
    case 'attachment':
      return <Attachment {...props} />;
    case 'contact':
      return <Contact {...props} />;
    case 'date':
      return <>{formatDate(props.value, 'PP')}</>;
    default:
      return <Default>{props.value}</Default>;
  }
};

function Attachment({
  attribute,
  attachments,
}: Pick<Props, 'attribute' | 'attachments'>) {
  if (attribute.options?.kind !== 'file') {
    return <>{attachments && <Gallery attachments={attachments} />}</>;
  }

  return (
    <>{attachments && <FilesListWithGallery attachments={attachments} />}</>
  );
}

function Contact(props: Pick<Props, 'value' | 'contacts'>) {
  const { contacts } = props;

  const value = parseJson(props.value);

  return (
    <>
      {Array.isArray(value) &&
        contacts
          .filter(({ id }) => value.includes(id))
          .map(({ name }) => name)
          .join('; ')}
    </>
  );
}

function Default(props: { children: string }) {
  return <Linkify>{props.children}</Linkify>;
}

function empty(value: string) {
  return !(value && value !== '[]' && value !== '""');
}
