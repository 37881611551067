import { SVGProps } from 'react';

const SvgHouseUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='16'
    height='15'
    viewBox='0 0 16 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      id='Vector'
      d='M8.41016 0.914062L15.6289 7.03906C15.9023 7.28516 15.9297 7.69531 15.7109 7.96875C15.4648 8.24219 15.0547 8.26953 14.7812 8.05078L14.125 7.47656V12.5625C14.125 13.793 13.1406 14.75 11.9375 14.75H4.0625C2.83203 14.75 1.875 13.793 1.875 12.5625V7.47656L1.19141 8.05078C0.917969 8.26953 0.507812 8.24219 0.261719 7.96875C0.0429688 7.69531 0.0703125 7.28516 0.34375 7.03906L7.5625 0.914062C7.80859 0.722656 8.16406 0.722656 8.41016 0.914062ZM12.8125 6.35547L8 2.28125L3.1875 6.35547V12.5625C3.1875 13.0547 3.57031 13.4375 4.0625 13.4375H11.9375C12.4023 13.4375 12.8125 13.0547 12.8125 12.5625V6.35547ZM9.75 6.875C9.75 7.50391 9.39453 8.07812 8.875 8.40625C8.32812 8.70703 7.64453 8.70703 7.125 8.40625C6.57812 8.07812 6.25 7.50391 6.25 6.875C6.25 6.27344 6.57812 5.69922 7.125 5.37109C7.64453 5.07031 8.32812 5.07031 8.875 5.37109C9.39453 5.69922 9.75 6.27344 9.75 6.875ZM7.125 9.5H8.875C10.0781 9.5 11.0625 10.4844 11.0625 11.6875C11.0625 11.9336 10.8438 12.125 10.625 12.125H5.375C5.12891 12.125 4.9375 11.9336 4.9375 11.6875C4.9375 10.4844 5.89453 9.5 7.125 9.5Z'
      fill={props.color ? props.color : 'black'}
    />
  </svg>
);

export default SvgHouseUserIcon;
