import { useEffect, useState } from 'react';
import { client } from '~/client';
import {
  CreateJobFormDocument,
  CreateJobFormQuery,
  CreateJobsMutation,
} from '~/gql/graphql';
import { CreateJobForm } from './job/CreateJobForm';
import { Loading } from './ui/Loading';
import { PopupDialogNew } from './ui/PopupDialogNew';

type Props = {
  locationId?: string;
  onCancel?: () => void;
  onSuccess?: (data: CreateJobsMutation) => void;
  show: boolean;
  onClose: () => void;
};

export const AddJobPopup = ({
  locationId,
  onCancel,
  onSuccess,
  onClose,
  show,
}: Props) => {
  const [data, setData] = useState<CreateJobFormQuery>();

  useEffect(() => {
    async function load() {
      const { data } = await client
        .query(
          CreateJobFormDocument,
          {},
          { requestPolicy: 'cache-and-network' }
        )
        .toPromise();
      setData(data);
    }
    load();
  }, []);

  return (
    <PopupDialogNew isOpen={show} onClose={onClose}>
      {data ? (
        <CreateJobForm
          data={data}
          locationId={locationId}
          onCancel={onCancel}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      ) : (
        <div className='flex h-full w-full items-center justify-center'>
          <Loading spinner />
        </div>
      )}
    </PopupDialogNew>
  );
};
