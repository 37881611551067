import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import { SelectorIcon as ChevronUpDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';
import { getFragmentData, graphql } from '~/gql';
import { ListAssetsQuery } from '~/gql/graphql';
import { Combolist } from '../Combolist';
import { styles } from '../FindAddContact/ContactSelect';
import { PopoverStateChangedEffect } from '../PopoverStateChangedEffect';
import { Avatar } from '../ui/Avatar';
import { Label } from '../ui/Label';
import { MenuTransition } from '../ui/Transition/MenuTransition';

// TODO use fragment masking instead
type Asset = ListAssetsQuery['assets'][number];

export type Props = {
  assets: Asset[];
  value: Asset[];
  onChange: (selected: Asset[]) => void;
  multiple?: boolean;
};

export const DeploymentFieldsFragment = graphql(`
  fragment DeploymentFields on Deployment {
    id
    displayLocation
    startedAt
    image
    location {
      __typename
      id
      name
    }
    space {
      id
      name
    }
  }
`);

const ListAssetsDocument = graphql(`
  query ListAssets {
    assets(status: Active) {
      id
      status
      type
      name
      image
      images
      notes
      attributes {
        name
        value
      }
      model {
        id
        brand
        name
        displayName
      }
      deployment {
        ...DeploymentFields
      }
      attachments {
        id
        ext
        url
        originalFilename
      }
    }
  }
`);

export function useAssets() {
  const [result, reexecuteQuery] = useQuery({
    query: ListAssetsDocument,
    requestPolicy: 'cache-first',
  });
  const assets = result.data?.assets ?? [];
  return [assets, () => reexecuteQuery({ requestPolicy: 'network-only' })] as [
    typeof assets,
    () => void
  ];
}

/**
 * Asset select
 */
export function AssetSelect({
  assets,
  value,
  onChange,
  multiple = false,
}: Props) {
  const { t } = useTranslation();
  const [cancelling, setCancelling] = useState(false);
  const [selected, setSelected] = useState<Asset[]>(value);

  useEffect(() => {
    // When value changes from outside, update selected so combolist selections stay in sync
    setSelected(value);
  }, [value]);

  const handleClosed = () => {
    if (cancelling) {
      setCancelling(false);
      setSelected(value);
      return;
    }
    onChange(selected);
  };

  const handleRemove = (asset: Asset) => {
    const newValue = value.filter(({ id }) => id !== asset.id);
    onChange(newValue);
    setSelected(newValue);
  };

  const error = '';

  return (
    <>
      <Label>{t('asset', { count: value.length })}</Label>
      <Popover className='relative'>
        <SelectButton
          selected={assets.filter((asset) =>
            value.find(({ id }) => id === asset.id)
          )}
          error={Boolean(error)}
          onRemove={handleRemove}
        />
        {error && <p className='ErrorMessage'>{error}</p>}
        <MenuTransition>
          <Popover.Panel
            className={classNames(
              'absolute top-0 z-50 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              multiple && 'pb-0'
            )}
          >
            {({ open }) => (
              <>
                <PopoverStateChangedEffect open={open} onClose={handleClosed} />
                <Combolist
                  items={assets}
                  searchKeys={['name']}
                  placeholder={t('asset_plural')}
                  value={selected}
                  onChange={setSelected}
                  multiple={multiple}
                  renderEmpty={() => <>No assets deployed at this location</>}
                  renderInner={(asset) => <OptionInner asset={asset} />}
                />
                {multiple && (
                  <div className='z-10 flex border-t bg-white p-1.5 text-xs'>
                    <Popover.Button
                      className='flex-1 p-2 text-left uppercase'
                      onClick={() => setCancelling(true)}
                    >
                      {t('cancel')}
                    </Popover.Button>
                    <Popover.Button className='flex-1 p-2 text-right uppercase'>
                      {t('ok')}
                    </Popover.Button>
                  </div>
                )}
              </>
            )}
          </Popover.Panel>
        </MenuTransition>
      </Popover>
    </>
  );
}

function SelectButton({
  selected,
  error,
  onRemove,
}: {
  selected: Asset[];
  error?: boolean;
  onRemove: (asset: Asset) => void;
}) {
  return (
    <Popover.Button
      as='div'
      className={classNames(styles.control, 'py-2.5', {
        'border-red-500': error,
      })}
    >
      <div className='flex items-center'>
        <div className='flex flex-1 flex-col gap-2 text-left'>
          {selected.map((item) => (
            <Inner key={item.id} asset={item} onRemove={onRemove} />
          ))}
        </div>
        <ChevronUpDownIcon
          className='h-5 w-5 text-gray-400'
          aria-hidden='true'
        />
      </div>
    </Popover.Button>
  );
}

function Inner({
  asset,
  onRemove,
}: {
  asset: Asset;
  onRemove: (asset: Asset) => void;
}) {
  invariant(asset, 'Asset must be defined');

  const deployment = getFragmentData(
    DeploymentFieldsFragment,
    asset.deployment
  );

  return (
    <div className='mb-2 flex items-center gap-2 px-2.5 pb-2 pt-1.5'>
      <div className='h-11 w-11'>
        <Avatar
          image={deployment?.image || asset.image}
          name={asset?.name}
          size='full'
          square
          className='rounded-md'
        />
      </div>
      <div className='ml-1 flex flex-col items-start overflow-hidden text-base'>
        <p className='truncate text-base font-medium text-grey-70 lg:text-[15px] lg:leading-5'>
          {asset.name}
        </p>
        <p className='truncate text-xs text-grey-40'>
          {deployment?.space.name}
        </p>
      </div>
      <div className='ml-auto'>
        <TrashCanButton onClick={() => onRemove(asset)} />
      </div>
    </div>
  );
}

function OptionInner({ asset }: { asset: Asset }) {
  const deployment = getFragmentData(
    DeploymentFieldsFragment,
    asset.deployment
  );
  return (
    <>
      <div className='h-6 w-6'>
        <Avatar
          image={deployment?.image || asset.image}
          name={asset?.name}
          size='full-small-text'
          square
          className='rounded-md !text-base'
        />
      </div>
      <div className='flex min-w-0 flex-col'>
        <div className='flex items-center gap-2'>
          <span className='truncate'>{asset.name}</span> -{' '}
          <span className='truncate'>{asset.type}</span>
        </div>
        <span className='truncate text-grey-40'>{deployment?.space.name}</span>
      </div>
    </>
  );
}

function TrashCanButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <FontAwesomeIcon
        icon={faTrashCan}
        className='-mb-1 h-6 w-6 stroke-1 text-red-600 opacity-50 hover:opacity-100'
      />
    </button>
  );
}
