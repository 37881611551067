import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button as BaseButton } from '~/components/ui/buttons/Button';
import { SideLayout } from '~/layouts/side/SideLayout';

type Props = {
  selected: string[];
  onClose: () => void;
  onCreateJob: () => void;
};

function Button({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <BaseButton
      as='div'
      className='block w-2/5'
      color='primary'
      onClick={onClick}
      rounded
    >
      {children}
    </BaseButton>
  );
}

export function ActivityBulkActionView({
  selected,
  onClose,
  onCreateJob,
}: Props) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames([
        'fixed inset-0 z-50 w-full overflow-hidden bg-white h-screen lg:relative',
        'lg:max-w-min',
        'lg:h-[calc(100vh-10rem-71px-1px)]',
      ])}
    >
      <SideLayout>
        <SideLayout.Head onClose={onClose}>
          {t('selected', { count: selected.length })}
        </SideLayout.Head>
        <SideLayout.Body className='mb-32 flex flex-col items-center justify-center'>
          <Button onClick={onCreateJob}>Create Job</Button>
        </SideLayout.Body>
      </SideLayout>
    </div>
  );
}
