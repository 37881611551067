import { SVGProps } from 'react';

const SvgWarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 18 18'
    width='18'
    height='18'
    {...props}
  >
    <path d='M0 0h18v18H0z' fill='none' />
    <path
      d='M1 15h16L9 2zm8.727-2.053H8.273v-1.368h1.454zm0-2.737H8.273V7.474h1.454z'
      fill='#fc9025'
    />
  </svg>
);

export default SvgWarningIcon;
