import React from 'react';
import { useTranslation } from 'react-i18next';
import { SkuInfo } from '~/components/smc/SkuInfo';
import { Card } from '~/components/ui/Card';
import { Hero } from '~/components/ui/Card/Hero';
import { StickyHeading } from '~/components/ui/StickyHeading';
import { TaskQuery } from '~/gql/graphql';

type Props = {
  task: TaskQuery['task'];
};

export function ReadOnlyTransferTask({ task }: Props) {
  const { t } = useTranslation();

  const moved = task.transfer?.moved ?? [];
  const localValue = moved.reduce((prev, curr) => {
    return { ...prev, [curr.id]: curr.qty };
  }, {} as Record<string, number | undefined>);

  let lastSpace = '';

  return (
    <>
      {task.transfer?.stockOnHand.map((item) => {
        const id = [item.space.id, item.sku.id].join(':');
        const entered = localValue[id];
        const target = item.target ?? null;
        const missing = target == null ? null : target - item.soh;

        const spaceHeading =
          lastSpace === item.space.id ? null : (
            <StickyHeading>{item.space.name}</StickyHeading>
          );
        lastSpace = item.space.id;

        const color = entered == null ? 'smoke' : 'green';

        if (moved.length && !entered) return null;

        return (
          <React.Fragment key={item.id}>
            {spaceHeading}
            <Card className='mb-4 flex p-4' color={color} shadow>
              <SkuInfo {...item.sku} />

              {entered != null ? (
                <Hero number={`+${entered}`} directive={t('transferred')} />
              ) : (
                <Hero number={missing} directive={t('missing')} />
              )}
            </Card>
          </React.Fragment>
        );
      })}
    </>
  );
}
