import { useTranslation } from 'react-i18next';
import { Status } from '~/gql/graphql';
import { SelectField } from './downshift/SelectField';

type Props = {
  name?: string;
  labelKey?: string;
  filter?: boolean;
};

export const StatusSelectField = ({
  name = 'status',
  labelKey,
  filter,
}: Props) => {
  const { t } = useTranslation();

  return (
    <SelectField
      floating={!filter}
      name={name}
      placeholder={t('status')}
      label={t(labelKey ?? 'status')}
      options={[
        { value: Status.Active, label: t('active') },
        { value: Status.Inactive, label: t('inactive') },
      ]}
      filter={filter}
      multiple
    />
  );
};
