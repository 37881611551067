import {
  faExternalLink,
  faFile,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssets } from '~/components/AssetSelect';
import { ReadValue } from '~/components/ui/ReadValue';

export function AssetAttachments({ assetId }: { assetId: string }) {
  const [assets] = useAssets();
  const asset = assets.find((asset) => asset.id === assetId);

  return (
    <div className='p-3'>
      <ReadValue label='attachment_plural'>
        {asset?.attachments.map(({ id, ext, originalFilename, url }) => (
          <a
            key={id}
            className='group mb-2 flex items-center hover:underline'
            href={url}
            target='_blank'
            rel='noreferrer'
          >
            <span className='mr-2 text-xl'>
              <FontAwesomeIcon icon={ext === '.pdf' ? faFilePdf : faFile} />
            </span>
            <span className='group-hover:text-blue'>{originalFilename}</span>
            <span className='ml-1 hidden text-blue group-hover:block'>
              <FontAwesomeIcon icon={faExternalLink} />
            </span>
          </a>
        ))}
      </ReadValue>
    </div>
  );
}
