/* eslint-disable @typescript-eslint/no-explicit-any */

import naturalCompare from 'string-natural-compare';

export function splice<T = any>(
  arr: T[],
  start: number,
  deleteCount: number,
  ...items: T[]
) {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
}

export function move<T = any>(arr: T[], oldIndex: number, newIndex: number) {
  return splice(splice(arr, oldIndex, 1), newIndex, 0, arr[oldIndex]);
}

export function remove(arr: any[], index: number) {
  return arr.slice(0, index).concat(arr.slice(index + 1));
}

export function sortNatural(arr: string[]) {
  return [...arr].sort((a, b) =>
    naturalCompare(a, b, { caseInsensitive: true })
  );
}

export function toggleValue(arr: any[], val: any, toggle?: boolean) {
  const i = arr.indexOf(val);
  if (toggle === undefined) {
    return i === -1 ? [...arr, val] : remove(arr, i);
  } else {
    return toggle && i === -1 ? [...arr, val] : i >= 0 ? remove(arr, i) : arr;
  }
}

export function unique<T = unknown>(arr: T[]) {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}
