import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UseQueryResponse } from 'urql';

export const useReload = (executeQuery: UseQueryResponse[1]) => {
  const { state } = useLocation() as { state: { reload?: boolean } | null };

  useEffect(() => {
    if (state?.reload) {
      executeQuery({ requestPolicy: 'cache-and-network' });
    }
  }, [state, executeQuery]);
};
