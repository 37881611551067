import classNames from 'classnames';
import { Card } from '~/components/ui/Card';
import { Image } from '~/components/ui/Image';
import { ItemsQuery } from '~/generated/graphql';
import { useSort } from '~/hooks/useSort';

type Props = {
  skus: ItemsQuery['items'][0]['skus'];
};

export const VariantList = ({ skus }: Props) => {
  const { sorted } = useSort(skus, { default: 'name' });
  return (
    <div>
      {sorted?.map((sku) => {
        return (
          <Card key={sku.id} className='flex border p-4'>
            <div className='mr-3'>
              <Image url={sku.image} />
            </div>
            <div className='flex-1 leading-tight'>
              <div className='text-sm font-semibold'>{sku._name}</div>
              <div className='text-xs'>{sku.code}</div>
              <div className='text-xs text-gray-600'>
                {sku.variant
                  .map(({ name, value }) => `${name}: ${value}`)
                  .join(', ')}
              </div>
            </div>
            <div className='mt-auto'>
              <div
                className={classNames(
                  'font-semibold',
                  sku.status === 'Inactive' ? 'text-delete' : 'text-success'
                )}
              >
                {sku.status}
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};
