import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useAssets } from '~/components/AssetSelect';
import { DeploymentFieldsFragment } from '~/components/AssetSelect/AssetSelect';
import { AuthGate } from '~/components/AuthGate';
import { Split } from '~/components/Split';
import { AssetList } from '~/components/assets/AssetList';
import { FacetedFilterOption } from '~/components/ui/FacetedFilterSelect';
import { Filter, filterKeyValueSeperator } from '~/components/ui/FilterGroup';
import { FilterGroupSearch } from '~/components/ui/FilterGroupSearch';
import { Result } from '~/components/ui/Result';
import { getFragmentData } from '~/gql';
import { ListAssetsQuery, Status } from '~/gql/graphql';
import { unique } from '~/helpers/array';
import parseFilters from '~/helpers/parseFilters';
import { useLocalStorage } from '~/hooks';
import { Filters } from '~/hooks/useFilter';
import { useFilterOptions } from '~/hooks/useFilterOptions';
import { useSearch } from '~/hooks/useSearch';
import { AssetsNav } from '~/layouts/nav/AssetsNav';

type TAssetContext = {
  assets: ListAssetsQuery['assets'];
  reload: () => void;
};

const noop = () => {};
const AssetContext = createContext<TAssetContext>({ assets: [], reload: noop });
export const useAssetContext = () => useContext(AssetContext);
export const useAsset = () => {
  const { assets } = useAssetContext();
  const { assetId } = useParams();
  invariant(assetId, 'useAsset requires an assetId');
  return assets.find((asset) => asset.id === assetId);
};

const SEARCH_OPTIONS = {
  keys: [
    'name',
    'displayAssetModelType',
    'displayAssetModel',
    'status',
    'deployedTo',
    'deployment.location.name',
    'deployment.space.name',
  ],
};

export const Assets = () => {
  const localStorage = useLocalStorage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [assets, reload] = useAssets();

  const applyFilters = (data: ListAssetsQuery['assets'], filters: Filters) => {
    const attributeFilters = filters['attributes']
      ? filters['attributes']?.reduce<{ key: string; value: string[] }[]>(
          (acc, filter) => {
            const [key, ...value] = filter.split(filterKeyValueSeperator);
            const index = acc.findIndex((f) => f.key === key);
            if (index === -1) {
              acc.push({ key, value: value });
            } else {
              acc[index].value.push(...value);
            }
            return acc;
          },
          []
        )
      : [];

    return data.filter((asset) => {
      const deployment = getFragmentData(
        DeploymentFieldsFragment,
        asset.deployment
      );
      const attributesMatch = attributeFilters.every((filter) => {
        const attribute = asset.attributes?.find(
          (attribute) => attribute.name === filter.key
        );
        return attribute
          ? filter.value.every((value) => attribute.value.includes(value))
          : false;
      });
      return (
        (!filters['status'] || filters['status'].includes(asset.status)) &&
        (!filters['type'] || filters['type'].includes(asset.type || '')) &&
        (!filters['deploymentLocation'] ||
          filters['deploymentLocation'].includes(
            deployment?.location.name || ''
          )) &&
        (!filters['deploymentSpace'] ||
          filters['deploymentSpace'].includes(deployment?.space.name || '')) &&
        attributesMatch
      );
    });
  };

  const [filters, setFilters] = useState<Filters>({});
  const { results, search } = useSearch(assets, SEARCH_OPTIONS);
  const filtered = applyFilters(results || [], filters);

  useEffect(() => {
    const navigateTo = localStorage.get('nav.assets') ?? '/assets';
    if (navigateTo !== '/assets') {
      navigate(navigateTo);
    }
  }, []);

  return (
    <AuthGate action='read' subject='feat.assets'>
      <AssetContext.Provider value={{ assets, reload }}>
        <Split
          nav={<AssetsNav />}
          filters={
            <AssetFilters
              data={results ?? []}
              onSubmit={({ query, filters }) => {
                search(query);
                setFilters(filters);
              }}
            />
          }
          main={
            <>
              <Result count={filtered?.length} />
              <AssetList data={filtered ?? []} />
            </>
          }
        />
      </AssetContext.Provider>
    </AuthGate>
  );
};

type AssetFiltersProps = {
  data: ListAssetsQuery['assets'];
  onSubmit: (values: {
    query: string;
    filters: ReturnType<typeof parseFilters>;
  }) => void;
};

const AssetFilters = ({ data, onSubmit }: AssetFiltersProps) => {
  const { t } = useTranslation();
  const [options] = useFilterOptions(data, [
    { name: 'status', path: 'status' },
    { name: 'type', path: 'type' },
  ]);
  const [searchValue, setSearchValue] = useState('');
  const [filtersValue, setFiltersValue] = useState(
    new URLSearchParams([['status', Status.Active]])
  );

  const attributeOptions =
    data?.reduce<FacetedFilterOption[]>((acc, asset) => {
      asset.attributes?.forEach((attribute) => {
        if (!acc.some((opt) => opt.key === attribute.name)) {
          const nameParts = attribute.name.split('\\');
          const group = nameParts.length > 1 ? nameParts[0] : undefined;
          const label =
            nameParts.length > 1
              ? nameParts.splice(1).join('\\')
              : attribute.name;
          acc.push({
            $group: group?.trimEnd(),
            key: attribute.name,
            label: label.trimStart(),
            value: attribute.value,
          });
        } else {
          const index = acc.findIndex(
            (opt: FacetedFilterOption) => opt.key === attribute.name
          );
          acc[index].value = unique([...acc[index].value, ...attribute.value]);
        }
      });
      return acc;
    }, []) ?? [];

  const deploymentOptions = useMemo(() => {
    const options = {
      locationNames: [] as string[],
      spaceNames: [] as string[],
    };
    data.forEach((asset) => {
      const deployment = getFragmentData(
        DeploymentFieldsFragment,
        asset.deployment
      );
      if (deployment) {
        options.locationNames.push(deployment.location.name);
        options.spaceNames.push(deployment.space.name);
      }
    });
    return options;
  }, [data]);

  const filters: Filter[] = [
    {
      name: 'status',
      label: t('status'),
      options: Object.values(Status).map((value) => ({
        value,
        label: t(`statuses.${value}`),
      })),
      searchable: false,
      type: 'select',
    },
    {
      name: 'attributes',
      label: t('attribute_plural'),
      options: attributeOptions,
      type: 'facetedSelect',
    },
    options.type.length > 1 && {
      name: 'type',
      label: t('assetType'),
      options: options.type,
      type: 'select',
    },
    {
      name: 'deploymentLocation',
      label: t('location'),
      options: unique(deploymentOptions.locationNames).map((value) => ({
        value,
        label: value,
      })),
      searchable: true,
      type: 'select',
    },
    {
      name: 'deploymentSpace',
      label: t('space'),
      options: unique(deploymentOptions.spaceNames).map((value) => ({
        value,
        label: value,
      })),
      searchable: true,
      type: 'select',
    },
  ];

  return (
    <FilterGroupSearch
      filters={filters}
      value={{ searchValue, filtersValue }}
      onChange={({ searchValue, filtersValue }) => {
        setSearchValue(searchValue);
        setFiltersValue(filtersValue);
        onSubmit({ query: searchValue, filters: parseFilters(filtersValue) });
      }}
      placement={'portal'}
    />
  );
};
