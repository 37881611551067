import { useTranslation } from 'react-i18next';
import { Avatar } from '../ui/Avatar';
import styles from './ItemDetail.module.css';

type SkuDetailProps = {
  image?: string | null;
  code: string;
  name: string;
  itemType: string;
};

/** @deprecated Use SkuInfo */
export const SkuDetail = ({ image, code, name, itemType }: SkuDetailProps) => {
  const { t } = useTranslation();
  return (
    <>
      <figure className={styles.picture}>
        <Avatar
          image={image}
          name={name}
          size='full'
          square
          className='rounded-md'
        />
      </figure>
      <div className={styles.desc}>
        <div className={styles.name}>{name}</div>
        <div className={styles.sku}>{code}</div>
        <div className={styles.type}>{t('itemType.' + itemType)}</div>
      </div>
    </>
  );
};

type ItemDetailProps = {
  item: {
    image?: string | null;
    sku_code: string;
    sku_name: string;
    item_type: string;
    asset_name?: string;
    space_name?: string;
  };
};

/** @deprecated Use SkuInfo */
export const ItemDetail = ({ item }: ItemDetailProps) => {
  const sku = {
    image: item.image,
    code: item.sku_code,
    name: item.sku_name,
    itemType: item.item_type,
  };

  return (
    <div className={styles.container}>
      <SkuDetail {...sku} />
      {item.asset_name && (
        <div className={styles.space}>
          <div className={styles.asset}>{item.asset_name}</div>
          <div>{item.space_name}</div>
        </div>
      )}
    </div>
  );
};
