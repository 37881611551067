import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';
import { JobCalendarEventView } from '~/components/job/JobCalendarEventView';
import { Loading } from '~/components/ui/Loading';
import { graphql } from '~/gql';

export const JobCalendarEventsDocument = graphql(`
  query JobCalendarEvents($ids: [ID!]!) {
    jobCalendarEvents(ids: $ids) {
      id
      ...JobCalendarEventFields
    }
  }
`);

export function Component() {
  // const data = useRouteLoaderData('jobs_$view');
  const navigate = useNavigate();

  const { eventId } = useParams();
  invariant(eventId);

  const [result] = useQuery({
    query: JobCalendarEventsDocument,
    requestPolicy: 'cache-and-network',
    variables: { ids: [eventId] },
  });

  if (result.fetching) {
    return <Loading spinner />;
  }

  const event = result.data?.jobCalendarEvents.find(({ id }) => id === eventId);

  return (
    <JobCalendarEventView
      event={event}
      jobPathPrefix='/jobs/'
      onClose={() => navigate('..')}
    />
  );
}
