import { ArrowRightIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { Button } from '../form/SubmitButton';
import { Input } from '../form/TextField';

export function AccountForm() {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className='mb-7 text-lg font-bold'>Create New Account</h1>

      <Form method='post' action='/accounts/new'>
        <Input
          name='name'
          label={t('organisationName')}
          required
          minLength={3}
          autoFocus
        />

        <Button type='submit' loading={false}>
          <span className='inline-flex items-center gap-3'>
            Create <ArrowRightIcon className='h-5 w-5' />
          </span>
        </Button>
      </Form>
    </div>
  );
}
