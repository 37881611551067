import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { AuthGate } from '~/components/AuthGate';
import { CreateButton } from '~/components/CreateButton';
import { FloatingActionButton } from '~/components/FloatingActionButton';
import { ListDetailView } from '~/components/ListDetailView';
import { AssetModelForm } from '~/components/asset-models/AssetModelForm';
import { FilterForm, FilterProps } from '~/components/filter/FilterForm';
import { StatusSelectField } from '~/components/form/StatusSelectField';
import { MatchList } from '~/components/ui/MatchList';
import { withModal } from '~/components/withModal';
import {
  AssetModelInput,
  ListAssetModelsQuery,
  useCreateAssetModelMutation,
  useListAssetModelsQuery,
  useUpdateAssetModelMutation,
} from '~/generated/graphql';
import { useFilter2 } from '~/hooks/useFilter';
import { useSearch } from '~/hooks/useSearch';
import { useSort } from '~/hooks/useSort';
import { AssetsNav } from '~/layouts/nav/AssetsNav';
import { AssetModelView } from '~/routes/asset-models/[id]';

type AssetModel = ListAssetModelsQuery['assetModels'][0];

const AssetModelFormModal = withModal(AssetModelForm);
const AssetModelViewModal = withModal(AssetModelView);

const SEARCH_OPTIONS = {
  keys: ['displayName', 'type', 'status'],
};

const AssetModelFilters = ({ onFiltered, onSearch }: FilterProps) => {
  return (
    <FilterForm
      initialValues={{
        model: { id: [] },
        type: [],
        status: ['Active'],
      }}
      onFiltered={onFiltered}
      onSearch={onSearch}
    >
      <StatusSelectField />
    </FilterForm>
  );
};

type Props = {};

export const AssetModels = (props: Props) => {
  const { t } = useTranslation();
  const [, create] = useCreateAssetModelMutation();
  const [, update] = useUpdateAssetModelMutation();
  const [{ data }, reexecuteQuery] = useListAssetModelsQuery();
  const models = data?.assetModels;
  const { filtered, filter } = useFilter2(models, {
    initialFilters: { status: ['Active'] },
  });
  const { results, search } = useSearch(filtered, SEARCH_OPTIONS);
  const { sorted } = useSort(results, { default: 'displayName' });

  const getListItemProps = (model: AssetModel) => ({
    id: model.id,
    linkTo: `/asset-models/${model.id}`,
    titleText: model.displayName,
    secondaryText: startCase(model.type),
    tertiaryText: model.notes,
    hero: { number: model.activeAssets ?? '', directive: t('activeAssets') },
    status: model.status,
    image: model.image,
  });

  const handleSubmit =
    (fn: typeof create | typeof update) => async (data: unknown) => {
      const id: string = (data as any).id || undefined;
      const { brand, name, type, status, notes, attributes, images } =
        data as AssetModelInput;

      await fn({
        id,
        input: { brand, name, type, status, notes, attributes, images },
      });
      reexecuteQuery({ requestPolicy: 'network-only' });
    };

  return (
    <AuthGate action='manage' subject='feat.items'>
      <ListDetailView
        nav={<AssetsNav />}
        filters={<AssetModelFilters onFiltered={filter} onSearch={search} />}
        actions={<CreateButton>{t('assetModel')}</CreateButton>}
        main={
          <MatchList
            items={sorted?.map(getListItemProps) ?? []}
            matchPath='/asset-models/:id/*'
          />
        }
      >
        <Route path='*' element={<FloatingActionButton />} />
        <Route
          path='new'
          element={
            <AssetModelFormModal
              action={handleSubmit(create)}
              title='New Asset Model'
              onSuccess={() => {}}
            />
          }
        />
        <Route
          path=':modelId/edit'
          element={
            <AssetModelFormModal
              action={handleSubmit(update)}
              title='Edit Asset Model'
              onSuccess={() => {}}
            />
          }
        />
        <Route path=':modelId' element={<AssetModelViewModal />} />
      </ListDetailView>
    </AuthGate>
  );
};
