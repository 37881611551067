import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { useContacts } from '~/routes/resources/contacts';
import { getFragmentData } from '../../../../gql';
import {
  GlobalAttributes_QueryFragmentFragmentDoc,
  SiteAttributesData_QueryDocument,
} from '../../../../gql/graphql';
import { ErrorMessage } from '../../../ui/Error';
import { Loading } from '../../../ui/Loading';
import { AttributeAuditTask } from './AttributeAuditTask';

export function AttributeAuditRoute() {
  const { jobId, taskId } = useParams();
  const [result] = useQuery({
    query: SiteAttributesData_QueryDocument,
    requestPolicy: 'cache-first',
  });
  const contacts = useContacts({ requestPolicy: 'cache-first' });
  const navigate = useNavigate();

  const allAttributes = getFragmentData(
    GlobalAttributes_QueryFragmentFragmentDoc,
    result.data
  );

  if (result.fetching) {
    return <Loading spinner />;
  }

  if (!allAttributes) {
    return <ErrorMessage message='No attributes found' />;
  }

  return (
    <>
      <AttributeAuditTask
        allAttributes={allAttributes.attributes.filter(
          // @ts-expect-error this filter shouldn't be necessart but it is so we'll ignore the error for now
          ({ name }) => !name.startsWith('integration_')
        )}
        contacts={contacts}
        onClose={() => navigate(`/jobs/${jobId}`)}
      />
    </>
  );
}
