import { RootBoundary } from '~/RootBoundary';
import { LogoSection } from './LogoSection';

export function AuthErrorBoundary() {
  return (
    <div className='flex w-screen items-center justify-center h-screen'>
      <div className='w-full lg:w-1/2'>
        <RootBoundary hasLogoSection showSupportBtn={false} />
      </div>
      <LogoSection />
    </div>
  );
}
