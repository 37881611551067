import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { Virtualizer, useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import { format } from 'date-fns';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  ShouldRevalidateFunction,
  useFetcher,
  useLoaderData,
  useLocation,
  useNavigate,
  useOutlet,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { client } from '~/client';
import { Split } from '~/components/Split';
import {
  TagCombobox,
  TagEntityType,
  TagEntityTypes,
} from '~/components/TagCombobox';
import { formatTagsToFaceted, useTags } from '~/components/TagCombobox/tags';
import { ActivityBulkActionView } from '~/components/activity/ActivityBulkActionView';
import {
  ActivityListItem,
  ActivityListItem_Activity,
} from '~/components/activity/ActivityListItem';
import { MarkAllAsReadButton } from '~/components/activity/MarkAllAsReadButton';
import { FilterGroupSearch } from '~/components/ui/FilterGroupSearch';
import { Loading } from '~/components/ui/Loading';
import { Result } from '~/components/ui/Result';
import { getFragmentData, graphql } from '~/gql';
import { Activity, Tag } from '~/gql/graphql';
import { formatDate } from '~/helpers/formatDate';
import parseFilters from '~/helpers/parseFilters';
import { useLocalStorage } from '~/hooks';
import { JobsNav } from '~/layouts/nav/JobsNav';
import { parseAttributeFilters } from './calendar._index';
import { useContacts } from './resources/contacts';
import { /* extractAttributes, */ useSites } from './resources/sites';

const ActivityListItem_AddTagsToActivity = graphql(/* GraphQL */ `
  mutation AddTagsToActivity($id: ID!, $tagIds: [ID!]!) {
    addTagsToActivity(id: $id, tagIds: $tagIds) {
      id
      tags {
        id
        entityType
        category
        name
        description
        colour
      }
    }
  }
`);

const ActivityListItem_RemoveTagsFromActivity = graphql(/* GraphQL */ `
  mutation RemoveTagsFromActivity($id: ID!, $tagIds: [ID!]!) {
    removeTagsFromActivity(id: $id, tagIds: $tagIds) {
      id
      tags {
        id
        entityType
        category
        name
        description
        colour
      }
    }
  }
`);

const PAGE_LIMIT = 50;
const DATA_OVERSCAN = 5;
const SEARCH_OPTIONS = {
  keys: [
    'node.job.name',
    'node.task.name',
    'node.job.site.name',
    'node.createdBy.name',
  ],
};

const Activity_Query = graphql(/* GraphQL */ `
  query Activity(
    $first: Int
    $after: String
    $kind: String
    $unread: String
    $flagged: String
    $site: [ID!]
    $author: [ID!]
    $dateRange: String
    $tag: [String!]
    $commentTagIds: [ID!]
    $jobTagIds: [ID!]
    $taskTagIds: [ID!]
    $query: String
  ) {
    tags(entityTypes: ["Activity"]) {
      nodes {
        id
        entityType
        category
        name
        description
        colour
      }
    }
    allActivity(
      first: $first
      after: $after
      kind: $kind
      unread: $unread
      flagged: $flagged
      site: $site
      author: $author
      dateRange: $dateRange
      tag: $tag
      commentTagIds: $commentTagIds
      jobTagIds: $jobTagIds
      taskTagIds: $taskTagIds
      query: $query
    ) {
      totalCount
      totalUnreadCount
      edges {
        node {
          id
          createdAt
          ...ActivityListItem_ActivityFragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`);

const Activities_TotalCount = graphql(`
  query Activities_TotalCount(
    $first: Int
    $after: String
    $kind: String
    $unread: String
    $flagged: String
    $site: [ID!]
    $author: [ID!]
    $dateRange: String
    $tag: [String!]
    $query: String
  ) {
    allActivity(
      first: $first
      after: $after
      kind: $kind
      unread: $unread
      flagged: $flagged
      site: $site
      author: $author
      dateRange: $dateRange
      tag: $tag
      query: $query
    ) {
      totalCount
    }
  }
`);

type ActivityLoaderData = Awaited<ReturnType<typeof loader>>;
type Edges = NonNullable<NonNullable<ActivityLoaderData>['activity']>['edges'];

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);

  const variables = {
    first: PAGE_LIMIT,
    after: url.searchParams.get('after'),
    kind: url.searchParams.get('filter[kind]'),
    unread: url.searchParams.get('filter[unread]'),
    flagged: url.searchParams.get('filter[flagged]'),
    site: url.searchParams.getAll('filter[site]'),
    author: url.searchParams.getAll('filter[author]'),
    dateRange: url.searchParams.get('filter[dateRange]'),
    tag: url.searchParams.getAll('filter[tag]'),
    commentTagIds: url.searchParams.getAll('filter[commentTagIds]'),
    jobTagIds: url.searchParams.getAll('filter[jobTagIds]'),
    taskTagIds: url.searchParams.getAll('filter[taskTagIds]'),
    query: url.searchParams.get('query'),
  };

  const { error, data } = await client
    .query(Activity_Query, variables, {
      requestPolicy: 'cache-and-network',
    })
    .toPromise();

  if (error) {
    throw new Response(error.message, { status: 500 });
  }

  return {
    activity: data?.allActivity,
    filters: new URLSearchParams(
      [...url.searchParams.entries()].filter(([k]) => k.startsWith('filter'))
    ).toString(),
    refresh: url.searchParams.get('page') === '0',
    tags: data?.tags,
  };
}

type Items = Array<Edges[number] | string>;

function removeUnnecessaryData(activities: Edges) {
  const cleanList = activities.filter((activity) => {
    const { kind, task } = activity.node as Activity;

    const isValidActivity =
      kind === 'status' && task && !(task.notes || task.attachments?.length);

    return !isValidActivity;
  });

  return addDateSeparators(cleanList);
}

function addDateSeparators(activity: Edges) {
  return activity.reduce(
    (prev, curr) => {
      const { node } = curr;
      const date = format(new Date(node.createdAt), 'yyyy-MM-dd');

      if (date !== prev.lastDate) {
        prev.lastDate = date;
        prev.all.push(date);
      }
      prev.all.push(curr);
      return prev;
    },
    { lastDate: '', all: [] as Items }
  ).all;
}

type ContextType = {
  activities: Items;
};
const ActivityListContext = createContext<ContextType>({} as ContextType);
export function useActivityListContext() {
  const context = useContext(ActivityListContext);

  if (!context) {
    throw new Error('Must be used within ActivityListContext.Provider');
  }

  return context;
}

export default function ActivityRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mounted, setMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const data = useLoaderData() as ActivityLoaderData;
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState(new URLSearchParams(data.filters));
  const [selected, setSelected] = useState<string[]>([]);
  const localStorage = useLocalStorage();

  useEffect(() => {
    const actvityFilters = localStorage.get('job.activity') || '';

    setIsLoading(true);
    setFilters(new URLSearchParams(actvityFilters));
    fetcher.load(`/activity?first=${PAGE_LIMIT}&${actvityFilters}&page=0`);
  }, []);

  const [activity, setActivity] = useState<Items>(
    removeUnnecessaryData(data?.activity?.edges ?? [])
  );

  const fetcher = useFetcher<ActivityLoaderData>();

  // Virtual scroll element
  const parentRef = useRef<HTMLDivElement>(null);

  // Page info
  const [hasNextPage, setHasNextPage] = useState(
    data.activity?.pageInfo.hasNextPage
  );
  const [totals, setTotals] = useState({
    count: data.activity?.totalCount ?? 0,
    unread: data.activity?.totalUnreadCount ?? 0,
  });
  const [newActivitiesCount, setNewActivitiesCount] = useState(0);

  const latest = data.activity?.edges?.[0]?.node.id;

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? activity.length + 1 : activity.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => 75, []),
    overscan: 5,
    paddingStart: 56, // 3.5rem
  });

  const sites = useSites();
  const contacts = useContacts();
  // const attributeOptions = extractAttributes(sites);

  // Tagging dependencies
  const [allTags] = useTags(TagEntityTypes);
  const [activityTags, reload] = useTags(TagEntityType.Activity);
  const [isTagBoxOpen, setIsTagBoxOpen] = useState(false);
  const [selectedTagsIds, setSelectedTagsIds] = useState<string[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<Activity>();

  const updateTags = useCallback(async () => {
    await removeTagsFromActivity();
    const newSetOfTags = await addTagsToActivity();

    const tags =
      newSetOfTags && newSetOfTags.length > 0
        ? newSetOfTags
        : activityTags.filter((t) => selectedTagsIds.includes(t.id));

    setActivity(
      activity.map((a) => {
        if (typeof a !== 'string' && a.node.id === selectedActivity?.id) {
          const { node } = a;

          return {
            ...a,
            node: { ...node, tags },
          };
        }

        return a;
      })
    );

    setIsTagBoxOpen(false);
  }, [selectedTagsIds]);

  async function addTagsToActivity() {
    const toAddTags = selectedTagsIds.filter(
      (id) => !selectedActivity?.tags?.map((a) => a.id).includes(id)
    );

    if (toAddTags.length) {
      const result = await client.mutation(ActivityListItem_AddTagsToActivity, {
        tagIds: toAddTags,
        id: selectedActivity?.id?.toString() ?? '',
      });
      const { data, error } = result;

      if (error) {
        toast.error(error.message.replace('[GraphQL] ', ''));
      } else {
        return data?.addTagsToActivity.tags;
      }
    } else {
      return null;
    }
  }

  async function removeTagsFromActivity() {
    const toRemoveTags = selectedActivity?.tags
      ?.map((a) => a.id)
      .filter((id) => !selectedTagsIds.includes(id));

    if (toRemoveTags?.length) {
      const result = await client.mutation(
        ActivityListItem_RemoveTagsFromActivity,
        {
          tagIds: toRemoveTags,
          id: selectedActivity?.id?.toString() ?? '',
        }
      );
      const { data, error } = result;

      if (error) {
        toast.error(error.message.replace('[GraphQL] ', ''));
      } else {
        return data?.removeTagsFromActivity.tags;
      }
    } else {
      return null;
    }
  }

  function handleTagActivity(activityItem: Activity) {
    setSelectedActivity(activityItem);
    setSelectedTagsIds(activityItem.tags?.map((t) => t.id) || []);
    setIsTagBoxOpen(true);
  }

  function handleTagClick(tag: Tag) {
    const newFilters = new URLSearchParams([...filters.entries()]);
    const type =
      tag.entityType === TagEntityType.Job
        ? 'jobTagIds'
        : tag.entityType === TagEntityType.Comment
        ? 'commentTagIds'
        : 'tags';

    const preSelectedTags = newFilters.getAll(`filter[${type}]`);

    if (!preSelectedTags.includes(tag.id)) {
      newFilters.append(
        'filter[allTags]',
        `${tag.entityType.toLocaleLowerCase()},${tag.name}`
      );

      handleSearchFilterChange({
        searchValue: query,
        filtersValue: newFilters,
      });
    }
  }

  useEffect(() => {
    setIsTagBoxOpen(false);
  }, [rowVirtualizer.isScrolling]);

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      activity.length &&
      lastItem.index >= activity.length - 1 &&
      hasNextPage &&
      fetcher.state === 'idle'
    ) {
      // Fail-safe to force the component to block duplicate requests
      setHasNextPage(false);

      const cursor = (activity[activity.length - 1] as Edges[number]).cursor;
      const qs = new URLSearchParams([
        ['first', String(PAGE_LIMIT)],
        ['after', cursor],
        ...filters.entries(),
      ]);
      fetcher.load(`/activity?${qs}`);
    }
  }, [hasNextPage, fetcher, rowVirtualizer.getVirtualItems(), activity.length]);

  useEffect(() => {
    if (fetcher.data?.activity) {
      if (fetcher.data?.refresh) {
        // Start again from the top
        setActivity(removeUnnecessaryData(fetcher.data?.activity?.edges ?? []));
        setFilters(new URLSearchParams(fetcher.data.filters));
        setIsLoading(false);
      } else {
        // Append the next page
        setActivity((prev) => [
          ...prev,
          ...removeUnnecessaryData(fetcher.data?.activity?.edges ?? []),
        ]);
      }

      setHasNextPage(fetcher.data.activity.pageInfo.hasNextPage);
      setTotals({
        count: fetcher.data.activity.totalCount,
        unread: fetcher.data.activity.totalUnreadCount,
      });
    }
  }, [fetcher.data]);

  useEffect(() => {
    setMounted(true);
  }, []);

  function appendTagsValue(filtersValue: URLSearchParams) {
    const parsedFilters = parseFilters(filtersValue);
    const selectedTags = parseAttributeFilters(
      parsedFilters['filter[allTags]']
    );

    const filterNames = ['commentTagIds', 'jobTagIds', 'taskTagIds', 'tag'];

    filterNames.forEach((filterName) => {
      const tagEntityType =
        filterName === 'jobTagIds'
          ? TagEntityType.Job
          : filterName === 'commentTagIds'
          ? TagEntityType.Comment
          : filterName === 'taskTagIds'
          ? TagEntityType.Task
          : TagEntityType.Activity;

      const idx = selectedTags.findIndex(
        (o) => o.name === tagEntityType.toLowerCase()
      );

      if (idx === -1) {
        filtersValue.delete(`filter[${filterName}]`);
      } else {
        const values = selectedTags[idx].value;

        values.forEach((tagName) => {
          const tag = allTags[allTags.findIndex((tag) => tag.name === tagName)];
          filtersValue.append(`filter[${filterName}]`, tag.id);
        });
      }
    });

    return filtersValue;
  }

  function appendDateRangeValue(filtersValue: URLSearchParams) {
    const dateAfter = filtersValue.get('filter[dateRange]After');
    const dateBefore = filtersValue.get('filter[dateRange]Before');
    filtersValue.delete('filter[dateRange]');

    if (!dateAfter || !dateBefore) return filtersValue;

    filtersValue.append('filter[dateRange]', `${dateAfter}_${dateBefore}`);
    return filtersValue;
  }

  const handleSearchFilterChange = ({
    searchValue,
    filtersValue,
  }: {
    searchValue: string;
    filtersValue: URLSearchParams;
  }) => {
    setQuery(searchValue);

    const filtersWithDateRange = appendDateRangeValue(filtersValue);
    const filtersWithTags = appendTagsValue(filtersWithDateRange);

    if (mounted) {
      fetcher.load(
        `/activity?first=${PAGE_LIMIT}&${filtersWithTags}&query=${searchValue}&page=0`
      );
      localStorage.set('job.activity', filtersValue.toString());
    }

    // if (searchValue !== '') {
    //   // search(searchValue);
    // }
    // if (filtersValue.toString() !== filters.filtersValue.toString()) {
    //   // setFilters(filtersValue);
    //
    //   filterFetcher.load(`/activity?first=${PAGE_LIMIT}&${filters}`);
    // }
  };

  function scrollToTop(virtualizer: Virtualizer<HTMLDivElement, Element>) {
    fetcher.load(`/activity?first=${PAGE_LIMIT}&page=0`);

    virtualizer.scrollToIndex(0, {
      align: 'start',
      behavior: 'smooth',
    });

    setTimeout(() => {
      setNewActivitiesCount(0);
    }, 2000);
  }

  const outlet = useOutlet();

  useEffect(() => {
    if (!outlet) setSelected([]);
  }, [outlet]);

  // Polling the total number of new activities
  useEffect(() => {
    const variables = {
      first: PAGE_LIMIT,
      after: filters.get('after'),
      kind: filters.get('filter[kind]'),
      unread: filters.get('filter[unread]'),
      flagged: filters.get('filter[flagged]'),
      site: filters.getAll('filter[site]'),
      author: filters.getAll('filter[author]'),
      dateRange: filters.get('filter[dateRange]'),
      tag: filters.getAll('filter[tag]'),
      query: filters.get('query'),
    };

    const interval = setInterval(async () => {
      const { data } = await client
        .query(Activities_TotalCount, variables, {
          requestPolicy: 'cache-and-network',
        })
        .toPromise();

      const newTotal = data?.allActivity.totalCount;
      if (!newTotal) return;

      if (newTotal > totals.count) {
        const newCount = newTotal - totals.count;

        setNewActivitiesCount(newCount);
        setTotals({
          count: newTotal,
          unread: totals.unread + newCount,
        });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [fetcher.data]);

  const { pathname, search } = useLocation();
  const isCreateFromActivity =
    pathname === '/activity/jobs/new' && search.search('activity') >= 0;
  const showBulkAction =
    (selected.length > 1 || (selected.length > 0 && !outlet)) &&
    !isCreateFromActivity;

  const handleSelect = (id: string, isSelected: boolean, isSingle: boolean) => {
    if (isSingle) return setSelected([id]);

    setSelected((prev) =>
      isSelected ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleBulkCreateJobs = () => {
    if (!selected.length) return;
    navigate(`jobs/new?activity=${selected.join(',')}`);
  };

  return (
    <ActivityListContext.Provider
      value={{
        activities: activity,
      }}
    >
      <Split
        outletHidden={showBulkAction}
        virtual
        noScroll
        height='lg:h-[calc(100vh-8.4rem-1px)] pb-[3vh]'
        nav={<JobsNav unreadActivity={totals.unread} />}
        filters={
          <div className='flex items-center'>
            <FilterGroupSearch
              filters={[
                {
                  name: 'filter[kind]',
                  type: 'select',
                  label: t('kind'),
                  options: [
                    { value: 'message', label: t('message_plural') },
                    { value: 'status', label: 'Status Changes' },
                    { value: 'taskNotes', label: 'Task Notes & Images' },
                  ],
                  multiple: false,
                  searchable: false,
                },
                {
                  name: 'filter[unread]',
                  type: 'select',
                  label: 'Unread',
                  options: [{ value: '1', label: 'Unread' }],
                  multiple: false,
                  searchable: false,
                },
                {
                  name: 'filter[allTags]',
                  label: t('tag_plural'),
                  // ! This is not what faceted means
                  options: formatTagsToFaceted(allTags),
                  type: 'facetedSelect',
                },
                {
                  name: 'filter[flagged]',
                  type: 'select',
                  label: 'Flagged',
                  options: [{ value: '1', label: 'Flagged' }],
                  multiple: false,
                  searchable: false,
                },
                {
                  name: 'filter[site]',
                  type: 'select',
                  label: t('site'),
                  options: sites.map((site) => ({
                    value: site.id,
                    label: site.name,
                  })),
                },
                {
                  name: 'filter[dateRange]',
                  type: 'date',
                  label: 'Date Range',
                },
                {
                  name: 'filter[author]',
                  type: 'select',
                  label: 'Author',
                  options: contacts.map((contact) => ({
                    value: contact.id,
                    label: contact.name,
                  })),
                },
                // {
                //   name: 'filter[tag]',
                //   type: 'select',
                //   label: t('tag'),
                //   options: [],
                // },
                // {
                //   name: 'attributes',
                //   label: t('attribute_plural'),
                //   options: attributeOptions,
                //   type: 'facetedSelect',
                // },
              ]}
              value={{ searchValue: query, filtersValue: filters }}
              onChange={handleSearchFilterChange}
              hideKeywordSearch
            />
            <div
              className={classNames(
                'ml-auto shrink-0 grow-0',
                outlet && 'hidden'
              )}
            ></div>
          </div>
        }
        main={
          isLoading ? (
            <div className='flex h-48 items-center justify-center'>
              <Loading spinner />
            </div>
          ) : (
            <>
              <div
                ref={parentRef}
                style={{
                  height: '100%',
                  width: '100%',
                  overflow: 'auto',
                  backgroundColor: '#F7F7F8',
                }}
              >
                <div
                  style={{
                    height: rowVirtualizer.getTotalSize(),
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <div className='flex h-14 items-center overflow-hidden px-4 py-2'>
                    <Result
                      count={totals.count}
                      className='!pb-0 text-xs leading-7 text-secondary lg:text-base'
                    />{' '}
                    <div className='flex-1 whitespace-nowrap pl-1 text-xs leading-7 text-secondary lg:text-base'>
                      ({totals.unread} unread)
                    </div>
                    <div className='flex-1 text-right'>
                      {totals.unread > 0 && (
                        <MarkAllAsReadButton
                          latest={latest}
                          unread={totals.unread}
                        />
                      )}
                    </div>
                  </div>

                  <Transition
                    className='sticky top-8 z-10 w-full'
                    show={newActivitiesCount > 0}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 scale-95'
                    enterTo='opacity-100 scale-100'
                    leave='ease-in duration-50'
                    leaveFrom='opacity-100 scale-100'
                    leaveTo='opacity-50 scale-95'
                  >
                    <button
                      className='pointer-events-auto mx-auto flex items-center gap-2 rounded-full border border-grey-20 bg-white px-3 py-1 text-sm shadow-md hover:bg-grey-5 lg:text-base'
                      onClick={() => scrollToTop(rowVirtualizer)}
                    >
                      <FontAwesomeIcon icon={faRotateRight} />
                      <span>{newActivitiesCount} new updates</span>
                    </button>
                  </Transition>

                  {/* TagCombobox overlay */}
                  <div
                    className={classNames(
                      'sticky top-0 z-50 w-full',
                      isTagBoxOpen && 'px-10 pt-5 h-screen'
                    )}
                    onClick={() => setIsTagBoxOpen(false)}
                  >
                    <div
                      className='ml-auto w-fit'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <TagCombobox
                        entityType={TagEntityType.Activity}
                        options={activityTags}
                        value={activityTags.filter((t) =>
                          selectedTagsIds.includes(t.id)
                        )}
                        onChange={(tags) => {
                          reload();
                          setSelectedTagsIds(tags.map(({ id }) => id));
                        }}
                        dialogMode={true}
                        isOpen={isTagBoxOpen}
                        onClose={() => {
                          setIsTagBoxOpen(false);
                          setSelectedTagsIds([]);
                        }}
                        onSubmit={updateTags}
                        containerStyles='w-80'
                      />
                    </div>
                  </div>
                  {/* End of TagCombobox overlay */}

                  {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                    const isLoaderRow = virtualRow.index > activity.length - 1;
                    const item = activity[virtualRow.index];
                    const isDateSeparatorRow = typeof item === 'string';
                    const isSelected =
                      (!isDateSeparatorRow &&
                        selected.includes(item?.node.id)) ||
                      false;
                    const node =
                      !isDateSeparatorRow && item?.node
                        ? getFragmentData(ActivityListItem_Activity, item.node)
                        : null;
                    const showCreateJobFrom =
                      !!node?.job?.id && !!(node?.comment || node?.task);

                    return (
                      <div
                        key={virtualRow.key}
                        data-index={virtualRow.index}
                        ref={rowVirtualizer.measureElement}
                        className='px-3 lg:px-4'
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          // height: `${virtualRow.size}px`,
                          transform: `translateY(${virtualRow.start}px)`,
                        }}
                      >
                        {isLoaderRow ? (
                          hasNextPage ? (
                            'Loading more...'
                          ) : (
                            'Nothing more to load'
                          )
                        ) : isDateSeparatorRow ? (
                          <div className='mx-0 flex items-center gap-3 lg:mx-[10%]'>
                            <div className='h-[.5px] flex-1 bg-[#dddee0]'></div>
                            <span className='py-2 text-center text-sm text-grey-40'>
                              {formatDate(item, 'PP')}
                            </span>
                            <div className='h-[.5px] flex-1 bg-[#dddee0]'></div>
                          </div>
                        ) : (
                          <div className='flex items-center gap-2'>
                            <div
                              className='mb-3 hidden w-6 shrink-0 items-center justify-start overflow-hidden p-1 lg:flex'
                              onClick={() =>
                                handleSelect(item.node.id, isSelected, false)
                              }
                            >
                              {showCreateJobFrom && (
                                <input
                                  type='checkbox'
                                  className='form-checkbox cursor-pointer disabled:border-grey-20 disabled:bg-grey-10'
                                  checked={isSelected}
                                  // disabled={job.status === Cancelled}
                                  readOnly
                                />
                              )}
                            </div>
                            <ActivityListItem
                              onSelect={(id, isSingle) =>
                                handleSelect(id, isSelected, isSingle)
                              }
                              activity={item.node}
                              onTag={(activity) =>
                                handleTagActivity(activity as Activity)
                              }
                              // onTagClick={handleTagClick}
                              onClick={() => {
                                setTotals({
                                  ...totals,
                                  unread: totals.unread - 1,
                                });
                              }}
                              showCreateJobFrom={showCreateJobFrom}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {showBulkAction ? (
                <ActivityBulkActionView
                  selected={selected}
                  onClose={() => setSelected([])}
                  onCreateJob={() => handleBulkCreateJobs()}
                />
              ) : null}
            </>
          )
        }
      />
    </ActivityListContext.Provider>
  );
}

export const shouldRevalidate: ShouldRevalidateFunction = (args) => {
  let shouldRevalidate = args.defaultShouldRevalidate;

  if (args.formAction === '/activity/mark-all-as-read') {
    shouldRevalidate = true;
  } else if (
    args.currentUrl.pathname.includes('/jobs') ||
    args.nextUrl.pathname.includes('/jobs')
  ) {
    shouldRevalidate = false;
  }

  return shouldRevalidate;
};
