import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StockManipulationComponent } from '~/components/smc/StockManipulationComponent';
import { useSite } from './sites.$siteId';

export default function StocktakeRoute() {
  const { siteId } = useParams();

  const context = useSite();

  const navigate = useNavigate();
  const location = useLocation();

  function handleClose() {
    navigate(-1);
  }

  function getMode() {
    if (location.pathname === `/sites/${siteId}/stocktake`) {
      return 'stocktake';
    } else if (location.pathname === `/sites/${siteId}/stock-adjustment`) {
      return 'adjustment';
    } else return 'stocktake';
  }

  const mode = getMode();

  return (
    <div className='z-30 h-full overflow-hidden md:absolute lg:relative'>
      <StockManipulationComponent
        location={`Site:${siteId}`}
        mode={mode}
        onCancel={handleClose}
        onSuccess={() => {
          context.reloadStock();
          handleClose();
        }}
      />
    </div>
  );
}
