import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { client } from '~/client';
import { DeploymentFieldsFragment } from '~/components/AssetSelect/AssetSelect';
import { CardSmall } from '~/components/ui/CardSmall';
import { ReadValue } from '~/components/ui/ReadValue';
import { getFragmentData, graphql } from '~/gql';
import { LONG_FORMAT, formatDate } from '~/helpers/formatDate';
import { useAsset } from './assets';

export const AssetDeploymentsDocument = graphql(`
  query AssetDeployments($id: ID!) {
    asset(id: $id) {
      deployments {
        id
        status
        startedAt
        image
        displayLocation
      }
    }
  }
`);

export async function loader({ params }: LoaderFunctionArgs) {
  invariant(params.assetId);
  const { data } = await client
    .query(
      AssetDeploymentsDocument,
      { id: params.assetId },
      { requestPolicy: 'cache-and-network' }
    )
    .toPromise();

  return data?.asset?.deployments ?? [];
}

export default function AssetDeployments() {
  const asset = useAsset();
  const deployments = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const deployment = getFragmentData(
    DeploymentFieldsFragment,
    asset?.deployment
  );

  if (!deployment) {
    return <div className='p-4'>This asset has no deployments</div>;
  }

  return (
    <>
      {deployment && (
        <div className='p-3'>
          <p className='mb-3 block text-sm font-medium text-grey-50'>
            Current Deployment
          </p>
          <ReadValue label='Location'>{deployment.displayLocation}</ReadValue>
          <ReadValue label='Deployed'>
            {formatDate(deployment.startedAt, LONG_FORMAT)}
          </ReadValue>
        </div>
      )}

      {deployments.length && (
        <>
          <p className='mb-3 block px-3 text-sm font-medium text-grey-50'>
            Deployment History
          </p>
          {deployments.map((deployment) => (
            <CardSmall
              key={deployment.id}
              // linkTo={`/deployments/${deployment.id}`}
              linkTo={``}
              titleText={deployment.displayLocation}
              secondaryText={formatDate(deployment.startedAt, LONG_FORMAT)}
              image={deployment.image}
              status={deployment.status}
            />
          ))}
        </>
      )}
    </>
  );
}
