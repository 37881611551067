import { DashboardModal, FileInput } from '@uppy/react';
import classNames from 'classnames';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { Attachment as TAttachment } from '~/generated/graphql';
import { FragmentType, getFragmentData } from '~/gql';
import { useUppy } from '~/hooks/useUppy';
import { AttachmentFields } from './AttachmentFields';
import { Thumb } from './Thumb';

// Icons
import Multi from './Multi';
import Single from './Single';

type Props = {
  id?: string;
  kind?: 'file' | 'image';
  multiple?: boolean;
  value: string[];
  onChange: (value: string[]) => void;
  // TODO make this non-optional (requires back-end change)
  attachments?: TAttachment[] | null;
  // TODO rename this when all references are using the masked fragment type
  attachments_v2?: FragmentType<typeof AttachmentFields>[] | null;
  // For previously saved images, holds the full URL to the image
  // TODO replace with attachments above
  images?: string[];
};

type UploadFieldProps = {
  name: string;
} & Omit<Props, 'value' | 'onChange'>;

type Preview = { preview: string; id: string | null; name: string };

/**
 * If the preview already exists in the list, do nothing. Otherwise, add it to the end.
 */
const appendUnique =
  (id: string, preview: string, name: string) => (previews: Preview[]) => {
    if (previews.findIndex((item) => item.id === id) > -1) {
      return previews;
    }
    return [...previews, { id, preview, name }];
  };

export function Upload({
  id,
  kind = 'image',
  multiple,
  value,
  onChange,
  images,
  ...props
}: Props) {
  // Get the global uppy instance
  const { uppy, open, setOpen } = useUppy(kind);

  // Unmask attachments if given masked fragment data
  const attachments = props.attachments_v2
    ? getFragmentData(AttachmentFields, props.attachments_v2)
    : props.attachments;

  uppy.once('complete', function handleComplete(result) {
    const successful = result.successful.length;
    const failed = result.failed.length;

    console.log(`complete! ${successful} successful, ${failed} failed.`);

    setNewItems((state) => {
      const newNewItems = result.successful.reduce<string[]>(
        (prev, file) =>
          typeof file.meta.id === 'string' &&
          file.meta.id &&
          !state.includes(file.meta.id)
            ? [...prev, file.meta.id]
            : prev,
        []
      );
      return newNewItems.length ? [...state, ...newNewItems] : state;
    });

    result.successful.forEach((file) => {
      setPreviews((state) => {
        return file.meta.id &&
          typeof file.meta.id === 'string' &&
          file.preview &&
          !state.some(({ id }) => id === file.meta.id)
          ? [
              ...state,
              { preview: file.preview, id: file.meta.id, name: file.name },
            ]
          : state;
      });
    });

    if (failed) {
      // setStatus((state) => ({ ...state, failed }));
    } else {
      uppy.cancelAll();
      // uppy.getPlugin('Dashboard').closeModal();
    }
  });

  const [existing, setExisting] = useState(value);
  const [newItems, setNewItems] = useState<string[]>([]);
  const [previews, setPreviews] = useState<Preview[]>([]);

  useEffect(() => {
    function handleFilesAdded() {
      setOpen();
    }
    uppy.on('files-added', handleFilesAdded);

    uppy.on('preprocess-complete', (file) => {
      if (file?.meta.id && typeof file.meta.id === 'string' && file.preview) {
        setPreviews(appendUnique(file.meta.id, file.preview, file.name));
      }

      console.log('preprocess-complete', file);
    });

    return () => {
      uppy.off('files-added', handleFilesAdded);
    };
  }, []);

  useEffect(() => {
    console.log('Upload changed', newItems);
    onChange([...existing, ...newItems]);
  }, [newItems]);

  const getUrl = (id: string) =>
    attachments?.find((a) => a.id === id)?.url ??
    images?.find((i) => i.includes(id));

  const showUploadButton = multiple || !value.length;

  return (
    <>
      {open && (
        <DashboardModal
          uppy={uppy}
          open={open}
          theme='light'
          proudlyDisplayPoweredByUppy={false}
          closeAfterFinish
        />
      )}

      {/* The following is very useful for debugging */}
      {/* <p>value: {JSON.stringify(value)}</p>
      <p>existing: {JSON.stringify(existing)}</p>
      <p>newItems: {JSON.stringify(newItems)}</p>
      <p>previews: {JSON.stringify(previews)}</p> */}
      {/* The margin,padding and gap here allow a little extra room for the "X" button */}
      <div className='mb-4 mt-6 grid w-full grid-cols-2 gap-3 px-4 xl:grid-cols-4'>
        {existing.map((id) => (
          <Thumb
            key={id}
            kind='image'
            url={getUrl(id) ?? ''}
            onRemove={() => {
              // console.log('remove existing', id);
              onChange(value.filter((v) => v !== id));
              setExisting(existing.filter((v) => v !== id));
            }}
          />
        ))}

        {previews.map(({ preview, name, id }) => (
          <Thumb
            key={preview}
            kind='image'
            url={preview}
            name={name}
            onRemove={() => {
              if (id === null) {
                // TODO pre-process did not complete, just cancel the upload
                setPreviews((state) =>
                  state.filter((item) => item.preview !== preview)
                );
                return;
              }
              // console.log('remove new', id);
              // onChange(value.filter((v) => v !== id));
              setNewItems((state) => state.filter((item) => item !== id));
              setPreviews((state) => state.filter((item) => item.id !== id));
            }}
            isUploading={id === null}
          />
        ))}
      </div>
      <label
        className={classNames(
          'flex cursor-pointer items-center justify-center pb-8 pt-4',
          !showUploadButton && 'hidden'
        )}
      >
        {multiple ? <Multi /> : <Single />}
        <span className='invisible absolute'>
          <FileInput uppy={uppy} />
        </span>
      </label>
    </>
  );
}

/** Image upload field */
export const UploadField = ({ name, ...props }: UploadFieldProps) => {
  const [field, , { setValue }] = useField<string[]>(name);

  if (process.env.NODE_ENV === 'development' && !Array.isArray(field.value)) {
    throw new Error('UploadField value must be an Array');
  }

  return <Upload {...props} value={field.value} onChange={setValue} />;
};
