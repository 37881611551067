import { SVGProps } from 'react';

const SvgAddJobIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='35'
    height='35'
    viewBox='0 0 35 35'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.0483 10.928C21.3472 10.928 22.4233 12.0042 22.4233 13.303V25.178C22.4233 26.5139 21.3472 27.553 20.0483 27.553H10.5483C9.2124 27.553 8.17334 26.5139 8.17334 25.178V13.303C8.17334 12.0042 9.2124 10.928 10.5483 10.928H12.0327H12.3667C12.6636 9.59204 13.8511 8.55298 15.2983 8.55298C16.7085 8.55298 17.9331 9.59204 18.1929 10.928H18.564H20.0483ZM11.1421 12.7092H10.5483C10.2144 12.7092 9.95459 13.0061 9.95459 13.303V25.178C9.95459 25.512 10.2144 25.7717 10.5483 25.7717H20.0483C20.3452 25.7717 20.6421 25.512 20.6421 25.178V13.303C20.6421 13.0061 20.3452 12.7092 20.0483 12.7092H19.4546V13.5999C19.4546 14.1194 19.0464 14.4905 18.564 14.4905H15.2983H12.0327C11.5132 14.4905 11.1421 14.1194 11.1421 13.5999V12.7092ZM14.4077 11.5217C14.4077 12.0413 14.7788 12.4124 15.2983 12.4124C15.7808 12.4124 16.189 12.0413 16.189 11.5217C16.189 11.0393 15.7808 10.6311 15.2983 10.6311C14.7788 10.6311 14.4077 11.0393 14.4077 11.5217ZM13.2202 18.6467C13.2202 19.1663 12.812 19.5374 12.3296 19.5374C11.8101 19.5374 11.439 19.1663 11.439 18.6467C11.439 18.1643 11.8101 17.7561 12.3296 17.7561C12.812 17.7561 13.2202 18.1643 13.2202 18.6467ZM14.7046 18.053H18.2671C18.564 18.053 18.8608 18.3499 18.8608 18.6467C18.8608 18.9807 18.564 19.2405 18.2671 19.2405H14.7046C14.3706 19.2405 14.1108 18.9807 14.1108 18.6467C14.1108 18.3499 14.3706 18.053 14.7046 18.053ZM14.7046 21.6155H18.2671C18.564 21.6155 18.8608 21.9124 18.8608 22.2092C18.8608 22.5432 18.564 22.803 18.2671 22.803H14.7046C14.3706 22.803 14.1108 22.5432 14.1108 22.2092C14.1108 21.9124 14.3706 21.6155 14.7046 21.6155ZM12.3296 23.0999C11.8101 23.0999 11.439 22.7288 11.439 22.2092C11.439 21.7268 11.8101 21.3186 12.3296 21.3186C12.812 21.3186 13.2202 21.7268 13.2202 22.2092C13.2202 22.7288 12.812 23.0999 12.3296 23.0999Z'
      fill='currentColor'
    />
    <path
      d='M26.3447 6.11548V8.92798H29.1572C29.4893 8.92798 29.7822 9.22095 29.7822 9.55298C29.7822 9.90454 29.4893 10.178 29.1572 10.178H26.3447V12.9905C26.3447 13.342 26.0518 13.6155 25.7197 13.6155C25.3682 13.6155 25.0947 13.342 25.0947 12.9905V10.178H22.2822C21.9307 10.178 21.6572 9.90454 21.6572 9.55298C21.6572 9.22095 21.9307 8.92798 22.2822 8.92798H25.0947V6.11548C25.0947 5.78345 25.3682 5.49048 25.7197 5.49048C26.0518 5.49048 26.3447 5.78345 26.3447 6.11548Z'
      fill='currentColor'
    />
  </svg>
);

export default SvgAddJobIcon;
