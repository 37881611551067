import { FullMono } from '~/brand/generated/propfit.app';

export function LogoSection() {
  return (
    <div
      className={
        'hidden items-center justify-center bg-gradient-to-b from-brand to-slate-dark h-screen lg:flex lg:w-1/2'
      }
    >
      <FullMono className='w-1/2' />
    </div>
  );
}
