import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

export const Settings = () => {
  const { t, i18n } = useTranslation();

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    i18n.changeLanguage(event.currentTarget.value);
  };

  return (
    <div className='conatiner m-24 mx-auto ml-96 justify-items-center'>
      <label>Change Language</label>
      <select
        value={i18n.language}
        onChange={handleChange}
        id='lang_select'
        className='flex flex-col items-center justify-center p-1 md:flex-row-reverse'
      >
        <option value='en'>{t('English')}</option>
        <option value='en-x-property'>English (Property Management)</option>
        <option value='fil'>{t('Filipino')}</option>
        {/* <option value='zh'>{t('Chinese')}</option>
        <option value='hi'>{t('Hindi')}</option>
        <option value='es'>{t('Spanish')}</option>
        <option value='ar'>{t('Arabic')}</option> */}
      </select>
    </div>
  );
};
