import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResultOf } from '@graphql-typed-document-node/core';
import { formatInTimeZone } from 'date-fns-tz';
import { Fragment } from 'react';
import { AttributeView } from '~/components/AttributeView';
import { DateDisplay } from '~/components/sites/tabs/JobsTab';
import { CardSmall } from '~/components/ui/CardSmall';
import { ErrorMessage } from '~/components/ui/Error';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { FragmentType, getFragmentData, graphql } from '~/gql';
import { JobStatus } from '~/gql/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { isSameDate } from '../JobView/JobHistory';
import { JobCalendarEventMenu } from './JobCalendarEventMenu';

type Event = ResultOf<typeof JobCalendarEventFields_JobCalendarEventFragment>;
type Job = Event['jobs'][number];

type Props = {
  event?: FragmentType<typeof JobCalendarEventFields_JobCalendarEventFragment>;
  jobPathPrefix: string;
  onClose: () => void;
};

export const JobCalendarEventFields_JobCalendarEventFragment = graphql(`
  fragment JobCalendarEventFields on JobCalendarEvent {
    id
    start
    end
    timeZone
    summary
    url
    icon
    cancelled
    hidden
    location {
      __typename
      id
      name
    }
    attributes {
      name
      value
    }
    jobs {
      __typename
      id
      status
      name
      createdAt
      scheduleStart
      scheduleEnd
      scheduledStartStrict
      scheduledStartDate
      scheduledStartTime
      scheduledEndStrict
      scheduledEndDate
      scheduledEndTime
      completedAt
      timeZone
      image
      tags
      location {
        __typename
        id
        name
      }
      assignee {
        id
        name
        image
      }
    }
  }
`);

export function JobCalendarEventView({
  jobPathPrefix,
  onClose,
  ...props
}: Props) {
  const event = getFragmentData(
    JobCalendarEventFields_JobCalendarEventFragment,
    props.event
  );
  if (!event) {
    return <ErrorMessage message='Event not found' onBack={onClose} />;
  }

  return (
    <SideLayout>
      <SideLayout.Head
        rightSlot={
          event.cancelled && (
            <JobCalendarEventMenu eventId={event.id} onHidden={onClose} />
          )
        }
        onClose={onClose}
      >
        {event.attributes?.find((a) => a.name === 'Guest')?.value[0] ??
          'Booking'}
      </SideLayout.Head>
      <SideLayout.Body className=''>
        <div className='px-4'>
          <StatusBadge
            value={event.cancelled ? JobStatus.Cancelled : JobStatus.Accepted}
          />
          <ReadValue label='site'>{event.location?.name}</ReadValue>
          <div className='flex gap-4'>
            <div>
              <ReadValue label='checkIn'>
                {formatInTimeZone(
                  new Date(event.start),
                  event.timeZone,
                  'PP p'
                )}
              </ReadValue>
            </div>
            <div>
              <ReadValue label='checkOut'>
                {event.end &&
                  formatInTimeZone(new Date(event.end), event.timeZone, 'PP p')}
              </ReadValue>
            </div>
          </div>
          <ReadValue label='link'>
            {event.url ? <ExternalLink url={event.url} /> : null}
          </ReadValue>
          <AttributeView attributes={event.attributes ?? []} />
        </div>
        {event.jobs.length > 0 && (
          <>
            <p className='mb-1 mt-4 px-4 text-sm font-bold text-[#00000096]'>
              Jobs
            </p>
            {event.jobs.map((job, i) => (
              <Fragment key={job.id}>
                {i <= 0 ||
                !isSameDate(
                  event.jobs[i - 1].scheduleStart,
                  job.scheduleStart
                ) ? (
                  <DateDisplay
                    date={formatInTimeZone(
                      job.scheduleStart,
                      job.timeZone,
                      'PP'
                    )}
                  />
                ) : (
                  ''
                )}
                <JobCardSm job={job} pathPrefix={jobPathPrefix} />
              </Fragment>
            ))}
          </>
        )}
      </SideLayout.Body>
    </SideLayout>
  );
}

function ExternalLink({ url }: { url: string }) {
  return (
    <a
      className='text-brand hover:underline'
      href={url}
      target='_blank'
      rel='noopener noreferrer'
    >
      {url} <FontAwesomeIcon icon={faExternalLink} />
    </a>
  );
}

function JobCardSm({ job, pathPrefix }: { job: Job; pathPrefix: string }) {
  return (
    <CardSmall
      linkTo={`${pathPrefix}${job.id}`}
      newTab
      titleText={job.name}
      secondaryText={job.location?.name}
      heroElement={<HeroElement job={job} />}
      user={{ name: job.assignee?.name ?? 'U' }}
      status={job.status}
    />
  );
}

function HeroElement({ job }: { job: Job }) {
  return (
    <div className='flex flex-col justify-start pr-1 text-right leading-tight'>
      <p className='... truncate text-sm font-medium leading-tight text-grey-70 md:text-[15px] '>
        {formatInTimeZone(job.scheduleStart, job.timeZone, 'h:mm a')}
      </p>
      {job.scheduleEnd && (
        <p className='whitespace-nowrap text-xs text-grey-50'>
          to {formatInTimeZone(job.scheduleEnd, job.timeZone, 'h:mm a')}
        </p>
      )}
    </div>
  );
}
