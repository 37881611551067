import { format, isAfter } from 'date-fns';
import { formatLocalDate } from '../../formatDate';
import { Job } from '../JobView';
import { Contact } from './Bubbles';

export function getAddress(value: string): Record<string, string> | null {
  const parseAddress = () => {
    if (!value.length) return;

    try {
      return JSON.parse(value);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return;
    }
  };

  const addr = parseAddress();
  if (!addr) return null;

  const address = [addr.street_number, addr.route].join(' ');
  const long = [addr.locality, addr.postal_code, addr.country].join(' ');

  return { address, long };
}

export const whenSummary = (job: Job) => {
  const today = new Date();
  const end = formatLocalDate(job, 'scheduledEnd');
  const start = new Date(Date.parse(job.scheduleStart));

  if (start && isAfter(today, start)) {
    return `after ${formatLocalDate(job, 'scheduledStart')}`;
  } else if (!start && end) {
    return `end ${end}`;
  } else if (start && end) {
    return `before ${end}`;
  } else return '';
};

export const parseDateTime = (date?: string | null) => {
  if (!date) return null;

  return format(new Date(date), 'd MMM yyyy p');
};

export const getContactsAssociatedToJob = (contacts: Contact[], job: Job) => {
  const assignees =
    contacts.length > 0
      ? contacts.filter((c) =>
          job.assignees?.map(({ id }) => id).includes(c.id)
        )
      : job.assignees;

  const owners =
    contacts.length > 0
      ? contacts.filter((c) => job.owners?.map(({ id }) => id).includes(c.id))
      : job.owners;

  let otherAssigneesIds = job.include?.map((i) => i.id);
  const otherAssignees =
    contacts.length > 0
      ? contacts?.filter((c) => otherAssigneesIds?.includes(c.id))
      : job.include && job.include.length > 0
      ? job.include
      : [];

  return { owners, assignees, otherAssignees };
};
