import { LocationField } from '~/components/form/LocationField'
import { ModalForm } from '~/components/form/ModalForm'
import { TextField } from '~/components/form/TextField'
import * as Yup from 'yup'

const transferTypeSchema = Yup.object().shape({
  source: Yup.object().shape({
    type: Yup.string().required('Required'),
    id: Yup.array()
      .min(1, 'Required')
      .max(1)
      .of(Yup.string().required('Required')),
  }),
})

export const TransferTypeForm = ({ onSuccess, prevData, step, returnTo }) => {
  const initialValues = prevData
    ? prevData[step]
    : {
        transfer: 'immediate',
        source: { type: 'Asset', id: [] },
        notes: '',
      }

  const handleSubmit = (values) => {
    onSuccess(values)
  }

  return (
    <ModalForm
      title='Transfer Stock'
      formikProps={{
        initialValues,
        onSubmit: handleSubmit,
        validationSchema: transferTypeSchema,
        validateOnChange: false,
        validateOnBlur: false,
      }}
      buttonText='Next'
      returnTo={returnTo}
    >
      <LocationField name='source' title='Source Location Type' />
      <TextField name='notes' label='Notes' />
    </ModalForm>
  )
}
