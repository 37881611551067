import { Outlet } from 'react-router-dom';
import { MainNav } from './nav/MainNav';

export const DefaultLayout = () => {
  return (
    <>
      <MainNav />
      <Outlet />
    </>
  );
};
