/**
 * React Select prefers styling via style objects
 * https://react-select.com/styles
 *
 * Keep styles together in this module so that they can be more easily reused
 *  across different components based on React Select.
 */
// import { Styles } from 'react-select';

const fontStyles = {
  fontFamily: 'var(--font-body)',
  fontWeight: 300,
  fontSize: 16,
  lineHeight: 1.1875,
  letterSpacing: 0.15,
};

const styles /*: Partial<Styles>*/ = {
  control: (styles: any, state: any) => ({
    ...styles,
    '&:hover': {
      borderColor: state.isFocused
        ? 'var(--color-brand)'
        : 'var(--color-gray88)',
    },
    backgroundColor: 'initial',
    borderColor: state.isFocused ? 'var(--color-brand)' : 'var(--color-gray88)',
    borderRadius: 0,
    borderWidth: 0,
    borderBottomWidth: state.isFocused ? 2 : 1,
    boxShadow: 'none',
    paddingBottom: state.isFocused ? 0 : 1,
    transition: 'border-color 200ms ease-in-out',

    ...fontStyles,

    '& > div': { padding: '2px 0' },
  }),
  placeholder: () => ({ display: 'none' }),
};

// eslint-disable-next-line import/no-default-export
export default styles;
