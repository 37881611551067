import { TJob } from '~/routes/jobs';
import { formatLocalDate, formatLocalTime } from '../../formatDate';

type Props = {
  job: TJob;
  small?: boolean;
};

export function Hero({ job, small }: Props) {
  if (job.completedAt) {
    return (
      <div className='ml-auto flex flex-col items-end justify-center text-right text-xs font-normal lg:text-sm'>
        <p className='flex gap-1'>{formatLocalTime(job, 'completedAt')}</p>
      </div>
    );
  }

  if (!(job.scheduleStart || job.scheduleEnd)) {
    // Unscheduled
    return (
      <div className='ml-auto hidden flex-col items-end justify-center text-right text-xs font-normal lg:flex lg:text-sm'>
        <p className='flex flex-[1_0_0] items-end justify-end gap-3'>
          <span className='flex gap-1'>
            <span className='text-sm font-normal not-italic leading-[17.5px] text-[#656565]'>
              Created
            </span>
            <span className='text-right text-sm font-semibold not-italic leading-[17.5px] text-[color:var(--text-primary,#272930)]'>
              {formatLocalDate(job, 'createdAt', 'P')}
            </span>
          </span>
        </p>
      </div>
    );
  }

  return (
    <div className='ml-auto flex flex-col items-end justify-center text-right text-xs font-normal lg:text-sm'>
      <p className='flex items-center gap-1'>
        {/* <span className='text-grey-50'>
            {job.scheduledStartStrict ? 'Start at' : 'Start from'}
          </span> */}
        {/* <UrgentIndicator job={job} /> */}
        {job.scheduledStartTime && (
          <span className='text-black'>
            {formatLocalTime(job, 'scheduledStart')}
          </span>
        )}
      </p>
      {job.scheduledEndTime && (
        <p className='flex gap-1'>
          <span className='text-grey-50'>
            {/* {job.scheduledEndStrict ? 'End at' : 'End before'} */}
            to
          </span>
          <span className='text-black'>
            {formatLocalTime(job, 'scheduledEnd')}
          </span>
        </p>
      )}
    </div>
  );
}
