import { addFile, openDb } from './db';

type UploadInit = {
  id: string;
  url: string;
  method: 'POST' | 'PUT';
  file: File;
  onProgress: (progress: UploadProgress) => void;
};

export type UploadProgress = {
  loaded: number;
  total: number;
};

class UploadError extends Error {
  status: number;
  statusText: string;

  constructor({ status, statusText }: { status: number; statusText: string }) {
    super(`${status} ${statusText}`);
    this.status = status;
    this.statusText = statusText;
  }
}

/**
 * Upload a file
 * @deprecated Replace with cloudflare images using uppy
 */
export async function s3upload({
  id,
  url,
  method,
  file,
  onProgress,
}: UploadInit) {
  const db = await openDb();
  await addFile(db, file, id);

  const xhr = new XMLHttpRequest();

  xhr.upload.onprogress = (event) => {
    onProgress({ loaded: event.loaded, total: event.total });
  };

  xhr.onloadend = () => {
    if (xhr.status === 200) {
      return Promise.resolve(id);
    }
    throw new UploadError({
      status: xhr.status,
      statusText: xhr.statusText,
    });
  };

  xhr.open(method, url);
  xhr.send(file);

  return id;
}
