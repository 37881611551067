import { Popover } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { EditorState, convertToRaw } from 'draft-js';
import { omit } from 'lodash';
import { nanoid } from 'nanoid';
import React, {
  ClipboardEvent,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useAppContext } from '~/App';
import { Input } from '~/components/form/TextField';
import { TaskInput } from '~/generated/graphql';
import { FragmentType, getFragmentData } from '~/gql';
import { JobTemplateFieldsFragment } from '~/gql/graphql';
import { JobTemplateFields } from '~/graphql/fragment/JobTemplateFields';
import { StocktakeTaskFields } from '~/graphql/fragment/StocktakeTaskFields';
import { pick } from '~/helpers/object';
import { Option } from '~/types';
import { TaskIcon } from '../JobView/TaskList/TaskListItem';
import { UseTemplateButton } from '../UseTemplateButton';
import { TaskList } from './TaskList';

type TaskTemplate = JobTemplateFieldsFragment['tasks'][number];

export function taskTemplateToTaskInput(task: TaskTemplate): TaskInput {
  // Display attributes
  const attributes = task.attributes?.map(({ id }) => id);

  // Asset task
  const assetTaskConfig = task.config
    ? omit(task.config, ['__typename'])
    : undefined;

  // Attribute task (Audit attributes)
  const attribute = task.attribute
    ? pick(task.attribute, ['attributes'])
    : undefined;
  const stocktakeData = getFragmentData(StocktakeTaskFields, task.stocktake);
  const purchase = task.purchase ? pick(task.purchase, ['spaces']) : undefined;
  const stocktake = stocktakeData
    ? pick(stocktakeData, ['include', 'itemStatus', 'spaces'])
    : undefined;

  return {
    // We should never need this when copying from template
    // _destroy: false,

    // Standard task properties
    id: nanoid(),
    type: task.type,
    name: task.name,
    description: task.description,
    attributes, // Display attributes

    // Asset task config
    asset: assetTaskConfig,

    // Attribute task config (Audit attributes)
    attribute,

    // Item task config
    item: undefined, // FIXME ItemTaskInput

    // Purchase task config
    purchase,

    // Stocktake task config
    stocktake,

    // Transfer task config
    transfer: undefined, // FIXME TransferTaskInput
  };
}

function createTask(name: string, type: string) {
  const editorState = EditorState.createEmpty();
  const defaultDescription = convertToRaw(editorState.getCurrentContent());

  // TODO support passing default source id for more advanaced tasks (Add Items)
  return {
    id: nanoid(),
    type: type,
    name,
    description: JSON.stringify(defaultDescription),
  };
}

type Props = {
  jobTemplates: FragmentType<typeof JobTemplateFields>[];
  state: [TaskInput[], Dispatch<SetStateAction<TaskInput[]>>];
  onShowTask: (id: string) => void;
  onChange?: () => void;
  onDeleteTask?: (id: string) => void;
  onUseTemplateSelect?: (template: JobTemplateFieldsFragment) => void;
  onTaskTypeUpdate: (id: string, type: string) => void;
};

export function ManageTasks({
  state,
  onShowTask,
  onChange,
  onDeleteTask,
  onTaskTypeUpdate,
  ...props
}: Props) {
  const [tasks, setTasks] = state;
  const [taskInput, setTaskInput] = useState<string>('');
  const [taskType, setTaskType] = useState<string>('Standard');
  const [pastedTasks, setPastedTasks] = useState<string[] | null>(null);

  const jobTemplates = getFragmentData(JobTemplateFields, props.jobTemplates);
  const templateOptions: Option[] = jobTemplates
    // .filter((t) => t.scope === form.scope)
    .map(({ name: value }) => ({ value, label: value }));

  function quickAdd() {
    if (pastedTasks) {
      pastedTasks.forEach((name) => {
        name !== '' &&
          name !== '\n' &&
          setTasks((state) => [...state, createTask(name, taskType)]);
      });
      onChange && onChange();
      setPastedTasks(null);
      setTaskInput('');
    } else if (taskInput) {
      setTasks((state) => [...state, createTask(taskInput, taskType)]);
      onChange && onChange();
      setTaskInput('');
    }
  }

  function parsePasted(e: ClipboardEvent<HTMLInputElement>) {
    let value = e.clipboardData.getData('Text');
    let textArray = value.split(/^/gm);
    setPastedTasks(textArray);
  }

  function deleteTask(id: string) {
    if (onDeleteTask) {
      onDeleteTask(id);
      setTasks(tasks);
      return;
    } else {
      const newTaskList = tasks.filter((task) => task.id !== id);
      setTasks(newTaskList);
    }
  }

  const handleSelectTemplate = (value: string) => {
    const template = jobTemplates.find(({ name }) => name === value);
    const templateTasks: TaskTemplate[] = template?.tasks ?? [];

    setTasks((currentTasks) => [
      // We want to keep any tasks that have been marked for deletion
      ...currentTasks.filter(({ _destroy }) => _destroy === true),
      ...templateTasks.map(taskTemplateToTaskInput),
    ]);

    if (props.onUseTemplateSelect && template) {
      props.onUseTemplateSelect(template);
    }
  };

  const focusTaskNameInput = () => {
    const taskNameInputEl = document
      .getElementById('task-name-field')
      ?.querySelector('input');

    if (taskNameInputEl) setTimeout(() => taskNameInputEl.focus(), 0);
  };

  return (
    <>
      <div className='mb-2 ml-1 flex items-end justify-between text-sm font-medium text-grey-50'>
        Tasks
      </div>

      {tasks.filter((task) => !task._destroy).length === 0 &&
        templateOptions.length > 0 && (
          <div className='mb-2 ml-1'>
            <UseTemplateButton
              templateOptions={templateOptions}
              onSelect={handleSelectTemplate}
            />
          </div>
        )}

      <TaskList
        tasks={tasks}
        onClick={onShowTask}
        onSort={setTasks}
        onDelete={deleteTask}
        onTaskTypeUpdate={onTaskTypeUpdate}
      />

      <div className='mb-5 flex items-center gap-3'>
        <div className='relative flex-1'>
          <Input
            onChange={(event: any) => {
              setTaskInput(event.target.value);
            }}
            onPaste={(event) => parsePasted(event)}
            onKeyUp={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.key === 'Enter' && quickAdd();
            }}
            className='mb-0'
            id='task-name-field'
            label=''
            value={taskInput}
            placeholder='Add task'
            icon={
              <TaskTypesSelect
                type={taskType}
                onTypeSelect={(type) => {
                  setTaskType(type);
                  focusTaskNameInput();
                }}
              />
            }
          />
        </div>
        {taskInput && (
          <button
            type='button'
            onClick={(e) => quickAdd()}
            className='z-20 flex h-8 w-8 items-center justify-center rounded-full bg-[#919FB4] p-1.5 transition-all duration-300 hover:bg-[#8894A9]'
          >
            <PlusIcon className='h-full w-full text-white' />
          </button>
        )}
      </div>
    </>
  );
}

export const taskTypeOptions = (): { value: string; label: string }[] => {
  const { ability } = useAppContext();

  const options = [{ value: 'Standard', label: 'Standard' }];

  if (ability.can('use', 'feat.tasks.attribute')) {
    options.push({ value: 'Attribute', label: 'Attributes Audit' });
    options.push({ value: 'Stocktake', label: 'Stocktake' });
    options.push({ value: 'Asset', label: 'Asset' });
  } else if (ability.can('use', 'feat.tasks.advanced')) {
    options.push({ value: 'Attribute', label: 'Attributes Audit' });
    options.push({ value: 'Purchase', label: 'Purchases' });
    options.push({ value: 'Stocktake', label: 'Stocktake' });
    options.push({ value: 'Restock', label: 'Restock' });
    options.push({ value: 'Transfer Stock', label: 'Transfer Stock' });
    options.push({ value: 'Asset', label: 'Asset' });
  }

  return options;
};

export const TaskTypesSelect = ({
  type,
  onTypeSelect,
}: {
  type: string;
  onTypeSelect: (type: string) => void;
}) => {
  const { ability } = useAppContext();
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
    placement: 'bottom-start',
  });

  const typeOptions = taskTypeOptions();

  if (typeOptions.length <= 1) return null;

  return (
    <Popover>
      {({ open, close }) => {
        return (
          <div className='flex w-full flex-row overflow-x-auto'>
            <Popover.Button ref={setReferenceElement} className='outline-none'>
              <TaskTypeTooltip
                tooltipText='Update Task Type'
                isTaskTypesSelectOpen={open}
              >
                <div
                  className={classNames(
                    'z-20 flex h-9 w-9 items-center justify-center rounded-full p-1 transition-all duration-200 hover:text-brand',
                    {
                      'bg-[#004B4B1F] text-brand': open,
                      'text-secondary hover:bg-[#004B4B14]': !open,
                    }
                  )}
                >
                  <TaskIcon taskType={type} />
                </div>
              </TaskTypeTooltip>
            </Popover.Button>
            <Popover.Panel
              ref={setPopperElement}
              className='border-gray-10 absolute z-[100] w-52 rounded-sm border bg-white p-1 pb-1 shadow-md'
              style={styles.popper}
              {...attributes.popper}
            >
              <div className='flex flex-col'>
                {typeOptions.map((taskType, idx) => {
                  const isActive = type === taskType.value;
                  return (
                    <div
                      key={idx}
                      className={classNames(
                        'flex cursor-pointer items-center gap-1 px-3.5 py-2 hover:bg-[#00707014]',
                        {
                          'bg-[#00707014] text-brand': isActive,
                        }
                      )}
                      onClick={() => {
                        onTypeSelect(taskType.value);
                        close();
                      }}
                    >
                      <span
                        className={classNames(
                          'flex-1 truncate whitespace-nowrap',
                          {
                            'text-brand': isActive,
                            'text-primary': !isActive,
                          }
                        )}
                      >
                        {taskType.label}
                      </span>
                      <div
                        className={classNames(
                          'flex h-5 items-center justify-end',
                          {
                            'text-brand': isActive,
                            'text-tertiary': !isActive,
                          }
                        )}
                      >
                        <TaskIcon taskType={taskType.value} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Popover.Panel>
          </div>
        );
      }}
    </Popover>
  );
};

const TaskTypeTooltip = ({
  children,
  tooltipText,
  isTaskTypesSelectOpen = false,
}: {
  children: React.ReactNode;
  tooltipText: string;
  isTaskTypesSelectOpen?: boolean;
}) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [show, setShow] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'flip',
        enabled: false,
      },
    ],
  });

  return (
    <>
      <span ref={setReferenceElement}>
        <div
          className='relative rounded-full'
          onTouchEnd={() => setShow(true)}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          {children}
        </div>
      </span>

      {show &&
        !isTaskTypesSelectOpen &&
        createPortal(
          <div
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 55 }}
            {...attributes.popper}
          >
            <div className='z-20 my-1 max-w-xs rounded bg-[#2D3131] px-2 py-1 text-xs text-[#EEF1F1]'>
              {tooltipText}
            </div>
          </div>,
          document.querySelector('#tooltip')!
        )}
    </>
  );
};
