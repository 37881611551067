import { RefObject, useEffect, useState } from 'react';

export const useCheckOverflow = (
  ref: RefObject<HTMLDivElement | HTMLUListElement>
) => {
  const [isOverflowing, setIsOverflowing] = useState(true);

  useEffect(() => {
    if (!ref.current) return;

    const offsetHeight = ref.current.offsetHeight;
    const scrollHeight = ref.current.scrollHeight;

    const isOver = scrollHeight - offsetHeight > 5;
    setIsOverflowing(isOver);
  }, [ref.current]);

  return { isOverflowing };
};
