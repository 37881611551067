import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/outline';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AddressInputGroup } from '~/components/form/AddressInputGroup';
import { SubmitButton } from '~/components/form/SubmitButton';
import { TextField } from '~/components/form/TextField';
import { AttributesField } from '~/components/form/attributes/AttributesField';
import { SelectField } from '~/components/form/downshift/SelectField';
import { UploadField } from '~/components/upload/Upload';
import {
  Attribute,
  ContactRelationship,
  ContactType,
  Status,
} from '~/generated/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { relationshipOptions } from '.';

export type FormValues = {
  status: Status;
  type: ContactType;
  name: string;
  organisationId: string[];
  relationship: string[];
  streetAddress: any;
  postalAddress: any;
  email: string;
  phone: string;
  attributes: Attribute[];
  images: string[];
  notes: string;
};

type Props = {
  type?: 'Customer' | 'Assignee' | 'Owner';
  defaultName?: string;
  onSubmit: (values: FormValues) => void;
  emailIsRequired?: boolean;
  isLoading?: boolean;
};

function createSchema(emailIsRequired: boolean) {
  const email = yup.string().email();

  return yup.object().shape({
    name: yup.string().required(),
    email: emailIsRequired ? email.required() : email,
  });
}

export const ShortContactForm = ({
  type,
  defaultName,
  onSubmit,
  emailIsRequired = false,
  isLoading = false,
}: Props) => {
  const { t } = useTranslation();

  const defaultRelationship =
    type === 'Customer' ? [ContactRelationship.Customer] : [];

  const initialValues: FormValues = {
    status: Status.Active,
    type: ContactType.Person,
    name: defaultName ?? '',
    organisationId: [],
    relationship: defaultRelationship,
    streetAddress: null,
    postalAddress: null,
    email: '',
    phone: '',
    attributes: [],
    images: [],
    notes: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={createSchema(emailIsRequired)}
    >
      <SideLayout>
        <SideLayout.Body>
          <br />
          <TextField
            className='mb-5 pt-2'
            name='name'
            label={t('name')}
            autoFocus
            required
          />
          <TextField
            name='email'
            label={t('emailAddress')}
            type='email'
            required
          />
          <TextField name='phone' label={t('phoneNumber')} type='phone' />
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className='flex pb-4 pt-6 text-left text-xs text-brand underline'>
                  <ChevronUpIcon
                    className={`${
                      !open ? 'rotate-180 transform pt-0.5' : ''
                    } mr-1 h-5 w-5  `}
                  />
                  {open ? <p>Less Details</p> : <p>More Details</p>}
                </Disclosure.Button>
                <Disclosure.Panel className='pb-2 text-sm text-copy-body'>
                  <SelectField
                    name='relationship'
                    label={t('relationship')}
                    options={relationshipOptions(t)}
                    multiple
                  />

                  <AddressInputGroup
                    name='streetAddress'
                    label={t('streetAddress')}
                  />
                  <AddressInputGroup
                    name='postalAddress'
                    label={t('postalAddress')}
                  />
                  <TextField name='notes' label={t('notes')} type='textarea' />
                  <AttributesField type='Contact' />
                  <UploadField name='images' />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton loading={isLoading} />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
};
