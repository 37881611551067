import { forwardRef, useState } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

type Props = {
  label: string;
  required?: boolean;
  error?: string | false;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'>;

type Ref = HTMLInputElement;

export const Input = forwardRef<Ref, Props>(
  ({ label, error, required, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className='mb-6 flex flex-col gap-1'>
        <label className='mb-1' htmlFor={props.id ?? props.name}>
          {label}
          {required && <span className='text-brand pl-0.5'>*</span>}
        </label>
        {props.type === 'password' ? (
          <div className='relative'>
            <div className='relative'>
              <div
                onClick={() => setShowPassword(!showPassword)}
                className='absolute top-0 bottom-0 right-4 flex justify-center lg:right-6'
              >
                {showPassword ? (
                  <EyeOffIcon className='text-brand my-auto h-6 w-6 cursor-pointer' />
                ) : (
                  <EyeIcon className='text-brand my-auto h-6 w-6 cursor-pointer' />
                )}
              </div>
              <input
                {...props}
                id={props.id ?? props.name}
                className={classNames([
                  error
                    ? 'border-red-600 focus-visible:border-red-600'
                    : 'border-gray88 focus-visible:border-brand ',
                  'after-eye w-full rounded border px-3 py-2.5 focus-visible:outline-none',
                ])}
                ref={ref}
                type={showPassword ? 'text' : 'password'}
              />
            </div>

            {error && (
              <div className='ErrorMessage'>
                {typeof error === 'string' ? error : JSON.stringify(error)}
              </div>
            )}
          </div>
        ) : (
          <>
            <div className='absolute top-0 bottom-0 right-3 flex justify-center'></div>
            <input
              {...props}
              id={props.id ?? props.name}
              className={classNames([
                error
                  ? 'border-red-600 focus-visible:border-red-600'
                  : 'border-gray88 focus-visible:border-brand ',
                'after-eye mt-1 w-full rounded border px-3 py-2.5 focus-visible:outline-none',
              ])}
              ref={ref}
              type={props.type ?? 'text'}
            />
            {error && (
              <div className='ErrorMessage'>
                {typeof error === 'string' ? error : JSON.stringify(error)}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);
