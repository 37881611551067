type Filters = {
  [key: string]: [string];
};

export default function parseFilters(filters: URLSearchParams): Filters {
  const parsedFilters: Filters = {};
  for (const [key, value] of filters.entries()) {
    if (key in parsedFilters) {
      parsedFilters[key].push(value);
    } else {
      parsedFilters[key] = [value];
    }
  }

  return parsedFilters;
}
