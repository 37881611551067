import classNames from 'classnames';
import { RefObject, createRef, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { BasicListItem, BasicListItemProps } from './BasicListItem';

type Item = { id: number | string } & BasicListItemProps;

type MatchListProps = {
  matchPath: string;
  items: Item[];
  placeholderIcon?: React.ReactNode;
  className?: string;
};

export const MatchList = ({
  matchPath,
  items,
  placeholderIcon,
  className,
}: MatchListProps) => {
  const refs: { [x: string]: RefObject<HTMLDivElement> } = items.reduce(
    (acc, item) => ({ ...acc, [item.id]: createRef() }),
    {}
  );

  const match = useMatch(matchPath);

  useEffect(() => {
    if (!Object.keys(refs).length) return;

    if (
      match?.params.id &&
      refs[match.params.id] &&
      refs[match.params.id].current
    ) {
      refs[match.params.id].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [refs, match]);

  return (
    <div
      className={classNames(
        'max-w-full flex-1 overflow-x-hidden p-4',
        className
      )}
    >
      {items.map((item) => (
        <div key={item.id} ref={refs[item.id]}>
          <BasicListItem
            active={Boolean(match && match.params.id === item.id.toString())}
            placeholderIcon={placeholderIcon}
            {...item}
            showInactive
          />
        </div>
      ))}
    </div>
  );
};
