import { SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useCombobox } from 'downshift';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option as BaseOption } from '~/types';
import { Input, Props as InputProps } from '../TextField';
import { FloatingInput } from '../TextFieldFloating';

const ALL = '_all_';

type Option = BaseOption & { $count?: number };

export type SelectProps = {
  _name?: string;
  label: string;
  className?: string;
  options: Option[] | readonly Option[]; //Array<Option | string>;
  /**
   * Sometimes it's useful to add props to the underlying input
   * e.g. for HTML form validation attributes
   */
  inputProps?: Partial<InputProps>;
  value: string[];
  onChange: (value: string[]) => void;
  creatable?: boolean;
  selectAdd?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  required?: boolean;
  placeholder?: string;
  floatingInput?: boolean;
  dynamicDropdown?: boolean;
  filter?: boolean;
  type?: 'Contact' | 'Site';
  hasSelectorIcon?: boolean;
  floatingOptions?: boolean;
};

export function Select({
  _name,
  inputProps,
  dynamicDropdown,
  selectAdd = false,
  creatable = false,
  disabled = false,
  multiple = false,
  required = false,
  hasSelectorIcon = false,
  floatingOptions = true,
  ...props
}: SelectProps) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    if (selectAdd) {
      return [
        {
          value: 'create' + props.type,
          label: '+ ' + t('create' + props.type),
        },
        ...props.options,
      ];
    }
    return multiple && !creatable
      ? [{ value: ALL, label: t('all') }, ...props.options]
      : props.options;
  }, [t, multiple, creatable, props.options, props.type, selectAdd]);

  const inputRef = useRef<HTMLInputElement>(null);
  const [cancelling, setCancelling] = useState(false);
  const [inputItems, setInputItems] = useState(options);
  const [selectedItems, setSelectedItems] = useState<string[]>(
    () => props.value
  );

  const withCreated = useMemo(() => {
    return options.concat(
      selectedItems
        ? selectedItems
            .filter(
              (value) => options.findIndex((o) => o.value === value) === -1
            )
            .map((value) => ({ value, label: value }))
        : []
    );
  }, [options, selectedItems]);

  // If options changes we need to do some cleanup
  useEffect(() => {
    // TODO un-select any removed options
    setInputItems(options);
  }, [setInputItems, options]);

  // If withCreated changes update inputItems to show the new option
  useEffect(() => {
    setInputItems(withCreated);
  }, [withCreated]);

  useEffect(() => {
    setSelectedItems(props.value);
  }, [setSelectedItems, props.value]);

  const {
    isOpen,
    // getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
    inputValue,
  } = useCombobox({
    items: [...inputItems],
    onSelectedItemChange: ({ type, selectedItem }) => {
      // If action type is blur do nothing because the value should already be
      // set. This prevents "Done" on mobile virtual keyboard toggling a value
      if (type === useCombobox.stateChangeTypes.InputBlur) {
        return;
      }

      // If no selected item e.g. because enter was pressed,
      // select the first item shown
      const selectedValue = selectedItem
        ? selectedItem.value
        : inputItems.length
        ? inputItems[0].value
        : '';

      // Treated as clearing the input
      if (selectedValue === '') {
        setSelectedItems([]);
        return;
      }

      if (!selectedValue) {
        return;
      }

      if (selectedValue === ALL) {
        setSelectedItems((selected) =>
          selected.length === props.options.length
            ? []
            : props.options.map(({ value }) => value)
        );
        return;
      }

      if (!multiple) {
        setSelectedItems([selectedValue]);
        return;
      }

      const index = selectedItems.indexOf(selectedValue);
      if (index > 0) {
        setSelectedItems([
          ...selectedItems.slice(0, index),
          ...selectedItems.slice(index + 1),
        ]);
      } else if (index === 0) {
        setSelectedItems([...selectedItems.slice(1)]);
      } else {
        setSelectedItems([...selectedItems, selectedValue]);
      }
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: '', // don't add the item string as input value at selection.
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '', // don't add the item string as input value at selection.
          };
        default:
          return changes;
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue === undefined || inputValue === '') {
        setInputItems(withCreated);
        return;
      }

      const filtered = withCreated.filter((item) =>
        item.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (
        creatable &&
        withCreated.findIndex((o) => o.value === inputValue) === -1
      ) {
        // Append the "Create" option
        setInputItems(
          filtered.concat({
            value: inputValue,
            label: `${t('create')} "${inputValue}"`,
          })
        );
        return;
      }

      setInputItems(filtered);
    },
    onIsOpenChange: ({ isOpen }) => {
      const hasChangedValue =
        selectedItems.length !== props.value.length ||
        (selectedItems && selectedItems.some((_, i) => props.value[i] !== _));
      if (!isOpen && hasChangedValue) {
        props.onChange(selectedItems);
      }
    },
  });

  useEffect(() => {
    if (cancelling) {
      closeMenu();
      setCancelling(false);
    }
  }, [closeMenu, setCancelling, cancelling]);

  useEffect(() => {
    if (!multiple) {
      closeMenu();
      inputRef.current?.blur();
    }
  }, [closeMenu, multiple, selectedItems]);

  const isSelected = (value: string) => {
    return selectedItems.includes(value);
  };

  // Show the select all option?
  const showAll = multiple && inputValue === '';

  const placeholderText =
    selectedItems.length === 1
      ? options.find(({ value }) => value === selectedItems[0])?.label
      : selectedItems.length
      ? props.filter
        ? `${props.label} • ${selectedItems.length}`
        : `${selectedItems.length} selected`
      : '';

  const buttons = multiple && (
    <li className='flex border-t p-1.5 pb-0 text-xs'>
      <button
        type='button'
        className='block flex-1 p-2 text-left uppercase'
        onClick={() => {
          setSelectedItems(props.value);
          setCancelling(true);
        }}
      >
        {t('cancel')}
      </button>
      <button
        type='button'
        className='block flex-1 p-2 text-right uppercase'
        onClick={closeMenu}
      >
        {t('ok')}
      </button>
    </li>
  );

  return (
    <div className={props.className}>
      {_name && (
        <select className='hidden' name={_name} multiple>
          {props.value.map((v) => (
            <option key={v} value={v} selected />
          ))}
        </select>
      )}
      {/* <label {...getLabelProps()}>Choose an element:</label> */}
      <div>
        {props.floatingInput ? (
          // @ts-expect-error FIXME unable to infer correct onChange type
          <FloatingInput
            disabled={disabled}
            type='text'
            label={props.label}
            placeholder={props.placeholder}
            {...inputProps}
            {...getInputProps({
              ref: inputRef,
              value: isOpen ? inputValue : placeholderText,
              onFocus: () => {
                if (!isOpen) {
                  openMenu();
                }
              },
              //* Work around for Chrome always displaying raised labels
              ...(() =>
                placeholderText ? { placeholder: placeholderText } : {})(),
            })}
          />
        ) : (
          // @ts-expect-error FIXME unable to infer correct onChange type
          <Input
            disabled={disabled}
            type='text'
            filter={props.filter}
            filtered={props.filter && selectedItems.length > 0 && true}
            label={props.label}
            placeholder={props.placeholder}
            icon={
              hasSelectorIcon && <SelectorIcon className='w-5 cursor-pointer' />
            }
            required={required}
            {...inputProps}
            {...getInputProps({
              ref: inputRef,
              value: isOpen ? inputValue : placeholderText,
              onFocus: () => {
                if (!isOpen) {
                  openMenu();
                }
              },
              //* Work around for Chrome always displaying raised labels
              ...(() =>
                placeholderText ? { placeholder: placeholderText } : {})(),
            })}
          />
        )}

        {/* <button
          type='button'
          {...getToggleButtonProps()}
          aria-label='toggle menu'
        >
          &#8595;
        </button> */}
      </div>
      <div className={props.filter ? '' : 'relative'} {...getMenuProps()}>
        {isOpen && inputItems.length > 0 && (
          <div
            className={classNames(
              'z-50 min-w-[20rem] max-w-[365px] overflow-hidden rounded border border-grey-10 bg-white py-1.5 shadow-md lg:-mt-2',
              floatingOptions && 'absolute'
            )}
          >
            <ul className='max-h-64 overflow-y-auto pr-2'>
              {inputItems.map((item, index) =>
                item.value === ALL && !showAll ? null : (
                  <li
                    key={item.value}
                    className={classNames(
                      'flex cursor-default items-center gap-1 whitespace-nowrap p-1.5 hover:bg-brand hover:text-white',
                      {
                        'bg-brand-ghost': isSelected(item.value),
                      }
                    )}
                    {...getItemProps({
                      item,
                      index,
                    })}
                  >
                    {multiple && (
                      <>
                        <input
                          className={classNames(
                            'z-30 mx-1 h-4 w-4 cursor-pointer accent-brand',
                            {
                              hidden:
                                item.value === ALL &&
                                selectedItems.length === props.options.length,
                            }
                          )}
                          type='checkbox'
                          checked={
                            item.value === ALL
                              ? selectedItems.length === props.options.length
                              : isSelected(item.value)
                          }
                        />
                        {selectedItems.length === props.options.length &&
                          item.value === ALL && (
                            <label
                              className={classNames(
                                'mx-1 flex h-4 w-4 cursor-pointer items-center justify-center rounded-[2px] border border-solid border-white bg-brand pb-[3px] text-xl text-white',
                                {
                                  'rounded-none': true,
                                }
                              )}
                              htmlFor='box'
                            >
                              -
                            </label>
                          )}
                      </>
                    )}
                    <p className='truncate'>
                      {item.label}
                      {item.$count ? ` (${item.$count})` : null}
                    </p>
                  </li>
                )
              )}
            </ul>
            {buttons}
          </div>
        )}
      </div>
    </div>
  );
}
