import { Can } from './Can';
import { Forbidden } from './Forbidden';

type Props = {
  children: React.ReactNode;
  action: string;
  subject: string;
};

export function AuthGate({ children, action, subject }: Props) {
  return (
    <>
      <Can do={action} on={subject}>
        {children}
      </Can>
      <Can not do={action} on={subject}>
        <Forbidden />
      </Can>
    </>
  );
}
