/**
 * Used for filtering a collection by query on given fields
 * e.g. [{ name: 'John', age: 20 }, { name: 'Jane', age: 30 }].filter(by('jo', ['name']))
 */
export function by<T>(query: string, fields: (keyof T)[]) {
  return (obj: T) =>
    fields.some((field) => {
      const value = obj[field];
      return (
        typeof value === 'string' &&
        value
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      );
    });
}

export function notUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value != null;
}
