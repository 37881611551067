import { useState } from 'react';
import { useAppContext } from '~/App';

export function useUppy(kind: 'file' | 'image') {
  const uppy = useAppContext().uppy.get(kind);

  // Open the uppy dashboard modal?
  const [open, setOpen] = useState(false);

  // Keep the open state in sync with the uppy dashboard modal
  uppy.once('dashboard:modal-closed', () => {
    setOpen(false);
  });

  return { uppy, open, setOpen: (b: boolean = true) => setOpen(b) };
}
