import { useFormikContext } from 'formik';
import { remove } from '~/helpers/array';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo } from 'react';
import { ComboboxField } from '../ui/Combobox';

const VARIANTS_MAX = 4;

type Keyed = { key: string; name: string; value: string };

type FormValues = {
  hasVariants: boolean;
  attributes: Keyed[];
};

export const VariantAttributesField = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const value = useMemo(() => values.attributes, [values.attributes]);

  const setVariants = useCallback(
    (variants: Keyed[]) => {
      setFieldValue('attributes', variants);
    },
    [setFieldValue]
  );

  // Check if variants should be modified
  useEffect(() => {
    const removeIndex = value
      .slice(0, -1)
      .findIndex((variant) => variant.name === '');
    if (removeIndex > -1) {
      // If a variant type is empty and it's not the last variant, remove it
      setVariants(remove(value, removeIndex));
    } else if (
      // If no variant is empty append an empty one up to max
      value.length < VARIANTS_MAX &&
      value.every((variant) => variant.name !== '')
    ) {
      setVariants([...value, { key: nanoid(), name: '', value: '' }]);
    }
  }, [value, setVariants]);

  return (
    <>
      {value.map(({ key }, i) => {
        return (
          <div key={key ?? '_new_'} className='grid grid-cols-2 gap-4'>
            <ComboboxField
              name={`attributes.${i}.name`}
              label={'Variant Type'}
              items={[]}
            />
            <ComboboxField
              name={`attributes.${i}.value`}
              label={'Value'}
              items={[]}
            />
          </div>
        );
      })}
    </>
  );
};
