import { SVGProps } from 'react'

const SvgLogoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 446.686 145.622'
    {...props}
  >
    <g transform='translate(-698.703 -527.035)'>
      <path
        d='M210.5,238.981l-37.4,20.762a8.092,8.092,0,0,0-.14,14.069l13.312,7.74a4.836,4.836,0,0,1,2.407,4.184v8.739a3.339,3.339,0,0,1-4.97,2.913l-49.031-27.475a3.444,3.444,0,0,1,.008-6.013l76.876-42.795a15.21,15.21,0,1,0-14.811-26.57L95.791,250.895a18.291,18.291,0,0,0,.073,31.983l96.04,53.043a18.291,18.291,0,0,0,27.135-15.963l.2-75.818a5.886,5.886,0,0,0-8.744-5.159'
        transform='translate(612.289 334.43)'
        fill='#fff'
      />
      <path
        d='M764.395,290.06a11.36,11.36,0,0,0-5.668-1.4q-8.944,0-8.943,11.283v8.215h12.479v7.279H749.784V361.4h-8.475V315.434h-9.1v-7.279h9.1v-8.63q0-8.37,4.835-13.233a16.324,16.324,0,0,1,12.063-4.861,16.734,16.734,0,0,1,6.188.936ZM809.526,361.4H801v-8.318h-.208q-5.563,9.568-16.378,9.566-7.954,0-12.453-4.211a14.592,14.592,0,0,1-4.5-11.178q0-14.924,17.574-17.366L801,327.655q0-13.572-10.97-13.57a26.213,26.213,0,0,0-17.366,6.551V311.9a32.988,32.988,0,0,1,18.094-4.992q18.771,0,18.77,19.861ZM801,334.464l-12.842,1.767q-5.927.833-8.943,2.937t-3.015,7.461a8.127,8.127,0,0,0,2.781,6.37,10.747,10.747,0,0,0,7.41,2.469,13.687,13.687,0,0,0,10.476-4.445A15.883,15.883,0,0,0,801,339.764Zm60.886,24.49a27.72,27.72,0,0,1-14.558,3.691q-11.388,0-18.381-7.408t-6.993-19.211q0-13.152,7.539-21.136t20.121-7.981a28,28,0,0,1,12.374,2.6v8.736a21.685,21.685,0,0,0-12.687-4.159,17.146,17.146,0,0,0-13.388,5.849q-5.225,5.848-5.226,15.365,0,9.358,4.913,14.766t13.181,5.408a21.365,21.365,0,0,0,13.1-4.627v8.108Zm37.592,1.924a16.45,16.45,0,0,1-7.955,1.664q-13.989,0-13.985-15.6V315.434h-9.151v-7.279h9.151v-13l8.528-2.755v15.754H899.48v7.279H886.065v30q0,5.356,1.819,7.644t6.03,2.287a8.96,8.96,0,0,0,5.563-1.767Zm33.641,1.767q-11.8,0-18.849-7.461T907.225,335.4q0-13.415,7.332-20.954t19.811-7.539q11.906,0,18.588,7.332t6.682,20.331q0,12.739-7.2,20.407t-19.319,7.668Zm.623-48.562q-8.217,0-13,5.589t-4.784,15.415q0,9.462,4.834,14.923t12.947,5.46q8.268,0,12.713-5.355t4.445-15.234q0-9.984-4.445-15.391t-12.71-5.407Zm67.281,2.7a10.429,10.429,0,0,0-6.448-1.716q-5.461,0-9.125,5.147t-3.665,14.038V361.4h-8.528V308.152h8.528v10.97h.207a18.616,18.616,0,0,1,5.563-8.762,12.685,12.685,0,0,1,8.37-3.147,13.917,13.917,0,0,1,5.095.728v8.843Z'
        transform='translate(144.366 277.81)'
        fill='#fff'
      />
    </g>
  </svg>
)

export default SvgLogoWhite

