import { JobQuery } from '~/gql/graphql';
import { MoveStockDestination } from './MoveStockDestination';
import { MoveStockOrigin } from './MoveStockOrigin';

type Job = NonNullable<JobQuery['job']>;
type Task = Job['tasks'][0];

function isStockTransferOrigin(job: Job, task: Task) {
  return job.isSource;
}

type Props = {
  enabled: boolean;
  job: Job;
  onSuccess: () => void;
  onClose: () => void;
};

export const MoveStock = ({ enabled, job, onSuccess, onClose }: Props) => {
  // TODO support multiple transfers on same job?
  // @ts-expect-error Refactor the masked fragment type
  const task = job.tasks.find((task) => Boolean(task.stockTransfer));

  if (!task) {
    return <div></div>;
  }

  const MoveStockComponent = isStockTransferOrigin(job, task)
    ? MoveStockOrigin
    : MoveStockDestination;

  return (
    <MoveStockComponent job={job} onSuccess={onSuccess} onClose={onClose} />
  );
};
