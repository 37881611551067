import { useField } from 'formik';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '~/components/form/TextField';
import { SkuInfo } from '~/components/smc/SkuInfo';
import { Card } from '~/components/ui/Card';
import { Hero } from '~/components/ui/Card/Hero';
import { StickyHeading } from '~/components/ui/StickyHeading';
import { JobQuery, StockSelection, TaskQuery } from '~/gql/graphql';
import { remove } from '~/helpers/array';
import { stop } from '~/helpers/event';
import { FocusElement, Group } from '../../../ui/FocusElement';

type Props = {
  job: NonNullable<JobQuery['job']>;
  task: TaskQuery['task'];
};

type Transfer = { selected: StockSelection[] };

export function TransferTaskField({ job, task }: Props) {
  const { t } = useTranslation();
  const [field, , helpers] = useField<Transfer>('transfer');

  const localValue = field.value.selected.reduce((prev, curr) => {
    return { ...prev, [curr.id]: curr.qty };
  }, {} as Record<string, number | undefined>);

  const setSelected = (selected: StockSelection[]) => {
    helpers.setValue({ ...field.value, selected });
  };

  const handleChange =
    (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const val = event.currentTarget.value;
      if (val === undefined) {
        return;
      }
      const { selected } = field.value;
      const idx = selected.findIndex((s) => s.id === id);
      const newSelected = idx > -1 ? remove(selected, idx) : selected;

      if (val === '') {
        setSelected(newSelected);
      } else {
        setSelected([...newSelected, { id, qty: parseInt(val) || 0 }]);
      }
    };

  let lastSpace = '';

  return (
    <Group>
      {task.transfer?.stockOnHand.map((item) => {
        const id = [item.space.id, item.sku.id].join(':');
        const entered = localValue[id];
        const target = item.target ?? null;
        const missing =
          target == null
            ? null
            : entered == null
            ? Math.max(0, target - item.soh)
            : Math.max(0, target - entered);

        const spaceHeading =
          lastSpace === item.space.id ? null : (
            <StickyHeading>{item.space.name}</StickyHeading>
          );
        lastSpace = item.space.id;

        const color = entered == null ? 'smoke' : 'green';

        return (
          <React.Fragment key={item.id}>
            {spaceHeading}
            <FocusElement id={item.id}>
              {(focus, close) => (
                <Card className='flex p-4' color={color} shadow>
                  <div className='flex flex-1 flex-col justify-between'>
                    <SkuInfo {...item.sku} />
                    {focus && (
                      <p>
                        Available:{' '}
                        {
                          task.transfer?.available.find(
                            (i) => i.id === item.sku.id
                          )?.qty
                        }
                      </p>
                    )}
                  </div>

                  {focus ? (
                    <div className='w-24 text-right'>
                      <CardInput
                        target={target}
                        missing={missing}
                        value={localValue[id] ?? item.soh}
                        onBlur={close}
                        onChange={handleChange(id)}
                      />
                    </div>
                  ) : entered != null ? (
                    <Hero
                      number={`${entered - item.soh}`}
                      directive={t('transferred')}
                    />
                  ) : (
                    <Hero number={missing ?? '-'} directive={t('missing')} />
                  )}
                </Card>
              )}
            </FocusElement>
          </React.Fragment>
        );
      })}
    </Group>
  );
}

type CardInputProps = {
  target: number | null;
  missing: number | null;
  value: number;
  onBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
  onChange: React.FormEventHandler<HTMLInputElement> | undefined;
};

function CardInput({
  target,
  missing,
  value,
  onBlur,
  onChange,
}: CardInputProps) {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col gap-4'>
      <Input
        flow='condensed'
        tabIndex={-1}
        label={t('target')}
        align='right'
        value={target ?? '-'}
        disabled
      />
      <Input
        flow='condensed'
        // data-focusonmount='1'
        tabIndex={0}
        label={t('onHand')}
        type='number'
        min='0'
        inputMode='decimal'
        align='right'
        value={value}
        onChange={onChange}
        onFocus={(event) => {
          event.currentTarget?.select && event.currentTarget.select();
        }}
        onBlur={onBlur}
        onMouseDown={stop}
      />
      <Input
        flow='condensed'
        tabIndex={-1}
        label={t('missing')}
        align='right'
        value={missing ?? '-'}
        disabled
      />
    </div>
  );
}
