import { post } from '~/helpers/fetch';

/*
 * Asset Config
 */

export const createSpaces = (data: any) =>
  post('/api/asset-config/add-spaces', data);

export const modifyProperties = (strategy: 'queue' | 'commit') => (data: any) =>
  post('/api/asset-config/modify-properties', { ...data, strategy });

export const spaceSkuAdd = (strategy: 'queue' | 'commit') => (data: any) =>
  post('/api/asset-config/queue-space-sku-add', { ...data, strategy });

export const spaceSkuRemove = (strategy: 'queue' | 'commit') => (data: any) =>
  post('/api/asset-config/queue-space-sku-remove', { ...data, strategy });

export const unqueueChanges = (data: any) =>
  post('/api/asset-config/unqueue-changes', data);

export const commitChanges = (data: any) =>
  post('/api/asset-config/commit-changes', data);

export const cloneSpaces = (data: any) =>
  post('/api/asset-config/clone-spaces', data);
