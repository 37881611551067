import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from '~/components/ui/Menu';

type KebabNavProps = {
  pages: {
    title: string;
    url: string;
  }[];
};

export const KebabNav = ({ pages }: KebabNavProps) => {
  const { t } = useTranslation();

  return (
    <Menu>
      {pages.map(({ title, url }) => {
        return (
          <Menu.Item key={title} as={Link} to={url}>
            {t(title)}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
