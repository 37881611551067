import { isFocusedAtom, useAtom } from '~/atom';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useGroupContext } from './Group';

type Props = {
  id: string;
  children:
    | React.ReactNode
    | ((focus: boolean, toggle: () => void) => React.ReactNode);
};

const siblingSelector =
  (property: 'previousElementSibling' | 'nextElementSibling') =>
  (el: HTMLElement | null, selectors: string) => {
    if (!el) {
      return;
    }
    let sibling = el[property];
    while (sibling != null) {
      if (sibling.matches(selectors)) {
        return sibling;
      }
      sibling = sibling[property];
    }
  };

const prevSelector = siblingSelector('previousElementSibling');
const nextSelector = siblingSelector('nextElementSibling');

export function FocusElement({ id, children }: Props) {
  const debug = false;
  const [state, setState] = useGroupContext('FocusElement');
  const focus = state === id;
  const setFocus = (focused: boolean) => setState(focused ? id : null);

  // Handle a global state for any focused stock selection
  const [, setIsFocused] = useAtom(isFocusedAtom);
  useEffect(() => setIsFocused(focus), [focus, setIsFocused]);

  // Scroll to effect
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!focus) return;

    setTimeout(
      () => {
        const scrollEl = ref.current?.closest('[data-autoscroll="1"]');

        if (scrollEl && ref.current) {
          const parentY = scrollEl.getBoundingClientRect().top;
          const pos = scrollEl.scrollTop;

          scrollEl.scrollTop =
            -50 + pos + ref.current.getBoundingClientRect().top - parentY;
        }
      },
      // ! Think this is needed to delay scroll until after a transition timing?
      // would prefer not to have to do this as it is quite jarring to the user
      101
    );
  }, [focus]);

  const handleFocus: React.FocusEventHandler<HTMLDivElement> = (event) => {
    setFocus(true);

    setTimeout(() => {
      const el = document.querySelector<HTMLInputElement>(
        'input[data-focusonmount="1"]'
      );

      if (el) {
        el.focus();
      }
    }, 0);
  };

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (focus && ref.current) {
      ref.current.dataset.shouldClose = 'true';
    }
  };

  const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (ref.current?.dataset.shouldClose === 'true') {
      setFocus(false);
      delete ref.current?.dataset.shouldClose;
    }
  };

  return (
    <div className='relative my-4 px-4'>
      <input
        className={classNames(
          'absolute top-0 left-4 z-50 h-4 w-4 rounded-full bg-red-500 focus:bg-green-500',
          { 'cursor-pointer opacity-0': !debug }
        )}
        type='number'
        inputMode='decimal'
        onFocus={(event) => {
          const prev = prevSelector(event.target.parentElement, 'div.relative');
          if (!prev) {
            event.target.blur();
            return;
          }
          prev.querySelector<HTMLDivElement>('[data-focuselement="1"')?.focus();
        }}
      />
      <div
        ref={ref}
        data-focuselement='1'
        className='js-card'
        tabIndex={/* readOnly ||  */ focus ? undefined : 0}
        onFocus={handleFocus}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {typeof children === 'function'
          ? children(focus, () => setFocus(false))
          : children}
      </div>
      <input
        className={classNames(
          'absolute bottom-0 right-4 z-50 h-4 w-4 rounded-full bg-red-500 focus:bg-green-500',
          { 'cursor-pointer opacity-0': !debug }
        )}
        type='number'
        inputMode='decimal'
        onFocus={(event) => {
          const next = nextSelector(event.target.parentElement, 'div.relative');
          next
            ?.querySelector<HTMLDivElement>('[data-focuselement="1"')
            ?.focus();
        }}
      />
    </div>
  );
}
