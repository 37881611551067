import { useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import { Table } from '~/components/table/Table';
import { formatCurrency } from '~/helpers/formatCurrency';
import { ConfigProps } from '~/routes/asset-config';
import { AssetConfig } from '~/types';

const getQueuedColor = (queuedType: 'Add' | 'Remove') => {
  switch (queuedType) {
    case 'Add':
      return '#00A22B';
    case 'Remove':
      return '#F20505';
    default:
      return undefined;
  }
};

const formatAssetCell = ({ row }: Cell<AssetConfig>) => {
  const { asset_name, location_name } = row.original;
  const displayLocation = location_name ? ` - ${location_name}` : '';

  return (
    <div
      style={{
        maxHeight: 30,
        overflow: 'hidden',
      }}
    >
      {asset_name}
      {displayLocation}
    </div>
  );
};

const formatLocationCell =
  (t: TFunction) =>
  ({ row }: Cell<AssetConfig>) => {
    const { asset_id, asset_name } = row.original;
    return (
      <>
        <div className='font-semibold'>{asset_name}</div>
        <div className='text-copy-alt'>
          {t('type.' + asset_id.split(':')[0])}
        </div>
      </>
    );
  };

const formatSkuCell =
  (t: TFunction) =>
  ({ row }: Cell<AssetConfig>) => {
    const { item_type, sku_id, sku_code, sku_name, queued_type } = row.original;

    if (!sku_id) {
      return <em>empty space</em>;
    }

    return (
      <>
        <div className='font-semibold'>
          {sku_name}
          {queued_type && (
            <span
              style={{ fontWeight: 600, color: getQueuedColor(queued_type) }}
            >
              {' '}
              &#8226; To {queued_type}
            </span>
          )}
        </div>
        <div className='text-copy-alt'>{t('itemType.' + item_type)}</div>
        {sku_code && <div className='text-copy-alt'>{sku_code}</div>}
      </>
    );
  };

const formatIntegerCell = ({ value }: Cell<AssetConfig, number | null>) => {
  return value ? value.toLocaleString() : '';
};

function getQueuedColumnId(id: string): keyof AssetConfig | undefined {
  switch (id) {
    case 'price':
      return 'queued_price';
    case 'capacity':
      return 'queued_capacity';
    case 'target':
      return 'queued_target';
  }
}

/**
 * Display cell content for a queueable property
 * @param {Object} args react-table cell arguments
 */
const formatQueuableCell = ({ column, row, value }: Cell<AssetConfig>) => {
  const key = getQueuedColumnId(column.id);

  const formattedValue =
    value || value === 0
      ? column.id === 'price'
        ? formatCurrency(value)
        : value.toLocaleString()
      : '';
  const isAdd = row.original.queued_type === 'Add';
  const queued = key ? row.original[key] : undefined;

  if (!isAdd && queued !== undefined) {
    const formattedQueued =
      queued === null
        ? '—'
        : typeof queued === 'number' && column.id === 'price'
        ? formatCurrency(queued)
        : queued.toLocaleString();

    return (
      <div style={{ position: 'relative', left: '.5rem' }}>
        <div
          style={{
            height: 30,
            padding: '0 .5rem',
            lineHeight: '30px',
            fontWeight: 600,
            background: 'var(--color-red10)',
            color: '#F20505',
          }}
        >
          {formattedValue || '—'}
        </div>
        <div
          style={{
            height: 30,
            padding: '0 .5rem',
            lineHeight: '30px',
            fontWeight: 600,
            background: 'var(--color-green10)',
            color: '#00A22B',
          }}
        >
          {formattedQueued}
        </div>
      </div>
    );
  }

  return formattedValue;
};

type Props = {
  locationType: ConfigProps['locationType'];
  data: any[];
  selected: any;
  setSelected: any;
};

export const AssetConfigTable = ({
  locationType,
  data,
  selected,
  setSelected,
}: Props) => {
  const { t } = useTranslation();

  // If true include target column, false include capacity column
  const [showTarget /* setCapacityTarget */] = useState(true);

  const locationColumns = useMemo(() => {
    switch (locationType) {
      case 'Asset':
        return [
          {
            Header: t('Asset'),
            accessor: 'asset_name',
            Cell: formatAssetCell,
            width: 120,
          },
        ];
      case 'Site':
        return [
          {
            Header: t('siteType'),
            accessor: 'site_type',
            width: 80,
          },
          {
            Header: t('siteName'),
            accessor: 'asset_name',
            width: 120,
          },
        ];
      default: {
        return [
          {
            Header: t('location'),
            accessor: 'asset_name',
            Cell: formatLocationCell(t),
            width: 120,
          },
        ];
      }
    }
  }, [locationType, t]);
  const columns = useMemo(
    () => [
      ...locationColumns,
      {
        Header: t('space'),
        accessor: 'space_name',
        width: 60,
      },
      {
        Header: t('itemName'),
        accessor: 'sku_name',
        Cell: formatSkuCell(t),
        width: 300,
      },
      // {
      //   Header: t('itemTypeLabel'),
      //   accessor: 'item_type',
      //   Cell: ({ row }: Cell<AssetConfig>) => {
      //     return row.original.item_type
      //       ? t('itemType.' + row.original.item_type)
      //       : '';
      //   },
      //   width: 120,
      // },
      {
        Header: t('price'),
        accessor: 'price',
        align: 'right',
        Cell: formatQueuableCell,
        width: 60,
      },
      ...(() => {
        if (showTarget) {
          return [
            {
              Header: t('target'),
              accessor: 'target',
              align: 'right',
              Cell: formatQueuableCell,
              width: 60,
            },
          ];
        }
        return [
          {
            Header: t('capacity'),
            accessor: 'capacity',
            align: 'right',
            Cell: formatQueuableCell,
            width: 60,
          },
        ];
      })(),
      // {
      //   Header: 'Empty',
      //   accessor: 'empty',
      //   align: 'right',
      //   Cell: formatIntegerCell,
      //   width: 60
      // },
      {
        Header: t('On Hand'),
        accessor: 'soh',
        align: 'right',
        Cell: formatIntegerCell,
        width: 60,
      },
      {
        Header: t('missing'),
        accessor: showTarget ? 'missing' : 'missing_capacity',
        align: 'right',
        Cell: formatIntegerCell,
        width: 60,
      },
      {
        Header: t('reorderAt'),
        accessor: 'reorder_level',
        align: 'right',
        Cell: formatIntegerCell,
        width: 60,
      },
    ],
    [t, locationColumns, showTarget]
  );

  const sortBy = useMemo(
    () => [
      { id: 'asset_name' },
      { id: 'space_name' },
      { id: 'item_type', desc: true },
      { id: 'sku_code' },
      { id: 'status', desc: true },
    ],
    []
  );

  const handleRowSelect = (selectedRowPaths: any) => {
    setSelected(selectedRowPaths);
  };

  return (
    <Table
      columns={columns}
      data={data}
      initialSelectedRowIds={selected}
      initialSortBy={sortBy}
      onRowSelect={handleRowSelect}
      style={{ flexGrow: 1 }}
    />
  );
};
