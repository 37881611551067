import { faFile, faFilePdf, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Kind } from '../upload2';

type Props = {
  kind: Kind;
  url: string;
  name?: string;
  onRemove?: () => void;
};

export function Thumb({ kind, url, name, onRemove }: Props) {
  // If the kind is "file" url should be an empty string for new files that haven't been saved yet
  // For images this does not apply because previews can be rendered as local blobs (data uris)
  // As a result, url is only required for images
  if (kind === 'image' && !url) {
    console.warn('No image URL provided for thumb');
    return null;
  }

  return (
    <div className='relative'>
      <p className='absolute -left-2 -top-2 w-8'>
        <button
          type='button'
          className='h-8 w-8 rounded-full bg-brand text-xl text-white shadow'
          onClick={onRemove}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </p>
      <p className='flex h-[130px] flex-col items-center justify-center gap-2 overflow-hidden rounded-xl bg-shade-10'>
        {kind === 'image' ? (
          <img src={url} alt='' />
        ) : (
          <>
            <FontAwesomeIcon
              icon={name?.endsWith('.pdf') ? faFilePdf : faFile}
              className='h-10 w-10'
            />
            <span
              className={classNames('text-center leading-5', !name && 'italic')}
            >
              {name ?? 'Unknown'}
            </span>
          </>
        )}
      </p>
    </div>
  );
}
