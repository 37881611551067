import { Formik } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PromptEffect } from '~/components/Prompt';
import { AddressInputGroup } from '~/components/form/AddressInputGroup';
import { StatusToggle } from '~/components/form/StatusToggle';
import { SubmitButton } from '~/components/form/SubmitButton';
import { TextField } from '~/components/form/TextField';
import { AttributesField } from '~/components/form/attributes/AttributesField';
import { SelectField } from '~/components/form/downshift/SelectField';
import { Loading } from '~/components/ui/Loading';
import { UploadField } from '~/components/upload/Upload';
import {
  Attribute,
  ContactInput,
  ContactRelationship,
  ContactType,
  Status,
  useContactQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
} from '~/generated/graphql';
import { parseJson } from '~/helpers';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useContactListContext } from '../ContactListContext';
import { ContactTypeField } from './ContactTypeField';
import { OrganisationField } from './OrganisationField';

export const relationshipOptions = (t: TFunction) => [
  { value: ContactRelationship.Staff, label: t('contactRelationships.Staff') },
  {
    value: ContactRelationship.ServiceProvider,
    label: t('contactRelationships.ServiceProvider'),
  },
  {
    value: ContactRelationship.Supplier,
    label: t('contactRelationships.Supplier'),
  },
  {
    value: ContactRelationship.Customer,
    label: t('contactRelationships.Customer'),
  },
  { value: ContactRelationship.Other, label: t('contactRelationships.Other') },
];

type Relationship = ContactRelationship;

export type FormData = {
  status: Status;
  type: ContactType;
  name: string;
  organisationId: string[];
  relationship: Relationship[];
  streetAddress: any;
  postalAddress: any;
  email: string;
  phone: string;
  attributes: Attribute[];
  images: string[];
  notes: string;
};

type Props = {
  title: string;
};

export const ContactForm = ({ title }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { contactId } = useParams();
  const { refresh } = useContactListContext();
  const [result] = useContactQuery({
    variables: { id: contactId! },
    pause: !contactId,
  });

  const contact = result.data?.contact;
  const [, create] = useCreateContactMutation();
  const [, update] = useUpdateContactMutation();

  if (contactId && !contact) return <Loading />;

  const initialValues: FormData = {
    status: contact?.status ?? Status.Active,
    type: contact?.type ?? ContactType.Person,
    name: contact?.name ?? '',
    organisationId: contact?.organisation ? [contact?.organisation.id] : [],
    relationship: (contact?.relationship as Relationship[]) ?? [],
    streetAddress: contact?.streetAddress
      ? parseJson(contact.streetAddress)
      : null,
    postalAddress: contact?.postalAddress
      ? parseJson(contact.postalAddress)
      : null,
    email: contact?.email ?? '',
    phone: contact?.phone ?? '',
    // @ts-expect-error FIXME
    attributes: contact?.attributes
      ? contact.attributes.map(({ name, value }) => ({
          name,
          value,
        }))
      : [],
    images: contact?.images ?? [],
    notes: contact?.notes ?? '',
  };

  const handleCancel = () => {
    navigate(contactId ? `/contacts/${contactId}` : '/contacts');
  };

  const handleSubmit = async (values: FormData) => {
    const input: ContactInput = {
      ...values,
      organisationId: values.organisationId[0],
      relationship: values.relationship,
      streetAddress: JSON.stringify(values.streetAddress),
      postalAddress: JSON.stringify(values.postalAddress),
    };

    async function doCreate() {
      const { data, error } = await create({ input });
      return { contactData: data?.createContact, error };
    }

    async function doUpdate(id: string) {
      const { data, error } = await update({ id, input });
      return { contactData: data?.updateContact, error };
    }
    const { contactData, error } = contact
      ? await doUpdate(contact.id)
      : await doCreate();

    if (error) {
      throw error;
    }

    if (contactData) {
      if (refresh) refresh();
      navigate(`/contacts/${contactData.id}`);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <SideLayout>
        <PromptEffect />
        <SideLayout.Head onClose={handleCancel}>{title}</SideLayout.Head>
        <SideLayout.Body>
          <StatusToggle />
          <ContactTypeField />
          <TextField className='mb-5 pt-2' name='name' label={t('name')} />
          <OrganisationField />
          <SelectField
            name='relationship'
            label={t('relationship')}
            options={relationshipOptions(t)}
            multiple
          />
          <TextField name='phone' label={t('phoneNumber')} type='phone' />
          <TextField name='email' label={t('emailAddress')} type='email' />
          <AddressInputGroup name='streetAddress' label={t('streetAddress')} />
          <AddressInputGroup name='postalAddress' label={t('postalAddress')} />
          <TextField name='notes' label={t('notes')} type='textarea' />
          <AttributesField type='Contact' />
          <UploadField name='images' />
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
};
