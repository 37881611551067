import { ChevronRightIcon } from '@heroicons/react/outline';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '~/App';
import { CurrentAvatar } from '~/components/ui/Avatar';
import { useLocationHash } from '~/hooks/useLocationHash';
import { AccountProfile } from './AccountProfile';
import { NotificationsForm } from './NotificationsForm';
import { PersonalInfoForm } from './PersonalInfoForm';
import { SettingsSubpage } from './SettingsSubpage';
import { Team } from './Team';
import { UpdateEmailForm } from './UpdateEmailForm';
import { UpdatePasswordForm } from './UpdatePasswordForm';
import { UpdateProfilePicForm } from './UpdateProfilePicForm';
import { UserSettingsPopup } from './UserSettingsPopup';

export type Page =
  | null
  | 'personal'
  | 'profilePicture'
  | 'email'
  | 'password'
  | 'organisation'
  | 'notifications'
  | 'team';

type SettingPage = {
  name: string;
  description: string;
  page: Page;
  Component: (props: PageProps) => JSX.Element;
};

type SettingLink = {
  name: string;
  description: string;
  linkTo: string;
};

type SettingGroup = {
  title: string;
  pages: (SettingPage | SettingLink)[];
};

const isLink = (page: SettingPage | SettingLink): page is SettingLink =>
  'linkTo' in page;

const settings: SettingGroup[] = [
  {
    title: 'settings.aboutYou',
    pages: [
      {
        name: 'Personal Information',
        description: 'Name & Phone Number',
        page: 'personal',
        Component: PersonalInfoForm,
      },
      {
        name: 'Email',
        description: 'Update your Email',
        page: 'email',
        Component: UpdateEmailForm,
      },
      {
        name: 'Security',
        description: 'Update your Password',
        page: 'password',
        Component: UpdatePasswordForm,
      },
    ],
  },

  {
    title: 'settings.accountSettings',
    pages: [
      {
        name: 'Account Profile',
        description: `Account name, logo and industry`,
        page: 'organisation',
        Component: AccountProfile,
      },
      {
        name: 'Administrators',
        description: 'Grant another person access to your account',
        page: 'team',
        Component: Team,
      },
      {
        name: 'Integrations',
        description: 'Connect your account to other services',
        linkTo: '/integrations',
      },
    ],
  },
  {
    title: 'settings.appSettings',
    pages: [
      {
        name: 'Notifications',
        description: 'Change your notification settings',
        page: 'notifications',
        Component: NotificationsForm,
      },
    ],
  },
];

export const UserSettings = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const [showSettings, setShowSettings] = useLocationHash('#settings-open');
  const [showPage, setShowPage] = useState<Page>(
    hash.endsWith(':email')
      ? 'email'
      : hash.endsWith(':notifications')
      ? 'notifications'
      : null
  );
  const { user: currentUser } = useContext(AppContext);

  const pageProps = {
    backTo: () => setShowPage(null),
    showPage,
    setShowPage,
  };

  const getPageProps = (page: SettingPage) => ({ page, ...pageProps });

  return (
    <>
      <UserSettingsPopup
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
      >
        <div className='flex flex-col items-center p-8 pt-0 lg:p-12 lg:pt-0'>
          <div
            className='h-24 w-24'
            onClick={() => setShowPage('profilePicture')}
          >
            <CurrentAvatar className='cursor-pointer' size='full' />
          </div>
          <SettingsSubpage
            title='Edit Profile Picture'
            isOpen={showPage === 'profilePicture' ? true : false}
            backTo={() => {
              setShowPage(null);
            }}
            form={<UpdateProfilePicForm {...pageProps} />}
          />
          <p className='pt-4 text-greyhound'>{currentUser?.name}</p>

          {settings.map((group) => (
            <Fragment key={group.title}>
              <Group title={t(group.title)} />
              {group.pages.map((page) =>
                isLink(page) ? (
                  <Link key={page.name} {...page} />
                ) : (
                  <Page key={page.name} {...getPageProps(page)} />
                )
              )}
            </Fragment>
          ))}
        </div>
      </UserSettingsPopup>
    </>
  );
};

type TabProps = {
  title: string;
  description: string;
  onClick?: any;
};

const Tab = ({ title, description, onClick }: TabProps) => (
  <div
    onClick={onClick}
    className='-mt-[1px] flex w-full cursor-pointer items-center justify-between border border-gray88 bg-white px-6 py-2'
  >
    <div className='flex flex-col items-start justify-center'>
      <h3 className='self-start text-sm font-bold text-copy-head'>{title}</h3>
      <p className='pb-0.5 text-left text-xs text-copy-muted opacity-80'>
        {description}
      </p>
    </div>
    <div className='w-8'>
      <ChevronRightIcon className='ml-auto w-5 text-copy-head' />
    </div>
  </div>
);

type GroupProps = {
  title: string;
};

function Group({ title }: GroupProps) {
  return (
    <h2 className='self-start pb-3 pt-6 font-bold text-copy-head'>{title}</h2>
  );
}

export type PageProps = {
  page: SettingPage;
  showPage: Page;
  setShowPage: (page: Page) => void;
};

function Page(props: PageProps) {
  const { page, showPage, setShowPage } = props;
  return (
    <>
      <Tab
        title={page.name}
        description={page.description}
        onClick={() => setShowPage(page.page)}
      />
      <SettingsSubpage
        title={page.name}
        isOpen={showPage === page.page ? true : false}
        backTo={() => {
          setShowPage(null);
        }}
        form={<page.Component {...props} />}
      />
    </>
  );
}

function Link({ name, description, linkTo }: SettingLink) {
  const navigate = useNavigate();

  return (
    <Tab
      title={name}
      description={description}
      onClick={() => navigate(linkTo)}
    />
  );
}
