import { ErrorMessage } from '~/components/ui/Error';
import { useField } from 'formik';
import { useAttributesQuery } from '~/generated/graphql';
import { splice } from '~/helpers/array';
import { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IAttribute } from '~/types';
import { Pair } from './Pair';

type TemporaryValue = {
  name: string;
  value: string;
};

type Props = {
  type: string;
  value: TemporaryValue[];
  onChange: (newValue: TemporaryValue[]) => void;
};

type AttributeFieldProps = {
  /** Field name */
  name?: string;
} & Omit<Props, 'value' | 'onChange'>;

type TContext = { names: string[]; getValues: (name: string) => string[] };
const AttributesGroupContext = createContext<TContext>({} as TContext);
export const useAttributes = () => useContext(AttributesGroupContext);

export function AttributesInput({ type, value, onChange }: Props) {
  const { t } = useTranslation();
  const [result] = useAttributesQuery({
    requestPolicy: 'cache-and-network',
    variables: { entityType: type },
  });
  const { data, error } = result;

  const names = useMemo(() => {
    if (!data) return [];

    return data.attributes.map((a) => a.name);
  }, [data]);

  const getValues = (name: string) => {
    return data?.attributes.find((a) => a.name === name)?.value ?? [];
  };

  const handleChange = (i: number) => (pair: TemporaryValue) => {
    onChange(splice(value, i, 1, pair));
  };

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  return (
    <AttributesGroupContext.Provider value={{ names, getValues }}>
      <div className='mb-7 grid grid-cols-2 gap-x-1 gap-y-3'>
        <div className='text-grey-50 ml-1 -mb-2 text-sm font-medium'>
          {t('attribute')}
        </div>
        <div className='text-grey-50 ml-1 -mb-2 text-sm font-medium'>
          {t('value')}
        </div>

        {value.map((value, i) => {
          return <Pair key={i} value={value} onChange={handleChange(i)} />;
        })}
      </div>
    </AttributesGroupContext.Provider>
  );
}

export const AttributesField = ({
  name = 'attributes',
  type,
}: AttributeFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<IAttribute[]>(name);

  // Do not allow the form to initialize with null or undefined value
  if (!field.value) {
    throw new Error('AttributesField must be initialized');
  }

  const value = appendEmpty(removeEmpty(unmerge(field.value)));

  function handleChange(newValue: TemporaryValue[]) {
    helpers.setValue(merge(newValue));
  }

  return <AttributesInput type={type} value={value} onChange={handleChange} />;
};

export const ControlledAttributes = (props: {
  type: string;
  value: IAttribute[];
  onChange: (value: IAttribute[]) => void;
}) => {
  const value = appendEmpty(removeEmpty(unmerge(props.value)));

  function handleChange(newValue: TemporaryValue[]) {
    props.onChange(merge(newValue));
  }

  return (
    <AttributesInput type={props.type} value={value} onChange={handleChange} />
  );
};

/**
 * Combine single line items into multi-value options
 */
function merge(data: TemporaryValue[]): IAttribute[] {
  return data.reduce((prev: IAttribute[], curr) => {
    const idx = prev.findIndex(({ name }) => curr.name === name);

    return idx > -1
      ? splice(prev, idx, 1, {
          name: curr.name,
          value: [...prev[idx].value, curr.value],
        })
      : [...prev, { name: curr.name, value: [curr.value] }];
  }, []);
}

/**
 * Separate multi-value options into single line items
 */
function unmerge(attributes: IAttribute[]): TemporaryValue[] {
  return attributes.flatMap(({ name, value: values }) =>
    values.map((value) => ({
      name,
      value,
    }))
  );
}

function removeEmpty(attributes: TemporaryValue[]) {
  return attributes.filter(({ name, value }) => name || value);
}

function appendEmpty(attributes: TemporaryValue[]) {
  if (attributes.length === 0) {
    return [{ name: '', value: '' }];
  }
  const last = attributes[attributes.length - 1];
  if (last && last.name && last.value) {
    return [...attributes, { name: '', value: '' }];
  }
  return attributes;
}
