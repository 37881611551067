import { Tab } from '@headlessui/react';
import classNames from 'classnames';

type TabHeaderProps = {
  title: string;
};

export function TabHeader({ title }: TabHeaderProps) {
  return (
    <Tab
      className={({ selected }) =>
        classNames([
          'w-full border-b py-2.5 text-[15px] font-medium leading-5',
          'ring-offset-blue-400 ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2',
          selected
            ? 'border border-brand bg-brand text-white shadow'
            : 'hover:black hover:bg-brand/[0.12] border-r border-grey-20 text-grey-70 first:border-l-0 last:border-r-0',
        ])
      }
    >
      {title}
    </Tab>
  );
}
