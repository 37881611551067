import classNames from 'classnames';
import { useOutlet } from 'react-router-dom';

type Props = {
  context?: unknown;
  height?: string;
};

export function Outlet({ context, height }: Props) {
  const outlet = useOutlet(context);

  if (!outlet) {
    return null;
  }

  return (
    <div
      className={classNames([
        'fixed inset-0 z-50 w-full overflow-hidden bg-white h-screen lg:relative',
        'lg:w-rsb xl:w-2rsb',
        height || 'lg:h-[calc(100vh-8.4rem)]',
      ])}
    >
      {outlet}
    </div>
  );
}
