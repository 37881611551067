import { TagsField } from '~/components/form/tagify/TagsField';
import { useFormikContext } from 'formik';
import { VariantType } from '~/generated/graphql';
import { remove } from '~/helpers/array';
import { nanoid } from 'nanoid';
import { useCallback, useEffect } from 'react';
import { ComboboxField } from '../ui/Combobox';

const VARIANTS_MAX = 4;

export type VariantValue = VariantType & { key: string };

type FormValues = {
  hasVariants: boolean;
  variantTypes: VariantValue[];
};

export const VariantsField = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const setVariants = useCallback(
    (variants: VariantValue[]) => {
      setFieldValue('variantTypes', variants);
    },
    [setFieldValue]
  );

  // Check if variants should be modified
  useEffect(() => {
    const removeIndex = values.variantTypes
      .slice(0, -1)
      .findIndex((variant) => variant.name === '');
    if (removeIndex > -1) {
      // If a variant type is empty and it's not the last variant, remove it
      setVariants(remove(values.variantTypes, removeIndex));
    } else if (
      // If no variant is empty append an empty one up to max
      values.variantTypes.length < VARIANTS_MAX &&
      values.variantTypes.every((variant) => variant.name !== '')
    ) {
      setVariants([
        ...values.variantTypes,
        { key: nanoid(), name: '', values: [] },
      ]);
    }
  }, [values.variantTypes, setVariants]);

  return (
    <>
      {values.variantTypes.map(({ key }, i) => {
        return (
          <div key={key} className='grid grid-cols-2 gap-4'>
            <ComboboxField
              name={`variantTypes.${i}.name`}
              label={'Type'}
              items={[]}
            />
            <TagsField name={`variantTypes.${i}.values`} label={'Values'} />
          </div>
        );
      })}
    </>
  );
};
