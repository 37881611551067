import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRevalidator } from 'react-router-dom';
import { Split } from '~/components/Split';
import { relationshipOptions } from '~/components/contact/ContactForm';
import { ContactList } from '~/components/contact/ContactList';
import { ContactListContext } from '~/components/contact/ContactListContext';
import { FilterGroupSearch } from '~/components/ui/FilterGroupSearch';
import { ContactRelationship, Status } from '~/gql/graphql';
import { useSearch } from '~/hooks/useSearch';
import { useSort } from '~/hooks/useSort';
import { MoreNav } from '~/layouts/nav/MoreNav';
import { useContacts } from './resources/contacts';

type Contact = ReturnType<typeof useContacts>[number];

const SEARCH_OPTIONS = {
  keys: [
    'name',
    'type',
    'status',
    'site',
    {
      name: 'relationship',
      getFn(contact: Contact) {
        return contact.relationship?.join(', ') ?? '';
      },
    },
  ],
};

export default function ListContactsRoute() {
  const { contactId } = useParams();
  const { t } = useTranslation();
  const [filters, setFilters] = useState<URLSearchParams>(
    new URLSearchParams('status=Active')
  );
  const [query, setQuery] = useState('');
  const contacts = useContacts({ includeInactive: true });
  const revalidator = useRevalidator();

  const parseFilters = (filters: URLSearchParams) => {
    return {
      status: filters.getAll('status') as Status[],
      relationship: filters.getAll('relationship') as ContactRelationship[],
    };
  };

  const { results, search } = useSearch(contacts, SEARCH_OPTIONS);
  const { sorted } = useSort(results, { default: 'name' });
  const refresh = () => revalidator.revalidate();

  useEffect(() => {
    search('', parseFilters(filters));
  }, []);

  return (
    <ContactListContext.Provider value={{ refresh }}>
      <Split
        nav={<MoreNav />}
        filters={
          <FilterGroupSearch
            filters={[
              {
                name: 'status',
                label: t('status'),
                options: [
                  { label: 'Active', value: Status.Active },
                  { label: 'Inactive', value: Status.Inactive },
                ],
                searchable: false,
                type: 'select',
              },
              {
                name: 'relationship',
                label: t('relationship'),
                options: relationshipOptions(t),
                type: 'select',
              },
            ]}
            value={{ searchValue: query, filtersValue: filters }}
            onChange={({ searchValue, filtersValue }) => {
              setFilters(filtersValue);
              setQuery(searchValue);
              search(searchValue, parseFilters(filtersValue));
            }}
          />
        }
        main={<ContactList contacts={sorted ?? []} selectedId={contactId} />}
      />
    </ContactListContext.Provider>
  );
}
