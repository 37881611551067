import { FloatingActionButton } from '~/components/FloatingActionButton';

export function MobileAddButton() {
  return (
    <div className='pointer-events-none fixed bottom-0 left-0 right-0 z-30 flex h-1/4 justify-center md:h-1/5 lg:hidden '>
      <FloatingActionButton />
      <div className='absolute inset-0 bg-gradient-to-t from-white md:via-white'></div>
    </div>
  );
}
