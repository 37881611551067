import { SearchIcon, XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { HTMLProps, useEffect, useRef } from 'react';

type SearchInputProps = {
  placeholder?: string;
  setQuery: (q: string) => void;
  query: string;
  className?: string;
  inputProps?: HTMLProps<HTMLInputElement>;
};

export function SearchInput({
  placeholder,
  setQuery,
  query,
  className,
  inputProps,
}: SearchInputProps) {
  // on mount, clear the search query
  useEffect(() => setQuery(''), []);

  // on mount, focus the input
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // next tick...
    setTimeout(() => inputRef.current?.focus(), 0);
  }, []);

  return (
    <div
      className={classNames(
        'flex w-full items-center rounded border border-grey-20 px-4 py-[11px] focus-visible:border-grey-40 focus-visible:outline-none',
        className
      )}
    >
      <SearchIcon className='mr-4 h-5 w-5 text-tertiary' />
      <input
        {...inputProps}
        ref={inputRef}
        className={classNames(
          'flex-1 text-base text-primary outline-none placeholder:text-tertiary',
          inputProps?.className
        )}
        type='text'
        placeholder={
          placeholder ? `Search ${placeholder.toLowerCase()}...` : 'Search...'
        }
        onKeyDownCapture={(event) => {
          if (event.key !== 'Escape' && event.key !== 'Tab') {
            event.stopPropagation();
          }
        }}
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />
      {query && (
        <XIcon
          onClick={() => {
            setQuery('');
          }}
          className='h-5 w-5 cursor-pointer text-primary hover:text-brand'
        />
      )}
    </div>
  );
}
