import classNames from 'classnames/bind';
import { useField } from 'formik';
import { forwardRef, HTMLProps, useMemo } from 'react';
import styles from './TextField.module.css';

const IDENTITY = { nextVal: 1 };

const cx = classNames.bind(styles);

export type Props = {
  label: string;
  align?: 'right';
  error?: string | false;
  /**
   * Select the input text/value when input is focused
   */
  selectOnFocus?: boolean;
} & HTMLProps<HTMLInputElement>;
type Ref = HTMLInputElement;

export const FloatingInput = forwardRef<Ref, Props>(
  (
    { label, align, error, selectOnFocus = false, type = 'text', ...props },
    ref
  ) => {
    const uniqueId = useMemo(
      () => props.id ?? `Input-${IDENTITY.nextVal++}`,
      [props.id]
    );
    return (
      <div
        className={cx('relative', 'mb-3', { error: !!error }, props.className)}
        style={{ ...props.style }}
      >
        <input
          ref={ref}
          type={type}
          placeholder={label}
          {...props}
          id={uniqueId}
          onFocus={(event) => {
            if (selectOnFocus) {
              event.currentTarget.select();
            }
            // pass through call to onFocus
            if (props.onFocus) {
              props.onFocus(event);
            }
          }}
          className={cx('input', { right: align === 'right' })}
        />
        <label htmlFor={uniqueId} className={styles.label}>
          {label}
        </label>
        <span className={styles.bar}></span>
        {props.placeholder && !props.value && (
          <div className={styles.placeholder}>{props.placeholder}</div>
        )}
        {error && (
          <div className='ErrorMessage'>
            {typeof error === 'string' ? error : JSON.stringify(error)}
          </div>
        )}
      </div>
    );
  }
);

type TextFieldProps = Props & {
  /** @todo figure out how to type this correctly, or have extend the forwardRef props instead of Props */
  ref?: any;
  name: string;
  label: string;
  align?: 'right';
};

export const FloatingTextField = ({ name = '', ...props }: TextFieldProps) => {
  const [field, meta] = useField<string>(name);

  return (
    <FloatingInput {...field} {...props} error={meta.touched && meta.error} />
  );
};
