import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PromptEffect } from '~/components/Prompt';
import { QuickAdd, QuickAddButton, useQuickAdd } from '~/components/QuickAdd';
import { StockSelectionField } from '~/components/form/StockSelectionField';
import { SubmitButton } from '~/components/form/SubmitButton';
import { Context } from '~/components/smc/card/Hero';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import {
  useAddItemsTaskQuery,
  useCompleteItemTaskMutation,
} from '~/generated/graphql';
import { JobQuery, JobStatus } from '~/gql/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { ItemTaskDetails } from './move-stock/ItemTaskDetails';

type Job = NonNullable<JobQuery['job']>;

type Props = {
  job: Job;
  onClose: () => void;
  onSuccess: () => void;
};

export const MoveStockDestination = ({ job, onClose, onSuccess }: Props) => {
  const { taskId } = useParams();
  const { t } = useTranslation(['job']);
  const [allProcessed, setAllProcessed] = useState(false);
  const { showQuickAdd, storedValues, openQuickAdd, closeQuickAdd } =
    useQuickAdd<FormValues>();

  if (!taskId) {
    throw new Error('Task ID is required');
  }

  const location = useMemo(() => {
    if (!job.location) throw new Error('Invalid job location');
    return `${job.location.__typename}:${job.location.id}`;
  }, [job]);

  const [, completeTask] = useCompleteItemTaskMutation();
  const [res] = useAddItemsTaskQuery({
    variables: { id: taskId },
    requestPolicy: 'network-only',
  });
  const { data, error, fetching } = res;

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const { error } = await completeTask({
        input: {
          id: taskId,
          movements: values.moved,
        },
      });

      if (error) {
        toast.error(error.message.replace('[GraphQL] ', ''));
        return;
      }

      onSuccess();
    },
    [completeTask, onSuccess, taskId]
  );

  const moved = useMemo(() => {
    return (
      data?.addItemsTask.cards
        .filter((item) => item.putAway != null)
        .map(({ id, putAway }) => ({
          id,
          qty: putAway!,
        })) ?? []
    );
  }, [data]);

  if (fetching) {
    return <Loading />;
  }
  if (error || !data) {
    return <ErrorMessage message={error?.message} />;
  }

  const items = data.addItemsTask.cards;
  const isComplete = data.addItemsTask.status === JobStatus.Complete;
  const initialValues: FormValues = { moved };

  if (showQuickAdd) {
    return (
      <QuickAdd
        stockLocation={location}
        onCancel={closeQuickAdd}
        onSuccess={(d) => {
          closeQuickAdd();
        }}
      />
    );
  }

  return (
    <Formik
      initialValues={storedValues ?? initialValues}
      onSubmit={handleSubmit}
    >
      <Form>
        <PromptEffect />
        <SideLayout>
          <SideLayout.Head onClose={onClose}>
            {t('tasks.addItems')}
            {/* @ts-expect-error FIXME */}
            <QuickAddButton onClick={openQuickAdd} />
          </SideLayout.Head>
          <SideLayout.Body className='p-0'>
            <ItemTaskDetails job={job} task={data.addItemsTask} />

            <div>
              <AllProcessedEffect
                cardCount={items.length}
                onAllProcessed={setAllProcessed}
              />
              <StockSelectionField
                context={Context.ADD}
                name='moved'
                location={location}
                items={items}
                readOnly={isComplete}
                moreFilters={false}
              />
            </div>
          </SideLayout.Body>
          <SideLayout.Foot className='p-4'>
            {isComplete ? (
              <button
                className='mb-5 w-full rounded-lg border border-brand bg-white p-3 font-semibold capitalize text-brand disabled:opacity-50'
                type='button'
                onClick={onClose}
              >
                {t('back')}
              </button>
            ) : (
              <SubmitButton enabled={allProcessed} />
            )}
          </SideLayout.Foot>
        </SideLayout>
      </Form>
    </Formik>
  );
};

type FormValues = { moved: { id: string; qty: number }[] };

type AllProcessedEffectProps = {
  cardCount: number;
  onAllProcessed: (allProcessed: boolean) => void;
};

const AllProcessedEffect = ({
  cardCount,
  onAllProcessed,
}: AllProcessedEffectProps) => {
  const formik = useFormikContext<FormValues>();

  useEffect(() => {
    onAllProcessed(formik.values.moved.length === cardCount);
  }, [cardCount, formik.values, onAllProcessed]);

  return null;
};
