import { useEffect, useRef } from 'react'

export function usePortal(id) {
  const ref = useRef(document.createElement('div'))

  useEffect(
    function mount() {
      const current = ref.current
      const parent = document.querySelector(`#${id}`)
      parent.appendChild(current)

      return function unmount() {
        current.remove()
      }
    },
    [id]
  )

  return ref.current
}
