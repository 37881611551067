import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FragmentType, getFragmentData } from '~/gql';
import { JobTemplateFields } from '~/graphql/fragment/JobTemplateFields';
import { CardNew } from '../ui/CardNew';
import { EmptyListActions } from '../ui/EmptyListActions';

type Props = {
  templates: FragmentType<typeof JobTemplateFields>[];
  selectedId?: string;
};

export function JobTemplateList({ templates, selectedId }: Props) {
  return templates && templates.length > 0 ? (
    <>
      {templates.map((template) => {
        const key = getFragmentData(JobTemplateFields, template).id;
        return (
          <JobTemplateListItem
            key={key}
            template={template}
            isSelected={selectedId === key}
          />
        );
      })}
    </>
  ) : (
    <EmptyListActions listType='Template' />
  );
}

type ListItemProps = {
  template: FragmentType<typeof JobTemplateFields>;
  isSelected: boolean;
};

function JobTemplateListItem(props: ListItemProps) {
  const template = getFragmentData(JobTemplateFields, props.template);

  return (
    <CardNew
      linkTo={template.id}
      isSelected={props.isSelected}
      titleText={template.name}
      image={<TemplateIcon />}
      secondaryText=''
      // tertiaryText={tertiaryText}
      // status={template.status}
      showImage
    />
  );
}

function TemplateIcon() {
  return (
    <div className='bg-shade-10 flex h-full w-full items-center justify-center'>
      <FontAwesomeIcon className='text-shade-40 text-2xl' icon={faListCheck} />
    </div>
  );
}
