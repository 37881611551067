import { SVGProps } from 'react'

const SvgLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 89.656 98.195'
    {...props}
  >
    <path
      d='M170.086,223.877l-25.221,14a5.457,5.457,0,0,0-.094,9.487l8.976,5.219a3.261,3.261,0,0,1,1.623,2.821V261.3a2.252,2.252,0,0,1-3.351,1.964l-33.062-18.527a2.323,2.323,0,0,1,.005-4.054L170.8,211.824a10.256,10.256,0,1,0-9.987-17.916l-68.077,38a12.334,12.334,0,0,0,.049,21.567l64.761,35.768a12.334,12.334,0,0,0,18.3-10.764l.138-51.125a3.969,3.969,0,0,0-5.9-3.479'
      transform='translate(-86.415 -192.605)'
      fill='#1a93c2'
    />
  </svg>
)

export default SvgLogoIcon

