import { useVirtualizer } from '@tanstack/react-virtual';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { AuthGate } from '~/components/AuthGate';
import { CreateButton } from '~/components/CreateButton';
import { FloatingActionButton } from '~/components/FloatingActionButton';
import { ListDetailView } from '~/components/ListDetailView';
import { SpaceFilters } from '~/components/space/SpaceFilters';
import { SpaceForm } from '~/components/space/SpaceForm';
import { BasicListItem } from '~/components/ui/BasicListItem';
import { Loading } from '~/components/ui/Loading';
import { Result } from '~/components/ui/Result';
import { withModal } from '~/components/withModal';
import {
  Filter,
  ListSpacesQuery,
  useListSpacesQuery,
} from '~/generated/graphql';
import { MoreNav } from '~/layouts/nav/MoreNav';
import { IAttribute } from '~/types';
import { SpaceView } from './[id]';

type Space = ListSpacesQuery['spacesConnection']['edges'][0]['node'];

const SpaceFormModal = withModal(SpaceForm);
const SpaceViewModal = withModal(SpaceView);

function displayShortAttributes(attributes: IAttribute[]) {
  return attributes
    .filter(({ name }) => !!name)
    .map(({ name, value }) => `${name}: ${value.join(', ')}`)
    .join('; ');
}

function getListItemProps(space: Space, t: any, selectedId?: string) {
  return {
    id: space.id,
    linkTo: `/spaces/${space.id}`,
    image: space.image,
    titleText: space.name,
    secondaryText: space.location.__typename
      ? `${space.location.name} (${t(space.location.__typename)})`
      : undefined,
    tertiaryText: space.attributes
      ? displayShortAttributes(space.attributes)
      : undefined,
    // hero: {
    //   number: space.activeSkus || 0,
    //   directive: `Active ${t('item', { count: space.activeSkus })}`,
    // },
    status: space.status,
    active: false, //Boolean(match && match.params.id === space.id.toString()),
  };
}

export const Spaces = () => {
  const { t } = useTranslation();
  const [cursor, setCursor] = useState<string>();
  const [query, setQuery] = useState<string>();
  const [filters, setFilters] = useState<Filter[]>();
  const [result, revalidate] = useListSpacesQuery({
    variables: { first: 15, after: cursor, query, filters },
  });

  const reload = () => revalidate({ requestPolicy: 'network-only' });

  const allRows =
    result.data?.spacesConnection.edges.map(({ node }) => node) ?? [];

  // const match = useMatch('/spaces/:id/*');

  const { data } = result;
  const hasNextPage = Boolean(data?.spacesConnection.pageInfo.hasNextPage);

  return (
    <AuthGate action='manage' subject='feat.items'>
      <ListDetailView
        nav={<MoreNav />}
        actions={<CreateButton to='new'>{t('space')}</CreateButton>}
        filters={
          <>
            <SpaceFilters
              onFiltered={(f) => {
                const newFilters = Object.keys(f).reduce(
                  (prev: Filter[], key) => {
                    return f[key].length
                      ? [...prev, { field: key, values: f[key] }]
                      : prev;
                  },
                  []
                );
                setCursor(undefined);
                setFilters(newFilters);
              }}
              onSearch={(q) => {
                setCursor(undefined);
                setQuery(q);
              }}
            />
            <Result count={data?.spacesConnection.totalCount} />
          </>
        }
        main={
          <NewVirtualList
            hasNextPage={hasNextPage}
            allRows={allRows}
            fetchNextPage={() => {
              setCursor(data?.spacesConnection.pageInfo.endCursor ?? undefined);
            }}
          />
        }
      >
        <Route path='*' element={<FloatingActionButton />} />
        <Route
          path='/new/*'
          element={<SpaceFormModal title={t('spaces.new')} reload={reload} />}
        />
        <Route
          path='/:id/edit'
          element={<SpaceFormModal title={t('spaces.edit')} reload={reload} />}
        />
        <Route path='/:spaceId/*' element={<SpaceViewModal />} />
      </ListDetailView>
    </AuthGate>
  );
};

function NewVirtualList({
  allRows,
  hasNextPage,
  fetchNextPage,
}: {
  hasNextPage: boolean;
  allRows: Space[];
  fetchNextPage: () => void;
}) {
  const { t } = useTranslation();
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 98,
    overscan: 5,
  });

  return (
    <>
      <div ref={parentRef} className='h-full w-full overflow-auto px-4 pt-1'>
        <div
          style={{
            height: rowVirtualizer.getTotalSize(),
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const item = allRows[virtualRow.index];

            return (
              <div
                key={virtualRow.index}
                // @ts-expect-error update
                ref={virtualRow.measureElement}
                className={'absolute left-0 top-0 w-full'}
                style={{
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                {item ? (
                  <BasicListItem {...getListItemProps(item, t)} />
                ) : (
                  hasNextPage && <LoaderRow onMount={fetchNextPage} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

function LoaderRow({ onMount }: { onMount: () => void }) {
  useEffect(() => {
    onMount();
  }, [onMount]);

  return <Loading />;
}
