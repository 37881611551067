import { Button } from '~/components/ui/buttons/Button';
import { useFormikContext } from 'formik';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type SMCItem = {
  id: string;
  soh: number;
  goal: number;
};

type Values = {
  adjustments?: { [id: string]: '' | number };
  picked?: { [stockTransferId: string]: { [spaceSkuId: string]: '' | number } };
  add?: { [x: string]: '' | number };
  change?: { [x: string]: '' | 'successful' | 'unsuccessful' };
  remove?: { [x: string]: '' | number };
};

type Props = {
  items: SMCItem[];
};

export const ProcessAll = ({ items }: Props) => {
  const { values, setValues } = useFormikContext<Values>();

  const allProcessed = useMemo(() => {
    return (
      // stocktake
      (values.adjustments
        ? Object.values(values.adjustments).every((v) => v !== '')
        : true) &&
      // move stock origin
      (values.picked ? anyDeep(values.picked) : true) &&
      // change config task
      (values.add ? anyDeep(values.add) : true) &&
      // (values.add ? Object.values(values.add).every((v) => v !== '') : true) &&
      (values.change
        ? Object.values(values.change).every((v) => v !== '')
        : true) &&
      (values.remove
        ? Object.values(values.remove).every((v) => v !== '')
        : true)
    );
  }, [values]);

  const handleProcess = () => setValues(processAll(items));
  const handleUnprocess = () => setValues(unprocessAll());
  const { t } = useTranslation();

  const button = allProcessed ? (
    <Button type='button' className='mx-auto' onClick={handleUnprocess}>
      {t('Unprocess All')}
    </Button>
  ) : (
    <Button type='button' className='mx-auto' onClick={handleProcess}>
      {t('Process All')}
    </Button>
  );

  return <div className='mb-4 text-center'>{button}</div>;
};

/**
 * This is convoluted...
 */
function processAll(items: SMCItem[]) {
  return produce((draft) => {
    if (draft.adjustments) {
      for (const x in draft.adjustments) {
        if (draft.adjustments[x] === '') {
          const item = items.find((i) => i.id === x);
          if (item) draft.adjustments[x] = item.goal;
        }
      }
    }

    if (draft.picked) {
      for (const x in draft.picked) {
        for (const y in draft.picked[x]) {
          if (draft.picked[x][y] === '') {
            const item = items.find((i) => i.id === [x, y].join(','));
            if (item) draft.picked[x][y] = item.goal;
          }
        }
      }
    }

    if (draft.add) {
      for (const x in draft.add) {
        if (typeof draft.add[x] === 'object') {
          for (const y in draft.add[x]) {
            if (draft.add[x][y] === '') {
              const item = items.find((i) => i.id === [x, y].join(','));
              if (item) draft.add[x][y] = item.goal;
            }
          }
        } else if (draft.add[x] === '') {
          draft.add[x] = 1;
        }
      }
    }

    if (draft.change) {
      for (const x in draft.change) {
        if (draft.change[x] === '') draft.change[x] = 'successful';
      }
    }

    if (draft.remove) {
      for (const x in draft.remove) {
        if (draft.remove[x] === '') {
          const item = items.find((i) => i.id === x);
          if (item) draft.remove[x] = item.soh;
        }
      }
    }
  });
}

/**
 * The reverse of process all
 */
function unprocessAll() {
  return produce((draft) => {
    if (draft.adjustments) {
      for (const x in draft.adjustments) draft.adjustments[x] = '';
    }

    if (draft.picked) {
      for (const x in draft.picked) {
        for (const y in draft.picked[x]) draft.picked[x][y] = '';
      }
    }

    if (draft.add) {
      for (const x in draft.add) {
        if (typeof draft.add[x] === 'object') {
          for (const y in draft.add[x]) draft.add[x][y] = '';
        } else {
          draft.add[x] = '';
        }
      }
    }

    if (draft.change) {
      for (const x in draft.change) draft.change[x] = '';
    }

    if (draft.remove) {
      for (const x in draft.remove) {
        if (typeof draft.remove[x] !== 'object') draft.remove[x] = '';
      }
    }
  });
}

/**
 * Small helper for finding all processed in form values that have depth > 1
 */
function anyDeep(values: { [x: string]: any }): boolean {
  return Object.values(values).every((v) =>
    typeof v === 'string' ? v !== '' : anyDeep(v)
  );
}
