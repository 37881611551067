import { useEffect, useRef } from 'react';

export function useScrollToFocus() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const block = ref.current as HTMLDivElement;
    if (block) {
      block.addEventListener('click', () => {
        setTimeout(() => {
          block.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 300);
      });
    }
  });

  return ref;
}
