import { useLocation, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';
import { JobCalendarEventView } from '~/components/job/JobCalendarEventView';
import { Loading } from '~/components/ui/Loading';
import { PopupDialog } from '~/components/ui/PopupDialog';
import { JobCalendarEventsDocument } from './jobs.$view.calendar.$eventId';

const ICAL_PREFIX = 'ical';

export default function CalendarBookingRoute() {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isPopup = !pathname.includes('jobs/calendar');

  const { bookingId } = useParams();
  invariant(bookingId);

  const eventId = bookingId.startsWith(ICAL_PREFIX)
    ? bookingId.substring(ICAL_PREFIX.length)
    : `CheckOut:${bookingId}`;
  const [result] = useQuery({
    query: JobCalendarEventsDocument,
    requestPolicy: 'cache-and-network',
    variables: { ids: [eventId] },
  });

  if (result.fetching && !isPopup) {
    return <Loading spinner />;
  } else if (result.fetching && isPopup) {
    return null;
  }

  const event = result.data?.jobCalendarEvents.find(({ id }) => id === eventId);

  const removeBookingSegment = () => {
    const newUrl = pathname.replace(/\/bookings\/\d+$/, '');
    navigate(newUrl);
  };

  return (
    <>
      {isPopup ? (
        <PopupDialog isOpen={true} onClose={removeBookingSegment}>
          <JobCalendarEventView
            event={event}
            jobPathPrefix='/jobs/calendar/'
            onClose={() => navigate('..')}
          />
        </PopupDialog>
      ) : (
        <JobCalendarEventView
          event={event}
          jobPathPrefix='/jobs/calendar/'
          onClose={() => navigate('..')}
        />
      )}
    </>
  );
}
