import { faLightbulb, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import * as yup from 'yup';
import { Prompt } from '~/components/Prompt';
import { Input } from '~/components/form/TextField';
import { CreateJobScope, JobStatus, TaskInput } from '~/generated/graphql';
import { getFragmentData, graphql } from '~/gql';
import {
  CreateJobFormQuery,
  CreateJobInput,
  CreateJobsMutation,
} from '~/gql/graphql';
import { ContactFields } from '~/graphql/fragment/ContactFields';
import { remove, splice, unique } from '~/helpers/array';
import { notEmpty } from '~/helpers/filter';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useActivityListContext } from '~/routes/activity';
import { useScheduleDataContext } from '~/routes/calendar._index';
import { useContacts } from '~/routes/resources/contacts';
import { Option } from '~/types';
import { SideLayout } from '../../layouts/side/SideLayout';
import { FindAddContact } from '../FindAddContact';
import { FindAddSite } from '../FindAddSite';
import { useSites } from '../FindAddSite/FindAddSite';
import { TagCombobox, TagEntityType } from '../TagCombobox';
import { useTags } from '../TagCombobox/tags';
import { ActivityListItem_Activity } from '../activity/ActivityListItem';
import { AttributeSelect } from '../form/AttributeSelect';
import { TimePicker } from '../form/TimePicker';
import { Switch } from '../form/switch/Switch';
import { TextArea } from '../form/textarea/Basic';
import { Accordion } from '../ui/Accordion';
import { JobsRepeat, RepeatValue, repeatToInput } from '../ui/JobsRepeat';
import { Label } from '../ui/Label';
import { Kind, toAttachmentInput } from '../upload2';
import { ControlledUpload, UploadResult } from '../upload2/Upload';
import { ManageTasks } from './CreateJobForm/ManageTasks';
import { CustomTaskForm } from './CustomTaskForm';
import { useJob } from './JobView';
import { SaveJobActions, SubmitOptions } from './SaveJobActions';
import { getDefaultScheduledEndTime } from './getDefaultScheduledEndTime';

export type LocationOptions = {
  Asset: Option[];
  Contact: Option[];
  Site: Option[];
  StockTransfer: Option[];
  Elsewhere: Option[];
};

const CreateJobsMutationDocument = graphql(`
  mutation CreateJobs($input: CreateJobInput!) {
    createJobs(input: $input) {
      id
      status
      name
      createdAt
      location {
        id
      }
      scheduledStartDate
      scheduledEndDate
    }
  }
`);

const CreateJobSchema = yup.object().shape({
  name: yup.string().required('Required'),
  scopeIds: yup.array().of(yup.string()),
  repeat: yup.object().test('numberOfRepeats validation', function (value) {
    let message = '';
    if (value.isRepeat && !value.numOfRepeats) {
      message = 'Required';
    }
    return message ? this.createError({ path: 'numOfRepeats', message }) : true;
  }),
});

/**
 * Form values that differ from the input type
 */
type DivergentFormValues = {
  guestyCleaningStatus: boolean;
  repeat: RepeatValue;
  // The upload field value held by the form differs from the input type
  attachments: UploadResult[];
};
type FormValues = Omit<CreateJobInput, keyof DivergentFormValues> &
  DivergentFormValues;

/**
 * FIXME these props are only required by popup on site view, not the create jobs form which is actually a route component (/jobs/new)
 * These could be moved out using composition to avoid a swathe of conditional logic
 * We want to display the same form in a different context
 * @deprecated use Props instead
 */
type CreateJobProps = {
  /** @deprecated see FIXME above */
  onSuccess?: (data: CreateJobsMutation) => void;
};

type Props = {
  data: CreateJobFormQuery;
  /** If provided, set initial value and lock the control to this location */
  locationId?: string;
  /** Passed through to SideLayout.Head */
  onClose: () => void;
};

const informMultipleSitesSelected = (jobCount: number) => {
  toast.info(`Jobs will be created for ${jobCount} places.`, {
    theme: 'dark',
    className: 'bg-[#0D9F8F] text-white',
    hideProgressBar: true,
    icon: <FontAwesomeIcon icon={faLightbulb} />,
  });
};

export const CreateJobForm = ({
  data,
  onClose,
  onSuccess: popupOnSuccess,
  ...props
}: Props & CreateJobProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('job');
  const { activities } = useActivityListContext();
  const activitySearchId = useSearchParams()[0].get('activity');
  const activitySearchIds = activitySearchId?.split(',');
  const unmaskedActivities = getFragmentData(
    ActivityListItem_Activity,
    // @ts-ignore FIXME
    activities?.map((item) => item?.node).filter((item) => !!item) ?? []
  );

  /** Are we creating a new job from within another job? */
  const createFrom = useJob({ required: false });
  const createFromActivity = unmaskedActivities
    ?.filter((activity) => activitySearchIds?.includes(activity?.id))
    .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1));
  const createFromActivityMessage = createFromActivity
    ?.map((act) => act?.comment?.body || act?.task?.notes)
    .filter(Boolean)
    .join('\n\n');

  const createFromActivityAttachments = (): UploadResult[] | null => {
    const attachments = createFromActivity
      ?.map((att) => att?.task?.attachments || att?.comment?.attachments)
      .filter(notEmpty)
      .flat()
      .map((att) => ({
        id: att.id,
        kind: att.kind as Kind,
        name: att.originalFilename,
        preview: att.thumb,
      }));

    return attachments && attachments.length > 0 ? attachments : null;
  };

  const scheduleContext = useScheduleDataContext();
  const [, createJobs] = useMutation(CreateJobsMutationDocument);
  const [sites] = useSites();
  const [allTags, reload] = useTags(TagEntityType.Job);
  const { isMobile } = useBreakpoint();

  const [navigateTo, setNavigateTo] = useState<string>();
  useEffect(() => {
    if (navigateTo) navigate(navigateTo);
  }, [navigateTo, navigate]);

  const { taskState, showTask, setShowTask, updateTask, deleteTask } =
    useEditTasks();

  const contacts = useContacts();
  const { pathname } = useLocation();
  const pageName = pathname.split('/')[1];

  // unmask fragment data
  const dataContacts = getFragmentData(ContactFields, data.contacts);
  const currentUserContact = dataContacts.find(
    (contact) => contact.isCurrentUser
  );

  const initialScopeIds = () => {
    if (createFrom?.job?.location) {
      return [createFrom.job.location.id];
    }
    if (createFromActivity) {
      return unique(
        createFromActivity.map((act) => act.job?.site?.id || '').filter(Boolean)
      );
    }
    if (props.locationId) {
      return [props.locationId];
    }
    return [];
  };

  const initialValues: FormValues = {
    name: '',
    scope: CreateJobScope.Site,
    scopeIds: initialScopeIds(),
    fromTemplate: '',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    scheduledStartStrict: '1',
    scheduledStartDate: '',
    scheduledStartTime: '',
    scheduledEndStrict: '0',
    scheduledEndDate: '',
    scheduledEndTime: '',
    owner: currentUserContact ? [currentUserContact.id] : null,
    assignee: null,
    customer: null,
    enforceOrder: false,
    tasks: [],
    includeAttributes: null,
    tags: [],
    notes: createFromActivityMessage ?? '',
    attachments: createFromActivityAttachments() ?? [],
    guestyCleaningStatus: false,
    repeat: {
      isRepeat: false,
      interval: '1',
      numOfRepeats: '',
      frequency: 'daily',
      repeatOnWeek: [],
      repeatOnMonth: '',
      repeatOnYear: '',
    },
  };

  // Custom loading state to make form submission busy up until the redirection is completed
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(CreateJobSchema),
  });
  const {
    formState: { isDirty, errors },
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
  } = form;

  useEffect(() => {
    reset(initialValues);
  }, [activitySearchId]);

  const timeZone = watch('timeZone');
  const scope = watch('scope');
  const scopeIds = watch('scopeIds');
  const assignee = watch('assignee');
  const scheduledStartDate = watch('scheduledStartDate');
  const scheduledEndDate = watch('scheduledEndDate');
  const isJobRepeat = watch('repeat.isRepeat');
  const locationId = scopeIds.length
    ? [scope, scopeIds[0]].join(':')
    : undefined;

  const selectedLocations =
    scopeIds.length > 1 ? `${scopeIds.length} Selected` : null;
  const location =
    scopeIds.length === 1
      ? sites.find((site) => site.id === scopeIds[0])
      : null;
  const where = selectedLocations || location?.name;
  const who =
    assignee &&
    contacts
      .filter(({ id }) => assignee.includes(id))
      .map(({ name }) => name)
      .join(', ');
  const when = scheduledStartDate || scheduledEndDate ? 'Scheduled' : null;
  const guestyEnabled = data.integrations.includes('guesty');

  const isMultipleJobCreation = !!(scopeIds.length > 1 || isJobRepeat);
  const notifyDefault = !isMultipleJobCreation;

  // const defaultSourceId =
  //   form.scope === 'Asset'
  //     ? form.scopeIds.reduce((prev, assetId) => {
  //         const asset = data.locations.find(
  //           ({ __typename, id }) =>
  //             __typename === 'Asset' && id === assetId.toString()
  //         );
  //         if (asset && asset.__typename === 'Asset' && asset.deployment) {
  //           return asset.deployment.defaultSourceId
  //             ? prev === ''
  //               ? asset.deployment.defaultSourceId
  //               : prev === asset.deployment.defaultSourceId
  //               ? prev
  //               : ''
  //             : '';
  //         }
  //         return prev;
  //       }, '')
  //     : '';

  const [task, setTask] = useState<TaskInput | null>(null);
  const [shownTaskType, setShownTaskType] = useState<string | null>(null);

  useEffect(() => {
    setTask(taskState[0].find((task) => task.id === showTask) || null);
    setShownTaskType(
      taskState[0].find((item) => item.id === showTask)?.type || null
    );
  }, [showTask, taskState]);

  const handleSuccessCreation = (jobId: string, status: string) => {
    if (pageName === 'activity') {
      setNavigateTo(
        status === JobStatus.Created
          ? `../jobs/${jobId}/edit`
          : `../jobs/${jobId}`
      );
    } else {
      setNavigateTo(
        status === JobStatus.Created ? `../${jobId}/edit` : `../${jobId}`
      );
    }

    toast.success('Job successfully created');
  };

  const submit = async (status: JobStatus, options: SubmitOptions = {}) => {
    await handleSubmit(
      async (data) => {
        setIsSubmitting(true);

        const res = await createJobs({
          input: {
            ...data,
            attachments: (
              createFromActivityAttachments() ?? data.attachments
            ).map(toAttachmentInput),
            scheduledStartDate: data.scheduledStartDate || null,
            scheduledStartTime: data.scheduledStartTime || null,
            scheduledEndDate: data.scheduledEndDate || null,
            scheduledEndTime: data.scheduledEndTime || null,
            tasks: taskState[0],
            status,
            notify: options.notify,
            comment: options.comment,
            sendMeACopy: options.sendMeACopy === true,
            includePdf: options.includePdf === true,
            guestyCleaningStatus: data.guestyCleaningStatus ? 'clean' : null,
            repeat: repeatToInput(data.repeat),
          },
        });
        if (res.error) {
          toast.error('Error: ' + res.error.message.replace('[GraphQL] ', ''));
          setIsSubmitting(false);
          return;
        }

        if (res.data) {
          // TODO too many conditionals... tidy up
          if (createFrom?.job) {
            // if (scheduleContext) {
            //   scheduleContext.reload();
            // }
            // if (onSuccess) {
            //   onSuccess(res.data);
            // }
            toast.success('Job saved successfully');
            setNavigateTo('..');
          } else if (scheduleContext) {
            if (res.data.createJobs.length > 1) {
              toast.success(
                `${res.data.createJobs.length} jobs successfully created`
              );
              setNavigateTo('..');
            } else {
              toast.success(`Job successfully created`);
              // Navigate clicking 'Save Draft' btn to edit form
              setNavigateTo(
                status === JobStatus.Created
                  ? `../${res.data.createJobs[0].id}/edit`
                  : `../${res.data.createJobs[0].id}`
              );
            }
          }
          // else if (onSuccess) {
          //   onSuccess(res.data);

          //   if (res.data.createJobs.length > 1) {
          //     toast.success(
          //       `${res.data.createJobs.length} jobs successfully created`
          //     );
          //     setNavigateTo('..');
          //   } else {
          //     toast.success(`Job successfully created`);
          //     // Navigate clicking 'Save Draft' btn to edit form
          //     setNavigateTo(
          //       status === JobStatus.Created
          //         ? `../${res.data.createJobs[0].id}/edit`
          //         : `../${res.data.createJobs[0].id}`
          //     );
          //   }
          // }
          else if (popupOnSuccess) {
            popupOnSuccess(res.data);
          } else {
            // default success handler
            handleSuccessCreation(res.data.createJobs[0].id, status);
          }
        }
      },
      (errors) => {
        if (errors.name) {
          // Open WHAT section if Job Name fails on form validation
          setAccordionOpenOnError(null);
          setTimeout(() => setAccordionOpenOnError(3), 0);
        }
      }
    )();
  };

  const updateTaskType = (taskId: string, taskType: string) => {
    const [, setTasks] = taskState;
    const tasksListCopy = taskState[0].slice();
    const taskIndex = tasksListCopy.findIndex((task) => task.id === taskId);
    if (taskIndex < 0) return;
    tasksListCopy[taskIndex].type = taskType;
    setTasks(tasksListCopy);
  };

  const [accordionOpenOnError, setAccordionOpenOnError] = useState<
    number | null
  >(null);

  const taskSection = task ? (
    <CustomTaskForm
      locationId={locationId}
      task={task}
      onApply={updateTask}
      onClose={() => setShowTask(null)}
      onDelete={deleteTask}
      scopeIds={scopeIds}
      taskType={shownTaskType}
      updateTaskType={(id, type) => updateTaskType(id, type)}
      showTaskOptions
    />
  ) : null;

  const whereCardRef = useRef<HTMLDivElement>(null);
  const [toggledPanelIdx, setToggledPanelIdx] = useState<number | undefined>(0);
  const isAccordionExpanded = toggledPanelIdx !== -1;

  // This adds click event listeners to accordion cards
  useEffect(() => {
    const blocksList: HTMLDivElement[] = [];
    const cards = whereCardRef.current?.parentNode?.childNodes;
    cards?.forEach((c) => blocksList.push(c as HTMLDivElement));

    blocksList?.forEach((b) => {
      const block = b as HTMLDivElement;
      block.addEventListener('click', () => {
        const isOpening = !block.querySelector('section');

        if (isOpening) {
          setTimeout(() => {
            block.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 300);
        }
      });
    });
  }, []);

  const handleAccordionDefaultOpen = (): number => {
    if (accordionOpenOnError !== null) return accordionOpenOnError;
    if (createFrom?.job?.location) return 1;
    if (createFromActivityMessage || createFromActivityAttachments()) return 3;
    return 0;
  };

  return (
    <FormProvider {...form}>
      <div className='flex h-full items-stretch justify-end overflow-y-auto'>
        <SideLayout>
          <SideLayout.Head onClose={onClose}>Create Job</SideLayout.Head>
          <SideLayout.Body className='px-4 pb-96 lg:pb-44'>
            <Prompt when={!navigateTo && isDirty} cancel='Keep Editing' />

            <Accordion
              defaultOpen={handleAccordionDefaultOpen()}
              onToggledPanel={setToggledPanelIdx}
            >
              {!props.locationId && (
                <Accordion.Card ref={whereCardRef}>
                  <Accordion.Button summary={where} help='Place'>
                    Where
                  </Accordion.Button>
                  <Accordion.Panel>
                    {(next) =>
                      scope === CreateJobScope.Site && (
                        <Controller
                          name='scopeIds'
                          control={control}
                          render={({ field, fieldState }) => (
                            <FindAddSite
                              optionsBoxHeight={
                                isMobile
                                  ? 'max-h-[calc(100vh-185px)]'
                                  : 'max-h-[calc(100vh-320px)]'
                              }
                              label={t('form.site')}
                              error={fieldState.error?.message}
                              value={field.value}
                              onChange={(selected) => {
                                if (selected && selected.length > 1) {
                                  informMultipleSitesSelected(selected.length);
                                }
                                if (
                                  selected &&
                                  selected.length &&
                                  selected[0].id
                                ) {
                                  field.onChange(selected.map(({ id }) => id));
                                  // next();
                                  return;
                                }
                                field.onChange([]);
                              }}
                              autoFocus
                              multiple
                            />
                          )}
                        />
                      )
                    }
                  </Accordion.Panel>
                </Accordion.Card>
              )}

              <Accordion.Card>
                <Accordion.Button summary={who} help='Unassigned'>
                  Who
                </Accordion.Button>
                <Accordion.Panel>
                  <Controller
                    name='owner'
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FindAddContact
                        optionsBoxHeight={
                          isMobile
                            ? 'max-h-[calc(100vh-285px)]'
                            : 'max-h-[calc(100vh-450px)]'
                        }
                        label={t('form.owner')}
                        // contacts={contacts.filter((c) =>
                        //   c.relationship?.includes(ContactRelationship.Staff)
                        // )}
                        error={error?.message}
                        value={
                          value
                            ? contacts.filter(({ id }) => value.includes(id))
                            : []
                        }
                        onChange={(newValue) => {
                          onChange(
                            newValue && newValue.length > 0
                              ? newValue.map(({ id }) => id)
                              : []
                          );
                        }}
                        isTeamMember
                        multiple
                        isAbsolute={false}
                      />
                    )}
                  />
                  <Controller
                    name='assignee'
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FindAddContact
                        optionsBoxHeight={
                          isMobile
                            ? 'max-h-[calc(100vh-285px)]'
                            : 'max-h-[calc(100vh-450px)]'
                        }
                        label={t('form.assignee')}
                        // contacts={contacts}
                        error={error?.message}
                        value={
                          value?.length
                            ? contacts.filter(({ id }) => value.includes(id))
                            : []
                        }
                        onChange={(newValue) => {
                          if (newValue && newValue.length > 0) {
                            onChange(newValue.map(({ id }) => id));
                          } else {
                            onChange(null);
                          }
                        }}
                        multiple
                        isAbsolute={false}
                      />
                    )}
                  />
                  <Controller
                    name='include'
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FindAddContact
                        optionsBoxHeight={
                          isMobile
                            ? 'max-h-[calc(100vh-285px)]'
                            : 'max-h-[calc(100vh-450px)]'
                        }
                        label={t('form.include')}
                        // contacts={contacts}
                        error={error?.message}
                        value={
                          value
                            ? contacts.filter(({ id }) => value.includes(id))
                            : []
                        }
                        onChange={(newValue) => {
                          onChange(
                            newValue && newValue.length > 0
                              ? newValue.map(({ id }) => id)
                              : []
                          );
                        }}
                        multiple
                        hasSelectAll
                        isAbsolute={false}
                      />
                    )}
                  />
                </Accordion.Panel>
              </Accordion.Card>
              <Accordion.Card>
                <Accordion.Button help='Unscheduled' summary={when}>
                  When
                </Accordion.Button>
                <Accordion.Panel>
                  <div className='mb-2.5 hidden gap-4'>
                    <label>
                      <input
                        {...register('scheduledStartStrict')}
                        type='radio'
                        value='1'
                      />{' '}
                      Start at
                    </label>
                    <label>
                      <input
                        {...register('scheduledStartStrict')}
                        type='radio'
                        value='0'
                      />{' '}
                      Start from
                    </label>
                  </div>
                  <div className='flex w-full items-stretch gap-3 text-left'>
                    <Controller
                      control={control}
                      name='scheduledStartDate'
                      render={({ field: { value, onChange } }) => (
                        <Input
                          label={t('job:form.scheduleStart')}
                          type='date'
                          value={value}
                          onChange={(event) => {
                            onChange(event);
                            setValue('repeat.repeatOnMonth', '');
                          }}
                        />
                      )}
                    />
                    {/* <Input
                      {...register('scheduledStartDate')}
                      label={t('job:form.scheduleStart')}
                      type='date'
                    /> */}
                    <Controller
                      control={control}
                      name='scheduledStartTime'
                      render={({ field: { value, onChange } }) => (
                        <TimePicker
                          label='Time'
                          focusValue='09:00'
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <button
                      className='text-grey-30'
                      onClick={() => {
                        setValue('scheduledStartDate', '');
                        setValue('scheduledStartTime', '');
                      }}
                    >
                      <FontAwesomeIcon className='h-5 w-5' icon={faTrashAlt} />
                    </button>
                  </div>
                  <div className='mb-2.5 hidden gap-4'>
                    <label>
                      <input
                        {...register('scheduledEndStrict')}
                        type='radio'
                        value='1'
                      />{' '}
                      End at
                    </label>
                    <label>
                      <input
                        {...register('scheduledEndStrict')}
                        type='radio'
                        value='0'
                      />{' '}
                      End before
                    </label>
                  </div>
                  <div className='flex w-full items-stretch gap-3 text-left'>
                    <Input
                      {...register('scheduledEndDate')}
                      label={t('job:form.scheduleEnd')}
                      type='date'
                    />
                    <Controller
                      control={control}
                      name='scheduledEndTime'
                      render={({ field: { value, onChange } }) => (
                        <TimePicker
                          label='Time'
                          focusValue={getDefaultScheduledEndTime(
                            watch('scheduledStartTime')
                          )}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <button
                      className='text-grey-30'
                      onClick={() => {
                        setValue('scheduledEndDate', '');
                        setValue('scheduledEndTime', '');
                      }}
                    >
                      <FontAwesomeIcon className='h-5 w-5' icon={faTrashAlt} />
                    </button>
                  </div>
                  <JobsRepeat label='Repeats' />
                </Accordion.Panel>
              </Accordion.Card>
              <Accordion.Card>
                <Accordion.Button help='Description' summary={watch('name')}>
                  What
                </Accordion.Button>
                <Accordion.Panel>
                  <Input
                    {...register('name')}
                    label={t('form.name')}
                    required
                    error={errors.name?.message}
                    autoFocus
                    maxLength={250}
                  />

                  <Label>{t('translation:notes')}</Label>
                  <TextArea {...register('notes')} />

                  <Controller
                    name='attachments'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ControlledUpload
                        id={`CreateJobForm`}
                        value={value ?? []}
                        onChange={onChange}
                      />
                    )}
                  />

                  <Controller
                    name='includeAttributes'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <AttributeSelect
                        label={t('translation:attribute_plural')}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />

                  <Controller
                    name='tags'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TagCombobox
                        containerStyles={
                          isMobile
                            ? '!max-h-[calc(100vh-170px)]'
                            : '!max-h-[calc(100vh-305px)]'
                        }
                        entityType={TagEntityType.Job}
                        options={allTags}
                        value={
                          value
                            ? allTags.filter((t) => value.includes(t.id))
                            : []
                        }
                        onChange={(tags) => {
                          reload();
                          onChange(tags.map(({ id }) => id));
                        }}
                      />
                    )}
                  />

                  {guestyEnabled && (
                    <label className='mb-5 block'>
                      <input
                        {...register('guestyCleaningStatus')}
                        type='checkbox'
                      />{' '}
                      Update Guesty cleaning status
                    </label>
                  )}

                  <ManageTasks
                    jobTemplates={data.jobTemplates}
                    onTaskTypeUpdate={updateTaskType}
                    state={taskState}
                    onShowTask={setShowTask}
                    onUseTemplateSelect={(template) => {
                      if (template.tags) {
                        setValue(
                          'tags',
                          template.tags.map(({ id }) => id)
                        );
                      }
                      if (template.notes) {
                        setValue('notes', template.notes);
                      }
                      if (template.includeAttributes) {
                        setValue(
                          'includeAttributes',
                          template.includeAttributes
                        );
                      }
                      // if (template.attachments) {
                      //   setValue('attachments', template.attachments);
                      // }
                    }}
                  />
                  {taskState[0].length > 0 && (
                    <Controller
                      name='enforceOrder'
                      control={control}
                      render={({ field }) => (
                        <Switch
                          name='enforceOrder'
                          label='Enforce task order'
                          help='Each task must be completed before the next task can be started.'
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  )}
                </Accordion.Panel>
              </Accordion.Card>
            </Accordion>

            {/* <FindAddContact
        label={t('job:form.customer')}
        type='Customer'
        // value={form.customer ? [form.customer] : []}
        value={form.customer ? [form.customer] : []}
        onChange={(value) => {
          setValue('customer', value.length ? value[0] : null);
        }}
      /> */}
            {/* GF-2761 temporarily hidden */}
            {/* <RadioGroup
        name='scope'
        title={t('job:form.scopeTitle')}
        options={Object.values(CreateJobScope).map((value) => ({
          value,
          label: t('type.' + value),
        }))}
        value={form.scope}
        onChange={(value) => {
          setValue('scopeIds', []);
          setValue('scope', value);
        }}
      /> */}

            {/* {form.scope === CreateJobScope.Asset && (
        <Select
          label={t('form.asset')}
          options={options.Asset}
          value={form.scopeIds.map((id) => id.toString())}
          onChange={(value) =>
            setValue(
              'scopeIds',
              value.map((v) => parseInt(v))
            )
          }
          multiple
        />
      )} */}
          </SideLayout.Body>
          <SideLayout.Foot
            className={classNames('rounded p-4', {
              '!fixed': isMobile,
            })}
          >
            <SaveJobActions
              assignee={watch('assignee')}
              jobName={watch('name')}
              loading={isSubmitting}
              onClick={submit}
              notifyDefault={notifyDefault}
              isMultipleJobCreation={isMultipleJobCreation}
            />
          </SideLayout.Foot>
        </SideLayout>
        {taskSection}
      </div>
    </FormProvider>
  );
};

export function useEditTasks() {
  const [showTask, setShowTask] = useState<string | null>(null);
  const taskState = useState<TaskInput[]>([]);

  const updateTask = useCallback(
    (data: TaskInput) => {
      const [, setTasks] = taskState;

      setTasks((tasks) =>
        splice(
          tasks,
          tasks.findIndex(({ id }) => id === data.id),
          1,
          data
        )
      );

      setShowTask(null);
    },
    [taskState]
  );

  const deleteTask = useCallback(
    (id: string) => {
      const [, setTasks] = taskState;

      setTasks((tasks) => {
        const idx = tasks.findIndex((t) => t.id === id);
        return idx > -1 ? remove(tasks, idx) : tasks;
      });

      setShowTask(null);
    },
    [taskState]
  );

  return {
    taskState,
    showTask,
    setShowTask,
    updateTask,
    deleteTask,
  };
}
