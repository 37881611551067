import { SideLayout } from '~/layouts/side/SideLayout';
import { Component, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type Props = { children: ReactNode };
type State = { hasError: boolean; isClosing: boolean };

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, isClosing: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   this.props.catch(error, errorInfo)
  // }

  render() {
    const { hasError, isClosing } = this.state;
    if (hasError) {
      return (
        <>
          {isClosing && <Navigate to='..' />}
          <SideLayout>
            <SideLayout.Head
              onClose={() => this.setState({ isClosing: true })}
            ></SideLayout.Head>
            <SideLayout.Body>
              <p className='text-center'>Something went wrong.</p>
            </SideLayout.Body>
          </SideLayout>
        </>
      );
    }

    return this.props.children;
  }
}
