import { SVGProps } from 'react'

const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' {...props}>
    <path
      d='M2.7 14.258V17.3h3.042l8.971-8.971-3.042-3.042zm14.365-8.282a.808.808 0 000-1.144l-1.9-1.9a.808.808 0 00-1.144 0l-1.482 1.487 3.042 3.042z'
      fill='inherit'
    />
    <path d='M0 0h20v20H0z' fill='none' />
  </svg>
)

export default SvgEdit

