import classNames from 'classnames';
import { Formik } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useQuery } from 'urql';
import { AddJobPopup } from '~/components/AddJobPopup';
import { QuickAddButton, useQuickAdd } from '~/components/QuickAdd';
import { CardSmall } from '~/components/ui/CardSmall';
import { Loading } from '~/components/ui/Loading';
import { Menu } from '~/components/ui/Menu';
import { SearchField } from '~/components/ui/SearchField';
import { FragmentType, getFragmentData, graphql } from '~/gql';
import { JobStatus } from '~/gql/graphql';
import { formatDate, formatDateInTimeZone } from '~/helpers/formatDate';
import { useSearch } from '~/hooks/useSearch';

const SiteJobs_JobFragment = graphql(`
  fragment SiteJobs_Job on Job {
    id
    status
    name
    scheduleStart
    scheduleEnd
    completedAt
    timeZone
    location {
      id
      name
    }
    assignee {
      id
      name
      image
    }
  }
`);

const SiteJobsQuery = graphql(`
  query SiteJobs($id: ID!, $scheduled: String!) {
    site(id: $id) {
      id
      name
      jobs(scheduled: $scheduled, first: 200) {
        totalCount
        edges {
          cursor
          node {
            id
            scheduleStart
            ...SiteJobs_Job
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`);

type DateDisplayProps = {
  date: string;
};

export function DateDisplay({ date }: DateDisplayProps) {
  return (
    <p className='mb-1 whitespace-nowrap py-1 text-center text-xs font-medium text-grey-50'>
      {date}
    </p>
  );
}

const SEARCH_KEYS = ['node.name', 'node.assignee.name'];

type JobsListProps = {
  siteId: string;
};

export function JobsList({ siteId }: JobsListProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [view, setView] = useState('scheduled');
  const [result, reexecuteQuery] = useQuery({
    query: SiteJobsQuery,
    variables: { id: siteId, scheduled: view },
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    search('');
    inputRef.current.value = '';
    inputRef.current.focus();
  }, [view]);

  const { data, fetching } = result;
  const edges = result.data?.site.jobs.edges;

  const quickAdd = useQuickAdd();

  const { results, search } = useSearch(edges, { keys: SEARCH_KEYS });

  if (fetching && !data) {
    return <div className='px-4 pb-4'>Loading...</div>;
  }

  type Item = string | NonNullable<typeof edges>[number]['node'];
  const items = results?.reduce((prev: Item[], { node }) => {
    const job = getFragmentData(SiteJobs_JobFragment, node);
    const date = job.scheduleStart || job.scheduleEnd;

    if (date && !prev.includes(date)) {
      prev.push(date);
    }

    // Exclude jobs with a completed status from the scheduled jobs list
    if (!(view === 'scheduled' && job.status === JobStatus.Complete)) {
      prev.push(node);
    }

    return prev;
  }, []);

  return (
    <>
      <div className='flex-grow'>
        <div className='flex px-4 pr-0'>
          <div
            className={classNames(
              'flex h-12 flex-1 cursor-pointer flex-col items-center justify-center text-center text-sm font-normal not-italic leading-[normal] text-[#007070] text-[color:var(--Grey-Mid,#656565)]',
              view === 'scheduled' &&
                'border-b-[3px] border-brand font-semibold'
            )}
            onClick={() => setView('scheduled')}
          >
            Scheduled
          </div>
          <div
            className={classNames(
              'flex h-12 flex-1 cursor-pointer flex-col items-center justify-center text-center text-sm font-normal not-italic leading-[normal] text-[#007070]',
              view === 'unscheduled' &&
                'border-b-[3px] border-brand font-semibold'
            )}
            onClick={() => setView('unscheduled')}
          >
            Unscheduled
          </div>
          <div className='ml-2 flex h-12 w-12 flex-col items-center justify-center'>
            <Menu>
              <Menu.Item onClick={() => setView('completed')}>
                Completed
              </Menu.Item>
            </Menu>
          </div>
        </div>
        {!edges?.length ? (
          <div className='p-4 text-center'>
            There are no {view} jobs for this property
          </div>
        ) : (
          <>
            <div className='p-4'>
              <SearchField
                ref={inputRef}
                placeholder='Search Jobs...'
                onChange={(event) => search(event.target.value)}
              />

              {/* <div className='relative flex items-center justify-between rounded-[35px] border border-solid border-[color:var(--Super-Light-Grey,#E8E8E8)] bg-white px-3.5 py-1 shadow-[2px_2px_4px_0px_rgba(0,0,0,0.10)]'>
                <SearchIcon className='pointer-events-none absolute left-3 mt-0.5 h-5 w-5 text-grey-20' />
                <input
                  ref={inputRef}
                  type='text'
                  className='w-full py-1 pl-8 outline-none'
                  onChange={(event) => search(event.target.value)}
                />
              </div> */}
            </div>
            {items?.map((item, i) => (
              <Fragment key={typeof item === 'string' ? item : item.id}>
                {typeof item === 'string' ? (
                  // Don't show the date if there's not a job following it
                  items[i + 1] && typeof items[i + 1] === 'string' ? null : (
                    <DateDisplay date={formatDate(item, 'PP')} />
                  )
                ) : (
                  <JobCardSm job={item} />
                )}
              </Fragment>
            ))}
          </>
        )}
      </div>
      <div className='sticky -bottom-40 left-0 right-0 z-30 flex h-20 justify-center lg:-bottom-24'>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <QuickAddButton centered={true} onClick={quickAdd.open} />
        </Formik>
        <div className='absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white md:via-white'></div>
      </div>
      <AddJobPopup
        locationId={siteId}
        onCancel={quickAdd.close}
        onSuccess={() => {
          reexecuteQuery({ requestPolicy: 'network-only' });
          quickAdd.close();
        }}
        show={quickAdd.showQuickAdd ?? false}
        onClose={quickAdd.close}
      />
    </>
  );
}

type JobCardSmProps = {
  job: FragmentType<typeof SiteJobs_JobFragment>;

  /** @todo We do not want to omit timeZone - we should be using it to format the date! */
  // job: Omit<
  //   NonNullable<JobQuery['job']>,
  //   'timeZone' | 'enforceOrder' | 'tasks' | 'canAcceptOffer'
  // >;
};

function JobCardSm(props: JobCardSmProps) {
  if (!props.job) {
    return <Loading />;
  }

  const job = getFragmentData(SiteJobs_JobFragment, props.job);

  const hero = (
    <div className='flex flex-col justify-start pr-1 text-right leading-tight'>
      {job.status === JobStatus.Complete ? (
        <p className='truncate text-sm font-medium leading-tight text-grey-70 md:text-[15px] '>
          {formatDateInTimeZone(job.completedAt, job.timeZone, 'h:mm a')}
        </p>
      ) : (
        <>
          <p className='truncate text-sm font-medium leading-tight text-grey-70 md:text-[15px] '>
            {formatDateInTimeZone(job.scheduleStart, job.timeZone, 'h:mm a')}
          </p>
          {job.scheduleEnd && (
            <p className='whitespace-nowrap text-xs text-grey-50'>
              to {formatDateInTimeZone(job.scheduleEnd, job.timeZone, 'h:mm a')}
            </p>
          )}
        </>
      )}
    </div>
  );

  return (
    <>
      <CardSmall
        linkTo={`jobs/${job.id}`}
        titleText={job.name}
        secondaryText={job.location?.name}
        heroElement={hero}
        user={{ name: job.assignee?.name ?? 'U' }}
        status={job.status}
      />
    </>
  );
}
