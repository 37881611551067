import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { ErrorMessage } from '~/components/ui/Error';
import {
  JobHistoryQuery,
  JobStatus,
  useJobHistoryQuery,
} from '~/generated/graphql';
import { JobDocument } from '~/gql/graphql';
import { formatDate as baseFormat } from '~/helpers/formatDate';
import { SideLayout } from '~/layouts/side/SideLayout';
import { Job } from '../index';
import { HistoryEntry } from './HistoryEntry';

export type TJobHistory = NonNullable<JobHistoryQuery['job']>['history'][0] & {
  previousStatus: JobStatus;
};

export const formatDate = (date: string | undefined) => baseFormat(date, 'PP');

export function extractDate(iso: string) {
  return formatDate(iso);
}

export function isSameDate(
  a: string | null | undefined,
  b: string | null | undefined
) {
  if (!(a && b)) return true;
  return extractDate(a) === extractDate(b);
}

export function JobHistory() {
  const navigate = useNavigate();
  const { t } = useTranslation('job');

  const { jobId } = useParams();

  const [result] = useQuery({ query: JobDocument, variables: { id: jobId! } });

  const job = result.data?.job;

  function handleClose() {
    navigate(-1);
  }

  if (!job) return <ErrorMessage message='Job not found' />;

  return (
    <SideLayout>
      <SideLayout.Head onClose={handleClose}>
        {t('statusHistory')}
      </SideLayout.Head>
      <SideLayout.Body>
        <div className='flex flex-col gap-5 p-4 pb-8 pt-2'>
          <HistoryEntries job={job} />
        </div>
      </SideLayout.Body>
    </SideLayout>
  );
}

type HistoryEntriesProps = {
  job: Job;
};

const HistoryEntries = ({ job }: HistoryEntriesProps) => {
  let [result] = useJobHistoryQuery({
    requestPolicy: 'cache-and-network',
    variables: { jobId: job.id },
  });

  const history = addPreviousStateToHistory(result.data);

  return (
    <>
      {history.map((entry, i) => (
        <Fragment key={entry.id}>
          {i > 0 ? (
            !isSameDate(
              entry.inputDate ?? entry.createdAt,
              history[i - 1].inputDate ?? history[i - 1].createdAt
            ) && <DateTitle date={entry.inputDate ?? entry.createdAt} />
          ) : (
            <DateTitle date={entry.inputDate ?? entry.createdAt} />
          )}
          <HistoryEntry tasks={job.tasks} key={entry.id} entry={entry} />
        </Fragment>
      ))}
    </>
  );
};

function addPreviousStateToHistory(data: JobHistoryQuery | undefined) {
  const history: TJobHistory[] = [];
  const prev: Record<string, JobStatus> = {};

  data?.job?.history.forEach((entry) => {
    const previousStatus = prev[entry.task?.id ?? 'NULL'] ?? 'Created';
    prev[entry.task?.id ?? 'NULL'] = entry.status;
    history.push({
      ...entry,
      previousStatus,
    });
  });

  return history;
}

type DateTitleProps = {
  date: string;
};

function DateTitle({ date }: DateTitleProps) {
  return (
    <span className=' mt-3 self-center rounded-xl bg-grey-10 px-3 py-0.5 text-xs font-semibold text-grey-70'>
      {extractDate(date)}
    </span>
  );
}
