import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AppContext } from '~/App';
import { Input } from '~/components/form/Input';
import { useChangeEmailMutation } from '~/generated/graphql';
import { Page } from '.';

type Props = {
  setShowPage: (page: Page) => void;
};

type FormData = {
  email: string;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email('This is not a valid Email').required('Required'),
});

export const UpdateEmailForm = ({ setShowPage }: Props) => {
  const { user: currentUser } = useContext(AppContext);

  const { t } = useTranslation();

  const [, changeEmail] = useChangeEmailMutation();
  const [newEmail, setNewEmail] = useState<string | null>(
    currentUser?.newEmail ?? null
  );

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<FormData>({
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const submit = async (values: FormData) => {
    const result = await changeEmail({ input: { email: values.email } });
    if (!result.error) {
      setNewEmail(values.email);
    }
  };

  return (
    <div className='flex w-full grow flex-col'>
      <div>
        <h2 className='text-md font-medium'>Current Email</h2>
        <p className='text-copy-alt pt-2'>
          Your current email is{' '}
          <span className='font-medium text-black'>{currentUser?.email}</span>
          {currentUser?.emailVerified ? (
            <span className='bg-success ml-2 inline-flex items-center gap-1 rounded-full pl-1 pr-2 font-medium text-white'>
              <CheckCircleIcon className='h-4 w-4' />
              Verified
            </span>
          ) : (
            <span className='bg-orangeAlert ml-2 inline-flex items-center gap-1 rounded-full pl-1 pr-2 font-medium text-white'>
              <XCircleIcon className='h-4 w-4' />
              Not Verified
            </span>
          )}
        </p>
        {newEmail === null ? (
          <form onSubmit={handleSubmit(submit)} className='pt-8'>
            <div>
              <Input
                {...register('email')}
                label={t('newEmail')}
                type='text'
                error={errors.email?.message}
              />
            </div>
            <button
              className='border-brand hover:border-slate-dark text-brand hover:text-slate-dark mt-auto rounded-lg border py-4 px-8 text-base font-semibold leading-4 transition-all duration-300  disabled:opacity-50'
              disabled={isSubmitting}
            >
              {t('saveChanges')}
            </button>
          </form>
        ) : (
          <div className='bg-blueAlert mt-10 bg-opacity-20 py-6 px-8'>
            <div className='flex items-center gap-4 pb-2'>
              <div className='bg-blue flex h-7 w-7 items-center justify-center rounded-full font-medium text-white'>
                i
              </div>
              <p className='font-medium'>Update pending verification</p>
            </div>
            <p className='text-copy-alt'>
              We just need to verify your email{' '}
              <span className='font-medium text-black'>{newEmail}</span>. Check
              your email to complete the update.
            </p>
            {/*
            <div className='flex gap-5 pt-2'>
              <button className='text-blue hover:text-brand cursor-pointer font-medium underline transition-all duration-300'>
                Resend Email
              </button>
              <button
                onClick={() => setHasSubmited(false)}
                className='text-blue hover:text-brand cursor-pointer font-medium underline transition-all duration-300'
              >
                Undo Change
              </button>
            </div>
            */}
          </div>
        )}

        <h2 className='text-md pt-10 font-medium'>Email Notifications</h2>
        <p className='text-copy-alt pt-2'>
          To manage what emails you receive, visit the {''}
          <span
            onClick={() => setShowPage('notifications')}
            className='text-blue hover:text-brand cursor-pointer font-medium underline transition-all duration-300'
          >
            notification preferences
          </span>
        </p>
      </div>
    </div>
  );
};
