import { useField } from 'formik';
import { Combobox } from './Combobox';

type Props = {
  name: string;
  label?: string;
  items: string[];
  disabled?: boolean;
  required?: boolean;
};

export const ComboboxField = ({ name, ...rest }: Props) => {
  const [field, meta, helpers] = useField(name);

  function handleChange(value: string) {
    helpers.setValue(value);
  }

  return (
    <>
      <Combobox value={field.value} onChange={handleChange} {...rest} />
      {meta.touched && meta.error && (
        <p className='ErrorMessage'>{meta.error}</p>
      )}
    </>
  );
};
