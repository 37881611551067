import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionFunctionArgs, useFetcher } from 'react-router-dom';
import { client } from '~/client';
import { graphql } from '~/gql';

type Props = {
  latest?: string;
  unread: number;
};

const MarkAllAsRead = graphql(/* GraphQL */ `
  mutation MarkAllAsRead($before: ID!) {
    markAllAsRead(before: $before)
  }
`);

export async function markAllAsRead({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const before = formData.get('before') as string;

  if (!before) {
    throw new Response('Missing before parameter', { status: 400 });
  }

  const result = await client.mutation(MarkAllAsRead, { before }).toPromise();

  if (result.data?.markAllAsRead && result.data?.markAllAsRead > 0) {
    // return redirect('/activity');
    // XXX hard refresh until we can figure out how to update the cache
    window.location.href = '/activity';
  }

  return result.data?.markAllAsRead;
}

export function MarkAllAsReadButton({ latest, unread }: Props) {
  const fetcher = useFetcher();
  const allRead = unread === 0;
  const submitting = fetcher.state === 'submitting';

  function handleClick() {
    fetcher.submit(
      { before: latest ?? '' },
      { method: 'post', action: '/activity/mark-all-as-read' }
    );
  }

  return (
    <button
      className='ml-auto flex items-center gap-2 rounded border border-grey-20 p-1 text-xs text-secondary lg:p-2 lg:text-base'
      onClick={handleClick}
      disabled={allRead || submitting}
    >
      <FontAwesomeIcon className='hidden lg:block' icon={faMessage} />
      {allRead ? 'All read!' : submitting ? 'Loading...' : 'Mark all as read'}
    </button>
  );
}
