import { faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Avatar } from '~/components/ui/Avatar';
import { JobStatus } from '~/generated/graphql';
import { formatDate as baseFormat } from '~/helpers/formatDate';
import { TJobHistory } from '.';
import { Task } from '../index';

type Props = {
  tasks: Task[];
  entry: TJobHistory;
};

export const formatTime = (date: string | undefined) => baseFormat(date, 'p');
export const formatDate = (date: string | undefined) =>
  baseFormat(date, "PP 'at' p");

function roundToMinute(date: string) {
  return date.slice(0, 16);
}

export function isSameDate(
  a: string | null | undefined,
  b: string | null | undefined
) {
  if (!(a && b)) return true;
  return roundToMinute(a) === roundToMinute(b);
}

export function HistoryEntry({ tasks, entry }: Props) {
  return (
    <div className='flex gap-5'>
      {entry.createdBy ? (
        <Avatar
          image={entry.createdBy.image}
          name={entry.createdBy.name}
          size='small'
        />
      ) : (
        // TODO support passing icon as image to Avatar
        <FontAwesomeIcon icon={faGears} className='h-8 w-8' />
      )}
      <EntryDetails task={tasks} entry={entry} />
    </div>
  );
}

type EntryDetailsProps = {
  entry: TJobHistory;
  task?: Task[];
};

const EntryDetails = ({ entry, task }: EntryDetailsProps) => {
  const { t } = useTranslation('job');

  if (entry.task?.id) {
    return (
      <div className=''>
        <EntryTitle entry={entry} />
        <p className='text-grey-50 text-sm'>
          <span className='font-medium'>{entry.task.name}</span>{' '}
          {entry.status === JobStatus.Created
            ? t('feed.status.Reset')
            : t(`feed.status.${entry.status}`)}
        </p>
        {!isSameDate(entry.createdAt, entry.inputDate) && (
          <RecordedDate entry={entry} />
        )}
      </div>
    );
  } else if (entry.status === 'Created') {
    return (
      <div>
        <EntryTitle entry={entry} />
        <p className='text-grey-70 text-sm font-medium'>Created the Job</p>
      </div>
    );
  } else {
    return (
      <div>
        <EntryTitle entry={entry} />
        <p className='text-grey-70 text-sm font-medium'>
          Job {t(`feed.status.${entry.status}`)}
        </p>
        {!isSameDate(entry.createdAt, entry.inputDate) && (
          <RecordedDate entry={entry} />
        )}
      </div>
    );
  }
};

function EntryTitle({ entry }: { entry: TJobHistory }) {
  const { t } = useTranslation();
  return (
    <p className='text-base font-semibold'>
      {entry.createdBy?.name ?? t('automation')}{' '}
      <span className='text-grey-40 pl-1 text-xs font-normal'>
        {formatTime(entry.inputDate ?? entry.createdAt)}
      </span>
    </p>
  );
}

function RecordedDate({ entry }: { entry: TJobHistory }) {
  return (
    <p className='text-grey-40 py-0.5 text-sm italic'>
      Recorded: {formatDate(entry?.createdAt)}
    </p>
  );
}
