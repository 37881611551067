import { SVGProps } from 'react';

const SvgClockExclamationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='23'
    height='19'
    viewBox='0 0 23 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      id='Vector'
      d='M0.370117 9.49956C0.370117 6.28261 2.09563 3.31176 4.89605 1.70504C7.69648 0.0983202 11.1475 0.0983202 13.9479 1.70504C16.0801 2.92854 17.5899 4.94309 18.1875 7.25648C18.0955 7.25297 18.0001 7.24945 17.9081 7.24945C17.4131 7.24945 16.9322 7.3057 16.4725 7.41469C15.9422 5.64625 14.7506 4.11336 13.0993 3.16761C10.8222 1.85973 8.02178 1.85973 5.74467 3.16761C3.46755 4.47549 2.06734 6.88732 2.06734 9.49956C2.06734 12.1118 3.46755 14.5272 5.74467 15.8315C7.77427 16.9952 10.2246 17.1218 12.3462 16.2077C12.6078 16.7245 12.9367 17.1956 13.3256 17.6175C10.6489 18.8903 7.49493 18.7813 4.89605 17.2906C2.09563 15.6874 0.370117 12.7165 0.370117 9.49956ZM8.57338 4.71808C8.57338 4.25048 8.95172 3.87429 9.42199 3.87429C9.89226 3.87429 10.2706 4.25048 10.2706 4.71808V9.04954L12.4381 10.484C12.1623 10.9867 11.9537 11.5317 11.8299 12.1083L8.95172 10.2027C8.71481 10.0445 8.57338 9.78082 8.57338 9.49956V4.71808ZM12.8164 13.4373C12.8164 10.6422 15.0971 8.37451 17.9081 8.37451C20.7191 8.37451 22.9998 10.6422 22.9998 13.4373C22.9998 16.2323 20.7191 18.5 17.9081 18.5C15.0971 18.5 12.8164 16.2323 12.8164 13.4373ZM17.0595 15.9686C17.0595 16.4362 17.4378 16.8124 17.9081 16.8124C18.3784 16.8124 18.7567 16.4362 18.7567 15.9686C18.7567 15.501 18.3784 15.1248 17.9081 15.1248C17.4378 15.1248 17.0595 15.501 17.0595 15.9686ZM17.3424 10.6246V13.4373C17.3424 13.7466 17.597 13.9998 17.9081 13.9998C18.2193 13.9998 18.4739 13.7466 18.4739 13.4373V10.6246C18.4739 10.3152 18.2193 10.0621 17.9081 10.0621C17.597 10.0621 17.3424 10.3152 17.3424 10.6246Z'
      fill={props.color ? props.color : 'black'}
    />
  </svg>
);

export default SvgClockExclamationIcon;
