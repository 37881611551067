import { EditLink } from '~/components/nav/EditLink';
import { ErrorMessage } from '~/components/ui/Error';
import { Gallery } from '~/components/ui/Gallery';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { useDeploymentsQuery } from '~/generated/graphql';
import { formatDate } from '~/helpers/formatDate';
import { SideLayout } from '~/layouts/side/SideLayout';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const DeploymentView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ data }] = useDeploymentsQuery();

  const deployment = data && data.deployments.find((i) => i.id === id);

  if (!deployment) {
    return <ErrorMessage message='Not found' />;
  }

  return (
    <SideLayout>
      <SideLayout.Head
        rightSlot={<EditLink />}
        onClose={() => navigate('/deployments')}
      >
        {deployment.asset?.name}
      </SideLayout.Head>
      <SideLayout.Body>
        <div className='mb-5'>
          <StatusBadge value={deployment.status} rounded />
        </div>
        <dl>
          <dt className='label mb-1.5'>Current Location</dt>
          <dd className='mb-6'>{deployment.displayLocation}</dd>
          <dt className='label mb-1.5'>{t('assetType')}</dt>
          <dd className='mb-6'>{startCase(deployment.asset?.type)}</dd>
          {deployment.asset?.model && (
            <>
              <dt className='label mb-1.5'>{t('assetModel')}</dt>
              <dd className='mb-6'>{deployment.asset?.model.displayName}</dd>
            </>
          )}
          <dt className='label mb-1.5'>Deployed</dt>
          <dd className='mb-6'>{formatDate(deployment.startedAt)}</dd>
          {deployment.endedAt && (
            <>
              <dt className='label mb-1.5'>Undeployed</dt>
              <dd className='mb-6'>{formatDate(deployment.endedAt)}</dd>
            </>
          )}
          {/* <Attributes /> */}
          {/* <dt className='label mb-1.5'>{t('notes')}</dt>
          <dd className='mb-6'>{deployment.notes}</dd> */}
          <Gallery images={deployment.image} />
        </dl>
      </SideLayout.Body>
    </SideLayout>
  );
};
