import { Attributes } from '~/components/ui/Attributes';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import { PopupDialog } from '~/components/ui/PopupDialog';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import {
  SpaceSkuDetailQuery,
  useSpaceSkuDetailQuery,
} from '~/generated/graphql';
import { formatCurrency } from '~/helpers/formatCurrency';
import { useState } from 'react';
import { SpaceSkuForm } from './SpaceSkuForm';

type Props = {
  item: SpaceSkuDetailQuery['spaceSkus'][0];
};

type SpaceSkuPopupProps = {
  id?: string;
  onClose: () => void;
};

export function SpaceSkuView({ item }: Props) {
  return (
    <div className='p-4'>
      <StatusBadge value={item.status} />
      <ReadValue label='onHand'>{item.soh}</ReadValue>
      <ReadValue label='capacity'>{item.capacity}</ReadValue>
      <ReadValue label='target'>{item.target}</ReadValue>
      <ReadValue label='reorderLevel'>{item.reorderLevel}</ReadValue>
      <ReadValue label='price'>{formatCurrency(item.price)}</ReadValue>
      <Attributes data={item} />
    </div>
  );
}

export function SpaceSkuPopup({ id, onClose }: SpaceSkuPopupProps) {
  const [edit, setEdit] = useState(false);
  const [result] = useSpaceSkuDetailQuery({
    pause: !id,
    variables: { id: id ?? '' },
  });
  const { data, error, fetching } = result;
  const item = data?.spaceSkus[0];

  function handleCloseView() {
    if (!edit) onClose();
  }

  function handleCloseEdit() {
    setEdit(false);
  }

  return (
    <>
      <PopupDialog isOpen={Boolean(id) && !edit} onClose={handleCloseView}>
        <PopupDialog.Title onEdit={() => setEdit(true)}>
          <div className='text-center'>
            {item?.space.name}
            <br />
            {item?.sku.name}
          </div>
        </PopupDialog.Title>
        <div className=''>
          {error && <ErrorMessage message={error.message} />}
          {fetching && <Loading />}
          {item && <SpaceSkuView item={item} />}
        </div>
      </PopupDialog>
      <PopupDialog isOpen={edit} onClose={handleCloseEdit}>
        <PopupDialog.Title>
          <div className='text-center'>
            {item?.space.name}
            <br />
            {item?.sku.name}
          </div>
        </PopupDialog.Title>
        <div className='absolute top-20 left-0 right-0 bottom-0'>
          {error && <ErrorMessage message={error.message} />}
          {fetching && <Loading />}
          {item && <SpaceSkuForm item={item} onSuccess={handleCloseEdit} />}
        </div>
      </PopupDialog>
    </>
  );
}
