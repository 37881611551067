import { faHouseBuilding } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export function BuildingPlaceholder({ className }: { className?: string }) {
  return (
    <div
      className={classNames(
        'flex h-full w-full items-center justify-center rounded-md bg-shade-10 text-2xl  text-shade-40',
        className
      )}
    >
      <FontAwesomeIcon icon={faHouseBuilding} />
    </div>
  );
}
