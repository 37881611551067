import { AnyAbility, PureAbility } from '@casl/ability';
import { Can as BaseCan, BoundCanProps } from '@casl/react';
import { createContext, createElement as h } from 'react';
import { useAppContext } from '~/App';

export const AbilityContext = createContext(new PureAbility([]));

export const useAbilityContext = () => {
  const { ability } = useAppContext();
  return ability;
};

export const Can = (props: BoundCanProps<AnyAbility>) => {
  const { ability } = useAppContext();

  return h(BaseCan, { ability, ...props });
};
