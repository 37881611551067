import { useMatch } from 'react-router-dom';
import { useLocalStorage } from '~/hooks';
import { SubNav } from './SubNav';

export function AssetsNav() {
  const localStorage = useLocalStorage();
  const matchAssets = useMatch('/assets/*');

  const pages = [
    {
      title: 'nav:assets.index',
      url: '/assets',
      createButton: 'asset',
    },
    {
      title: 'nav:assets.deployments',
      url: '/deployments',
    },
    // {
    //   title: 'nav:assets.assetModels',
    //   url: '/asset-models',
    // },
  ];

  return (
    <SubNav
      pages={pages}
      match={matchAssets ? 0 : undefined}
      onItemClick={({ url }) => localStorage.set('nav.assets', url)}
    />
  );
}
