import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useVirtual } from 'react-virtual';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { BasicListItem, BasicListItemProps } from './BasicListItem';

interface IListItemProps extends BasicListItemProps {
  id: number | string;
}

type VirtualListProps<ItemType> = {
  data?: Array<ItemType & { id: string | number }>;
  getListItemProps: (
    item: ItemType & { id: string | number },
    scrollTo?: number | string | null
  ) => IListItemProps;
  placeholderIcon?: (item: ItemType) => React.ReactNode;
  scrollTo?: number | string;
};

function easeInOutQuint(t: number) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}

export function VirtualList<ItemType>({
  data,
  getListItemProps,
  placeholderIcon,
  scrollTo,
}: VirtualListProps<ItemType>) {
  const items = useMemo(() => data || [], [data]);
  const parentRef = useRef<HTMLDivElement>(null);
  const scrollingRef = useRef<number>();

  const { isMobile } = useBreakpoint();

  // https://github.com/tannerlinsley/react-virtual/blob/c833135c513f7fc9c205efaa017627e4c1f6b74b/examples/smooth-scroll/src/index.js
  const scrollToFn = useCallback(
    (offset: number, defaultScrollTo?: (offset: number) => void) => {
      if (!(parentRef.current && scrollingRef)) return;
      const duration = 1000;
      const start = parentRef.current.scrollTop;
      const startTime = (scrollingRef.current = Date.now());

      const run = () => {
        if (scrollingRef.current !== startTime) return;
        const now = Date.now();
        const elapsed = now - startTime;
        const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
        const interpolated = start + (offset - start) * progress;

        if (elapsed < duration) {
          defaultScrollTo && defaultScrollTo(interpolated);
          requestAnimationFrame(run);
        } else {
          defaultScrollTo && defaultScrollTo(interpolated);
        }
      };

      requestAnimationFrame(run);
    },
    []
  );

  const rowVirtualizer = useVirtual({
    size: items.length,
    parentRef,
    estimateSize: useCallback(() => (isMobile ? 64 : 98), [isMobile]),
    // overscan: 5,
    scrollToFn,
  });

  const { scrollToIndex } = rowVirtualizer;
  useEffect(() => {
    if (scrollTo) {
      const idx = items.findIndex(({ id }) => '' + id === '' + scrollTo);
      if (idx > -1) {
        scrollToIndex(idx);
      }
    }
  }, [items, scrollTo, scrollToIndex]);

  return (
    <>
      <div ref={parentRef} className='overflow-auto px-4 pb-24 pt-4 lg:pb-4'>
        <div
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => (
            <div
              key={items[virtualRow.index].id}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <BasicListItem
                {...getListItemProps(items[virtualRow.index], scrollTo)}
                placeholderIcon={placeholderIcon?.(items[virtualRow.index])}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
