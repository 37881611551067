import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Attributes } from '~/components/ui/Attributes';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { useSpaceSkuDetailQuery } from '~/generated/graphql';
import { formatCurrency } from '~/helpers/formatCurrency';
import { SideLayout } from '~/layouts/side/SideLayout';
import EditIcon from '../nav/edit';
import { SpaceSkuForm } from './SpaceSkuForm';

export function SpaceSkuView() {
  const [edit, setEdit] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => navigate('..');

  const { itemId } = useParams();

  const [result] = useSpaceSkuDetailQuery({
    pause: !itemId,
    variables: { id: itemId ?? '' },
  });

  const { data, error, fetching } = result;
  const item = data?.spaceSkus[0];

  function handleCloseEdit() {
    setEdit(false);
  }

  function handleOpenEdit() {
    setEdit(true);
  }

  if (!item) {
    return <Loading />;
  }

  return (
    <SideLayout className='lg:relative'>
      <div className='block'>
        <SideLayout.Head
          onClose={edit ? handleCloseEdit : handleClose}
          rightSlot={
            !edit && (
              <EditIcon
                onClick={handleOpenEdit}
                className='fill-grey-50 hover:fill-brand ml-auto h-5 w-5 cursor-pointer'
              />
            )
          }
        >
          <div className='text-center'>
            <p>{item?.sku.name}</p>
          </div>
        </SideLayout.Head>
      </div>
      <SideLayout.Body className='p-0'>
        {error && <ErrorMessage message={error.message} />}
        {fetching && <Loading />}
        {edit ? (
          <SpaceSkuForm item={item} onSuccess={handleCloseEdit} />
        ) : (
          <div className='px-4 text-left'>
            <StatusBadge value={item.status} />
            <ReadValue label='space'>{item?.space.name}</ReadValue>
            <ReadValue label='onHand'>{item.soh}</ReadValue>
            <ReadValue label='capacity'>{item.capacity}</ReadValue>
            <ReadValue label='target'>{item.target}</ReadValue>
            <ReadValue label='reorderLevel'>{item.reorderLevel}</ReadValue>
            <ReadValue label='price'>{formatCurrency(item.price)}</ReadValue>
            <Attributes data={item} />
          </div>
        )}
      </SideLayout.Body>
    </SideLayout>
  );
}
