import { useLoaderData, useNavigate } from 'react-router-dom';
import { client } from '~/client';
import { CreateJobForm } from '~/components/job/CreateJobForm';
import { graphql } from '~/gql';

const CreateJobFormDocument = graphql(`
  query CreateJobForm {
    integrations
    attributes(entityType: "Site") {
      id
      type
      category
      name
      value
      condition
    }
    jobNameOptions
    jobTemplates(status: Active) {
      ...JobTemplateFields
    }
    locations(status: Active) {
      __typename
      id
      name
      ... on Asset {
        deployment {
          id
          displayLocation
          defaultSourceId
        }
      }
    }
    contacts(status: Active) {
      ...ContactFields
    }
  }
`);

export async function loader() {
  const { data, error } = await client
    .query(CreateJobFormDocument, {}, { requestPolicy: 'cache-and-network' })
    .toPromise();

  if (error || !data) {
    throw new Response(error?.message, { status: 500 });
  }

  return data;
}

export default function CreateJobRoute() {
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const navigate = useNavigate();

  return <CreateJobForm data={data} onClose={() => navigate('..')} />;
}
