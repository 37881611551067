import { StockSelectionField } from '~/components/form/StockSelectionField';
import { SubmitButton } from '~/components/form/SubmitButton';
import { QuickAdd } from '~/components/QuickAdd';
import { Context } from '~/components/smc/card/Hero';
import { SkuInfo } from '~/components/smc/SkuInfo';
import { Card } from '~/components/ui/Card';
import { Formik, useFormikContext } from 'formik';
import { SkusQuery, StockSelection, useSkusQuery } from '~/generated/graphql';
import ChevronUp from '~/icons/chevron-up';
import { SideLayout } from '~/layouts/side/SideLayout';
import { toPairs } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseHero } from '../tasks/card/Hero';

type FormValues = {
  receive: StockSelection[];
};

type Props = {
  location?: string;
  dispatch: StockSelection[];
  initialValues: FormValues;
  onBack: () => void;
  onSubmit: (values: FormValues) => void;
};

export const StockToReceive = ({
  location,
  dispatch,
  initialValues,
  onBack,
  onSubmit,
}: Props) => {
  const [showQuickAdd, setShowQuickAdd] = useState(false);
  const [storedValues, setStoredValues] = useState<FormValues | null>(null);
  const { t } = useTranslation();

  const dispatchedSkus: string[] = useMemo(() => {
    return dispatch.map(({ id }) => id.split(':')[1]);
  }, [dispatch]);

  if (!location) return null;

  if (showQuickAdd) {
    return (
      <QuickAdd
        stockLocation={location}
        onCancel={() => setShowQuickAdd(false)}
        onSuccess={(d) => {
          setShowQuickAdd(false);
        }}
      />
    );
  }

  return (
    <Formik initialValues={storedValues ?? initialValues} onSubmit={onSubmit}>
      <SideLayout>
        <SideLayout.Head onClose={onBack} iconClass='chevron-left'>
          Stock to Receive
          <QuickAddButton
            onClick={(values) => {
              setStoredValues(values);
              setShowQuickAdd(true);
            }}
          />
        </SideLayout.Head>
        <SideLayout.Body className='flex-1 overflow-y-auto px-0 pt-4'>
          <p className='text-copy-alt mb-5 px-4 text-sm font-bold tracking-wide'>
            Stock to go to {t(location.split(':')[0])}
          </p>
          <StockSelectionField
            context={Context.MANUAL_DEST}
            name='receive'
            location={location}
            in={dispatchedSkus}
          />
        </SideLayout.Body>
        <SideLayout.Foot>
          <Unallocated dispatch={dispatch} />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
};

type QuickAddButtonProps = {
  onClick: (values: FormValues) => void;
};

const QuickAddButton = ({ onClick }: QuickAddButtonProps) => {
  const { values } = useFormikContext<FormValues>();

  return (
    <button
      type='button'
      className='bg-brand absolute top-4 right-4 h-8 w-8 rounded-full text-center leading-none text-white shadow-md'
      style={{ fontSize: '2rem' }}
      onClick={() => onClick(values)}
    >
      +
    </button>
  );
};

const Unallocated = ({ dispatch }: { dispatch: StockSelection[] }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [{ data }] = useSkusQuery();

  const {
    values: { receive },
  } = useFormikContext<FormValues>();

  const sum: Record<string, number> = useMemo(() => {
    const left = dispatch.reduce((prev, curr) => {
      const skuId = curr.id.split(':')[1];
      return { ...prev, [skuId]: (prev[skuId] ?? 0) + curr.qty };
    }, {} as Record<string, number>);

    return receive.reduce((prev, curr) => {
      const skuId = curr.id.split(':')[1];
      return { ...prev, [skuId]: (prev[skuId] ?? 0) - curr.qty };
    }, left);
  }, [dispatch, receive]);

  const invalid = toPairs(sum).filter(([id, qty]) => qty !== 0);

  if (invalid.length === 0) {
    return (
      <p className='p-4'>
        <SubmitButton />
      </p>
    );
  }

  return (
    <div className='bg-white'>
      <div
        className='bg-orange relative bottom-0 w-full cursor-pointer uppercase'
        onClick={() => setOpen((state) => !state)}
      >
        <div className='p-4 text-center font-medium text-white'>
          {t('unallocated', { count: invalid.length })}
        </div>
        <div
          className={[
            'absolute inset-y-0 right-5 transform pt-4',
            open ? 'rotate-180' : '',
          ].join(' ')}
        >
          <ChevronUp className='icon-white h-4 w-4' />
        </div>
      </div>
      {open && (
        <div className='overflow-y-auto p-4' style={{ maxHeight: 328 }}>
          {invalid.map(([id, qty]) => {
            return (
              <UnallocatedCard
                key={id}
                id={id}
                qty={qty}
                sku={data?.skus.find((sku) => sku.id === id)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

type UnallocatedCardProps = {
  id: string;
  qty: number;
  sku?: SkusQuery['skus'][0];
};

const UnallocatedCard = ({ id, qty, sku }: UnallocatedCardProps) => {
  const { t } = useTranslation();

  const info = {
    name: sku?.name ?? '',
    code: sku?.code,
    itemType: sku?.itemType,
    image: sku?.image,
  };
  return (
    <Card color='smoke' className='border border-gray-200'>
      <div className='flex justify-between p-4'>
        <SkuInfo {...info} />
        <BaseHero
          number={Math.abs(qty)}
          directive={qty > 0 ? t('Unallocated') : t('Overallocated')}
        />
      </div>
    </Card>
  );
};
