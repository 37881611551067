import { Dialog, Transition } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/solid';
import React, { Fragment, createContext, useContext } from 'react';

type TContext = {
  onClose?: () => void;
};

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
};

type TitleProps = {
  children: React.ReactNode;
  /** Edit button clicked */
  onEdit?: () => void;
};

const Context = createContext({} as TContext);

function usePopupDialogContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Must be rendered within PopupDialogContext');
  }
  return context;
}

function Title({ children, onEdit }: TitleProps) {
  const { onClose } = usePopupDialogContext();
  return (
    <div className='grid w-full grid-cols-[35px_minmax(0,_1fr)_35px] items-center justify-center bg-white p-4 '>
      <button
        type='button'
        onClick={onClose}
        className='x col-span-1 text-copy-nav hover:text-brand'
      ></button>
      <div className='col-span-1 mx-1 flex-1 overflow-hidden break-words text-center text-xl font-medium text-copy-head'>
        {children}
      </div>
      {onEdit && (
        <div className='col-span-1 pb-1 text-right'>
          <button
            type='button'
            onClick={() => onEdit()}
            className='hover:text-brand'
          >
            <PencilIcon className='h-5 w-5 fill-copy-nav hover:fill-brand' />
          </button>
        </div>
      )}
    </div>
  );
}

export function PopupDialog({ children, isOpen, onClose, title }: Props) {
  return (
    <Context.Provider value={{ onClose }}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 z-[100]' onClose={onClose}>
          <div className='min-h-full text-center md:px-4'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-400'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay
                onClick={onClose}
                className='fixed inset-0 bg-slate-800 bg-opacity-40 lg:bg-opacity-20'
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300 origin-bottom lg:origin-center'
              enterFrom='opacity-0 scale-y-0 lg:scale-y-100'
              enterTo='opacity-100 scale-y-100'
              leave='ease-in duration-300 origin-bottom'
              leaveFrom='opacity-100 scale-y-100'
              leaveTo='opacity-0 scale-y-0 lg:scale-y-100'
            >
              <div className='absolute left-0 right-0 top-0 my-3 ml-auto mr-auto flex h-full w-full items-center justify-center text-left lg:top-auto lg:max-w-[828px]'>
                <div className='relative h-full w-full overflow-y-auto rounded-t-2xl bg-white lg:h-[calc(100vh_-_5rem)] lg:rounded-2xl'>
                  {title && <PopupDialog.Title>{title}</PopupDialog.Title>}
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Context.Provider>
  );
}

PopupDialog.Title = Title;
