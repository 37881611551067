import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { HTMLProps, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FindAddContact } from '~/components/FindAddContact';
import { Button } from '~/components/form/SubmitButton';
import { Confirm } from '~/components/ui/Confirm';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import {
  ContactsQuery,
  ListUsersQuery,
  useInviteUserMutation,
  useListUsersQuery,
  useRemoveUserMutation,
} from '~/generated/graphql';
import { useBreakpoint } from '~/hooks/useBreakpoint';

type User = ListUsersQuery['users'][number];

type FormData = {
  contacts: ContactsQuery['contacts'];
};

export const Team = () => {
  const [result, reexecuteQuery] = useListUsersQuery({
    requestPolicy: 'network-only',
    variables: {},
  });
  const { data, error, fetching } = result;

  const reload = () => reexecuteQuery({ requestPolicy: 'network-only' });
  const users = data?.users ?? [];

  const [memberToRemove, setMemberToRemove] = useState<User | null>(null);
  const [deletedMembers, setDeletedMembers] = useState<string[]>([]);
  const [removeResult, removeUser] = useRemoveUserMutation();
  const isRemoved = Boolean(removeResult.data?.removeUser);

  useEffect(() => {
    if (isRemoved && memberToRemove?.email) {
      setDeletedMembers((prev) => [...prev, memberToRemove.email]);
      setMemberToRemove(null);
    }
  }, [isRemoved]);

  async function handleRemove() {
    if (!memberToRemove?.email) return;
    await removeUser({ email: memberToRemove.email });
  }

  if (error) return <ErrorMessage />;
  if (fetching) return <Loading />;

  return (
    <>
      <p className='mb-10'>
        Grant another user access to administer your account. Once they accept
        they will have <b>full access</b> to your account and will receive
        notifications. You can remove them at any time.
      </p>

      {users.length > 0 && (
        <>
          <p className='mb-4 text-lg font-semibold'>Current administrators</p>
          <table className='mb-16 w-full table-auto border-collapse'>
            <tbody>
              {users.map((user) => (
                <UserRow
                  key={`${user.__typename}:${user.id}`}
                  user={user}
                  onRemoveClick={(user) => setMemberToRemove(user)}
                  deletedMembers={deletedMembers}
                />
              ))}
            </tbody>
          </table>
          <p className='mb-4 text-lg font-semibold'>
            Add another administrator
          </p>
        </>
      )}

      <InviteUserForm
        onSuccess={() => {
          reload();
          setDeletedMembers([]);
        }}
      />

      <Confirm
        show={!!memberToRemove}
        title='Remove Administrator'
        cancel='Cancel'
        confirm='DELETE'
        onCancel={() => setMemberToRemove(null)}
        onConfirm={() => handleRemove()}
        isLoading={removeResult.fetching}
        danger
      >
        <p className='text-center'>
          <span className='font-semibold'>{memberToRemove?.email ?? ''}</span>{' '}
          will be removed
        </p>
      </Confirm>
    </>
  );
};

function InviteUserForm({ onSuccess }: { onSuccess: () => void }) {
  const [graphqlError, setGraphqlError] = useState<string>();
  const { t } = useTranslation();
  const [{ error, fetching }, inviteUser] = useInviteUserMutation();
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: { contacts: [] },
    resolver: yupResolver(
      Yup.object().shape({
        contacts: Yup.array().min(1, 'Required').required(),
      })
    ),
  });
  const { isMobile } = useBreakpoint();

  // Use an effect to copy graphql error message to local state
  // Doing this enables clearing the error message independently from the request,
  // e.g. when the form changes
  useEffect(() => {
    setGraphqlError(error?.message?.replace('[GraphQL] ', ''));
  }, [error]);

  async function onSubmit(data: FormData) {
    if (!data.contacts[0].email) return;
    const result = await inviteUser({
      email: data.contacts[0].email,
    });

    if (result.data?.inviteUser) {
      reset();
      onSuccess();
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name='contacts'
        render={({ field: { value, onChange } }) => (
          <FindAddContact
            optionsBoxHeight={
              isMobile
                ? 'max-h-[calc(100vh-180px)]'
                : 'max-h-[calc(100vh-300px)]'
            }
            error={errors.contacts?.message || graphqlError}
            value={value}
            onChange={(newValue) => {
              setGraphqlError(undefined);
              onChange(newValue);
            }}
          />
        )}
      />

      <div className='mt-4 max-w-full pb-24 lg:max-w-[40%]'>
        <Button type='submit' loading={fetching} intent='secondary'>
          {t('sendInvitation')}
        </Button>
      </div>
    </form>
  );
}

function UserRow({
  user,
  onRemoveClick,
  deletedMembers,
}: {
  user: User;
  onRemoveClick: (user: User) => void;
  deletedMembers: string[];
}) {
  const { t } = useTranslation();

  const invited = user.__typename === 'Invitation';

  if (deletedMembers.includes(user.email)) {
    return (
      <tr className='border border-[#D3D3D3] bg-[#EB57571A]'>
        <td colSpan={5} className='p-3 text-center'>
          {invited ? user.email : user.name} has successfully been removed from
          your co-hosts.
        </td>
      </tr>
    );
  }

  return (
    <tr
      className={classNames({
        'border border-[#94E1FF] bg-[#E7F4F9]': invited,
      })}
    >
      {invited ? (
        <>
          <td className='p-2'>{user.email}</td>
          <td className='p-2'>Waiting for confirmation</td>
          <td className='p-2'>
            <ResendButton email={user.email} />
          </td>
        </>
      ) : (
        <>
          <td className='p-2'>{user.name}</td>
          <td className='p-2'>{user.email}</td>
          <td></td>
        </>
      )}
      {user.__typename === 'User' && user.isOwner ? (
        <td className='p-2 text-right'>
          <span className='font-bold text-brand'>Owner</span>
        </td>
      ) : (
        <>
          <td className='p-2 text-right'>
            <span className='font-bold text-brand'>{t('standard')}</span>
          </td>
          <td className='p-2'>
            <div
              onClick={() => onRemoveClick(user)}
              className='my-1 flex h-8 w-[50px] cursor-pointer items-center justify-center rounded-3xl border border-grey-10 text-grey-20 shadow transition-all hover:text-red-600'
            >
              <FontAwesomeIcon className='h-5 w-4' icon={faTrashCan} />
            </div>
          </td>
        </>
      )}
    </tr>
  );
}

function ResendButton({ email }: { email: string }) {
  const { t } = useTranslation();
  const [result, resend] = useInviteUserMutation();
  const { data, error, fetching } = result;

  if (data?.inviteUser) {
    return <span>{t('sent')}</span>;
  }

  if (error) {
    return <span>{t('error')}</span>;
  }

  return (
    <ActionButton onClick={() => resend({ email })} disabled={fetching}>
      {fetching ? t('sending') + '...' : t('resendEmail')}
    </ActionButton>
  );
}

function ActionButton(props: HTMLProps<HTMLButtonElement>) {
  return (
    <button
      {...props}
      type='button'
      className='cursor-pointer text-blue underline'
    />
  );
}
