import { useLocalStorage } from '~/hooks';
import { SubNav } from './SubNav';

export const StockNav = () => {
  const localStorage = useLocalStorage();

  const pages = [
    {
      title: 'stock',
      url: '/stock-on-hand',
    },
    {
      title: 'movements',
      url: '/stock-movements',
    },
    {
      title: 'item_plural',
      url: '/items',
    },
    {
      title: 'Configuration',
      url: '/config',
    },
  ];

  return (
    <SubNav
      pages={pages}
      onItemClick={({ url }) => localStorage.set('nav.items', url)}
    />
  );
};
