import classNames from 'classnames';
import { usePickerContext } from './PickerContext';

type Props = {
  children: React.ReactNode;
  id: string;
  className?: string;
  onClick: () => void;
};

export function Button({ children, id, onClick, className }: Props) {
  const { value } = usePickerContext();

  return (
    <button
      className={classNames(
        'flex-1 p-3 leading-5',
        value && id === value ? 'bg-[#1e5d80] text-white' : 'font-medium',
        className
      )}
      value={id}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
