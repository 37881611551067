import { groupBy } from 'lodash';
import { Fragment, useEffect } from 'react';
import { useQuery } from 'urql';
import { AttributesQuery } from '~/generated/graphql';
import { getFragmentData, graphql } from '~/gql';
import {
  Attachment,
  Attribute_AttributeFragmentFragmentDoc,
} from '~/gql/graphql';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useSearch } from '~/hooks/useSearch';
import { useContacts } from '~/routes/resources/contacts';
import { AttributeInput, byName, parseCondition } from './AttributeInput';

export type Attribute = Omit<
  AttributesQuery['attributes'][number],
  '__typename'
>;

type Props = {
  value: Record<string, string>;
  onChange: (value: Record<string, string>) => void;
  /** List of attribute IDs to accept inputs for (and display to the user) */
  accept?: string[];
  searchValue?: string;
  readMode?: boolean;
  attachments?: Record<string, Attachment[]>;
};

// TODO move into a top level route or resource
const SiteAttributesData_Query = graphql(`
  query SiteAttributesData_Query {
    ...GlobalAttributes_QueryFragment
  }
`);

const GlobalAttributes_QueryFragment = graphql(`
  fragment GlobalAttributes_QueryFragment on Query {
    attributes(entityType: "Site") {
      id
      ...Attribute_AttributeFragment
    }
  }
`);

const SEARCH_OPTIONS = {
  keys: ['name', 'value', 'category'],
};

export function AttributeFormData({
  value: values,
  onChange,
  accept,
  searchValue = '',
  readMode,
  attachments,
}: Props) {
  const { isMobile } = useBreakpoint();
  const [result] = useQuery({ query: SiteAttributesData_Query });
  const query = getFragmentData(GlobalAttributes_QueryFragment, result.data);
  const { results, search } = useSearch(query?.attributes, SEARCH_OPTIONS);
  const contacts = useContacts();

  useEffect(() => {
    if (searchValue.length) {
      search(searchValue);
    }
  }, [searchValue]);

  if (!result.data) {
    return null;
  }

  const isAccepted = (id: string) => {
    if (accept && accept.length > 0) {
      return accept.includes(id);
    }
    return true;
  };

  const isAvailable = (condition: [string, string]) => {
    const [name, value] = condition;
    const found = query?.attributes.find(byName(name));
    if (found && values[found.id] !== value) {
      return false;
    }
    return true;
  };

  const handleChange = (id: string) => (value: string) => {
    if (value === undefined) {
      return;
    }
    onChange({ ...values, [id]: value || '' });
  };

  const filteredResults = results?.filter((attr) => {
    const condition = parseCondition(attr);
    if (!isAccepted(attr.id)) {
      return false;
    }
    if (condition && !isAvailable(condition)) {
      return false;
    }

    return !attr.id.startsWith('integration_');
  });

  const groupedByCategory = groupBy(filteredResults, (a) => {
    const attribute = getFragmentData(
      Attribute_AttributeFragmentFragmentDoc,
      a
    );
    return attribute.category ?? 'General';
  });

  return (
    <div className='pb-48'>
      {Object.keys(groupedByCategory).map((key) => (
        <Fragment key={key}>
          <div className='pb-1 pt-3 text-base font-semibold text-primary'>
            {key}
          </div>

          {groupedByCategory[key].map((attribute) => {
            return (
              <AttributeInput
                key={attribute.id}
                attribute={attribute}
                value={
                  values && values[attribute.id] ? values[attribute.id] : ''
                }
                onChange={handleChange(attribute.id)}
                readMode={readMode}
                attachments={attachments?.[attribute.id]}
                contacts={contacts}
              />
            );
          })}
        </Fragment>
      ))}
    </div>
  );
}
