import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import { Button } from '~/components/form/SubmitButton';
import { Dialog } from '~/components/ui/Dialog';
import { Switch } from '~/components/ui/nucleus/Switch';
import { graphql } from '~/gql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { DateTime } from '../DateTime';

type Props = {
  selected: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

const CompleteJobs_Mutation = graphql(`
  mutation CompleteJobs($input: CompleteJobsInput!) {
    completeJobs(input: $input) {
      id
      status
      completedAt
      permissions
    }
  }
`);

export function BulkComplete({ selected, onCancel, onSuccess }: Props) {
  const { t } = useTranslation();
  const [result, completeJobs] = useMutation(CompleteJobs_Mutation);

  // Confirmation dialog state
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [endTime, setEndTime] = useState<string | null>(null);

  const confirm = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (result.error) return;
    if (result.data) onSuccess();
  }, [result, onSuccess]);

  const loading = result.fetching;

  return (
    <>
      <Dialog
        title='Bulk Change Warning!'
        show={isOpen}
        onClose={() => {
          if (loading) return;
          setIsOpen(false);
        }}
      >
        <div className='flex flex-col flex-wrap gap-3 px-5'>
          <p className='px-2 pt-3'>
            You are about to mark as complete
            <br />
            <span className='font-semibold'>{selected.length}</span> jobs.
          </p>
          <div className='flex gap-3 self-end pb-5 pt-1'>
            <button
              className={classNames(
                'px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand',
                {
                  'text-grey-40 hover:text-grey-40': loading,
                }
              )}
              onClick={() => {
                setIsOpen(false);
              }}
              disabled={loading}
            >
              Go back
            </button>
            <Button
              type='button'
              loading={loading}
              size='small'
              onClick={() => {
                completeJobs({
                  input: {
                    jobIds: selected,
                    endTime: endTime,
                  },
                });
              }}
            >
              {loading ? t('loading') : 'Go Ahead'}
            </Button>
          </div>
        </div>
      </Dialog>
      <SideLayout>
        <SideLayout.Head iconClass='chevron-left' onClose={onCancel}>
          Bulk Change
        </SideLayout.Head>
        <SideLayout.Body>
          <p className='my-4'>
            You are about to mark{' '}
            <span className='font-semibold'>{selected.length}</span> jobs as
            Complete.
          </p>
          <p className='mb-4'>The Completion Date will default to:</p>
          <ol className='mb-4 ml-4 list-decimal'>
            <li className='mb-1'>Actual Start if job has been started</li>
            <li className='mb-1'>Scheduled End if set and job not started</li>
            <li className='mb-1'>
              Scheduled Start if set and job not started and Scheduled End not
              set
            </li>
            <li className='mb-1'>
              Current time if job not started and neither Scheduled Start nor
              Scheduled End set
            </li>
          </ol>
          <div className='mb-3'>
            <Switch
              label='Override default Completion Dates'
              active={endTime !== null}
              onChange={(checked) =>
                setEndTime(checked ? new Date().toISOString() : null)
              }
            />
          </div>
          {endTime && (
            <DateTime
              timeZone='Australia/Brisbane'
              label='End Time'
              value={endTime}
              onChange={setEndTime}
            />
          )}
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <Button type='button' intent='primary' onClick={confirm}>
            Complete Jobs
          </Button>
        </SideLayout.Foot>
      </SideLayout>
    </>
  );
}
