import { SVGProps } from 'react'

const SvgSites = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    strokeWidth={2}
    viewBox='0 0 32 32'
    {...props}
  >
    <path
      d='M16,2C9.919,2,5,6.382,5,11.8,5,19.15,16,30,16,30S27,19.15,27,11.8C27,6.382,22.081,2,16,2Zm0,13.3a3.734,3.734,0,0,1-3.929-3.5A3.734,3.734,0,0,1,16,8.3a3.734,3.734,0,0,1,3.929,3.5A3.734,3.734,0,0,1,16,15.3Z'
      fill='none'
    />
  </svg>
)

export default SvgSites
