import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Form, Formik, useFormikContext } from 'formik';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { TextField } from '~/components/form/TextField';
import { SelectField } from '~/components/form/downshift/SelectField';
import {
  ItemType,
  LocationQuery,
  QuickAddMutation,
  SkusQuery,
  Status,
  useLocationQuery,
  useQuickAddMutation,
  useSkusQuery,
} from '~/generated/graphql';
import { useEnumOptions } from '~/hooks/useEnumOptions';
import { SideLayout } from '~/layouts/side/SideLayout';
import { Option } from '~/types';
import { PromptEffect } from './Prompt';
import { SessionStorageEffect } from './form/SessionStorageEffect';
import { StockLocationField } from './form/StockLocation';
import { SubmitButton } from './form/SubmitButton';
import { RadioGroupField } from './form/checkradio/CheckboxGroup';
import { ComboboxField } from './ui/Combobox';
import { Loading } from './ui/Loading';
import { UploadField } from './upload/Upload';

const QuickAddSchema = Yup.object().shape({
  location: Yup.string()
    .matches(/^[A-Za-z]+:\d+$/, 'Required')
    .required('Required'),
  spaceName: Yup.string().required('Required'),
  createNew: Yup.string().oneOf(['0', '1']).required('Required'),
  skuId: Yup.array().when('createNew', {
    is: '0',
    then: Yup.array()
      .min(1, 'Required')
      .of(Yup.string().required('Required'))
      .required('Required'),
  }),
  itemName: Yup.string().when('createNew', {
    is: '1',
    then: Yup.string().min(1, 'Required').required('Required'),
  }),
  itemType: Yup.string().when('createNew', {
    is: '1',
    then: Yup.string().oneOf(Object.values(ItemType)).required('Required'),
  }),
  price: Yup.number().positive('Must be positive'),
  capacity: Yup.number()
    .min(0, 'Must be a positive number')
    .when('location.type', (type: string, schema: Yup.NumberSchema) =>
      type === 'Asset' ? schema.required('Required') : schema
    ),
  target: Yup.number()
    .min(0, 'Must be a positive number')
    .when('location.type', (type: string, schema: Yup.NumberSchema) =>
      type === 'Asset' ? schema.required('Required') : schema
    )
    .when('capacity', (capacity, schema) => {
      if (capacity) {
        return schema.max(capacity, 'Must be less than or equal to capacity');
      }
    }),
});

type TContext = {
  location?: LocationQuery['location'];
  setLocationId: (id: string) => void;
};
// Don't want the form provider to re-render when it changes the location and
// multiple form children use location so make it available via a child context
const QuickAddContext = createContext<TContext>({} as TContext);

type Props = {
  stockLocation?: string;
  onCancel?: () => void;
  onSuccess?: (data: QuickAddMutation) => void;
};

export const QuickAdd = ({
  stockLocation,
  onCancel: onClose,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const [{ data: skusData }] = useSkusQuery();
  const [, quickAdd] = useQuickAddMutation();

  // Filter out skus that already exist in selected space
  const [filterSkus, setFilterSkus] = useState<string[]>([]);
  const skuOptions = useMemo(() => {
    return skusData?.skus
      .filter(
        ({ id, item }) =>
          !filterSkus.includes(id) && item.status === Status.Active
      )
      .map((sku) => ({
        value: sku.id,
        label: sku.name,
      }));
  }, [filterSkus, skusData]);

  const handleUpdate = useCallback((skus: string[]) => {
    setFilterSkus(skus);
  }, []);

  return (
    <div className='h-full lg:overflow-y-auto'>
      <Formik
        initialValues={{
          location: stockLocation ?? 'Site',
          spaceName: '',
          createNew: '0',
          skuId: [],
          itemName: '',
          itemType: '' as ItemType,
          skuCode: '',
          capacity: '',
          target: '',
          reorderLevel: '',
          price: '',
          images: [],
        }}
        validationSchema={QuickAddSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const item =
            values.createNew === '1'
              ? { name: values.itemName, itemType: values.itemType }
              : skusData?.skus.find((sku) => sku.id === values.skuId.join());

          if (!item) {
            throw new Error('Invalid item');
          }

          const res = await quickAdd({
            input: {
              location: values.location,
              spaceName: values.spaceName,
              itemName: item.name.trim(),
              itemType: item.itemType,
              skuCode: values.skuCode,
              capacity: parseInt(values.capacity),
              target: parseInt(values.target),
              reorderLevel: parseInt(values.reorderLevel),
              price: parseFloat(values.price),
              images: values.images,
            },
          });

          if (res.error) {
            toast.error(res.error.message.replace('[GraphQL] ', ''));
          } else if (res.data) {
            toast.success(t('Success'));
            onSuccess && onSuccess(res.data);
            onClose && setTimeout(() => onClose(), 0);
          }

          setSubmitting(false);
        }}
      >
        <LocationLoader id={stockLocation}>
          <PromptEffect />
          <Form className='h-full'>
            <SessionStorageEffect
              storageKey={'form.quickAdd'}
              fields={['spaceName']}
            />
            <SpaceNameChangedEffect
              // spaces={locationData?.location.spaces}
              skusData={skusData}
              onUpdate={handleUpdate}
            />
            <SideLayout>
              <SideLayout.Head onClose={onClose}>
                {stockLocation ? t('quickAdd') : t('addItems')}
              </SideLayout.Head>
              <SideLayout.Body>
                {stockLocation ? <ReadOnlyLocation /> : <LocationField />}
                <SpaceNameField />
                <RadioGroupField
                  title=''
                  name='createNew'
                  options={[
                    { value: '0', label: 'Choose an item' },
                    { value: '1', label: 'Create a new item' },
                  ]}
                />
                <ItemFields skuOptions={skuOptions} skus={skusData} />
              </SideLayout.Body>
              <SideLayout.Foot>
                <p className='p-4'>
                  <SubmitButton />
                </p>
              </SideLayout.Foot>
            </SideLayout>
          </Form>
        </LocationLoader>
      </Formik>
    </div>
  );
};

type LocationLoaderProps = {
  children?: React.ReactNode;
  id?: string;
};

function LocationLoader({ children, id }: LocationLoaderProps) {
  const [locationId, setLocationId] = useState(id ?? '');
  const [{ data, fetching }] = useLocationQuery({
    variables: { id: locationId },
    requestPolicy: 'cache-and-network',
    pause: !locationId,
  });
  const location = locationId ? data?.location : undefined;

  return (
    <QuickAddContext.Provider value={{ location, setLocationId }}>
      {fetching ? <Loading /> : children}
    </QuickAddContext.Provider>
  );
}

function ReadOnlyLocation() {
  const { location } = useContext(QuickAddContext);
  const { t } = useTranslation();

  return (
    <div>
      <p className='label mb-1.5'>
        {t('location')}{' '}
        {location ? `(${t('type.' + location.__typename)})` : ''}
      </p>
      <p className='mb-6'>{location?.name}</p>
    </div>
  );
}

/** Local location select takes into account QuickAddContext */
function LocationField() {
  const { setLocationId } = useContext(QuickAddContext);
  const { t } = useTranslation();

  return (
    <StockLocationField
      title={t('location')}
      onUpdate={(id) => setLocationId(id ?? '')}
    />
  );
}

type SpaceNameChangedEffectProps = {
  spaces?: LocationQuery['location']['spaces'];
  skusData?: SkusQuery;
  onUpdate: (skus: string[]) => void;
};

const SpaceNameChangedEffect = ({
  spaces,
  skusData,
  onUpdate,
}: SpaceNameChangedEffectProps) => {
  const { values, setFieldValue } = useFormikContext<{ spaceName: string }>();

  useEffect(() => {
    if (!(spaces && values.spaceName)) {
      onUpdate([]);
    } else {
      const space = spaces.find(({ name }) => name === values.spaceName);
      onUpdate(space?.skus.map((sku) => sku.skuId) ?? []);
    }

    setFieldValue('skuId', []);
  }, [spaces, skusData, onUpdate, values.spaceName, setFieldValue]);

  return null;
};

function SpaceNameField() {
  const { location } = useContext(QuickAddContext);
  const { t } = useTranslation();
  const spaces = location ? location.spaces.map((space) => space.name) : [];

  return (
    <ComboboxField
      name='spaceName'
      label={t('spaceName')}
      items={spaces}
      required
      floatingOptions={false}
    />
  );
}

type ItemFieldsProps = { skuOptions?: Option[]; skus?: SkusQuery };

const ItemFields = ({ skuOptions, skus }: ItemFieldsProps) => {
  const itemTypes = useEnumOptions(ItemType, 'itemType');
  const { values } = useFormikContext<{ createNew: '0' | '1' }>();
  const { t } = useTranslation();

  if (values.createNew === '0') {
    return (
      <div className='pb-4'>
        <SelectField
          name='skuId'
          label={t('sku')}
          options={skuOptions ?? []}
          disabled={!skuOptions}
          required
          floatingOptions={false}
        />
        <Properties />
      </div>
    );
  }

  return (
    <>
      <TextField name='itemName' label={t('itemName')} />
      <SelectField
        name='itemType'
        label={t('itemTypeLabel')}
        options={itemTypes}
      />
      <TextField name='skuCode' label={t('skuCode')} />
      <Properties />
      <UploadField name='images' />
    </>
  );
};

const Properties = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<{
    createNew: '0' | '1';
    skuId: string[];
    itemType: ItemType;
  }>();
  const skuId = values.skuId.length && values.skuId[0];

  if (values.createNew === '0' && !skuId) {
    return null;
  }

  return (
    <>
      {/* <TextField
        label={t('capacity')}
        name='capacity'
        align='right'
        type='number'
        min='0'
      /> */}
      <TextField
        label={t('target')}
        name='target'
        align='right'
        type='number'
        min='0'
      />
      <TextField
        label={t('reorderLevel')}
        name='reorderLevel'
        align='right'
        type='number'
        min='0'
      />
      <TextField
        label={t('price')}
        name='price'
        align='right'
        type='number'
        min='0'
        step='any'
      />
    </>
  );
};

type QuickAddButtonProps = {
  onClick: (values: unknown) => void;
  centered?: boolean;
};

export const QuickAddButton = ({ onClick, centered }: QuickAddButtonProps) => {
  const formik = useFormikContext();
  const { values } = formik ?? {};

  return (
    <button
      type='button'
      className={classNames(
        !centered && 'right-4',
        'absolute top-4 z-50 h-8 w-8 rounded-full bg-brand text-center leading-none text-white shadow-md'
      )}
      onClick={() => onClick(values)}
    >
      <FontAwesomeIcon icon={faPlus} />
    </button>
  );
};

export function useQuickAdd<T = unknown>() {
  const [showQuickAdd, setShowQuickAdd] = useState(false);
  const [storedValues, setStoredValues] = useState<T | undefined>(undefined);

  const open = useCallback((values: T) => {
    setStoredValues(values);
    setShowQuickAdd(true);
  }, []);

  const close = useCallback(() => {
    setShowQuickAdd(false);
  }, []);

  return {
    showQuickAdd,
    storedValues,
    open,
    openQuickAdd: open,
    close,
    closeQuickAdd: close,
  };
}
