import { useTranslation } from 'react-i18next';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import HeadsetIcon from '~/HeadsetIcon';

const ZENDESK_KEY = import.meta.env.VITE_ZENDESK_KEY || '';
export const ZendeskInitializer = () => {
  return (
    <Zendesk
      defer
      zendeskKey={ZENDESK_KEY}
      onLoaded={() => {
        ZendeskAPI('messenger', 'hide');
        ZendeskAPI('messenger:on', 'close', () => {
          ZendeskAPI('messenger', 'close');
          ZendeskAPI('messenger', 'hide');
        });
      }}
    />
  );
};

export const handleSupportMessengerOpen = () => {
  ZendeskAPI('messenger', 'show');
  ZendeskAPI('messenger', 'open');
};

export const SupportLink = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={handleSupportMessengerOpen}
        className='flex flex-row items-center justify-start py-4 font-medium text-copy-alt hover:text-brand lg:py-0 lg:text-base lg:font-normal'
      >
        <span>
          <HeadsetIcon className='color-current mr-3 block h-8 w-8 flex-1' />
        </span>
        {t('support')}
      </div>
    </>
  );
};
