import { SVGProps } from 'react'

const SvgJobs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    strokeWidth={1.5}
    stroke='#1095C0'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    {...props}
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2' />
    <rect x={9} y={3} width={6} height={4} rx={2} />
    <path d='M9 12h.01M13 12h2M9 16h.01M13 16h2' />
  </svg>
)

export default SvgJobs
