import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePortal } from '~/hooks/usePortal';
import { ErrorBoundary } from './ErrorBoundary';

type ModalProps<ComponentProps = any> = {
  component: React.ComponentType<ComponentProps>;
  open?: boolean;
  children?: React.ReactNode;
};

export const Modal = ({
  component: Content,
  open = false,
  ...props
}: ModalProps<any>) => {
  const [width, setWidth] = useState(
    // Read the initial width from localStorage when modal is opened
    () =>
      (JSON.parse(window.localStorage.getItem('ui.rsb.width') || 'null') ||
        420) - 6
  );

  const target = usePortal('modal-root');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => setMounted(true), 0);
  }, []);

  // Handle a CustomEvent dispatched from Resize component
  useEffect(() => {
    function listener(ev: CustomEventInit) {
      setWidth(ev.detail.width ? ev.detail.width - 6 : 414);
    }
    document.addEventListener('resized', listener);

    return () => {
      document.removeEventListener('resized', listener);
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      className={classNames(
        'absolute inset-0 bg-white',
        'lg:fixed lg:bottom-0 lg:left-auto lg:right-0 lg:top-nav-height lg:z-[51] lg:h-auto lg:w-[414px] lg:overflow-y-auto lg:border-l lg:border-gray88',
        {
          hidden: !open,
        }
      )}
      style={{ width: window.innerWidth > 1024 ? width : '' }}
    >
      <div className='relative h-full' style={{ opacity: mounted ? 1 : 0 }}>
        <ErrorBoundary>
          <Content {...props} />
        </ErrorBoundary>
      </div>
    </div>,
    target
  );
};

export const ModalChildren = ({ children }: { children: React.ReactNode }) => {
  const target = usePortal('modal-root');

  return ReactDOM.createPortal(
    <Modal component={React.Fragment} open={true}>
      {children}
    </Modal>,
    target
  );
};
