import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { faPaperclip, faTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useRef, useState } from 'react';
import { useMutation } from 'urql';
import { TagCombobox, TagEntityType } from '~/components/TagCombobox';
import { TagsBuilder } from '~/components/TagCombobox/TagsBuilder';
import { useTags } from '~/components/TagCombobox/tags';
import { TextArea } from '~/components/form/textarea/TextArea';
import { Upload, UploadResult } from '~/components/upload2';
import { graphql } from '~/gql';
import { SendButton } from './SendButton';

type Props = {
  jobId: string;
  onSuccess?: () => void;
};

const AddComment_Mutation = graphql(/* GraphQL */ `
  mutation AddComment($input: AddCommentInput!) {
    addComment(input: $input) {
      id
      body
      attachments {
        id
        ext
        url
        originalFilename
      }
      tags {
        id
        entityType
        category
        name
        description
        colour
      }
      createdAt
      createdBy {
        id
        name
      }
    }
  }
`);

export function useAddCommentMutation() {
  return useMutation(AddComment_Mutation);
}

export function MessageInput({ jobId, onSuccess }: Props) {
  const [attachments, setAttachments] = useState<UploadResult[]>([]);
  const [tagsIDs, setTagsIDs] = useState<string[]>([]);

  const [{ fetching: sending }, addComment] = useAddCommentMutation();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [allTags, reload] = useTags(TagEntityType.Comment);

  const selectedTags = allTags.filter((t) => tagsIDs.includes(t.id));

  const send = useCallback(async () => {
    if (!(inputRef.current?.value || attachments.length > 0)) {
      return;
    }
    await addComment({
      input: {
        jobId,
        body: inputRef.current?.value ?? '',
        attachments: attachments.map(({ id, kind, name }) => ({
          id,
          kind,
          originalFilename: name,
        })),
        tagIds: tagsIDs,
      },
    });

    if (inputRef.current) inputRef.current.value = '';
    setAttachments([]);
    setTagsIDs([]);
    onSuccess && onSuccess();
  }, [jobId, addComment, attachments, onSuccess, tagsIDs]);

  function appendUniqueAttachments(attachments: UploadResult[]) {
    setAttachments((prev: UploadResult[]) => {
      const newValue = [...prev];
      attachments.forEach((attachment) => {
        if (newValue.find(({ id }) => id === attachment.id)) {
          return;
        }
        newValue.push(attachment);
      });
      return newValue;
    });
  }

  return (
    <div className='mx-4 rounded border bg-white'>
      <TextArea
        ref={inputRef}
        label=''
        className='border-0'
        // className='mr-2 flex-auto resize-none rounded border px-2 leading-10 outline-none'
        placeholder='Enter message...'
        disabled={sending}
        // onBlur={() => setOpen(false)}
        // onFocus={handleOpen}
        onKeyDown={(event) => {
          if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
            send();
          }
        }}
        skeleton
      />
      {attachments.length > 0 && (
        <div className='flex gap-2 p-2 pt-0 text-left'>
          {attachments.map(({ id, preview, name }) => (
            <img key={id} alt={name} src={preview} className='h-16' />
          ))}
        </div>
      )}

      <TagsBuilder
        className='px-2 pb-2'
        tags={selectedTags}
        onRemove={(id) => {
          setTagsIDs(tagsIDs.filter((tagID) => tagID !== id));
        }}
      />

      <div className='flex items-center border-t px-3 py-1'>
        <Upload
          id={`MessageInput:${jobId}`}
          imagesOnly={false}
          onUploaded={appendUniqueAttachments}
        >
          <div className='flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-5'>
            <FontAwesomeIcon icon={faPaperclip} />
            &nbsp;
            <FontAwesomeIcon icon={faPhotoFilm} />
          </div>
        </Upload>

        <TagCombobox
          entityType={TagEntityType.Comment}
          options={allTags}
          value={tagsIDs ? allTags.filter((t) => tagsIDs.includes(t.id)) : []}
          onChange={(tags) => {
            reload();
            setTagsIDs(tags.map(({ id }) => id));
          }}
          customButton={
            <div className='flex h-10 w-10 cursor-pointer items-center justify-center rounded-full hover:bg-grey-5'>
              <FontAwesomeIcon icon={faTag} />
            </div>
          }
          containerStyles='w-80 bottom-[40px]'
        />

        <div className='ml-auto mr-1'>
          <SendButton clasName sending={sending} send={send} />
        </div>
      </div>
    </div>
  );
}
