import { ResultOf } from '@graphql-typed-document-node/core';
import { useQuery } from 'urql';
import { graphql } from '~/gql';
import { TagEntityTypes } from '.';
import { FacetedFilterOption } from '../ui/FacetedFilterSelect';

export type Tag = ResultOf<typeof ListTagsDocument>['tags']['edges'][0]['node'];

export const ListTagsDocument = graphql(`
  query ListTags($entityTypes: [String!]) {
    tags(entityTypes: $entityTypes) {
      edges {
        node {
          id
          entityType
          name
          category
          description
          colour
        }
      }
    }
  }
`);

export function useTags(
  entityTypes: string | string[] = TagEntityTypes
): [allTags: Tag[], reload: () => void] {
  const types = typeof entityTypes === 'string' ? [entityTypes] : entityTypes;

  const [result, reexecuteQuery] = useQuery({
    query: ListTagsDocument,
    variables: { entityTypes: types },
  });
  const edges = result.data?.tags.edges;

  const allTags = edges?.map(({ node }) => node) ?? [];
  const reload = () => reexecuteQuery({ requestPolicy: 'network-only' });
  return [allTags, reload];
}

export function formatTagsToFaceted(tags: Tag[]): FacetedFilterOption[] {
  return tags.reduce<FacetedFilterOption[]>((result, item) => {
    const index = result.findIndex((entry) => entry.label === item.entityType);

    if (index === -1) {
      result.push({
        // $group: item.entityType,
        key: item.entityType.toLowerCase(),
        label: item.entityType,
        value: [item.name],
      });
    } else {
      result[index].value.push(item.name);
    }

    return result;
  }, []);
}
