import { useTranslation } from 'react-i18next';
import { SideLayout } from '~/layouts/side/SideLayout';

type Props = {
  message?: string;
  /** If provided component will render a SideLayout with close action */
  onBack?: () => void;
};

export function ErrorMessage({ message, onBack }: Props) {
  const { t } = useTranslation();
  const messageText = message && message.replace('[GraphQL]', '');
  const body = (
    <div className='text-copy-body rounded bg-red-300 px-4 py-3 text-sm'>
      <span className='font-bold text-red-900'>{t('error')}:</span>{' '}
      {messageText}
    </div>
  );

  if (onBack) {
    return (
      <SideLayout>
        <SideLayout.Head onClose={onBack} />
        <SideLayout.Body>{body}</SideLayout.Body>
      </SideLayout>
    );
  }

  return body;
}
