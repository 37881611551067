import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from '~/components/ui/Card';
import { JobStatus } from '~/generated/graphql';
import { initials } from '~/helpers/initials';
import { Status } from '~/types';
import { Avatar } from './Avatar';
import { Image } from './Image';
import { StatusBadge } from './StatusBadge';

export type BasicListItemProps = {
  /** URL path to pass to router link */
  linkTo: string;
  /** URL to an image file to pass to `Image` component */
  image?: string | null;
  /** Fallback if image is missing */
  placeholderIcon?: React.ReactNode;
  /** First row of text */
  titleText: string;
  /** Middle row of text */
  secondaryText: React.ReactNode;
  /** Bottom row of text */
  tertiaryText?: React.ReactNode;
  /** Hero content for the right side. Ignored if heroElement provided */
  hero?: { number?: number | string; directive: React.ReactNode };
  /** Hero element for more control over what's displayed on the right side */
  heroElement?: React.ReactNode;
  /** Status to display in badge */
  status?: Status | keyof typeof JobStatus;
  /** If true will display the image or a placeholder image if image is not set */
  showStatus?: boolean;
  /** If true will display the status if it is "Inactive" */
  showInactive?: boolean;
  /** If true item will be colored as active link */
  active?: boolean;
  /** If true will display the image or a placeholder image if image is not set */
  showImage?: boolean;
  /** Object representing an assigned user */
  user?: {
    name: string;
    image?: string | null;
  } | null;
};

function formatTextLength(text: string, maxLenght: number) {
  if (text.length > maxLenght) {
    return text.slice(0, maxLenght);
  }
  return text;
}

export const BasicListItem = ({
  linkTo,
  image,
  placeholderIcon,
  titleText,
  secondaryText,
  tertiaryText,
  hero,
  heroElement,
  status,
  showStatus,
  showInactive,
  showImage = true,
  user,
}: BasicListItemProps) => {
  const { t } = useTranslation();

  const heroContainer =
    heroElement ??
    (hero && (
      <div className='flex items-center gap-3 text-right'>
        {hero.number && <div className='mb-0.5 text-xl'>{hero.number}</div>}
        <div className='text-sm leading-tight xl:pr-2'>{hero.directive}</div>
      </div>
    ));

  return (
    <Card>
      <Link
        to={linkTo}
        className={classNames(
          'mb-2 flex items-center gap-2 rounded border border-grey-20 bg-white px-2 py-2 shadow-sm lg:mb-3 lg:px-4 lg:py-3'
        )}
      >
        {showImage && (
          <div className='hidden h-9 w-9 xs:block lg:h-14 lg:w-14'>
            {!image && !placeholderIcon ? (
              <Avatar
                image={image}
                name={titleText}
                size='full'
                square
                className='rounded-md'
              />
            ) : !image && placeholderIcon ? (
              placeholderIcon
            ) : (
              <Image url={image} size='fit' />
            )}
          </div>
        )}
        <div className='ml-2 mr-3 flex-1 overflow-hidden'>
          <div className='items-center gap-2 md:flex '>
            <div className='mb-0 flex-shrink flex-grow-0 text-sm font-semibold leading-4 text-grey-70 md:text-base lg:max-w-[70%] xl:mb-0'>
              <TitleText text={titleText} />
            </div>
            {secondaryText && (
              <div className='mt-1 hidden h-1 w-1 rounded-full bg-grey-90 md:block'></div>
            )}
            <div className='secondary-text ... mb-0 flex-1 flex-grow truncate text-sm text-grey-40 md:mt-0 md:text-base'>
              {secondaryText}
            </div>
          </div>
          <div className='tertiary-text ... hidden truncate text-xs text-grey-40 md:block'>
            {tertiaryText}
          </div>
        </div>
        <div className='flex h-full items-center gap-3'>
          {heroContainer}
          <div className='mt-auto flex items-end justify-between md:mt-0 md:items-center lg:items-end xl:mr-1 xl:mt-0 xl:items-center'>
            {user && (
              <abbr
                className='mr-3 flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-gray-300 pb-0.5 text-center text-base font-medium leading-none text-gray-500 sm:h-7 sm:w-7 xl:mr-6'
                style={{ textDecoration: 'none' }}
                title={user ? user.name : 'Unassigned'}
              >
                {user.image ? (
                  <Image url={user.image} size='fit' borders={false} />
                ) : (
                  initials(user.name)
                )}
              </abbr>
            )}
            {showStatus && status && (
              <div
                className={classNames([
                  `badge-${status.toLowerCase()}`,
                  'h-6 w-6 items-center justify-center rounded-full py-1 text-center text-xs font-medium shadow transition-all',
                ])}
              >
                <p className='hidden leading-none'>{t(`statuses.${status}`)}</p>
                <p className='block leading-none'>{status.substring(0, 1)}</p>
              </div>
            )}
            {showInactive && status === 'Inactive' && (
              <StatusBadge value={status} />
            )}
          </div>
        </div>
      </Link>
    </Card>
  );
};

type TitleTextProps = {
  text: string;
};

function TitleText({ text }: TitleTextProps) {
  return (
    <>
      <div className='... hidden truncate lg:block 2xl:block'>{text}</div>
      <div className='... block truncate lg:hidden'>
        {formatTextLength(text, 28)}
      </div>
    </>
  );
}
