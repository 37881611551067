import Toggle, { ToggleProps } from 'react-toggle';
import { useField } from 'formik';
import classNames from 'classnames/bind';
import styles from './StatusToggle.module.css';
import { forwardRef } from 'react';

const cx = classNames.bind(styles);

type Props = {
  name?: string;
};

export const StatusToggle = ({ name = 'status' }: Props) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);

  return (
    <label
      className={cx('label', 'mb-5', { active: field.value === 'Active' })}
    >
      <span className={styles.toggle}>
        <Toggle
          defaultChecked={field.value === 'Active'}
          onChange={(event) =>
            helpers.setValue(event.target.checked ? 'Active' : 'Inactive')
          }
        />
      </span>
      <span className={styles.value}>{field.value}</span>
    </label>
  );
};

export function ControlledStatusToggle(props: ToggleProps) {
  return (
    <label className={cx('label', 'mb-5', { active: props.checked })}>
      <span className={styles.toggle}>
        <Toggle {...props} />
      </span>
      <span className={styles.value}>
        {props.checked ? 'Active' : 'Inactive'}
      </span>
    </label>
  );
}
