import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSortable, SortableOptions } from 'react-sortablejs';
import { SortableEvent } from 'sortablejs';
import { EmptyListActions } from '~/components/ui/EmptyListActions';
import { Result } from '~/components/ui/Result';
import { useSortJobMutation } from '~/generated/graphql';
import { ListJobsQuery } from '~/gql/graphql';
import { JobListItem } from './JobListItem';

type Job = Extract<ListJobsQuery['jobBoard'][number], { __typename: 'Job' }>;

type Props = {
  jobs?: ListJobsQuery['jobBoard'];
  scrollId?: string;
  hasFilters?: boolean;
  isSmall: boolean;
  selected: string[];
  toggleSelected: (id: string, shift: boolean, single?: boolean) => void;
};

const options: SortableOptions = {
  animation: 200,
  ghostClass: 'opacity-50',
  chosenClass: 'opacity-100',
  dragClass: 'opacity-100',

  // these two rules effectively disables dragging on mobile
  delay: 2000,
  delayOnTouchOnly: true,
};

export function Unscheduled({
  jobs,
  scrollId,
  hasFilters,
  isSmall,
  selected,
  toggleSelected,
}: Props) {
  const [res, sort] = useSortJobMutation();
  const [state, setState] = useState((jobs as Job[]) ?? []);
  const { t } = useTranslation();
  const { fetching } = res;

  // If jobs change e.g. due to keyword search, reinitialize the local state
  useEffect(() => setState((jobs as Job[]) ?? []), [jobs]);

  async function update({ newIndex, oldIndex }: SortableEvent) {
    if (newIndex == null || oldIndex == null) {
      return;
    }

    const idx = newIndex > oldIndex ? newIndex + 1 : newIndex;
    const place =
      idx === state.length
        ? { sort: 'after', idx: idx - 1 }
        : { sort: 'before', idx };
    const job = state[place.idx];

    await sort({ id: state[oldIndex].id, sort: place.sort, rel: job.id });
  }

  if (!state) {
    return null;
  }

  if (jobs?.length === 0) {
    if (hasFilters) {
      return (
        <div className='max-w-full flex-1 overflow-x-hidden px-7 py-4'>
          There are no jobs matching these filters...
        </div>
      );
    } else {
      return <EmptyListActions listType='Job' />;
    }
  }

  const isAllSelected = jobs?.length === selected?.length;

  return (
    <>
      {fetching && (
        <div className='absolute inset-0 z-[9001] bg-black opacity-0'></div>
      )}
      <div className='max-w-full flex-1 gap-3 overflow-x-hidden p-3 pb-[25vh] pt-2 h-screen lg:h-[calc(100vh-12.38rem)] lg:px-4 lg:pb-0'>
        <Result count={state?.length} />
        <ReactSortable
          list={state}
          setList={setState}
          onStart={(evt) => {
            evt?.preventDefault();
            return false;
          }}
          onUpdate={update}
          {...options}
        >
          {state.map((job) => (
            <JobListItem
              key={job.id}
              small={isSmall}
              job={job}
              selected={isAllSelected || selected.includes(job.id)}
              onSelect={(_, shift, single) =>
                toggleSelected(job.id, shift, single)
              }
            />
          ))}
        </ReactSortable>
      </div>
    </>
  );
}
