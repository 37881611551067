import {
  faBookmark,
  faClipboardList,
  faLocationDot,
  faTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResultOf } from '@graphql-typed-document-node/core';
import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  useFetcher,
  useNavigate,
  useOutlet,
  useSearchParams,
} from 'react-router-dom';
import { client } from '~/client';
import { Activity } from '~/generated/graphql';
import { FragmentType, getFragmentData, graphql } from '~/gql';
import { Tag } from '~/gql/graphql';
import { formatDate } from '~/helpers/formatDate';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { TagsBuilder } from '../TagCombobox/TagsBuilder';
import { TaskBadge } from '../job/JobView/Feed/Feed';
import { MemoGallery } from '../job/JobView/Feed/Gallery';
import { Avatar } from '../ui/Avatar';
import { Card } from '../ui/nucleus';
import { StatusBadge } from '../ui/nucleus/StatusBadge';
import AddJobIcon from './icons/AddJobIcon';

type Props = {
  activity: FragmentType<typeof ActivityListItem_Activity>;
  onTag: (activity: Activity) => void;
  onTagClick?: (tag: Tag) => void;
  onSelect: (id: string, isSingle: boolean) => void;
  onClick: () => void;
  showCreateJobFrom?: boolean;
};

const ActivityListItem_TagFragment = graphql(/* GraphQL */ `
  fragment ActivityListItem_TagFragment on Tag {
    id
    entityType
    category
    name
    description
    colour
  }
`);

export const ActivityListItem_Activity = graphql(/* GraphQL */ `
  fragment ActivityListItem_ActivityFragment on Activity {
    id
    kind
    createdAt
    isRead
    isFlagged
    tags {
      ...ActivityListItem_TagFragment
    }
    createdBy {
      id
      name
      image
    }
    job {
      id
      name
      site {
        id
        name
        image
        address
      }
      jobTags {
        ...ActivityListItem_TagFragment
      }
    }
    task {
      id
      name
      notes
      tags {
        nodes {
          ...ActivityListItem_TagFragment
        }
      }
      attachments {
        id
        kind
        thumb
        url
        originalFilename
      }
    }
    comment {
      id
      body
      attachments {
        id
        kind
        thumb
        url
        originalFilename
      }
      tags {
        ...ActivityListItem_TagFragment
      }
    }
    entry {
      id
      status
      inputDate
    }
  }
`);

function Tags(props: {
  tags: FragmentType<typeof ActivityListItem_TagFragment>[];
}) {
  const tags = getFragmentData(ActivityListItem_TagFragment, props.tags);

  return <TagsBuilder tags={[...tags]} />;
}

export function ActivityListItem(props: Props) {
  const fetcher = useFetcher();
  const activity = getFragmentData(ActivityListItem_Activity, props.activity);
  const [searchParams] = useSearchParams();
  const isSelected = searchParams.get('activity') === activity.id;
  const navigate = useNavigate();

  const [isRead, setIsRead] = useState(activity.isRead);

  useEffect(() => {
    const lsList =
      window.sessionStorage.getItem('activity.recentlyReadActivities') ?? '[]';
    const recentlyReadActivities = JSON.parse(lsList);

    if (recentlyReadActivities.includes(activity.id)) {
      setIsRead(true);
    } else {
      setIsRead(activity.isRead);
    }
  }, [activity, activity.isRead]);

  function handleClick() {
    const to =
      activity.entry && activity.task
        ? `jobs/${activity.job?.id}/tasks/${activity.task.id}`
        : `jobs/${activity.job?.id}?activity=${activity.id}${
            activity.kind === 'message' || activity.task ? '#messages-open' : ''
          }`;

    navigate(to);
    setTimeout(() => props.onSelect(activity.id, true), 500);

    if (isRead) return;

    fetcher.submit(
      { id: activity.id },
      { method: 'post', action: '/activity/mark-as-read' }
    );

    addIdToStorage(activity.id);
    setIsRead(true);
    props.onClick();
  }

  function addIdToStorage(id: string) {
    const lsList =
      window.sessionStorage.getItem('activity.recentlyReadActivities') ?? '[]';
    const list: Array<string> = JSON.parse(lsList);
    list.push(id);
    window.sessionStorage.setItem(
      'activity.recentlyReadActivities',
      JSON.stringify(list)
    );
  }

  /**
   * Holds an "optimistic update" version of the activity - I think?
   * There may be problems here if something causes the data to drift out of sync
   */

  return (
    <div className='flex-1' onClick={handleClick}>
      <ActivityListCard
        activity={activity}
        isRead={isRead}
        isSelected={isSelected}
        onTag={() => props.onTag(activity as Activity)}
        onTagClick={
          props.onTagClick &&
          ((tag) => props.onTagClick && props.onTagClick(tag))
        }
        showCreateJobFrom={props.showCreateJobFrom}
      />
    </div>
  );
}

type ActivityListCardProps = {
  activity: ResultOf<typeof ActivityListItem_Activity>;
  isRead: boolean;
  isSelected: boolean;
  onTag: () => void;
  onTagClick?: (tag: Tag) => void;
  showCreateJobFrom?: boolean;
};

export function ActivityListCard(props: ActivityListCardProps) {
  const navigate = useNavigate();
  const { activity, isRead, isSelected, onTag, onTagClick, showCreateJobFrom } =
    props;

  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const outlet = useOutlet();
  const [showFlagButton, setShowFlagButton] = useState(false);

  const user = activity.createdBy;
  const site = activity.job?.site;

  // used for optimistic updates:
  const [flagged, setFlagged] = useState(activity.isFlagged);
  const [activityTagsLimit, setActivityTagsLimit] = useState<number>(0);
  const [jobTagsLimit, setJobTagsLimit] = useState<number>(0);

  const activityTagsRef = useRef<HTMLDivElement>(null);
  const jobTagsRef = useRef<HTMLDivElement>(null);

  const getTagsLimit = (container: HTMLDivElement | null) => {
    if (!container) return;

    const { offsetWidth, firstChild } = container;
    const children = firstChild?.childNodes;

    if (!children) return;

    const tagsElements = Array.from(children) as HTMLElement[];
    const tagsWidths = tagsElements?.map(
      (el: HTMLElement) => el.offsetWidth + 7
    );
    tagsWidths.unshift(100); // allowance for [+n more]

    let canFitTags = 0;
    let widthOfTagsFitted = 0;

    tagsWidths?.forEach((tagWidth) => {
      if (widthOfTagsFitted + tagWidth > offsetWidth) {
        return;
      }

      canFitTags++;
      widthOfTagsFitted += tagWidth + 7;
    });

    return canFitTags ?? children.length;
  };

  useEffect(() => {
    const activityLimit = getTagsLimit(activityTagsRef.current);
    if (activityLimit) {
      setActivityTagsLimit(activityLimit);
    }

    const jobLimit = getTagsLimit(jobTagsRef.current);
    if (jobLimit) {
      setJobTagsLimit(jobLimit);
    }
  }, []);

  useEffect(() => setFlagged(activity.isFlagged), [activity.isFlagged]);

  let description: ReactNode = null;
  if (activity.comment || activity.task) {
    const body = activity.comment?.body || activity.task?.notes;
    description =
      body != null ? (
        activity.task ? (
          <div
            className={classNames(
              'mb-2 flex w-full flex-col items-start justify-center gap-2 border-l-2 border-[#008F90] bg-[#EEEFF0] px-3.5 py-2.5 lg:w-3/4',
              showFlagButton && 'bg-[#DDDEE0]' // Add hover effect conditionally
            )}
          >
            {activity.entry && activity.task && (
              <>
                {activity.task.notes && <p>{activity.task.notes}</p>}
                {activity.task.attachments && (
                  <div>
                    <MemoGallery attachments={activity.task.attachments} />
                  </div>
                )}
                <Tags tags={activity.task.tags.nodes} />
                <TaskBadge
                  className={showFlagButton && 'bg-[#DDDEE0]'}
                  name={activity.task.name}
                />
              </>
            )}
          </div>
        ) : (
          <>
            {body}

            {activity.comment?.tags && (
              <TagsBuilder
                className='px-2'
                limit={isMobile ? 1 : 2}
                tags={activity.comment?.tags as Tag[]}
              />
            )}
          </>
        )
      ) : (
        <>
          <span>Attachment added</span>

          {activity.comment?.tags && (
            <TagsBuilder
              className='px-2'
              limit={isMobile ? 1 : 2}
              tags={activity.comment?.tags as Tag[]}
            />
          )}
        </>
      );
  }

  const limit = isMobile ? 1 : outlet ? 3 : activityTagsLimit;

  return (
    <Card
      selected={isSelected}
      onMouseOver={() => setShowFlagButton(true)}
      onMouseOut={() => setShowFlagButton(false)}
      type={'activity'}
    >
      {showFlagButton && (
        <div className='absolute -top-3 right-2.5 flex'>
          {/* {activity.kind !== 'message' && ( */}
          <div
            className={classNames(
              'flex items-center justify-center overflow-hidden rounded-l border border-r-0 bg-white p-[2px] text-grey-40 transition-all'
            )}
            onClick={(e) => {
              e.stopPropagation();
              onTag();
            }}
          >
            <FontAwesomeIcon
              className='cursor-pointer rounded-l-sm px-[11px] py-[11px] transition-all hover:bg-grey-5 hover:text-primary'
              icon={faTag}
            />
          </div>

          <div
            className={classNames(
              'flex items-center justify-center overflow-hidden border bg-white px-[2px] transition-all',
              flagged ? 'text-flagged' : 'text-grey-40',
              // activity.kind !== 'message' ? 'rounded-r border-l-0' : 'rounded'
              'border-x-0'
            )}
            onClick={async (event) => {
              event.stopPropagation();
              setShowFlagButton(false);
              setFlagged(!flagged);
              await client.mutation(FlagMessage_Mutation, {
                id: activity.id,
                flagged: !flagged,
              });
            }}
          >
            <FontAwesomeIcon
              className={classNames(
                'cursor-pointer px-[11px] py-[11px] transition-all hover:bg-grey-5',
                flagged ? 'text-flagged' : 'hover:text-primary'
                // activity.kind !== 'message' ? 'rounded-r-sm' : 'rounded-sm'
              )}
              icon={faBookmark}
            />
          </div>

          {showCreateJobFrom && (
            <div
              className={classNames(
                'flex items-center justify-center overflow-hidden border bg-white px-[2px] text-grey-40 transition-all',
                'rounded-r border-l-0'
              )}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`jobs/new?activity=${activity.id}`);
              }}
            >
              <AddJobIcon
                className='cursor-pointer rounded-r-sm p-0.5 hover:bg-grey-5 hover:text-primary'
                width={34}
                height={38}
              />
            </div>
          )}
        </div>
      )}
      <div className='flex items-start gap-2 lg:gap-3'>
        <div className='flex items-center gap-1 pt-1 lg:gap-2'>
          <div className='my-auto -ml-1 flex w-3 items-center justify-center lg:ml-0'>
            {!isRead && (
              <div className='h-[9px] w-[9px] rounded-full bg-blue'></div>
            )}
          </div>
          <div className='ml-1 hidden shrink-0 items-stretch overflow-hidden rounded-md xs:block'>
            <Avatar
              size='large'
              automation={user ? false : true}
              image={user?.image}
              name={user?.name}
            />
          </div>
        </div>
        <div className='min-w-0 flex-1'>
          <div
            className={classNames(
              'flex items-center gap-3 text-sm font-semibold lg:text-base'
            )}
          >
            <div className='flex min-w-0 items-baseline gap-1.5'>
              <span className='pb-0.5 font-semibold text-primary'>
                {user?.name ?? t('automation')}
              </span>
              {activity.entry && !activity.task && (
                <>
                  <StatusBadge
                    usedIn={'activityFeed'}
                    status={activity.entry.status}
                  />
                  <span className='whitespace-nowrap pb-0.5 font-normal text-secondary'>
                    job
                  </span>
                </>
              )}
              <div className='ml-1 whitespace-nowrap text-2xs text-tertiary'>
                {formatDate(activity.createdAt, 'hh:mm a')}
              </div>
            </div>
          </div>
          {description && (
            <div className='mb-1.5 flex items-center text-sm md:text-base'>
              {description}
            </div>
          )}

          {activity.comment?.attachments?.length && (
            <MemoGallery attachments={activity.comment.attachments} />
          )}

          {/* {activity.task?.attachments?.length && (
            <MemoGallery attachments={activity.task.attachments} />
          )} */}

          <div className='mt-0.5 flex flex-col items-start gap-1 text-sm font-normal text-tertiary'>
            <div className='flex w-full items-start gap-1.5'>
              <FontAwesomeIcon className='pt-[3px]' icon={faClipboardList} />
              <p className='whitespace-nowrap'>{`${activity.job?.id} - ${activity.job?.name}`}</p>
              <p className='whitespace-nowrap'>
                {activity.task && ` / ${activity.task.name}`}
              </p>
              <div className='w-full' ref={jobTagsRef}>
                <TagsBuilder
                  tags={activity.job?.jobTags as Tag[]}
                  onClick={onTagClick && ((tag) => onTagClick(tag))}
                  limit={jobTagsLimit}
                />
              </div>
            </div>
            {site && (
              <div className='flex items-start gap-1.5'>
                <FontAwesomeIcon className='pt-[3px]' icon={faLocationDot} />
                {site.name}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='absolute right-[1.5rem] top-[-0.27rem] lg:top-[-0.313rem]'>
        {flagged && !showFlagButton && (
          <FontAwesomeIcon
            icon={faBookmark}
            className='h-4 text-flagged transition-all'
          />
        )}
      </div>

      <div
        className={classNames('mt-2', isMobile ? 'ml-[52px]' : 'ml-[72px]')}
        ref={activityTagsRef}
      >
        <TagsBuilder tags={activity.tags as Tag[]} limit={limit} />
      </div>
    </Card>
  );
}

//
// ----------------------------------------------------------------------------
//

const FlagMessage_Mutation = graphql(/* GraphQL */ `
  mutation FlagMessage($id: ID!, $flagged: Boolean!) {
    flagMessage(id: $id, flagged: $flagged)
  }
`);

const MarkAsRead_Mutation = graphql(/* GraphQL */ `
  mutation MarkAsRead($id: ID!) {
    markAsRead(id: $id)
  }
`);

export async function markAsRead({ request }: ActionFunctionArgs) {
  const data = await request.formData();
  const id = data.get('id');

  if (!(id && typeof id === 'string')) {
    return null;
  }

  const result = await client.mutation(MarkAsRead_Mutation, { id }).toPromise();

  return null;
}
