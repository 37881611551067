import { useMemo } from 'react';
import { useOptions } from '~/api';
import { cloneSpaces } from '~/api/assets';
import { ActionForm } from '~/components/form/ActionForm';
import { Checkbox } from '~/components/form/checkradio/FilterToggle';
import { SelectField } from '~/components/form/downshift/SelectField';

type Props = {
  selected: any;
  onClose: () => void;
  onSuccess: () => void;
};

export const CloneSpaces = ({ selected, onClose, onSuccess }: Props) => {
  const { data: assets } = useOptions('assets');
  const clone = useMemo(() => Object.keys(selected), [selected]);

  return (
    <ActionForm
      action={cloneSpaces}
      title='Clone Spaces'
      buttonText='Clone'
      buttonDisabled={!clone.length}
      initialValues={{
        clone,
        to: [],
        includeItems: '',
      }}
      onCancel={onClose}
      onSuccess={(res) => {
        // const { assets, selected } = res.data;
        onSuccess();
      }}
      enableReinitialize
    >
      {clone.length ? (
        <>
          <SelectField
            label='To Asset'
            options={assets ?? []}
            name='to'
            // multiple
          />
          <Checkbox
            name='includeItems'
            label='Include items and associated properties'
            value='1'
          />
        </>
      ) : (
        <div>No spaces selected</div>
      )}
    </ActionForm>
  );
};
