import { Button } from '~/components/ui/buttons/Button';
// import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Selected } from '.';

type Props = {
  selected: Selected;
};

export const AssetConfigActions = ({ selected }: Props) => {
  const { t } = useTranslation();

  // const hasSelected = useMemo(() => {
  //   const [spaces, spaceSkus] = Object.keys(selected).reduce(
  //     (acc: [string[], string[]], key) => {
  //       const [type, id] = key.split(':');
  //       if (type === 'space') {
  //         return [[...acc[0], id], acc[1]];
  //       }
  //       if (type === 'space_sku') {
  //         return [acc[0], [...acc[1], id]];
  //       }
  //       return acc;
  //     },
  //     [[], []]
  //   );

  //   return {
  //     nothing: spaces.length + spaceSkus.length === 0,
  //     spaceSkus: spaceSkus.length > 0,
  //   };
  // }, [selected]);

  return (
    <>
      {/* <p className='mt-10 mb-5 font-semibold'>{t('Make Changes')}</p> */}
      <Button as={Link} to='add-items' color='primary' rounded>
        {t('addItems')}
      </Button>
      <Button
        as={Link}
        to='remove-items'
        color='primary'
        rounded
        // disabled={hasSelected.nothing}
      >
        {t('removeItems')}
      </Button>

      {/* <p className='mt-10 mb-5 font-semibold'>{t('Manage Changes')}</p>
      <Button
        as={Link}
        to='unqueue-changes'
        color='primary'
        rounded
        disabled={hasSelected.nothing}
      >
        {t('Unqueue Changes')}
      </Button>
      <Button
        as={Link}
        to='commit-changes'
        color='primary'
        rounded
        disabled={hasSelected.nothing}
      >
        {t('Commit Changes')}
      </Button> */}

      {/* <p className='mt-10 mb-5 font-semibold'>{t('arrangeSpaces')}</p>
      <Button as={Link} to='add-spaces' color='primary' rounded>
        + {t('space', { count: 0 })}
      </Button>
      <Button color='primary' rounded disabled={true || hasSelected.nothing}>
        {t('Remove Spaces')}
      </Button>
      <Button
        as={Link}
        to='clone-spaces'
        color='primary'
        rounded
        disabled={hasSelected.nothing}
      >
        {t('Clone Spaces')}
      </Button>
      <p className='mt-10 mb-5 font-semibold'>{t('Manage Stock')}</p> */}

      <Button as={Link} to='stock-adjustment' color='primary' rounded>
        {t('adjustStock')}
      </Button>
      {/* 2022-08-03 temporarily hidden */}
      {/* <Button as={Link} to='transfer-stock' color='primary' rounded>
        {t('Transfer Stock')}
      </Button> */}
      <Button as={Link} to='stocktake' color='primary' rounded>
        {t('stocktake')}
      </Button>
      <Button
        as={Link}
        to='modify-properties'
        color='primary'
        rounded
        // disabled={hasSelected.nothing}
      >
        {t('modifyProperties')}
      </Button>
    </>
  );
};
