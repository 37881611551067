import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, forwardRef } from 'react';

type Props = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

type Ref = HTMLInputElement;

export const SearchField = forwardRef<Ref, Props>(
  ({ onChange, placeholder }, ref) => (
    <div className='flex h-[42px] w-full items-center rounded-full border border-grey-20 bg-white px-4 py-2'>
      <FontAwesomeIcon icon={faSearch} className='mr-3 text-sm text-grey-40' />
      <input
        ref={ref}
        placeholder={placeholder ? placeholder : 'Search...'}
        type='text'
        className='flex-1 bg-transparent text-base outline-none'
        onChange={onChange}
      />
    </div>
  )
);
