import { useMutation } from 'urql';
import { Menu } from '~/components/ui/Menu';
import { graphql } from '~/gql';

type Props = {
  eventId: string;
  onHidden: () => void;
};

const HideJobCalendarEventDocument = graphql(`
  mutation HideJobCalendarEvent($id: ID!) {
    hideJobCalendarEvent(id: $id) {
      id
      hidden
    }
  }
`);

export function JobCalendarEventMenu({ eventId, onHidden }: Props) {
  const [, hide] = useMutation(HideJobCalendarEventDocument);

  async function handleClick() {
    const result = await hide({ id: eventId });
    if (!result.error) {
      onHidden();
    }
  }

  return (
    <Menu>
      <Menu.Item onClick={handleClick}>
        <span className='text-delete'>Hide cancelled event</span>
      </Menu.Item>
    </Menu>
  );
}
