import { useEffect } from 'react';
import Sortable from 'sortablejs';

export const useSortable = (
  element: HTMLElement | null,
  options: Sortable.Options = {}
) => {
  useEffect(() => {
    if (!element) {
      return;
    }
    const sortable = new Sortable(element, {
      handle: '.handle',
      animation: 150,
      delay: 250,
      delayOnTouchOnly: true,
      ...options,
    });

    return () => {
      sortable.destroy();
    };
  }, [element, options]);
};
