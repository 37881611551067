import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useAuth } from '~/hooks/useAuth';
import styles from './FloatingActionButton.module.css';
import { Button } from './ui/buttons/Button';

const cx = classNames.bind(styles);

type Props = {
  actions?: Array<string | { to: string; label: string }>;
  rule?: string;
};

export const FloatingActionButton = ({ actions = ['new'] }: Props) => {
  const { can } = useAuth();
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState<Boolean>(false);

  if (actions.length === 1 && typeof actions[0] === 'string') {
    if (actions[0] === 'new') {
      const createResource = pathname.split('/')[1] + '.create';

      if (!can(createResource)) {
        return null;
      }
    }
    return (
      <Link
        to={actions[0]}
        className={cx('FloatingActionButton', 'pointer-events-auto lg:hidden')}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Link>
    );
  }

  return (
    <div
      className={cx('FloatingActionButton', 'pointer-events-auto lg:hidden', {
        open: showMenu,
      })}
    >
      <span className={styles.toggle} onClick={() => setShowMenu((s) => !s)}>
        <FontAwesomeIcon icon={faPlus} />
      </span>
      <CSSTransition in={!!showMenu} timeout={300} classNames='slide'>
        <div className={styles.menu}>
          {actions
            .map((a) => (typeof a === 'string' ? { to: a, label: a } : a))
            .map(({ to, label }) => (
              <Button key={to} as={Link} to={to} color='primary' rounded>
                {label}
              </Button>
            ))}
        </div>
      </CSSTransition>
    </div>
  );
};
