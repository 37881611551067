import { SVGProps } from 'react'

const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    width='1em'
    height='1em'
    {...props}
  >
    <title>{'ionicons-v5-a'}</title>
    <polyline
      points='112 328 256 184 400 328'
      style={{
        fill: 'none',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 48,
      }}
    />
  </svg>
)

export default SvgChevronUp

