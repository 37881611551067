import { VariantProps, cva } from 'class-variance-authority';

// Classes Removed from previous styling: 'min-w-[6rem] max-w-[16rem] overflow-x-hidden'

export const pillStyles = cva(
  'flex cursor-pointer items-center h-8 justify-center gap-2 rounded-full border px-4 pb-0.5 text-base whitespace-nowrap w-fit active:text-primary hover:text-primary text-secondary',
  {
    variants: {
      active: {
        true: 'border-grey-20 bg-grey-5 font-medium',
        false: 'border-grey-10 hover:border-grey-20 font-normal',
      },
      hasParent: {
        true: '',
        false: 'mx-1 my-2',
      },
    },
  }
);

type PillProps<T extends React.ElementType> = {
  as?: T;
} & VariantProps<typeof pillStyles>;

export function Pill<T extends React.ElementType = 'div'>({
  as,
  className,
  active,
  hasParent = false,
  ...props
}: PillProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof PillProps<T>>) {
  const Component = as ?? 'div';
  return (
    <Component
      className={pillStyles({ active, hasParent, className })}
      {...props}
    />
  );
}
