import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'urql';
import { Button } from '~/components/form/SubmitButton';
import { Select } from '~/components/form/downshift/Select';
import { Dialog } from '~/components/ui/Dialog';
import { graphql } from '~/gql';
import { SideLayout } from '~/layouts/side/SideLayout';

type Props = {
  selected: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

const JobTemplates_Query = graphql(`
  query JobTemplates {
    jobTemplates(status: Active) {
      id
      name
    }
  }
`);

const UpdateJobTemplates_Mutation = graphql(`
  mutation UpdateJobTemplates($jobIds: [ID!]!, $templateId: ID!) {
    updateJobTemplates(jobIds: $jobIds, templateId: $templateId) {
      id
      #   tasks
      #  notes
      # images
      # attributes
    }
  }
`);

export function BulkTemplateUpdate({ selected, onCancel, onSuccess }: Props) {
  const { t } = useTranslation();
  const [options] = useQuery({ query: JobTemplates_Query });
  const [result, updateJobTemplates] = useMutation(UpdateJobTemplates_Mutation);

  // Confirmation dialog state
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [template, setTemplate] = useState<string[]>([]);

  const confirm = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (result.error) return;
    if (result.data) onSuccess();
  }, [result, onSuccess]);

  const loading = result.fetching;

  return (
    <>
      <Dialog
        title='Bulk Change Warning!'
        show={isOpen}
        onClose={() => {
          if (loading) return;
          setIsOpen(false);
        }}
      >
        <div className='flex flex-col flex-wrap gap-3 px-5'>
          <p className='px-2 pt-3'>
            Are you sure you want to replace the contents of the What section of{' '}
            <span className='font-semibold'>{selected.length}</span> jobs using
            the selected template?
          </p>
          <div className='flex gap-3 self-end pb-5 pt-1'>
            <button
              className={classNames(
                'px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand',
                {
                  'text-grey-40 hover:text-grey-40': loading,
                }
              )}
              onClick={() => {
                setIsOpen(false);
              }}
              disabled={loading}
            >
              Go back
            </button>
            <Button
              type='button'
              loading={loading}
              size='small'
              onClick={() => {
                updateJobTemplates({
                  jobIds: selected,
                  templateId: template[0],
                });
              }}
            >
              {loading ? t('loading') : 'Go Ahead'}
            </Button>
          </div>
        </div>
      </Dialog>
      <SideLayout>
        <SideLayout.Head iconClass='chevron-left' onClose={onCancel}>
          Bulk Change
        </SideLayout.Head>
        <SideLayout.Body>
          <p className='my-4'>
            You are about to replace the contents of the What section of{' '}
            <span className='font-semibold'>{selected.length}</span> jobs using
            the selected template.
            <br />
            <br />
            All Tasks, Notes, Images, Tags and Display Attributes will be
            overwritten, including any changes made to individual jobs after the
            old template was applied.
          </p>
          <p className='mb-4'>
            <Select
              label='Select Template'
              options={
                options.data?.jobTemplates.map((t) => ({
                  label: t.name,
                  value: t.id,
                })) ?? []
              }
              value={template}
              onChange={setTemplate}
            />
          </p>
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <Button
            type='button'
            intent='primary'
            onClick={confirm}
            disabled={!template.length}
          >
            Replace Template
          </Button>
        </SideLayout.Foot>
      </SideLayout>
    </>
  );
}
