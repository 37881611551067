import { CardSmall } from '~/components/ui/CardSmall';
import { Status, useListLocationDeploymentsQuery } from '~/generated/graphql';

export type LocationAssetSpace = {
  __typename?: 'Space' | undefined;
  id: string;
  name: string;
  deployments: {
    __typename?: 'Deployment' | undefined;
    id: string;
    status: Status;
    startedAt: any;
    endedAt?: any;
    image?: string | null | undefined;
    asset: {
      __typename?: 'Asset' | undefined;
      id: string;
      name: string;
      type: string;
      image?: string | null | undefined;
      model?:
        | {
            __typename?: 'AssetModel' | undefined;
            id: string;
            displayName: string;
          }
        | null
        | undefined;
    };
  }[];
};

type AssetsListProps = {
  siteId: string;
};

export function AssetsList({ siteId }: AssetsListProps) {
  const location = `Site:${siteId}`;
  const [result] = useListLocationDeploymentsQuery({
    variables: { id: location },
  });
  const { data, fetching, error } = result;
  const spaces = data?.location.spaces;

  function getActiveSpaces() {
    const spaceArr: LocationAssetSpace[] = [];
    spaces?.forEach((space) => {
      if (space.deployments.length > 0) {
        spaceArr.push(space);
      }
    });
    return spaceArr;
  }

  const activeSpaces = getActiveSpaces();

  if (fetching) {
    return <div className='px-4 pb-4'>Loading...</div>;
  }

  if (error) {
    return (
      <div className='px-4 pb-4'>There was an error loading the Assets.</div>
    );
  }

  return (
    <>
      {!activeSpaces?.length ? (
        <div className='flex-grow p-4'>
          There are no Assets for this property
        </div>
      ) : (
        activeSpaces?.map((space, i) =>
          space.deployments.length > 0 ? (
            <div className='flex-grow pt-4' key={space.id}>
              <p className='text-grey-50 bg-grey-2 sticky top-0 z-[2] mb-1 px-4 py-1 text-center text-xs font-medium'>
                {space.name}
              </p>
              {space.deployments.map((deployment, i) => (
                <CardSmall
                  key={deployment.asset.id}
                  linkTo={`assets/${deployment.asset.id}`}
                  showImage={true}
                  image={
                    deployment.asset.image ? deployment.asset.image : undefined
                  }
                  titleText={deployment.asset.name}
                  secondaryText={deployment.asset.type}
                  // hero={{ number: item.soh, directive: 'on Hand' }}
                />
              ))}
            </div>
          ) : (
            <></>
          )
        )
      )}

      {/* <div className='sticky -bottom-40 left-0 right-0 z-30 flex h-20 justify-center lg:-bottom-24'>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <QuickAddButton centered={true} onClick={quickAdd.open} />
        </Formik>
        <div className='absolute bottom-0 right-0 left-0 h-20 bg-gradient-to-t from-white md:via-white'></div>
      </div> */}
      {/* <AddJobPopup
            location={[siteId]}
            onCancel={quickAdd.close}
            onSuccess={() => reload()}
            show={quickAdd.showQuickAdd ?? false}
            onClose={quickAdd.close}
          /> */}
    </>
  );
}
