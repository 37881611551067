import type { Attachment, AttachmentInput } from '~/gql/graphql';
import type { Kind } from '.';
import type { UploadResult } from './Upload';
// import { AttachmentFields } from '../upload/AttachmentFields';
// import type{ FragmentType } from '~/gql';

export function toAttachmentInput(upload: UploadResult): AttachmentInput {
  return {
    id: upload.id,
    kind: upload.kind,
    originalFilename: upload.name,
  };
}

// TODO: FragmentType<typeof AttachmentFields>
export function toFormValue(attachment: Attachment): UploadResult {
  return {
    id: attachment.id,
    kind: attachment.kind as Kind,
    name: attachment.originalFilename,
    preview: attachment.thumb,
  };
}
