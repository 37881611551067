import { ControlledKeywordSearch } from '../ControlledKeywordSearch';
import { Filter, FilterGroup } from './FilterGroup';

type Value = {
  searchValue: string;
  filtersValue: URLSearchParams;
};

type Props = {
  filters: Filter[];
  value: Value;
  onChange: (value: Value) => void;
  placement?: 'portal';

  /**
   * Keyword search is currently broken on Activity view so have added this option to hide it
   */
  hideKeywordSearch?: boolean;
};

export function FilterGroupSearch({
  filters,
  value,
  onChange,
  placement,
  hideKeywordSearch,
}: Props) {
  return (
    <div className='max-w-full overflow-hidden bg-white py-2 lg:flex lg:items-center lg:justify-start lg:gap-4 lg:px-4 lg:py-[7px]'>
      {hideKeywordSearch ? (
        <div className='hidden w-12 lg:block'></div>
      ) : (
        <ControlledKeywordSearch
          value={value.searchValue}
          onChange={(query) => onChange({ ...value, searchValue: query })}
        />
      )}
      <FilterGroup
        filters={filters}
        value={value.filtersValue}
        onChange={(filters) => onChange({ ...value, filtersValue: filters })}
        placement={placement}
      />
    </div>
  );
}
