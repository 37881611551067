import axios from 'axios'
import { toPairs } from 'lodash'
import { useState, useEffect } from 'react'
import qs from 'qs'
import { ModalForm } from '~/components/form/ModalForm'
import { SMCCardGroup } from '~/components/smc/SMCCardGroup'
import styles from './TransferStock.module.css'
import { useTranslation } from 'react-i18next'

export const AddForm = ({ prev, onSuccess, step, onPrevState }) => {
  const { t } = useTranslation()
  const [stock, setStock] = useState([])
  const initialValues = stock.reduce((obj, { id, initialValue }) => {
    return { ...obj, [id]: initialValue || '' }
  }, {})

  useEffect(() => {
    async function fetchStock() {
      const url =
        'space-skus?' +
        qs.stringify({
          location: { type: prev.destination.type, id: prev.destination.id[0] },
          forSkuIn: toPairs(prev.remove),
        })
      const { data } = await axios.get(url)

      setStock(data)
    }

    fetchStock()
  }, [prev.destination, prev.remove])

  const handleSubmit = (values, { setSubmitting }) => {
    const add = {}
    Object.keys(values.adjustments).forEach((i) => {
      if (values.adjustments[i] !== '') {
        add[i] = values.adjustments[i]
      }
    })
    onSuccess({ add }, { setSubmitting })
  }

  const initValues = { adjustments: initialValues }

  return (
    <ModalForm
      title='Transfer Stock'
      formikProps={{
        enableReinitialize: true,
        initialValues: initValues,
        onSubmit: handleSubmit,
      }}
      buttonText='Submit'
      onPrevState={() => onPrevState(step - 1)}
    >
      <div className={styles.subtitle}>
        Stock to add to {t('type.' + prev.destination.type)}
      </div>

      <SMCCardGroup
        mode='adjustment'
        operator='add'
        spaceSkus={stock}
        adjustmentLabel='Transfer In'
        showHeroLabel={false}
      />
    </ModalForm>
  )
}
