import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  useNavigate,
  useSubmit,
} from 'react-router-dom';
import { client } from '~/client';
import { SiteForm } from '~/components/sites/SiteForm';
import {
  CreateSiteDocument,
  CreateSiteMutation,
  CreateSiteMutationVariables,
} from '~/generated/graphql';
import { graphql } from '~/gql';

const SiteFormDocument = graphql(`
  query SiteForm {
    integrations
    homheroAreas {
      id
      name
    }
  }
`);

export async function loader() {
  const { error, data } = await client.query(SiteFormDocument, {}).toPromise();

  if (error) {
    throw new Response(error.message, { status: 500 });
  }

  return data ?? { integrations: [], homheroAreas: [] };
}

export async function action({ request }: ActionFunctionArgs) {
  const body = await request.formData();
  const newSite = Object.fromEntries(body);
  const input = JSON.parse(newSite.data as string);

  const res = await client
    .mutation<CreateSiteMutation, CreateSiteMutationVariables>(
      CreateSiteDocument,
      { input }
    )
    .toPromise();

  const id = res.data?.createSite?.id;
  if (id) {
    return redirect(`/sites/${id}`);
  }
}

export function NewSite() {
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const navigate = useNavigate();
  const submit = useSubmit();
  const { t } = useTranslation();

  function handleClose(id?: string) {
    navigate(id ? `/sites/${id}` : '/sites');
  }

  return (
    <SiteForm
      title={t('newSite')}
      homheroAreas={data.homheroAreas}
      homheroEnabled={data.integrations.includes('homhero')}
      onClose={handleClose}
      onSubmit={(input) =>
        submit({ data: JSON.stringify(input) }, { method: 'post' })
      }
    />
  );
}
