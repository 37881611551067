import { get } from 'lodash';
import { useMemo } from 'react';

type FilterKey = {
  name: string;
  path: string;
  t?: (key: string) => string;
};

export const useFilterOptions = <T>(
  data: T[] | undefined,
  keys: FilterKey[]
) => {
  const filterOptions = useMemo(() => {
    // const t = new Date();
    const obj: Record<string, string[]> = {};
    /** Translate value */
    const translate: Record<string, (key: string) => string> = {};

    keys.forEach((key) => {
      const { name, t } = key;
      obj[name] = [];
      if (t) {
        translate[name] = t;
      }
    });

    data?.forEach((item) => {
      keys.forEach(({ name: key, path }) => obj[key].push(get(item, path)));
    });

    const options: Record<
      string,
      { value: string; label: string; $count: number }[]
    > = {};
    Object.keys(obj).forEach((key) => {
      options[key] = Object.entries(
        obj[key].reduce((prev: Record<string, number>, curr) => {
          if (!curr) {
            return prev;
          }
          return prev[curr]
            ? { ...prev, [curr]: prev[curr] + 1 }
            : { ...prev, [curr]: 1 };
        }, {})
      )
        .map(([value, $count]) => ({
          value,
          label: translate[key] ? translate[key](value) : value,
          $count,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    });

    // console.log('filterOptions [+%s]', new Date().getTime() - t.getTime());

    return options;
  }, [keys, data]);

  return [filterOptions];
};
