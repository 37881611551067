import { Formik, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { StatusToggle } from '~/components/form/StatusToggle';
import { SubmitButton } from '~/components/form/SubmitButton';
import { TextField } from '~/components/form/TextField';
import { AttributesField } from '~/components/form/attributes/AttributesField';
import { SelectField } from '~/components/form/downshift/SelectField';
import { Loading } from '~/components/ui/Loading';
// import { UploadField } from '~/components/upload/Upload';
import {
  Status,
  useCreateAssetMutation,
  useListAssetModelsQuery,
  useUpdateAssetMutation,
} from '~/generated/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useAssets } from '../AssetSelect';
import { PromptEffect } from '../Prompt';
import { ComboboxField } from '../ui/Combobox';

type Props = {
  id?: string;
  isPopup?: boolean;
  onSuccess?: (assetId?: string) => void;
};

export function AssetForm({ id, isPopup, onSuccess }: Props) {
  const { assetId } = useParams();
  const [result] = useAssets();
  const asset = result.find((asset) => asset.id === (id ? id : assetId));
  const navigate = useNavigate();
  const { t } = useTranslation(['asset']);
  const [, create] = useCreateAssetMutation();
  const [, update] = useUpdateAssetMutation();
  const title = assetId ? t('editAsset') : t('translation:createAsset');
  if ((assetId || id) && !asset) {
    return <Loading />;
  }

  const initialValues = asset
    ? {
        id: asset.id,
        status: asset.status,
        name: asset.name,
        asset_model_id: asset.model ? [asset.model.id] : [],
        type: asset.type,
        notes: asset.notes,
        attributes: asset.attributes ?? [],
        images: asset.images,
        attachments: asset.attachments
          ? asset.attachments.map(({ id }) => id)
          : [],
      }
    : {
        id: null,
        status: 'Active',
        name: '',
        asset_model_id: [],
        type: '',
        notes: '',
        attributes: [],
        images: [],
        attachments: [],
      };

  // TODO this is incorrectly typed
  const handleSubmit = async (values: typeof initialValues) => {
    const { id, status, asset_model_id, attachments, attributes, ...rest } =
      values;
    const input = {
      ...rest,
      status: status as Status,
      modelId: asset_model_id.length ? parseInt(asset_model_id[0]) : null,
      attachments,
      attributes: attributes?.map(({ name, value }) => ({ name, value })),
    };
    if (id) {
      const { data } = await update({ id, input });
      if (data) {
        onSuccess?.();
        navigate(onSuccess ? '' : `/assets/${data.updateAsset.id}`);
      }
    } else {
      const { data } = await create({ input });
      if (data) {
        onSuccess?.(data.createAsset.id);
        navigate(onSuccess ? '' : `/assets/${data.createAsset.id}`);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <SideLayout>
        <PromptEffect />
        {!isPopup && (
          <SideLayout.Head
            onClose={() => navigate('/assets' + (assetId ? `/${assetId}` : ''))}
          >
            {title}
          </SideLayout.Head>
        )}
        <SideLayout.Body>
          <StatusToggle />
          <TextField name='name' label={t('asset:name')} required />
          <AssetTypeField />
          {/* <AssetModelField /> */}
          <AttributesField type='Asset' />
          <TextField name='notes' label='Notes' />
          {/* <div className='-mb-8 ml-1 mt-8 text-sm font-medium text-grey-50'>
            Files
          </div>
          <div className='flex flex-col text-center'>
            <UploadField
              multiple
              type='attachment'
              name='attachments'
              fileInfo={asset?.attachments}
            />
          </div>
          <div className='flex flex-col p-4 text-center'>
            <UploadField multiple name='attachments' />
          </div> */}
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
}

const AssetTypeField = () => {
  const { t } = useTranslation(['asset']);
  const [result] = useQuery<{ assetTypes: string[] }>({
    query: '{ assetTypes }',
    requestPolicy: 'network-only',
  });

  // TODO figure out why assetTypes returns empty values
  const assetTypes = result.data?.assetTypes;

  return (
    <ComboboxField
      name='type'
      label={t('assetType')}
      items={assetTypes ? assetTypes.filter((value) => value !== '') : []}
      required
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AssetModelField = () => {
  const [result] = useListAssetModelsQuery();
  const { dirty, values, setFieldValue } = useFormikContext<{ type: string }>();
  const models = result.data?.assetModels;
  const filteredModelOptions = useMemo(() => {
    const type = values.type;

    return !models
      ? []
      : type
      ? // @ts-ignore
        models
          .filter((opt) => type === opt.type)
          .map((value) => ({
            value: value.id,
            label: value.displayName,
          }))
      : models.map((value) => ({
          value: value.id,
          label: value.displayName,
        }));
  }, [models, values.type]);

  // reset the model field if asset type changes
  useEffect(() => {
    if (dirty) {
      setFieldValue('asset_model_id', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type, setFieldValue]);

  return (
    <SelectField
      name='asset_model_id'
      label='Model'
      options={filteredModelOptions}
      disabled={!values.type}
    />
  );
};
