import { faAirbnb } from '@fortawesome/free-brands-svg-icons';
import {
  faPlaneArrival,
  faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalendarIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import { enAU } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FragmentType, getFragmentData } from '~/gql';
import GuestyIcon from '~/icons/Guesty';
import { JobCalendarEventFields_JobCalendarEventFragment } from '../JobCalendarEventView/JobCalendarEventView';

type Props = {
  event: FragmentType<typeof JobCalendarEventFields_JobCalendarEventFragment>;
  small?: boolean;
};

export function CalendarEvent({ small, ...props }: Props) {
  const event = getFragmentData(
    JobCalendarEventFields_JobCalendarEventFragment,
    props.event
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sourceProvider = event.icon;
  const icon =
    event.summary === 'Check-in' ? (
      <FontAwesomeIcon icon={faPlaneArrival} className='mr-1 ' />
    ) : (
      <FontAwesomeIcon icon={faPlaneDeparture} className='mr-1' />
    );
  const isEnd = event.summary === 'Check-out';
  const date = isEnd && event.end ? event.end : event.start;

  return (
    <div
      onClick={() => navigate(`calendar/${event.id}`)}
      className='mb-2 flex h-12 flex-grow cursor-pointer items-center gap-1 rounded-full border border-shade-25 bg-shade-5 px-3 py-1.5 pl-5 lg:pr-2'
      style={
        {
          'Check-in': { background: 'rgb(244, 255, 239)' },
          'Check-out': { background: 'rgb(239, 252, 255)' },
        }[event.summary]
      }
    >
      <div
        className={classNames(
          small && 'flex items-center',
          'flex max-w-[50%] lg:max-w-[40%]'
        )}
      >
        <div className={classNames(!small && 'lg:hidden')}>{icon}</div>
        {!small && (
          <div className='flex items-center gap-3'>
            <p
              className={classNames(
                'hidden text-base font-medium text-grey-70 md:block lg:font-semibold',
                { 'line-through': event.cancelled }
              )}
            >
              {t(isEnd ? 'checkOut' : 'checkIn')}
            </p>
            <div className='mt-1 hidden h-1 w-1 rounded-full bg-grey-70 md:block'></div>
          </div>
        )}
        <p
          className={classNames(
            'ml-1 truncate text-sm font-medium text-grey-50 md:ml-3 md:text-base ',
            { 'line-through': event.cancelled },
            small ? 'lg:text-grey-70' : 'lg:font-semibold'
          )}
        >
          {event.location?.name}
        </p>
      </div>
      <div className='ml-auto flex items-center gap-2 pr-2'>
        <p
          className={classNames(
            'whitespace-nowrap text-black',
            event.cancelled && 'line-through',
            'text-xs lg:mr-2 lg:text-sm'
          )}
        >
          {formatInTimeZone(date, event.timeZone, 'h:mm a', { locale: enAU })}
        </p>
        <div className='ml-auto flex w-[48px] items-center justify-end md:w-[132px] lg:w-[158px]'>
          {!small && <div className='hidden lg:block'>{icon}</div>}
          <div className={classNames('flex flex-row-reverse lg:gap-2')}>
            {sourceProvider === 'airbnb' && event.url && (
              <a
                className={
                  'hidden text-blue underline transition-colors hover:text-brand lg:block'
                }
                target='_blank'
                rel='noreferrer'
                href={event.url}
              >
                <div className='flex items-center justify-center gap-2'>
                  <p className='mb-0.5'>Airbnb</p>{' '}
                  <ExternalLinkIcon className='h-5 w-5' />
                </div>
              </a>
            )}

            {sourceProvider === 'guesty' && (
              <div className='hidden items-center justify-center gap-2 lg:flex'>
                <p className='text-sm font-semibold'>Guesty</p> <GuestyIcon />
              </div>
            )}

            {sourceProvider === 'booking.com' && (
              <div className='hidden items-center justify-center gap-2 lg:flex'>
                <p className='text-sm font-semibold'>Booking.com</p>{' '}
                <CalendarIcon className='h-4 w-4' />
              </div>
            )}

            {sourceProvider === 'homhero' && (
              <div className='hidden items-center justify-center gap-2 lg:flex'>
                <p className='text-sm font-semibold'>Homhero</p>{' '}
                <CalendarIcon className='h-4 w-4' />
              </div>
            )}

            <div className='flex w-14 justify-end lg:hidden'>
              {sourceProvider === 'airbnb' && (
                <div className='flex h-6 w-6 items-center justify-center rounded-full bg-[#E2DFDF] text-[#ff385c] lg:h-7 lg:w-7 '>
                  <FontAwesomeIcon icon={faAirbnb} />
                </div>
              )}

              {sourceProvider === 'guesty' && (
                <div className='flex h-6 w-6 items-center justify-center rounded-full bg-[#E2DFDF] text-[#ff385c] lg:h-7 lg:w-7 '>
                  <GuestyIcon className='w-3.5' />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
