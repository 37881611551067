import { Combobox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  SelectorIcon as ChevronUpDownIcon,
  PlusIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { FocusEvent, Fragment, useRef, useState } from 'react';
import naturalCompare from 'string-natural-compare';

const byNaturalValue = (a: string, b: string) =>
  naturalCompare(a, b, { caseInsensitive: true });

type Props = {
  label?: string;
  items: string[];
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  disabledItems?: string[];
  autoFocus?: boolean;
  /** When true the "Create" option is always displayed, even when nothing is entered in the input */
  createAlwaysShown?: boolean;
  sortItems?: boolean;
  required?: boolean;
  floatingOptions?: boolean;
};

function UICombobox({
  label,
  items,
  value,
  onChange,
  disabled = false,
  disabledItems = [],
  autoFocus,
  createAlwaysShown,
  sortItems = true,
  required = false,
  floatingOptions = true,
}: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [query, setQuery] = useState('');

  const sorted = sortItems ? [...items].sort(byNaturalValue) : items;

  const filtered =
    query === ''
      ? sorted
      : sorted.filter((item) =>
          item
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  return (
    <Combobox value={value} onChange={onChange} disabled={disabled} nullable>
      {({ open }) => (
        <>
          {label && (
            <Combobox.Label className='mb-1.5 ml-1 text-sm font-medium text-grey-50'>
              {label}
              {required && <span className='pl-0.5 text-brand'>*</span>}
            </Combobox.Label>
          )}
          <div
            className={classNames(
              'input relative mt-0.5 w-full',
              label && 'mb-5'
            )}
          >
            <div className='flex w-full cursor-default overflow-hidden rounded border border-grey-20 bg-white text-left'>
              <Combobox.Input
                className='flex-1 truncate border-none bg-white px-3 py-2.5 outline-none'
                // displayValue={(v) => v}
                onChange={(event) => setQuery(event.target.value)}
                onFocus={(e: FocusEvent<HTMLInputElement>) => {
                  setTimeout(() => {
                    if (
                      e.relatedTarget?.id?.includes(
                        'headlessui-combobox-button'
                      ) ||
                      e.relatedTarget?.id?.includes(
                        'headlessui-combobox-option'
                      )
                    )
                      return;
                    !open && buttonRef.current?.click();
                  }, 0);
                }}
                style={{ fontSize: 16 }}
                autoFocus={autoFocus}
              />
              <Combobox.Button
                ref={buttonRef}
                className='flex items-center pr-2'
              >
                <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options
                className={classNames(
                  'z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                  floatingOptions && 'absolute'
                )}
              >
                {(createAlwaysShown ||
                  (query.length > 0 && !items.includes(query))) && (
                  <Combobox.Option
                    value={query || '$NEW'}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-brand text-white' : 'text-gray-900'
                      }`
                    }
                  >
                    {({ active }) => (
                      <>
                        <span className='block truncate'>
                          Create {query ? `"${query}"` : ''}
                        </span>
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-brand'
                          }`}
                        >
                          <PlusIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                )}
                {
                  /* filtered.length === 0 && query !== '' ? (
              <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                Nothing found.
              </div>
            ) : */ filtered.map((item) => (
                    <Combobox.Option
                      key={item}
                      value={item}
                      disabled={disabledItems.includes(item)}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-brand text-white' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected, active, disabled }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            } ${disabled ? 'opacity-75' : ''}`}
                          >
                            {item}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-brand'
                              }`}
                            >
                              <CheckIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                }
              </Combobox.Options>
            </Transition>
          </div>
        </>
      )}
    </Combobox>
  );
}

export { UICombobox as Combobox };
