import { PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Link, useLocation, useMatch } from 'react-router-dom';

export interface NavLinkProps {
  exact?: boolean;
  matches?: string[];
  className?: string;
  permissionKey?: string;
  to: string;
  children: React.ReactNode;
  activeKey?: string;
  isActive?: (key: string) => boolean;
  onClick?: () => void;
  locked?: boolean;
}

export function NavLink({
  exact,
  matches,
  permissionKey,
  activeKey,
  isActive: activePredicate,
  ...props
}: NavLinkProps) {
  const match = useMatch(props.to);
  const partialMatch = useMatch(props.to + '/*');
  const location = useLocation();

  const isActive = activePredicate
    ? activeKey && activePredicate(activeKey)
    : Array.isArray(matches)
    ? matches.findIndex((match) => location.pathname.indexOf(match) > -1) > -1
    : exact
    ? match
    : partialMatch;

  const permission = permissionKey
    ? `${permissionKey}.list`
    : `${props.to[0] === '/' ? props.to.substr(1) : props.to}.list`;

  const className = isActive
    ? classNames(props.className, 'active')
    : props.className;

  const { locked, ...linkProps } = props;

  return (
    <>
      <Link {...linkProps} className={className}>
        {locked ? (
          <>
            {props.children} <Pro />
          </>
        ) : (
          props.children
        )}
      </Link>
    </>
  );
}

const Pro = () => (
  <span className='bg-brand absolute top-1 right-3 ml-2 mb-2.5 flex items-center rounded-full py-0.5 pl-1.5 pr-1 text-[9px] font-bold uppercase text-white lg:static'>
    Pro
    <PlusIcon className='inline-block h-[10px] w-[10px] fill-current stroke-current' />
  </span>
);
