import Fuse from 'fuse.js';
import { useCallback, useMemo, useState } from 'react';

type SearchOptions<T> = {
  /** See https://fusejs.io/api/options.html#keys for examples */
  keys: Fuse.FuseOptionKey<T>[];
};

type Filters = Record<string, string[]>;

type State = {
  query: string;
  filters?: Filters;
  expression?: Fuse.Expression;
};

export const useSearch = <T = any>(
  data: T[] | undefined,
  options: SearchOptions<T>
) => {
  const [state, setState] = useState<State>({ query: '' });

  const search = useCallback(
    (query: string, filters?: Filters) => {
      let expression: Fuse.Expression | undefined;

      if (filters && Object.keys(filters).length) {
        const filtersExpression = Object.keys(filters)
          .map((key) => {
            if (!includesKey(options.keys, key)) {
              // eslint-disable-next-line no-console
              console.error(`Invalid filter key: "${key}"`);
              return [] as unknown as {
                $or: { $path: string; $val: string }[];
              };
            }

            const filterOn = filters![key].map((value) => ({
              $path: key,
              // TODO - allow for this in config options
              $val:
                key === 'relationship' || key === 'includeAttributes'
                  ? `'"${value}"`
                  : `="${value}"`,
            }));

            return { $or: filterOn };
          })
          .filter((e) => e.$or && e.$or.length);

        if (filtersExpression.length) {
          expression = {
            $and: filtersExpression,
          };
        }
      }

      if (query) {
        const keywordSearch: Fuse.Expression = {
          $or: options.keys.map((k) => ({
            $path: k.toString(),
            $val: query,
          })),
        };

        // If there is already a filter expression combine the two
        if (expression) {
          expression = {
            // @ts-expect-error FIXME
            $and: [keywordSearch, ...expression.$and],
          };
        } else {
          expression = keywordSearch;
        }
      }

      // console.log('expression:', expression);

      setState({ query, filters, expression });
    },
    [options.keys]
  );

  const fuse = useMemo(() => {
    return new Fuse<T>(Array.isArray(data) ? data : [], {
      useExtendedSearch: true,
      threshold: 0,
      ignoreLocation: true,
      ...options,
    });
  }, [data, options]);

  const results = state.expression
    ? fuse.search(state.expression).map(({ item }) => item)
    : data;

  return {
    results,
    query: state.query,
    search,
  };
};

/**
 * Helper for switching the order of search callback parameters for compatibility with FilterForm
 */
export const useSearchFilter = <T = any>(
  data: T[] | undefined,
  options: SearchOptions<T>
) => {
  const { results, search } = useSearch(data, options);

  return {
    results,
    search: (filters: Filters, query?: string) => search(query ?? '', filters),
  };
};

function includesKey<T>(keys: Fuse.IFuseOptions<T>['keys'], key: string) {
  return keys
    ? keys.some((k) =>
        typeof k === 'string'
          ? k === key
          : // @ts-expect-error FIXME
            Object.hasOwn(k, 'name') && k.name === key
      )
    : false;
}
