import classNames from 'classnames';
import { groupBy } from 'lodash';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';
import { graphql } from '~/gql';
import {
  AttributesDialog,
  groupByIntegration,
  PropFit,
} from '../job/CreateJobForm/AttributesDialog';
import { Label } from '../ui/Label';

type Props = {
  label?: string;
  value?: string[] | null;
  onChange: (selected: string[]) => void;
  /** If true, integration attributes will be removed from the selection */
  isAttributesTask?: boolean;
};
export const AttributeSelect_Query = graphql(`
  query AttributeSelect {
    integrations
    attributes(entityType: "Site") {
      id
      type
      category
      name
      value
      condition
    }
  }
`);

/**
 * Select from the global collection of Site attributes
 */
export function AttributeSelect({
  label,
  value,
  onChange,
  isAttributesTask = false,
}: Props) {
  const { t } = useTranslation();
  const [tab, setTab] = useState<string>(PropFit);
  const [result] = useQuery({ query: AttributeSelect_Query });
  const { data } = result;
  const integration = data?.integrations[0];

  const attributesList = data?.attributes;

  const selectedAttributesSorted =
    !attributesList || !value
      ? []
      : attributesList.filter((a) => value.includes(a.id)).map((at) => at);

  const groupedByIntegration = groupByIntegration(
    integration,
    selectedAttributesSorted
  );

  const filteredGroup = groupedByIntegration[tab] ?? [];

  const groupedByCategory = groupBy(filteredGroup, (item) => {
    return item.category ?? 'General';
  });

  return (
    <>
      <Label>{t(label ?? 'translation:attribute_plural')}</Label>
      <AttributesDialog
        integration={integration}
        attributes={data?.attributes ?? []}
        initialValue={value}
        onChange={onChange}
        globalSelect
        isAttributesTask={isAttributesTask}
      />
      {value && (
        <>
          <div className='mb-2 flex'>
            {Object.keys(groupedByIntegration).length > 0
              ? Object.keys(groupedByIntegration).map((key) => {
                  if (isAttributesTask && key !== PropFit) return null;
                  return (
                    <div
                      className={classNames(
                        'flex h-12 flex-1 cursor-pointer flex-col items-center justify-center text-center text-sm font-normal not-italic leading-[normal] text-[#007070]',
                        tab === key &&
                          'border-b-[3px] border-brand font-semibold'
                      )}
                      onClick={() => setTab(key)}
                    >
                      <span className='capitalize'>{key}</span>
                    </div>
                  );
                })
              : null}
          </div>
          {Object.keys(groupedByCategory).map((key) => (
            <Fragment key={key}>
              <div className='pb-1 pt-3 text-base font-semibold text-primary'>
                {key}
              </div>
              {groupedByCategory[key].map((attribute) => (
                <Fragment key={attribute.id}>
                  {attribute && (
                    <p className='indent-5 text-sm'>{attribute.name}</p>
                  )}
                </Fragment>
              ))}
            </Fragment>
          ))}
          <br />
        </>
      )}
    </>
  );
}
