import { Link, useNavigate, useParams } from 'react-router-dom';
import { Attributes } from '~/components/ui/Attributes';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useTranslation } from 'react-i18next';
import { ViewSpaceQuery, useViewSpaceQuery } from '~/generated/graphql';
import { ReadValue } from '~/components/ui/ReadValue';
import { Loading } from '~/components/ui/Loading';
import { Menu } from '~/components/ui/Menu';

type Space = ViewSpaceQuery['spaces'][number];

export const SpaceView = () => {
  const navigate = useNavigate();
  const { spaceId } = useParams();
  const { t } = useTranslation();
  const [result] = useViewSpaceQuery({
    pause: !spaceId,
    variables: { id: spaceId! },
    requestPolicy: 'cache-and-network',
  });
  const space = result.data?.spaces[0];

  if (!space) {
    return <Loading />;
  }

  return (
    <SideLayout>
      <SideLayout.Head
        rightSlot={
          <Menu>
            <Menu.Item as={Link} to='edit'>
              {t('spaces.edit')}
            </Menu.Item>
          </Menu>
        }
        onClose={() => navigate('/spaces')}
      >
        {space.name}
      </SideLayout.Head>
      <SideLayout.Body>
        <div className='mb-5'>
          <StatusBadge value={space.status} rounded />
        </div>
        <div>
          <div className='label mb-3'>
            {t(`type.${space.location.__typename}`)}
          </div>
          <div className='mb-5'>
            <StockLocationLink location={space.location} />
          </div>
          <ReadValue label='description' value={space.description} />
          <ReadValue label='notes' value={space.notes} />
        </div>
        {/* @ts-expect-error FIXME */}
        <Attributes data={space} />
      </SideLayout.Body>
    </SideLayout>
  );
};

function StockLocationLink({ location }: { location: Space['location'] }) {
  const path = (() => {
    // prettier-ignore
    switch (location.__typename) {
      case 'Asset': return 'assets';
      case 'Contact': return 'contacts';
      case 'Site': return 'sites';
    }
  })();

  if (path) {
    return <Link to={`/${path}/${location.id}`}>{location.name}</Link>;
  }

  return <>{location.name}</>;
}
