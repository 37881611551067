import { Form, Formik, useFormikContext } from 'formik';
import produce from 'immer';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { PromptEffect } from '~/components/Prompt';
import { StockSelectionField } from '~/components/form/StockSelectionField';
import { SubmitButton } from '~/components/form/SubmitButton';
import { Select } from '~/components/form/downshift/Select';
import { Context } from '~/components/smc/card/Hero';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import { Button } from '~/components/ui/buttons/Button';
import {
  SelectStockOption,
  StockSelection,
  Sku as TSku,
  useAddItemsTaskQuery,
  useGeneratePicksMutation,
  useStockOnHandQuery,
} from '~/generated/graphql';
import { JobQuery, JobStatus } from '~/gql/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { StockTransferItem } from '~/types';
import { ItemTaskDetails } from './move-stock/ItemTaskDetails';

type Job = NonNullable<JobQuery['job']>;

type SpaceStock = {
  spaceId: number;
  spaceName: string;
  priority: number;
  skuId: number;
  soh: number;
};

type OriginItem = StockTransferItem & {
  id: string;
  spaceName: string;
  space: { name: string };
  sku: Pick<TSku, 'id' | 'code' | 'image' | 'name' | 'itemType'>;
  soh: number;
  reserved: number;
  [x: string]: any;
};

type GroupBy = ['source' | 'destination'];

type Props = {
  job: Job;
  onSuccess: () => void;
  onClose: () => void;
};

export const MoveStockOrigin = ({ job, onSuccess, onClose }: Props) => {
  const { taskId } = useParams();

  invariant(taskId, 'Task ID is required');

  const { t } = useTranslation(['job']);
  const [allProcessed, setAllProcessed] = useState(false);
  const [groupBy, setGroupBy] = useState<GroupBy>(['source']);
  const [{ fetching: loadingPicks }, generatePicks] =
    useGeneratePicksMutation();

  const [res, revalidate] = useAddItemsTaskQuery({
    variables: { id: taskId },
    requestPolicy: 'cache-and-network',
  });
  const task = res.data?.addItemsTask;

  const location = useMemo(() => {
    if (!job.location) throw new Error('Invalid job location');
    return `${job.location.__typename}:${job.location.id}`;
  }, [job]);

  const [result] = useStockOnHandQuery({
    pause: !job.location,
    variables: {
      location: [job.location?.__typename, job.location?.id].join(':'),
    },
  });
  const { data, error, fetching } = result;

  const spaceStock: SpaceStock[] = useMemo(() => {
    if (!data) return [];

    return data.stockOnHand.map((spaceSku) => {
      const { space, sku, soh /* priority */ } = spaceSku;
      return {
        spaceId: parseInt(space.id),
        spaceName: space.name,
        priority: 1,
        skuId: parseInt(sku.id),
        soh,
      };
    });
  }, [data]);

  const stock = useMemo(
    () =>
      spaceStock.reduce((acc, cur) => {
        // TODO needs to take into account priority
        return { ...acc, [cur.skuId]: cur };
      }, {} as Record<string, SpaceStock>),
    [spaceStock]
  );

  // at the origin, items are grouped by sku and sum of to pick for that sku
  const items = useMemo(() => {
    if (!task?.stockTransfer) return [];
    const list =
      groupBy[0] === 'source'
        ? // @ts-expect-error FIXME
          task.stockTransfer.items.reduce((acc: OriginItem[], cur) => {
            const added = acc.findIndex(
              (item: any) => item.sku_id === cur.skuId
            );
            if (added > -1) {
              return produce(acc, (draft) => {
                draft[added].pick += cur.pick;
                if (cur.picked) {
                  draft[added].picked = draft[added].picked || 0 + cur.picked;
                }
              });
            }
            const sku = task.cards.find(
              (card) => card.sku.id === cur.skuId
            )?.sku;
            if (!sku) return acc;
            return [
              ...acc,
              {
                ...cur,
                id: [stock[cur.skuId]?.spaceId ?? 'Elsewhere', cur.skuId].join(
                  ':'
                ),
                spaceName: stock[cur.skuId]?.spaceName ?? 'Elsewhere',
                space: { name: stock[cur.skuId]?.spaceName ?? 'Elsewhere' },
                sku: {
                  id: cur.skuId,
                  code: sku.code,
                  image: sku.image,
                  name: sku.name,
                  itemType: sku.itemType,
                },
                soh: stock[cur.skuId]?.soh ?? 0,
                reserved: 0,
              },
            ];
          }, [])
        : task.stockTransfer.items.map((item) => {
            const card = task.cards.find((card) => card.sku.id === item.skuId);
            const sku = card?.sku;
            if (!sku) throw new Error('Missing sku');
            return {
              ...item,
              id: [item.spaceId, item.skuId].join(':'),
              spaceName: card.space.name,
              space: {
                name: card.space.name,
              },
              sku: {
                id: sku.id,
                code: sku.code,
                image: sku.image,
                name: sku.name,
                itemType: sku.itemType,
              },
              soh: card.soh ?? 0,
              reserved: 0,
            };
          });
    return sortBy(list, 'spaceName');
  }, [groupBy, stock, task]);

  if (fetching || !task) {
    return <Loading />;
  }
  if (error || !data) {
    return (
      <ErrorMessage
        message={error?.message ?? 'Unable to fetch stock on hand'}
      />
    );
  }

  const selectItems = task.cards;
  const isComplete = task.status === JobStatus.Complete;

  const picked = selectItems.reduce((values, item) => {
    const { id, picked: qty } = item;
    if (qty == null) {
      return values;
    }
    return [...values, { id, qty }];
  }, [] as StockSelection[]);

  const canGeneratePicks =
    task.status !== 'Complete' &&
    task.stockTransfer.selectStock === SelectStockOption.AUTO;

  const handleGeneratePicks = async () => {
    const { error } = await generatePicks({
      stockTransferId: parseInt(task.stockTransfer.id),
    });

    if (error) {
      toast.error(error.graphQLErrors.join());
    }

    revalidate();
  };

  return (
    <Formik
      initialValues={{ source: picked, destination: [] }}
      onSubmit={async (values) => {
        const strategy = groupBy[0];
        const data = { strategy, picked: values[strategy] };
        await window.axios.put(`/tasks/${task.id}`, data);
        onSuccess();
      }}
      enableReinitialize
    >
      <Form>
        <PromptEffect />
        <SideLayout>
          <SideLayout.Head onClose={onClose}>
            {t('tasks.pickItems')}
          </SideLayout.Head>
          <SideLayout.Body className='p-0'>
            <ItemTaskDetails job={job} task={task} />
            {canGeneratePicks && (
              <p className='py-4 text-center'>
                <Button
                  type='button'
                  onClick={handleGeneratePicks}
                  color='primary'
                  loading={loadingPicks}
                  rounded
                  outlined
                >
                  {items.length ? 'Update' : 'Generate'} Picks
                </Button>
              </p>
            )}
            {!loadingPicks && (
              <div>
                <AllProcessedEffect
                  cardCount={items.length}
                  groupBy={groupBy[0]}
                  onAllProcessed={setAllProcessed}
                />
                <div className='px-4'>
                  <Select
                    label='Group By'
                    options={[
                      { value: 'source', label: 'Source spaces' },
                      { value: 'destination', label: 'Destination spaces' },
                    ]}
                    value={groupBy}
                    onChange={(val) => setGroupBy(val as GroupBy)}
                  />
                </div>
                <StockSelectionField
                  context={Context.PICK}
                  name={groupBy[0]}
                  location={location}
                  items={selectItems}
                  stock={items}
                  readOnly={isComplete}
                  moreFilters={false}
                />
              </div>
            )}
          </SideLayout.Body>
          <SideLayout.Foot className='p-4'>
            {isComplete ? (
              <button
                className='mb-5 w-full rounded-lg border border-brand bg-white p-3 font-semibold capitalize text-brand disabled:opacity-50'
                type='button'
                onClick={onClose}
              >
                {t('back')}
              </button>
            ) : (
              <SubmitButton enabled={task.cards.length > 0 && allProcessed} />
            )}
          </SideLayout.Foot>
        </SideLayout>
      </Form>
    </Formik>
  );
};

type AllProcessedEffectProps = {
  cardCount: number;
  groupBy: GroupBy[0];
  onAllProcessed: (allProcessed: boolean) => void;
};

const AllProcessedEffect = ({
  cardCount,
  groupBy,
  onAllProcessed,
}: AllProcessedEffectProps) => {
  const formik = useFormikContext<{
    source: unknown[];
    destination: unknown[];
  }>();

  useEffect(() => {
    onAllProcessed(formik.values[groupBy].length === cardCount);
  }, [cardCount, groupBy, formik.values, onAllProcessed]);

  return null;
};
