import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';
import { useAbilityContext } from '~/components/Can';
import { NavLink } from '~/components/NavLink';
import { handleLogout } from '~/components/auth/LogoutLink';
import { Navbar } from '~/components/nav/Navbar';
import { CountBadge } from '~/components/ui/CountBadge';
import { Menu } from '~/components/ui/Menu';
import { graphql } from '~/gql';
import { useLocalStorage } from '~/hooks';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useLocationHash } from '~/hooks/useLocationHash';
import { NavLogo } from './NavLogo';

type Props = {
  unreadActivity?: number;
};

const ActivitiesUnreadCount = graphql(`
  query ActivityUnreadCount {
    allActivity {
      totalUnreadCount
    }
  }
`);

function useUpdateActivityTotalUnreadCount(total: number | undefined) {
  const [result] = useQuery({
    query: ActivitiesUnreadCount,
    requestPolicy: 'cache-and-network',
    pause: total !== undefined,
  });

  if (total) return total;
  return result.data?.allActivity.totalUnreadCount ?? 0;
}

/** Convert translation key to route path */
const toRoutePath = (key: string) =>
  `/jobs/${key.substring('nav:jobs.'.length)}`;

export function JobsNav(props: Props) {
  const { t } = useTranslation(['translation', 'nav']);
  const localStorage = useLocalStorage();
  const ability = useAbilityContext();
  const [, setShowSettings] = useLocationHash('#settings-open');
  const hasScheduledAccess = ability.can('read', 'feat.schedule_view');
  const { isMobile } = useBreakpoint();

  const unreadActivity = useUpdateActivityTotalUnreadCount(
    props.unreadActivity
  );

  const subNavOptions = [
    'nav:jobs.scheduled',
    'nav:jobs.unscheduled',
    'nav:jobs.completed',
  ];
  if (hasScheduledAccess) {
    subNavOptions.unshift('nav:jobs.calendar');
  }

  function remember(event: React.MouseEvent<HTMLAnchorElement>) {
    const { pathname } = event.currentTarget;
    if (pathname) {
      localStorage.set('nav.jobs', pathname);
    }
  }

  return (
    <div className='z-50 flex items-center justify-between bg-white'>
      <NavLogo />
      <Navbar className='overflow-x-hidden'>
        {ability.can('use', 'feat.activity') && (
          <Navbar.Item
            className='relative pl-3 pr-3 lg:pl-5 lg:pr-5'
            as={NavLink}
            to='/activity'
            activeKey='nav:jobs.activity'
            isActive={() => location.pathname.startsWith('/activity')}
            onClick={remember}
          >
            {t('nav:jobs.activity')}

            {Boolean(unreadActivity) && (
              <div
                className={classNames(
                  isMobile
                    ? 'absolute right-6 top-2.5'
                    : 'absolute right-[22px] top-3'
                )}
              >
                <div className='relative'>
                  <CountBadge
                    className='absolute left-0 top-0'
                    count={unreadActivity}
                  />
                </div>
              </div>
            )}
          </Navbar.Item>
        )}
        {subNavOptions.map((key, i) => (
          <Navbar.Item
            key={key}
            as={NavLink}
            to={toRoutePath(key)}
            className={classNames(
              'pr-1',
              (!hasScheduledAccess ? i !== 0 && i !== 1 : i !== 1 && i !== 2)
                ? 'hidden lg:flex'
                : ''
            )}
            activeKey={key}
            isActive={() => location.pathname.startsWith(toRoutePath(key))}
            onClick={remember}
          >
            {t(key)}
          </Navbar.Item>
        ))}
      </Navbar>
      <div className='flex items-center'>
        <Link className='add-button' to='new'>
          + {t('job')}
        </Link>
      </div>
      <div className='lg:hidden'>
        <Menu>
          <Menu.Item as={Link} to='/jobs/completed'>
            {t('nav:jobs.completed')}
          </Menu.Item>
          {false && (
            <>
              <Menu.Item onClick={() => setShowSettings(true)}>
                {t('setting_plural')}
              </Menu.Item>
              <Menu.Item onClick={handleLogout}>{t('signOut')}</Menu.Item>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}
