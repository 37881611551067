import { useTranslation } from 'react-i18next';

/**
 *
 * @param enumObject type to return options for
 * @param translate if true value will be translated, if a string value will be translated using string as prefix, otherwise option label is equal to the value
 * @returns
 */
export function useEnumOptions(
  enumObject: Record<string, string>,
  translate?: boolean | string
) {
  const { t } = useTranslation();
  const getLabel =
    translate === true
      ? (value: string) => t(value)
      : translate
      ? (value: string) => t(translate + '.' + value)
      : (value: string) => value;

  return Object.values(enumObject).map((value) => ({
    value,
    label: getLabel(value),
  }));
}
