import { faCheck, faTag } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Combobox } from '@headlessui/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { DotsVerticalIcon, PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { by } from '~/helpers/filter';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useCheckOverflow } from '~/hooks/useCheckOverflow';
import { Menu } from '../ui/Menu';
import type { Tag } from './tags';

type Props = {
  items: Tag[];
  searchKeys: (keyof Tag)[];
  placeholder?: string;
  value: Tag[];
  onChange: (value: Tag[]) => void;
  onCreate?: ((tag?: Tag) => void) | null;
  onDelete: (tag: Tag) => void;
};

/**
 * Combobox with static, always visible list of options. Use inside a popover, dialog, command palette etc.
 */
export function TagCombolist({
  items,
  searchKeys,
  placeholder = '',
  value,
  onChange,
  onCreate,
  onDelete,
}: Props) {
  const [query, setQuery] = useState('');
  const { isMobile } = useBreakpoint();

  const optionsRef = useRef<HTMLUListElement>(null);
  const { isOverflowing } = useCheckOverflow(optionsRef);

  const searchResult =
    query === '' ? items : items.filter(by(query, searchKeys));
  const filteredItems = _.orderBy(searchResult, ['category'], ['desc']);

  function handleChange(newValue: Tag[]) {
    if (
      onCreate &&
      newValue.length > 0 &&
      newValue[newValue.length - 1] === null
    ) {
      onCreate({
        name: query,
        description: '',
        category: '',
        entityType: '',
        colour: '81848F',
      } as Tag);
      return;
    }

    onChange(newValue);
  }

  return (
    <Combobox
      value={value}
      onChange={handleChange}
      multiple={true}
      by={(a, b) => a?.id === b?.id}
    >
      <div className='sticky top-0 z-[60] rounded-t-md bg-white p-2'>
        {items.length ? (
          <div className='x-3 flex items-center rounded-full bg-grey-3 px-4 py-2'>
            <FontAwesomeIcon
              icon={faSearch}
              className='mr-3 text-sm text-grey-40'
            />
            <Combobox.Input
              className='flex-1 bg-transparent text-base outline-none'
              type='text'
              placeholder={
                placeholder
                  ? `Search ${placeholder.toLowerCase()}...`
                  : 'Search...'
              }
              onChange={(event) => setQuery(event.target.value)}
              autoFocus
            />
          </div>
        ) : (
          <div className='ml-1 text-base text-tertiary'>
            Tags selector is currently empty
          </div>
        )}
      </div>
      <Combobox.Options
        ref={optionsRef}
        static
        className={classNames(
          'flex scroll-py-3 flex-col',
          isOverflowing ? 'overflow-auto' : 'overflow-visible'
        )}
      >
        {onCreate && (
          <Combobox.Option
            value={null}
            className={({ active }) =>
              classNames(
                'flex cursor-pointer select-none items-center justify-between py-2 text-gray-900',
                active && 'bg-grey-10'
              )
            }
            key={'create'}
          >
            <div className='ml-2 flex items-center gap-2 truncate whitespace-nowrap'>
              <PlusIcon className='h-5 text-gray-900' aria-hidden='true' />
              <div className='text-base'>New Tag</div>
            </div>
          </Combobox.Option>
        )}

        <TagItems tags={filteredItems} />
      </Combobox.Options>
    </Combobox>
  );

  function TagItems({ tags }: { tags: Tag[] }) {
    const renderedCategories: string[] = [];

    return (
      <>
        {tags.map((tag, i) => {
          const tagItem = (
            <div key={tag.id}>
              {tag.category && !renderedCategories.includes(tag.category) && (
                <div className='mx-2 mt-2'>{tag.category}</div>
              )}

              <Combobox.Option key={tag.id} value={tag}>
                {({ active, selected, disabled }) => (
                  <div
                    className={classNames(
                      'flex min-h-[32px] cursor-default select-none items-center pl-2',
                      active && 'bg-grey-10',
                      selected && 'bg-brand-ghost',
                      disabled && 'opacity-75'
                    )}
                  >
                    <div className='mr-auto flex-col items-center gap-2 truncate whitespace-nowrap py-0.5'>
                      <div className='flex items-center'>
                        <FontAwesomeIcon
                          className='mr-[10px]'
                          style={{ color: `#${tag.colour}` }}
                          icon={faTag}
                        />
                        <span className='truncate text-base'>{tag.name}</span>
                      </div>
                      <div className='flex flex-col'>
                        <span className='ml-[23px] truncate text-sm text-tertiary'>
                          {tag.description}
                        </span>
                      </div>
                    </div>
                    {selected && (
                      <FontAwesomeIcon
                        className='mx-3 text-brand'
                        icon={faCheck}
                        fontSize='20px'
                      />
                    )}
                    {onCreate && (isMobile || (!isMobile && active)) ? (
                      <Menu
                        buttonText={
                          <DotsVerticalIcon className='h-[18px] text-tertiary hover:text-brand' />
                        }
                        buttonClassName={classNames(
                          'px-2',
                          tag.description && tag.description.length > 0
                            ? 'h-12'
                            : 'h-8',
                          !isMobile && 'bg-grey-20'
                        )}
                        topPosition={i < 4 ? '' : '-top-20'}
                      >
                        <Menu.Item
                          className='mt-2 p-2'
                          onClick={(e: Event) => {
                            onCreate && onCreate(tag);
                            e.stopPropagation();
                          }}
                        >
                          <div className='flex cursor-pointer items-center text-grey-70 hover:text-brand'>
                            <PencilIcon
                              className='ml-2 mr-1 h-5'
                              aria-hidden='true'
                            />
                            <span className='ml-3 text-base'>Edit Tag</span>
                          </div>
                        </Menu.Item>
                        <Menu.Item
                          className='mb-2 p-2'
                          onClick={(e: Event) => {
                            onDelete(tag);
                            e.stopPropagation();
                          }}
                        >
                          <div className='flex cursor-pointer items-center text-grey-70 hover:text-brand'>
                            <TrashIcon
                              className='ml-2 mr-1 h-5'
                              aria-hidden='true'
                            />
                            <span className='ml-3 text-base'>Delete</span>
                          </div>
                        </Menu.Item>
                      </Menu>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </Combobox.Option>
            </div>
          );

          if (tag.category && !renderedCategories.includes(tag.category)) {
            renderedCategories.push(tag.category);
          }

          return tagItem;
        })}
      </>
    );
  }
}
