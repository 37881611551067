import produce from 'immer';
import { useCallback, useState } from 'react';
import { byNaturalValue } from '~/helpers/sort';

export type TSort = {
  path: string;
  desc?: boolean;
};

type Opts = {
  default: string;
};

export type SortByFunc = (sort?: TSort) => void;

export const useSort = <T>(data: readonly T[] | undefined, options?: Opts) => {
  const defaultSort = options?.default ? { path: options.default } : undefined;
  const [sorting, setSort] = useState<TSort | undefined>(defaultSort);

  const doSort = (data: readonly T[], sorting: TSort) => {
    return produce(data, (draft) => {
      // @ts-expect-error FIXME
      draft.sort(byNaturalValue(sorting.path));
      if (sorting.desc) draft.reverse();
    });
  };

  const sorted = sorting && data ? doSort(data, sorting) : data;
  const sortBy = useCallback((sort?: TSort) => {
    setSort(sort);
  }, []);

  return {
    sorted,
    sortBy,
  };
};
