import { useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import { JobTemplateForm } from '~/components/job-template/JobTemplateForm';
import { action, loader } from './job-templates.new';

export { action, loader };

export default function CloneJobTemplateRoute() {
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const navigate = useNavigate();
  const submit = useSubmit();

  function handleClose() {
    navigate('..');
  }

  return (
    <JobTemplateForm
      data={data}
      onClose={handleClose}
      onSubmit={submit}
      clone
    />
  );
}
