import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Square } from '~/brand/generated/propfit.app';
import { Input } from '~/components/form/Input';
import { post } from '~/helpers/fetch';
import { LogoSection } from './LogoSection';

type FormData = {
  username: string;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string().required('Required'),
});

export const ForgotPassword = () => {
  const [done, setDone] = useState(false);
  const { t } = useTranslation('auth');

  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const forgotPassword = async (values: FormData) => {
    try {
      await post('/api/forgot-password', values);
      setDone(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  if (done) {
    return (
      <div className='flex w-screen items-center justify-center h-screen'>
        <div className='w-full lg:w-1/2'>
          <div className='mx-auto flex flex-col justify-center rounded-lg px-10 py-24 text-center md:w-4/5 md:border md:border-gray88 lg:mx-auto lg:w-[30rem]'>
            <div className='mb-8 flex flex-col items-center justify-center'>
              <Square className='mx-auto h-20' />
            </div>
            <p className='mb-6 text-lg font-semibold'>{t('resetPassword')}</p>
            <p>{t('forgot.confirmation')}</p>
            <div className='mt-6 text-center text-sm'>
              <Link className='font-normal underline' to='/login?pw=1'>
                {t('backToLogin')}
              </Link>
            </div>
          </div>
        </div>
        <LogoSection />
      </div>
    );
  }

  return (
    <div className='flex w-screen items-center justify-center h-screen'>
      <div className='w-full lg:w-1/2'>
        <form
          onSubmit={handleSubmit(forgotPassword)}
          className='mx-auto flex flex-col justify-center rounded-lg px-10 py-24 md:w-4/5 md:border md:border-gray88 lg:mx-auto lg:w-[30rem] '
        >
          <div className='mb-8 flex flex-col items-center justify-center'>
            <Square className='mx-auto h-20' />
            <h1 className='mb-6 mt-5 text-2xl font-medium'>Set New Password</h1>
          </div>
          <Input
            {...register('username')}
            placeholder={t('email')}
            label={t('forgot.instruction')}
            type='email'
            error={errors.username?.message}
          />

          <button
            className='mb-4 w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark  disabled:opacity-50'
            disabled={isSubmitting}
          >
            {t('recoverPassword')}
          </button>
          <div className='text-center text-sm'>
            <Link className='font-normal underline' to='/login?pw=1'>
              {t('backToLogin')}
            </Link>
          </div>
        </form>
      </div>

      <LogoSection />
    </div>
  );
};
