import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionForm } from '~/components/form/ActionForm';
import { LocationField } from '~/components/form/LocationField';
import { StatusToggle } from '~/components/form/StatusToggle';
import { TextField } from '~/components/form/TextField';
import { AttributesField } from '~/components/form/attributes/AttributesField';
import { Loading } from '~/components/ui/Loading';
import { UploadField } from '~/components/upload/Upload';
import {
  useCreateSpaceMutation,
  useUpdateSpaceMutation,
  useViewSpaceQuery,
} from '~/generated/graphql';
import { PromptEffect } from '../Prompt';

type Props = {
  title: string;
  reload: () => void;
};

export const SpaceForm = ({ title, reload }: Props) => {
  const [, create] = useCreateSpaceMutation();
  const [, update] = useUpdateSpaceMutation();
  const navigate = useNavigate();
  const { id: spaceId } = useParams();
  const { t } = useTranslation();

  const [result] = useViewSpaceQuery({
    pause: !spaceId,
    variables: { id: spaceId! },
  });
  const space = result.data?.spaces[0];

  const action = async (values: any) => {
    const { location, ...input } = values;

    const res = spaceId
      ? await update({ id: spaceId, input })
      : await create({
          locationId: `${location.type}:${location.id[0]}`,
          input,
        });

    if (res.error) {
      toast.error(res.error.message.replace('[GraphQL] ', ''));
      throw new Error();
    }
  };

  if (spaceId && !space) {
    return <Loading />;
  }

  const initialValues = space
    ? {
        status: space.status,
        name: space.name,
        attributes: space.attributes ?? [],
        images: /* space.images ?? */ [],
      }
    : {
        status: 'Active',
        name: '',
        location: {
          id: [],
          type: 'Site',
        },
        attributes: [],
        images: [],
      };

  return (
    <ActionForm
      action={action}
      title={title}
      initialValues={initialValues}
      onCancel={() => navigate(spaceId ? `/spaces/${spaceId}` : '/spaces')}
      onSuccess={({ data, status }) => {
        navigate(`/spaces/${data.id}`);

        if (status === 201) {
          reload();
        }
      }}
    >
      <PromptEffect />
      <StatusToggle />
      <TextField name='name' label={t('spaceName')} className='mb-5' required />
      {space && (
        <>
          <div className='label mb-1'>{t(space.location.__typename)}</div>
          <div className='mb-5'>{space.location.name}</div>
        </>
      )}

      {!spaceId && <LocationField title={t('location')} required />}
      <AttributesField type='Space' />
      <UploadField name='images' />
    </ActionForm>
  );
};
