import { useContext } from 'react';
import { can, is } from '~/helpers/user';
import { AppContext } from '~/App';
import { Role } from '~/types';

export function useAuth() {
  const { user: currentUser } = useContext(AppContext);

  return {
    isCurrentUser(user: { id: number | string } | string | null | undefined) {
      if (user && currentUser) {
        const id = typeof user === 'string' ? user : '' + user.id;
        return id === '' + currentUser.id;
      }
      return false;
    },

    can(permission: string) {
      return currentUser ? can(currentUser, permission) : false;
    },

    is(role: Role) {
      return currentUser ? is(currentUser, role) : false;
    },
  };
}
