import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { gql, useQuery } from 'urql';
import * as yup from 'yup';
import { DateTimeField } from '~/components/form/DateTimeField';
import { LocationField } from '~/components/form/LocationField';
import { SubmitButton } from '~/components/form/SubmitButton';
import { UploadField } from '~/components/upload/Upload';
import { useCreateDeploymentMutation } from '~/generated/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useAssetContext } from '~/routes/assets';
import { ComboboxField } from '../ui/Combobox';

type Props = { onSuccess: () => void };

const schema = yup.object().shape({
  location: yup
    .object()
    .shape({
      id: yup.array().of(yup.string()).min(1, 'Required'),
      type: yup.string().oneOf(['Fsp', 'Site']).required(),
    })
    .required('Location is required'),
  spaceName: yup.string().trim().min(1, 'Required').required('Required'),
  startDate: yup.date().required('Required'),
  images: yup.array().of(yup.string()),
});

export function DeploymentForm() {
  const { reload } = useAssetContext();
  const navigate = useNavigate();
  const { assetId } = useParams();
  invariant(assetId, 'assetId is required');
  const back = () => navigate(`/assets/${assetId}`);
  const [, create] = useCreateDeploymentMutation();

  return (
    <Formik
      initialValues={{
        location: { id: [], type: '' },
        spaceName: '',
        startDate: new Date(),
        images: [],
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        const { location, spaceName, startDate, images } = values;
        await create({
          input: {
            assetId,
            locationId: locationToId(location),
            spaceName,
            startDate,
            images,
          },
        });
        reload();
        back();
      }}
    >
      <SideLayout>
        <SideLayout.Head onClose={back}>New Deployment</SideLayout.Head>
        <SideLayout.Body>
          <LocationField title='Deploy to Location Type' />
          <SpaceNameComboboxField />
          <DateTimeField name='startDate' />
          <div className='flex flex-col p-4 text-center'>
            <UploadField name='images' />
          </div>
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
}

const LocationSpacesQuery = gql`
  query ($locationId: ID!) {
    location(id: $locationId) {
      spaces {
        id
        name
      }
    }
  }
`;

function locationToId(location: { id: string[]; type: string }) {
  return location && location.id.length
    ? `${location.type}:${location.id}`
    : undefined;
}

function SpaceNameComboboxField() {
  const { t } = useTranslation();
  const {
    values: { location },
  } = useFormikContext<{ location: { id: string[]; type: string } }>();
  const locationId = locationToId(location);
  const [result] = useQuery<{
    location: { spaces: { id: string; name: string }[] };
  }>({
    query: LocationSpacesQuery,
    variables: { locationId },
    pause: !locationId,
  });
  const spaces = result.data?.location.spaces.map(({ name }) => name);

  return (
    <ComboboxField
      name='spaceName'
      label={t('spaceName')}
      items={spaces ?? []}
      disabled={!locationId}
    />
  );
}
