import { StockSelectionField } from '~/components/form/StockSelectionField';
import { SubmitButton } from '~/components/form/SubmitButton';
import { SwitchField } from '~/components/form/switch/SwitchField';
import { Context } from '~/components/smc/card/Hero';
import { Formik } from 'formik';
import { StockSelection } from '~/generated/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useTranslation } from 'react-i18next';

type FormValues = {
  reserveStock: boolean;
  dispatch: StockSelection[];
};

type Props = {
  location?: string;
  initialValues: FormValues;
  onBack: () => void;
  onSubmit: (values: FormValues) => void;
};

export const StockToDispatch = ({
  location,
  initialValues,
  onBack,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  if (!location) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <SideLayout>
        <SideLayout.Head onClose={onBack} iconClass='chevron-left'>
          Stock to Dispatch
        </SideLayout.Head>
        <SideLayout.Body className='flex-1 overflow-y-auto px-0 pt-4'>
          <p className='text-copy-alt mb-5 px-4 text-sm font-bold tracking-wide'>
            Stock to come from {t(location.split(':')[0])}
          </p>
          <SwitchField name='reserveStock' label='Reserve Stock' />
          <StockSelectionField
            context={Context.MANUAL_SOURCE}
            name='dispatch'
            location={location}
          />
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
};
