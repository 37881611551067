import { useState } from 'react';
import { Step1Form } from './Step1Form';
import { Step2Form } from './Step2Form';
import { CancelCallback, Data, Mode, SuccessCallback } from './types';

type Props = {
  mode: Mode;
  onCancel: CancelCallback;
  onSuccess: SuccessCallback;
  location?: string;
};

export const StockManipulationComponent = ({
  mode,
  onCancel,
  onSuccess,
  location,
}: Props) => {
  const [step, setStep] = useState<number>(1);
  const [data, setData] = useState<Data>();
  const [prevData, setPrevData] = useState(null);

  const handleStep1 = (formData: any) => {
    setData(formData);
    setPrevData(formData);
    setStep(2);
  };

  const handleStep2 = (step2Data: any) => {
    setData((state) => ({ ...state, ...step2Data }));
    onSuccess();
  };

  const handlePrevState = (value: number) => {
    setStep(value);
  };

  return (
    <>
      {step === 1 && (
        <Step1Form
          mode={mode}
          onCancel={onCancel}
          onSuccess={handleStep1}
          prevData={prevData}
          location={location}
        />
      )}
      {step === 2 && data && (
        <Step2Form
          mode={mode}
          data={data}
          onCancel={onCancel}
          onSuccess={handleStep2}
          onPrevState={handlePrevState}
        />
      )}
    </>
  );
};
