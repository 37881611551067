import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '~/hooks/useAuth';
import { Button } from './ui/buttons/Button';

type Props = {
  children: React.ReactNode;
  to?: string;
};

/**
 * A `Button` preconfigured for create action, uses authorization
 */
export const CreateButton = ({ children, to = 'new' }: Props) => {
  const { can } = useAuth();
  const { pathname } = useLocation();
  const createResource = pathname.split('/')[1] + '.create';

  if (!can(createResource)) {
    return null;
  }

  return (
    <Button as={Link} to={to} color='primary' rounded>
      + {children}
    </Button>
  );
};
