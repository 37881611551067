import { Combobox } from '../../ui/Combobox';
import { useAttributes } from './AttributesField';

type Props = {
  value: { name: string; value: string };
  onChange: (attribute: { name: string; value: string }) => void;
};

export function Pair({ value: attributeValue, onChange }: Props) {
  const { names, getValues } = useAttributes();
  const { name, value } = attributeValue;

  const handleChange = (key: keyof Props['value']) => (newValue: string) => {
    onChange({ ...attributeValue, [key]: newValue ?? '' });
  };

  return (
    <>
      <Combobox items={names} value={name} onChange={handleChange('name')} />
      <Combobox
        items={getValues(name)}
        value={value}
        onChange={handleChange('value')}
      />
    </>
  );
}
