import 'flatpickr/dist/themes/light.css';
import { useField } from 'formik';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import { formatDate, LONG_FORMAT } from '~/helpers/formatDate';
import { Input } from './TextField';

interface DateTimeProps extends DateTimePickerProps {
  error?: string;
  maxDate?: string | number | Date;
}

interface DateTimeFieldProps extends DateTimeProps {
  name: string;
}

export const DateTime = ({ error, maxDate, ...props }: DateTimeProps) => {
  const dateTime = props.value && (props.value as Date);
  const { t } = useTranslation();

  const label = t('dateAndTime');

  return (
    <>
      <Flatpickr
        options={{
          dateFormat: 'd/m/Y H:i',
          defaultDate: new Date().valueOf(),
          enableTime: true,
          time_24hr: true,
          wrap: true,
          maxDate,
          disableMobile: true,
        }}
        {...props}
      >
        {dateTime ? (
          <Input
            readOnly
            value={formatDate(dateTime.toISOString(), LONG_FORMAT)}
            label={label}
            data-input
          />
        ) : (
          <Input label={label} data-input />
        )}
      </Flatpickr>
      {error && <div className='ErrorMessage'>{error}</div>}
    </>
  );
};

export const DateTimeField = ({ name, ...props }: DateTimeFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  return (
    <DateTime
      error={meta.touched && meta.error ? meta.error : ''}
      value={field.value}
      onChange={(val) => setValue(val[0])}
      {...props}
    />
  );
};
