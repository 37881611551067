import { useFormikContext } from 'formik';
import { useEffect } from 'react';

type Props = {
  storageKey: string;
  fields?: string[];
};

export const SessionStorageEffect = ({ storageKey, fields }: Props) => {
  const { setValues, values } = useFormikContext<Record<string, unknown>>();

  useEffect(() => {
    const session = window.sessionStorage.getItem(storageKey);
    if (!session) {
      return;
    }
    try {
      const saved = JSON.parse(session);
      if (Object.keys(saved).length) {
        setValues({ ...values, ...saved }, false);
      }
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey, setValues]);

  useEffect(() => {
    let save: any = {};
    if (fields) {
      fields.forEach((field) => {
        if (values[field]) {
          save[field] = values[field];
        }
      });
    } else {
      save = values;
    }
    window.sessionStorage.setItem(storageKey, JSON.stringify(save));
  }, [storageKey, fields, values]);

  return null;
};
