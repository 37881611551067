import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from '~/components/NavLink';
import { Navbar } from '~/components/nav/Navbar';
import { NavLogo } from './NavLogo';

type Page = {
  /**
   * translation key for link text
   * @todo rename to "key" or "tKey"
   */
  title: string;
  url: string;
  /**
   * Translation key to use for create button or undefined if no create button
   */
  createButton?: string;
};

type SubNavProps = {
  pages: Page[];
  /**
   * Index of page url matches
   */
  match?: number;
  permissionKey?: string;
  hideLogo?: boolean;
  isActive?: (key: string) => void;
  onItemClick?: (page: Page) => void;
};

function PageTitle({
  pages,
  isActive,
}: Pick<SubNavProps, 'pages' | 'isActive'>) {
  const { t } = useTranslation(['translation', 'nav']);
  const location = useLocation();

  for (let i = 0; i < pages.length; i++) {
    const { title, url } = pages[i];
    const match = isActive ? isActive(title) : location.pathname === url;
    if (match) {
      return (
        <h1 className='ml-2 text-center text-xl font-semibold'>{t(title)}</h1>
      );
    }
  }

  return null;
}

export const SubNav = ({
  pages,
  match,
  permissionKey,
  hideLogo,
  isActive,
  onItemClick,
}: SubNavProps) => {
  const { t } = useTranslation(['translation', 'nav']);

  return (
    <>
      <div className='row grid w-full grid-cols-[4rem_1fr_4rem] items-center justify-between py-3 pr-2 lg:hidden'>
        {hideLogo ?? <NavLogo />}
        <PageTitle pages={pages} isActive={isActive} />
        <div className='ml-auto w-10 text-right'>
          {pages.length > 1 && (
            <Menu as='div' className='ml-auto inline-block text-left'>
              <Menu.Button>
                <DotsVerticalIcon className='hover:text-brand mt-2 h-6 w-6' />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95 -translate-y-5'
                enterTo='transform opacity-100 scale-100 translate-y-0'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='border-gray55 absolute right-4 z-50 mt-2 w-44 origin-top-right overflow-hidden rounded-md border bg-white shadow-lg focus:outline-none'>
                  <div className='flex flex-col bg-white'>
                    {pages.map((page) => {
                      const { title, url } = page;
                      return (
                        <Menu.Item key={title}>
                          <Link
                            className='border-gray88 hover:text-brand border-b py-4 pl-5 text-sm font-medium text-black opacity-60 last:border-b-0'
                            to={url}
                            onClick={onItemClick && (() => onItemClick(page))}
                          >
                            {t(title)}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      </div>
      <Navbar>
        {pages.map((page) => {
          const { title, url } = page;
          return (
            <Navbar.Item
              as={NavLink}
              to={url}
              key={title}
              className='hidden lg:flex'
              permissionKey={permissionKey ? permissionKey : ''}
              onClick={onItemClick && (() => onItemClick(page))}
              activeKey={isActive ? title : undefined}
              isActive={isActive}
            >
              {t(title)}
            </Navbar.Item>
          );
        })}

        {match != null && pages[match].createButton && (
          <div className='ml-auto flex items-center'>
            <Link className='add-button' to='new'>
              + {t(pages[match].createButton!)}
            </Link>
          </div>
        )}
      </Navbar>
    </>
  );
};
