import { Gallery } from '~/components/ui/Gallery';
import { Loading } from '~/components/ui/Loading';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { useListAssetModelsQuery } from '~/generated/graphql';
import { DetailView } from '~/layouts/detail/DetailView';
import { startCase } from 'lodash';
import { useParams } from 'react-router-dom';

export function useAssetModel(id: string | undefined) {
  const [{ data }] = useListAssetModelsQuery({
    pause: !id,
    requestPolicy: 'cache-only',
    variables: {},
  });
  return data?.assetModels.find((model) => model.id === id);
}

export const AssetModelView = () => {
  const { modelId } = useParams();
  useListAssetModelsQuery();
  const model = useAssetModel(modelId);

  if (!model) {
    return <Loading />;
  }

  const description = model.notes ? (
    <div className='whitespace-pre-line'>{model.notes}</div>
  ) : null;

  return (
    <DetailView title={model.displayName} returnTo='/asset-models'>
      <StatusBadge value={model.status} rounded />
      <ReadValue label='assetType' value={startCase(model.type)} />
      {/* <Attributes data={model} /> */}
      <ReadValue label='description' value={description} />
      <Gallery images={model.image} />
    </DetailView>
  );
};
