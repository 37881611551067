import { Formik, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AttributeFormikField } from '~/components/AttributeForm';
import { SubmitButton } from '~/components/form/SubmitButton';
import { Attachment, SiteInput, SiteQuery, Status } from '~/generated/graphql';
import { parseJson } from '~/helpers';

type Props = {
  site?: SiteQuery['site'];
  search?: string;
  onSubmit: (input: SiteInput) => void;
  onFormTouched?: (isDirty: boolean) => void;
};

export const AttributeEditForm = ({
  site,
  search,
  onSubmit,
  onFormTouched,
}: Props) => {
  const { t } = useTranslation();

  const attachments = site?.attributes?.reduce(
    (prev: Record<string, Attachment[]>, curr) => {
      if (curr.attachments && curr.attachments.length) {
        return { ...prev, [curr.id]: curr.attachments };
      }
      return prev;
    },
    {}
  );

  const initialValues: SiteInput = {
    status: site?.status ?? Status.Active,
    name: site?.name ?? '',
    type: site?.type ?? '',
    address: site?.address ? parseJson(site.address) : null,
    defaultSourceId: site?.defaultSource?.id ?? null,
    licensorId: site?.licensor?.id ?? null,
    notes: site?.notes ?? '',
    attributes:
      site?.attributes
        ?.filter(({ id }) => !id.startsWith('integration_'))
        .map(({ id, value, attachments }) => ({
          id,
          name: id,
          value,
          attachments: attachments?.map((saved) => ({
            id: saved.id,
            kind: saved.kind,
            originalFilename: saved.originalFilename,
            preview: saved.thumb,
          })),
        })) ?? [],
    images: site?.images ?? [],
    airbnbCalendar: (site?.airbnbCalendar?.map(({ url }) => url) ?? [
      '',
    ]) as string[],
    airbnbDefaultCheckIn: site?.airbnbDefaultCheckIn ?? '',
    airbnbDefaultCheckOut: site?.airbnbDefaultCheckOut ?? '',
  };

  async function handleSubmit(input: SiteInput) {
    return onSubmit({
      ...input,
      address: JSON.stringify(input.address),
      attributes: input.attributes?.map(({ name, value }) => ({
        name,
        value,
      })),
    });
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ dirty }) => {
        return (
          <>
            {onFormTouched && <FormikDirtyEffect onDirty={onFormTouched} />}
            <div className='mb-2 mt-8'>
              <AttributeFormikField attachments={attachments} search={search} />
            </div>

            {dirty && (
              <div className='border-t-1 sticky bottom-0 rotate-180 bg-[#f8f9fa] p-4 shadow'>
                <div className='rotate-180'>
                  <SubmitButton>{t('saveChanges')}</SubmitButton>
                </div>
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};

function FormikDirtyEffect(props: { onDirty: (dirty: boolean) => void }) {
  const { dirty } = useFormikContext();

  useEffect(() => {
    props.onDirty(dirty);
  }, [dirty]);

  return null;
}
