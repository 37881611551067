import { Tag } from '~/generated/graphql';
import { Dialog } from '../ui/Dialog';

export function DeleteConfirmation({
  tag,
  onClose,
  onDelete,
}: {
  tag: Tag | null;
  onClose: () => void;
  onDelete: () => void;
}) {
  return (
    <Dialog
      show={tag !== null}
      danger={true}
      title='Delete Tag'
      onClose={onClose}
    >
      <div className='flex flex-col'>
        <span className='my-6'>"{tag?.name}" will be deleted</span>

        <div className='flex gap-3 self-end px-5 pb-5'>
          <button
            className='px-4 py-2 text-sm font-medium uppercase transition-all duration-500 hover:text-brand'
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type='button'
            className='rounded-md border border-delete bg-delete px-5 py-2 text-sm font-medium uppercase text-white transition-all duration-200 hover:brightness-75 hover:transition-all hover:duration-500'
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </Dialog>
  );
}
