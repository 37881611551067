import { isFunction } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { SideLayout } from '~/layouts/side/SideLayout'
import { CancelPrompt } from './CancelPrompt'
import { useTranslation } from 'react-i18next'
import { SubmitButtonGroup } from './SubmitButtonGroup'

/**
 * A standard form composed of the following features/components:
 * - Formik form
 * - Side layout with title
 * - Cancel prompt
 *
 * @deprecated Use ActionForm instead
 */
export const ModalForm = ({
  formikProps,
  title,
  children,
  buttonText = 'Submit',
  onCancelProp = null,
  onPrevState = null,
  returnTo,
}) => {
  const navigate = useNavigate()
  const headProps = onPrevState
    ? {
        iconClass: 'chevron-left',
        onClose: onPrevState,
      }
    : {
        iconClass: 'x',
        onClose() {
          navigate(returnTo)
        },
      }
  const { t } = useTranslation()

  return (
    <Formik enableReinitialize {...formikProps}>
      {(formik) => {
        const { isSubmitting, dirty } = formik
        return (
          <Form>
            <CancelPrompt when={dirty}>
              <SideLayout>
                <SideLayout.Head {...headProps}>{t(title)}</SideLayout.Head>
                <SideLayout.Body>
                  {isFunction(children) ? children(formik) : children}
                </SideLayout.Body>
                <SideLayout.Foot>
                  <SubmitButtonGroup
                    buttonText={buttonText}
                    onCancel={onCancelProp}
                    disabled={isSubmitting}
                  />
                </SideLayout.Foot>
              </SideLayout>
            </CancelPrompt>
          </Form>
        )
      }}
    </Formik>
  )
}
