import { SVGProps } from 'react'

const SvgTable = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
    <g transform='translate(-326 -1231)'>
      <g
        transform='translate(348.151 1233) rotate(90)'
        fill='#fff'
        strokeLinejoin='round'
        strokeWidth={2}
      >
        <rect width={20} height={20.15} rx={2} stroke='none' />
        <rect x={1} y={1} width={18} height={18.15} rx={1} fill='none' />
      </g>
      <g
        transform='translate(342.081 1233) rotate(90)'
        fill='#fff'
        strokeLinejoin='round'
        strokeWidth={2}
      >
        <rect width={20} height={8.08} stroke='none' />
        <rect x={1} y={1} width={18} height={6.08} fill='none' />
      </g>
    </g>
  </svg>
)

export default SvgTable

