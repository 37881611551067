import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { Can, useAbilityContext } from '~/components/Can';
import { NavLink } from '~/components/NavLink';
import { LogoutLink } from '~/components/auth/LogoutLink';
import { Navbar } from '~/components/nav/Navbar';
import { useLocalStorage } from '~/hooks';
import { useLocationHash } from '~/hooks/useLocationHash';
import { NavLogo } from './NavLogo';
import { SettingsLink } from './SettingsLink';
import { SupportLink } from './SupportLink';
import { TenantSwitcher } from './TenantSwitcher';
import ContactsIcon from './icons/Contacts';
import SpacesIcon from './icons/Spaces';

type MobileMenuState = {
  in: boolean;
  style: CSSProperties;
};

export const MobileMenu = ({ onClose }: { onClose: () => void }) => {
  const [, setShowSettings] = useLocationHash('#settings-open');
  const [state, setState] = useState<MobileMenuState>({
    in: false,
    style: { top: '100%', opacity: 0.5 },
  });
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    document.body.style.overflow = 'hidden';
    setState({ in: true, style: { top: 0, opacity: 1 } });

    return () => {
      document.body.style.overflow = '';
    };
  }, [setState]);

  const handleClick = () => {
    setState({ in: false, style: { top: '100%', opacity: 0.5 } });
  };

  return (
    <nav
      className={classNames([
        'pt-8', // iphone notch
        'flex flex-col',
        'absolute -inset-0',
        'z-50 bg-white opacity-100',
        'lg:hidden',
        'transition-all duration-300 ease-in-out',
        'overflow-y-auto',
      ])}
      style={state.style}
      onTransitionEnd={(e) => !state.in && onClose()}
    >
      <div className='relative px-6 py-4 pt-0 text-center text-xl font-medium text-copy-head'>
        <button
          className='x'
          style={{ position: 'absolute', bottom: '1rem', left: '1rem' }}
          onClick={handleClick}
        ></button>
        {t('more')}
      </div>
      <ul className='mb-12 divide-y divide-gray88 pt-4'>
        <Can do='manage' on='feat.templates'>
          <li className='cursor-pointer px-6 text-lg text-copy-head'>
            <NavLink
              to='/job-templates'
              onClick={onClose}
              className='flex flex-row items-center justify-start py-4 font-medium text-copy-alt hover:text-brand lg:py-0 lg:text-base lg:font-normal'
            >
              <span>
                <FontAwesomeIcon
                  className='-ml-0.5 mr-3.5 block h-6 w-8 flex-1 fill-current'
                  icon={faListCheck}
                />
              </span>
              {t('jobTemplate_plural')}
            </NavLink>
          </li>
        </Can>
        <li className='cursor-pointer px-6 text-lg text-copy-head'>
          <NavLink
            to='/contacts'
            onClick={onClose}
            className='flex flex-row items-center justify-start py-4 font-medium text-copy-alt hover:text-brand lg:py-0 lg:text-base lg:font-normal'
          >
            <span>
              <ContactsIcon className='-ml-0.5 mr-3.5 block h-8 w-8 flex-1 fill-current' />
            </span>
            {t('contact_plural')}
          </NavLink>
        </li>
        <li className='hidden cursor-pointer px-6 text-lg text-copy-head'>
          <NavLink
            to='/spaces'
            onClick={onClose}
            className='flex flex-row items-center justify-start py-4 font-medium text-copy-alt hover:text-brand lg:py-0 lg:text-base lg:font-normal'
          >
            <span>
              <SpacesIcon className='-ml-0.5 mr-3.5 block h-8 w-8 flex-1 fill-current' />
            </span>
            {t('space_plural')}
          </NavLink>
        </li>
        <li className='cursor-pointer px-6 text-lg text-copy-head'>
          <SettingsLink onClick={() => setShowSettings(true)} />
        </li>
        <li className='px-6 pt-1 text-lg text-copy-head'>
          <LogoutLink />
        </li>
        <li className='cursor-pointer px-6 text-lg text-copy-head'>
          <SupportLink />
        </li>
      </ul>
      <TenantSwitcher />
    </nav>
  );
};

export const MoreNav = () => {
  const { t } = useTranslation();
  const localStorage = useLocalStorage();

  const location = useLocation();
  const matchJobTemplates = useMatch('/job-templates/*');
  const matchAutomations = useMatch('/automations/*');
  const matchContacts = useMatch('/contacts/*');

  const pages = [
    {
      title: 'contact_plural',
      url: '/contacts',
    },
  ];

  const ability = useAbilityContext();

  if (ability.can('manage', 'feat.templates')) {
    pages.splice(0, 0, {
      title: 'jobTemplate_plural',
      url: '/job-templates',
    });
  }

  if (ability.can('manage', 'feat.automation')) {
    pages.splice(1, 0, {
      title: 'automation_plural',
      url: '/automations',
    });
  }

  if (ability.can('manage', 'feat.items')) {
    pages.push({
      title: 'space_plural',
      url: '/spaces',
    });
  }

  function remember(event: React.MouseEvent<HTMLAnchorElement>) {
    const { pathname } = event.currentTarget;
    if (pathname) {
      localStorage.set('nav.more', pathname);
    }
  }

  return (
    <>
      <div className='row flex w-full items-center justify-between py-3 pr-4 lg:hidden'>
        <NavLogo />
        {pages.map(({ title, url }) => {
          return url === location.pathname ? (
            <h1 key={url} className='text-xl font-semibold'>
              {t(title)}
            </h1>
          ) : (
            ''
          );
        })}
        <div className='w-10 text-right'>&nbsp;</div>
      </div>
      <div className='hidden lg:flex'>
        <Navbar>
          {pages.map(({ title, url }) => (
            <Navbar.Item key={title} as={NavLink} to={url} onClick={remember}>
              {t(title)}
            </Navbar.Item>
          ))}
          <Can do='manage' on='feat.templates'>
            {matchJobTemplates && (
              <div className='ml-auto flex items-center'>
                <Link className='add-button' to='new'>
                  + {t('jobTemplate')}
                </Link>
              </div>
            )}
          </Can>
          <Can do='manage' on='feat.automation'>
            {matchAutomations && (
              <div className='ml-auto flex items-center'>
                <Link className='add-button' to='new'>
                  + {t('automation')}
                </Link>
              </div>
            )}
          </Can>
          {matchContacts && (
            <div className='ml-auto flex items-center'>
              <Link className='add-button' to='new'>
                + {t('contact')}
              </Link>
            </div>
          )}
        </Navbar>
      </div>
    </>
  );
};
