import { useContext } from 'react';
import { AppContext } from '~/App';

// TODO rename to useStorage now that this supports session as well?
export function useLocalStorage(options?: { session?: boolean }) {
  const {
    tenant: { id: tenantId },
  } = useContext(AppContext);

  function get(key: string) {
    const storage = options?.session
      ? window.sessionStorage
      : window.localStorage;
    return storage.getItem(`tid:${tenantId}::${key}`);
  }

  function set(key: string, value: string) {
    const storage = options?.session
      ? window.sessionStorage
      : window.localStorage;
    return storage.setItem(`tid:${tenantId}::${key}`, value);
  }

  return { get, set };
}
