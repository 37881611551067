import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { JobView } from '~/components/job/JobView';

export function loader({ params }: LoaderFunctionArgs) {
  const { jobId } = params;

  invariant(jobId, 'jobId is required');

  // TODO moved from JobView, need to re-enable this feature after data loading moved into loader
  // useEffect(() => {
  //   if (data?.job && setView && view === null) {
  //     setView(
  //       data.job.status === JobStatus.Complete
  //         ? 'completed'
  //         : data.job.scheduleStart || data.job.scheduleEnd
  //         ? 'scheduled'
  //         : 'unscheduled'
  //     );
  //   }
  // }, [data, view, setView]);

  return { jobId };
}

export default function JobViewRoute() {
  const { jobId } = useLoaderData() as ReturnType<typeof loader>;

  return <JobView jobId={jobId} />;
}
