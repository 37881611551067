import { createContext, useContext, useState } from 'react';

const GroupContext = createContext<
  [string | null, React.Dispatch<React.SetStateAction<string | null>>] | null
>(null);
GroupContext.displayName = 'GroupContext';

export function useGroupContext(component: string) {
  let context = useContext(GroupContext);
  if (context === null) {
    let err = new Error(
      `<${component} /> is missing a parent <GroupContext /> component.`
    );
    // if (Error.captureStackTrace) Error.captureStackTrace(err, useGroupContext);
    throw err;
  }
  return context;
}

export function Group({ children }: { children: React.ReactNode }) {
  const state = useState<string | null>(null);
  return (
    <GroupContext.Provider value={state}>{children}</GroupContext.Provider>
  );
}
