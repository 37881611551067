import { camelCase } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from '~/components/table/Table';
import { formatCurrency } from '~/helpers/formatCurrency';
import { formatDate } from '~/helpers/formatDate';

export const StockMovementTable = ({ data }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: stockMovementId } = useParams();

  const columns = useMemo(
    () => [
      { Header: 'Seq', accessor: 'id' },
      {
        Header: t('Occurred At'),
        accessor: 'occurredAt',
        Cell: ({ cell: { value } }: any) => formatDate(value),
        width: 140,
      },
      {
        Header: t('Location Type'),
        accessor: 'locationType',
        width: 100,
        Cell: ({ value }) => t(camelCase(value)),
      },
      { Header: t('Location'), accessor: 'locationName', width: 100 },
      { Header: t('Space'), accessor: 'spaceName', width: 100 },
      {
        Header: t('sku'),
        accessor: 'skuCode',
        Cell: ({ row }) => (
          <>
            <div className='font-semibold'>{row.original.skuName}</div>
            {row.original.skuCode && (
              <div className='text-copy-alt'>{row.original.skuCode}</div>
            )}
          </>
        ),
        width: 350,
      },
      {
        Header: t('Type'),
        accessor: 'type',
        Cell: ({ row: { original } }) =>
          original.adjustment_type || original.type,
        width: 110,
      },
      {
        Header: t('Quantity'),
        accessor: 'quantity',
        align: 'right',
        width: 80,
      },
      {
        Header: t('price'),
        accessor: 'price',
        align: 'right',
        width: 80,
        Cell: ({ cell: { value } }: any) => formatCurrency(value),
      },
      {
        Header: t('total'),
        accessor: 'total',
        align: 'right',
        width: 80,
        Cell: ({ cell: { value } }: any) => formatCurrency(value),
      },
      {
        Header: 'New SOH',
        accessor: 'newSoh',
        align: 'right',
        width: 80,
      },
      { Header: t('Notes'), accessor: 'notes', width: 100 },
      { Header: t('Entered By'), accessor: 'enteredBy', width: 100 },
      {
        Header: t('Entered At'),
        accessor: 'enteredAt',
        Cell: ({ cell: { value } }) => formatDate(value),
        width: 140,
      },
    ],
    [t]
  );

  const initialHiddenColumns = useMemo(() => ['id'], []);
  const initialSortBy = useMemo(
    () => [
      { id: 'occurredAt', desc: true },
      { id: 'id', desc: true },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialHiddenColumns={initialHiddenColumns}
      initialSortBy={initialSortBy}
      activeId={stockMovementId}
      onRowClick={(row: any) => navigate(`./${row.id}`)}
    />
  );
};
