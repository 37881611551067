import { ActionFunctionArgs, redirect, useRouteError } from 'react-router-dom';
import * as Yup from 'yup';
import { client } from '~/client';
import { AccountForm } from '~/components/account/AccountForm';
import { ErrorMessage } from '~/components/ui/Error';
import {
  CreateTenantDocument,
  CreateTenantMutation,
  CreateTenantMutationVariables,
} from '~/generated/graphql';
import { RootBoundary } from '~/RootBoundary';

const schema = Yup.object().shape({
  name: Yup.string().required('Required').min(3, 'Required'),
  logo: Yup.string().nullable(),
  industry: Yup.string().nullable(),
});

export async function action({ request }: ActionFunctionArgs) {
  const data = Object.fromEntries(await request.formData());
  const input = await schema.validate(data);

  const res = await client
    .mutation<CreateTenantMutation, CreateTenantMutationVariables>(
      CreateTenantDocument,
      { input }
    )
    .toPromise();

  const tenantId = res.data?.createTenant;
  if (!tenantId) {
    throw new Response('Something went wrong', { status: 400 });
  }

  await window.axios.patch(`/sessions`, { tenantId });
  return redirect('/');
}

export function Component() {
  return (
    <div className='flex min-h-[calc(100vh-6rem)] items-center justify-center'>
      <div className='w-full px-4 lg:max-w-md'>
        <AccountForm />
      </div>
    </div>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  if (error instanceof Yup.ValidationError) {
    return <ErrorMessage message='Something went wrong' />;
  }

  return <RootBoundary />;
}
