import { SVGProps } from 'react';

const SvgCalendarExclamationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='22'
    height='17'
    viewBox='0 0 22 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      id='Vector'
      d='M0 4.25C0 3.07793 0.95293 2.125 2.125 2.125H3.45312V0.796875C3.45312 0.355273 3.8084 0 4.25 0C4.6916 0 5.04688 0.355273 5.04688 0.796875V2.125H9.82812V0.796875C9.82812 0.355273 10.1834 0 10.625 0C11.0666 0 11.4219 0.355273 11.4219 0.796875V2.125H12.75C13.9221 2.125 14.875 3.07793 14.875 4.25V4.78125V6.375V6.59414C14.3039 6.75684 13.766 7.00254 13.2812 7.31797V6.37168L1.59375 6.375V14.875C1.59375 15.1672 1.83281 15.4062 2.125 15.4062H11.5713C11.9697 16.0172 12.4811 16.5518 13.0754 16.9768C12.9691 16.9934 12.8629 17 12.75 17H2.125C0.95293 17 0 16.0471 0 14.875V6.375V4.78125V4.25ZM3.1875 9.03125C3.1875 8.73906 3.42656 8.5 3.71875 8.5H6.90625C7.19844 8.5 7.4375 8.73906 7.4375 9.03125V12.2188C7.4375 12.5109 7.19844 12.75 6.90625 12.75H3.71875C3.42656 12.75 3.1875 12.5109 3.1875 12.2188V9.03125ZM11.6875 12.2188C11.6875 9.5791 13.8291 7.4375 16.4688 7.4375C19.1084 7.4375 21.25 9.5791 21.25 12.2188C21.25 14.8584 19.1084 17 16.4688 17C13.8291 17 11.6875 14.8584 11.6875 12.2188ZM15.6719 14.6094C15.6719 15.051 16.0271 15.4062 16.4688 15.4062C16.9104 15.4062 17.2656 15.051 17.2656 14.6094C17.2656 14.1678 16.9104 13.8125 16.4688 13.8125C16.0271 13.8125 15.6719 14.1678 15.6719 14.6094ZM15.9375 9.5625V12.2188C15.9375 12.5109 16.1766 12.75 16.4688 12.75C16.7609 12.75 17 12.5109 17 12.2188V9.5625C17 9.27031 16.7609 9.03125 16.4688 9.03125C16.1766 9.03125 15.9375 9.27031 15.9375 9.5625Z'
      fill={props.color ? props.color : 'black'}
    />
  </svg>
);

export default SvgCalendarExclamationIcon;
