import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ReadValue } from '~/components/ui/ReadValue';
import { formatDate } from '~/helpers/formatDate';
import { SideLayout } from '~/layouts/side/SideLayout';
import { StockMovement } from '~/types';

type Props = {
  movements: StockMovement[];
};

export function StockMovementRoute() {
  const { movements } = useOutletContext<{ movements: StockMovement[] }>();

  return <StockMovementView movements={movements} />;
}

const StockMovementView = ({ movements }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const movement = movements.find((m) => m.id.toString() === id);

  if (!movement) {
    return null;
  }

  return (
    <SideLayout>
      <SideLayout.Head onClose={() => navigate('/stock-movements')}>
        {t('stockMovementDetails')}
      </SideLayout.Head>
      <SideLayout.Body>
        <ReadValue label='skuName' value={movement.skuName} />
        <ReadValue label='skuCode' value={movement.skuCode} />
        <ReadValue label='space' value={movement.spaceName} />
        <ReadValue label='location' value={movement.locationName} />
        <ReadValue
          label='locationType'
          value={t('type.' + movement.locationType)}
        />
        <ReadValue label='movementType' value={movement.type} />
        <ReadValue label='adjustmentType' value={movement.adjustment_type} />
        <ReadValue label='movement' value={movement.quantity} />
        <ReadValue label='newOnHand' value={movement.newSoh} />
        <ReadValue label='occurredAt' value={formatDate(movement.occurredAt)} />
        <ReadValue label='enteredAt' value={formatDate(movement.enteredAt)} />
        <ReadValue label='enteredBy' value={movement.enteredBy} />
        <ReadValue label='notes' value={movement.notes} />
      </SideLayout.Body>
    </SideLayout>
  );
};
