import { useField } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import { PopupDialogNew } from '~/components/ui/PopupDialogNew';
import { graphql } from '~/gql';
import { ContactInput, ContactType, Status } from '~/gql/graphql';
import { useScrollToFocus } from '~/hooks/useScrollToFocus';
import { useContacts } from '~/routes/resources/contacts';
import {
  FormValues,
  ShortContactForm,
} from '../contact/ContactForm/ShortContactForm';
import { ContactSelect, ContactSelectProps } from './ContactSelect';

type Props = Omit<ContactSelectProps, 'onCreate'>;
type Ref = HTMLDivElement;

type FieldProps = Omit<Props, 'value' | 'onChange'> & {
  name: string;
};

const CreateContactDocument = graphql(`
  mutation CreateContact($input: ContactInput!) {
    createContact(input: $input) {
      id
      status
      type
      name
      email
      phone
      relationship
      image
      isCurrentUser
      isTeamMember
    }
  }
`);

function formDataToInput(values: FormValues): ContactInput {
  return {
    status: Status.Active,
    type: ContactType.Person,
    name: values.name,
    email: values.email,
    // TODO more details
  };
}

export const FindAddContact = (props: Props) => {
  const contacts = props.contacts ?? useContacts();
  const [{ fetching: isLoading }, createContact] = useMutation(
    CreateContactDocument
  );
  const [showCreateContactPopup, setShowCreateContactPopup] = useState<
    string | false
  >(false);

  const ref = useScrollToFocus();

  async function handleSubmit(data: FormValues) {
    // fixme: this should be a mutation
    data.email;
    const result = await createContact({ input: formDataToInput(data) });

    if (result.error) {
      toast.error('Error adding contact');
      return;
    }

    if (result.data?.createContact) {
      props.onChange([result.data.createContact]);
    }
    setShowCreateContactPopup(false);
  }

  return (
    <div ref={ref}>
      <CreateContactPopup
        show={showCreateContactPopup}
        onClose={() => {
          if (isLoading) return;
          setShowCreateContactPopup(false);
        }}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
      <ContactSelect
        {...props}
        contacts={contacts}
        onCreate={setShowCreateContactPopup}
      />
    </div>
  );
};

type CreateContactPopupProps = {
  show: string | boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
  isLoading?: boolean;
};

function CreateContactPopup({
  show,
  onClose,
  onSubmit,
  isLoading = false,
}: CreateContactPopupProps) {
  const { t } = useTranslation();
  // TODO determine if value looks like an email address or not and fill the correct field
  const defaultName = typeof show === 'string' ? show : undefined;

  return (
    <PopupDialogNew isOpen={show !== false} onClose={onClose}>
      <PopupDialogNew.Title loading={isLoading}>
        {t('createContact')}
      </PopupDialogNew.Title>
      <ShortContactForm
        // type={type}
        defaultName={defaultName}
        onSubmit={onSubmit}
        emailIsRequired
        isLoading={isLoading}
      />
    </PopupDialogNew>
  );
}

export function FindAddContactField({ name, ...props }: FieldProps) {
  const contacts = useContacts();
  const [field, meta, helpers] = useField(name);

  return (
    <>
      <FindAddContact
        {...props}
        value={
          field.value ? contacts.filter(({ id }) => id === field.value) : []
        }
        onChange={(val) => helpers.setValue(val.length ? val[0].id : null)}
      />
      {meta.error && <p className='ErrorMessage'>{meta.error}</p>}
    </>
  );
}
