import {
  KeyboardEventHandler,
  MouseEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '~/CheckCircleIcon';
import WarningIcon from '~/WarningIcon';
import { CardStatus } from '~/components/form/StockSelectionField';
import { Input } from '~/components/form/TextField';
import { AddItemsTaskQuery } from '~/generated/graphql';

/**
 * Behaviour/translation contexts for SMC cards and SMC card groups
 */
export enum Context {
  VIEW_STOCK = 'viewStock',
  PICK = 'pick',
  ADD = 'add',
  REMOVE = 'remove',
  /** Purchase Task */
  PURCHASE = 'purchase',
  STOCKTAKE = 'stocktake',
  ADJUSTMENT = 'adjustment',
  PURCHASE_ORDER = 'purchaseOrder',
  TRANSFER_SOURCE = 'transferSource',
  TRANSFER_DEST = 'transferDestination',
  MANUAL_SOURCE = 'manualSource',
  MANUAL_DEST = 'manualDestination',
}

// Allowed item properties containing expected/toMove values
type Keys = keyof Pick<
  HeroProps['item'],
  'soh' | 'pick' | 'received' /* | 'missing' */
>;

type HeroConfig = {
  autoFill: boolean;
  showStatusIcon: boolean;
  /** Key for toMove value -  */
  toMove: Keys;
};

function getConfig(context: Context): HeroConfig {
  switch (context) {
    case Context.VIEW_STOCK:
    case Context.REMOVE:
    case Context.TRANSFER_SOURCE:
    case Context.TRANSFER_DEST:
    case Context.MANUAL_SOURCE:
    case Context.MANUAL_DEST:
      return {
        autoFill: false,
        showStatusIcon: false,
        toMove: 'soh',
      };
    case Context.ADJUSTMENT:
      return {
        autoFill: false,
        showStatusIcon: true,
        toMove: 'soh',
      };
    case Context.PURCHASE:
      return {
        autoFill: false,
        showStatusIcon: true,
        toMove: 'soh',
      };
    case Context.PURCHASE_ORDER:
      return {
        autoFill: true,
        showStatusIcon: true,
        toMove: 'soh', //'missing'
      };
    case Context.PICK:
      return {
        autoFill: true,
        showStatusIcon: true,
        toMove: 'pick',
      };
    case Context.ADD:
      return {
        autoFill: true,
        showStatusIcon: true,
        toMove: 'received',
      };
    case Context.STOCKTAKE:
      return {
        autoFill: true,
        showStatusIcon: true,
        toMove: 'soh',
      };
  }
}

type HeroProps = {
  /** @todo Rename. mode? */
  context: Context;
  item: AddItemsTaskQuery['addItemsTask']['cards'][0];
  open: boolean;
  status?: CardStatus;
  expectedValue?: string;
  value: string;
  onChange: (value: string) => void;
};

export const Hero = ({
  context,
  item,
  open,
  status,
  value,
  onChange,
}: HeroProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [touched, setTouched] = useState(false);
  const { t } = useTranslation('smc');
  const { soh } = item;
  const isProcessed = value !== '';
  const newOnHand =
    value === ''
      ? soh
      : Context.STOCKTAKE === context
      ? parseInt(value) - soh // For stocktakes, actual new on hand is entered, this holds the adjustment amount
      : [
          Context.ADD,
          Context.ADJUSTMENT,
          Context.MANUAL_DEST,
          Context.PURCHASE,
        ].includes(context)
      ? soh + parseInt(value)
      : soh - parseInt(value);
  const config = useMemo(() => getConfig(context), [context]);
  const toMove = config.toMove ? item[config.toMove] : undefined;
  const number = isProcessed
    ? [
        Context.PICK,
        Context.ADD,
        Context.STOCKTAKE,
        Context.MANUAL_DEST,
      ].includes(context)
      ? value
      : newOnHand
    : [Context.PICK, Context.ADD].includes(context)
    ? toMove
    : soh;

  useEffect(() => {
    if (config.autoFill && !touched && open) {
      setTouched(true);
      if (toMove != null) {
        onChange('' + toMove);
        // next tick...
        setTimeout(() => inputRef.current?.select(), 101);
      }
    }
    // eslint-disable-next-line
  }, [open, touched, toMove]);

  if (open) {
    const InputGroup =
      context === Context.PURCHASE
        ? PurchaseInputs
        : context === Context.STOCKTAKE
        ? StocktakeInputs
        : DefaultInputs;
    return (
      <div className='w-24 text-right'>
        <InputGroup
          ref={inputRef}
          context={context}
          value={value}
          soh={soh}
          newOnHand={newOnHand}
          onChange={onChange}
        />
      </div>
    );
  }

  const displayStatus = !config.showStatusIcon ? null : status ===
    CardStatus.SUCCESS ? (
    <span className='ml-1 align-bottom text-success'>
      <CheckCircleIcon className='inline-block h-5 fill-current' />
    </span>
  ) : (
    <span className='ml-1 align-bottom'>
      <WarningIcon className='inline-block' />
    </span>
  );

  return (
    <div className='flex flex-col justify-between'>
      <div>
        <div className='text-right text-2xl'>
          {/* <input
            tabIndex={-1}
            className='w-full text-3xl text-right bg-transparent outline-none'
            type='number'
            defaultValue={number}
            readOnly
          /> */}
          {number}
        </div>
        <div className='whitespace-nowrap text-right text-xs text-tertiary'>
          {isProcessed
            ? t('processed', { context })
            : t('directive', { context })}
        </div>
      </div>
      {value !== '' && (
        <div className='whitespace-nowrap text-right text-xs'>
          {t('tag', {
            context,
            // ! This is a hack to get the correct translation for stocktakes
            count: value === soh.toString() ? 1 : 0,
            qty: value,
            newOnHand,
            toMove,
          })}
          {displayStatus}
        </div>
      )}
    </div>
  );
};

type HeroInputProps = {
  context: Context;
  soh: number;
  value: string;
  newOnHand: number;
  onChange: (value: string) => void;
};

function DefaultInputs({
  context,
  soh,
  value,
  newOnHand,
  onChange,
}: HeroInputProps) {
  const { t } = useTranslation('smc');
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.currentTarget.value);
  };

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        event.currentTarget.blur();
      }
    },
    []
  );

  /**
   * Prevent clicking inside an input element from bubbling up to the
   * focusable card causing it to close unexpectedly
   */
  const handleMouseDown: MouseEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.stopPropagation();
    },
    []
  );
  return (
    <>
      <Input
        tabIndex={-1}
        label={t('startOnHand')}
        type='number'
        align='right'
        value={soh}
        disabled
      />
      <Input
        tabIndex={0}
        label={t('input', { context })}
        type='number'
        min='0'
        align='right'
        value={value}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onMouseDown={handleMouseDown}
        autoFocus
        selectOnFocus
      />
      <Input
        tabIndex={-1}
        label={t('newOnHand')}
        type='number'
        align='right'
        value={newOnHand}
        disabled
      />
    </>
  );
}

const PurchaseInputs = forwardRef<HTMLInputElement, HeroInputProps>(
  (props, ref) => {
    // TODO future implementation needs to include two editable inputs
    // For now just use the default
    return <DefaultInputs {...props} />;
  }
);

const StocktakeInputs = forwardRef<HTMLInputElement, HeroInputProps>(
  (props, ref) => {
    const { context, soh, value, newOnHand, onChange } = props;
    const { t } = useTranslation('smc');
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
      event
    ) => {
      onChange(event.currentTarget.value);
    };

    const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        if (event.key === 'Escape' || event.key === 'Enter') {
          event.currentTarget.blur();
        }
      },
      []
    );

    /**
     * Prevent clicking inside an input element from bubbling up to the
     * focusable card causing it to close unexpectedly
     */
    const handleMouseDown: MouseEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        event.stopPropagation();
      },
      []
    );
    return (
      <>
        <Input
          tabIndex={-1}
          label={t('startOnHand')}
          type='number'
          align='right'
          value={soh}
          disabled
        />
        <Input
          tabIndex={-1}
          label={t('adjustment')}
          type='number'
          align='right'
          value={newOnHand}
          disabled
        />
        <Input
          ref={ref}
          tabIndex={0}
          label={t('input', { context })}
          type='number'
          min='0'
          align='right'
          value={value}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onMouseDown={handleMouseDown}
          autoFocus
          selectOnFocus
        />
      </>
    );
  }
);
