import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { Confirm } from './ui/Confirm';

type Props = {
  when: boolean;
  title?: string;
  body?: string;
  cancel?: string;
  confirm?: string;
};

/**
 * Conditionally renders confirmation dialog and blocks navigation
 */
export const Prompt = ({ when, ...rest }: Props) => {
  const blocker = useBlocker(when);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!when) return;

      // Cancel the event.
      event.preventDefault();
      // Chrome (and legacy IE) requires returnValue to be set.
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);

  return (
    <Confirm
      show={blocker.state === 'blocked'}
      onCancel={() => blocker.reset?.()}
      onConfirm={() => blocker.proceed?.()}
      {...rest}
    />
  );
};

/**
 * Formik context aware Prompt wrapper
 */
export const PromptEffect = (props: Omit<Props, 'when'>) => {
  const { dirty, isSubmitting } = useFormikContext();
  return <Prompt when={dirty && !isSubmitting} {...props} />;
};
