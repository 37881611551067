import classNames from 'classnames';
import { forwardRef, HTMLProps } from 'react';

type Ref = HTMLInputElement;

type Props = {
  label: string;
  error?: string | false;
} & HTMLProps<Ref>;

export const OtpInput = forwardRef<Ref, Props>((props, ref) => {
  const { label, error } = props;

  return (
    <div className='mb-6 flex flex-col gap-1'>
      <label className='mb-1' htmlFor={props.id ?? props.name}>
        {label}
      </label>
      <input
        {...props}
        id={props.id ?? props.name}
        className={classNames([
          error
            ? 'border-red-600 focus-visible:border-red-600'
            : 'border-gray88 focus-visible:border-brand ',
          'mt-1 w-full rounded border px-3 py-2.5 focus-visible:outline-none',
          'text-center font-mono text-2xl font-semibold',
        ])}
        ref={ref}
        type='text'
        maxLength={6}
        autoComplete='off'
        autoFocus
      />
      {error && (
        <div className='ErrorMessage'>
          {typeof error === 'string' ? error : JSON.stringify(error)}
        </div>
      )}
    </div>
  );
});
