import { ArrowLeftIcon, PencilIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';
import { Attributes } from '~/components/ui/Attributes';
import { Confirm } from '~/components/ui/Confirm';
import { SearchField } from '~/components/ui/SearchField';
import {
  Attribute,
  SiteInput,
  SiteQuery,
  useSiteQuery,
} from '~/generated/graphql';
import { useSearch } from '~/hooks/useSearch';
import { AttributeEditForm } from './AttributeEditForm';
type SiteAttributes = SiteQuery['site']['attributes'];
type Props = {
  attributes?: SiteQuery['site']['attributes'] | null;
  integrationAttributes?: Attribute[] | null;
  integrationName?: string;
  isSearchSticked?: boolean;
  onUpdate?: (input: SiteInput) => void;
  siteId: string;
  hideEdit?: boolean;
};

const SEARCH_OPTIONS = {
  keys: ['name', 'value', 'category'],
};

export function AttributesList({
  attributes,
  integrationAttributes,
  integrationName,
  isSearchSticked = false,
  onUpdate,
  siteId,
  hideEdit,
}: Props) {
  const [view, setView] = useState(0);

  return (
    <>
      {integrationAttributes && integrationAttributes.length > 0 && (
        <div className='flex px-4'>
          <div
            className={classNames(
              'flex h-12 flex-1 cursor-pointer flex-col items-center justify-center text-center text-sm font-normal not-italic leading-[normal] text-[#007070]',
              view === 0 && 'border-b-[3px] border-brand font-semibold'
            )}
            onClick={() => setView(0)}
          >
            PropFit
          </div>
          <div
            className={classNames(
              'flex h-12 flex-1 cursor-pointer flex-col items-center justify-center text-center text-sm font-normal not-italic leading-[normal] text-[#007070]',
              view === 1 && 'border-b-[3px] border-brand font-semibold'
            )}
            onClick={() => setView(1)}
          >
            <span className='capitalize'>
              {integrationName ?? 'Integration'}
            </span>
          </div>
        </div>
      )}
      {view === 0 && (
        <SearchAttributesList
          attributes={attributes ?? []}
          isSearchSticked={isSearchSticked}
          onUpdate={onUpdate}
          siteId={siteId}
          hideEdit={hideEdit}
        />
      )}
      {view === 1 && (
        <SearchAttributesList
          attributes={integrationAttributes ?? []}
          isSearchSticked={isSearchSticked}
          onUpdate={onUpdate}
          siteId={siteId}
          hideEdit={hideEdit}
        />
      )}
    </>
  );
}
function SearchAttributesList({
  attributes,
  onUpdate,
  isSearchSticked = false,
  siteId,
  hideEdit,
}: {
  attributes: NonNullable<SiteAttributes>;
  onUpdate?: (input: SiteInput) => void;
  isSearchSticked?: boolean;
  siteId?: string;
  hideEdit?: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  invariant(siteId);
  const [result] = useSiteQuery({
    variables: { id: siteId },
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const { results, search } = useSearch(attributes, SEARCH_OPTIONS);

  useEffect(() => {
    return () => {
      if (inputRef.current) {
        inputRef.current.value = '';
        search('');
        setIsEditing(false);
      }
    };
  }, [siteId]);

  const showEditButton = !isEditing && !hideEdit;

  return (
    <>
      <div className='flex flex-col'>
        <div
          id='search-attributes'
          className={classNames(
            'sticky top-0 z-10 flex items-center bg-[#f8f9fa] p-3',
            isSearchSticked && 'shadow'
          )}
        >
          {isEditing && (
            <ArrowLeftIcon
              className='mr-2 h-5 cursor-pointer text-secondary'
              onClick={() => {
                if (isFormDirty) {
                  setIsConfirming(true);
                } else {
                  setIsEditing(false);
                }
              }}
            />
          )}

          <SearchField
            ref={inputRef}
            placeholder='Search Attributes...'
            onChange={(event) => search(event.target.value)}
          />

          {showEditButton && (
            <div
              className='ml-1 flex cursor-pointer items-center justify-center rounded-full hover:bg-grey-10'
              onClick={() => setIsEditing(true)}
            >
              <PencilIcon className='m-2 h-5 text-secondary' />
            </div>
          )}
        </div>
        {!attributes.length && !isEditing && (
          <p className='p-4 text-center'>
            There are no attributes for this property
          </p>
        )}
      </div>

      {isEditing ? (
        <AttributeEditForm
          site={result.data?.site}
          search={inputRef.current?.value}
          onSubmit={(input) => {
            if (onUpdate) {
              onUpdate(input);
              setIsEditing(false);
              setIsFormDirty(false);
            }
          }}
          onFormTouched={setIsFormDirty}
        />
      ) : (
        <div className='m-4 mt-0'>
          <Attributes data={{ attributes: results }} />
        </div>
      )}
      {isConfirming && (
        <Confirm
          onCancel={() => setIsConfirming(false)}
          onConfirm={() => {
            setIsEditing(false);
            setIsConfirming(false);
            setIsFormDirty(false);
          }}
          title='Discard unsaved changes?'
          body="If you proceed you will lose all the changes you've made."
          cancel='Go back'
          confirm='Lose changes'
        />
      )}
    </>
  );
}
