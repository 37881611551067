import useSWR from 'swr';
import { StockMovement } from '~/types';
import { createUseResource } from '~/api';

const key = '/api/stock-movements?';

export const useStockMovements = (query?: string) => {
  const url = query ? key + query : key;
  return useSWR<StockMovement[]>(url);
};

export const useStockMovement = createUseResource(key);
