import CardIcon from './Card';
import TableIcon from './Table';

const VIEW_OPTIONS = ['CARD', 'TABLE'] as const;

export type View = typeof VIEW_OPTIONS[number];

type ViewSwitcherProps = {
  selected: View;
  onSelect: (view: View) => void;
};

export const ViewSwitcher = ({ selected, onSelect }: ViewSwitcherProps) => {
  return (
    <div className='hidden lg:block'>
      {VIEW_OPTIONS.map((value) => (
        <button
          key={value}
          className={
            selected === value
              ? 'text-brand mr-1 h-8 w-8 fill-current stroke-current'
              : 'text-copy-alt mr-1 h-8 w-8 fill-current stroke-current'
          }
          onClick={() => onSelect(value)}
        >
          {value === 'CARD' ? <CardIcon /> : <TableIcon />}
        </button>
      ))}
    </div>
  );
};
