import { useEffect } from 'react';
import { useMutation } from 'urql';
import { Confirm } from '~/components/ui/Confirm';
import { JobStatusBadge } from '~/components/ui/JobStatusBadge';
import { graphql } from '~/gql';
import { JobStatus } from '~/gql/graphql';

type Props = {
  selected: string[];
  /** Confusing name, meaning the bulk cancel was cancelled (confirmation dialog was dismissed) */
  onCancel: () => void;
  onSuccess: () => void;
};

const CancelJobs_Mutation = graphql(`
  mutation CancelJobs($ids: [ID!]!) {
    cancelJobs(ids: $ids) {
      id
      status
      permissions
    }
  }
`);

export function BulkCancel({ selected, onCancel, onSuccess }: Props) {
  const [result, cancelJobs] = useMutation(CancelJobs_Mutation);

  useEffect(() => {
    if (result.error) return;
    if (result.data) onSuccess();
  }, [result, onSuccess]);

  return (
    <Confirm
      title='Cancel Jobs'
      cancel='Go back'
      confirm='Cancel Jobs'
      onCancel={onCancel}
      onConfirm={() => {
        selected && cancelJobs({ ids: selected });
      }}
      isLoading={result.fetching}
      danger
    >
      <div className='mb-3 flex items-center gap-1.5 whitespace-nowrap'>
        Total <strong>{selected.length}</strong> jobs will be{' '}
        <JobStatusBadge value={JobStatus.Cancelled} />
      </div>
      {/* {selected?.map((id) => {
    const job = filteredJobs?.find((job) => job.id === id);
    return <div key={id}>{job?.name!}</div>;
  })} */}
      <p className='text-left'>Do you want to proceed?</p>
    </Confirm>
  );
}
