import { useField } from 'formik';
import { difference } from 'lodash';
import { useMemo } from 'react';
import { Select, SelectProps } from './Select';

type Props = Omit<SelectProps, 'value' | 'onChange'> & {
  name: string | string[];
};

export const SelectField = ({ name, ...props }: Props) => {
  // @ts-expect-error bad type def, Formik does allow field names in this notation: https://formik.org/docs/guides/arrays#avoid-nesting
  const [field, meta, helpers] = useField<any | any[]>({ name });
  const { setValue } = helpers;

  const options = useMemo(() => {
    if (props.creatable) {
      const values = props.options.map((option) => option.value);
      const created = difference(field.value, values).map((value) => ({
        value,
        label: value,
      }));
      return props.options.concat(created);
    }
    return props.options;
  }, [field.value, props.options, props.creatable]);

  const value = useMemo(
    () =>
      Array.isArray(field.value)
        ? field.value
        : field.value === ''
        ? []
        : [field.value],
    [field.value]
  );

  const inputProps = {
    error: meta.touched && meta.error,
  };

  const handleChange = (value: string[]) => {
    setValue(Array.isArray(field.value) ? value : value[0]);
  };

  return (
    <>
      <Select
        {...props}
        inputProps={inputProps}
        options={options}
        value={value}
        onChange={handleChange}
      />
    </>
  );
};
