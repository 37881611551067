import { format, isBefore, isSameDay, isSameMinute } from 'date-fns';
import { formatDate } from '~/helpers/formatDate';
import {
  OccupationInformation,
  OccupationStatus,
} from './OccupationInformation';

export const parseDateTime = (date?: string | null) => {
  if (!date) return null;

  const formatted = format(new Date(date), 'd MMM yy p');
  return formatted.replace(':00 ', '');
};

export const parseDate = (date: string | null) => {
  if (!date) return null;

  const formatted = formatDate(date, 'd MMM yy');
  return formatted.replace(':00 ', '');
};

export const getDate = (dateTime: string) => {
  const pattern = /\s\d{1,2}:\d{2}\s(?:AM|PM)$/;
  return dateTime.replace(pattern, '');
};

export const checkOccupationStatus = (
  occupationData: OccupationInformation
) => {
  const { occupied, schedule, prevCheckOut, nextCheckIn } = occupationData;
  const { checkOut: prevBookingCO } = prevCheckOut;
  const { checkIn: nextBookingCI } = nextCheckIn;

  if (!schedule) return null;

  if (occupied) {
    const { checkOut } = occupied;

    if (
      checkOut &&
      !isSameMinute(new Date(schedule), new Date(checkOut)) &&
      isBefore(new Date(schedule), new Date(checkOut))
    ) {
      return OccupationStatus.Occupied;
    }
  }

  if (
    nextBookingCI &&
    prevBookingCO &&
    isSameDay(new Date(nextBookingCI), new Date(prevBookingCO)) &&
    isSameDay(new Date(nextBookingCI), new Date(schedule))
  ) {
    return OccupationStatus.BackToBack;
  }

  if (nextBookingCI && isSameDay(new Date(nextBookingCI), new Date(schedule))) {
    return OccupationStatus.SameDayCheckIn;
  }

  if (prevBookingCO && isSameDay(new Date(prevBookingCO), new Date(schedule))) {
    return OccupationStatus.SameDayCheckOut;
  }

  return null;
};

export function convertToISO(dateString: string | null): string {
  if (dateString === null) {
    return '';
  }

  const date = new Date(dateString);

  const adjustedDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  );

  return adjustedDate.toISOString().replace(/\.\d{3}Z$/, '');
}
