import { FormEvent } from 'react';
import { Input } from '~/components/form/TextField';

type SearchProps = {
  query: string;
  search: any;
};

export const Search = ({ query, search }: SearchProps) => {
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    search(event.currentTarget.value);
  };

  return (
    // <div>
    <Input label='Filter' value={query} onChange={handleChange} />
    // </div>
  );
};
