import { useMatch } from 'react-router-dom';
import { BasicListItem } from '~/components/ui/BasicListItem';
import { getFragmentData } from '~/gql';
import { ListAssetsQuery } from '~/gql/graphql';
import { DeploymentFieldsFragment } from '../AssetSelect/AssetSelect';
import { Avatar } from '../ui/Avatar';

type Asset = ListAssetsQuery['assets'][number];

type AssetListItemProps = {
  asset: Asset;
};

const AssetListItem = ({ asset }: AssetListItemProps) => {
  const match = useMatch('/assets/:id');
  const active = Boolean(match && match.params.id === asset.id.toString());
  const deployment = getFragmentData(
    DeploymentFieldsFragment,
    asset.deployment
  );

  return (
    <BasicListItem
      linkTo={'/assets/' + asset.id}
      titleText={asset.name}
      secondaryText={'' + asset.type}
      tertiaryText={deployment?.displayLocation}
      active={active}
      // TODO display asset model image if no asset image here
      image={asset.image ?? deployment?.image}
      placeholderIcon={
        <Avatar
          image={asset.image}
          name={asset.name}
          size='full'
          square
          className='rounded-md'
        />
      }
    />
  );
};

export const AssetList = ({ data }: { data: Asset[] }) => {
  return (
    <>
      {data.map((asset) => (
        <AssetListItem key={asset.id} asset={asset} />
      ))}
    </>
  );
};
