import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddItemsTaskQuery } from '~/generated/graphql';
import { JobQuery } from '~/gql/graphql';

type Props = {
  job: NonNullable<JobQuery['job']>;
  task: AddItemsTaskQuery['addItemsTask'];
};

export const ItemTaskDetails = ({ job, task }: Props) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const { stockTransfer } = task;
  const jobNumber = 'J' + job.id.toString().padStart(4, '0');
  const stockTransferNumber = stockTransfer
    ? 'ST' + stockTransfer.id.toString().padStart(4, '0')
    : null;

  const toggle: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setShow((state) => !state);
  };

  return (
    <dl className='px-4 pt-4'>
      <dt className='label mb-1.5' style={{ display: 'flex' }}>
        <div>Job</div>
        <div className='ml-auto'>
          <a
            href='#more'
            onClick={toggle}
            className='text-brand underline hover:underline'
          >
            {show ? 'Less' : 'More'}
          </a>
        </div>
      </dt>
      <dd className='mb-6'>
        {jobNumber}, {job.name}
      </dd>
      {show && stockTransfer && (
        <>
          <dt className='label mb-1.5'>Stock Transfer</dt>
          <dd className='mb-6'>{stockTransferNumber}</dd>
          <dt className='label mb-1.5'>
            Source ({t(stockTransfer.source.__typename)})
          </dt>
          <dd className='mb-6'>{stockTransfer.source.name}</dd>
          <dt className='label mb-1.5'>
            Destination ({t(stockTransfer.destination.__typename)})
          </dt>
          <dd className='mb-6'>{stockTransfer.destination.name}</dd>
          <dt className='label mb-1.5'>Select Stock</dt>
          <dd className='mb-6'>Automatically</dd>
          <dt className='label mb-1.5'>Process Queued Changes</dt>
          <dd className='mb-6'>
            {stockTransfer.processQueuedChanges ? 'Yes' : 'No'}
          </dd>
          <dt className='label mb-1.5'>Fill To</dt>
          <dd className='mb-6'>{t(stockTransfer.rule)}</dd>
        </>
      )}
    </dl>
  );
};
