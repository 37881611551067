import { useEffect, useState } from 'react';
import { useField } from 'formik';
import {
  Input,
  TextField,
  Props as TextFieldProps,
} from '~/components/form/TextField';
import CheckCircleIcon from '~/CheckCircleIcon';
import WarningIcon from '~/WarningIcon';

const arithmetic = {
  add: (a: number, b: number) => a + b,
  sub: (a: number, b: number) => arithmetic.add(a, -1 * b),
};

type Props = {
  name: string;
  mode?: 'adjustment' | 'stocktake';
  operator: 'add' | 'sub';
  soh: number;
  goal?: number;
} & TextFieldProps;

/**
 * @param {object} props
 * @param {string} props.operator Arithmetic operator to apply to soh and value
 * to get newOnHand e.g. 'sub' (-) -> soh - value = newOnHand
 * @param {number} props.soh Start on Hand
 * @param {number} props.goal Input amount needed
 */
export const SMCInputGroupField = ({
  mode = 'adjustment',
  operator,
  soh,
  goal,
  onBlur,
  ...props
}: Props) => {
  const [showLabel, setShowLabel] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const formikField = useField(props.name);
  const [field] = formikField;

  useEffect(() => {
    const [field, meta, helpers] = formikField;
    const { setValue, setTouched } = helpers;
    const hasGoal = Boolean(goal) || goal === 0;
    const untouched = field.value === '' && !meta.touched;

    if (mode === 'adjustment' && hasGoal && untouched) {
      setTouched(true);
      setValue(goal);
    } else if (mode === 'stocktake' && untouched) {
      setTouched(true);
      setValue(soh);
    }
  }, [formikField, goal, soh, mode]);

  const newOnHand = field.value ? arithmetic[operator](soh, field.value) : soh;

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
  };

  const handleBlurText = () => {
    setShowLabel(true);
    if (field.value === soh) {
      setConfirmed(true);
    } else {
      setConfirmed(false);
    }
  };

  if (mode === 'stocktake') {
    return (
      <>
        <Input
          value={soh}
          label='Start on Hand'
          type='number'
          min='0'
          style={{ height: '3.125rem', marginBottom: 0 }}
          disabled
        />
        <Input
          value={field.value === '' ? 0 : field.value - soh}
          label='Adjustment'
          type='number'
          min='0'
          style={{ height: '3.125rem', marginBottom: 0 }}
          disabled
        />
        <TextField
          {...props}
          label='End on Hand'
          type='number'
          min='0'
          inputMode='decimal'
          style={{ height: '3.125rem', marginBottom: 0 }}
          onMouseDown={(event) => event.stopPropagation()}
          onClick={(event) => event.stopPropagation()}
          onFocus={handleFocus}
          onBlur={handleBlurText}
        />
        {showLabel ? (
          <>
            {confirmed ? (
              <>
                Confirmed
                <CheckCircleIcon
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                    marginLeft: 4,
                    fill: 'var(--color-green)',
                    verticalAlign: 'text-bottom',
                  }}
                />
              </>
            ) : (
              <>
                Adjusted
                <WarningIcon
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                    marginLeft: 4,
                    fill: 'var(--color-warning)',
                    verticalAlign: 'text-bottom',
                  }}
                />
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <>
      <Input
        value={soh}
        label='Start on Hand'
        type='number'
        min='0'
        style={{ height: '3.125rem', marginBottom: 0 }}
        disabled
      />
      <TextField
        {...props}
        type='number'
        min='0'
        onMouseDown={(event) => event.stopPropagation()}
        onClick={(event) => event.stopPropagation()}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputMode='decimal'
        style={{ height: '3.125rem', marginBottom: 0 }}
      />
      {goal && `of ${goal}`}
      <Input
        value={newOnHand}
        label='New on Hand'
        type='number'
        min='0'
        style={{ height: '3.125rem', marginBottom: 0 }}
        disabled
      />
    </>
  );
};

// TODO Controlled version
export const SMCInputGroup = () => {
  // const
};
