import { useState, useEffect } from 'react'
import { ModalForm } from '~/components/form/ModalForm'
import { SMCCardGroup } from '~/components/smc/SMCCardGroup'
import { mapLocationToUrl } from '~/helpers'
import styles from './TransferStock.module.css'
import { forOwn } from 'lodash'
import { useTranslation } from 'react-i18next'

export const RemoveForm = ({
  prev,
  onSuccess,
  onPrevState,
  step,
  prevData,
}) => {
  const [stock, setStock] = useState([])
  const initialValues = stock.reduce((obj, item) => {
    return { ...obj, [item.sku.aggregate_id]: '' }
  }, {})

  // TODO this is very similar to fetchSpaceSkus in SMC > Step2Form
  useEffect(() => {
    async function fetchStock() {
      const axios = window.axios
      const url = mapLocationToUrl(prev.source)
      const { data } = await axios.get(url)
      const arr = []

      data.spaces.forEach((space) => {
        space.skus.forEach((sku) => {
          arr.push({ id: sku.aggregate_id, space, sku, soh: sku.soh })
        })
      })

      setStock(arr)
    }

    fetchStock()
  }, [prev.source])

  const handleSubmit = (values) => {
    const remove = {}
    Object.keys(values.adjustments).forEach((i) => {
      if (values.adjustments[i] !== '') {
        remove[i] = values.adjustments[i]
      }
    })
    onSuccess({ remove })
  }

  if (prevData[step]) {
    forOwn(initialValues, (value, key) => {
      forOwn(prevData[step]['remove'], (v, k) => {
        if (k === key) initialValues[key] = v
      })
    })
  }

  const initValues = { adjustments: initialValues }
  const { t } = useTranslation()

  return (
    <ModalForm
      title='Transfer Stock'
      formikProps={{
        enableReinitialize: true,
        initialValues: initValues,
        onSubmit: handleSubmit,
      }}
      buttonText='Next'
      onPrevState={() => onPrevState(step - 1)}
    >
      <div className={styles.subtitle}>
        {t('Stock to remove from')} {prev.sourceType}
      </div>

      <SMCCardGroup
        mode='adjustment'
        operator='sub'
        spaceSkus={stock}
        adjustmentLabel='Transfer Out'
        showHeroLabel={false}
      />
    </ModalForm>
  )
}
