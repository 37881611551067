import { useField } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationType, useLocationsQuery } from '~/generated/graphql';
import { RadioGroup } from './checkradio';
import { Select } from './downshift/Select';

type Props = {
  name: string;
  title: string;
  error?: string;
  value: string;
  onChange: (value: string) => void;
};

type FieldProps = {
  name?: string;
  title: string;
  onUpdate?: (id: string | undefined) => void;
};

function StockLocation({ value, onChange, error, ...props }: Props) {
  const { t } = useTranslation();
  const [type, id] = value.split(':', 2) as [LocationType, string];
  const [result] = useLocationsQuery({
    variables: { type },
    pause: !type,
  });
  const { fetching, data } = result;

  if (fetching) return null;

  return (
    <>
      <RadioGroup
        {...props}
        options={[
          { value: 'Site', label: t('type.Site') },
          {
            value: 'Contact',
            label: t('type.Contact_type', { type: 'Supplier' }),
          },
        ]}
        value={type}
        onChange={(val) => onChange(val)}
      />
      {type && (
        <Select
          label={t('type.' + type)}
          options={
            data?.locations.map((l) => ({ value: l.id, label: l.name })) ?? []
          }
          disabled={fetching}
          value={id ? [id] : []}
          onChange={(selected) =>
            selected.length && onChange(`${type}:${selected[0]}`)
          }
          required
        />
      )}
      {error && <p className='ErrorMessage'>{error}</p>}
    </>
  );
}

export function StockLocationField({
  name = 'location',
  title,
  onUpdate,
}: FieldProps) {
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(
        field.value && field.value.indexOf(':') > 0 ? field.value : undefined
      );
    }
  }, [field.value, onUpdate]);

  return (
    <StockLocation
      name={name}
      title={title}
      error={meta.touched ? meta.error : undefined}
      value={field.value}
      onChange={helpers.setValue}
    />
  );
}
