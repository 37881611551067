import { faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useAppContext } from '~/App';
import { Avatar } from '~/components/ui/Avatar';
import { useBreakpoint } from '~/hooks/useBreakpoint';

// TODO This is a perfect use case for fragment type instead
import { Contact } from './Bubbles';

type Props = {
  label: string;
  contacts: Contact[];
  onContactClick: (id: string) => void;
};

type ItemProps = {
  contact: Contact;
  onContactClick: (id: string) => void;
};

function ContactSectionItem({ contact, onContactClick }: ItemProps) {
  const { isMobile } = useBreakpoint();
  const { user: currentUser } = useAppContext();
  const { id, name, image, phone } = contact;
  const isSelf = currentUser?.email === contact.email;

  return (
    <div>
      <div
        className={classNames(
          'flex items-center rounded-[5px] border border-[#bdc9c8] bg-white',
          isMobile ? 'gap-[5px] p-2' : 'gap-[17px] px-[13px] py-2'
        )}
      >
        <Avatar
          className='h-[45px] w-[46px]'
          name={name}
          image={image}
          size='full'
        />
        <p
          className='cursor-pointer font-medium hover:underline'
          onClick={() => onContactClick(id)}
        >
          {name} {isSelf && '(me)'}
        </p>

        {!isSelf && phone && (
          <div className={classNames('ml-auto flex', isMobile && 'gap-[5px]')}>
            <button
              className={classNames(
                'h-[42px] w-[42px] rounded-full',
                isMobile ? 'bg-grey-3' : 'hover:bg-grey-3'
              )}
              onClick={() => (window.location.href = `tel:${phone}`)}
            >
              <FontAwesomeIcon
                color='#506262'
                className='text-lg'
                icon={faPhone}
              />
            </button>

            {/* <button
                className={classNames(
                  'h-[42px] w-[42px] rounded-full',
                  isMobile
                    ? 'bg-grey-3'
                    : 'hover:bg-grey-3'
                )}
                onClick={() => navigate(`contacts/${id}`)}
              >
                <FontAwesomeIcon
                  color='#506262'
                  className='text-lg'
                  icon={faMessage}
                />
              </button> */}
          </div>
        )}
      </div>
    </div>
  );
}

export const ContactSection = ({ label, contacts, onContactClick }: Props) => (
  <div>
    <p className='mb-[3px] text-sm'>{label}:</p>
    <div className='flex flex-col gap-2'>
      {contacts.map((c, i) => (
        <ContactSectionItem
          key={i}
          contact={c}
          onContactClick={onContactClick}
        />
      ))}
    </div>
  </div>
);
