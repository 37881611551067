import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HelperPopup } from '~/components/ui/HelperPopup';
import { TaskInput } from '~/generated/graphql';
import { move } from '~/helpers/array';
import { useSortable } from '~/hooks/useSortable';
import { TaskTypesSelect } from './ManageTasks';

export function TaskList({
  tasks,
  onClick,
  onSort,
  onDelete,
  onTaskTypeUpdate,
}: {
  tasks: TaskInput[];
  onClick: (id: string) => void;
  onSort: (tasks: TaskInput[]) => void;
  onDelete: (id: string) => void;
  onTaskTypeUpdate: (id: string, type: string) => void;
}) {
  // const { t } = useTranslation(['translation', 'job']);
  const sortRef = useRef<HTMLDivElement>(null);
  useSortable(sortRef?.current, {
    onUpdate({ oldIndex, newIndex }) {
      if (oldIndex !== undefined && newIndex !== undefined) {
        onSort(move(tasks, oldIndex, newIndex));
      }
    },
  });

  return (
    <div ref={sortRef}>
      {tasks.map((task, i) => (
        <TaskEditCard
          key={`${task.id}-${i}`}
          onClick={onClick}
          task={task}
          onDelete={onDelete}
          onTaskTypeUpdate={onTaskTypeUpdate}
        />
      ))}
    </div>
  );
}

type TaskEditCardProps = {
  task: TaskInput;
  onClick: (taskId: string) => void;
  onDelete: (id: string) => void;
  onTaskTypeUpdate: (id: string, type: string) => void;
};

function TaskEditCard({
  task,
  onClick,
  onDelete,
  onTaskTypeUpdate,
}: TaskEditCardProps) {
  const { t } = useTranslation(['translation', 'job']);
  // Don't display a task if it's set to be deleted on save
  if (task._destroy) {
    return null;
  }

  return (
    <div className='mb-3 flex w-full items-center gap-3'>
      <div className='relative w-full'>
        <div
          className={classNames(
            'flex flex-1 items-center rounded border bg-white text-grey-20 shadow transition-all hover:text-grey-40 hover:shadow-md',
            `${task.required ? 'border-brand' : 'border-grey-10'}`
          )}
          onClick={() => onClick(task.id)}
        >
          <div className='handle flex flex-1 cursor-pointer p-2.5'>
            <p className='cursor-move pr-[35px] text-grey-50'>
              {task.name ??
                (task.type === 'Item' ? t('job:tasks.addItems') : t(task.type))}
            </p>
            {task.type === 'Restock' &&
              task.item &&
              !task.item.locationId.startsWith('Elsewhere') &&
              !task.item.locationId && (
                <p className='relative -top-4 h-4 w-4'>
                  <HelperPopup>⚠️ Missing source location</HelperPopup>
                </p>
              )}
          </div>
          {task.required && (
            <div className='absolute -right-2 -top-2.5 flex h-4 w-4 items-center justify-center rounded-full bg-brand'>
              <span className='text-[9px] font-bold text-white'>M</span>
            </div>
          )}
        </div>
        <div className='absolute right-2 top-0 flex h-full items-center justify-center'>
          <TaskTypesSelect
            type={task.type}
            onTypeSelect={(type) => onTaskTypeUpdate(task.id, type)}
          />
        </div>
      </div>
      <QuickDeleteTaskButton taskId={task.id} onDelete={onDelete} />
    </div>
  );
}

type QuickDeleteTaskButtonProps = {
  taskId: string;
  onDelete: (id: string) => void;
};

function QuickDeleteTaskButton({
  taskId,
  onDelete,
}: QuickDeleteTaskButtonProps) {
  return (
    <div
      onClick={() => onDelete(taskId)}
      className='my-1 flex h-8 w-[50px] cursor-pointer items-center justify-center rounded-3xl border border-grey-10 text-grey-20 shadow transition-all hover:text-red-600'
    >
      <FontAwesomeIcon className='h-5 w-4' icon={faTrashCan} />
    </div>
  );
}
