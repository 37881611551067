import classNames from 'classnames';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { handleSupportMessengerOpen } from './layouts/nav/SupportLink';

export function RootBoundary({
  hasLogoSection = false,
  showSupportBtn = true,
}: {
  hasLogoSection?: boolean;
  showSupportBtn?: boolean;
}) {
  const error = useRouteError();
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data?.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    errorMessage = 'Unknown error';
  }

  console.error(error);

  return (
    <div className='flex h-[calc(100vh-8rem)] items-center justify-center'>
      <div
        className={classNames('flex w-4/5 flex-col gap-1', {
          'md:w-3/5 lg:w-2/5': !hasLogoSection,
          'lg:w-3/5': hasLogoSection,
        })}
      >
        <h1 className='text-[22px] font-semibold text-black'>
          Something went wrong!
        </h1>
        <span className='mb-4 text-base text-black'>{errorMessage}</span>
        <div className='flex items-center justify-end gap-3'>
          {showSupportBtn && (
            <button
              className='rounded-full border border-[#6E7979] px-6 py-2.5 normal-case text-[#002727] hover:brightness-150'
              onClick={() => handleSupportMessengerOpen()}
            >
              Contact Support
            </button>
          )}
          <button
            className='rounded-full bg-black px-6 py-2.5 text-white hover:bg-[#002727]'
            onClick={() => window.location.reload()}
          >
            Reload
          </button>
        </div>
      </div>
    </div>
  );
}
