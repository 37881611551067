import { addDays, differenceInCalendarDays, startOfDay } from 'date-fns';
import { ZoomLevel } from '~/routes/calendar._index';
import { CalendarEvent } from './ScheduleView';

/**
 * This class is responsible for calculating the vertical position of each event
 * on the calendar grid. It does this by iterating over each event and checking
 * for any overlapping events. If an event overlaps with another event, it will
 * increment the vertical position of the event until it finds an available slot.
 *
 * The vertical positions of the events will be calculated when the class is instantiated.
 *
 * The vertical positions can be accessed by calling the `getEventVpos` method.
 */
export class CalendarEventOrganiser {
  /** Calculate the number of whole days which an event will span */
  static getCalendarEventLength({ start, end }: { start: any; end?: any }) {
    const startDate = startOfDay(new Date(start));
    const endDate = startOfDay(addDays(new Date(end || start), 1));
    const days = differenceInCalendarDays(endDate, startDate);
    return days > 0 ? days : 1;
  }

  private events: CalendarEvent[];
  /** occupied[siteIndex]?.[columnIndex] == [undefined, undefined, true] */
  private occupied: (boolean | undefined)[][][];
  private zoomLevel: ZoomLevel;
  private vpos: Record<string, number>;

  constructor({
    events,
    zoomLevel,
  }: {
    events: CalendarEvent[];
    zoomLevel: ZoomLevel;
  }) {
    this.events = events;
    this.occupied = [];
    this.zoomLevel = zoomLevel;
    this.vpos = this.calculateEventVpos();
  }

  private occupyCell = (
    siteIndex: number,
    columnIndex: number,
    vpos: number
  ) => {
    if (this.occupied[siteIndex] == undefined) {
      this.occupied[siteIndex] = [];
    }
    if (this.occupied[siteIndex][columnIndex] == undefined) {
      this.occupied[siteIndex][columnIndex] = [];
    }
    this.occupied[siteIndex][columnIndex][vpos] = true;
  };

  private occupyCellRange = (
    siteIndex: number,
    startIndex: number,
    endIndex: number,
    vpos: number
  ) => {
    for (var i = startIndex; i <= endIndex; i++) {
      this.occupyCell(siteIndex, i, vpos);
    }
  };

  private isCellOccupied = (
    siteIndex: number,
    columnIndex: number,
    vpos: number
  ) => this.occupied[siteIndex]?.[columnIndex]?.[vpos] || false;

  private isCellRangeOccupied = (
    siteIndex: number,
    startIndex: number,
    endIndex: number,
    vpos: number
  ) => {
    for (var i = startIndex; i <= endIndex; i++) {
      if (this.isCellOccupied(siteIndex, i, vpos)) {
        return true;
      }
    }
    return false;
  };

  private getNextAvailableVpos = (
    siteIndex: number,
    startIndex: number,
    endIndex: number
  ) => {
    var candidateVpos = 0;

    while (
      this.isCellRangeOccupied(siteIndex, startIndex, endIndex, candidateVpos)
    ) {
      candidateVpos++;
    }

    return candidateVpos;
  };

  /** Calculate the vertical position of each event */
  private calculateEventVpos = () => {
    const eventVpos: Record<string, number> = {};

    this.events.forEach(({ x: columnIndex, y: siteIndex, event }) => {
      // Not relevant for bookings
      if (event.status == 'Booking') return;

      const days = CalendarEventOrganiser.getCalendarEventLength(event);

      const vpos = this.getNextAvailableVpos(
        siteIndex,
        columnIndex,
        columnIndex + days - 1
      );

      eventVpos[event.id] = vpos;

      this.occupyCellRange(
        siteIndex,
        columnIndex,
        columnIndex + days - 1,
        vpos
      );
    });

    return eventVpos;
  };

  getEventVpos = (): Exclude<typeof this.vpos, null> => {
    if (this.vpos === null) {
      const vpos = this.calculateEventVpos();
      this.vpos = vpos;
      return vpos;
    }

    return this.vpos;
  };

  getOccupied = () => this.occupied;
}
