import * as Sentry from '@sentry/react';
import { createContext, Suspense, useContext, useEffect, useMemo } from 'react';
import { hotjar } from 'react-hotjar';
import {
  createBrowserRouter,
  createRoutesFromElements,
  LoaderFunctionArgs,
  redirect,
  Route,
  RouterProvider,
  useLoaderData,
  useRevalidator,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SWRConfig } from 'swr';
import { Provider } from 'urql';
import { fetcher } from '~/helpers/fetch';
import { DefaultLayout } from '~/layouts/DefaultLayout';
import { NotFound } from '~/routes/NotFound';
import { User } from '~/types';
import './App.css';
import { client } from './client';
import { UppyInstanceManager } from './components/upload2';

// Account
import * as accounts_new from './routes/accounts.new';

// Activity
import { markAsRead } from './components/activity/ActivityListItem';
import { markAllAsRead } from './components/activity/MarkAllAsReadButton';
import * as activity from './routes/activity';

// Inbox

// Jobs
import { JobHistory } from '~/components/job/JobView/JobHistory';
import * as jobs from '~/routes/jobs';
import * as jobs_$view_$jobId from '~/routes/jobs.$view.$jobId';
import * as jobs_$view_$jobId_edit from '~/routes/jobs.$view.$jobId.edit';
import * as jobs_$view_calendar_$eventId from '~/routes/jobs.$view.calendar.$eventId';
import * as jobs_$view_new from '~/routes/jobs.($view).new';
import * as automations_$automationId from './routes/automations.$automationId';
import * as automations_$automationId_edit from './routes/automations.$automationId.edit';
import * as automations__index from './routes/automations._index';
import * as automations_new from './routes/automations.new';
import * as bookings_$bookingId from './routes/bookings.$bookingId';
import * as calendar__index from './routes/calendar._index';
import * as jobs_$view from './routes/jobs.$view';
import * as jobs_$view_$jobId_tasks_$taskId from './routes/jobs.$view.$jobId.tasks.$taskId';
import * as jobs__index from './routes/jobs._index';

// Job Templates
import * as templates_$templateId from '~/routes/job-templates.$templateId';
import * as templates_$templateId_clone from '~/routes/job-templates.$templateId.clone';
import * as templates_$templateId_edit from '~/routes/job-templates.$templateId.edit';
import { TemplateTaskView } from '~/routes/job-templates.$templateId.tasks.$taskId';
import * as templates__index from '~/routes/job-templates._index';
import * as templates_new from '~/routes/job-templates.new';

//Sites
import * as sites from '~/routes/sites';
import * as sites_$siteId from '~/routes/sites.$siteId';
import * as sites_$siteId_edit from '~/routes/sites.$siteId.edit';
import * as sites_$siteId_stocktake from '~/routes/sites.$siteId.stocktake';
import * as sites_new from '~/routes/sites.new';

// Stock
import { Items } from '~/routes/items';
import { StockMovements } from '~/routes/stock-movements';
import { StockOnHand } from '~/routes/stock-on-hand';
import { StockMovementRoute } from './routes/stock-movements/[id]';

// Assets
import { AssetView } from '~/components/assets/AssetView';
import { AssetConfig as Config } from '~/routes/asset-config';
import { AssetModels } from '~/routes/asset-models/index';
import { Assets } from '~/routes/assets';
import { Deployments } from '~/routes/deployments';
import { DeploymentView } from '~/routes/deployments.$id';
import { EditDeploymentForm } from '~/routes/deployments.$id.edit';
import { AssetForm } from './components/assets/AssetForm';

// More
import { PureAbility } from '@casl/ability';
import { SpaceSkuView } from '~/components/space-sku/SpaceSkuView';
import { Settings } from '~/routes/settings';
import { Spaces } from '~/routes/spaces';
import { DeploymentForm } from './components/assets/DeploymentForm';
import { AuthErrorBoundary } from './components/auth/AuthErrorBoundary';
import { ForgotPassword } from './components/auth/ForgotPassword';
import { Help } from './components/auth/Help';
import { LoginForm } from './components/auth/LoginForm';
import { ResetPassword } from './components/auth/ResetPassword';
import { SignupForm } from './components/auth/SignupForm';
import { RootBoundary } from './RootBoundary';
import AssetLayout from './routes/assets.$assetId';
import {
  default as AssetJobs,
  loader as assetJobsLoader,
} from './routes/assets.$assetId._index';
import AssetAttachments from './routes/assets.$assetId.attachments';
import {
  default as AssetDeployments,
  loader as assetDeploymentsLoader,
} from './routes/assets.$assetId.deployments';

// Contacts
import * as contacts_$contactId from '~/routes/contacts.$contactId';
import * as contacts_$contactId_edit from '~/routes/contacts.$contactId.edit';
import * as contacts__index from '~/routes/contacts._index';
import * as contacts_new from '~/routes/contacts.new';

// Integrations
import * as integrations__index from '~/routes/integrations._index';
import * as integrations_airbnb from '~/routes/integrations.airbnb';
import * as integrations_guesty from '~/routes/integrations.guesty';
import * as integrations_homhero from '~/routes/integrations.homhero';

// Other
import { AttributeAuditRoute } from './components/job/tasks/AttributeAuditTask';

type Session = {
  /** @deprecated Use GraphQL types instead */
  enums: {
    status: ['Active', 'Inactive'];
    assetType: string[];
    organisationType: string[];
  };
  tenant: {
    id: string;
    name: string;
    logo: string | null;
    image: string | null;
    industry: string | null;
    roles: string[];
  };
  user: User | null;
  rules: unknown;
};

type Context = {
  /** @deprecated Use GraphQL types instead */
  enums: Session['enums'];
  tenant: Session['tenant'];
  user: Session['user'];
  ability: PureAbility;
  isAuthenticated: boolean;
  uppy: UppyInstanceManager;
  revalidate: () => void;
};

/** @deprecated */
export const useEnums = () => {
  const { enums } = useContext(AppContext);

  return enums;
};

export const AppContext = createContext({} as Context);

export const useAppContext = () => useContext(AppContext);

export const useTenant = () => {
  const { tenant } = useAppContext();

  return {
    id: tenant?.id,
  };
};

const Loading = () => <div>Loading...</div>;

async function rootLoader({ request, params }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');

  // By default all routes are protected unless ignored here
  const ignored = [
    '/login',
    '/signup',
    '/forgot-password',
    '/help',
    '/reset-password',
  ];
  const isProtected = !ignored.includes(url.pathname);

  const res = await fetch('/api/sessions');

  if (token) {
    // TODO handle this server side
    if (res.status === 403) {
      // Expected current link format is /job/:view where view is actually a job id
      window.location.href = `/in/?job=${params.view}&token=${token}`;
    } else {
      window.location.href = url
        .toString()
        .substring(0, url.toString().indexOf('?'));
    }
    return null;
  }

  if (isProtected) {
    return res.status === 403 ? redirect('/login') : res;
  }

  return res.status === 403 ? null : redirect('/jobs');
}

const HOTJAR_ID = import.meta.env.VITE_HOTJAR_HJID;
const HOTJAR_SNIPPET_VERSION = import.meta.env.VITE_HOTJAR_HJSV;

const App = () => {
  const data = useLoaderData() as any;
  const revalidator = useRevalidator();

  // Keep a global Uppy instance to manage background uploads
  const uppy = useMemo(() => new UppyInstanceManager(), []);

  const isAuthenticated = Boolean(data);
  const session = data ? data[0] : ({} as any);
  const revalidate = () => revalidator.revalidate();

  if (session.user) {
    const { tenant, user } = session;
    Sentry.setContext('tenant', { id: tenant.id, name: tenant.name });
    Sentry.setUser({ id: user.id, username: user.name, email: user.email });
  }

  const ability = new PureAbility(session.rules);

  useEffect(() => {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <SWRConfig value={{ fetcher }}>
        <AppContext.Provider
          value={{ ...session, ability, isAuthenticated, uppy, revalidate }}
        >
          <DefaultLayout />
          <ToastContainer autoClose={2000} />
        </AppContext.Provider>
      </SWRConfig>
    </Suspense>
  );
};

const ClientApp = () => (
  <Provider value={client}>
    <App />
  </Provider>
);

function createJobViewRoutes() {
  return (
    <>
      <Route path=':jobId/edit' element={<jobs_$view_$jobId_edit.Component />}>
        <Route
          path='bookings/:bookingId'
          element={<bookings_$bookingId.default />}
        />
      </Route>
      <Route
        path=':jobId'
        loader={jobs_$view_$jobId.loader}
        element={<jobs_$view_$jobId.default />}
      >
        <Route
          path='contacts/:contactId'
          element={<contacts_$contactId.default />}
        />
        <Route
          path='bookings/:bookingId'
          element={<bookings_$bookingId.default />}
        />
        <Route
          path='tasks/:taskId'
          element={<jobs_$view_$jobId_tasks_$taskId.TaskView />}
        />
        <Route path='history' element={<JobHistory />} />
        <Route
          path='new'
          loader={jobs_$view_new.loader}
          element={<jobs_$view_new.default />}
        />
      </Route>
    </>
  );
}

const router = createBrowserRouter([
  {
    id: 'mark-all-as-read',
    path: '/activity/mark-all-as-read',
    action: markAllAsRead,
  },
  {
    id: 'mark-as-read',
    path: '/activity/mark-as-read',
    action: markAsRead,
  },
  // {
  //   id: 'flagged',
  //   path: '/flagged',
  //   action: flagged,
  // },
  ...createRoutesFromElements(
    <Route
      path='/'
      loader={rootLoader}
      element={<ClientApp />}
      errorElement={<RootBoundary />}
    >
      <Route index loader={() => redirect('/jobs')} />
      <Route
        path='login'
        element={<LoginForm />}
        errorElement={<AuthErrorBoundary />}
      />
      <Route
        path='signup'
        element={<SignupForm />}
        errorElement={<AuthErrorBoundary />}
      />
      <Route
        path='forgot-password'
        element={<ForgotPassword />}
        errorElement={<AuthErrorBoundary />}
      />
      <Route path='help' element={<Help />} />
      <Route
        path='reset-password'
        element={<ResetPassword />}
        errorElement={<AuthErrorBoundary />}
      />
      <Route
        path='accounts/new'
        action={accounts_new.action}
        element={<accounts_new.Component />}
        errorElement={<accounts_new.ErrorBoundary />}
      />
      {/* <Route path='login' loader={() => redirect('/jobs')} />
      <Route path='signup' loader={() => redirect('/jobs')} />
      <Route path='forgot-password' loader={() => redirect('/jobs')} /> */}

      <Route
        id='attribute-audit'
        path='/jobs/:jobId/tasks/:taskId/attribute-audit'
        element={<AttributeAuditRoute />}
      />

      <Route
        id='activity'
        path='activity'
        loader={activity.loader}
        element={<activity.default />}
        shouldRevalidate={activity.shouldRevalidate}
        errorElement={<RootBoundary />}
      >
        <Route path='new' loader={() => redirect('/activity/jobs/new')} />
        <Route
          path='jobs/new'
          loader={jobs_$view_new.loader}
          element={<jobs_$view_new.default />}
        />
        <Route path='jobs'>{createJobViewRoutes()}</Route>
      </Route>

      <Route path='jobs' errorElement={<jobs.ErrorBoundary />}>
        <Route index element={<jobs__index.default />} />
        <Route
          path=':view'
          loader={jobs_$view.loader}
          element={<jobs.JobsLayout />}
          id='jobs_$view'
        >
          <Route
            path='new'
            loader={jobs_$view_new.loader}
            element={<jobs_$view_new.default />}
          />
          <Route
            path='calendar/:eventId'
            element={<jobs_$view_calendar_$eventId.Component />}
          />
          {createJobViewRoutes()}
        </Route>
      </Route>
      <Route
        path='jobs/calendar'
        element={<calendar__index.default />}
        errorElement={<RootBoundary />}
      >
        <Route
          path='bookings/:bookingId'
          element={<bookings_$bookingId.default />}
        />
        <Route path='sites/:siteId' element={<sites_$siteId.default />}>
          {/* Secondary Outlet for Calendar Sites  */}
          {/* Stock */}
          <Route
            path='stock-adjustment'
            element={<sites_$siteId_stocktake.default />}
          />
          <Route
            path='stocktake'
            element={<sites_$siteId_stocktake.default />}
          />
          {/* Jobs */}
          <Route path='jobs'>{createJobViewRoutes()}</Route>
          {/* Items  */}
          <Route path='items/:itemId' element={<SpaceSkuView />} />
          {/* Assets  */}
          <Route path='assets/:assetId' element={<AssetView />} />
        </Route>
        {/* Calendar Sites Edit */}
        <Route
          path='sites/:siteId/edit'
          loader={sites_$siteId_edit.loader}
          action={sites_$siteId_edit.action}
          element={<sites_$siteId_edit.EditSite />}
        />
        <Route
          path='new'
          loader={jobs_$view_new.loader}
          element={<jobs_$view_new.default />}
        />
        {createJobViewRoutes()}
      </Route>

      {/* TEMPLATES  */}
      <Route
        path='job-templates'
        loader={templates__index.loader}
        element={<templates__index.default />}
        errorElement={<RootBoundary />}
      >
        <Route
          path='new'
          action={templates_new.action}
          loader={templates_new.loader}
          element={<templates_new.default />}
        />
        <Route path=':templateId' element={<templates_$templateId.default />}>
          <Route path='tasks/:taskId' element={<TemplateTaskView />} />
        </Route>
        <Route
          path=':templateId/edit'
          action={templates_$templateId_edit.action}
          loader={templates_$templateId_edit.loader}
          element={<templates_$templateId_edit.default />}
        />
        <Route
          path=':templateId/clone'
          action={templates_$templateId_clone.action}
          loader={templates_$templateId_clone.loader}
          element={<templates_$templateId_clone.default />}
        />
      </Route>

      <Route
        path='stock-movements'
        element={<StockMovements />}
        errorElement={<RootBoundary />}
      >
        <Route path=':id' element={<StockMovementRoute />} />
      </Route>
      <Route
        path='stock-on-hand/*'
        element={<StockOnHand />}
        errorElement={<RootBoundary />}
      />
      <Route
        path='items/*'
        element={<Items />}
        errorElement={<RootBoundary />}
      />
      <Route
        path='config/*'
        element={<Config />}
        errorElement={<RootBoundary />}
      />

      <Route path='assets' element={<Assets />} errorElement={<RootBoundary />}>
        <Route path='new' element={<AssetForm />} />
        <Route path=':assetId/edit' element={<AssetForm />} />
        <Route path=':assetId/deploy' element={<DeploymentForm />} />
        <Route path=':assetId' element={<AssetLayout />}>
          <Route index loader={assetJobsLoader} element={<AssetJobs />} />
          <Route
            path='deployments'
            loader={assetDeploymentsLoader}
            element={<AssetDeployments />}
          />
          <Route path='attachments' element={<AssetAttachments />} />
        </Route>
      </Route>
      {/* <Route path='asset-config/*' element={<Config locationType='Asset' />} /> */}
      <Route
        path='deployments'
        element={<Deployments />}
        errorElement={<RootBoundary />}
      >
        <Route path=':id' element={<DeploymentView />} />
        <Route
          path=':id/edit'
          element={<EditDeploymentForm deployments={[]} />}
        />
      </Route>
      <Route path='asset-models/*' element={<AssetModels />} />

      {/* SITES */}
      <Route
        path='sites'
        element={<sites.default />}
        errorElement={<RootBoundary />}
      >
        {/* Main Outlet for Sites  */}
        <Route
          path='new'
          loader={sites_new.loader}
          action={sites_new.action}
          element={<sites_new.NewSite />}
        />
        <Route
          path=':siteId/edit'
          loader={sites_$siteId_edit.loader}
          action={sites_$siteId_edit.action}
          element={<sites_$siteId_edit.EditSite />}
        />
        <Route path=':siteId' element={<sites_$siteId.default />}>
          {/* Secondary Outlet for Sites  */}
          {/* Stock */}
          <Route
            path='stock-adjustment'
            element={<sites_$siteId_stocktake.default />}
          />
          <Route
            path='stocktake'
            element={<sites_$siteId_stocktake.default />}
          />
          {/* Jobs */}
          <Route path='jobs'>{createJobViewRoutes()}</Route>
          {/* Items  */}
          <Route path='items/:itemId' element={<SpaceSkuView />} />
          {/* Assets  */}
          <Route path='assets/:assetId' element={<AssetView />} />
        </Route>
      </Route>

      <Route
        path='site-config/*'
        element={<Config /* locationType='Site' */ />}
        errorElement={<RootBoundary />}
      />

      <Route
        path='spaces/*'
        element={<Spaces />}
        errorElement={<RootBoundary />}
      />

      <Route
        path='contacts'
        element={<contacts__index.default />}
        errorElement={<RootBoundary />}
      >
        <Route path='new' element={<contacts_new.default />} />
        <Route
          path=':contactId/edit'
          element={<contacts_$contactId_edit.default />}
        />
        <Route path=':contactId' element={<contacts_$contactId.default />} />
      </Route>

      <Route
        path='automations'
        loader={automations__index.loader}
        element={<automations__index.Component />}
        errorElement={<RootBoundary />}
      >
        <Route path='new' element={<automations_new.Component />} />
        <Route
          path=':automationId/edit'
          element={<automations_$automationId_edit.Component />}
        />
        <Route
          path=':automationId'
          element={<automations_$automationId.Component />}
        />
      </Route>

      <Route path='integrations' errorElement={<RootBoundary />}>
        <Route
          index
          loader={integrations__index.loader}
          element={<integrations__index.default />}
        />
        <Route path='airbnb' element={<integrations_airbnb.default />} />
        <Route
          path='guesty'
          action={integrations_guesty.action}
          element={<integrations_guesty.default />}
          errorElement={<integrations_guesty.ErrorBoundary />}
        />
        <Route path='homhero' element={<integrations_homhero.default />} />
      </Route>
      <Route
        path='settings'
        element={<Settings />}
        errorElement={<RootBoundary />}
      />

      <Route path='*' element={<NotFound />} />
    </Route>
  ),
]);

const RouterApp = () => <RouterProvider router={router} />;

export { RouterApp as App };
