import { graphql } from '~/gql';

export const ContactFields = graphql(`
  fragment ContactFields on Contact {
    id
    status
    type
    name
    email
    phone
    relationship
    image
    isCurrentUser
    isTeamMember
  }
`);
