import { graphql } from '~/gql';
import { Attachment } from '~/gql/graphql';
import { FileLink } from '../FileLink';
import { Gallery } from '../job/JobView/Feed/Gallery';

type Props = {
  attachments?: Attachment[];
};

const Attribute_AttachmentFragment = graphql(`
  fragment Attribute_AttachmentFragment on Attachment {
    id
    kind
    ext
    thumb
    url
    originalFilename
  }
`);

export function FilesListWithGallery({
  attachments,
}: Pick<Props, 'attachments'>) {
  const files = attachments
    ?.filter(({ kind }) => kind === 'file')
    .map(({ id, originalFilename, url }) => (
      <FileLink key={id} url={url} originalFilename={originalFilename} />
    ));

  const images = attachments?.filter(({ kind }) => kind === 'image');

  return (
    <>
      {files}
      {images && images.length > 0 && <Gallery attachments={images} />}
    </>
  );
}
