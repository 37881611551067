import { extractDate } from '../JobHistory';

type Props = {
  date: string;
  className?: string;
};

export function FormatedDate({ date, className }: Props) {
  let tDay = new Date();
  let today = extractDate(tDay.toISOString());
  let yDay = new Date();
  yDay.setDate(yDay.getDate() - 1);
  let yesterday = extractDate(yDay.toISOString());

  if (extractDate(date) === today) {
    return <h3 className={className ?? ''}>Today</h3>;
  } else if (extractDate(date) === yesterday) {
    return <h3 className={className ?? ''}>Yesterday</h3>;
  }
  return <h3 className={className ?? ''}>{extractDate(date)}</h3>;
}
