import { Address as TAddress } from '~/types';

type Props = {
  data?: TAddress | string | null;
  longFormat?: boolean;
  className?: string;
};

/**
 * Parse address data and display a formatted address
 * @todo Remove this or at least have it use parseJson helper and type return value
 */
const addressObj = (data: Props['data']) => {
  if (typeof data === 'string') {
    try {
      const parsedAddress = JSON.parse(data);
      return parsedAddress;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return;
    }
  } else {
    return data;
  }
};

const getAddressCoordinates = (address: TAddress) => {
  const {
    // subpremise,
    street_number: number,
    route,
    locality,
    administrative_area_level_1: state,
    postal_code: postcode,
    // country,
  } = address;

  return [number, route, locality, state, postcode].join('+');
};

const mapsSelector = (addressCoordinates: string | undefined) => {
  if (
    /* if we're on iOS, open in Apple Maps */
    navigator.platform.indexOf('iPhone') !== -1 ||
    navigator.platform.indexOf('iPad') !== -1 ||
    navigator.platform.indexOf('iPod') !== -1
  )
    window.open(
      `maps://maps.google.com/maps/dir/?daddr=${addressCoordinates}}`
    );
  /* else use Google */ else
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${addressCoordinates}`
    );
};

export const redirectNewWindowToLocation = (address: string) => {
  const addressObj = JSON.parse(address);
  const addressCoordinates = getAddressCoordinates(addressObj);
  mapsSelector(addressCoordinates);
};

const isNumber = (value: string) => {
  return /^[0-9]*$/.test(value);
};

export const Address = ({ data, longFormat = false }: Props) => {
  if (!data) {
    return <span>-</span>;
  }
  const address = addressObj(data);

  const {
    subpremise,
    street_number: number,
    route,
    locality,
    administrative_area_level_1: state,
    postal_code: postcode,
    // country,
  } = address;

  if (!longFormat) {
    return (
      <address className='flex flex-col items-start not-italic'>
        {subpremise && subpremise + ', '}
        {number} {route && route + ','} {locality && locality + ','} {state}{' '}
        {postcode}
      </address>
    );
  }

  if (subpremise && isNumber(subpremise)) {
    return (
      <address className='flex flex-col items-start text-left not-italic'>
        <p>
          {`${subpremise}/`}
          {number} {route}
        </p>
        <p>
          {locality} {state} {postcode}
        </p>
      </address>
    );
  } else if (subpremise) {
    return (
      <address className='flex flex-col items-start text-left not-italic'>
        <p>{subpremise}</p>
        <p>
          {number} {route}
        </p>
        <p>
          {locality} {state} {postcode}
        </p>
      </address>
    );
  } else {
    return (
      <address className='flex flex-col items-start text-left not-italic'>
        <p>
          {number} {route}
        </p>
        <p>
          {locality} {state} {postcode}
        </p>
        {/* <p>{country}</p> */}
      </address>
    );
  }
};

export const AddressLink = ({ data }: Props) => {
  const address = addressObj(data);

  if (!address) return null;

  const addressCoordinates = getAddressCoordinates(address);
  return (
    <button onClick={() => mapsSelector(addressCoordinates)}>
      <Address data={data} longFormat />
    </button>
  );
};

export const DirectionsLink = ({ data, className }: Props) => {
  const address = addressObj(data);
  const addressCoordinates = getAddressCoordinates(address);
  return (
    <button
      onClick={() => mapsSelector(addressCoordinates)}
      className={className ? className : ''}
    >
      Get Directions
    </button>
  );
};

export const ShortAddress = ({ data, className }: Props) => {
  if (!data) {
    return null;
  }
  const {
    subpremise,
    street_number: number,
    route,
    locality,
    administrative_area_level_1: state,
  } = addressObj(data);
  return (
    <span className={className}>
      {subpremise && subpremise + ', '}
      {number} {route}
      {locality && ', ' + locality + ' '}
      {!locality && state ? ', ' + state : state}
    </span>
  );
};
