import { ControlledAttributes } from '~/components/form/attributes/AttributesField';
import { Button } from '~/components/form/SubmitButton';
import { Input } from '~/components/form/TextField';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import {
  AttributeInput,
  SpaceSkuDetailQuery,
  useUpdateSpaceSkuMutation,
} from '~/generated/graphql';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type FormData = {
  // status
  soh: number;
  capacity: number | null;
  target: number | null;
  reorderLevel: number | null;
  price: number | null;
  attributes: AttributeInput[];
};

type Props = {
  item: SpaceSkuDetailQuery['spaceSkus'][0];
  onSuccess: () => void;
};

export function SpaceSkuForm({ item, onSuccess }: Props) {
  const { t } = useTranslation();
  const [result, updateSpaceSku] = useUpdateSpaceSkuMutation();
  const { fetching } = result;
  const { control, handleSubmit, register } = useForm<FormData>({
    defaultValues: {
      capacity: item.capacity,
      target: item.target,
      reorderLevel: item.reorderLevel,
      price: item.price != null ? item.price / 100 : null,
      attributes:
        item.attributes?.map(({ name, value }) => ({ name, value })) ?? [],
    },
  });

  async function update(data: FormData) {
    const result = await updateSpaceSku({ id: item.id, input: data });
    if (result.error) {
      toast.error('Something went wrong');
      return;
    }
    onSuccess();
  }

  function registerNumber(name: keyof FormData) {
    return {
      type: 'number',
      min: 0,
      ...register(name, { valueAsNumber: true }),
    };
  }

  function registerMoney(name: keyof FormData) {
    return {
      type: 'number',
      min: 0,
      step: '0.01',
      ...register(name, {
        setValueAs: (v) => (v !== '' ? v * 100 : null),
      }),
    };
  }

  return (
    <form
      className='relative h-[calc(100vh_-_7rem)] p-4 lg:h-full'
      onSubmit={handleSubmit(update)}
    >
      <StatusBadge value={item.status} />
      <ReadValue label='onHand'>{item.soh}</ReadValue>
      {/* <Input {...registerNumber('capacity')} label={t('capacity')} /> */}
      <Input {...registerNumber('target')} label={t('target')} />
      <Input {...registerNumber('reorderLevel')} label={t('reorderLevel')} />
      <Input {...registerMoney('price')} label={t('price')} />
      <Controller
        name='attributes'
        control={control}
        render={({ field }) => (
          <ControlledAttributes type='SpaceSku' {...field} />
        )}
      />
      <div className='absolute bottom-0 left-4 right-4'>
        <Button loading={fetching} />
      </div>
    </form>
  );
}
