import { createContext, useContext } from 'react';

type TContext = {
  refresh: () => void;
};

export const ContactListContext = createContext<TContext>({} as TContext);

export function useContactListContext() {
  return useContext(ContactListContext);
}
