import { QuickAddMutation } from '~/generated/graphql';
import { QuickAdd } from './QuickAdd';
import { PopupDialogNew } from './ui/PopupDialogNew';

type Props = {
  stockLocation?: string;
  onCancel?: () => void;
  onSuccess?: (data: QuickAddMutation) => void;
  show: boolean;
  onClose: () => void;
};

export const QuickAddPopup = ({
  stockLocation,
  onCancel,
  onSuccess,
  onClose,
  show,
}: Props) => {
  return (
    <PopupDialogNew isOpen={show} onClose={onClose}>
      <QuickAdd
        stockLocation={stockLocation}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
    </PopupDialogNew>
  );
};
