import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import invariant from 'tiny-invariant';
import { AppContext } from '~/App';
import {
  NotificationSettingInput,
  useSaveNotificationPreferencesMutation,
} from '~/generated/graphql';
import { UserNotificationSettings } from '~/types';
import { Page } from '.';

type Props = {
  setShowPage: (page: Page) => void;
};

type FormData = UserNotificationSettings;

export const NotificationsForm = ({ setShowPage }: Props) => {
  const { user: currentUser, revalidate } = useContext(AppContext);
  invariant(currentUser);

  const {
    formState: { isSubmitting },
    handleSubmit,
    register,
    watch,
  } = useForm<FormData>({
    defaultValues: currentUser.notificationSettings,
  });
  const [, save] = useSaveNotificationPreferencesMutation();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name && type === 'change') {
        // * Little bit crude, but it works for now.
        // * Might need a cleaner solution if notification settings are expanded further.
        let notificationType: NotificationSettingInput['type'] | null = null;
        let values:
          | {
              email?: boolean;
              sms?: boolean;
            }
          | undefined
          | null = null;
        if (name.startsWith('messages')) {
          notificationType = 'messages';
          values = value.messages;
        } else if (name.startsWith('statusChanges')) {
          notificationType = 'status_changes';
          values = value.statusChanges;
        } else if (name.startsWith('jobReminders')) {
          if (name.includes('twentyFourHoursPrior')) {
            notificationType = 'job_reminders_24_hours';
            values = value.jobReminders?.twentyFourHoursPrior;
          } else if (name.includes('oneHourPrior')) {
            notificationType = 'job_reminders_1_hour';
            values = value.jobReminders?.oneHourPrior;
          }
        }

        if (notificationType === null || values === null) {
          return;
        }

        if (name.endsWith('email')) {
          save({
            input: {
              type: notificationType,
              email: values?.email,
            },
          }).then(revalidate);
        } else if (name.endsWith('sms')) {
          save({
            input: {
              type: notificationType,
              sms: values?.sms,
            },
          }).then(revalidate);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, save]);

  function Checkbox({ name }: { name: Parameters<typeof register>[0] }) {
    return (
      <input
        type='checkbox'
        {...register(name)}
        className='z-30 mx-auto h-4 w-4 cursor-pointer accent-brand'
      />
    );
  }

  return (
    <form>
      <div className='flex flex-col gap-11 text-copy-alt'>
        <div>
          Specify which notifications you would like to receive and how you
          would like to receive them.
        </div>
        <div className='grid w-fit grid-cols-3 gap-x-4 gap-y-6'>
          <div>{/* Top left corner */}</div>
          <div className='mx-auto'>Email</div>
          <div className='mx-auto'>SMS</div>
          <div>Messages</div>
          <Checkbox name='messages.email' />
          <Checkbox name='messages.sms' />
          <div>Status Changes</div>
          <Checkbox name='statusChanges.email' />
          <Checkbox name='statusChanges.sms' />
          {/* Temporarily disable job reminders notification settings because they don't exist */}
          {/* <div className='col-span-3 font-semibold'>Job Reminders</div>
          <div className='ml-8 list-item'>24 hours prior</div>
          <Checkbox name='jobReminders.twentyFourHoursPrior.email' />
          <Checkbox name='jobReminders.twentyFourHoursPrior.sms' />
          <div className='ml-8 list-item'>1 hour prior</div>
          <Checkbox name='jobReminders.oneHourPrior.email' />
          <Checkbox name='jobReminders.oneHourPrior.sms' /> */}
        </div>
      </div>
      {/* To be updated to currentUser.phone when added to the backend */}
      {!currentUser?.email && (
        <p className='text-red pt-2 text-sm font-light'>
          You need a valid mobile phone number to receive SMS notifications.
          Click{' '}
          <span
            onClick={() => setShowPage('personal')}
            className='cursor-pointer text-blue underline'
          >
            here{' '}
          </span>
          to add one.
        </p>
      )}
    </form>
  );
};
