import { useNavigate } from 'react-router-dom';
import { Step2Form } from '~/components/smc/Step2Form';
import { ErrorMessage } from '~/components/ui/Error';
import { JobQuery, TaskQuery } from '~/gql/graphql';

type Job = JobQuery['job'];

type Props = {
  enabled: boolean;
  job: Job;
  task: TaskQuery['task'];
  onSuccess: () => void;
  onClose: () => void;
};

export const PurchaseTask = ({
  enabled,
  job,
  task,
  onSuccess,
  onClose,
}: Props) => {
  const navigate = useNavigate();
  const location = job?.location;

  if (!location) {
    return (
      <ErrorMessage message='Job does not have a location' onBack={onClose} />
    );
  }

  const initialAdjustments = task.purchase?.movements?.map(({ id, qty }) => ({
    id,
    qty,
  }));

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    } else {
      navigate('.');
    }
  };

  return (
    <Step2Form
      // FIXME
      mode='purchase'
      data={{
        enabled,
        taskId: task.id,
        name: task.name,
        description: task.description,
        date: new Date(),
        location,
        purchase: task.purchase,
      }}
      buttonText='Complete Task'
      jobStatus={job.status}
      jobName={job.name}
      taskRequired={task.required}
      taskStatus={task.status}
      initialAdjustments={initialAdjustments}
      onSuccess={handleSuccess}
      onCancel={onClose}
      showStep={false}
    />
  );
};
