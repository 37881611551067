import useSWR from 'swr';

type Optionable =
  | 'asset-models'
  | 'fsps'
  | 'organisations'
  | 'warehouses'
  | 'states'
  | 'assets'
  | 'skus'
  | 'users'
  | 'spaces'
  | 'locations';

type Option = {
  value: string;
  label: string;
};

export const useOptions = (key: Optionable, qs: string = '') => {
  return useSWR<Option[]>(`/api/${key}?select${qs ? '&' + qs : ''}`);
};

export function createUseResource<T>(key: string) {
  return (id: string | undefined) => {
    const { data } = useSWR<T[]>(key);

    if (data) {
      return data.find((item: any) => item.id.toString() === id);
    }
  };
}
