import { useTranslation } from 'react-i18next';

type BaseHeroProps = {
  number: number | string;
  directive: string;
  status?: React.ReactNode;
};

type HeroProps = {
  color?: 'green' | 'warning';
  // TODO use MoveStockV2 Movement
  movement: any;
};

export function Hero({ color, movement }: HeroProps) {
  const { type, move, moved } = movement;
  const { t } = useTranslation('job');
  const number = moved === null ? move : moved;
  const directive = t(`card.${type}.${moved === null ? 'verb' : 'input'}`);

  if (color === 'green') {
  }

  return (
    <div className='text-right'>
      <div className='text-3xl'>{number}</div>
      <div className='text-xs whitespace-nowrap'>{directive}</div>
      {moved !== null && (
        <div className='text-xs whitespace-nowrap'>of {move}</div>
      )}
    </div>
  );
}

export function BaseHero({ number, directive, status }: BaseHeroProps) {
  return (
    <div className='text-right'>
      <div className='text-3xl'>{number}</div>
      <div className='text-xs whitespace-nowrap'>{directive}</div>
      {status && <div className='text-xs whitespace-nowrap'>{status}</div>}
    </div>
  );
}
