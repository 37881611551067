import classNames from 'classnames';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { Label } from '../ui/Label';

type Props = {
  label?: string;
  focusValue?: string;
  error?: string;
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
};

type State = {
  hour: string;
  minute: string;
  meridiem: string;
};

const HOURS = [...Array(12)].map((_, i) => (1 + i).toString());
const MINUTES = [...Array(4)].map((_, i) =>
  (i * 15).toString().padStart(2, '0')
);

const emptyValue = { hour: '', minute: '', meridiem: 'am' } as const;
const initValue = (value: string) => {
  if (!value) {
    return null;
  }
  const hour = value.substring(0, 2);
  const minute = value.slice(-2);
  const meridiem = parseInt(hour) >= 12 ? 'pm' : 'am';
  const hour12 = parseInt(hour) % 12 || 12;
  return { hour: hour12.toString(), minute, meridiem };
};

export const TimePicker = ({
  label,
  focusValue,
  error,
  value,
  onChange,
  required = false,
  disabled = false,
}: Props) => {
  const [parts, setParts] = useState<State>(() => {
    return initValue(value) ?? emptyValue;
  });
  const setPart = (k: keyof State) => (v: string) => {
    return setParts({ ...parts, [k]: v });
  };

  useEffect(() => {
    setParts(initValue(value) ?? emptyValue);
  }, [value]);

  useEffect(() => {
    if (Object.values(parts).every(Boolean)) {
      const hour = parseInt(parts.hour);
      onChange(
        `${
          parts.meridiem === 'pm' && hour < 12
            ? 12 + hour
            : parts.hour.padStart(2, '0')
        }:${parts.minute}`
      );
    }
  }, [parts]);

  return (
    <>
      <div
        className='relative mb-5 flex flex-col gap-1'
        onFocusCapture={(event) => {
          if (!value && focusValue) {
            setParts(initValue(focusValue) ?? emptyValue);
          }
        }}
      >
        <Label>
          {label}
          {required && <span className='pl-0.5 text-brand'>*</span>}
        </Label>

        <div
          className={classNames([
            'input mt-0.5 overflow-hidden whitespace-nowrap rounded border px-2 py-2.5 focus-visible:border-grey-40',
            error ? 'border-red-600' : 'border-grey-20',
          ])}
        >
          <Segment
            items={HOURS}
            value={parts.hour}
            onChange={setPart('hour')}
            disabled={disabled}
          />
          <span className='inline-block'>:</span>
          <Segment
            items={MINUTES}
            value={parts.minute}
            onChange={setPart('minute')}
            disabled={disabled}
          />
          <span className='inline-block'>&nbsp;</span>
          <Segment
            items={['am', 'pm']}
            value={parts.meridiem}
            onChange={setPart('meridiem')}
            disabled={disabled}
          />
        </div>

        {error && <p className='ErrorMessage'>{error}</p>}
      </div>
    </>
  );
};

type SegmentProps = {
  items: string[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

function Segment({ items, value, onChange, disabled = false }: SegmentProps) {
  return (
    <select
      className='appearance-none rounded bg-white px-0.5 text-right text-base text-copy-body outline-none focus:bg-brand focus:text-white'
      value={value}
      onChange={(event) => onChange(event.target.value)}
      disabled={disabled}
    >
      <option value='' disabled>
        --
      </option>
      {items.map((option) => {
        return (
          <option key={option} value={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
}

type FieldProps = Omit<Props, 'value' | 'onChange'> & {
  name: string;
};

export function TimePickerField({ name, ...props }: FieldProps) {
  const [field, meta, helpers] = useField(name);

  return (
    <TimePicker
      {...props}
      value={field.value}
      onChange={(time) => helpers.setValue(time)}
      error={meta.error}
    />
  );
}
