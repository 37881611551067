import axios from 'axios';
import { LogoutIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { MouseEvent } from 'react';

export const handleLogout = (event: MouseEvent) => {
  event.preventDefault();

  axios.delete('sessions').then(() => {
    window.location.assign('/login');
  });
};

export const LogoutLink = () => {
  const { t } = useTranslation();

  return (
    <a
      href='/logout'
      onClick={handleLogout}
      className='hover:text-brand text-copy-alt flex flex-row items-center justify-start py-4 font-medium lg:py-0 lg:text-base lg:font-normal'
    >
      <span>
        <LogoutIcon
          strokeWidth={1}
          className='color-current mr-3 block h-8 w-8 flex-1'
        />
      </span>
      {t('signOut')}
    </a>
  );
};
