import { useEffect, useState } from 'react';

/**
 * The width of the window minus the scrollbar and borders
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Window/innerWidth#Usage_notes
 */
const getHtmlWidth = () => document.children[0].clientWidth;

type Props = {
  children?: React.ReactNode;
};

export const Resize = ({ children }: Props) => {
  // TODO if the window resizes % based bounds will need to be recalculated
  //  need to setup a resize listener to properly support that
  const maxWidth = getHtmlWidth() / 2;
  const minWidth = 421;

  const [width, setWidth] = useState<number>(
    () =>
      JSON.parse(window.localStorage.getItem('ui.rsb.width') || 'null') || 421
  );

  useEffect(() => {
    window.localStorage.setItem('ui.rsb.width', JSON.stringify(width));
    document.dispatchEvent(new CustomEvent('resized', { detail: { width } }));
  }, [width]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const handleMouseUp = (event: MouseEvent) => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (event: MouseEvent) => {
      const selection = window.getSelection();
      if (selection) selection.removeAllRanges();

      const width = getHtmlWidth() - event.clientX;
      setWidth(Math.min(Math.max(width, minWidth), maxWidth));
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // TODO enable transition width on the element for smooth resizing
    const width = getHtmlWidth() - event.clientX;
    setWidth(width <= minWidth ? maxWidth : minWidth);
  };

  return (
    <div className='zbg-gray-300 flex h-full w-full' style={{ width: width }}>
      <div
        className='hidden transition-colors hover:bg-gray-100 lg:block'
        style={{ cursor: 'col-resize', width: 7 }}
        onMouseDown={handleMouseDown}
        onDoubleClick={handleDoubleClick}
      ></div>
      <div className='flex-1'>{children}</div>
    </div>
  );
};
