import { Form, Formik, FormikHelpers } from 'formik';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ActionCallable = (values: any) => Promise<any>;
type Action = { key: string; action: ActionCallable; buttonText: string };

type Props<IV = Record<string, any>> = {
  children: React.ReactNode;
  action: ActionCallable | Action[];
  initialValues: IV;
  validationSchema?: any;
  title?: string;
  buttonText?: string;
  buttonDisabled?: boolean;
  sanitizeValues?: (values: IV) => IV;
  onSuccess?: (data: any) => void;
  onCancel?: () => void;
  enableReinitialize?: boolean;

  /** @deprecated use onCancel instead */
  prevUrl?: string;
};

/**
 * A form that dispatches an action on submit
 *
 * @param {Object} props
 * @param {*} validationSchema Passed directly to Formik
 * @param {string} props.sanitizeValues Sometimes when integrating with third-party form components it's simpler to map values to a format at the time of submit rather than deal with conversion via the typical formik setValue
 * @param {function} props.onSuccess Callback function for when action is successfully dispatched. For cases where you want some behaviour without using Redux. Avoid using this wherever possible and favour the Redux way instead.
 *
 * @deprecated
 */
export const ActionForm = ({
  action,
  initialValues,
  onCancel,
  title,
  children,
  buttonText,
  buttonDisabled = false,
  sanitizeValues = (values) => values,
  onSuccess,
  prevUrl = '',
  enableReinitialize = false,
  ...props
}: Props) => {
  const [selectedAction, setSelectedAction] = useState<string | false>(
    () => Array.isArray(action) && action[0].key
  );
  const navigate = useNavigate();
  const moreActions = Array.isArray(action) && action.length > 1;

  const handleSubmit = async (
    values: any,
    { setErrors, setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    try {
      const getAction = () => {
        if (Array.isArray(action)) {
          const submitAction = action.find((a) => a.key === selectedAction);
          if (!submitAction) throw new Error('Invalid action');
          return submitAction.action;
        } else {
          return action;
        }
      };

      const res = await getAction()(sanitizeValues(values));
      if (onSuccess && res) {
        // in case res is undefined
        res.data && typeof res.data === 'string' // if status string
          ? onSuccess({ values })
          : onSuccess(res);
        setSubmitting(false);
      } else {
        // default success navigate back one
        navigate(-1);
      }
      resetForm();
    } catch (error: any) {
      if (error.response) {
        setErrors(error.response.data.errors);
      }
      setSubmitting(false);
    }
  };

  return (
    <Formik
      {...props}
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <Form style={{ height: '100%' }}>
          <SideLayout>
            <SideLayout.Head
              prevUrl={prevUrl}
              onClose={onCancel ?? (() => navigate('..'))}
            >
              {title}
            </SideLayout.Head>

            <SideLayout.Body>{children}</SideLayout.Body>

            <SideLayout.Foot className='p-4'>
              {moreActions && (
                <button
                  type='button'
                  className='border-brand text-brand mb-4 w-full rounded-lg border bg-white p-3 font-semibold capitalize disabled:opacity-50'
                  onClick={() => {
                    setSelectedAction(action[1].key);
                    submitForm();
                  }}
                  disabled={buttonDisabled || isSubmitting}
                >
                  {action[1].buttonText}
                </button>
              )}
              <button
                type='button'
                onClick={() => {
                  setSelectedAction(moreActions && action[0].key);
                  submitForm();
                }}
                className='bg-brand w-full rounded-lg p-3 font-semibold capitalize text-white disabled:opacity-50'
                style={{ boxShadow: '0px 4px 5px #00000029' }}
                disabled={buttonDisabled || isSubmitting}
              >
                {moreActions ? action[0].buttonText : buttonText ?? 'Submit'}
              </button>
              {/* <SubmitButton enabled={!buttonDisabled}>
                {buttonText}
              </SubmitButton> */}
            </SideLayout.Foot>
          </SideLayout>
        </Form>
      )}
    </Formik>
  );
};
