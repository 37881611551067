import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  Children,
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useBreakpoint } from '~/hooks/useBreakpoint';

type Props = {
  children: false | React.ReactElement | (false | React.ReactElement)[];
  /** Index of panel to start open. Default: 0 */
  defaultOpen?: number;
  onToggledPanel?: (idx?: number) => void;
};

type Ref = HTMLDivElement;

type Context = {
  open: number;
  setOpen: React.Dispatch<React.SetStateAction<number>>;
};

type TCardContext = {
  index: number;
  open: boolean;
  toggle: (i?: number) => void;
};

const AccordionContext = createContext<Context>({} as Context);
const CardContext = createContext<TCardContext>({} as TCardContext);

AccordionContext.displayName = 'AccordionContext';
CardContext.displayName = 'CardContext';

const useAccordionContext = () => useContext(AccordionContext);
const useCardContext = () => useContext(CardContext);

function AccordionRoot({ children, defaultOpen = 0, onToggledPanel }: Props) {
  const [open, setOpen] = useState<Context['open']>(0);

  useEffect(() => {
    onToggledPanel && onToggledPanel(open);
  }, [open]);

  useEffect(() => {
    // next tick
    setTimeout(() => {
      setOpen(defaultOpen);
    }, 0);
  }, [defaultOpen]);

  // This ensures the first card is opened by default, even if null children are passed
  useEffect(() => {
    let index = -1;
    Children.forEach(children, (child, i) => {
      if (index < 0 && child) {
        index = i;
        return;
      }
    });
    setOpen(index);
  }, []);

  return (
    <AccordionContext.Provider value={{ open, setOpen }}>
      {Children.map(children, (child, index) =>
        child ? cloneElement(child, { index }) : null
      )}
    </AccordionContext.Provider>
  );
}

const Card = forwardRef<Ref, Props>(
  ({ children, index }: Props & { index?: number }, ref) => {
    const { open, setOpen } = useAccordionContext();

    const toggle = (i?: number) =>
      i != null
        ? setOpen(i)
        : setOpen((x) => (index != null && x !== index ? index : -1));

    return (
      <CardContext.Provider
        value={{ index: index ?? -1, open: index === open, toggle }}
      >
        <div
          ref={ref}
          className='border-1 mb-5 rounded-[10px] border border-grey-20 shadow-[2px_2px_3px_0px_rgba(0,0,0,0.1)]'
        >
          {children}
        </div>
      </CardContext.Provider>
    );
  }
);

function GreyCard({ children, index }: Props & { index?: number }) {
  const { open, setOpen } = useAccordionContext();
  const { isMobile } = useBreakpoint();

  const toggle = (i?: number) =>
    i != null
      ? setOpen(i)
      : setOpen((x) => (index != null && x !== index ? index : -1));

  return (
    <CardContext.Provider
      value={{ index: index ?? -1, open: index === open, toggle }}
    >
      <div
        className={classNames(
          'border-1 rounded-[10px] border border-grey-20 bg-[#f1f4f3] shadow-[2px_2px_3px_0px_rgba(0,0,0,0.1)]',
          isMobile ? 'mb-[13px]' : 'mb-5'
        )}
      >
        {children}
      </div>
    </CardContext.Provider>
  );
}

function GreyButton({
  children,
  help,
  summary,
}: {
  children: React.ReactNode;
  help?: string;
  summary?: React.ReactNode;
}) {
  const { isMobile } = useBreakpoint();
  const { open, toggle } = useCardContext();

  return (
    <motion.button
      type='button'
      className={classNames(
        'flex w-full items-center justify-between gap-4 rounded-[10px] py-5 text-left font-medium text-grey-90',
        isMobile ? 'px-[13px]' : 'px-[21px]',
        open ? 'bg-[#f1f4f3]' : 'bg-white'
      )}
      onClick={() => toggle()}
    >
      {children}
      {!open &&
        (summary ? (
          <span className='truncate font-normal'>{summary}</span>
        ) : (
          <span className='truncate font-normal text-grey-40'>{help}</span>
        ))}
    </motion.button>
  );
}

function Button({
  children,
  help,
  summary,
}: {
  children: React.ReactNode;
  help?: string;
  summary?: React.ReactNode;
}) {
  const { open, toggle } = useCardContext();

  return (
    <motion.button
      type='button'
      className='flex w-full justify-between gap-4 px-[21px] py-5 text-left font-medium text-grey-90'
      onClick={() => toggle()}
    >
      {children}
      {!open &&
        (summary ? (
          <span className='truncate font-normal'>{summary}</span>
        ) : (
          <span className='truncate font-normal text-grey-40'>{help}</span>
        ))}
    </motion.button>
  );
}

function Panel({
  children,
  scroll,
}: {
  children: React.ReactNode | ((next: () => void) => React.ReactNode);
  /** If true sets a max height of the open panel and scrolls if necessary, otherwise should fit content */
  scroll?: boolean;
}) {
  const { index, open, toggle } = useCardContext();
  const { isMobile } = useBreakpoint();
  const next = () => toggle(index + 1);

  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.section
          key='content'
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.25, ease: [0.04, 0.62, 0.23, 0.98] }}
          className={classNames(isMobile ? 'px-4' : 'px-[21px]', {
            'max-h-64 overflow-y-auto': scroll,
          })}
        >
          {typeof children === 'function' ? children(next) : children}
          <div className='flex justify-end'>{/* skip/next buttons */}</div>
        </motion.section>
      )}
    </AnimatePresence>
  );
}

export const Accordion = Object.assign(AccordionRoot, {
  GreyCard,
  GreyButton,
  Card,
  Button,
  Panel,
});
