import { Routes } from 'react-router-dom';
import { Resize } from './ui/resize/Resize';
import styles from './ListDetailView.module.css';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  nav: React.ReactNode;
  actions: React.ReactNode;
  filters: React.ReactNode;
  extra?: React.ReactNode;
  main: React.ReactNode;
  type?: string;
  background?: string;
};

export const ListDetailView = ({
  children,
  nav,
  actions,
  filters,
  extra,
  main,
  type,
  background,
}: Props) => {
  return (
    <>
      {children && <Routes>{children}</Routes>}
      <div className={classNames(styles.parent)}>
        <div className={classNames(styles.side)}>
          <Resize>
            <div className='flex h-full flex-col items-center justify-center overflow-y-auto'>
              <div className='flex flex-col'>{actions}</div>
            </div>
          </Resize>
        </div>
        <div className={classNames(styles.head, 'lg:pt-0')}>{nav}</div>
        <div className={classNames(styles.main)}>
          <div
            className={classNames(styles.box, 'flex flex-col bg-white lg:pl-2')}
          >
            <div className='block flex-none bg-white p-4'>
              {filters}
              {extra}
            </div>
            <div
              className={classNames(
                background,
                'relative h-full overflow-y-auto'
              )}
            >
              {main}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
