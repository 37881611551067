import {
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  useNavigate,
  useSubmit,
} from 'react-router-dom';
import invariant from 'tiny-invariant';
import { client } from '~/client';
import {
  JobTemplateForm,
  unserialize,
} from '~/components/job-template/JobTemplateForm';
import { graphql } from '~/gql';
import { loader } from './job-templates.new';

const UpdateJobTemplateDocument = graphql(`
  mutation UpdateJobTemplate($id: ID!, $input: JobTemplateInput!) {
    updateJobTemplate(id: $id, input: $input) {
      id
    }
  }
`);

export async function action({ params, request }: ActionFunctionArgs) {
  const { templateId: id } = params;

  invariant(id, 'Missing templateId');

  const formData = await request.formData();
  const input = unserialize(formData);

  const { data, error } = await client
    .mutation(UpdateJobTemplateDocument, { id, input })
    .toPromise();

  if (error || !data) {
    throw new Response(error?.message, { status: 500 });
  }

  return redirect(`/job-templates/${data.updateJobTemplate.id}`);
}

export { loader };

export default function EditJobTemplateRoute() {
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const navigate = useNavigate();
  const submit = useSubmit();

  function handleClose(id?: string) {
    navigate(id ? `/job-templates/${id}` : '/job-templates');
  }

  return (
    <JobTemplateForm data={data} onClose={handleClose} onSubmit={submit} />
  );
}
