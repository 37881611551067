import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { TagsBuilder } from '~/components/TagCombobox/TagsBuilder';
import { Picker } from '~/components/nav/Picker';
import { Avatar } from '~/components/ui/Avatar';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { graphql } from '~/gql';
import { JobQuery, JobStatus, Tag } from '~/gql/graphql';
import { formatDate } from '~/helpers/formatDate';
import { SideLayout } from '~/layouts/side/SideLayout';
import { isSameDate } from '../JobHistory';
import { JobMenu } from '../JobMenu';
import { Comment } from './Comment';
import { FormatedDate } from './FormattedDate';
import { MemoGallery } from './Gallery';
import { JobHistory } from './JobHistory';
import { MessageInput } from './MessageInput';

type Props = {
  job: NonNullable<JobQuery['job']>;
  onTabChange: (isFeed: boolean) => void;
  onClose: () => void;
};

const Feed_Query = graphql(/* GraphQL */ `
  query Feed($id: ID!, $last: Int) {
    job(id: $id) {
      id
      status
      comments(last: $last) {
        __typename
        ... on Comment {
          id
          createdAt
          createdBy {
            id
            name
            image
          }
          tags {
            id
            entityType
            category
            name
            description
            colour
          }
        }
        ... on JobHistory {
          id
          status
          taskId
          task {
            id
            name
            notes
            attachments {
              id
              kind
              thumb
              url
              originalFilename
            }
          }
          createdAt
          createdBy {
            id
            name
            image
          }
        }
        ...Feed_Comment
        ...Feed_JobHistory
      }
    }
  }
`);

export function Feed({ job, onTabChange, onClose }: Props) {
  const [searchParams] = useSearchParams();
  const [{ data }, reexecuteQuery] = useQuery({
    query: Feed_Query,
    variables: { id: job.id },
    requestPolicy: 'cache-and-network',
  });

  const ref = useRef<HTMLDivElement>(null);
  const [highlight, setHighlight] = useState(() =>
    searchParams.get('activity')
  );
  const feed = data?.job?.comments;
  const reload = () => {
    reexecuteQuery({ requestPolicy: 'cache-and-network' });
    setHighlight(null);
  };

  useEffect(() => {
    setHighlight(searchParams.get('activity'));
  }, [searchParams]);

  // Find nearest scrollable parent and scroll to bottom
  useEffect(() => {
    const scrollable = ref.current?.closest('.overflow-y-auto');
    if (scrollable) {
      // Find the element to scroll to
      const element =
        highlight && scrollable.querySelector(`[data-activity="${highlight}"]`);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }

      // Scroll to bottom
      scrollable.scrollTop = scrollable.scrollHeight;
    }
  }, [data, highlight]);

  // const [showMenu, setShowMenu] = useState<string | null>(null);

  return (
    <SideLayout noScroll={true}>
      <SideLayout.Head
        rightSlot={
          job.permissions.includes('edit') ? (
            <JobMenu job={job} />
          ) : // <GeneratePdfButton />
          null
        }
        onClose={onClose}
      >
        <div className='flex w-full flex-col items-center'>
          <h5 className='leading-5 text-[#031121]'>
            {job.jobNumber} - {job.name}
          </h5>

          <div className='mb-2 mt-[5px] flex text-base font-medium leading-[17px] text-[#005959]'>
            <span className='text-[#7F909C]'>For</span>
            <span className='ml-[3px]'>{job.owner.name}</span>
          </div>

          <StatusBadge value={job.status} />
        </div>
      </SideLayout.Head>

      <div className='px-4 py-[2px] print:hidden'>
        <Picker value='messages' className='rounded-full'>
          <Picker.Button
            id='details'
            onClick={() => onTabChange(false)}
            className='rounded-l-full'
          >
            Details
          </Picker.Button>
          <Picker.Button
            id='messages'
            onClick={() => onTabChange(true)}
            className='rounded-r-full'
          >
            Messages & History
          </Picker.Button>
        </Picker>
      </div>

      <SideLayout.Body ref={ref} className='flex-1 overflow-y-auto'>
        {feed?.map((item, i) => (
          <div key={item.id} className='px-5' data-activity={item.id}>
            {i > 0 ? (
              !isSameDate(item.createdAt, feed[i - 1].createdAt) && (
                <FormatedDate
                  date={item.createdAt}
                  className='sticky top-0 bg-white py-1 text-center text-xs font-semibold text-grey-70'
                />
              )
            ) : (
              <FormatedDate
                date={item.createdAt}
                className='sticky top-0 bg-white py-1 text-center text-xs font-semibold text-grey-70'
              />
            )}
            {item.__typename === 'JobHistory' && <JobHistory item={item} />}
            {(item.__typename === 'Comment' ||
              (item.task &&
                item.status === JobStatus.Complete &&
                (item.task?.notes || item.task?.attachments?.length))) && (
              <div
                className='my-6 flex items-start gap-[10px]'
                // onMouseOver={() => setShowMenu(item.id)}
                // onMouseOut={() => setShowMenu(null)}
              >
                <div className='h-7 w-7'>
                  <Avatar
                    size='full'
                    url={item.createdBy?.image}
                    name={item.createdBy?.name ?? 'Automation'}
                  />
                </div>
                <div className='flex flex-auto flex-col gap-[3px] pr-5'>
                  <div className='flex gap-[7px]'>
                    <span className='text-sm font-semibold leading-[17.5px] text-[#272930]'>
                      {item.createdBy?.name ?? 'Automation'}
                    </span>
                    <span className='flex flex-col justify-end self-end text-2xs font-normal leading-[14.8px] text-[#646872]'>
                      {formatDate(item.createdAt, 'h:mm a')}
                    </span>
                  </div>
                  {item.__typename === 'JobHistory' && (
                    <div className='mb-4 flex flex-col items-start justify-center gap-[7px] border-l-2 border-[#008F90] bg-[#EEEFF0] px-3.5 py-2.5'>
                      {item.__typename === 'JobHistory' &&
                        item.task &&
                        item.status === JobStatus.Complete && (
                          <>
                            <TaskBadge name={item.task.name} />
                            {item.task.notes && (
                              <p className='px-2 py-1'>{item.task.notes}</p>
                            )}
                            {item.task.attachments && (
                              <div className='px-2 pt-1'>
                                <MemoGallery
                                  attachments={item.task.attachments}
                                />
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  )}
                  {item.__typename === 'Comment' && (
                    <>
                      <Comment
                        comment={item}
                        highlight={highlight === item.id}
                      />
                      <TagsBuilder tags={item.tags as Tag[]} />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </SideLayout.Body>

      <SideLayout.Foot className='!border-t-0'>
        <MessageInput jobId={job.id} onSuccess={reload} />
      </SideLayout.Foot>
    </SideLayout>
  );
}

export function TaskBadge({ name }: { name: string }) {
  return (
    <div className='flex items-center gap-1 rounded-sm border border-grey-20 px-[5px] py-0.5 text-[#81848F]'>
      <span className='h-[15px] w-[15px]'>
        <svg
          width='15'
          height='16'
          viewBox='0 0 15 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.875 2.375H3.125C2.4375 2.375 1.875 2.9375 1.875 3.625V12.375C1.875 13.0625 2.4375 13.625 3.125 13.625H11.875C12.5625 13.625 13.125 13.0625 13.125 12.375V3.625C13.125 2.9375 12.5625 2.375 11.875 2.375ZM6.69375 10.6813C6.45 10.925 6.05625 10.925 5.8125 10.6813L3.56875 8.4375C3.325 8.19375 3.325 7.8 3.56875 7.55625C3.8125 7.3125 4.20625 7.3125 4.45 7.55625L6.25 9.35625L10.55 5.05625C10.7937 4.8125 11.1875 4.8125 11.4313 5.05625C11.675 5.3 11.675 5.69375 11.4313 5.9375L6.69375 10.6813Z'
            fill='#81848F'
          />
        </svg>
      </span>
      <span className='text-xs leading-[18px]'>{name}</span>
    </div>
  );
}
