import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import {
  AutomationForm,
  FormValues,
} from '~/components/automation/AutomationForm/AutomationForm';
import { graphql } from '~/gql';
import { AutomationInput } from '~/gql/graphql';

export const AutomationFormDocument = graphql(`
  query AutomationForm($id: ID) {
    contacts(status: Active) {
      ...ContactFields
    }
    jobTemplates(status: Active) {
      value: id
      label: name
    }
    automation(id: $id) {
      id
      status
      name
      on
      whereType
      matchAttribute {
        key: name
        value
      }
      sites {
        id
        name
        image
      }
      jobName
      template {
        id
      }
      owners {
        id
      }
      assignees {
        id
      }
      day
      time
    }
  }
`);

const CreateAutomationDocument = graphql(`
  mutation CreateAutomation($input: AutomationInput!) {
    createAutomation(input: $input)
  }
`);

export function formToInput(data: FormValues): AutomationInput {
  return {
    ...data,
    matchAttribute: data.matchAttribute.map(({ key, value }) => ({
      name: key,
      value,
    })),
    sites: data.sites.map(({ id }) => id),
    runOnExistingBookings: data.runOnExistingBookings === 'true',
  };
}

export function Component() {
  const navigate = useNavigate();
  const params = useParams();

  const [, createAutomation] = useMutation(CreateAutomationDocument);
  const [result] = useQuery({
    query: AutomationFormDocument,
    variables: { id: params.automationId },
    requestPolicy: 'network-only',
  });

  if (!result.data) {
    return null;
  }

  return (
    <AutomationForm
      data={result.data}
      onSubmit={async (data) => {
        const res = await createAutomation({
          input: formToInput(data),
        });

        const id = res.data?.createAutomation;
        if (id) {
          return navigate(`/automations/${res.data?.createAutomation}`);
        }
      }}
    />
  );
}
