import { get } from 'lodash';
import naturalCompare from 'string-natural-compare';

type AnyObject = {
  [x: string]: any;
};

/**
 * Returns a sort function that compares the values of an object at given path
 */
export const byNaturalValue = (path: string) => (a: AnyObject, b: AnyObject) =>
  naturalCompare(get(a, path, '') ?? '', get(b, path, '') ?? '', {
    caseInsensitive: true,
  });
