import { SVGProps } from 'react'

const SvgNounRotatePhone2620512 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 1200 1200' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g>
      <path d='m363.12 252.09h-281.3c-13.738 0.011718-26.91 5.4727-36.625 15.188-9.7148 9.7148-15.18 22.887-15.191 36.625v592.2c0.011719 13.738 5.4766 26.91 15.191 36.625 9.7148 9.7148 22.887 15.176 36.625 15.188h281.3c13.738-0.011718 26.91-5.4727 36.625-15.188 9.7148-9.7148 15.176-22.887 15.191-36.625v-592.2c-0.015625-13.738-5.4766-26.91-15.191-36.625-9.7148-9.7148-22.887-15.176-36.625-15.188zm-303.52 111.02h325.73v458.98h-325.73zm22.211-81.422h281.3c5.8867 0.007812 11.535 2.3477 15.699 6.5117 4.1641 4.1641 6.5039 9.8125 6.5117 15.699v29.605h-325.73v-29.605c0.003906-5.8867 2.3477-11.535 6.5117-15.699 4.1641-4.1641 9.8086-6.5039 15.699-6.5117zm281.3 636.62h-281.3c-5.8906-0.007812-11.535-2.3477-15.699-6.5117-4.1641-4.1641-6.5078-9.8125-6.5117-15.699v-44.398l325.73-0.003906v44.402c-0.007812 5.8867-2.3477 11.535-6.5117 15.699-4.1641 4.1641-9.8125 6.5039-15.699 6.5117z' />
      <path d='m222.48 862.8c-5.8867-0.003906-11.535 2.332-15.703 6.4922-4.1641 4.1602-6.5078 9.8086-6.5078 15.695-0.003906 5.8867 2.332 11.535 6.4922 15.699 4.1602 4.168 9.8047 6.5078 15.695 6.5117 5.8867 0.003906 11.535-2.332 15.699-6.4922 4.168-4.1602 6.5078-9.8086 6.5117-15.695-0.007813-5.8828-2.3438-11.527-6.5039-15.691-4.1602-4.1641-9.8008-6.5078-15.684-6.5195z' />
      <path d='m1118.2 562.99h-592.21c-13.738 0.011718-26.906 5.4727-36.621 15.188-9.7148 9.7109-15.18 22.879-15.195 36.617v281.3c0.011719 13.738 5.4766 26.91 15.191 36.625 9.7148 9.7148 22.887 15.176 36.625 15.188h592.21c13.738-0.011718 26.91-5.4727 36.625-15.188 9.7148-9.7148 15.18-22.887 15.191-36.625v-281.3c-0.015625-13.738-5.4805-26.906-15.195-36.617-9.7148-9.7148-22.883-15.176-36.621-15.188zm-59.207 29.605v325.71h-458.98v-325.71zm-555.21 303.5v-281.3c0.003906-5.8906 2.3477-11.535 6.5117-15.695 4.1641-4.1641 9.8125-6.5039 15.699-6.5039h44.398v325.71h-44.398c-5.8906-0.007812-11.535-2.3477-15.699-6.5117-4.1641-4.1641-6.5078-9.8125-6.5117-15.699zm636.64 0h-0.003907c-0.003906 5.8867-2.3477 11.535-6.5117 15.699-4.1641 4.1641-9.8086 6.5039-15.699 6.5117h-29.605v-325.71h29.605c5.8867 0 11.535 2.3398 15.699 6.5039 4.1641 4.1602 6.5078 9.8047 6.5117 15.695z' />
      <path d='m537.07 733.2c-5.8906 0-11.539 2.3398-15.707 6.5078-4.1641 4.168-6.5039 9.8164-6.5039 15.707 0 5.8945 2.3438 11.543 6.5117 15.707 4.168 4.168 9.8164 6.5039 15.707 6.5039 5.8945-0.003906 11.543-2.3477 15.707-6.5156 4.1641-4.168 6.5039-9.8164 6.5-15.711-0.007812-5.8867-2.3516-11.531-6.5156-15.695-4.1641-4.1602-9.8086-6.5-15.699-6.5039z' />
      <path d='m488.96 281.69c34.352 0.027344 67.293 13.68 91.59 37.965 24.297 24.289 37.969 57.223 38.008 91.574v42l-16.641-16.617c-3.7422-3.7383-9.1875-5.1992-14.297-3.832-5.1055 1.3672-9.0938 5.3594-10.465 10.465-1.3672 5.1055 0.09375 10.555 3.832 14.293l41.867 41.879c1.3711 1.375 3.0039 2.4609 4.8008 3.1953 2.707 1.1289 5.6914 1.4297 8.5742 0.86328 2.8789-0.57031 5.5273-1.9805 7.6016-4.0586l41.867-41.879c3.5625-3.7656 4.8906-9.125 3.5039-14.121-1.3867-4.9961-5.2891-8.8984-10.281-10.293-4.9961-1.3906-10.352-0.070313-14.125 3.4883l-16.609 16.609v-42.004c-0.054687-42.203-16.848-82.664-46.703-112.5-29.852-29.836-70.32-46.605-112.52-46.633-4-0.11719-7.875 1.3945-10.742 4.1797-2.8711 2.7891-4.4883 6.6211-4.4883 10.621s1.6172 7.8281 4.4883 10.617c2.8672 2.7891 6.7422 4.2969 10.742 4.1836z' />
    </g>
  </svg>
)

export default SvgNounRotatePhone2620512

