import { useEffect } from 'react';

export const useTitle = (title: string | undefined) => {
  useEffect(() => {
    const defaultTitle = document.title;

    if (title) {
      document.title = `${title} | ${defaultTitle}`;
    }

    return () => {
      document.title = defaultTitle;
    };
  }, [title]);
};
