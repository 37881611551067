import { createSpaces } from '~/api/assets';
import { ActionForm } from '~/components/form/ActionForm';
import { LocationField } from '~/components/form/LocationField';
import { TagsField } from '~/components/form/tagify/TagsField';
import * as Yup from 'yup';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

export function AddSpaces({ onClose, onSuccess }: Props) {
  return (
    <ActionForm
      action={createSpaces}
      title='Add Spaces'
      buttonText='Add'
      initialValues={{ location: { type: 'Asset', id: [] }, spaceName: [] }}
      validationSchema={Yup.object({
        location: Yup.object({
          type: Yup.string().required('Required'),
          id: Yup.array()
            .of(Yup.string())
            .min(1, 'Required')
            .required('Required'),
        }),
        spaceName: Yup.array()
          .of(Yup.string())
          .min(1, 'Required')
          .required('Required'),
      })}
      onCancel={onClose}
      onSuccess={onSuccess}
    >
      <LocationField title='Location Type' multiple />
      <TagsField name='spaceName' label='Space Name' />
    </ActionForm>
  );
}
