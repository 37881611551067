import { faAirbnb } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLoaderData } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { client } from '~/client';
import { graphql } from '~/gql';
import { useTitle } from '~/hooks';
import SvgGuesty from '~/icons/Guesty';

const ListIntegrationsDocument = graphql(`
  query ListIntegrations {
    integrations
  }
`);

export async function loader() {
  const result = await client.query(ListIntegrationsDocument, {});
  return result.data?.integrations ?? null;
}

export default function IntegrationsRoute() {
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  useTitle('Integrations');

  invariant(data, 'Expected integrations data');

  return (
    <div className='mx-auto mt-4 max-w-screen-md px-4 md:px-0'>
      <h1 className='text-copy-head self-start pt-6 pb-3 text-xl font-bold'>
        Integrations
      </h1>
      {data.length > 0 && (
        <>
          <h2 className='text-copy-head self-start pt-6 pb-3 font-bold'>
            Active
          </h2>

          {data.includes('guesty') && (
            <div // Link
              // to='guesty'
              className='border-gray88 -mt-[1px] flex w-full cursor-pointer items-center justify-between rounded border bg-white px-6 py-2'
            >
              <div className='mr-3 text-3xl'>
                <SvgGuesty />
              </div>
              <div className='mr-auto flex flex-col items-start justify-center'>
                <h3 className='text-copy-head self-start text-sm font-bold'>
                  Guesty
                </h3>
                <p className='text-copy-muted pb-0.5 text-left text-xs opacity-80'>
                  Connect your Guesty account
                </p>
              </div>
              <div>Connected!</div>
            </div>
          )}
        </>
      )}
      {data.length < 1 && (
        <>
          <h2 className='text-copy-head self-start pt-6 pb-3 font-bold'>
            Available
          </h2>
          {/* <Airbnb /> */}
          {!data.includes('guesty') && <Guesty />}
          {/* <Homhero /> */}
        </>
      )}
    </div>
  );
}

function Airbnb() {
  return (
    <Link
      to='airbnb'
      className='border-gray88 -mt-[1px] flex w-full cursor-pointer items-center justify-between rounded border bg-white px-6 py-2'
    >
      <div className='mr-3 text-3xl' style={{ color: '#ff385c' }}>
        <FontAwesomeIcon icon={faAirbnb} />
      </div>
      <div className='mr-auto flex flex-col items-start justify-center'>
        <h3 className='text-copy-head self-start text-sm font-bold'>Airbnb</h3>
        <p className='text-copy-muted pb-0.5 text-left text-xs opacity-80'>
          Connect your host calendar to view check-in/check-out in schedule.
        </p>
      </div>
      <button
        type='button'
        className='bg-brand flex items-center rounded px-4 py-2 text-white'
      >
        <div>Setup</div>
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke-width='2'
            stroke='currentColor'
            aria-hidden='true'
            className='ml-2 h-5'
          >
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M9 5l7 7-7 7'
            ></path>
          </svg>
        </div>
      </button>
    </Link>
  );
}

function Guesty() {
  return (
    <Link
      to='guesty'
      className='border-gray88 -mt-[1px] flex w-full cursor-pointer items-center justify-between rounded border bg-white px-6 py-2'
    >
      <div className='mr-3 text-3xl'>
        <SvgGuesty />
      </div>
      <div className='mr-auto flex flex-col items-start justify-center'>
        <h3 className='text-copy-head self-start text-sm font-bold'>Guesty</h3>
        <p className='text-copy-muted pb-0.5 text-left text-xs opacity-80'>
          Connect your Guesty account
        </p>
      </div>
      <button
        type='button'
        className='bg-brand flex items-center rounded px-4 py-2 text-white'
      >
        <div>Setup</div>
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke-width='2'
            stroke='currentColor'
            aria-hidden='true'
            className='ml-2 h-5'
          >
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M9 5l7 7-7 7'
            ></path>
          </svg>
        </div>
      </button>
    </Link>
  );
}

function Homhero() {
  return (
    <Link
      to='homhero'
      className='border-gray88 -mt-[1px] flex w-full cursor-pointer items-center justify-between rounded border bg-white px-6 py-2'
    >
      <div className='mr-3'>
        <img className='w-20' src='/homhero.png' alt='Homhero' />
      </div>
      <div className='mr-auto flex flex-col items-start justify-center'>
        <h3 className='text-copy-head self-start text-sm font-bold'>Homhero</h3>
        <p className='text-copy-muted pb-0.5 text-left text-xs opacity-80'>
          Connect your homhero account to sync your places and bookings.
        </p>
      </div>
      <button
        type='button'
        className='bg-brand flex items-center rounded px-4 py-2 text-white'
      >
        <div>Setup</div>
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke-width='2'
            stroke='currentColor'
            aria-hidden='true'
            className='ml-2 h-5'
          >
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M9 5l7 7-7 7'
            ></path>
          </svg>
        </div>
      </button>
    </Link>
  );
}
