import { Tab } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  SiteInput,
  useSiteQuery,
  useUpdateSiteMutation,
} from '~/generated/graphql';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { SideLayout } from '~/layouts/side/SideLayout';
import { AssetsList } from '../sites/tabs/AssetsTab';
import { AttributesList } from '../sites/tabs/AttributesList';
import { JobsList } from '../sites/tabs/JobsTab';
import { StockTab } from '../sites/tabs/StockTab';
import { AddressLink } from '../ui/Address';
import { BuildingPlaceholder } from '../ui/BuildingPlaceholder';
import { Gallery } from '../ui/Gallery';
import { Loading } from '../ui/Loading';
import { TabHeader } from '../ui/TabHeader';

type Props = {
  siteId: string;
  onClose: () => void;
  isPopup?: boolean;
};

export function SiteView({ siteId, onClose, isPopup }: Props) {
  let [result, reloadSite] = useSiteQuery({
    requestPolicy: 'cache-and-network',
    variables: { id: siteId },
  });

  const [, update] = useUpdateSiteMutation();
  const [isSearchSticked, setIsSearchSticked] = useState(false);
  const { isMobile } = useBreakpoint();
  const containerEdge = isMobile ? 0 : 161;

  useEffect(() => {
    const scrollContainer = document.querySelector('#site-view-container');
    if (!scrollContainer) return;

    const onScroll = () => {
      const searchEl = scrollContainer?.querySelector('#search-attributes');
      if (!searchEl) return;

      const { top } = searchEl.getBoundingClientRect();
      const visible = isMobile ? top === containerEdge : top <= containerEdge;
      setIsSearchSticked(visible);
    };

    scrollContainer.addEventListener('scroll', onScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', onScroll);
    };
  }, []);

  const site = result.data?.site;
  if (!site) {
    return <Loading />;
  }

  const address = site.address && site.address !== '{}' && (
    <AddressLink data={site.address} longFormat />
  );

  const handleUpdateSite = async (input: SiteInput) => {
    if (!siteId) return;

    const response = await update({ id: siteId, input });

    if (response.error) {
      toast.error(response.error.message.replace('[GraphQL] ', ''));
      return;
    }

    reloadSite();
  };

  return (
    <SideLayout isPopup={isPopup} id='site-view-container'>
      {!isPopup && (
        <div className='block'>
          <SideLayout.Head
            onClose={onClose}
            rightSlot={
              <Link
                className='ml-1 mt-2.5 flex h-9 w-9 cursor-pointer items-center justify-center rounded-full hover:bg-grey-10'
                to='edit'
              >
                <PencilIcon className='m-2 h-5 text-secondary' />
              </Link>
            }
          >
            {site.name}
          </SideLayout.Head>
        </div>
      )}
      <SideLayout.Body className='flex flex-col gap-4 px-4 py-2'>
        <div className='flex gap-5 rounded-md border border-grey-20 p-3'>
          <div className={classNames('h-32 w-32 overflow-hidden rounded ')}>
            {site.image ? (
              <Gallery size='fit' images={site.image} />
            ) : (
              <BuildingPlaceholder className='text-5xl' />
            )}
          </div>

          <div className='flex flex-col gap-2'>
            <p className='text-tertiary'>{site.type}</p>
            {address && address}

            {site.licensor?.name && (
              <div className='flex flex-col gap-0'>
                <p className='text-sm text-tertiary'>Owner</p>
                <p className=''>{site.licensor?.name}</p>
              </div>
            )}
          </div>
        </div>

        <div className='w-auto rounded-t-2xl border border-grey-20'>
          <Tab.Group>
            <Tab.List className='bg-blue-900/20 flex overflow-hidden rounded-t-2xl'>
              <TabHeader title='Attributes' />
              <TabHeader title='Jobs' />
              <TabHeader title='Items' />
              <TabHeader title='Assets' />
            </Tab.List>
            <Tab.Panels className=''>
              <Tab.Panel className='relative flex min-h-[20rem] flex-col bg-grey-2'>
                <AttributesList
                  attributes={site.attributes?.filter(
                    ({ id }) => !id.startsWith('integration_')
                  )}
                  // @ts-expect-error FIXME
                  integrationAttributes={site.integrationAttributes}
                  integrationName={result.data?.integrations?.[0]}
                  onUpdate={handleUpdateSite}
                  isSearchSticked={isSearchSticked}
                  siteId={siteId}
                  hideEdit={isPopup}
                />
              </Tab.Panel>
              <Tab.Panel className='relative flex min-h-[20rem] flex-col bg-grey-2'>
                <JobsList siteId={siteId} />
              </Tab.Panel>
              <Tab.Panel className='relative flex min-h-[20rem] flex-col bg-grey-2'>
                <StockTab siteId={siteId} />
              </Tab.Panel>
              <Tab.Panel className='relative flex min-h-[20rem] flex-col bg-grey-2'>
                <AssetsList siteId={siteId} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </SideLayout.Body>
    </SideLayout>
  );
}
