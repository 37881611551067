import classNames from 'classnames';
import { MouseEvent, useEffect, useRef } from 'react';
import { CheckboxState } from '.';

type Props = {
  value: CheckboxState;
  onClick?: (event: MouseEvent, isChecked: CheckboxState) => void;
  disabled?: boolean;
  className?: string;
};

export function Checkbox({
  value,
  onClick,
  className,
  disabled = false,
}: Props) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const checkbox = ref.current;

    if (!checkbox) return;

    if (value === CheckboxState.CHECKED) {
      checkbox.checked = true;
      checkbox.indeterminate = false;
    } else if (value === CheckboxState.INDETERMINATE) {
      checkbox.checked = false;
      checkbox.indeterminate = true;
    } else if (value === CheckboxState.UNCHECKED) {
      checkbox.checked = false;
      checkbox.indeterminate = false;
    }
  }, [value]);

  return (
    <input
      ref={ref}
      checked={value === CheckboxState.CHECKED}
      onClick={(e) => {
        onClick &&
          onClick(
            e,
            value === CheckboxState.CHECKED
              ? CheckboxState.UNCHECKED
              : CheckboxState.CHECKED
          );
      }}
      type='checkbox'
      className={classNames('form-checkbox', className)}
      disabled={disabled}
      readOnly
    />
  );
}
