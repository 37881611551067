import { cva, type VariantProps } from 'class-variance-authority';
import classNames from 'classnames';
import { createElement } from 'react';
import styles from '~/layouts/nav/Nav.module.css';

type NavbarProps = {
  children?: React.ReactNode;
  className?: string;
} & VariantProps<typeof navbar>;

type ItemProps = {
  as?: string | React.ComponentType<any>;
  children?: React.ReactNode;
  className?: string;
  [x: string]: any;
};

const navbar = cva('', {
  variants: {
    intent: {
      primary: [
        'bg-blue-500',
        'text-white',
        'border-transparent',
        'hover:bg-blue-600',
      ],
      secondary: [
        'bg-white',
        'text-gray-800',
        'border-gray-400',
        'hover:bg-gray-100',
      ],
    },
    size: {
      small: ['text-sm', 'py-1', 'px-2'],
      medium: ['text-base', 'py-2', 'px-4'],
    },
  },
  compoundVariants: [
    { intent: 'primary', size: 'medium', className: 'uppercase' },
  ],
  defaultVariants: {
    intent: 'primary',
    size: 'medium',
  },
});

function Item({ as = 'a', children, className, ...props }: ItemProps) {
  const itemClass = [
    'flex items-center',
    'px-4 pt-1 pb-0 lg:pb-0.5',
    'h-16 lg:h-[65px] lg:px-5',
    'border-b-4 border-transparent',
    'text-copy-nav',
    'hover:border-current',
    'whitespace-nowrap',
    'transition-colors duration-300 ease-in-out',
    className,
  ].join(' ');

  return createElement(as, { className: itemClass, ...props }, children);
}

export function Navbar({ children, className, ...props }: NavbarProps) {
  return (
    <nav
      className={classNames([
        styles.subnav,
        'flex grow items-stretch',
        'overflow-x-auto overflow-y-hidden',
        'z-10 bg-white',
        'lg:pl-16 xl:pl-24',
        className,
      ])}
    >
      {children}
    </nav>
  );
}

Navbar.Item = Item;
