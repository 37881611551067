import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from 'urql';
import * as Yup from 'yup';
import { ActionForm } from '~/components/form/ActionForm';
import { ErrorMessage } from '~/components/ui/Error';
import { UploadField } from '~/components/upload/Upload';
import { DeploymentsQuery } from '~/generated/graphql';

type Props = {
  deployments: DeploymentsQuery['deployments'];
};

export const EditDeploymentForm = ({ deployments }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [, updateDeployment] = useMutation(UPDATE_DEPLOYMENT_MUTATION);

  const deployment = deployments.find((d) => d.id === id);

  if (!deployment) {
    return <ErrorMessage message='Not found' />;
  }

  return (
    <ActionForm
      action={async ({ images }) => {
        return await updateDeployment({ id, images });
      }}
      title='Edit Deployment'
      buttonText='Submit'
      initialValues={{
        images: deployment.images,
      }}
      validationSchema={Yup.object().shape({
        images: Yup.array().of(Yup.string()).required(),
      })}
      onSuccess={() =>
        navigate(`/deployments/${id}`, { state: { reload: true } })
      }
    >
      <div className='flex flex-col p-4 text-center'>
        <UploadField name='images' />
      </div>
    </ActionForm>
  );
};

const UPDATE_DEPLOYMENT_MUTATION = gql`
  mutation UpdateDeployment($id: ID!, $images: [String!]!) {
    updateDeployment(id: $id, images: $images)
  }
`;
