import localforage from 'localforage';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useMutation, useQuery } from 'urql';
import { Button } from '~/components/form/SubmitButton';
import { ReadOnlyEditor } from '~/components/form/editor/ReadOnlyEditor';
import { Confirm } from '~/components/ui/Confirm';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { getFragmentData, graphql } from '~/gql';
import {
  AttributeTaskFragmentDoc,
  JobDocument,
  JobStatus,
  TaskDataFragmentDoc,
} from '~/gql/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { Contact } from '~/routes/resources/contacts';
import { WorkflowButtons } from '../../WorkflowButtons';
import { AttributeAuditChangeFn } from './AttributeAuditForm';
import { AttributeAuditLocation } from './AttributeAuditLocation';
import { AllAttributes } from './types';

type Props = {
  allAttributes: AllAttributes;
  contacts: Contact[];
  onClose: () => void;
};

const CompleteAttributeAuditTask = graphql(`
  mutation CompleteAttributeAuditTask($input: CompleteAttributeTaskInput!) {
    completeAttributeAuditTask(input: $input) {
      id
      status
      ...AttributeTask
    }
  }
`);

/**
 * Remake of AttributeTask
 */
export function AttributeAuditTask({
  allAttributes,
  contacts,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const { jobId, taskId } = useParams();
  const [{ fetching: submitting }, completeTask] = useMutation(
    CompleteAttributeAuditTask
  );

  // True if any of the form values have changed
  const [dirty, setDirty] = useState(false);

  // Whether or not to show the discard changes confirmation dialog
  const [confirm, setConfirm] = useState(false);

  // TODO: ref or state a better idea?
  const formValues = useRef<Parameters<AttributeAuditChangeFn>[0]['values']>();

  invariant(jobId, 'jobId is required');
  invariant(taskId, 'taskId is required');

  const [{ data, error, fetching }] = useQuery({
    query: JobDocument,
    variables: { id: jobId },
    // TODO requestPolicy: 'cache-only',
  });

  const locationId = data?.job?.location ? data.job.location.id : null;
  const taskData = data?.job?.tasks.find(({ id }) => id === taskId);

  // Not an ideal usage of fragment masking but will make more sense when refactoring other tasks later...
  const job = data?.job;
  const task = getFragmentData(TaskDataFragmentDoc, taskData);
  const attributeAudit = getFragmentData(
    AttributeTaskFragmentDoc,
    task
  )?.attribute;

  const enabled =
    job?.status === JobStatus.InProgress && task?.status === JobStatus.Created;
  // TODO hide on scroll down, scroll up to show?
  const showButtons =
    job?.status === JobStatus.Accepted || job?.status === JobStatus.InProgress; // !enabled || dirty; // show or hide the task footer?

  const taskValues = attributeAudit?.attributes.reduce<Record<string, string>>(
    (prev, id) => {
      const savedField = attributeAudit.formValues?.find((v) => v.name === id);
      return { ...prev, [id]: savedField ? savedField.value[0] : '' };
    },
    {}
  );

  // Wrap the descripton in read only editor
  const description = task?.description && (
    <ReadOnlyEditor content={task.description} />
  );

  function handleClose() {
    // We will keep the cache if the task is deferred
    const taskDeferred = task?.status === JobStatus.Incomplete;

    dirty && !taskDeferred ? setConfirm(true) : onClose();
  }

  async function removeAndClose() {
    if (!task) return;

    // Clean up the offline storage
    await localforage.removeItem(`Task:${task.id}:Form`);

    // Close the task
    onClose();
  }

  async function handleSubmit() {
    const values = formValues.current;
    if (!(task && values)) {
      return;
    }
    const attributes = Object.keys(values).map((id) => ({
      name: id,
      value: [values[id]],
    }));

    await completeTask({ input: { id: task.id, attributes } });
    await removeAndClose();
  }

  if (fetching) {
    return <Loading spinner />;
  }

  if (!(job && task)) {
    return <ErrorMessage message='Task not found' />;
  }

  if (!attributeAudit) {
    return (
      <ErrorMessage message='No attribute audit found or task misconfigured' />
    );
  }

  if (!locationId) {
    return <>The job must have a location</>;
  }

  return (
    <SideLayout className='mx-auto lg:max-w-[828px] lg:border-r lg:h-screen'>
      {confirm && (
        <Confirm
          onCancel={() => setConfirm(false)}
          onConfirm={removeAndClose}
        />
      )}
      <SideLayout.Head onClose={handleClose}>{task.name}</SideLayout.Head>
      <SideLayout.Body className='p-4 pb-64'>
        {task.status !== JobStatus.Created && (
          <StatusBadge prefix={t('job:task')} value={task.status} />
        )}
        <ReadValue label={t('description')}>{description}</ReadValue>
        <AttributeAuditLocation
          locationId={locationId}
          allAttributes={allAttributes}
          contacts={contacts}
          overrides={taskValues}
          storageKey={`Task:${taskId}:Form`}
          readMode={!enabled}
          onChange={({ dirty, values }) => {
            setDirty(dirty);
            formValues.current = values;
          }}
        />
      </SideLayout.Body>
      <SideLayout.Foot className='p-4' show={showButtons}>
        <WorkflowButtons
          id={task.id}
          jobStatus={job.status}
          taskStatus={task.status}
          // onReset={() => {}}
          startJobId={job.id}
        >
          <CompleteTaskButton onClick={handleSubmit} />
        </WorkflowButtons>
      </SideLayout.Foot>
    </SideLayout>
  );
}

function CompleteTaskButton({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation('job');

  return (
    <Button type='button' onClick={onClick}>
      {t('completeTask')}
    </Button>
  );
}
