import classNames from 'classnames';
import { Avatar } from '../Avatar';

export type AvatarsStackProps = {
  list: { name: string; image?: string | null }[];
  isMobile?: boolean;
};

export function AvatarsStack({ list, isMobile = false }: AvatarsStackProps) {
  const isEqualsToTwo = list.length === 2;
  const isMoreThanTwo = list.length > 2;
  const filteredList = isMoreThanTwo ? Array(list[0]) : list;
  const excessCount = list.length - filteredList.length;

  const avatarContainerStyle = classNames(
    'rounded-full',
    !isMobile && '-ml-[5px] bg-white p-[1px]'
  );

  return (
    <div className='flex w-fit'>
      {isMobile && list.length > 1 ? (
        <></>
      ) : (
        filteredList.map((contact, i) => (
          <div
            key={i}
            className={avatarContainerStyle}
            style={{ zIndex: list.length - i }}
          >
            <Avatar name={contact.name} image={contact.image} size='medium' />
          </div>
        ))
      )}
      {((isMobile && isEqualsToTwo) || isMoreThanTwo) && (
        <div className={avatarContainerStyle}>
          <div className='flex h-6 w-6 items-center justify-center rounded-full bg-grey-20 text-xs font-medium text-secondary lg:h-8 lg:w-8 lg:text-sm'>
            <p>+{isMobile ? list.length : excessCount}</p>
          </div>
        </div>
      )}
    </div>
  );
}
