import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { AutomationView } from '~/components/automation/AutomationView/AutomationView';
import { ErrorMessage } from '~/components/ui/Error';
import { ListAutomationsDocument } from './automations._index';

export function Component() {
  const navigate = useNavigate();
  const params = useParams();
  const [result] = useQuery({
    query: ListAutomationsDocument,
    requestPolicy: 'cache-and-network',
  });
  const { data } = result;

  const automation = data?.automations.find(
    (automation) => automation.id === params.automationId
  );

  if (!automation) {
    return (
      <ErrorMessage
        message='Automation not found'
        onBack={() => navigate('/automations')}
      />
    );
  }

  return <AutomationView automation={automation} />;
}
