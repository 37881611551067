import { faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useParams } from 'react-router-dom';
import { client } from '~/client';
import { AuthGate } from '~/components/AuthGate';
import { Split } from '~/components/Split';
import { CardNew } from '~/components/ui/CardNew';
import { FilterGroupSearch } from '~/components/ui/FilterGroupSearch';
import { graphql } from '~/gql';
import { ListAutomationsQuery, Status } from '~/gql/graphql';
import { useSearch } from '~/hooks/useSearch';
import { MoreNav } from '~/layouts/nav/MoreNav';

const SEARCH_OPTIONS = {
  keys: ['name', 'on', 'template.name', 'assignee.name'],
};

export const ListAutomationsDocument = graphql(`
  query ListAutomations {
    automations {
      id
      status
      name
      on
      whereType
      matchAttribute {
        key: name
        value
      }
      sites {
        id
        name
        image
      }
      do
      jobName
      template {
        id
        name
        tasks {
          id
          type
          name
          description
        }
      }
      owners {
        id
        name
        image
      }
      assignees {
        id
        name
        image
      }
      day
      time
      conditions {
        type
        operator
        nights
      }
      repeatUntilOffset
    }
  }
`);

export async function loader() {
  const { data } = await client
    .query(ListAutomationsDocument, {}, { requestPolicy: 'cache-and-network' })
    .toPromise();

  return data?.automations ?? null;
}

export function Component() {
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const {
    results: automations,
    search,
    query,
  } = useSearch(data ?? undefined, SEARCH_OPTIONS);

  const [filtersValue, setFiltersValue] = useState(
    new URLSearchParams([['status', Status.Active]])
  );

  // Sort automations by name
  const sortedAutomations = automations?.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  // Filter automations by filters
  const filteredAutomations = sortedAutomations?.filter((automation) => {
    const status = filtersValue.get('status');

    return !status || automation.status === status;
  });

  return (
    <>
      <AuthGate action='manage' subject='feat.automation'>
        <Split
          nav={<MoreNav />}
          filters={
            <FilterGroupSearch
              filters={[
                {
                  type: 'select',
                  name: 'status',
                  label: 'Status',
                  options: [
                    { label: 'Active', value: Status.Active },
                    { label: 'Inactive', value: Status.Inactive },
                  ],
                  multiple: false,
                  searchable: false,
                },
              ]}
              value={{
                searchValue: query,
                filtersValue: filtersValue,
              }}
              onChange={({ searchValue, filtersValue }) => {
                search(searchValue);
                setFiltersValue(filtersValue);
              }}
              placement={'portal'}
            />
          }
          main={
            <div className='pb-8'>
              {filteredAutomations?.map((automation) => (
                <Item key={automation.id} automation={automation} />
              ))}
            </div>
          }
        />
        {/* <div style={{ height: 'calc(100svh - 5rem)' }}> */}

        {/* </div> */}
      </AuthGate>
    </>
  );
}

function Item({
  automation,
}: {
  automation: ListAutomationsQuery['automations'][0];
}) {
  const { automationId } = useParams();
  const { t } = useTranslation();

  return (
    <CardNew
      linkTo={automation.id}
      titleText={automation.name}
      secondaryText={<span className='capitalize'>{t(automation.on)}</span>}
      tertiaryText={
        automation.jobName +
        ' for ' +
        automation.sites.map(({ name }) => name).join(', ')
      }
      // status={automation.status}
      image={
        <div className='flex h-full w-full items-center justify-center bg-shade-10'>
          <FontAwesomeIcon className='text-2xl text-shade-40' icon={faGears} />
        </div>
      }
      isSelected={automationId === automation.id}
      status={automation.status}
      showImage
    />
  );
}
