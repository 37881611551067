import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '~/hooks/useAuth';
import { FloatingActionButton } from './FloatingActionButton';
import { Button } from './ui/buttons/Button';

export const ManageStockActions = () => {
  const { can } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      {getActions(can, t).map(({ to, label }) => (
        <Button key={to} as={Link} to={to} color='primary' rounded>
          {label}
        </Button>
      ))}
    </>
  );
};

export const ManageStockFAB = (props: { path?: '*' }) => {
  const { can } = useAuth();
  const { t } = useTranslation();

  return <FloatingActionButton actions={getActions(can, t)} />;
};

function getActions(can: (permission: string) => boolean, t: TFunction) {
  const actions = [
    { to: 'stock-adjustment', label: t('adjustStock') },
    { to: 'stocktake', label: t('stocktake') },
  ];
  // if (can('transfer-stock.create')) {
  //   actions.push({ to: 'transfer-stock', label: t('Transfer Stock') });
  // }
  return actions;
}
