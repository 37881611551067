import { ResultOf } from '@graphql-typed-document-node/core';
import { Popover, Transition } from '@headlessui/react';
import { SelectorIcon as ChevronUpDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import { graphql } from '~/gql';
import { Tag as InputTagProps, TagFieldsFragment as Tag } from '~/gql/graphql';
import { useCheckOverflow } from '~/hooks/useCheckOverflow';
import { useScrollToFocus } from '~/hooks/useScrollToFocus';
import { PopoverStateChangedEffect } from '../PopoverStateChangedEffect';
import { Label } from '../ui/Label';
import { MenuTransition } from '../ui/Transition/MenuTransition';
import { DeleteConfirmation } from './DeleteConfirmation';
import { TagCombolist } from './TagCombolist';
import { TagForm } from './TagForm';
import { TagsBuilder } from './TagsBuilder';

export enum TagEntityType {
  Activity = 'Activity',
  Comment = 'Comment',
  Job = 'Job',
  Task = 'Task',
}

export const TagEntityTypes = [
  TagEntityType.Activity,
  TagEntityType.Comment,
  TagEntityType.Job,
  TagEntityType.Task,
];

type Props = {
  entityType: TagEntityType;
  options: Tag[];
  value: Tag[];
  customButton?: React.ReactNode;
  containerStyles?: string;
  onChange: (tags: Tag[], mode?: 'create') => void;

  /** `true` to manage open state externally */
  dialogMode?: boolean;
  /** Used in DialogMode */
  isOpen?: boolean;
  /** Used in DialogMode */
  hasPreview?: boolean;
  /** Used in DialogMode */
  onClose?: () => void;
  /** Used in DialogMode */
  onSubmit?: () => void;
};

const TagFieldsFragment = graphql(`
  fragment TagFields on Tag {
    id
    entityType
    name
    category
    description
    colour
  }
`);

type t = ResultOf<typeof TagFieldsFragment>;

const CreateTagMutationDocument = graphql(`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      ...TagFields
    }
  }
`);

const UpdateTagMutationDocument = graphql(`
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      ...TagFields
    }
  }
`);

const DeleteTagDocument = graphql(`
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input)
  }
`);

// TODO this component needs to be refactored
export const TagCombobox = ({
  entityType,
  options,
  value,
  customButton,
  containerStyles,
  onChange,
  dialogMode = false,
  isOpen,
  hasPreview,
  onClose,
  onSubmit,
}: Props) => {
  const [formValues, setFormValues] = useState<Tag>();
  const [tagToDelete, setTagToDelete] = useState<Tag | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const ref = useScrollToFocus();

  const [, createTag] = useMutation(CreateTagMutationDocument);
  const [, updateTag] = useMutation(UpdateTagMutationDocument);
  const [, deleteTag] = useMutation(DeleteTagDocument);

  function removeFromSelected(id: string) {
    onChange([...value].filter((tag) => tag.id !== id));
  }

  const handleCreateTag = (tag: Tag) => {
    const isEdit = !!tag.id;

    if (isEdit) {
      const { __typename, entityType, ...rest } = tag;

      updateTag({ input: rest }).then((result) => {
        if (result.error) {
          toast.error(result.error.graphQLErrors.join(', '));
        } else {
          onChange([...value, tag]);
          toast.success('Tag successfully updated');
        }
      });

      return;
    }

    tag.entityType = entityType;

    createTag({ input: tag }).then((result) => {
      if (result.error) {
        toast.error(result.error.graphQLErrors.join(', '));
      } else {
        const data = result.data as { createTag: Tag };
        onChange([...value, data.createTag], 'create');
        toast.success('Tag successfully created');
      }
    });

    setIsFormOpen(false);
  };

  const handleDeleteTag = async () => {
    if (!tagToDelete) return;

    const result = await deleteTag({
      input: { id: tagToDelete.id },
    });

    if (result?.error) {
      toast.error(result.error.graphQLErrors.join(', '));
    } else {
      toast.success('Tag successfully deleted');
    }

    setTagToDelete(null);
  };

  return (
    <div ref={dialogMode ? null : ref} className='relative'>
      <TagForm
        initialValues={formValues}
        tags={options}
        onClose={() => setIsFormOpen(false)}
        onSubmit={handleCreateTag}
        isOpen={isFormOpen}
      />

      {dialogMode && isOpen ? (
        <DialogMode
          options={options}
          value={value}
          customButton={customButton}
          containerStyles={containerStyles}
          hasPreview={hasPreview}
          onRemove={removeFromSelected}
          onChange={onChange}
          onCreate={(tag) => {
            setFormValues(tag);
            setIsFormOpen(true);
          }}
          onDelete={setTagToDelete}
          onCloseDialog={onClose}
          onSubmit={onSubmit}
        />
      ) : !dialogMode ? (
        <PopoverMode
          options={options}
          customButton={customButton}
          containerStyles={containerStyles}
          value={value}
          onRemove={removeFromSelected}
          onChange={onChange}
          onCreate={(tag) => {
            setFormValues(tag);
            setIsFormOpen(true);
          }}
          onDelete={setTagToDelete}
          onModalOpen={setIsFormOpen}
        />
      ) : null}

      <DeleteConfirmation
        tag={tagToDelete}
        onClose={() => setTagToDelete(null)}
        onDelete={handleDeleteTag}
      />
    </div>
  );
};

type ModesProps = {
  options: Tag[];
  value: Tag[];
  customButton?: React.ReactNode;
  containerStyles?: string;
  hasPreview?: boolean;
  onModalOpen?: (v: boolean) => void;
  onChange: (tags: Tag[]) => void;
  onCreate: (tag?: InputTagProps | undefined) => void;
  onDelete: (tag: InputTagProps) => void;
  onRemove: (id: string) => void;
  onCloseDialog?: () => void;
  onSubmit?: () => void;
};

function PopoverMode({
  options,
  customButton,
  containerStyles,
  value,
  onModalOpen,
  onChange,
  onCreate,
  onDelete,
  onRemove,
}: ModesProps) {
  const popRef = useRef(null);
  const { isOverflowing } = useCheckOverflow(popRef);

  return (
    <>
      {!customButton && (
        <Label className='mb-1.5 ml-1 text-sm font-medium text-grey-50'>
          Tags
        </Label>
      )}

      <Popover>
        {customButton ? (
          <Popover.Button> {customButton} </Popover.Button>
        ) : (
          <Popover.Button className='input min-w-56 mb-5 mt-0.5 flex min-h-[46px] w-full cursor-pointer items-center rounded-md border border-grey-20 px-3 py-2 focus-visible:border-grey-40 focus-visible:outline-none'>
            <div className='flex flex-1 flex-wrap gap-1 text-left'>
              {value.length > 0 ? (
                <TagsBuilder tags={value} onRemove={onRemove} />
              ) : (
                <span className='text-grey-40'>None Selected</span>
              )}
            </div>
            <ChevronUpDownIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </Popover.Button>
        )}

        <MenuTransition className={classNames(!customButton && 'relative')}>
          <Popover.Panel
            as='div'
            ref={popRef}
            className={classNames(
              'absolute z-50 max-h-96 border border-grey-20 bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:border-grey-40 focus-visible:outline-none sm:text-sm',
              customButton ? 'rounded-md' : '-top-6 w-full rounded-b-md',
              isOverflowing ? 'overflow-auto' : 'overflow-visible',
              containerStyles
            )}
          >
            {({ open }) => {
              return (
                <>
                  <PopoverStateChangedEffect open={open} onClose={() => {}} />
                  <TagCombolist
                    items={options}
                    searchKeys={['name', 'category', 'description']}
                    placeholder='tags'
                    value={value}
                    onChange={onChange}
                    onCreate={onCreate}
                    onDelete={onDelete}
                  />

                  <div className='sticky bottom-0 z-10 flex justify-between rounded-b-md border-t bg-white p-1.5 text-xs'>
                    <Popover.Button
                      className='p-2 text-left uppercase text-grey-40 hover:text-brand'
                      onClick={() => onModalOpen && onModalOpen(false)}
                    >
                      Cancel
                    </Popover.Button>
                    <Popover.Button className='rp-2 w-16 pr-2 text-right uppercase text-grey-40 hover:text-brand '>
                      Ok
                    </Popover.Button>
                  </div>
                </>
              );
            }}
          </Popover.Panel>
        </MenuTransition>
      </Popover>
    </>
  );
}

function DialogMode({
  options,
  containerStyles,
  value,
  hasPreview = true,
  onCloseDialog,
  onChange,
  onCreate,
  onDelete,
  onRemove,
  onSubmit,
}: ModesProps) {
  const dialogRef = useRef<HTMLDivElement>(null);
  const { isOverflowing } = useCheckOverflow(dialogRef);

  return (
    <div
      ref={dialogRef}
      className={classNames(
        'max-h-96 rounded-md border border-grey-20 bg-white shadow-lg',
        isOverflowing ? 'overflow-auto' : 'overflow-visible',
        containerStyles
      )}
    >
      {hasPreview && (
        <Transition
          show={value.length > 0}
          enter='transition-opacity duration-200'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-150'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='mx-2 mt-2'>
            <span className='mb-1.5 font-medium text-grey-50'>
              Selected Tags
            </span>
            <div className='flex min-h-[32px] flex-wrap items-center gap-1 rounded border border-grey-20 p-1 text-base'>
              <TagsBuilder
                tags={value}
                onRemove={onRemove}
                limit={10}
                className='!gap-1'
              />
            </div>
          </div>
        </Transition>
      )}

      <TagCombolist
        items={options}
        searchKeys={['name', 'category', 'description']}
        placeholder='tags'
        value={value}
        onChange={onChange}
        onCreate={onCreate}
        onDelete={onDelete}
      />

      <div className='sticky bottom-0 z-10 flex justify-between rounded-b-md border-t bg-white p-1.5 text-xs'>
        <button
          className='p-2 text-left uppercase text-grey-40 hover:text-brand'
          onClick={onCloseDialog}
        >
          Cancel
        </button>
        <button
          className='rp-2 w-16 pr-2 text-right uppercase text-grey-40 hover:text-brand'
          onClick={(e) => {
            e.preventDefault();
            onSubmit && onSubmit();
          }}
        >
          Ok
        </button>
      </div>
    </div>
  );
}
