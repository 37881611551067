import classNames from 'classnames';
import classNamesBind from 'classnames/bind';
import { getDaysInMonth } from 'date-fns';
import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import { RepeatInput } from '~/gql/graphql';
import { getOrdinalSuffix } from '~/helpers/ordinalSuffix';
import { Input } from '../form/TextField';
import { Select } from '../form/downshift/Select';
import { Avatar } from './Avatar';
import styles from './JobsRepeat.module.css';

type JobsRepeatProps = {
  label: string;
};

export type RepeatValue = {
  isRepeat: boolean;
  interval: string;
  numOfRepeats: string;
  frequency: string;
  repeatOnWeek: string[];
  repeatOnMonth: string;
  repeatOnYear: string;
};

function getMonthByDay(value: string) {
  const arr = value.split(',');
  return [arr[0], arr[1].substring(0, 2).toLowerCase()].join('');
}
export function repeatToInput(value: RepeatValue): RepeatInput | null {
  if (!value.isRepeat) {
    return null;
  }

  const bymonthday =
    value.frequency === 'monthly' &&
    value.repeatOnMonth.startsWith('bymonthday:')
      ? value.repeatOnMonth.substring('bymonthday:'.length)
      : undefined;
  const byday =
    value.frequency === 'monthly' && value.repeatOnMonth.startsWith('byday:')
      ? [getMonthByDay(value.repeatOnMonth.substring('byday:'.length))]
      : value.frequency === 'weekly'
      ? value.repeatOnWeek.map((day) => day.substring(0, 2).toLowerCase())
      : undefined;
  return {
    freq: value.frequency,
    interval: value.interval,
    byday,
    bymonthday,
    count: parseInt(value.numOfRepeats) + 1,
  };
}

const DAYS: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const frequencyOptions = ['daily', 'weekly', 'monthly'];
const getWeekNumber = (date: Date) => {
  const dateNumber = date.getDate();
  const daysInMonth = getDaysInMonth(date);
  const isLastWeekDayOfMonth = daysInMonth / (dateNumber + 7) < 1;
  return isLastWeekDayOfMonth ? 5 : Math.ceil(dateNumber / 7);
};

const cx = classNamesBind.bind(styles);

export function JobsRepeat({ label }: JobsRepeatProps) {
  const { t } = useTranslation();
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { repeat, scheduledStartDate, scheduledEndDate } = watch();

  watch((data, { name: _, type }) => {
    const isOnChange = type === 'change';
    if (isOnChange && !data.scheduledStartDate && !data.scheduledEndDate) {
      setValue('repeat.isRepeat', false);
    }
  });

  const now = new Date();
  const formStartDate = scheduledStartDate ? new Date(scheduledStartDate) : now;
  const startDateWeekNumber = getWeekNumber(formStartDate);

  const monthOptions = [
    {
      value: `bymonthday:${formStartDate.getDate()}`,
      label: `Day ${formStartDate.getDate()} of the month`,
    },
    {
      value: `byday:${startDateWeekNumber},${DAYS[formStartDate.getDay()]}`,
      label: `${
        startDateWeekNumber > 4 ? 'Last' : getOrdinalSuffix(startDateWeekNumber)
      } ${DAYS[formStartDate.getDay()]} of the month`,
    },
  ];
  const numOfRepeatsError = errors.numOfRepeats?.message || '';

  return (
    <div className='flex flex-col'>
      <div className='mb-5 flex items-center gap-2'>
        <Controller
          name='repeat.isRepeat'
          control={control}
          render={({ field }) => (
            <div className={cx('jobs-repeat-toggle')}>
              <Toggle
                disabled={!scheduledEndDate && !scheduledStartDate}
                checked={field.value}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                }}
                icons={false}
              />
            </div>
          )}
        />
        <span>{label}</span>
      </div>
      {repeat?.isRepeat && (
        <>
          <div className='flex flex-nowrap items-stretch gap-2'>
            <div className='basis-full 2xl:basis-1/3'>
              <Input
                {...register('repeat.interval')}
                label='Every'
                type='number'
              />
            </div>
            <Controller
              control={control}
              name='repeat.frequency'
              render={({ field }) => (
                <div className='min-w-[118px] basis-full 2xl:basis-1/3'>
                  <label className='ml-1 text-sm font-medium text-grey-50'></label>
                  <Select
                    hasSelectorIcon
                    inputProps={{ className: '' }}
                    label=''
                    options={frequencyOptions.map((value) => ({
                      value,
                      label: t(`repeatFrequencies.${value}`),
                    }))}
                    value={field.value ? [field.value] : []}
                    onChange={(selected) =>
                      field.onChange(selected.length ? selected[0] : '')
                    }
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name='repeat.numOfRepeats'
              render={({ field }) => (
                <div className='flex min-w-[118px] basis-full 2xl:basis-1/3'>
                  <Input
                    className={classNames(
                      'relative flex w-full flex-col gap-1',
                      {
                        'mb-5': !numOfRepeatsError,
                      }
                    )}
                    value={field.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      event.target.value = value.replace(/\D+/g, '');
                      field.onChange(event);
                    }}
                    label='No. of Repeats'
                    inputMode='numeric'
                    // @ts-ignore TOFIX
                    error={numOfRepeatsError}
                  />
                </div>
              )}
            />
          </div>
          {repeat?.frequency !== 'daily' ? (
            <div className='mb-8 flex flex-col gap-2 '>
              <span>Repeat on</span>
              {repeat?.frequency === 'weekly' ? (
                <Controller
                  control={control}
                  name='repeat.repeatOnWeek'
                  render={({ field }) => (
                    <div className='flex items-center justify-between gap-0 sm:justify-start sm:gap-1 lg:gap-2'>
                      {DAYS.map((day, index) => (
                        <div
                          className='rounded-full'
                          onClick={() => {
                            const values = [...field.value];
                            field.onChange(
                              values.includes(day)
                                ? values.filter((val) => val !== day)
                                : [...values, day]
                            );
                          }}
                          key={index}
                        >
                          <Avatar
                            className={classNames('!h-10 cursor-pointer', {
                              '!bg-brand text-white':
                                repeat?.repeatOnWeek.includes(day),
                            })}
                            name={day}
                            size='medium'
                          />
                        </div>
                      ))}
                    </div>
                  )}
                />
              ) : null}
              {repeat?.frequency === 'monthly' ? (
                <Controller
                  control={control}
                  name='repeat.repeatOnMonth'
                  render={({ field }) => (
                    <Select
                      hasSelectorIcon
                      label=''
                      inputProps={{ className: '' }}
                      options={monthOptions}
                      value={field.value ? [field.value] : []}
                      onChange={(selected) =>
                        field.onChange(
                          selected.length ? selected[0] : monthOptions[0].value
                        )
                      }
                      sortItems={false}
                    />
                  )}
                />
              ) : null}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
