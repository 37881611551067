import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 600 600'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <g>
      <path d='m462 106.4h-224c-16.336 0-32.004 6.4922-43.559 18.043-11.551 11.555-18.043 27.223-18.043 43.559v22.398h33.602v-22.398c0-7.4258 2.9492-14.547 8.1992-19.801 5.2539-5.25 12.375-8.1992 19.801-8.1992h224c7.4258 0 14.547 2.9492 19.801 8.1992 5.25 5.2539 8.1992 12.375 8.1992 19.801v224c0 7.4258-2.9492 14.547-8.1992 19.801-5.2539 5.25-12.375 8.1992-19.801 8.1992h-22.398v33.602h22.398c16.336 0 32.004-6.4922 43.559-18.043 11.551-11.555 18.043-27.223 18.043-43.559v-224c0-16.336-6.4922-32.004-18.043-43.559-11.555-11.551-27.223-18.043-43.559-18.043z' />
      <path d='m266 240.8h33.602v33.602h-33.602z' />
      <path d='m176.4 240.8h33.602v33.602h-33.602z' />
      <path d='m355.6 240.8h33.602v33.602h-33.602z' />
      <path d='m355.6 330.4h33.602v33.602h-33.602z' />
      <path d='m355.6 420h33.602v33.602h-33.602z' />
      <path d='m210 392v-67.199h-33.602v67.199c0 16.336 6.4922 32.004 18.043 43.559 11.555 11.551 27.223 18.043 43.559 18.043h67.199v-33.602h-67.199c-7.4258 0-14.547-2.9492-19.801-8.1992-5.25-5.2539-8.1992-12.375-8.1992-19.801z' />
    </g>
  </svg>
);

export default SvgComponent;
