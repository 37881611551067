import { useEffect, useMemo, useCallback, forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useTable, useFlexLayout, useSortBy, useRowSelect } from 'react-table'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Up, Down } from './icons'
import styles from './Table.module.css'

const cx = classNames.bind(styles)

const getStyles = (column) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }

  if (column.align) {
    style.textAlign = column.align
  }

  return { style }
}

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type='checkbox' ref={resolvedRef} {...rest} />
    </>
  )
})

/**
 * Purpose built data table using
 * [*react-table*](https://github.com/tannerlinsley/react-table).
 * Sortable by default. Optionally selectable or clickable rows.
 *
 * To make `Table` rows clickable simply pass a `onRowClick` prop.
 *
 * To make `Table` rows selectable simply pass a `onSelectRow` prop. Note that
 * selectable table rows provide their own click handler which makes this
 * incompatible with `onRowClick`
 *
 * @typedef {object} Props
 * @prop {any} [onRowSelect]
 * @prop {string[]} [initialHiddenColumns]
 * @prop {any} [style]
 *
 * @param {Props} props
 */
export const Table = ({
  columns,
  data,
  onRowSelect = undefined,
  onRowClick = undefined,
  initialHiddenColumns = [],
  initialSelectedRowIds = undefined,
  initialSortBy,
  activeId = undefined,
  ...props
}) => {
  const hoverable = Boolean(onRowSelect) || Boolean(onRowClick)

  const handleRowClick = useMemo(
    () =>
      onRowSelect ? (row) => row.toggleRowSelected() : (row) => onRowClick(row),
    [onRowSelect, onRowClick]
  )

  const defaultColumn = useMemo(
    () => ({ minWidth: 50, width: 150, maxWidth: 300 }),
    []
  )

  // May make this configurable via props but for now it is hidden
  const idKey = 'id'

  // Note that this overrides a default which did support nested rows:
  // (row, relativeIndex, parent) => parent ? [parent.id, relativeIndex].join('.') : relativeIndex
  const getRowId = useCallback((row) => row[idKey], [idKey])

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    totalColumnsWidth,
    // selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: initialHiddenColumns,
        selectedRowIds: initialSelectedRowIds,
        sortBy: initialSortBy,
      },
      getRowId,
    },
    useFlexLayout,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (onRowSelect) {
        hooks.allColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            minWidth: 35,
            maxWidth: 35,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }
    }
  )

  useEffect(() => {
    onRowSelect && onRowSelect(selectedRowIds)
  }, [onRowSelect, selectedRowIds])

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)

      const selected = row.isSelected || activeId === row.id.toString()

      // TODO move this out into custom row decorator
      const added = row.original.queued_type === 'Add'
      const removed = row.original.queued_type === 'Remove'

      return (
        <div
          {...row.getRowProps({
            style,
            onClick: hoverable ? () => handleRowClick(row) : undefined,
            className: cx('tr', { selected, added, removed }),
          })}
        >
          {row.cells.map((cell) => {
            return (
              <div
                {...cell.getCellProps(getStyles(cell.column))}
                className={cx('td')}
              >
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [activeId, handleRowClick, hoverable, prepareRow, rows]
  )

  return (
    <div
      {...getTableProps({ className: cx('table', { hoverable }) })}
      {...props}
    >
      <div className={cx('thead')}>
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps({ className: cx('tr') })}>
            {headerGroup.headers.map((column) => (
              <div
                {...column.getHeaderProps({
                  ...column.getSortByToggleProps(),
                  ...getStyles(column),
                  className: cx('th'),
                })}
              >
                <div>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Down className={styles.sort} />
                      ) : (
                        <Up className={styles.sort} />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps()} className={cx('tbody')}>
        <AutoSizer defaultHeight={300} defaultWidth={totalColumnsWidth}>
          {({ width, height }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemCount={rows.length}
              itemSize={80}
              style={{ overflowY: 'scroll' }}
            >
              {RenderRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      id: PropTypes.string,
      Header: PropTypes.string.isRequired,
      Cell: PropTypes.func,
    })
  ),
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  onRowSelect: PropTypes.func,
  initialSelectedRowIds: PropTypes.objectOf(PropTypes.bool),
  initialSortBy: PropTypes.arrayOf(PropTypes.object),
}

Table.defaultProps = {
  initialSelectedRowIds: {},
  initialSortBy: [],
}
