import PropTypes from 'prop-types'
import { commitChanges, unqueueChanges } from '~/api/assets'
import { ActionForm } from '~/components/form/ActionForm'
import { AssetConfigCard } from '~/components/asset-config/AssetConfigCard'

function isQueued(row) {
  return !(
    row.queued_type === null &&
    row.queued_price === null &&
    row.queued_capacity === null &&
    row.queued_target === null
  )
}

export const ManageQueuedChanges = ({ mode, selected, data, onSuccess }) => {
  const queued = Object.keys(selected).reduce((arr, id) => {
    const row = data.find((o) => o.id === id)

    if (!row) {
      return arr
    }

    // FIXME reset error on mutated row object - should avoid mutating row
    delete row.error

    if (isQueued(row)) {
      if (mode === 'Commit' && row.queued_type === 'Remove' && row.soh > 0) {
        row.error = 'On Hand must be 0 to commit a Removal'
      }
      return arr.concat(row)
    }
    return arr
  }, [])

  const [action, formKey] =
    mode === 'Commit' ? [commitChanges, 'commit'] : [unqueueChanges, 'unqueue']

  const initialValues = queued.map(({ space_id, sku_id }) => ({
    space_id,
    sku_id,
  }))

  return (
    <ActionForm
      action={action}
      initialValues={{ [formKey]: initialValues }}
      title={mode + ' Changes'}
      buttonText={mode}
      onSuccess={onSuccess}
      enableReinitialize
    >
      {queued.length
        ? queued.map((item) => <AssetConfigCard key={item.id} item={item} />)
        : 'No changes selected'}
    </ActionForm>
  )
}

ManageQueuedChanges.propTypes = {
  mode: PropTypes.oneOf(['Unqueue', 'Commit']).isRequired,
  selected: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
}
