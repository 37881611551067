import { format, parse } from 'date-fns/fp';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TaskIcon } from '~/components/job/JobView/TaskList/TaskListItem';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { ListAutomationsQuery } from '~/gql/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { AutomationMenu } from './AutomationMenu';

type Props = {
  automation: ListAutomationsQuery['automations'][number];
};

const formatTime = format('h:mm a');
const parseTime = parse(new Date())('HH:mm:ss');

export function AutomationView({ automation }: Props) {
  const { t } = useTranslation();

  return (
    <SideLayout>
      <SideLayout.Head
        leftSlot={<Link to='..' className='x' />}
        rightSlot={<AutomationMenu />}
      >
        {automation.name}
      </SideLayout.Head>
      <SideLayout.Body>
        <StatusBadge value={automation.status} />
        <ReadValue label='automations.on' className='capitalize'>
          {t(automation.on)}
        </ReadValue>
        <ReadValue label='automations.whereType'>
          {t(`automations.whereTypes.${automation.whereType}`)}
        </ReadValue>
        <ReadValue label='site' options={{ count: automation.sites.length }}>
          {automation.sites.length > 0
            ? automation.sites.map(({ id, name, image }) => (
                <div key={id} className='flex items-center gap-2'>
                  {/* <Image url={image} /> */}
                  {name}
                </div>
              ))
            : null}
        </ReadValue>
        <ReadValue label='Job Name'>{automation.jobName}</ReadValue>
        <ReadValue
          label='jobOwner'
          options={{ count: automation.owners?.length ?? 1 }}
        >
          {automation.owners?.map(({ name }) => name).join(', ') ?? '-'}
        </ReadValue>
        <ReadValue label='assignedTo'>
          {automation.assignees?.map(({ name }) => name).join(', ') ?? '-'}
        </ReadValue>
        <ReadValue label='template'>
          <Link
            className='flex items-center gap-2 text-brand hover:underline'
            to={`/job-templates/${automation.template.id}`}
          >
            {automation.template.name}
          </Link>
        </ReadValue>
        {automation.template.tasks.length > 0 && (
          <div className='mb-5'>
            <div className='mb-4 mt-6 flex items-center justify-between text-sm font-bold text-copy-alt'>
              <p>Task List Preview</p>
              <Link
                className='text-brand'
                to={`/job-templates/${automation.template.id}/edit`}
              >
                {t('editTemplate')}
              </Link>
            </div>
            {automation.template.tasks.map((task) => (
              <div
                key={task.id}
                className='mt-3 flex w-full items-center justify-between rounded border border-grey-10 px-3 py-2.5 text-grey-50 transition-all'
              >
                {task.name}
                <div className='ml-auto flex h-5 items-center justify-center text-brand'>
                  <TaskIcon
                    taskType={task.type}
                    taskDescription={task.description}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <ReadValue label='Scheduled Start'>
          {automation.day === 0
            ? 'Same day'
            : automation.day < 0
            ? Math.abs(automation.day) + ' days before'
            : automation.day + ' days after'}{' '}
          {automation.time && `at ${formatTime(parseTime(automation.time))}`}
        </ReadValue>
        <ReadValue label='Additional Conditions'>
          {automation.conditions && automation.conditions.length > 0 && (
            <>
              {automation.conditions.map((condition, i) => (
                <div key={i}>
                  {condition.type[0].toUpperCase()}
                  {condition.type.substring(1).replace('-', ' ')}{' '}
                  {condition.operator} {condition.nights}
                </div>
              ))}
            </>
          )}
        </ReadValue>
      </SideLayout.Body>
    </SideLayout>
  );
}
