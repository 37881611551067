import { SVGProps } from 'react'

const SvgMore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    stroke='#1095C0'
    fill='#1095C0'
    {...props}
  >
    <circle cx={256} cy={256} r={48} />
    <circle cx={416} cy={256} r={48} />
    <circle cx={96} cy={256} r={48} />
  </svg>
)

export default SvgMore
