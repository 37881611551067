import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSiteTypesQuery } from '~/generated/graphql';
import { useLocalStorage } from '~/hooks';
import { useSiteListContext } from '~/routes/sites';
import { SubNav } from './SubNav';

export const SitesNav = () => {
  const localStorage = useLocalStorage();
  const { t } = useTranslation();
  const { view, setView } = useSiteListContext();
  const [result] = useSiteTypesQuery({
    variables: { preset: true },
    requestPolicy: 'cache-and-network',
  });

  const { data, fetching } = result;

  // SubNav component compatible data structure
  const visibleItems = [...(data?.siteTypes ?? []), 'Other'].map((type) => ({
    type,
    title: `${type}s`,
    url: `/sites`,
  }));

  useEffect(() => {
    if (!fetching && !view && visibleItems.length > 0) {
      // TODO check type from localstorage still exists in visibleItems
      setView(localStorage.get('nav.sites') ?? visibleItems[0].type);
    }
  }, [fetching, visibleItems, view, setView]);

  if (data && data.siteTypes.length === 0) {
    return null;
  }

  return (
    <div className='z-50 flex items-center justify-between'>
      <SubNav
        pages={visibleItems}
        isActive={(key) => key === view + 's'}
        // @ts-expect-error Fix type definition - needs generic support for additional properties
        onItemClick={({ type }) => {
          setView(type);
          localStorage.set('nav.sites', type);
        }}
      />
      <div>
        <Link className='add-button' to='new'>
          + {t('site')}
        </Link>
      </div>
    </div>
  );
};
