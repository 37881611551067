import classNames from 'classnames';

type Props = {
  count: number;
  color?: 'primary' | 'danger' | 'brand';
  className?: string;
};

export const CountBadge = ({ count, color = 'primary', className }: Props) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-center rounded-full px-1.5 py-[1px]',
        color === 'brand'
          ? 'bg-brand'
          : color === 'danger'
          ? 'bg-flagged'
          : 'bg-blue-80',
        className
      )}
    >
      <p className='text-[11px] leading-4 text-white'>{countDisplay(count)}</p>
    </div>
  );
};

function countDisplay(count: number) {
  if (count >= 100) {
    return '99+';
  } else return count;
}
