import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import { PromptEffect } from '~/components/Prompt';
import { useQuickAdd } from '~/components/QuickAdd';
import { QuickAddPopup } from '~/components/QuickAddPopup';
import { StockSelectionField } from '~/components/form/StockSelectionField';
import { SubmitButton } from '~/components/form/SubmitButton';
import { ReadOnlyEditor } from '~/components/form/editor/ReadOnlyEditor';
import {
  JobStatus,
  StockSelection,
  useFinishStocktakeMutation,
  useStockOnHandQuery,
} from '~/generated/graphql';
import { graphql } from '~/gql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { ResetTaskStatusButton, WorkflowButtons } from '../job/WorkflowButtons';
import { MandatoryTaskHelper } from '../job/tasks/Custom';
import { useConfirm } from '../ui/Confirm';
import { SMCHead } from './SMCHead';
import { Context } from './card/Hero';
import { CancelCallback, Data, Mode, SuccessCallback } from './types';

// type Operator = 'add' | 'sub';

// const ADJUSTMENT_TYPE_OP_MAP: { [x: string]: Operator } = {
//   'Stock Order': 'add',
//   'Interstate Transfer - Sent': 'sub',
//   'Interstate Transfer - Received': 'add',
//   'Over Pick': 'add',
//   Replacement: 'sub',
//   'Online Order': 'sub',
//   'Sample / Gift': 'sub',
//   'Other - Add to Stock On Hand': 'add',
//   'Other - Subtract from Stock On Hand': 'sub',
// };

type Props = {
  mode: Mode;
  data: Data;
  onCancel: CancelCallback;
  onSuccess: SuccessCallback;
  buttonText?: string;
  jobStatus?: JobStatus;
  jobName?: string;
  taskRequired?: boolean | null;
  taskStatus?: JobStatus;
  initialAdjustments?: StockSelection[];
  /**
   * @deprecated
   */
  onPrevState?: (...args: any[]) => any;
  showStep?: boolean;
};

type FormValues = {
  adjustments: StockSelection[];
};

const CompletePurchaseTaskMutation = graphql(`
  mutation CompletePurchaseTask($input: CompletePurchaseTaskInput!) {
    completePurchaseTask(input: $input)
  }
`);

export const Step2Form = ({
  mode,
  data,
  onCancel,
  onSuccess,
  buttonText,
  jobStatus,
  jobName,
  taskRequired,
  taskStatus,
  initialAdjustments,
  onPrevState,
  showStep = true,
}: Props) => {
  const { t } = useTranslation();
  const location = `${data.location.__typename ?? data.location.type}:${
    data.location.id
  }`;
  const enabled = data.enabled === undefined ? true : data.enabled;
  const taskComplete = taskStatus === JobStatus.Complete;
  const canResetTaskStatus =
    jobStatus === JobStatus.InProgress && taskStatus === JobStatus.Incomplete;

  // const operator =
  //   mode === 'adjustment' && data.adjustmentType
  //     ? ADJUSTMENT_TYPE_OP_MAP[data.adjustmentType]
  //     : 'add';

  const { showQuickAdd, storedValues, closeQuickAdd, openQuickAdd } =
    useQuickAdd<FormValues>();

  const [, completePurchaseTask] = useMutation(CompletePurchaseTaskMutation);
  const [, finishStocktake] = useFinishStocktakeMutation();

  const initialValues = storedValues ?? {
    adjustments: initialAdjustments ?? [],
  };

  const handleSubmit = async (values: FormValues) => {
    const prev = data;

    if (mode === 'purchase') {
      if (!data.taskId) {
        throw new Error('Task ID is required');
      }

      const result = await completePurchaseTask({
        input: {
          id: '' + data.taskId,
          adjustments: values.adjustments,
        },
      });

      if (result.error) {
        toast.error(result.error.message.replace('[GraphQL] ', ''));
        return;
      }

      toast.success(t('Success'));
      onSuccess && onSuccess();
      return;
    }

    if (mode === 'stocktake') {
      const result = await finishStocktake({
        input: {
          date: prev.date,
          adjustments: values.adjustments,
          taskId: data.taskId ? '' + data.taskId : undefined,
          locationId: location,
        },
      });

      if (result.error) {
        toast.error(result.error.message.replace('[GraphQL] ', ''));
        return;
      }

      toast.success(t('Success'));
      onSuccess && onSuccess();
      return;
    }

    try {
      await window.axios.post('stock-adjustments', { ...prev, ...values });
      toast.success(t('Success'));
      onSuccess && onSuccess();
    } catch (err: any) {
      toast.error(err.response.data);
    }
  };

  const handleClose = () => {
    onPrevState ? onPrevState(1) : onCancel();
  };

  const description = ReadOnlyEditor({ content: data.description });

  const [stockOnHandResult, reloadStockOnHand] = useStockOnHandQuery({
    pause: true,
    requestPolicy: 'cache-and-network',
    variables: { location },
  });

  const showMandatoryTaskHelper =
    taskRequired &&
    taskStatus !== JobStatus.Complete &&
    taskStatus !== JobStatus.Incomplete;

  return (
    <>
      <QuickAddPopup
        show={showQuickAdd}
        stockLocation={location}
        onCancel={closeQuickAdd}
        onClose={closeQuickAdd}
        onSuccess={reloadStockOnHand}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ dirty, isSubmitting }) => {
          const { confirm } = useConfirm({ when: dirty && !isSubmitting });
          return (
            <Form className='left-0 h-full'>
              <PromptEffect />
              <SideLayout>
                <SMCHead
                  step={showStep ? 2 : undefined}
                  onClose={confirm(handleClose)}
                  openQuickAdd={enabled ? openQuickAdd : undefined}
                >
                  {data.name ?? t(mode)}
                </SMCHead>
                <SideLayout.Body className='relative'>
                  {showMandatoryTaskHelper && (
                    <div className='px-4'>
                      <MandatoryTaskHelper jobName={jobName ?? ''} />
                    </div>
                  )}

                  {mode === 'purchase' && data.purchase && (
                    <dl className='px-4'>
                      {description && (
                        <>
                          <dt className='label mb-1.5'>{t('description')}</dt>
                          <dd className='mb-3'>{description}</dd>
                        </>
                      )}
                      <dt className='label mb-1.5'>Spaces</dt>
                      <dd className='mb-3'>
                        {data.purchase.spaces.join(', ')}
                      </dd>
                    </dl>
                  )}
                  {mode === 'stocktake' && data.stocktake && (
                    <dl className='px-4'>
                      {description && (
                        <>
                          <dt className='label mb-1.5'>{t('description')}</dt>
                          <dd className='mb-3'>{description}</dd>
                        </>
                      )}
                      <dt className='label mb-1.5'>Include</dt>
                      <dd className='mb-3'>
                        {data.stocktake.include
                          .map((type) => t(`itemType.${type}_plural`))
                          .join(', ')}
                      </dd>
                      <dt className='label mb-1.5'>Item Status</dt>
                      <dd className='mb-3'>
                        {data.stocktake.itemStatus.join(', ')}
                      </dd>
                      <dt className='label mb-1.5'>Spaces</dt>
                      <dd className='mb-3'>
                        {data.stocktake.spaces.join(', ')}
                      </dd>
                    </dl>
                  )}
                  <StockSelectionField
                    context={
                      mode === 'purchase'
                        ? Context.PURCHASE
                        : mode === 'stocktake'
                        ? Context.STOCKTAKE
                        : Context.ADJUSTMENT
                    }
                    name='adjustments'
                    location={location}
                    readOnly={
                      !enabled ||
                      (taskStatus && taskStatus !== JobStatus.Created)
                    }
                    initialFilters={data.purchase || data.stocktake}
                    items={stockOnHandResult.data?.stockOnHand}
                  />
                </SideLayout.Body>
                <SideLayout.Foot className='z-20 p-4'>
                  {canResetTaskStatus ? (
                    <ResetTaskStatusButton
                      id={data.taskId as string}
                      jobStatus={jobStatus}
                    />
                  ) : taskComplete ? (
                    <></>
                  ) : data.taskId && jobStatus && taskStatus ? (
                    enabled ? (
                      <WorkflowButtons
                        id={'' + data.taskId}
                        jobStatus={jobStatus}
                        taskStatus={taskStatus}
                      >
                        <SubmitButton>{buttonText ?? t('submit')}</SubmitButton>
                      </WorkflowButtons>
                    ) : (
                      <button
                        className='mb-5 w-full rounded-lg border border-brand bg-white p-3 font-semibold capitalize text-brand disabled:opacity-50'
                        type='button'
                        onClick={onCancel}
                      >
                        {t('back')}
                      </button>
                    )
                  ) : (
                    <SubmitButton enabled={enabled}>
                      {buttonText ?? t('submit')}
                    </SubmitButton>
                  )}
                </SideLayout.Foot>
              </SideLayout>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
