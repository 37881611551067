import {
  faPlaneArrival,
  faPlaneDeparture,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  OccupationInformation,
  OccupationStatus,
  getOccupationData,
} from '~/components/ui/CardNewV2/OccupationInformation';
import { checkOccupationStatus } from '~/components/ui/CardNewV2/utils';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { Job } from '../JobView';
import { parseDateTime } from './utils';

type Props = { job: Job; className?: string };

type ItemProps = {
  label: string;
  booking: string | null;
  isCheckIn: boolean;
  onClick: () => void;
};

const BookingSectionItem = ({
  label,
  booking,
  isCheckIn,
  onClick,
}: ItemProps) => (
  <>
    {booking && (
      <div className='flex flex-col gap-[5px]'>
        <div
          className='flex cursor-pointer items-center gap-[5px] font-semibold leading-[17.5px] hover:underline'
          onClick={onClick}
        >
          <FontAwesomeIcon
            color='#005959'
            size='sm'
            icon={isCheckIn ? faPlaneArrival : faPlaneDeparture}
          />
          {label}
        </div>
        <p className='font-normal text-[#506262]'>{booking}</p>
      </div>
    )}
  </>
);

export function BookingSection({ job, className }: Props) {
  const { pathname } = useLocation();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();

  const occupationData = getOccupationData(job);
  const { nextCheckIn, prevCheckOut, occupied } = occupationData;
  const status = checkOccupationStatus(occupationData);

  const nextBookingCI = parseDateTime(nextCheckIn.checkIn);
  const prevBookingCO = parseDateTime(prevCheckOut.checkOut);

  const openBookingModal = (id: string) => {
    const regex = /\/tasks\/\d+/;

    const url = regex.test(pathname)
      ? pathname.replace(regex, `/bookings/${id}`)
      : `${pathname}/bookings/${id}`;

    navigate(url);
  };

  if (!(prevBookingCO || nextBookingCI || occupied)) return null;

  return (
    <div className={className}>
      <div
        className={classNames(
          'flex flex-col rounded-[5px] border border-[#bdc9c8] bg-white'
        )}
      >
        <OccupationInformation occupationData={occupationData}>
          <OccupationInformation.JobViewBanner isMobile={isMobile} />
        </OccupationInformation>

        <div
          className={classNames(
            'flex flex-wrap gap-[20px] p-4',
            isMobile && 'px-[13px]'
          )}
        >
          {status === OccupationStatus.Occupied ? (
            <>
              <BookingSectionItem
                label='Check-in'
                booking={parseDateTime(occupied.checkIn)}
                isCheckIn={true}
                onClick={() => occupied.id && openBookingModal(occupied.id)}
              />

              <BookingSectionItem
                label='Check-out'
                booking={parseDateTime(occupied.checkOut)}
                isCheckIn={false}
                onClick={() => occupied.id && openBookingModal(occupied.id)}
              />
            </>
          ) : (
            <>
              <BookingSectionItem
                label='Previous Check-out'
                booking={prevBookingCO}
                isCheckIn={false}
                onClick={() =>
                  prevCheckOut.id && openBookingModal(prevCheckOut.id)
                }
              />

              <BookingSectionItem
                label='Next Check-in'
                booking={nextBookingCI}
                isCheckIn={true}
                onClick={() =>
                  nextCheckIn.id && openBookingModal(nextCheckIn.id)
                }
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
