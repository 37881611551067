import { DashboardModal } from '@uppy/react';
import { useField } from 'formik';
import localforage from 'localforage';
import { ReactNode, useCallback, useEffect } from 'react';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useUppy } from '~/hooks/useUppy';
import type { Kind } from '.';
import { UploadPreviews } from './UploadPreviews';

// uppy css
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

// Icons
import { UploadCompleteCallback } from '@uppy/core';
import { notUndefined } from '~/helpers/filter';
import Multi from '../upload/Multi';
import Single from '../upload/Single';

type Props = {
  id: string;
  children?: ReactNode;
  imagesOnly?: boolean;
  onUploaded: (uploaded: UploadResult[]) => void;
};

type Meta = Record<string, unknown>;

export type UploadResult = {
  id: string;
  preview: string;
  name: string;
  kind: Kind;
};

export function Upload({ children, id, imagesOnly, onUploaded }: Props) {
  const { isMobile } = useBreakpoint();

  const { uppy, open, setOpen } = useUppy(imagesOnly ? 'image' : 'file');

  const handleComplete: UploadCompleteCallback<Meta> = useCallback(
    async (result) => {
      console.log('complete', result);
      const results = result.successful.map((file) => {
        if (!file.response) {
          throw new Error('Something went wrong');
        }

        if (file.response.body.result) {
          // Images
          return {
            // @ts-expect-error FIXME
            id: file.response.body.result.id as string,
            preview: file.preview ?? '',
            name: file.name,
            kind: 'image' as 'image',
          };
        }

        // Other attachments
        return {
          id: file.meta.id as string,
          preview: '',
          name: file.name,
          kind: 'file' as 'file',
        };
      });

      // Keep a copy of the uploaded images in offline storage so that it can continue to be used until the form is submitted... avoids having to retrieve the signed URL for unsaved uploads
      const blobs = result.successful
        .map((file) => {
          if (file.response?.body.result) {
            // @ts-expect-error FIXME
            const id = file.response.body.result.id as string;

            return { id, data: file.data };
          }
        })
        .filter(notUndefined);
      await addImageUploadResultsToStorage(blobs);

      onUploaded(results);
    },
    [onUploaded]
  );

  useEffect(() => {
    if (open) {
      uppy.on('complete', handleComplete);

      return () => {
        uppy.off('complete', handleComplete);
      };
    }
  }, [open, handleComplete]);

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <button type='button' onClick={() => setOpen(true)}>
        {children ?? <UploadButton multiple />}
      </button>
      {open && id && (
        <DashboardModal
          id={id}
          uppy={uppy}
          open={open}
          theme='light'
          onRequestClose={handleClose}
          proudlyDisplayPoweredByUppy={false}
          note='Attachment limit 50 MB'
          locale={{
            strings: {
              browseFiles: isMobile ? 'Add files/photos' : 'browse files',
              dropPasteFiles: isMobile
                ? '%{browseFiles}'
                : 'Drop files here or %{browseFiles}',
            },
          }}
        />
      )}
    </>
  );
}

type UploadFieldProps = {
  name: string;
} & Omit<Props, 'onUploaded'>;

export function UploadField(props: UploadFieldProps) {
  const [field, meta, helpers] = useField<UploadResult[]>(props.name);

  return (
    <>
      <UploadPreviews
        uploads={field.value}
        onRemove={(id) => {
          helpers.setValue(field.value.filter((item) => item.id !== id));
        }}
      />
      <Upload
        {...props}
        onUploaded={(uploaded) => {
          helpers.setValue(field.value.concat(uploaded));
        }}
      />
    </>
  );
}

type ControlledUploadProps = {
  value: UploadResult[];
  onChange: (value: UploadResult[]) => void;
} & Omit<Props, 'onUploaded'>;

export function ControlledUpload({
  id,
  value,
  onChange,
}: ControlledUploadProps) {
  return (
    <div className='text-center'>
      <UploadPreviews
        uploads={value}
        onRemove={(id) => {
          onChange(value.filter((item) => item.id !== id));
        }}
      />
      <Upload
        id={id}
        onUploaded={(uploaded) => {
          onChange(value.concat(uploaded));
        }}
      />
    </div>
  );
}

function UploadButton({ multiple }: { multiple: boolean }) {
  return (
    <div className='flex cursor-pointer items-center justify-center pb-8 pt-4'>
      {multiple ? <Multi /> : <Single />}
    </div>
  );
}

// ------------------------------

type OfflineImages = {
  images: {
    id: string;
    data: Blob;
    expiresAt: number;
  }[];
};

async function addImageUploadResultsToStorage(
  images: { id: string; data: Blob }[]
) {
  try {
    const key = 'all-upload-previews';
    const offline = await localforage.getItem<OfflineImages>(key);
    const expires = Date.now() + 24 * 60 * 60 * 1000;

    await localforage.setItem(key, {
      images: [
        ...(offline?.images.filter((image) => image.expiresAt < Date.now()) ??
          []),
        ...images.map((image) => ({ ...image, expiresAt: expires })),
      ],
    });

    console.log('added to storage', images);
  } catch (err) {
    // fail silently
    console.error(err);
  }
}

export async function loadPreviewsFromStorage(): Promise<
  OfflineImages['images']
> {
  const key = 'all-upload-previews';
  const offline = await localforage.getItem<OfflineImages>(key);

  if (!(offline && offline.images)) {
    return [];
  }

  return offline.images;
}
