import { ReactNode, createContext, useContext } from 'react';
import { FaHouseUser } from 'react-icons/fa6';

import classNames from 'classnames';
import { isSameDay } from 'date-fns';
import CalendarExclamationIcon from '~/CalendarExclamationIcon';
import ClockExclamationIcon from '~/ClockExclamationIcon';
import HouseUserIcon from '~/HouseUserIcon';
import { Job } from '~/components/job/JobView';
import { formatLocalDate } from '~/components/job/formatDate';
import { TJob } from '~/routes/jobs';
import {
  checkOccupationStatus,
  convertToISO,
  parseDate,
  parseDateTime,
} from './utils';

type Props = {
  children: ReactNode;
  occupationData: OccupationInformation;
};

export type Booking = {
  id: string | null;
  checkIn: string | null;
  checkOut: string | null;
};

export type OccupationInformation = {
  occupied: Booking;
  prevCheckOut: Booking;
  nextCheckIn: Booking;
  schedule: string | null;
};

export enum OccupationStatus {
  Occupied = 'Occupied',
  BackToBack = 'Back to Back',
  SameDayCheckOut = 'Same Day Check-Out',
  SameDayCheckIn = 'Same Day Check-In',
}

type Context = {
  status: OccupationStatus | null;
  isPrevBookingSameDay: boolean;
  isNextBookingSameDay: boolean;
  top: JSX.Element | null;
  bottom: JSX.Element | null;
};

const styles = {
  inner:
    'ml-auto hidden h-[18px]  pl-[21px] pr-[15px] text-[13px] leading-4 lg:block',
  outer:
    'w-full border border-[#006a6a24] px-[10px] py-[1px] text-[13px] leading-4 lg:hidden',
};

const OccupationContext = createContext<Context>({} as Context);
const useOccupationContext = () => useContext(OccupationContext);

function OccupationInformationRoot({ children, occupationData }: Props) {
  const { occupied, prevCheckOut, nextCheckIn, schedule } = occupationData;

  const status = checkOccupationStatus(occupationData);

  const isPrevBookingSameDay = prevCheckOut.checkOut
    ? isSameDay(
        new Date(prevCheckOut.checkOut),
        new Date(convertToISO(schedule))
      )
    : false;

  const isNextBookingSameDay = nextCheckIn.checkIn
    ? isSameDay(new Date(nextCheckIn.checkIn), new Date(convertToISO(schedule)))
    : false;

  const top =
    status === OccupationStatus.Occupied ? (
      <>
        <span className='font-bold'>Occupied</span>
        <FaHouseUser className='mx-1' /> Check-in: {parseDate(occupied.checkIn)}
      </>
    ) : isPrevBookingSameDay ? (
      <>Previous Check-out: {parseDateTime(prevCheckOut.checkOut)}</>
    ) : prevCheckOut.checkOut ? (
      <>Previous Check-out: {parseDate(prevCheckOut.checkOut)}</>
    ) : null;

  const bottom =
    status === OccupationStatus.Occupied ? (
      <>Check-out: {parseDate(occupied.checkOut)}</>
    ) : isNextBookingSameDay ? (
      <>Next Check-in: {parseDateTime(nextCheckIn.checkIn)}</>
    ) : parseDate(nextCheckIn.checkIn) ? (
      <>Next Check-in: {parseDate(nextCheckIn.checkIn)}</>
    ) : null;

  return (
    <OccupationContext.Provider
      value={{
        status,
        isPrevBookingSameDay,
        isNextBookingSameDay,
        top,
        bottom,
      }}
    >
      {children}
    </OccupationContext.Provider>
  );
}

type JobListItemInnerProps = {
  children: ReactNode;
  hidden?: boolean;
};

const JobListItemInner = ({ children, hidden }: JobListItemInnerProps) => {
  const { status, isPrevBookingSameDay, isNextBookingSameDay, top, bottom } =
    useOccupationContext();

  if (hidden) {
    // emulate the space that would be taken up by the inner component
    return (
      <>
        <div>&nbsp;</div>
        {children}
        <div>&nbsp;</div>
      </>
    );
  }

  return (
    <>
      <div
        className={classNames(
          styles.inner,
          'w-fit min-w-[275px] rounded-bl-[15px]',
          status === OccupationStatus.Occupied
            ? 'bg-[#238080] text-white'
            : isPrevBookingSameDay
            ? 'bg-[#6D28D9] text-white'
            : top
            ? 'bg-[#006a6a14] font-thin text-[#7F909C]'
            : 'bg-transparent'
        )}
      >
        <div className='ml-auto flex w-fit items-center gap-1'>{top}</div>
      </div>

      {children}

      <div
        className={classNames(
          styles.inner,
          'w-full min-w-[275px] rounded-tl-[15px]',
          status === OccupationStatus.Occupied
            ? 'bg-[#238080] text-white'
            : isNextBookingSameDay
            ? 'bg-[#6D28D9] text-white'
            : bottom
            ? 'bg-[#006a6a14] font-thin text-[#7F909C]'
            : 'bg-transparent'
        )}
      >
        <div className='ml-auto w-fit'>{bottom}</div>
      </div>
    </>
  );
};

const JobListItemOuter = ({ children }: { children: ReactNode }) => {
  const { status, isPrevBookingSameDay, isNextBookingSameDay, top, bottom } =
    useOccupationContext();

  return (
    <>
      <div
        className={classNames(
          styles.outer,
          'rounded-t-[10px] border-b-0',
          status === OccupationStatus.Occupied
            ? 'bg-[#238080] text-white'
            : isPrevBookingSameDay
            ? 'bg-[#6D28D9] text-white'
            : 'bg-[#006a6a14] text-tertiary'
        )}
      >
        <div className='flex items-center gap-1'>{top}</div>
      </div>

      {children}

      {bottom && (
        <div
          className={classNames(
            styles.outer,
            'rounded-b-[10px] border-t-0 shadow',

            status === OccupationStatus.Occupied
              ? 'bg-[#238080] text-white'
              : isNextBookingSameDay
              ? 'bg-[#6D28D9] text-white'
              : 'bg-[#006a6a14] text-tertiary'
          )}
        >
          {bottom}
        </div>
      )}
    </>
  );
};

const JobViewBanner = ({ isMobile }: { isMobile: boolean }) => {
  const { status } = useOccupationContext();

  return (
    <>
      {status && (
        <div
          className={classNames(
            'flex w-full items-center gap-[5px] rounded-t-[5px] py-[5px]',
            status === OccupationStatus.Occupied
              ? 'bg-[#238080] text-white'
              : 'bg-[#6D28D9] text-white',
            isMobile ? 'px-[13px]' : 'px-4'
          )}
        >
          {status === OccupationStatus.Occupied ? (
            <HouseUserIcon className='h-[15px]' color='#fff' />
          ) : status === OccupationStatus.SameDayCheckOut ? (
            <CalendarExclamationIcon className='h-[15px]' color='#fff' />
          ) : status === OccupationStatus.SameDayCheckIn ? (
            <CalendarExclamationIcon className='h-[15px]' color='#fff' />
          ) : status === OccupationStatus.BackToBack ? (
            <ClockExclamationIcon className='h-[15px]' color='#fff' />
          ) : null}
          <span className='text-sm uppercase leading-[17px] text-white'>
            {status}
          </span>
        </div>
      )}
    </>
  );
};

export function getOccupationData(job: TJob | Job) {
  const { occupied, prevCheckOut, nextCheckIn } = job;
  const schedule =
    formatLocalDate(job, 'scheduledStart') ||
    formatLocalDate(job, 'scheduledEnd');

  const occupationData = {
    occupied,
    prevCheckOut: {
      id: prevCheckOut && prevCheckOut.id,
      checkIn: prevCheckOut && prevCheckOut.checkIn,
      checkOut: prevCheckOut && prevCheckOut.checkOut,
    },
    nextCheckIn: {
      id: nextCheckIn && nextCheckIn.id,
      checkIn: nextCheckIn && nextCheckIn.checkIn,
      checkOut: nextCheckIn && nextCheckIn.checkOut,
    },
    schedule,
  } as OccupationInformation;

  return occupationData;
}

export const OccupationInformation = Object.assign(OccupationInformationRoot, {
  JobListItemInner,
  JobListItemOuter,
  JobViewBanner,
});
