import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocationsQuery } from '~/generated/graphql';
import { SelectField } from './downshift/SelectField';

type LocationFieldProps = {
  name?: string;
  title: string;
  multiple?: boolean;
  required?: boolean;
};

export const LocationField = ({
  name = 'location',
  title,
  multiple,
  required = false,
}: LocationFieldProps) => {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (!field.value.type) {
      setFieldValue(`${name}.type`, 'Site');
    }
    if (meta.touched) {
      setFieldValue(`${name}.id`, []);
    }
    helpers.setTouched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, name, field.value.type]);

  const [result] = useLocationsQuery({
    variables: {
      type: field.value.type,
      subtype: field.value.type === 'Contact' ? 'Supplier' : undefined,
    },
    pause: !field.value.type,
  });
  const { fetching, data } = result;

  if (fetching) return null;

  return (
    <>
      {/* <RadioGroupField
        name={`${name}.type`}
        title={title}
        options={[
          { value: 'Site', label: t('type.Site') },
          {
            value: 'Contact',
            label: t('type.Contact_type', { type: 'Supplier' }),
          },
        ]}
      /> */}
      {field.value.type && (
        <SelectField
          name={`${name}.id`}
          label={t('type.' + field.value.type)}
          options={
            data?.locations.map((l) => ({ value: l.id, label: l.name })) ?? []
          }
          disabled={fetching}
          multiple={multiple}
          required={required}
        />
      )}
    </>
  );
};
